/**
*	@desc Model Product, extend Core Model 
*/
define('models/singles/catalogue',['models/core/model', 'app', 'db/easy', 'tools/ajax', 'tools/buildURL'], function (Model, App, DB, Ajax, URLBuilder) {

	'use strict';

	return Model.extend({

		idAttribute: ['id', 'declid'],

		url: function () {

			var base = _.result(this, 'urlRoot') || _.result(this.collection, 'url') || urlError();

			if (this.isNew()) return base;

			var id = this.get("id");
			var declid = this.get("declid");

			return base.replace(/[^\/]$/, '$&/') + encodeURIComponent(id) + '/' + encodeURIComponent(declid);
		},

		defaults: {
			id: 0,
			declid: 0,
			name: '',
			description: '',
			tax: 0,
			isDecli: 'N',
			qt: 1,
			unity: '',
			unitAmountIsTaxesFree: 'N'
		},

		storeName: 'product',
		name: 'product',
		urlRoot: '/rest/product',

		/**
   * 
   * @returns {Boolean}
   */
		isNew: function () {
			return !this.get('id') ? true : false;
		},

		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				if (App.indexedDB) {

					if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

						DB[_this.storeName].get([parseInt(_this.get('id')), parseInt(_this.get('declid'))]).then(function (model) {

							if (!model) {
								reject();
							} else {

								_this.set(model);

								if (_.isFunction(_this.fetchLocalCallback)) {
									_this.fetchLocalCallback();
								}

								resolve();
							}
						}, function (error) {
							console.log(error);
							reject();
						});
					}
				} else {
					reject();
				}
			});
		},

		/**
   * 
   * @param {type} options
   * @returns {undefined}
   */
		saveLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

					var id = {};

					id = {
						id: parseInt(_this.get('id')),
						declid: parseInt(_this.get('declid'))
					};

					_this.set(id);

					DB[_this.storeName].put(_this.toJSON()).then(function (lastId) {
						resolve();
					}, function (error) {
						console.log(error, _this);
						reject();
					});
				} else {
					reject();
				}
			});
		},

		/**
   * @desc Destroy data in local indexedDB
   * @returns {Promise}
   */
		destroyLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				var id = [parseInt(_this.get('id')), parseInt(_this.get('declid'))];

				DB[_this.storeName].delete(id).then(function () {
					resolve();
				}, function (error) {
					console.log(error);
				});
			});
		},

		/**
   * 
   * @param {type} attributes
   * @returns {catalogue_L4.catalogueAnonym$1.validate.validationErrors}
   */
		validate: function (attributes) {

			var required = {
				"product": {
					'name': true,
					'tradename': false,
					'unitAmount': false,
					'unit': true,
					'taxid': true,
					'notes': false,
					'unitAmountIsTaxesFree': false
				}
			};

			var validationErrors = { product: {} };

			if (required.product.name && attributes.name === '') {
				validationErrors['product']['name'] = this.computeError({ name: 'name', type: 'required' });
			}

			if (required.product.tradename && attributes.tradename === '') {
				validationErrors['product']['tradename'] = this.computeError({ name: 'tradename', type: 'required' });
			}

			if (required.product.unitAmount && attributes.unitAmount === '') {
				validationErrors['product']['unitAmount'] = this.computeError({ name: 'unitAmount', type: 'required' });
			}

			if (_.toArray(validationErrors.product).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @param {type} attributes
   * @returns {undefined}
   */
		validateVariation: function (attributes) {

			var required = {
				"declination": {
					'name': true,
					'tradename': false,
					'unitAmount': false
				}
			};

			var validationErrors = { declination: {} };

			if (required.declination.name && attributes.name === '') {
				validationErrors['declination']['name'] = 'product reference is required';
			}

			if (required.declination.tradename && attributes.tradename === '') {
				validationErrors['declination']['tradename'] = 'product tradename is required';
			}

			if (required.declination.unitAmount && attributes.unitAmount === '') {
				validationErrors['declination']['unitAmount'] = 'unitAmount is required';
			}

			if (_.toArray(validationErrors.declination).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @param {type} variationFields
   * @returns {Promise}
   */
		activateVariation: function (variationFields) {

			var _this = this;
			var url = _this.url() + '/activatevariation';

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: url,
					data: {
						variationfields: variationFields
					},
					dataType: "json",
					success: function (data) {

						if (data.status == 'error') {
							reject();
						} else {
							resolve();
						}
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @param {type} declination
   * @returns {undefined}
   */
		addVariation: function (declination) {

			var _this = this;
			var url = _this.url() + '/addvariation';
			var decliFields = _this.get('decliFields');

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: url,
					data: {
						idf1_id: decliFields.idf1,
						idf2_id: decliFields.idf2,
						idf3_id: decliFields.idf3,
						declination: declination
					},
					dataType: "json",
					success: function (data) {

						if (data.status == 'error') {
							reject();
						} else {
							resolve();
						}
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @param {type} rates
   * @returns {undefined}
   */
		editRateCategories: function (rates) {

			var _this = this;
			var url = _this.url() + '/editratecategories';

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: url,
					data: {
						rates: rates
					},
					dataType: "json",
					success: function (data) {

						if (data.status == 'error') {
							reject();
						} else {
							resolve();
						}
					},
					error: function () {
						reject();
					}
				});
			});
		}

	}, {

		/**
   * 
   * @param {type} whid
   * @param {type} itemid
   * @param {type} itemDeclid
   * @returns {Promise}
   */
		getAvailableSerials: function (whid, itemid, itemDeclid) {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(["rest", 'product', itemid, itemDeclid, whid, 'getAvailbleSerials'], false),
					method: 'GET',
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						reject(err);
					}

				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		getBarCodes: function (itemid) {

			return new Promise(_.bind(function (resolve, reject) {

				Ajax({

					url: URLBuilder(["rest", 'product', itemid, 'getBarCodes'], false),
					method: 'GET',
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						reject(err);
					}

				});
			}, this));
		}

	});
});
