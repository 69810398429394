/**
 * @desc Third Form, extend Page View
 * Child View : Core Form
 */
define('views/third/form',['backbone', 'underscore', 'views/core/page', 'models/singles/third', 'views/core/form', 'views/core/toastNotification', 'tools/buildURL', 'app'], function (Backbone, _, Page, Third, Form, ToastNotification, URLBuilder, App) {

	'use strict';

	return Page.extend({

		events: _.extend({
			"change .type": 'checkTypeAction'
		}, Page.prototype.events),

		title4Create: App.polyglot.t("createClient"),
		title4Update: App.polyglot.t("updateClient"),
		form: {},
		linked: 'third',
		toastMessages: {
			thirdSaved: App.polyglot.t("thirdSaved"),
			thirdUpdated: App.polyglot.t("thirdUpdated")
		},
		template: "templates/third/form.twig",

		/**
   * 
   * @param {Router} router
   * @param {int} thirdId
   */
		initialize: function (router, thirdId, third) {

			if (!third.isNew()) {
				this.title = this.title4Update;
				this.classes = 'updateThird';
			} else {
				this.title = this.title4Create;
				this.classes = 'createThird';
			}

			Page.prototype.initialize.apply(this, arguments);

			this.modelToSave = third;
			this.models[this.modelToSave.name] = this.modelToSave;

			//when we come from overview, we are certain than third is in localDB
			if (App.comeFrom === 'overview') {
				this.modelToSave.mustFetchLocal = true;
			}

			this.subviews = [];
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);
			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			// DOM Declaration
			this.$.corpInfos = $("#corpInfos", this.$el);
			this.$.contactInfos = $("#contactInfo", this.$el);
			this.$.radioPerson = $('#radioPerson', this.$el);
			this.$.radioCorp = $('#radioCorp', this.$el);
			this.$.radioMan = $('#radioMan', this.$el);
			this.$.radioWoman = $('#radioWoman', this.$el);
			this.$.type = $('.type', this.$el);
			this.$.civil = $('.civil', this.$el);
			this.$.logoIdentity = $('.identity i', this.$el);

			this.checkType();
		},

		/**
   *  check type of third Checked in form
   *  if person , hide corp infos
   */
		checkTypeAction: function (e) {

			var $radioChecked = $(e.currentTarget);

			if (!$radioChecked.parent().hasClass('focused')) {

				$radioChecked.parent().toggleClass('focused');

				if ($radioChecked.val() !== "corporation") {

					this.$.corpInfos.hide();

					$('input', this.$.corpInfos).attr('data-catchable', 'N');
					$('input', this.$.contactInfos).attr('data-catchable', 'Y');

					this.$.contactInfos.show();
					this.$.radioCorp.parent().removeClass('focused');
				} else {

					this.$.radioPerson.parent().removeClass('focused');

					$('input', this.$.corpInfos).attr('data-catchable', 'Y');
					$('input', this.$.contactInfos).attr('data-catchable', 'N');

					this.$.contactInfos.hide();
					this.$.corpInfos.show();
				}
			}
		},

		/**
   * check radio button and initialize them with her value 
   */
		checkType: function () {

			//prevent third type change in case of update
			if (!this.modelToSave.isNew()) {
				//check third type
				if (this.modelToSave.get("type") === "person") {
					this.$.corpInfos.remove();
				} else if (this.modelToSave.get("type") === "corporation") {
					this.$.contactInfos.remove();
				}
			} else {

				//check third type
				if (this.modelToSave.get("type") === "person") {
					this.$.radioPerson.prop("checked", true).trigger('change');
				} else if (this.modelToSave.get("type") === "corporation") {
					this.$.radioCorp.prop("checked", true).trigger('change');
				}
			}

			//check contact civil
			if (this.modelToSave.get("contact").civil === "man") {
				this.$.radioMan.prop("checked", true);
			} else if (this.modelToSave.get("contact").civil === "woman") {
				this.$.radioWoman.prop("checked", true);
			}
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			this.modelToSave.set(dataForm[this.linked]);

			if (dataForm[this.linked]['type'] === 'person') {
				this.modelToSave.set({ contact: dataForm.contact });
			}

			var isnew = this.modelToSave.isNew();

			if (this.modelToSave.isValid()) {

				this.modelToSave.save({ fetchAfterSave: false }).then(function () {

					if (isnew) {
						var toastMsg = _this.toastMessages.thirdSaved;
					} else {
						var toastMsg = _this.toastMessages.thirdUpdated;
					}

					_this.form.trigger('successful', toastMsg);
					_this.form.redirect(isnew, URLBuilder([_this.linked, "overview", _this.modelToSave.id]));
				}, function (jqXHR) {
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
