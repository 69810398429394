/**
 * @desc Unity Pref, extend Page View
 */
define('views/setting/unityPref',['underscore', 'views/core/page', 'views/core/form', 'models/collections/unities', 'app'], function (_, Page, Form, Unities, App) {

	'use strict';

	return Page.extend({

		toastMessages: {
			unity: {
				removeMsg: App.polyglot.t("unityRemoved"),
				saveMsg: App.polyglot.t('unitiesSaved')
			}
		},
		events: {
			"click .delete-unity": "showRemoveConfirm",
			'submit form': 'formAction',
			'click .addline': "newLine"
		},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/unity';
			this.collection = new Unities();
			this.collections[this.collection.name] = this.collection;
			this.title = App.polyglot.t('unities');
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			this.$.unitiesList = $('#unities-list', this.$el);
			this.$.row4Clone = $('#new-row', this.$el).clone();

			$('#new-row', this.$el).remove();

			this.currentIndex = $('.unity-line', this.$el).length;
		},

		/**
   * 
   * @returns {undefined}
   */
		showRemoveConfirm: function (e) {

			var $line = $(e.currentTarget).closest(".unity-line");
			var $id = $line.data('id');

			//if it's a new line just delete the line else show confirm for delete line on server too 
			if (_.isUndefined($id)) {
				$line.remove();
			} else {

				var callback = this.form.render.bind(this.form); // executed after suppression
				this.unity = this.collection.get($id);

				this.removeConfirm(this.unity, this.unity.get('value'), $line, this.toastMessages.unity.removeMsg, callback);
			}

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		newLine: function () {

			//clone a payMedium line
			var $line = this.$.row4Clone.clone();
			var $inputVal = $('.unity-value', $line);

			$inputVal.attr('name', 'unities[' + this.currentIndex + '][unit][value]');

			//show the line
			$line.removeAttr('id');

			this.$.unitiesList.append($line);
			this.currentIndex++;
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.sendDataFormV2();
			var _this = this;
			var collection2Save = new Unities();

			_.each(dataForm.unities, function (unit, index) {

				if (unit.unit && unit.unit['value']) {
					collection2Save.add(unit);
				}
			});

			if (collection2Save.length > 0) {

				//loader animation
				this.$el.append(_this.formActionSetLoader());

				collection2Save.save().then(function () {

					collection2Save.fetch({
						method: 'POST'
					}).then(function () {

						//end animation + toastNotif success
						$(".inprogress-mask", _this.$el).remove();
						_this.form.trigger('successful', _this.toastMessages.unity.saveMsg);
						_this.goToPreviousPage();
					});
				});
			} else {
				_this.goToPreviousPage();
			}

			return false;
		}

	});
});
