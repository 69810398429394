/**
 * @desc Supplier List, extend Third Listing View
 * Child View : Core Listing
 */
define('views/supplier/list',['underscore', 'views/third/list', 'tools/buildURL', 'tools/core/utils', 'app'], function (_, ThirdList, URLBuilder, Utils, App) {

	'use strict';

	return ThirdList.extend({

		name: 'suppliers',
		linked: 'supplier',
		title: App.polyglot.t('suppliers'),
		toastMessages: {
			linked: {
				linkedRemoved: App.polyglot.t('supplierRemoved')
			}
		},
		template: "templates/supplier/list.twig",
		urlToForm: URLBuilder(["supplier", "form"])
	});
});
