define('views/emailBox/syncError',['backbone', 'underscore', 'views/core/page', 'app', 'views/core/overview'], function (Backbone, _, Page, App, Overview) {

    'use strict';

    return Page.extend({

        events: _.extend({}, Page.prototype.events),
        template: "templates/emailbox/syncError.twig",
        title: App.polyglot.t('syncError'),

        /**
         *
         */
        initialize: function (router) {

            Page.prototype.initialize.apply(this, arguments);
            this.subviews.push(new Overview({ parentView: this }, this.router));
            this.render();
        },

        /**
         *
         */
        afterRender: function () {
            Page.prototype.afterRender.apply(this, arguments);
        }

    });
});
