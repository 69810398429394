/**
 * @desc override jquery.ajax
 */
define('tools/ajax',['jquery', 'backbone'], function ($, Backbone) {

	'use strict';

	return function (options) {

		var App = require('app');

		//merge API request with api tokens
		var urlRegexp = new RegExp(".*rest.*");

		if (urlRegexp.test(options.url)) {

			if (options.data) {
				options.data = _.extend(options.data, App.apiTokens);
			} else {
				options.data = App.apiTokens;
			}
		}

		var jqXhr = $.ajax(options);

		jqXhr.done(function (data) {

			if (data.error !== undefined && data.error !== '') {

				if (data.error.code === "E_USER_NOT_LOGGED" && App.isLogged) {

					App.isLogged = false;

					App.clearTokens().then(function () {

						// user is not logged - load default language
						App.initTranslations((navigator.language || navigator.userLanguage).indexOf('fr') == 0 ? 'fr' : 'en').then(function (trad) {

							App.polyglot = trad;

							//Go to login page
							var route = '#auth';
							var handler = Backbone.history.navigate(route, { trigger: true, replace: true });
						});
					});
				} else if (data.error === "E_SERVICE_UNVAILABLE") {
					App.isMaintenance = true;
					var route = '#maintenance';
					var handler = Backbone.history.navigate(route, { trigger: true, replace: true });
				}
			} else if (data.response && data.response.APP_VERSION) {

				App.isMaintenance = false;

				if (App.version != data.response.APP_VERSION) {

					if (App.isOnline) {
						App.onNewVersion();
					}
				}
			}
		});

		jqXhr.fail(function (jqXHR, textStatus, error) {

			if (jqXHR.status === 505) {
				console.log("error on the server");
			} else if (jqXHR.status === 0) {
				App.onConnectionLost();
			}
		});

		return jqXhr;
	};
});
