/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/agendaLabel',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {},

		storeName: 'agendaLabel',
		name: 'agendaLabel'

	});
});
