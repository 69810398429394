/**
 * @desc overview Core  extend Core View
 */
define('views/core/overview',['views/core/view', 'app', 'tools/core/utils'], function (View, App, Utils) {

	'use strict';

	return View.extend({

		className: "overview container-fluid",
		title: "",
		template: "",
		modelID: "",
		form: {},
		header: {},
		popupOptions: {},
		collections: {},

		/**
   * @param {Object}  array of models
   * @param {String} template
   * @param {int} modelID
   * @param {View} header
   */
		initialize: function (options) {

			View.prototype.initialize.apply(this, arguments);

			this.parentView = options.parentView;
			this.template = options.template ? options.template : options.parentView.template;
			this.models = options.parentView.models;
			this.modelsFetched = options.parentView.modelsFetched; //models already loaded
			this.collections = options.parentView.collections;
			this.header = options.parentView.headerView;
			this.router = options.parentView.router;
			this.options = options.parentView.options;
		},

		/**
   * @insert Overview in the DOM
   */
		renderCallback: function () {

			var _this = this;
			this.$.page = this.parentView.$el;

			this.$.page.append(this.$el);
		}

	});
});
