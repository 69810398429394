/**
 * @desc EmailBox router, extend Core Router
 */
define('routers/emailBox',['backbone', 'underscore', 'routers/core/router', 'tools/buildURL', 'models/collections/folders'], function (Backbone, _, CoreRouter, URLBuilder, Folders) {

    'use strict';

    return CoreRouter.extend({

        checkOnline: {
            form: true
        },

        routes: {
            'emailBox/home(/:linkedtype)(/:linkedid)': 'emailHome',
            'emailBox/list/:box(/)(:linkedtype)(/:linkedid)': 'listing',
            'emailBox/overview/:box/:id(/:linkedtype)(/:linkedid)': 'overview',
            'emailBox/form/:box(/:context)(/:id)(/:linkedtype)(/:linkedid)': 'form',
            'emailBox/syncError': 'syncError'
        },

        initialize: function () {
            CoreRouter.prototype.initialize.apply(this, arguments);
        },

        syncError: function () {

            var _this = this;
            var trueRoute = Backbone.history.location.hash;

            return new Promise(function (resolve, reject) {

                require(['require', 'views/emailBox/syncError'], function (require, SyncError) {

                    var view = new SyncError(_this);
                    view.trueRoute = trueRoute;
                    resolve(view);
                });
            });
        },

        /**
         *
         * @param {type} comefrom
         * @returns {undefined}
         */
        listing: function (box, linkedtype, linkedid) {

            var _this = this;
            var trueRoute = Backbone.history.location.hash;

            return new Promise(function (resolve, reject) {

                require(['require', 'views/emailBox/list'], function (require, ListingMail) {

                    _this.getAvailableActions(linkedtype, linkedid, 'listing').then(function (params) {

                        var view = new ListingMail(_this, box, linkedtype, linkedid, params[0], params[1]);
                        view.trueRoute = trueRoute;
                        resolve(view);
                    }, function () {
                        _this.navigate("#emailBox/syncError", { replace: true, trigger: true });
                    });
                });
            });
        },

        /**
         *
         * @param {type} linkedtype
         * @param {type} linkedid
         * @param {type} linkedname
         * @returns {undefined}
         */
        emailHome: function (linkedtype, linkedid) {

            var _this = this;
            var trueRoute = Backbone.history.location.hash;

            return new Promise(function (resolve, reject) {

                require(['require', 'views/core/homeModule'], function (require, HomeModule) {

                    _this.getAvailableActions(linkedtype, linkedid).then(function (params) {

                        var view = new HomeModule(_this, params[0], 'emails');
                        view.trueRoute = trueRoute;

                        resolve(view);
                    }, function (e) {
                        _this.navigate("#emailBox/syncError", { replace: true, trigger: true });
                    });
                });
            });
        },

        /**
         * 
         * @param id
         * @returns {*|c}
         */
        overview: function (box, id, linkedtype, linkedid) {

            var _this = this;
            var trueRoute = Backbone.history.location.hash;

            return new Promise(function (resolve, reject) {

                require(['require', 'views/emailBox/overview'], function (require, OverviewMail) {

                    var view = new OverviewMail(_this, box, id, linkedtype, linkedid);
                    view.trueRoute = trueRoute;

                    resolve(view);
                });
            });
        },

        /**
         *
         */
        form: function (box, context, id, linkedtype, linkedid) {

            var _this = this;
            var trueRoute = Backbone.history.location.hash;

            return new Promise(function (resolve, reject) {

                require(['require', 'views/emailBox/form'], function (require, Form) {

                    var view = new Form(_this, box, context, id, linkedtype, linkedid);
                    view.trueRoute = trueRoute;

                    resolve(view);
                });
            });
        },

        /**
         *
         * @param {type} linkedtype
         * @param {type} linkedid
         * @param {type} linkedname
         * @returns {Promise}
         */
        getAvailableActions: function (linkedtype, linkedid, context) {

            var folders = new Folders();

            var extraParams = '';
            var xhrParams = {};
            if (linkedid && linkedtype) {

                extraParams = URLBuilder([linkedtype, linkedid], false);

                //for linked we get boxes only not folders
                xhrParams['getFolders'] = "N";
            }

            return new Promise(function (resolve, reject) {

                folders.fetch({ method: "POST", data: xhrParams }).then(function () {

                    var links = [];

                    folders.each(function (folder) {

                        links.push({
                            name: folder.get('label'),
                            link: URLBuilder(['emailBox', 'list', folder.get('key'), extraParams])
                        });
                    });

                    // if(context !== 'listing') {
                    //     links.push({
                    //         img	 : 'public/ressources/images/new_mail_cyan.svg',
                    //         name : 'newMessage',
                    //         link : extraParams ? URLBuilder(['email','form', extraParams]) : URLBuilder(['email','form']),
                    //         class: "new-mail-from-home"
                    //     });
                    // }

                    resolve([links, folders]);
                }, function (e) {
                    reject(e);
                });
            });
        }
    });
});
