/**
 *  @desc Child view of  Panel View
 *  represent staff infos in SideBar
 */
define('views/sidebar/staff',['jquery', 'views/core/view', 'models/singles/staff', 'app', 'models/singles/corp'], function ($, View, Staff, App, Corp) {

	return View.extend({
		models: {},
		id: 'staffInfo',
		template: 'templates/staff/staffInfo.twig',
		className: 'vertical-center',
		/**
   * insert staff info in sidebar
   */
		renderCallback: function () {
			$("#side-bar").prepend(this.$el);
			this.trigger('renderend');
		},

		/**
   * 
   */
		initialize: function () {

			var _this = this;

			this.on('staffCorpUpdated', this.render.bind(this));

			this.options = {
				extraData: {
					staff: function () {

						return new Promise(function (resolve, reject) {
							resolve(App.currentStaff);
						});
					},
					corp: function () {

						return new Promise(function (resolve, reject) {
							resolve(App.currentCorp);
						});
					}
				}
			};
		}

	});
});
