/**
 *	@desc Third model, rextend Core Model 
 */
define('models/singles/third',['models/core/model', 'models/singles/address', 'underscore', 'app', 'models/singles/people', 'db/easy', 'tools/buildURL', 'tools/ajax'], function (Model, Address, _, App, People, DB, URLBuilder, Ajax) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			maincontactid: 0,
			addressid: 0,
			type: "corporation",
			name: "",
			mainAddress: "",
			addresses: "",
			contact: "",
			email: "",
			tel: "",
			mobile: "",
			fax: ""
		},

		storeName: 'third',
		urlRoot: '/rest/third/',
		name: 'third',
		/**
   * @desc Check if model is valid
   */
		validate: function (attributes, options) {

			var required = {
				"third": {
					'name': true,
					'type': true,
					'email': false,
					'mobile': false,
					'tel': false,
					'fax': false
				},

				"contact": {
					name: attributes.type === "person" ? true : false,
					forename: false,
					email: false,
					mobile: false,
					tel: false,
					fax: false
				}
			};

			var validatorRegEx = {
				email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i
			};

			var validationErrors = {};
			validationErrors[this.name] = {};
			validationErrors['contact'] = {};

			//Third validator
			if (attributes.type === 'corporation') {

				if (attributes.name === "" && required.third.name) {
					validationErrors[this.name]['name'] = this.computeError({ name: 'name', type: 'required' });
				}

				if (!validatorRegEx.email.test(attributes.email) && attributes.email !== "") {
					validationErrors[this.name]['email'] = this.computeError({ name: 'email', type: 'invalid' });
				}

				if (required.third.tel && attributes.tel == "") {
					validationErrors[this.name]['tel'] = this.computeError({ name: 'tel', type: 'required' });
				}

				if (required.third.mobile && attributes.mobile == "") {
					validationErrors[this.name]['mobile'] = this.computeError({ name: 'mobile', type: 'required' });;
				}
			}

			if (attributes.type === "person") {

				if (attributes.contact.forename !== '' || attributes.contact.email !== '' || attributes.contact.tel !== '' || attributes.contact.mobile !== '') {
					required.contact.name = true;
				}

				if (attributes.contact.name === "" && required.contact.name) {
					validationErrors['contact']['name'] = this.computeError({ name: 'name', type: 'required' });
				}

				if (!validatorRegEx.email.test(attributes.contact.email) && attributes.contact.email !== "") {
					validationErrors['contact']['email'] = this.computeError({ name: 'email', type: 'invalid' });
				}

				if (required.contact.tel && attributes.tel == "") {
					validationErrors['contact']['tel'] = this.computeError({ name: 'tel', type: 'required' });
				}

				if (required.contact.mobile && attributes.mobile == "") {
					validationErrors['contact']['mobile'] = this.computeError({ name: 'mobile', type: 'required' });
				}
			}

			if (_.toArray(validationErrors[this.name]).length > 0 || _.toArray(validationErrors.contact).length > 0) {
				return validationErrors;
			}
		},

		/**
   * @description return true if third or one of his mainContact have an email
   * @returns {undefined}
   */
		hasMail: function () {

			var thirdHasMail = this.get('email') !== '';

			if (this.get('type') === 'corporation') {
				var contactHasMail = this.get('contact') === '' ? false : this.get('contact').email !== '';
				return thirdHasMail || contactHasMail;
			} else {
				return thirdHasMail;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		fetchCallback: function () {

			var promises = [];

			return new Promise(_.bind(function (resolve, reject) {

				/**
     * nested models
     */
				// if third have addresses, save them in localdb
				if (this.get('mainAddress') !== '') {
					var Address = require('models/singles/address');

					// if address is not loaded
					if (_.isUndefined(Address)) {
						var Address = require('models/singles/address');
					}

					_.each(this.get("addresses"), function (address) {
						var address2Save = new Address(address);
						promises.push(address2Save.saveLocal());
					});
				}

				Promise.all(promises).then(function () {
					resolve();
				});
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		fetchLocalCallback: function () {
			return this.fetchCallback();
		}
	}, {

		/**
   * 
   * @returns {undefined}
   */
		ClasseNameByRelationType: function (relationType) {

			var className = '';

			return new Promise(function (resolve, reject) {

				switch (relationType) {

					case 'third':

						className = require('models/singles/third');

						break;

					case 'supplier':

						className = require('models/singles/supplier');

						break;

					case 'prospect':

						className = require('models/singles/prospect');

						break;

					default:

						break;

				}

				resolve(className);
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		getContacts: function (thirdid) {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(['rest', 'third', thirdid, 'contacts'], false),
					method: 'GET',
					dataType: 'json',
					success: function (contacts) {
						resolve(contacts);
					},
					error: function () {
						reject();
					}

				});
			});
		}

	});
});
