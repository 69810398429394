/**
 * Created by vnavarrete on 07/09/2017.
 *	@desc Model Month, extend Core Model
 */
define('models/singles/nylasAccount',['models/core/model', "tools/ajax", "tools/buildURL"], function (Model, Ajax, URLBuilder) {

    'use strict';

    return Model.extend({

        defaults: {},

        urlRoot: '/rest/emailBox/nylasAccount',
        name: 'nylasAccount',

        /**
         *
         */
        enable: function () {

            var _this = this;

            return new Promise(function (resolve, reject) {

                Ajax({
                    url: URLBuilder([_this.urlRoot, "enable", _this.id], false),
                    method: "PUT",
                    success: function () {
                        resolve();
                    },
                    error: function () {
                        reject();
                    }
                });
            });
        },

        /**
         *
         */
        disable: function () {

            var _this = this;

            return new Promise(function (resolve, reject) {

                Ajax({
                    url: URLBuilder([_this.urlRoot, "disable", _this.id], false),
                    method: "PUT",
                    success: function () {
                        resolve();
                    },
                    error: function () {
                        reject();
                    }
                });
            });
        }

    });
});
