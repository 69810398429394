/**
 * @desc Mail List, extend PageList View
 * Child View : Core Listing
 */
define('views/email/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/emails', 'app', 'tools/core/utils'], function (_, PageList, Listing, URLBuilder, Emails, App, Utils) {

	'use strict';

	return PageList.extend({

		name: "mails",
		hideFilterPannel: true,
		params: {},
		listing: {},
		toastMessages: {
			email: {
				emailRemoved: App.polyglot.t("emailRemoved")
			}
		},
		addImg: Utils.loadFile('new_mail.svg', 'images'),
		addLabel: App.polyglot.t('newMessage'),
		canAdd: true,
		addAction: function () {
			this.listing.goToForm(this.urlToMailForm);
		},

		template: "templates/email/list.twig",

		/**
   * @desc initialize
   */
		initialize: function (router, box, linkedtype, linkedid, links) {

			this.params = {};
			this.availableActions = links;

			switch (box) {

				case 'inbox':

					this.params.box = 'inbox';
					this.title = App.polyglot.t("inbox");

					break;
				case 'outbox':

					this.params.box = 'outbox';
					this.params.step = 'sent';
					this.title = App.polyglot.t("emailsended");

					break;
				case 'trashbox':

					this.params.box = 'trash';
					this.title = App.polyglot.t("emaildeleted");

					break;
				case 'scheduledbox':

					this.title = App.polyglot.t("emailprogrammed");
					this.params.box = 'outbox';
					this.params.step = 'scheduled';

					break;

			}

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.canAdd = true;
				this.canDelete = true;
				this.collection = new Emails();
				this.collection.XHRData = { search: { 'ownerid': App.currentStaff.id } };
				var _this = this;
				this.placeholderSearchBar = App.polyglot.t('subject') + ', ' + App.polyglot.t('message');

				if (linkedtype && linkedid) {

					if (Utils.inArray(linkedtype, App.availableDoctypes) || Utils.inArray(linkedtype, App.availablePurDoctypes) || linkedtype === 'opportunity') {

						this.params['relatedtype'] = linkedtype;
						this.params['relatedid'] = linkedid;
					} else {

						this.params = _.extend(this.params, {
							linkedid: linkedid,
							linkedtype: linkedtype
						});

						if ($.inArray(linkedtype, App.availableThirds) !== -1) {
							this.params['linkedtype'] = 'third';
						}
					}

					this.urlToMailForm = URLBuilder(["email", 'form']);

					if (this.params['linkedtype'] || this.params['relatedtype']) {
						this.urlToMailForm = URLBuilder([this.urlToMailForm, this.params['relatedtype'] ? this.params['relatedtype'] : this.params['linkedtype'], this.params['relatedid'] ? this.params['relatedid'] : this.params['linkedid']], false);
					}

					this.urlToMailForm = URLBuilder([this.urlToMailForm, "mailbox"], false);

					if (linkedtype === 'people' && !App.isOnline) {

						delete this.params.linkedid;
						delete this.params.linkedtype;

						this.params.peopleid = linkedid;
						this.collection.offlineQueryArgs.push('peopleid');
					}

					this.params['link'] = App.GoToOverviewByRelated(linkedtype, linkedid);

					this.options = {

						extraData: {

							linked: function () {

								return new Promise(function (resolve, reject) {
									resolve(_this.params);
								});
							}

						}

					};

					this.linkedid = linkedid;
				} else {
					this.urlToMailForm = URLBuilder(['email', 'form', 'mailbox']);
				}

				this.collection.XHRData.search = _.extend(this.collection.XHRData.search, this.params);
				this.listing = new Listing({ parentView: this });
				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageList.prototype.afterRender.apply(this, arguments);
			var _this = this;

			//			//init material effect on each row of listing
			this.listing.initSwipeEffect({

				removeMsg: this.toastMessages.email.emailRemoved,
				onModify: false,
				onRemove: function (model, $currentTarget) {
					$currentTarget.remove();
				},
				popupid: 'doc-option',
				modelName: "subject",
				getModel: function ($currentTarget) {
					var id = $currentTarget.data('id');
					return _this.collection.get(id);
				}
			}, '', this);
		}

	});
});
