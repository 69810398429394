/**
 *	@desc Contact model, extend Core Model 
 */
define('models/singles/contactSynchro',['models/core/model', 'underscore', 'db/easy'], function (Model, _, DB) {

	'use strict';

	return Model.extend({

		defaults: {
			name: ""
		},

		name: 'contactSynchro',

		/**
   * @desc Check if model is valid
   */
		validate: function (attributes) {

			var required = {
				"contact": {
					name: true,
					forename: false,
					email: false,
					phonehome: false,
					phonemobile: false
				}
			};

			var validatorRegEx = {
				email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i
			};

			var validationErrors = { contact: {} };

			//Contact validator
			if (!attributes.name && required.contact.name) {
				validationErrors['contact']['name'] = this.computeError({ name: 'name', type: 'required' });
			}

			if (required.contact.email && (!attributes.email || !validatorRegEx.email.test(attributes.email))) {
				validationErrors['contact']['email'] = this.computeError({ name: 'email', type: 'invalid' });
			}

			if (_.toArray(validationErrors.contact).length > 0) {
				return validationErrors;
			}
		}

	});
});
