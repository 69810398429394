/**
 *  @desc custom fields Collection, extend core Collection
 *  
 */
define('models/collections/customfields',['models/core/collection', 'models/singles/customfield', 'db/easy', 'tools/ajax'], function (Collection, Customfield, DB, Ajax) {

	'use strict';

	return Collection.extend({

		model: Customfield,
		storeName: 'customfields',
		name: 'customfields',
		url: '/rest/customfields/list',
		pagination: { nbperpage: 500 },

		constructor: function () {

			Collection.apply(this, arguments);
			this.defaultOfflineQueryArgs = [];
		}

	});
});
