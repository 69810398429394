/**
 * Created by vnavarrete on 15/06/2017.
 */
/**
 *  @desc Emails Collection, extend core Collection
 */
define('models/collections/folders',['models/core/collection', 'models/singles/folder', 'db/easy', 'tools/core/utils'], function (Collection, Folder, DB, Utils) {

    'use strict';

    return Collection.extend({

        model: Folder,
        storeName: 'folder',
        name: 'folders',
        url: '/rest/emailBox/folder/list',

        /**
         *
         * @returns {undefined}
         */
        constructor: function () {
            Collection.apply(this, arguments);
        },

        offlineQuery: function (value, options) {

            var finded = Collection.prototype.offlineQuery.apply(this, arguments);
            var boxToInclude = ['inbox', 'sent', 'drafts', 'trash'];

            if (options.getFolders === "N") {
                finded = finded && Utils.inArray(value.id, boxToInclude);
            }

            return finded;
        }

    });
});
