/**
 * @desc People List, extend Third Listing View
 * Child View : Core Listing
 */
define('views/people/list',['underscore', 'views/third/list', 'tools/buildURL', 'app'], function (_, ThirdList, URLBuilder, App) {

	'use strict';

	return ThirdList.extend({

		name: 'peoples',
		linked: 'people',
		title: App.polyglot.t('contacts'),
		toastMessages: {
			linked: {
				linkedRemoved: App.polyglot.t('contactDeleted')
			}
		},
		template: "templates/people/list.twig",
		subviews: [],
		urlToForm: function (model, $currentTarget) {
			this.router.navigate(URLBuilder(['people', 'form', model.get('id')], '', 0, 'list'), { trigger: true });
		},

		initialize: function (router, collection) {
			ThirdList.prototype.initialize.apply(this, arguments);
		}

	});
});
