/**
 * Created by vnavarrete on 15/06/2017.
 */

/**
 *	@desc email Model , extend Core Model
 */
define('models/singles/emailThread',['models/core/model', 'app', 'tools/buildURL', 'tools/ajax'], function (Model, App, URLBuilder, Ajax) {

    'use strict';

    return Model.extend({

        defaults: {},

        name: 'emailThread',
        storeName: 'emailThread',
        urlRoot: '/rest/emailBox/',

        /**
         *
         * @returns {undefined}
         */
        validate: function (attributes) {

            var validatorRegEx = {
                email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i
            };

            var validationErrors = { mail: {} };

            if (!attributes.mail.emails) {
                validationErrors['mail']['emails'] = this.computeError({ name: 'emails', type: 'required' });
            }

            if (_.toArray(validationErrors.mail).length > 0) {
                return validationErrors;
            }
        },

        /**
         *
         * @param val
         */
        toggleStarred: function (val) {

            var url = this.urlRoot + 'toggleStarred';
            var _this = this;

            return new Promise(function (resolve, reject) {

                new Ajax({
                    method: 'POST',
                    url: url,
                    data: JSON.stringify({
                        id: _this.id,
                        value: val
                    }),
                    success: function () {

                        _this.set({ starred: val });

                        _this.saveLocal().then(function () {
                            resolve();
                        });
                    },
                    error: function () {
                        reject();
                    }
                });
            });
        }

    }, {

        /**
         *
         * @returns {*|c}
         */
        getSignature: function () {

            return new Promise(function (resolve, reject) {

                Ajax({

                    "method": "GET",
                    "url": "/rest/emailBox/signature",
                    "success": function (data) {
                        resolve(data);
                    },
                    "error": reject

                });
            });
        },

        /**
         *
         * @returns {*|c}
         */
        getNylasPrefs: function () {

            return new Promise(function (resolve, reject) {

                Ajax({

                    "method": "GET",
                    "url": "/rest/emailBox/nylasPrefs",
                    "success": function (data) {
                        resolve(data);
                    },
                    "error": reject

                });
            });
        },

        /**
         *
         * @param fileid
         * @returns {*|c}
         */
        getAttachment: function (fileid, content_type) {

            return new Promise(function (resolve, reject) {

                var myHeaders = new Headers();
                myHeaders.append('Authorization', App.nylasPrefs.authToken);

                var myRequest = new Request('https://api.nylas.com/files/' + fileid + '/download', {
                    method: "GET",
                    headers: myHeaders
                });

                fetch(myRequest).then(function (response) {
                    return response.blob();
                }).then(function (myBlob) {
                    myBlob = new Blob([myBlob], { type: content_type });
                    console.log(myBlob);
                    resolve(myBlob);
                });
            });
        }
    });
});
