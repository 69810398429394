/**
 * Created by vnavarrete on 15/06/2017.
 */

/**
 *	@desc email Model , extend Core Model
 */
define('models/singles/folder',['models/core/model', 'app', 'tools/buildURL'], function (Model, App, URLBuilder) {

    'use strict';

    return Model.extend({

        defaults: {},

        name: 'folder',
        storeName: 'folder',
        urlRoot: '/rest/emailBox/folder',
        idAttribute: 'key'

    });
});
