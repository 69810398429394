/**
 * @desc StockRecap overview, extend Page View
 * Child View : Core Overview
 */
define('views/document/stockRecap',['underscore', 'views/core/page', 'tools/buildURL', 'views/core/toastNotification', 'models/singles/document', 'views/core/form', 'app', 'moment', 'tools/core/utils'], function (_, Page, URLBuilder, ToastNotif, Document, Form, App, Moment, Utils) {

	'use strict';

	return Page.extend({

		events: _.extend({
			"change input[type='checkbox']": 'checkBypass',
			'click .editRow': "goToDocRowForm"
		}, Page.prototype.events),

		listing: {},
		title: App.polyglot.t('docStockRecap'),
		toastMessages: {
			docSaved: App.polyglot.t('docSaved'),
			docUpdated: App.polyglot.t('docUpdated')
		},

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, thirdid, docid) {

			this.previousLink = router.navigate.bind(this, URLBuilder(['document', 'form', doctype, thirdid, docid, 'stockRecap']), { trigger: true, replace: true });

			Page.prototype.initialize.apply(this, arguments);

			Document.classNameByDoctype(doctype).then(_.bind(function (ClassName) {

				var _this = this;
				this.router = router;
				this.template = "templates/document/stockRecap.twig";
				this.document = new ClassName({ id: 0 });

				this.options = {

					extraData: {

						//take tempDoc in localDB
						stockRecap: function () {

							return new Promise(function (resolve, reject) {

								_this.document.fetchLocal().then(function () {

									_this.document.set({ id: _this.document.get('docid') });

									_this.document.checkStock().then(function (data) {

										_this.stockRecap = data;
										_this.stockRecap.currentDoc = _this.document.toJSON();

										resolve(data);
									});
								});
							});
						}

					}

				};

				this.form = new Form({ parentView: this });
				this.subviews.push(this.form);

				this.listenTo(this.form, 'dataSend', this.recordDoc);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			//DOM Declaration
			this.$.formActions = $('.form-actions', this.$el);

			//display eventual errors
			if (this.stockRecap.docFormAlert.cmup) {
				$('#panel-stock-cmup', this.$el).addClass('panel-error');
			}

			if (this.stockRecap.docFormAlert.serial) {
				$('#panel-serial', this.$el).addClass('panel-error');
			}

			if (this.stockRecap.docFormBlockSave.value === 'Y') {

				this.hasErrors = true;

				if (this.stockRecap.docFormBlockSave.bypassSaveBloqued === 'N') {

					this.$.formActionsTemp = $('.form-actions', this.$el).children().clone(true, true);
					this.$.formActions.empty();

					this.btn4update = "<button type='button' class='btn btn-cancel cancel col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3'>" + App.polyglot.t("editDoc") + "</button>";
					this.$.formActions.append(this.btn4update);

					if (!_.isEmpty(this.stockRecap.docFormAlert)) {

						this.cantBypass = _.some(this.stockRecap.docFormAlert, function (rows) {

							var isLocked = _.some(rows, function (row) {
								return row.canLock === 'Y';
							});

							if (isLocked) {
								return true;
							}
						});
					}

					this.checkBypass();
				}
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		checkBypass: function (e) {

			if (this.hasErrors) {

				var $currentTarget = e ? $(e.currentTarget) : $('input[name="document[stockImpact]"]');

				if (!$currentTarget.is(':checked') && !this.cantBypass) {
					this.$.formActions.empty();
					this.$.formActions.append(this.$.formActionsTemp);
				} else {
					this.$.formActions.empty();
					this.$.formActions.append(this.btn4update);
				}
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		recordDoc: function () {

			$('.submit-form', this.$el).prop('disabled', true);

			var _this = this;

			var $stockImpact = $('input[name="document[stockImpact]"]');

			this.document.attributes.document['stockImpact'] = $stockImpact.is(':checked') ? 'Y' : 'N';

			if (_this.document.isNew()) {
				var isnew = true;
				var toastMsg = _this.toastMessages.docSaved;
			} else {
				var toastMsg = _this.toastMessages.docUpdated;
				var isnew = false;
			}

			this.document.save().then(function () {

				var options = {
					id: 'toast-success',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: toastMsg
				};

				new ToastNotif(options);

				_this.router.navigate(URLBuilder(["document", "overview", _this.document.get('doctype'), _this.document.id]), { trigger: true, replace: true });
			}, function (jqXHR) {});
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		goToDocRowForm: function (e) {

			var $el = $(e.currentTarget);

			var link = URLBuilder(['document', 'docRowForm', this.document.get('doctype'), this.document.get('document').thirdid, this.document.get('document').id, this.document.get('document').doclang, $el.data('rowIndex'), $el.data('rowLinkedid'), $el.data('rowDeclid')]);

			this.router.navigate(link, { trigger: true, replace: true });

			return false;
		}

	});
});
