/* global Promise */

/**
 * @desc app Pref, extend Page View
 */
define('views/setting/appPref',['underscore', 'views/core/page', 'views/core/form', 'models/collections/currencies', 'tools/buildURL', 'app', 'models/singles/corp', 'tools/numberFormat'], function (_, Page, Form, Currencies, URLBuilder, App, Corp, NumberFormat) {

	'use strict';

	return Page.extend({

		toastMessages: {
			numberPref: {
				saveMsg: App.polyglot.t("prefsSaved")
			}
		},
		modelToSave: {},
		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.title = this.polyglot.t('dataFormatting');
			this.router = router;
			this.template = 'templates/setting/app';
			this.currencies = new Currencies();
			this.collections[this.currencies.name] = this.currencies;
			this.form = new Form({ parentView: this });

			this.subviews.push(this.form);
			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			//focus selects on current values
			var $currencySelected = $("select[name='numberformat[currencyid]'] option[data-selected='Y']");
			$currencySelected.prop('selected', true);
			this.currentCurrencyId = $currencySelected.val();

			var $timeFormat = $("select[name='corp[timeformat]']");
			$timeFormat.val(App.dateFormat.timeFormat);

			var $hourFormat = $("select[name='corp[hourformat]']");
			$hourFormat.val(App.dateFormat.hourFormat);

			var $currencyPos = $("select[name='numberformat[currencyposition]']");
			$currencyPos.val(App.numberFormat.currency.position);

			var $precision = $("select[name='numberformat[numberprecision]']");
			$precision.val(App.numberFormat.precision);

			var $separatorSelected = $("select[name='numberformat[separator]'] option[data-numberdecimals='" + App.numberFormat.separators.decimals + "'][data-numberthousands='" + App.numberFormat.separators.thousands + "']");
			$separatorSelected.prop('selected', true);
		},

		/**
   * 
   * @returns {undefined}
   */
		prepareTemplateRendering: function () {
			var toTemplate = View.prototype.prepareTemplateRendering.apply(this, arguments);
			toTemplate['dateFormat'] = App.dateFormat;

			return toTemplate;
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var promises = [];
			var dateFormat = {
				hourFormat: dataForm.corp['hourformat'],
				timeFormat: dataForm.corp['timeformat']
			};

			return new Promise(function (resolve, reject) {

				promises.push(
				//TODO : if new id == old id do nothin
				Corp.updateNumberFormat(dataForm.numberformat, _this.currentCurrencyId).then(function () {}, function (jqXHR) {
					console.log(arguments);
				}));

				promises.push(Corp.updateDateFormat(dateFormat));

				Promise.all(promises).then(function () {

					var corp = new Corp();

					corp.fetch().then(function () {
						App.numberFormat = new NumberFormat(corp.get('numberformat'));
					});

					_this.router.navigate(URLBuilder(["accountPref"]), { trigger: true });
					_this.form.trigger('successful', _this.toastMessages.numberPref.saveMsg);

					resolve();
				}, function (error) {
					console.log(error);
				});
			});

			return false;
		}

	});
});
