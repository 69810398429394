/**
 *	@desc Funnel Model , extend Core Model 
 */
define('models/singles/funnel',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'funnels',
		name: 'funnel'

	});
});
