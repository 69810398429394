define('routers/core/router',['underscore', 'backbone', 'app', 'tools/monitoring'], function (_, Backbone, App, Monitoring) {

	'use strict';

	return Backbone.Router.extend({

		routes: {
			'': 'home',
			'error': "error",
			'accessError': "error",
			'loading': "loading",
			'supportRequest': 'supportRequest',
			'filterPanel/:listName': 'filterPanel',
			'nylasConnect/:type': 'nylasConnect',
			'maintenance': 'maintenance',
			'corpBlocked': 'corpBlocked'
		},

		requiresNoAuth: ["#auth", "#auth/login", "#auth/register", "#loading"],
		requireNetwork: /.*form|pref.*/i,
		checkOnline: {},
		history: [],

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {

			// activate monitoring
			if (!Backbone.Router.prototype.monitoring) {
				App.monitoring = Backbone.Router.prototype.monitoring = new Monitoring();
			}

			var _this = this;

			this.on('route', function (route, args) {

				var path = Backbone.history.location.hash;

				this.history.push(path);

				//we keep always the 2 last entries
				if (this.history.length > 2) {
					this.history.splice(0, 1);
				}

				Backbone.Router.prototype.monitoring.setPageView(path);

				if (App.header) {

					App.header.$.brandMenu.removeClass('hidden');
					App.header.$.brandReturn.removeClass('hidden');
					App.header.$.actionList.empty();
					App.header.$.actionList.removeAttr('style');
					App.header.$el.find("nav").removeClass("box-shadow-none");
					App.header.$el.removeClass("hidden");
				}

				$('.content-wrapper').css("padding-top", "49px");
				$('#action-list-left').remove();
				$("#linked-container").remove();
				$("#wrapper-search").remove();
				$(".add-action").remove();
				$("#addContact-toast").remove();
				$('#page-title').removeClass("titleDown");
				$('.active', '#side-bar').removeClass('active');
				$('.openned', '#side-bar').removeClass('openned');
				$('.modal').modal('hide');
				$('.mask').removeClass('mask-up');
				$('body').removeAttr('class');
				$('nav.background-color-focused').removeAttr('style');

				//render the current link in sidebar active
				if (route === "home") {
					$('.sidebar .sidebar-dashboard').addClass('active');
				} else {
					$('.sidebar-' + _this.sidelink).addClass('active');
				}
			});
		},

		/**
   * @desc this method is called before all
   * @param {type} params
   * @param {type} next
   * @param {type} currentAction
   * @returns {Boolean}
   */
		before: function (params, next, currentAction) {

			var _this = this;

			if (!App.isOnline && App.currentStaff.get('isOfflineActivated') == 'N') {
				$('header nav .menu').click();
				return false;
			}

			//set loader
			if (!_.isEmpty(App.view)) {

				if (App.view.setLoader !== false && App.view.$('overlay').length == 0) {

					//if we are in toggleable page set loader on it else on main page
					if ($('.toggleablePage.toggled').length) {
						$('.toggleablePage.toggled').append('<div class="overlay">' + App.view.fa_loader + '</div>');
					} else if ($('.panelPage.toggled').length) {
						$('.panelPage').append('<div class="overlay">' + App.view.fa_loader + '</div>');
					} else {
						App.view.$el.append('<div class="overlay">' + App.view.fa_loader + '</div>');
					}
				}

				App.view.destroyObjects();
			}

			if (App.isLogged) {

				// if it's a module and it's not loaded

				if (this.plugin) {

					var hasAccess = App.pluginIsLoaded(this.plugin);

					if (App.currentStaff.get('permission')[this.plugin + "_access"]) {
						hasAccess = hasAccess && App.permission.can(this.plugin + "_access");
					}

					if (!hasAccess) {
						return this.redirectAccessError();
					}
				}

				// if it's a module and it's not loaded
				if (this.plugin && this.checkLicence) {
					// if it's a plugin with licences
					if (this.checkLicence === true) {
						if (App.permission.canAccessModule(this.plugin) === false) {
							return this.redirectAccessError();
						}
					}
				}

				// if there are something to check before entering route
				if (this.checkBefore && !_.isUndefined(this.checkBefore[currentAction])) {
					var canAccess = this.checkBefore[currentAction].call(this);
					if (!canAccess) {
						return this.redirectAccessError();
					}
				}

				var promise = new Promise(function (resolve, reject) {

					if (_this.plugin) {

						App.getPreferencesByPlugin(_this.plugin).then(function () {
							resolve();
						});
					} else {
						resolve();
					}
				});
			} else {
				var promise = Promise.resolve(true);
			}

			if (App.currentRoute) {
				App.comeFrom = App.currentRoute;
			}

			promise.then(function () {

				var path = Backbone.history.location.hash;

				//when offline disable navigation to write features
				if (!App.isOnline && _this.checkOnline[currentAction]) {
					_this.navigate(_this.currentHash, { trigger: true, replace: true });
				} else {

					//check if autoLog
					var autoLogRegExp = new RegExp("\#auth\/.+\/.+");
					if (autoLogRegExp.test(path)) {
						return next();
					}

					// Checking if user is authenticated or not
					var needAuth = !_.contains(_this.requiresNoAuth, path);
					var needNoAuth = _.contains(_this.requiresNoAuth, path);

					//when offline disable navigation to write features
					if (!App.isOnline && _this.checkOnline[currentAction]) {
						_this.navigate(_this.currentHash, { trigger: true, replace: true });
					} else {

						if (path === "#maintenance") {
							return next();
						}

						//if route need to be logged, redirect to login page
						if (needAuth && !App.isLogged) {
							App.goToLogPage();
							return false;
							//if route need to not be logged redirect to logged home page
						} else if (needNoAuth && App.isLogged) {
							Backbone.history.navigate('', { trigger: true });
							return false;
							//No problem, handle the route!!
						} else {
							return next();
						}
					}
				}
			});
		},

		after: function () {},

		route: function (route, name, callback) {

			if (!_.isRegExp(route)) route = this._routeToRegExp(route);

			if (_.isFunction(name)) {
				callback = name;
				name = '';
			}

			if (!callback) callback = this[name];

			var router = this;

			Backbone.history.route(route, function (fragment) {

				var reg = new RegExp(".*form.*");

				var args = router._extractParameters(route, fragment);

				var next = function () {

					App.currentRoute = name;
					router.currentHash = Backbone.history.location.hash;

					callback && callback.apply(router, args).then(function (view) {

						router.trigger.apply(router, ['route:' + name].concat(args));
						router.trigger('route', name, args);
						Backbone.history.trigger('route', router, name, args);
						router.after.apply(router, args);
					});
				};

				router.before.apply(router, [args, next, name]);
			});

			return this;
		},

		/**
   * 
   * @param {type} callbacks
   * @param {type} args
   * @param {type} name
   * @returns {undefined}
   */
		execute: function (callbacks, args, name) {
			Backbone.Router.prototype.execute.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		home: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			if (App.isLogged) {

				var _this = this;

				return new Promise(function (resolve, reject) {

					require(['require', 'views/home'], function (require) {

						var Home = require('views/home');
						var home = new Home(_this);
						home.trueRoute = trueRoute;

						resolve(home);
					});
				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		loading: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/loadingPage'], function (require) {

					var LoadingPage = require('views/core/loadingPage');
					var loadingView = new LoadingPage(_this);
					loadingView.trueRoute = trueRoute;

					loadingView.render(_this);
					resolve(loadingView);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		error: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['views/core/error'], function (ErrorView) {

					var errorView = new ErrorView(_this);
					errorView.trueRoute = trueRoute;

					errorView.render();
					resolve(errorView);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		supportRequest: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/support/supportRequest'], function (require, SupportView) {

					var view = new SupportView(_this);
					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		filterPanel: function (listName) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				switch (listName) {

					case 'invoice':
					case 'estimate':
					case 'creditNote':
					case 'creditnote':
					case 'order':
					case 'delivery':
					case 'proforma':
					case 'third_invoice':
					case 'third_estimate':
					case 'third_creditNote':
					case 'third_order':
					case 'third_delivery':
					case 'third_proforma':

						var filterPanelName = 'documentsFilterPanel';

						break;

					case 'purInvoice':
					case 'purCreditNote':
					case 'purOrder':
					case 'purDelivery':

						var filterPanelName = 'purchaseFilterPanel';

						break;

					case 'item':
					case 'service':

						var filterPanelName = 'catalogFilterPanel';

						break;

					case 'prospects':
					case 'clients':
					case 'suppliers':

						var filterPanelName = 'thirdsFilterPanel';

						break;

					case 'events_late':
					case 'events_today':
					case 'events_tomorrow':

						var filterPanelName = 'eventsFilterPanel';

						break;

					case 'third_tickets':

						var filterPanelName = 'ticketsFilterPanel';

						break;

					default:

						//special check for opportunity
						var myRegex = new RegExp('opportunities_([0-9])*');

						if (myRegex.test(listName)) {
							var filterPanelName = 'opportunitiesFilterPanel';
						} else {
							var filterPanelName = listName + 'FilterPanel';
						}

				}

				require(['require', 'views/filterPanels/' + filterPanelName], function (require, FilterPanel) {

					var view = new FilterPanel(_this, listName);
					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		/**
  *
   */
		nylasConnect: function (type) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/nylas/login'], function (require, NylasConnect) {

					var view = new NylasConnect(_this, type);
					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		/**
  * 
  * @returns {Promise}
  */
		maintenance: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/auth/maintenance'], function (require, MaintenanceView) {

					var view = new MaintenanceView(_this);
					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		corpBlocked: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/auth/corpBlocked'], function (require, CorpBlocked) {

					var view = new CorpBlocked(_this);

					view.trueRoute = trueRoute;
					view.render({ 'setLoader': false });
					resolve(view);
				});
			});
		},

		/**
   * 
   */
		redirectError: function () {
			this.navigate('error', { trigger: true });
			return false;
		},

		redirectAccessError: function () {
			this.navigate('accessError', { trigger: true });
			return false;
		},

		_pageView: function () {
			var path = Backbone.history.getFragment();
			//			ga('send', 'pageview', {page: "/" + path});
		},

		/**
   * 
   * @param {type} view
   * @returns {undefined}
   */
		setView: function (view) {

			this.unsetView();

			App.view = view;
		},

		/**
   * 
   * @returns {undefined}
   */
		unsetView: function () {

			if (!_.isEmpty(App.view)) {

				App.view.destroy();
				delete App.view;
				App.view = {};
			}
		}

	});
});
