/**
 *	@desc SavedSearch Model , extend Core Model
 */
define('models/singles/savedSearch',['models/core/model', 'app'], function (Model, App) {

    'use strict';

    return Model.extend({

        defaults: {
            id: 0
        },

        name: 'savedSearch',
        urlRoot: '/rest/savedSearches'

    });
});
