/**
 * @description TicketFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/ticketsFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/thirds'], function (_, FilterPanel, App, Utils, ListingFilters, Thirds) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			this.thirds = new Thirds();
			this.collections[this.thirds.name] = this.thirds;
			this.thirds.fetchOneTime = true;;
			this.title = App.polyglot.t("tickets");
			this.listName = listName;

			FilterPanel.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;

			var formatted_availableTicketStep = [];

			_.each(App.availableTicketStep, function (step) {
				formatted_availableTicketStep.push({
					id: step,
					label: App.polyglot.t(step)
				});
			});

			var filterList = {
				state: {
					label: App.polyglot.t('state'),
					field: {
						type: 'input',
						name: 'steps',
						offlineSearchField: 'step',
						classes: "offline-selectize",
						isMultiple: true,
						allValue: true,
						selectize: {
							domElt: 'input[name="filter[steps]"]',
							placeholder: App.polyglot.t('state'),
							create: false,
							valueField: 'id',
							labelField: 'label',
							options: formatted_availableTicketStep
						}
					}
				},
				ownerType: {
					label: App.polyglot.t('owner'),
					field: {
						type: 'select',
						name: 'assigned',
						offlineSearchField: 'staffid',
						values: [{ value: 'all', label: App.polyglot.t('all') }, { value: 'mine', label: App.polyglot.t('mytickets'), selected: true }, { value: 'none', label: App.polyglot.t('unassigned') }, { value: 'others', label: App.polyglot.t('assigned') }],
						valueAttr: 'value',
						labelAttr: 'label'
					}
				},
				client: {
					label: App.polyglot.t('client'),
					field: {
						type: 'input',
						name: 'thirds',
						offlineSearchField: 'thirdid',
						classes: "offline-selectize",
						isMultiple: true,
						selectize: {
							domElt: 'input[name="filter[thirds]"]',
							placeholder: App.polyglot.t('name'),
							create: false,
							valueField: 'id',
							labelField: 'name',
							searchField: ['name', 'forename'],
							maxItems: '1',
							maxOptions: '1',
							loadThrottle: 600,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.thirds.fetch({

										method: 'POST',
										data: { search: { contains: query } }

									}, true).then(function () {
										resolve(_this.thirds);
									});
								});
							},
							render: 'third'
						}
					}
				}
			};

			if (this.listName === 'third_tickets') {
				delete filterList['client'];
			}

			this.filters = filterList;
			return this.filters;
		}

	});
});
