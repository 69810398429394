/**
 * Child View : Core Listing
 */
define('views/log/list',['underscore', 'views/core/pageList', 'views/core/listing', 'models/collections/eventLogs', 'app'], function (_, PageList, Listing, Eventlog, App) {

	'use strict';

	return PageList.extend({

		name: 'logs',

		events: _.extend({
			'change #log-related-selector': 'changeRelated',
			'click .log-row': 'goToRelated'
		}, PageList.prototype.events),

		hideFilterPannel: true,
		hideSearchIcon: true,
		title: App.polyglot.t('logHisto'),
		listing: {},
		currentRelated: 'client',
		toastMessages: {},
		template: "templates/eventlog/list.twig",

		/**
   * @desc initialize
   */
		initialize: function (router, relatedtype, relatedid) {

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				//no cache for log page
				delete App.cacheControl[this.router.currentHash];

				var _this = this;

				this.collection = new Eventlog();
				var availableRelateds = this.collection.getAvailableRelateds();

				if (relatedtype && relatedid) {

					this.collection.XHRData = {

						search: {
							'linkedtype': relatedtype,
							'linkedid': relatedid
						}

					};

					this.globalListing = false;
				} else {

					this.collection.XHRData = {
						search: {
							'linkedtype': _this.currentRelated
						}
					};

					this.globalListing = true;
				}

				this.var4template('availableRelateds', availableRelateds);
				this.var4template('isGlobalListing', _this.globalListing ? 'Y' : 'N');

				this.listing = new Listing({ parentView: this });
				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			if (App.isOnline) {

				if (this.collection.nbResults > 0) {
					this.collection.loadMore = true;
				} else {
					this.collection.loadMore = false;
				}
			}

			if (this.globalListing === true) {

				App.header.$el.find("nav").addClass("box-shadow-none");
				$('#log-related-selector option[value="' + this.currentRelated + '"]').prop('selected', 'selected');
				_this.$('.main-container').css('padding-top', '50px');
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		changeRelated: function (e) {

			this.currentRelated = $(e.currentTarget).val();
			this.collection.pagination.pagenum = 1;
			this.collection.offsetOffline = 0;
			this.collection.XHRData.search.linkedtype = this.currentRelated;
			this.listing.collection.reset();

			var _this = this;

			this.render().then(function () {
				_this.$el.scrollTop(0);
			});
		},

		scrollTop: function () {
			this.$el.find('.log-container').animate({ scrollTop: 0 });
		},

		/**
   * 
   * @returns {undefined}
   */
		goToRelated: function (e) {
			var v_current = $(e.currentTarget);
			if (v_current.data('log') != 'deleted' && v_current.data('link')) {
				return this.router.navigate(v_current.data('link'), { trigger: true });
			}
		}

	});
});
