define('views/auth/corpBlocked',['views/core/page', 'views/core/overview', 'app'], function (Page, Overview, App) {

	return Page.extend({

		template: 'templates/auth/corpBlocked.twig',
		subviews: [],
		id: 'corpBlocked',

		/**
   * 
   * @param {type} router
   * @returns {undefined}
   */
		initialize: function (router) {
			Page.prototype.initialize.apply(this, arguments);
			this.subviews.push(new Overview({ parentView: this }));
			this.render();
		},

		afterRender: function () {
			Page.prototype.afterRender.apply(this, arguments);
			App.header.$el.addClass("hidden");
			$('.content-wrapper').css('padding-top', '0px');
			App.logout();
		}

	});
});
