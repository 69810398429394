/**
 * @desc Core View, extend Backbone.View
 */
define('views/core/view',['underscore', 'backbone', 'tools/ajax', 'tools/core/utils', 'app', 'db/easy', 'tools/math', 'big', 'moment', 'tinyMCE', 'tools/buildURL', 'tools/materialEffect', 'polyglot', 'hammerjs', 'selectize', 'tools/monitoring', 'tools/disableSelection', 'backboneHammer', 'jqueryhammer', 'libs/selectize-no-delete', 'libs/selectize-select-optgroup'], function (_, Backbone, Ajax, Utils, App, DB, MathTools, Big, Moment, TinyMCE, URLBuilder, initMaterialEffect, Polyglot, Hammer, Selectize, Monitoring) {

	'use strict';

	/**
  *
  * @type @exp;Backbone@pro;View@call;extend
  */

	var View = Backbone.View.extend({

		requesters: {
			template: ''
		},

		hammerOptions: {
			domEvents: true
		},

		title: '',
		template: '',
		renderCallback: function () {},
		patternSearch: "",
		fa_loader: '<div class="loader"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>',
		options: {},
		// polyglot is the library for translations
		polyglot: {},

		/**
   * @desc get translations defined in app and set it here to be accessible everywhere
   * @returns {undefined}
   */
		initialize: function (view) {
			this.lifeTimeValue = Date.now();
			this.firstRender = true;
		},

		/**
   *
   * @returns {undefined}
   */
		onWindowsResize: function () {},

		/**
   * @desc Get all models/collections to fetch for view rendering
   * @returns {Array}
   */
		getModelsToFetch: function () {

			var models = [];
			_.each(this.collections, function (collectionInstance) {

				if (!collectionInstance.isAlreadyFetched) {
					models.push(collectionInstance);
				}
			});

			_.each(this.models, function (modelInstance) {
				models.push(modelInstance);
			});

			return models;
		},

		/**
   * @desc say to user that write features are disabled
   * @param {event} e
   * @returns {Boolean}
   */
		checkButton: function (e) {

			if (!App.isOnline) {

				e.stopImmediatePropagation();
				e.stopPropagation();
				e.preventDefault();

				this.notifWriteDisabled();

				return false;
			} else {
				return true;
			}
		},

		/**
   * @desc transform every models/collections fetched to JSON, for template (called by render function)
   */
		prepareTemplateRendering: function () {

			var toTemplate = {};

			_.each(this.collections, function (collectionInstance, collectionName) {

				toTemplate[collectionName] = collectionInstance.keepCollection ? collectionInstance : collectionInstance.toJSON();

				if (!_.isUndefined(collectionInstance.pagination)) {
					toTemplate.pagination = collectionInstance.pagination;
				}
			});

			_.each(this.models, function (modelInstance, modelName) {

				if (modelInstance instanceof Backbone.Model) {

					if (modelInstance.get('customfields')) {
						toTemplate['cfValues'] = modelInstance.get('customfields');
					} else {
						toTemplate['cfValues'] = [];
					}

					toTemplate[modelName] = modelInstance.toJSON();
				} else {
					// extra data who are not model, already to JSON
					toTemplate[modelName] = modelInstance;
				}
			});

			_.each(this.modelsFetched, function (modelInstance, modelName) {

				if (modelInstance instanceof Backbone.Model) {

					if (modelInstance.get('customfields')) {
						toTemplate['cfValues'] = modelInstance.get('customfields');
					} else {
						toTemplate['cfValues'] = [];
					}

					toTemplate[modelName] = modelInstance.toJSON();
				} else {
					// extra data who are not model, already to JSON
					toTemplate[modelName] = modelInstance;
				}
			});

			this.toTemplate = toTemplate = _.extend(toTemplate, this.getGlobaleToTemplate());

			return toTemplate;
		},

		/**
   *
   * @returns {undefined}
   */
		getGlobaleToTemplate: function () {

			var toTemplate = [];

			//give global vars to  template
			toTemplate['App'] = App;
			toTemplate['Math'] = MathTools;
			toTemplate['Big'] = Big;
			toTemplate['Moment'] = Moment;
			toTemplate['polyglot'] = App.polyglot;
			toTemplate['privileges'] = App.permission;
			toTemplate['preferences'] = App.preferences;
			toTemplate['URLBuilder'] = URLBuilder;
			toTemplate['tools'] = Utils;

			if (App.currencies) {
				toTemplate['currencies'] = App.currencies.toJSON();
			}

			return toTemplate;
		},

		/**
   *
   * @returns {undefined}
   */
		setLoader: function ($target, forceOpacity) {
			var opacity = !_.isUndefined(forceOpacity) ? forceOpacity : 1;
			return $target.append('<div class="overlay" style="opacity:' + opacity + ' !important">' + this.fa_loader + '</div>');
		},

		/**
   *
   * @returns {undefined}
   */
		removeLoader: function () {
			$('.content-wrapper').find('.overlay').remove();
		},

		/**
   *
   * @param {boolean} merge
   * @param {array} moreData
   * @returns {view_L12.viewAnonym$1}
   */
		render: function (options, moreData) {

			this.renderStart = Date.now();

			options = _.isUndefined(options) ? {} : options;

			var _this = this;

			return new Promise(_.bind(function (resolveRender, rejectRender) {

				if (App.isLogged) {

					if (_this.template) {

						//get template
						this.requesters.template = Ajax({
							url: _this.template,
							dataType: "html"
						});
					}

					var merge = _.isUndefined(options.add) ? false : options.add;
					var promises = [this.requesters.template];
					var models = this.getModelsToFetch();

					/**
      * Check cacheControl only for page View and if it's the first render (prevent render by infinite scroll)
      *
      */
					if (_this.parentView && this.firstRender && this.id === 'listing') {

						if (App.cacheControl[this.parentView.router.currentHash] && Date.now() - App.cacheControl[this.parentView.router.currentHash] <= App.TTLView && !this.parentView.useSavedSearch) {
							var isCached = true;
						} else {
							//view has expired, delete it from cache
							var isCached = false;
							delete App.cacheControl[this.parentView.router.currentHash];
						}
					}

					//fetch every models/collections
					_.each(models, function (model) {

						var XHRData = {};

						var currentTimestamp = Date.now();

						if (model) {

							if (!model.fetchOneTime || _this.firstRender) {

								if (model instanceof Backbone.Collection) {

									if (App.indexedDB && !App.isOnline || model.mustFetchLocal || isCached || model.isStored) {

										//flag collection taken from cache
										if (isCached) {
											model.isCached = true;
										}

										if (model.search) {

											XHRData = {
												contains: model.search[model.searchFieldName]
											};
										}

										XHRData = $.extend(true, XHRData, model.XHRData);

										promises.push(model.fetchLocal(XHRData, merge));
									} else {

										XHRData = {
											pagination: model.pagination,
											search: model.search,
											order: {
												direction: model.orderBy,
												arg: model.orderArg
											}
										};

										$.extend(true, XHRData, model.XHRData);

										promises.push(model.fetch({
											method: 'POST', //fetching collection is always with POST method
											saveLocalAfterFetch: options.saveAfterFetch,
											data: XHRData
										}, merge));
									}
								} else if (model instanceof Backbone.Model) {

									if (App.indexedDB && !App.isOnline || model.mustFetchLocal || isCached) {

										if (!model.isNew()) {
											promises.push(model.fetchLocal());
										}
									} else {

										if (!model.isNew() || model.name === "corp") {
											promises.push(model.fetch());
										}
									}
								}
							}
						}
					});

					//get additionnals data who are not model or collection
					if (!_.isUndefined(this.options.extraData)) {

						_.each(this.options.extraData, function (dataFunction, key) {

							if (_.isFunction(dataFunction)) {
								promises.push(dataFunction().then(function (data) {
									_this.models[key] = data;
								}, function (err) {
									console.log(key);
								}));
							}
						});
					}

					//execute when every models/collections and template are fetched with success
					Promise.all(promises).then(function (results) {

						_this.firstRender = false;

						if (_this.router && (_this.parentView && !_.isUndefined(_this.parentView.trueRoute) || !_.isUndefined(_this.trueRoute))) {

							var trueRoute = !_this.parentView ? _this.trueRoute : _this.parentView.trueRoute;

							//trick for fix speed navigation problems
							if (trueRoute === _this.router.currentHash) {
								_this.computeTemplate(results, resolveRender, rejectRender);
							}
						} else {
							_this.computeTemplate(results, resolveRender, rejectRender);
						}
					}, function (error) {

						if (_this.router && error && error !== 'E_USER_NOT_LOGGED') {

							_this.destroyObjects();

							if (error === 'E_OBJ_NOT_LOADABLE') {
								_this.resourceNotAvailable();
								App.cacheControl = {};
								_this.goToPreviousPage();
							} else if (error.message === 'Object Nylas not loadable') {
								_this.router.navigate("#emailBox/syncError", { trigger: true, replace: true });
							} else {
								_this.router.navigate("#error", { trigger: true, replace: true });
							}

							return false;
						}
					}).catch(function (e) {
						App.monitoring.captureException(e, {
							extra: {
								collections: _this.collections,
								models: _this.models,
								route: _this.router.currentHash,
								template: _this.template
							}
						});
						_this.destroyObjects();

						if (_this.router) {
							_this.router.navigate("#error", { trigger: true });
						}
					});

					//case of public page
					//take just the template
				} else {

					var templateRequest = new Promise(function (resolve, reject) {

						//get template
						_this.requesters.template = Ajax({
							url: _this.template,
							dataType: "html",
							success: function (template) {
								resolve(template);
							},
							error: function (error) {
								console.log(error);
								reject();
							}
						});
					});

					templateRequest.then(function (html) {

						var dataToTemplate = {
							'polyglot': App.polyglot
						};

						var template = _.template(html); //compile template with underscore
						_this.$el.html(template(dataToTemplate)); //Insert template in DOM with data

						_this.renderCallback();
						resolveRender();
					}, function (error) {
						console.log(error);
					});
				}
			}, this));

			return this;
		},

		computeTemplate: function (results, resolveRender, rejectRender) {

			var _this = this;

			var html = results[0]; //html template

			if (!_.isEmpty(_this.models) || !_.isEmpty(_this.collections) || !_.isEmpty(_this.modelsFetched)) {

				try {

					var template = _.template(html); //compile template with underscore
					var dataToTemplate = _this.prepareTemplateRendering(); //transform models/collections to JSON

					_this.$el.html(template(dataToTemplate)); //Insert template in DOM with data
					_this.renderCallback();
					resolveRender();
				} catch (e) {
					rejectRender(e);
					App.monitoring.captureException(e, {
						extra: {
							collections: _this.collections,
							models: _this.models,
							route: _this.router.currentHash,
							template: _this.template
						}
					});
					return false;
				}
			} else {

				try {
					var template = _.template(html); //compile template with underscore
					var dataToTemplate = _this.getGlobaleToTemplate(); //get every global vars for template
					_this.$el.html(template(dataToTemplate)); //Insert template in DOM with data
					_this.renderCallback();
					resolveRender();
				} catch (e) {
					App.monitoring.captureException(e, {
						extra: {
							collections: _this.collections,
							models: _this.models,
							route: _this.router.currentHash,
							template: _this.template
						}
					});
					rejectRender(e);
					return false;
				}
			}
		},

		/**
   *
   * @param {type} model
   * @returns {Boolean}
   */
		removeConfirm: function (model, modelName, $currentTarget, msg, callback, destroyOptions) {

			var _this = this;

			if (App.useActionSheet) {

				require(['views/widgets/deleteActionSheet'], function (DeleteActionSheet) {
					new DeleteActionSheet(_this.removeModel.bind(_this, model, $currentTarget, msg, callback, destroyOptions));
				});
			} else {

				var Popup = require("views/core/Popup");

				new Popup({

					id: "modal-remove",
					title: modelName,
					buttons: [{
						title: App.polyglot.t('delete'),
						classes: "btn btn-primary",
						onclick: _this.removeModel.bind(_this, model, $currentTarget, msg, callback, destroyOptions)
					}, {
						title: App.polyglot.t('cancel'),
						classes: "btn-cancel btn-secondary"
					}],
					mergeBtns: false,
					content: App.polyglot.t('confirmDelete'),
					data: {},
					callback: ""

				});
			}

			return false;
		},

		/**
   *
   * @param {type} model
   * @returns {Boolean}
   */
		removeModel: function (model, $maskTarget, msg, callback, destroyOptions) {

			var id = model.id;
			var _this = this;
			var ToastNotification = require('views/core/toastNotification');

			if (!$maskTarget.hasClass('page')) {
				$maskTarget.append(_this.formActionSetLoader());
			}

			model.destroy({
				data: JSON.stringify(destroyOptions)
			}).then(function () {

				new ToastNotification({
					id: 'toast-success',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: msg
				});

				if (_.isFunction(callback)) {
					callback();
				}
			}, function (error) {

				if (error === 'E_OBJ_NOT_LOADABLE') {
					_this.resourceNotAvailable();
					_this.render();
				}
			});

			return false;
		},

		resourceNotAvailable: function () {

			require(['views/core/toastNotification'], function (Notif) {

				new Notif({
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: App.polyglot.t('resourceNotLongerAvailable')
				});
			});
		},

		/**
   *
   * @returns {Boolean}
   */
		toggleMoreMenu: function () {

			$("#more-menu").toggle();

			return false;
		},

		/**
   *
   * @param {type} e
   * @returns {undefined}
   */
		closeMoreMenu: function (e) {

			var $container = $("#more-menu");

			if (!$container.is(e.target) // if the target of the click isn't the $contaiter...
			&& $container.has(e.target).length === 0 // ... not a descendant of the $contaiter
			|| $(e.target).prop('tagName') === "LI" && !$container.is(e.target)) // or if li in $container
				{
					$container.hide();
				}
		},

		/**
   * @desc init selectize
   */
		initSelectize: function () {

			var _this = this;

			_.each(this.selectizeObjects, function (selectize) {

				var $elt = selectize.domElt;
				var options = selectize.options instanceof Backbone.Collection ? selectize.options.toJSON() : _.toArray(selectize.options);

				var selectizeParams = {

					plugins: ['remove_button', 'select-optgroup'],
					create: selectize.create,
					valueField: selectize.valueField,
					labelField: selectize.labelField,
					maxItems: selectize.maxItems ? selectize.maxItems : 9999,
					minItems: selectize.minItems ? selectize.minItems : 0,
					maxOptions: selectize.maxOptions ? selectize.maxOptions : 9999,
					placeholder: selectize.placeholder ? selectize.placeholder : $elt.attr("placeholder"),
					options: options,
					searchField: selectize.searchField,
					loadThrottle: selectize.loadThrottle ? selectize.loadThrottle : 300,
					hideSelected: selectize.hideSelected,

					optgroups: selectize.optgroups ? selectize.optgroups : "",
					optgroupField: selectize.optgroupField ? selectize.optgroupField : '',
					optgroupLabelField: selectize.optgroupLabelField ? selectize.optgroupLabelField : '',
					optgroupValueField: selectize.optgroupValueField ? selectize.optgroupValueField : '',

					onInitialize: function () {

						if (_.isFunction(selectize.onInitialize)) {
							selectize.onInitialize.call(_this);
						}

						$elt.parent().find('.selectize-input').after('<div class="selectize-overlay"></div>');
					},

					// when user add an option
					onItemAdd: function (value, item) {

						if (_.isFunction(selectize.onItemAdd)) {
							selectize.onItemAdd.call(_this, value, item, this);
						}
					},

					// when user delete an option
					onItemRemove: function (value, item) {

						if (_.isFunction(selectize.onItemRemove)) {
							selectize.onItemRemove.call(_this, value, item);
						}
					},
					// on every input change
					onChange: _.debounce(function (value) {

						if (value) {

							$($elt[0]).closest('.form-group').find('label').addClass('active');

							if (selectize.maxItems == 1) {
								$elt[0].selectize.blur();
							}
						}

						if (_.isFunction(selectize.onChange)) {
							selectize.onChange.call(_this, value, this);
						}
					}, selectize.debounceTime ? selectize.debounceTime : 0),

					// when user delete an option
					onDelete: function (value, item) {
						if (_.isFunction(selectize.onDelete)) {
							selectize.onDelete.call(_this, value, item);
						}
					},
					// when user focus the input
					onFocus: function () {

						var $scrollable = _this.scrollable ? $(_this.scrollable, _this.$el) : $('.container-fluid');
						var $currentTarget = this.$wrapper;
						var offset = $currentTarget.offset();

						setTimeout(function () {
							$scrollable.animate({ scrollTop: $scrollable.scrollTop() + offset.top - $currentTarget.height() - 30 });
						}, 500);

						if ($elt.parent().hasClass('has-error')) {
							$elt.parent().removeClass('has-error');
						}

						if ($elt.closest('.form-group').find('label')) {
							$elt.closest('.form-group').find('label').addClass('active');
						}

						if (selectize.onFocus && _.isFunction(selectize.onFocus)) {
							selectize.onFocus.call(_this);
						}

						//for listing-selectize if dropdown content is bigger than window height set a fixed height  on it for have a scroll
						if ($elt.parent().is('#listing-selectize')) {

							setTimeout(function () {

								var displayableHeight = $(window).outerHeight() - $('header').outerHeight() - $('#nav-pills-container').outerHeight() - $('.selectize-input').outerHeight();
								var dropdownHeight = $('.selectize-dropdown-content > div').outerHeight() * $('.selectize-dropdown-content > div').length;

								if ($('.selectize-dropdown-content > div').outerHeight() * $('.selectize-dropdown-content > div').length > displayableHeight) {
									$('.selectize-dropdown-content').height(displayableHeight);;
								} else {
									$('.selectize-dropdown-content').removeAttr('style');
								}
							}, 100);
						}
					},
					// when user leave input
					onBlur: function () {

						if ($elt.closest('.form-group').find('label') && _.isEmpty($elt[0].selectize.items)) {
							$elt.closest('.form-group').find('label').removeClass('active');
						}

						if (selectize.onBlur && _.isFunction(selectize.onBlur)) {
							selectize.onBlur.call(_this);
						}
					}

				};

				// override the display of options
				if (selectize.render) {

					if (_.isFunction(selectize.render)) {

						selectizeParams.render = selectize.render;
					} else if (_.isString(selectize.render)) {

						selectizeParams.render = _this.defaultSelectizeRender.call(_this, selectize.render);
					} else {

						selectizeParams.render = {};

						if (_.isFunction(selectize.render.option)) {
							selectizeParams.render['option'] = selectize.render.option;
						}

						if (_.isFunction(selectize.render.item)) {
							selectizeParams.render['item'] = selectize.render.item;
						}
					}
				}

				// set available options on selectize render
				if (_.isFunction(selectize.load)) {

					selectizeParams.load = function (query, callback) {

						if (!query.length) return callback();

						if (_.isFunction(selectize.load)) {

							selectize.load(query, callback).then(function (result) {

								var resultArray = result instanceof Backbone.Collection ? _.toArray(result.toJSON()) : _.toArray(result);
								callback(resultArray);
							}, function (error) {
								console.log(error);
							});
						}
					};
				}

				$elt.removeClass('material-input').removeClass('form-control');

				if (selectize.noDelete) {
					selectizeParams.plugins.push('no-delete');
				}

				// create selectize with provided parameters
				$elt.selectize(selectizeParams);

				// set default values on selectize
				if (selectize.defaultValues) {

					var defaultValues = selectize.defaultValues instanceof Backbone.Collection ? selectize.defaultValues.toJSON() : _.toArray(selectize.defaultValues);
					var valuesToSet = defaultValues;

					if (!defaultValues instanceof Array || defaultValues[0] instanceof Object) {

						var valuesToSet = [];
						_.each(defaultValues, function (item, index) {
							valuesToSet.push(item[selectize.valueField]);
						});
					}

					try {
						$elt[0].selectize.setValue(valuesToSet, true);
					} catch (e) {
						console.log(e);
					}
				}

				// if there is no connection available - disable selectize
				if (!App.isOnline && !$elt.hasClass('offline-selectize')) {
					$elt[0].selectize.lock();
				}
			});
		},

		/**
   * @desc list of precreated selectize render display
   * @param {type} label
   * @returns {undefined}
   */
		defaultSelectizeRender: function (label) {

			var _this = this;

			var renderFct = function () {};

			switch (label) {

				case 'third':

					renderFct = {

						option: function (data, escape) {

							if (data) {

								if (data.relationType === 'corporation') {
									var nameToRender = escape(data.name);
								} else if (data.fullName) {
									var nameToRender = escape(data.fullName);
								} else {
									var nameToRender = data.name ? escape(data.name) : '';
								}
								var avatar = '';
								if (data.avatar) {

									switch (data.avatar.type) {

										case 'initials':

											var size = 'data-selectable-big';
											var avatar = '<div class="avatar-initials initials-' + data.avatar.class + ' img-size img-size-small">' + data.avatar.value + '</div>';

											break;

										case 'twitteruserpic':

											var size = 'data-selectable-small';
											var avatar = '<div class="avatar-img img-size img-size-small"><img src="' + data.avatar.value + '"></div>';

											break;

										case 'picture':

											var size = 'data-selectable-small';
											var avatar = '<div class="avatar-img img-size img-size-small"><img src="' + App.fileUrl + "/" + data.avatar.value + '"></div>';

											break;
									}

									return '<div ' + size + '>' + avatar + '<span>' + nameToRender + '</span>' + '</div>';
								} else {
									return '<div>' + '<span>' + nameToRender + '</span>' + '</div>';
								}
							}
						}
					};

					break;

				case 'staff':

					renderFct = {

						option: function (data, escape) {

							var avatar = '';

							if (data.avatar.type == 'picture') {
								var avatar = '<div class="avatar-img img-size img-size-small"><img src="' + App.fileUrl + data.avatar.value + '&cache=Y"></div>';
							} else {
								var avatar = '<div class="avatar-initials initials-' + data.avatar.class + ' img-size img-size-small">' + data.avatar.value + '</div>';
							}

							return '<div data-selectable-big>' + avatar + '<span>' + escape(data.fullName) + '</span>' + '</div>';
						}

					};

					break;

				case 'opportunity':

					renderFct = {

						option: function (data, escape) {

							return '<div data-selectable-big>' + '<span>' + escape(data.ident) + '</span>' + '</div>';
						},
						item: function (item, escape) {

							return '<div>' + '<span class="tradename ellipsis">' + $.trim(escape(item.value)) + ' - ' + escape($.trim(item.label)) + '</span>' + '</div>';
						}

					};

				case 'address':

					renderFct = {

						option: function (addr, escape) {

							var rendered = "<ul class='address-selectize'>";

							_.each(addr.toDisplay, function (part2display) {
								rendered = rendered + "<li>" + escape(part2display) + "</li>";
							});

							rendered = rendered + "</ul>";

							return rendered;
						}

					};

					break;

				case 'productWithPrice':
				case 'product':

					var selectype = label;

					renderFct = {

						option: function (item, escape) {

							var caption = item.name;
							var display = '';
							var avatar = '';

							if (item.defaultImage && item.defaultImage.file.public_path) {
								var size = selectype == 'product' ? 'small' : 'big';
								var avatar = '<div class="avatar-img img-size img-size-' + size + '"><img src="' + App.fileUrl + item.defaultImage.file.public_path + '&cache=Y"></div>';
							} else {
								var size = '';
							}

							//on row of type once just display itemName
							if (item.type && selectype != 'product') {

								var label = item.tradename;

								//get the right price
								if (item.hasPriceException === 'Y') {
									var price = _this.isTTC ? item.unitAmountTaxesInc : item.unitAmount;
								} else if (_this.rowindex) {
									var price = item.unitAmount;
								} else {
									var rc = item.prices[_this.modelToSave.get('rateCategory')];
									var price = rc.amount;
								}

								display = '<div style="display : inline-block; vertical-align : middle" >' + '<span class="tradename ellipsis">' + $.trim(escape(caption)) + '</span>' + (caption ? '<span class="caption ellipsis">' + escape($.trim(label)) + '</span>' : '') + '<div class="caption ellipsis">' + escape(_this.numberFormat.formatToDisplay(price)) + '</div>' + '</div>';
							} else {
								display = '<span class="tradename ellipsis">' + $.trim(escape(caption)) + '</span>';
							}

							if (size) {
								var linesize = size == 'small' ? 'data-selectable-big' : 'data-selectable-small';
							} else {
								var linesize = 'data-selectable-noimg';
							}

							display = '<div ' + linesize + '>' + avatar + display + '</div>';

							return display;
						},
						option_create: function (data, escape) {
							return '<div class="create">' + "<span class='uc-first'>" + App.polyglot.t("ref") + '</span>' + ' : ' + "<span class='bold'>" + data.input + "</span>" + '</div>';
						}

					};

					break;

				case 'model':

					renderFct = {

						option: function (item, escape) {

							var caption = item.ident;
							var label = item.subject;
							var price = item.formatted_totalAmount;

							return '<div>' + '<span class="tradename ellipsis">' + $.trim(escape(caption)) + '</span>' + (caption ? '<span class="caption ellipsis">' + escape($.trim(label)) + '</span>' : '') + '<div class="caption ellipsis">' + escape(price) + '</div>' + '</div>';
						}
					};

					break;

				case 'email':

					renderFct = {

						option: function (item, escape) {

							var caption = item.name;
							var label = item.email;

							var avatar = '';

							if (item.avatar) {

								switch (item.avatar.type) {

									case 'initials':

										var size = 'data-selectable-big';
										var avatar = '<div class="avatar-initials initials-' + item.avatar.class + ' img-size img-size-small">' + item.avatar.value + '</div>';

										break;

									case 'twitteruserpic':

										var size = 'data-selectable-small';
										var avatar = '<div class="avatar-img img-size img-size-small"><img src="' + item.avatar.value + '"></div>';

										break;

									case 'picture':

										var size = 'data-selectable-small';
										var avatar = '<div class="avatar-img img-size img-size-small"><img src="' + App.fileUrl + "/" + item.avatar.value + '"></div>';

										break;
								}
							}

							return '<div>' + avatar + '<div style="font-size:14px;line-height:initial;" class="color-blue caption ellipsis">' + escape(item.name) + '</div>' + '<div style="font-size:13px;line-height:initial;" class="color-grey-blue caption ellipsis">' + escape(item.email) + '</div>' + '</div>';
						}

					};

					break;

				case "accounting":

					renderFct = {

						option: function (item, escape) {
							return '<div>' + '<span class="tradename ellipsis">' + $.trim(escape(item.code)) + ' - ' + escape($.trim(item.label)) + '</span>' + '</div>';
						}

					};
					break;

				case 'ticket':

					renderFct = {

						option: function (item, escape) {
							return '<div>' + '<span class="tradename ellipsis">' + "#" + $.trim(escape(item.ident)) + ' - ' + escape($.trim(item.subject)) + '</span>' + '</div>';
						},

						item: function (item, escape) {
							return '<div>' + '<span class="tradename ellipsis">' + "#" + $.trim(escape(item.ident)) + '</span>' + '</div>';
						}

					};

					break;

				case 'document':

					renderFct = {

						option: function (item, escape) {
							return '<div>' + '<div class="tradename ellipsis">' + $.trim(escape(item.ident)) + '</div> ' + "<div>" + Utils.capitalizeFirstLetter(App.polyglot.t("for")) + " " + escape($.trim(item.thirdName)) + '</div>' + '</div>';
						},

						item: function (item, escape) {
							return '<div>' + '<span class="tradename ellipsis">' + $.trim(escape(item.ident)) + '</span>' + '</div>';
						}

					};

			}

			return renderFct;
		},

		/**
   * @desc return pre-defined function for load resource on a selectize, depending of linkedtype
   * @param {type} linkedtype
   * @returns {undefined}
   */
		getSelectizeLoader: function (linkedtype) {

			switch (linkedtype) {

				case 'third':
				case 'client':

					var loadFunction = _.bind(function (query, callback) {

						var _this = this;

						return new Promise(function (resolve, reject) {

							_this.thirds.fetch({

								method: 'POST',
								data: {
									search: {
										contains: query
									}
								}

							}, true).then(function () {

								var resultArray = _.toArray(_this.thirds.toJSON());

								callback(resultArray);
								resolve(_this.thirds);
							});
						});
					}, this);

					break;

				case 'prospect':

					var loadFunction = _.bind(function (query, callback) {

						var _this = this;

						return new Promise(function (resolve, reject) {

							_this.prospects.fetch({

								method: 'POST',
								data: {
									search: {
										contains: query
									}
								}

							}, true).then(function () {

								var resultArray = _.toArray(_this.prospects.toJSON());

								callback(resultArray);
								resolve(_this.prospects);
							});
						});
					}, this);

					break;

				case 'opportunity':

					var loadFunction = _.bind(function (query, callback) {

						var _this = this;

						return new Promise(function (resolve, reject) {

							_this.opportunities.fetch({

								method: 'POST',
								data: {
									search: {
										ident: query
									}
								}

							}, true).then(function () {

								var resultArray = _.toArray(_this.opportunities.toJSON());

								callback(resultArray);
								resolve(_this.opportunities);
							});
						});
					}, this);

					break;

			}

			return loadFunction;
		},

		/**
   *
   * @returns {String}
   */
		formActionSetLoader: function () {

			var faWrapper = $('<div></div>');
			var fa = $(this.fa_loader);
			faWrapper.append(fa);

			return '<div class="vertical-center inprogress-mask">' + faWrapper.html() + '</div>';
		},

		/**
   *
   * @returns {undefined}
   */
		destroy: function () {

			var _this = this;

			_.each(App.view.subviews, function (view, index, list) {

				var subView = App.view.subviews.shift();

				//we keep same header for every views
				if (subView.viewName !== 'header') {
					subView.remove();
				} else {
					subView.undelegateEvents();
				}
			});

			_.each(App.view.toggleableViews, function (view, index, list) {
				var toggleableView = App.view.toggleableViews.shift();
				toggleableView.remove();
			});

			//remove Parent view
			App.view.remove();
		},

		destroyObjects: function () {

			delete this.renderPromises;

			//unbind header events
			App.header.$el.off();

			//remove TinyMCE intances
			TinyMCE.remove();

			//remove childs views and events
			_.each(App.view.subviews, function (view, vIndex, list) {

				if (!_.isUndefined(view.collections)) {

					_.each(view.collections, function (collection, index) {
						delete App.view.subviews[vIndex].collections[index];
					});
				}

				if (!_.isUndefined(view.models)) {

					_.each(view.models, function (model, index) {
						delete App.view.subviews[vIndex].models[index];
					});
				}

				if (!_.isUndefined(view.options.extraData)) {

					_.each(view.options.extraData, function (extraData, index) {
						delete App.view.subviews[vIndex].options.extraData[index];
					});
				}
			});

			delete App.view.customfields;
			delete App.view.shortP;
			delete App.view.shortenedPanel;

			if (!_.isUndefined(App.view.collections)) {

				_.each(App.view.collections, function (collection, index) {
					delete App.view.collections[index];
				});
			}

			if (!_.isUndefined(App.view.models)) {

				_.each(App.view.models, function (model, index) {
					delete App.view.models[index];
				});
			}

			if (App.view.options && !_.isUndefined(App.view.options.extraData)) {

				_.each(App.view.options.extraData, function (extraData, index) {
					delete App.view.options.extraData[index];
				});
			}
		},

		/**
   *
   * @param {type} options
   * @returns {undefined}
   */
		initEffect: function (options, target, context) {

			options = !options ? {} : options;
			var _this = context; //current row

			var callback = function ($currentTarget) {

				$currentTarget.unbind('touchend');

				// get model
				var model = !options || !options.getModel ? _this.collection.get($(this).data('id')) : options.getModel.call(_this, $currentTarget);

				// get the selected attr and set it as the title as the popup
				var modelName = !options.modelName ? 'name' : options.modelName;
				var popupTitle = !options.title ? model.get(modelName) ? model.get(modelName) : model.get(modelName) : options.title;
				var buttons = [];

				var checkCanWrite = function (options) {

					var canWrite = true;

					// if canWrite is set, we check if attr and val is equals to the model
					if (options.canWrite) {
						_.each(options.canWrite, function (row) {
							_.each(row, function (modelVal, modelAttr) {
								if (canWrite) {
									canWrite = model.get(modelAttr) == modelVal;
								}
							});
						});
					}

					return canWrite;
				};

				var canWrite = checkCanWrite(options);

				if (options.onModify && _this.canEdit) {

					buttons.push({
						title: App.polyglot.t("update"),
						classes: "btn button-with-network btn-primary model-modify",
						onclick: function () {

							//onModify is a function or an url
							if (_.isFunction(options.onModify)) {
								options.onModify.call(_this, model, $currentTarget);
							} else {
								var url = URLBuilder([options.onModify, model.get('id')], false);
								_this.goToForm.call(_this, url);
							}
						}
					});
				}

				if (options.onRemove && _this.canDelete) {

					buttons.push({
						title: App.polyglot.t("delete"),
						classes: "btn model-remove btn-secondary button-with-network",
						onclick: function () {

							if (_.isFunction(options.onRemove)) {

								var callback = function () {
									options.onRemove.call(_this, model, $currentTarget);
								};
							} else {
								var callback = _this.render.bind(_this, { add: true }); //executed after suppression
							}

							options.isDeleteAvailable = !options.isDeleteAvailable ? function () {
								return true;
							} : options.isDeleteAvailable; //will check if delete is  available

							if (options.isDeleteAvailable && options.destroyOptions && _.isFunction(options.destroyOptions)) {
								options.destroyOptions = options.destroyOptions.call(_this, model);
							}

							if (options.isDeleteAvailable(model)) {
								// options.removeMsg = ! _.isFunction(options.removeMsg) ? options.removeMsg : options.removeMsg.call(_this, model, $currentTarget);
								options.removeMsg = 'ntm';
								_this.removeConfirm.call(_this, model, popupTitle, $currentTarget, options.removeMsg, callback, options.destroyOptions);
							}
						}
					});
				}

				if (options.buttons) {

					var cloned = $.extend(true, [], options.buttons);

					_.each(cloned, function (obj) {

						var canWrite = checkCanWrite(obj);

						if (canWrite) {
							obj.onclick = obj.onclick.bind(this, model, $currentTarget);
							buttons.push(obj);
						}
					});
				}

				if (_this.trueRoute === window.location.hash) {

					require(['views/core/popupAction'], function (PopupAction) {

						new PopupAction({
							id: options.popupid ? options.popupid : 'popup_' + model.get('id'),
							title: popupTitle,
							buttons: buttons
						});
					});
				}
			};

			$('.material-effect', target).each(function () {

				if ($(this).prop('tagName') === 'IFRAME') {
					var $target = $('body', $(this).contents());
				} else {
					var $target = $(this);
				}

				//bind function callback with  target dom object (this)
				initMaterialEffect($target, callback.bind(this));
			});
		},

		/**
   *
   * @returns {undefined}
   */
		isScrollable: function () {
			return this.$(".main-container").height() > $(window).height();
		},

		/**
   *
   * @returns {undefined}
   */
		goToPreviousPage: function () {
			window.history.back();
			return false;
		},

		/**
   *
   * @param {type} model
   * @param {type} url
   * @returns {undefined}
   */
		goToForm: function (url) {
			this.router.navigate(url, { trigger: true });
			return false;
		},

		/**
   *
   * @returns {undefined}
   */
		notifWriteDisabled: function () {

			if ($(".notif-writeDisabled").length < 1) {

				require(['views/core/toastNotification'], function (Notification) {

					var notifWriteDisabled = new Notification({
						id: 'toast-error',
						timeDuration: 3000,
						classes: 'notif-writeDisabled',
						content: App.polyglot.t('offlineWriteModeAlert')
					});
				});
			}
		},

		/**
   * @description add a simple var for the template and set value in a view attribute
   * @param {type} key
   * @param {type} value
   * @returns {undefined}
   */
		var4template: function (key, value) {

			var _this = this;

			this.options.extraData[key] = function () {

				return new Promise(function (resolve, reject) {
					resolve(value);
				});
			};
		},

		/**
   * @description init pull to refresh action for refresh the data when it's fired
   * @param {type} wrapper
   * @param {type} $scrollable
   * @param {type} viewType
   * @returns {undefined}
   */
		initPullToRefresh: function (wrapper, $scrollable, viewType) {

			var _this = this;

			if (_this.hammer && _.isFunction(_this.hammer.destroy)) {
				//prevent multi events
				_this.hammer.destroy();
			}

			$('#refreshMe').css('visibility', 'hidden');

			if (!$('#touchEventActions').length) {
				var refreshWrapper = '<div class="horizontal-center" id="touchEventActions"><div id="pull-to-refresh-wrapper"><span>' + _this.formActionSetLoader() + '</span></div></div>';
				$(wrapper).prepend(refreshWrapper);
			}

			/**
    * check ios version
   	 * /!\ pull to refresh not compatible for ios < 9.3
   */
			if (navigator.os == 'ios') {

				var iosVMatcher = new RegExp('OS ((\\d+_?){2,3})\\s', 'gi');
				var iosV = iosVMatcher.exec(navigator['userAgent']);
				iosV = iosV[1].split("_");
				iosV.length = 2;
				iosV = iosV.join('.');
			}

			if (navigator.os !== 'ios' || iosV >= 9.3) {

				this.hammer = new Hammer(wrapper, { threshold: 10, direction: Hammer.DIRECTION_VERTICAL, inputClass: Hammer.TouchInput });

				var panMax = 100;
				var pos;

				this.hammer.on('pandown panup', function (e) {

					if ($scrollable.scrollTop() <= 3 && $('.dropdown-active').length == 0) {
						//dont do it if a selectize is active

						var percent = 100 / 60 * e.deltaY;
						pos = 60 / 100 * 100 + percent - 50;
						pos = Math.min(panMax, pos);

						$('#touchEventActions').css('transform', 'translate3d(0, ' + pos + 'px, 0)');
						$scrollable.css('transform', 'translate3d(0, ' + (pos < 0 ? 0 : pos) + 'px, 0)');
						$('#wrapper-search').css('transform', 'translate3d(0, ' + (pos < 0 ? 0 : pos) + 'px, 0)');
						$('#listing-selectize').css('transform', 'translateY(' + (pos < 0 ? 0 : pos) + 'px)');
						$('#linked-container').css('transform', 'translateY(' + (pos < 0 ? 0 : pos) + 'px)');
					}
				});

				this.hammer.on('panstart', function (e) {

					//prevent scroll while paning
					if ($scrollable.scrollTop() <= 3) {
						$scrollable.css('overflow-y', 'hidden');
					}
				});

				this.hammer.on('panend', function (e) {

					if ($('#touchEventActions').length) {

						$scrollable.css('overflow-y', 'auto');

						var position = $('#touchEventActions').position().top;
						$scrollable.removeClass('disableScroll');

						$('#listing-selectize').removeAttr('style');

						if (position === 0) {

							_this.anchorPosition = 0;

							$('#touchEventActions').css('transform', 'translate3d(0, 0px, 0)');
							$scrollable.css('transform', 'translate3d(0, 0px, 0)');
							$('#wrapper-search').css('transform', 'translate3d(0, 0px, 0)');
							$('#linked-container').css('transform', 'translate3d(0, 0px, 0)');

							_this.hammer.destroy();

							$('#refreshMe').css({
								visibility: 'initial'
							});

							if (viewType === 'listing') {

								_this.collection.reset(); //tricks for backbone composite key bug

								if (_this.collection.pagination) {
									_this.collection.pagination.pagenum = 1;
									_this.collection.offsetOffline = 0;
								}

								_.each(_this.hammerEvents, function (hm) {
									hm.destroy();
								});

								_this.listing.renderOptions = {
									add: false
								};

								_this.render();
							} else {
								_this.render();
							}
						} else {
							$('#touchEventActions').css('transform', 'translate3d(0, 0px, 0)');
							$scrollable.css('transform', 'translate3d(0, 0px, 0)');
							$('#wrapper-search').css('transform', 'translate3d(0, 0px, 0)');
							$('#linked-container').css('transform', 'translate3d(0, 0px, 0)');
						}
					}
				});
			}
		},

		/**
   * @description add action item in action list (in right of header)
   * @returns {undefined}
   */
		populateActionList: function () {

			var _this = this;

			// in first clear action-list
			App.header.$.actionList.empty();

			_.each(this.actionList, function (actionItem, key) {

				switch (key) {

					case 'delete':

						if (_this.canDelete) {
							actionItem.liClass = 'deleteAction';
							App.header.$.actionList.append(_this.addActionItem(actionItem));
						}

						break;

					case 'modify':

						if (_this.canEdit) {
							actionItem.liClass = 'editAction';
							App.header.$.actionList.append(_this.addActionItem(actionItem));
						}

						break;

					default:
						App.header.$.actionList.append(_this.addActionItem(actionItem));
						break;

				}
			});

			$('#action-list').removeClass('hidden');
		},

		/**
   *
   * @param {string} link
   * @param {string} faClass
   * @param {string} liClass
   * @param {string} datas
   * @param {string} aClass
   * @returns {$}
   */
		addActionItem: function (actionItem) {

			if (actionItem.img) {
				var item = "<li class='pointer " + (actionItem.liClass ? actionItem.liClass : "") + " vertical-center'><a class='uc-first " + (actionItem.aClass ? actionItem.aClass : "") + "'>" + '<img src="' + Utils.loadFile(actionItem.img, 'images') + '" />' + "</a></li>";
			} else {
				var item = "<li class='pointer " + (actionItem.liClass ? actionItem.liClass : "") + " vertical-center'><a class='uc-first " + (actionItem.aClass ? actionItem.aClass : "") + "'>" + (actionItem.fa ? "<i class='fa " + actionItem.fa + "'></i>" : actionItem.label) + "</a></li>";
			}

			var $item = $(item);

			//add data-attributes
			if (actionItem.datas) {

				_.each(actionItem.datas, function (data, key) {
					$("a", $item).data(key, data);
				});
			}

			if (_.isFunction(actionItem.link)) {
				$("a", $item).on('click', actionItem.link.bind(this));
			} else {
				$("a", $item).prop('href', actionItem.link);
			}

			return $item;
		},

		/**
  *
   */
		renderHeader: function () {

			var _this = this;
			_this.processBackRouting();
		},

		/**
  *
   */
		processBackRouting: function () {

			var _this = this;

			/**
   * @description function when view have a previous link defined and when we click on back arrow or press back button
    */
			var previousLinkCallback = function (e) {

				_this.onBackCallback().then(function () {

					if (_.isFunction(_this.previousLink)) {
						_this.previousLink();
					} else {
						_this.router.navigate(_this.previousLink, { trigger: true, replace: true });
					}
				}, function () {
					_this.renderHeader();
				});
				return false;
			};

			/**
    * @description function when view have not a previous link defined and when we click on back arrow or press back button
    */
			var goBackCallback = function () {

				_this.onBackCallback().then(function () {
					_this.goToPreviousPage();
				}, function () {
					//rebind event
					_this.renderHeader();
				});

				return false;
			};

			if (this.previousLink) {

				$(".return").unbind("click");
				$(".return").one("click", previousLinkCallback);
			} else {

				$(".return").unbind("click");
				$(".return").one("click", goBackCallback);
			}
		},

		/**
  *
   * @param actions
   * @param target
   * @param context
   * @param $rows
   */
		initSwipeEffect: function (actions, target, context, $rows) {

			var _this = this;

			this.hammerEvents = [];

			$rows = !$rows ? $('.row-listing', this.$el) : $rows;

			$rows.each(function () {

				$(this).append('<div class="swipeActions vertical-center horizontal-center"></div>');

				//get row jq obj
				var $currentTarget = $(this);

				//get model
				var model = !actions || !actions.getModel ? _this.collection.get($(this).data('id')) : actions.getModel.call(_this, $currentTarget);
				var modelName = !actions.modelName ? 'name' : actions.modelName;
				var popupTitle = !actions.title ? model.get(modelName) ? model.get(modelName) : model.get(modelName) : actions.title;

				//if we need a special check for each row in addition of global privilege
				if (actions.canDelete) {
					var canRemove = actions.canDelete(model) && _this.parentView.canDelete;
				} else {
					var canRemove = _this.parentView.canDelete;
				}

				//if we need a special check for each row in addition of global privilege
				if (actions.canEdit) {
					var canEdit = actions.canEdit(model) && _this.parentView.canEdit;
				} else {
					var canEdit = _this.parentView.canEdit;
				}

				if (actions.onModify && canEdit) {

					$('.swipeActions', $currentTarget).append('<i class="fa fa-pencil editAction button-with-network"></i>');

					$('.editAction', $(this)).on('click', function () {

						if (App.isOnline) {

							//onModify is a function or an url
							if (_.isFunction(actions.onModify)) {
								actions.onModify.call(_this, model, $currentTarget);
							} else {
								var url = URLBuilder([actions.onModify, model.get('id')], false);
								_this.goToForm.call(_this, url);
							}
						} else {}

						return false;
					});
				}

				if (actions.onRemove && canRemove) {

					$('.swipeActions', $currentTarget).append('<i class="fa fa-trash deleteAction button-with-network"></i>');

					$('.deleteAction', $(this)).on('click', function () {

						if (App.isOnline) {

							if (_.isFunction(actions.onRemove)) {

								var callback = function () {
									actions.onRemove.call(_this, model, $currentTarget);
								};
							} else {
								var callback = _this.render.bind(_this, { add: true }); //executed after suppression
							}

							actions.isDeleteAvailable = !actions.isDeleteAvailable ? function () {
								return true;
							} : actions.isDeleteAvailable; //will check if delete is  available

							if (actions.isDeleteAvailable && actions.destroyOptions && _.isFunction(actions.destroyOptions)) {
								actions.destroyOptions = actions.destroyOptions.call(_this, model);
							}

							if (actions.isDeleteAvailable(model)) {

								if (actions.removeFunction) {
									actions.removeFunction.call(_this, model, $currentTarget);
								} else {
									actions.removeMsg = !_.isFunction(actions.removeMsg) ? actions.removeMsg : actions.removeMsg.call(_this, model, $currentTarget);
									_this.removeConfirm.call(_this, model, popupTitle, $currentTarget, actions.removeMsg, callback, actions.destroyOptions);
								}
							}
						}

						return false;
					});
				}

				/**
     * Add custom actions
     */
				if (actions.more) {

					_.each(actions.more, function (action) {

						var check = _.isFunction(action.check) ? action.check(model) : true;

						if (check) {

							$('.swipeActions', $currentTarget).append('<i class="fa ' + action.fa + ' ' + action.class + '"></i>');
							$('.' + action.class, $currentTarget).on('click', function () {
								action.callback(model, $currentTarget);
								return false;
							});
						}
					});
				}

				if (canEdit || canRemove || actions.more) {

					var rowsListingHammer = new Hammer.Manager(this, { inputClass: Hammer.TouchInput });
					var panH = new Hammer.Pan({ threshold: 10, direction: 6 });

					rowsListingHammer.add(panH);
					_this.hammerEvents.push(rowsListingHammer);

					var pos;

					var panMin = actions.maxSwipe ? -actions.maxSwipe : -200;
					var row = this;
					var $swipeWrapper = $(row).find('.swipeActions'); //wrapper wich contains actions
					var $el = $(row).children(); //row to translate

					rowsListingHammer.on('panstart', function (e) {
						//					$(this).append('<div id="touch-overlay" style="position :fixed; top:0px; left:0px; width:100%; height:100%;" id="touch-overlay"></div>');
					});

					rowsListingHammer.on('panleft panright', function (e) {

						var percent = 100 / 60 * e.deltaX;
						pos = 60 / 100 * 100 + percent;
						pos = Math.max(pos, panMin);

						if (pos > 0) {
							pos = 0;
						}

						$el.css('transform', 'translate3d(' + pos + 'px,  0, 0)');
						$swipeWrapper.css('transform', 'translate3d(' + pos + 'px,  0, 0)');
					});

					rowsListingHammer.on('panend', function (e) {

						if (pos >= panMin / 1.80) {
							$el.css('transform', 'translate3d(0,  0, 0)');
						} else {
							$el.css('transform', 'translate3d(' + panMin + 'px,  0, 0)');
						}
					});
				}
			});
		}

	});

	return View;
});
