/**
 *	@desc support Model , extend Core Model 
 */
define('models/singles/ticketConversation',['models/core/model', 'app', 'tools/ajax'], function (Model, App, Ajax) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			draft: 'N',
			message: ''
		},

		storeName: 'supportconversations',
		name: 'conversation',
		urlRoot: '/rest/support/conversation',

		/**
   * 
   * @param {type} attributes
   * @returns {ticketConversation_L9.ticketConversationAnonym$1.validate.validationErrors}
   */
		validate: function (attributes) {

			var required = {
				'step': false,
				'assignedTo': false,
				'cc': false,
				'bcc': false,
				'message': true
			};

			var _this = this;

			var validationErrors = {
				response: {}
			};

			_.each(required, function (attr, nameAttr) {

				if (!attributes[nameAttr] && required[nameAttr]) {
					validationErrors['response'][nameAttr] = _this.computeError({ name: nameAttr, type: 'required' });
				}
			});

			if (_.toArray(validationErrors['response']).length > 0) {
				return validationErrors;
			}
		}

	});
});
