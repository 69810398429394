/**
 *	@desc Model CreditNote, extend Document Model 
 */
define('models/singles/proforma',['models/singles/document'], function (Document) {

	'use strict';

	return Document.extend({

		defaults: _.extend({

			id: 0,
			doctype: 'proforma',
			ident: '',
			formattedDocType: 'Proforma',
			thirdName: '',
			availableSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled']

		}, Document.prototype.defaults),

		urlRoot: '/rest/document/proforma',

		isEditable: function () {
			return !("advanced" === this.get('step') || "invoiced" === this.get('step') || "accepted" === this.get('step'));
		},

		isStepAllowed: function () {
			return true;
		}

	});
});
