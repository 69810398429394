define('views/core/Popup',['jquery', 'views/core/view', 'tools/ajax', 'big', 'libs/bootstrap'], function ($, View, Ajax, Big) {

	'use strict';

	return View.extend({

		events: {
			'show.bs.modal': 'startup',
			'hidden.bs.modal': 'destroy',
			"click .btn-submit": "fireSubmit"

		},

		id: '',
		className: "modal fade",
		tagName: 'div',
		title: '',
		buttons: [],
		width: '',
		height: '',
		data: {},
		callback: '',
		closeAllBefore: true,
		template: "templates/widgets/popupSkeleton.twig",
		models: {},
		collections: {},
		containerBtn: '.modal-footer',

		constructor: function () {

			this.buttons = [];
			this.defaultBtns = [{ title: "Valider", classes: "btn btn-primary btn-submit submit-form", type: 'submit', hideOnClick: false }, { title: "Annuler", classes: "btn btn-secondary btn-cancel" }];

			View.apply(this, arguments);
		},

		/**
   * 
   * @param {type} options
   * @returns {undefined}
   */
		initialize: function (options) {

			var _this = this;
			this.id = options.id;
			this.title = options.title;
			this.buttons = options.buttons ? options.buttons : [];
			this.mergeBtns = _.isUndefined(options.mergeBtns) ? true : options.mergeBtns;
			this.data = options.data;
			this.callback = options.callback;
			this.onStart = options.onStart;
			this.contentTemplate = options.contentTemplate;
			this.customEvents = options.customEvents; //function who bind extra events on popup
			this.content = options.content;

			if (options.collections) {
				this.collections = options.collections;
			}

			_this.$el.on('show.bs.modal', this.reposition.bind(this));

			$(window).on('resize', function () {
				_this.reposition.bind(_this);
			});

			this.render({
				saveAfterFetch: false
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		renderCallback: function () {

			var _this = this;

			this.$el.find('.popup-content').html('<div class="popup-loader">' + this.fa_loader + '</div>');

			this.$el.attr('tabindex', "-1");
			this.$el.attr('role', "dialog");
			this.$el.attr('aria-labelledby', "myModalLabel");
			this.$el.attr('aria-hidden', "true");

			if (this.title !== '') {
				this.$el.find('.modal-title').html(this.title);
			} else {
				this.$el.find('.modal-header').remove();
			}

			$('body').append(this.$el);

			$('#' + this.id).modal({
				keyboard: true,
				backdrop: true
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		initBtns: function () {

			var _this = this;

			if (this.mergeBtns) {
				this.buttons = _.extend(this.buttons, this.defaultBtns);
			}

			if (this.buttons) {

				_this.$el.find(_this.containerBtn).html("");

				var colBtn = new Big(12).div(this.buttons.length).round().toString();
				var colXsBtn = this.buttons.length > 3 ? 12 : colBtn;
				var _this = this;

				for (var i in this.buttons) {

					var btn = this.buttons[i];
					var $btn = $('<button class="btn ellipsis"></button>');
					btn.hideOnClick = _.isUndefined(btn.hideOnClick) ? true : btn.hideOnClick;

					$btn.html("<span class='uc-first'>" + btn.title + '</span>').addClass(btn.classes);

					if ($btn.hasClass('button-with-network')) {

						$btn.on('click', function (e) {

							if (!_this.checkButton.call(_this, e)) {
								_this.hide();
							}
						});
					}

					$btn.on('click', btn.onclick);

					if (btn.fa) {
						$btn.prepend("<i class='fa " + btn.fa + "'></i>");
					}

					if (btn.hideOnClick) {
						$btn.on('click', _this.hide.bind(_this));
					}

					if (btn.type) {
						$btn.prop('type', btn.type);
					}

					var wrapper = $('<div class="wrapper-btn col-xs-12 col-md-12 col-lg-12"></div>');
					_this.$el.find(_this.containerBtn).append(wrapper);
					wrapper.append($btn);
				}
			}
		},

		/**
   * 
   * @param {type} buttons
   * @returns {undefined}
   */
		addButtons: function (options) {
			this.buttons = options.buttons;
			this.initBtns();
		},

		/**
   * 
   * @param {type} options
   * @returns {undefined}
   */
		setContent: function () {
			$('.popup-content', this.$el).html('');
			$(".popup-content", this.$el).append(this.content);
		},

		/**
   * @description when modal show is fired, execute onStart function
   * @returns {undefined}
   */
		startup: function () {

			var _this = this;

			var startupPromise = new Promise(function (resolve, reject) {

				if (_this.contentTemplate) {

					//get content template 
					Ajax({
						url: _this.contentTemplate,
						success: function (template) {

							//compile template 
							var compiledTemplate = _.template(template);
							$(".popup-content", _this.$el).html(compiledTemplate(_this.data));

							if (!_.isUndefined(_this.onStart) && _.isFunction(_this.onStart)) {
								_this.onStart();
							}

							resolve();
						}
					});
				} else {
					_this.setContent();
					resolve();
				}
			});

			startupPromise.then(function () {

				_this.initBtns();

				//bind extra events
				if (_.isFunction(_this.customEvents)) {
					_this.customEvents.call(_this);
				}
			});
		},

		/**
   * 
   */
		reposition: function () {

			var dialog = $('.modal-dialog', this.$el);
			this.$el.css('display', 'block');

			// Dividing by two centers the modal exactly, but dividing by three 
			// or four works better for larger screens.
			//			dialog.css("margin-top", Math.max(0, ($(window).height() - dialog.height()) / 4));
		},

		/**
   * 
   * @returns {undefined}
   */
		destroy: function () {

			if (!_.isUndefined(this.callback) && _.isFunction(this.callback)) {
				this.callback();
			}

			var _this = this;

			//remove childs views and events
			_.each(this.subviews, function (view, index, list) {

				var myview = _this.subviews.shift();

				if (!_.isUndefined(myview.collections)) {

					_.each(myview.collections, function (collection, index) {
						delete myview.collections[index];
					});
				}

				if (!_.isUndefined(myview.models)) {

					_.each(myview.models, function (model, index) {
						delete myview.models[index];
					});
				}

				myview.remove();
			});

			this.remove();
		},

		/**
   * 
   * @returns {undefined}
   */
		hide: function () {
			this.$el.modal('hide');
		},

		/**
   * @description fire submit event of form view
   * @returns {undefined}
   */
		fireSubmit: function (e) {

			if (this.form) {
				this.form.$el.trigger('submit', [this.$el]);
			}
		}

	});
});
