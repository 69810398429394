/**
 * @description DocumentFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/purchaseFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/suppliers', 'models/collections/smarttags'], function (_, FilterPanel, App, Utils, ListingFilters, Thirds, Smarttags) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			this.doctype = listName;
			this.thirds = new Thirds();
			this.smartTags = new Smarttags();
			this.smartTags.fetchOneTime = true;
			this.smartTags.XHRData = {
				search: Smarttags.buildFilterByLinked(this.doctype)
			};
			this.smartTags.url = '/rest/smarttags/search';

			switch (this.doctype) {

				case "purInvoice":

					this.title = App.polyglot.t('supplierInvoices');
					var linkedNameForSavedSearch = "purdocinvoices";
					break;

				case "purOrder":

					this.title = App.polyglot.t('orders');
					var linkedNameForSavedSearch = "purdocorders";
					break;

				case "purDelivery":

					this.title = App.polyglot.t('deliveries');
					var linkedNameForSavedSearch = "purdocdeliveries";
					break;

				case "purCreditNote":

					this.title = App.polyglot.t('supplierCreditNotes');
					var linkedNameForSavedSearch = "purdoccreditnotes";
					break;
			}

			this.collections[this.smartTags.name] = this.smartTags;

			FilterPanel.prototype.initialize.apply(this, [router, listName, linkedNameForSavedSearch]);
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;

			var filterList = {};

			filterList = _.extend(filterList, {
				steps: {
					label: App.polyglot.t('status'),
					field: {
						type: 'select',
						isMultiple: true,
						name: 'steps',
						offlineSearchField: 'step',
						values: App[this.doctype + 'Steps'],
						allValue: true
					}
				},
				supplier: {
					label: App.polyglot.t('supplier'),
					field: {
						type: 'input',
						name: 'thirds',
						offlineSearchField: 'thirdid',
						classes: "offline-selectize",
						isMultiple: true,
						selectize: {
							domElt: 'input[name="filter[thirds]"]',
							placeholder: App.polyglot.t('name'),
							create: false,
							valueField: 'id',
							labelField: 'name',
							searchField: ['name', 'forename'],
							maxItems: '1',
							maxOptions: '1',
							loadThrottle: 600,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.thirds.fetch({

										method: 'POST',
										data: { search: { contains: query } }

									}, true).then(function () {
										resolve(_this.thirds);
									});
								});
							},
							render: 'third'
						}
					}
				},
				smarttags: {
					label: App.polyglot.t('smarttags'),
					field: {
						type: 'input',
						name: 'tags',
						offlineSearchField: 'smarttags',
						classes: "smarttags-selectize",
						isMultiple: false,
						selectize: {
							domElt: 'input[name="filter[tags]"]',
							placeholder: App.polyglot.t('smarttags'),
							create: false,
							valueField: 'word',
							labelField: 'word',
							searchField: ['word'],
							loadThrottle: 600,
							options: this.smartTags,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.smartTags.fetch({

										method: 'POST',
										data: { search: _.extend({ contains: query }, _this.smartTags.XHRData.search) }

									}, true).then(function () {
										resolve(_this.smartTags);
									});
								});
							}
						}

					}
				}

			});

			this.filters = filterList;
			return this.filters;
		}

	});
});
