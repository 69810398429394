/**
 * @desc Sellsy Support Request Form, extend Page View
 * Child View : Core Form
 */
define('views/support/supportRequest',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/ticket', 'app', 'tinyMCE', 'tools/buildURL'], function (Backbone, _, Page, Form, Ticket, App, TinyMCE, URLBuilder) {

	'use strict';

	return Page.extend({

		events: {},

		title: App.polyglot.t('Feedback'),
		form: {},
		template: "templates/support/supportRequest.twig",
		toastMessages: {
			created: App.polyglot.t('supportRequestSaved')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.modelToSave = new Ticket();
			this.models[this.modelToSave.name] = this.modelToSave;

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			Page.prototype.afterRender.apply(_this, arguments);

			App.header.$.brandMenu.removeClass('hidden');

			TinyMCE.init({
				selector: 'textarea[name="ticket[message]"]',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize",
				menubar: false,
				statusbar: false
			});
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			var validationErrors = this.modelToSave.isValidRequest(dataForm.ticket);

			if (!validationErrors) {

				this.modelToSave.set(dataForm.ticket);

				var toastMsg = this.toastMessages.created;

				this.modelToSave.sendSupportRequest().then(function () {
					_this.form.trigger('successful', toastMsg);
					_this.router.navigate(URLBuilder([""]), { trigger: true });
				}, function () {
					// re activate submit button
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(validationErrors, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
