/**
 *  @description Error View extend Page View
 */
define('views/core/error',['jquery', 'app', 'views/core/page', 'views/core/overview'], function ($, App, Page, Overview) {

	return Page.extend({

		template: '/templates/error',
		className: 'container-fluid page',
		title: App.polyglot.t('anErrorOccured'),
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);
			this.overview = new Overview({ parentView: this }, this.router);
			this.subviews.push(this.overview);

			this.listenTo(this.overview, 'renderend', this.afterRender);
		}

	});
});
