/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/expense',['models/core/model', 'tools/ajax', 'db/easy', 'tools/core/utils', 'app'], function (Model, Ajax, DB, Utils, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		urlRoot: '/rest/expense/',
		name: 'expense',
		storeName: 'expense',

		/**
   * 
   * @param {type} attributes
   * @returns {timetracking_L9.timetrackingAnonym$1.validate.validationErrors}
   */
		validate: function (attributes) {

			var _this = this;

			var required = {
				'ident': true,
				'thirdid': true,
				'displayedDate': true,
				'currency': true,
				//rows
				'name': true,
				'notes': false,
				'unitAmount': true,
				'taxid': App.hasVat ? true : false
			};

			var validationErrors = { expense: {} };

			if (required.displayedDate && !attributes.displayedDate) {
				validationErrors['expense']['displayedDate'] = this.computeError({ name: 'displayedDate', type: 'invalid' });
			} else {
				this.attributes.displayedDate_timestamp = Utils.SellsyMoment(attributes.displayedDate, App.HTMLDateFormat, true).unix();
			}

			if (required.thirdid && !attributes.thirdid) {
				validationErrors['expense']['thirdid'] = this.computeError({ name: 'supplier', type: 'required' });
			}

			if (required.ident && !attributes.ident) {
				validationErrors['expense']['ident'] = this.computeError({ name: 'ident', type: 'required' });
			}

			if (required.currency && !attributes.currency) {
				validationErrors['expense']['currency'] = this.computeError({ name: 'currency', type: 'required' });
			}

			$.each(attributes.rows, function (index, row) {

				if (required.name && !row.name) {

					if (!validationErrors[index]) {
						validationErrors[index] = {};
					}

					validationErrors[index]['name'] = _this.computeError({ name: 'name', type: 'required' });
				}

				if (required.notes && !row.notes) {

					if (!validationErrors[index]) {
						validationErrors[index] = {};
					}

					validationErrors[index]['notes'] = _this.computeError({ name: 'notes', type: 'required' });
				}

				if (required.unitAmount && !row.unitAmount) {

					if (!validationErrors[index]) {
						validationErrors[index] = {};
					}

					validationErrors[index]['unitAmount'] = _this.computeError({ name: 'unitAmount', type: 'required' });
				}

				if (required.taxid && !row.taxid) {

					if (!validationErrors[index]) {
						validationErrors[index] = {};
					}

					validationErrors[index]['taxid'] = _this.computeError({ name: 'taxid', type: 'required' });
				}
			});

			if (_.toArray(validationErrors.expense).length > 0 || _.toArray(validationErrors).length > 1) {
				return validationErrors;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		sendJustif: function (form_data) {

			var _this = this;

			return new Promise(function (resolve, reject) {
				Ajax({
					'method': 'POST',
					contentType: false,
					processData: false,
					url: _this.urlRoot + _this.id + '/addjustif',
					data: form_data,
					success: function () {
						resolve();
					},
					error: function () {
						reject();
					}
				});
			});
		}

	});
});
