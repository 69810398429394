/**
 * @desc People router, extend Core Router
 */
define('routers/support',['backbone', 'underscore', 'routers/core/router', 'app'], function (Backbone, _, CoreRouter, App) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "support",
		plugin: 'support',

		checkOnline: {
			form: true
		},

		routes: {
			'ticket/list(/:thirdid)': 'listing',
			'ticket/form(/)(:thirdid)': 'form',
			'ticket/overview/:id': 'overview'
		},

		/**
   * 
   */
		checkBefore: {

			form: function () {
				return App.permission.can("support_createticket");
			}

		},

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		listing: function (thirdid, thirdname) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/support/list'], function (require) {

					var SupportView = require('views/support/list');
					var view = new SupportView(_this, thirdid, thirdname);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		form: function (thirdid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/support/form'], function (require) {

					var SupportView = require('views/support/form');
					var view = new SupportView(_this, thirdid);
					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} id
   * @returns {undefined}
   */
		overview: function (id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/support/overview'], function (require) {

					var SupportView = require('views/support/overview');
					var view = new SupportView(_this, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		}

	});
});
