/**
* @desc AddressForm, extend Page View
* Child View : Core Form
*/
define('views/address/formAddress',['backbone', 'underscore', 'views/core/page', 'models/singles/third', 'views/core/form', 'models/singles/address', 'models/singles/people', 'tools/buildURL', 'tools/core/utils', 'app'], function (Backbone, _, Page, Third, Form, Address, People, URLBuilder, Utils, App) {

	'use strict';

	var FormAddress = Page.extend({

		events: {},

		title: App.polyglot.t('addAddress'),
		form: {},
		toastMessages: {
			addressSaved: App.polyglot.t('addrSaved'),
			addressUpdated: App.polyglot.t('addrModified')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} linkedId
   */
		initialize: function (router, linkedId, addressId, relationtype, comeFrom) {

			this.relationType = relationtype;
			this.comeFrom = comeFrom;
			var _this = this;

			Page.prototype.initialize.apply(this, arguments);

			this.options.extraData = {

				countriesList: function () {

					return new Promise(function (resolve, reject) {
						resolve(Utils.getCountriesList());
					});
				}

			};

			//we take address throught the linked for know if adress is main/mainDeliv
			var promiseLinked4Address = new Promise(_.bind(function (linkedFetched, reject) {

				var address = '';

				switch (relationtype) {

					case 'prospect':
					case 'supplier':
					case 'third':

						this.linkedType = 'third';
						_this.third = new Third({ id: linkedId });

						_this.third.fetchLocal().then(function () {

							if (addressId) {
								address = _this.third.get('addresses')[addressId + '_0'];
								linkedFetched(address);
							} else {
								linkedFetched();
							}
						}, function (err) {
							console.log(err);
						});

						break;

					case 'corp':

						this.linkedType = relationtype;

						if (addressId) {

							address = App.currentCorp.get('addresses')[addressId];
							linkedFetched(address);
						} else {

							linkedFetched();
						}

						break;

					case 'people':

						this.linkedType = relationtype;
						var people = new People({ id: linkedId });

						people.fetch().then(function () {

							if (addressId) {

								address = people.get('addresses')[addressId + '_0'];
								linkedFetched(address);
							} else {
								linkedFetched();
							}
						});

						break;
				}
			}, this));

			promiseLinked4Address.then(_.bind(function (address) {

				this.modelToSave = new Address(address);
				this.modelToSave.set({ linkedtype: this.linkedType, linkedid: linkedId });

				this.options.extraData.address = function () {

					return new Promise(function (resolve, reject) {
						resolve(_this.modelToSave.toJSON());
					});
				};

				if (!this.modelToSave.isNew()) {
					this.title = App.polyglot.t('modifyAddress');
				}
				this.subviews = [];
				this.template = "templates/third/formAddress.twig";
				this.router = router;
				this.form = new Form({ parentView: this });

				this.subviews.push(this.form);

				this.listenTo(this.form, 'dataSend', this.formAction);

				this.render();
			}, this), function (err) {
				console.log(err);
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;
			Page.prototype.afterRender.apply(this, arguments);

			FormAddress.initAddressSelectize.call(this);
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			_.each(dataForm.address, function (attribute, index, model) {
				var selector = '[name = "' + _this.modelToSave.name + '[' + index + ']"]';
				var $selector = $(selector);
				$selector.data("oldvalue", attribute);
			});

			this.modelToSave.set(dataForm.address);

			//take country name in DOM
			var $countryName = $("select[name='address[countrycode]'] option[value='" + dataForm.address['countrycode'] + "']");

			this.modelToSave.set({ countryname: $countryName.text() });
			this.modelToSave.set({ relationType: this.relationType });

			//unset isMain/isMainDeliv = 'N'
			if (this.modelToSave.get('isMain') === 'N') {
				this.modelToSave.unset('isMain');
			}
			if (this.modelToSave.get('isMainDeliv') === 'N') {
				this.modelToSave.unset('isMainDeliv');
			}

			if (this.modelToSave.isValid()) {

				var toastMsg = this.modelToSave.isNew() ? this.toastMessages.addressSaved : this.toastMessages.addressUpdated;

				this.modelToSave.save().then(function () {

					if (_this.comeFrom) {

						var fetchLinkedPromise = _this.linkedType === 'corp' ? App.currentCorp.fetch() : _this.third.fetch();

						fetchLinkedPromise.then(function () {
							_this.form.trigger('successful', toastMsg);
							_this.goToPreviousPage();
						});
					} else {

						if (_this.linkedType === 'corp') {
							//route is specific for corp

							App.currentCorp.fetch().then(function () {
								_this.form.trigger('successful', toastMsg);
								_this.router.navigate(URLBuilder(['accountPref', 'addr']), { trigger: true, replace: true });
							});
						} else {
							_this.form.trigger('successful', toastMsg);
							_this.router.navigate(URLBuilder([_this.relationType, 'overview', _this.modelToSave.get('linkedid')]), { trigger: true, replace: true });
						}
					}
				}, function () {
					//toast error
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make the input invalid in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	}, {
		initAddressSelectize: function () {

			var _this = this;
			var dom = {
				'country': $("input[name='address[countrycode]']", _this.$el),
				'town': $("input[name='address[town]']", _this.$el),
				'zip': $("input[name='address[zip]']", _this.$el),
				'state': $("input[name='address[state]']", _this.$el)
			};

			var country = dom.country.val();

			var emptyAll = function () {

				var $select = dom.town.selectize();
				var control = $select[0].selectize;
				control.clear();

				var $select = dom.zip.selectize();
				var control = $select[0].selectize;
				control.clear();

				var $select = dom.state.selectize();
				var control = $select[0].selectize;
				control.clear();
			};

			Utils.getCountriesList().then(function (countriesList) {

				var defaultCountry = countriesList[App.currentCorp.get('preferences').defaultAddressCountry];
				if (country != '') {
					defaultCountry = countriesList[country];
				}

				_this.selectizeObjects = [{
					domElt: dom.country,
					options: countriesList,
					create: false,
					debounceTime: 1000,
					labelField: 'name',
					valueField: 'code',
					searchField: 'name',
					defaultValues: defaultCountry,
					maxItems: 1,
					minItems: 1,
					onItemAdd: function (value, item, opt) {

						if (value == 'FR') {
							dom.state.closest('.form-group').addClass('hidden');
						} else {
							dom.state.closest('.form-group').removeClass('hidden');
						}
					},
					onItemRemove: function () {
						emptyAll();
					}
				}, {
					domElt: dom.town,
					create: true,
					debounceTime: 1000,
					labelField: 'name',
					valueField: 'name',
					searchField: 'name',
					maxItems: 1,
					minItems: 1,
					load: function (value, sele) {

						var countryCode = dom.country.val();

						return new Promise(function (resolve, reject) {

							Utils.getTownList(value, countryCode).then(function (townlist) {
								resolve(townlist);
							});
						});
					},
					onItemAdd: function (value, item, opt) {

						var selectedOpt = opt.options[value];

						var $select = dom.zip.selectize();
						var control = $select[0].selectize;
						control.addOption({ 'customid': selectedOpt.customid, 'zipcode': selectedOpt.zipcode }); //option can be created manually or loaded using Ajax
						control.addItem(selectedOpt.customid);

						if (selectedOpt.customid) {

							console.log("2");

							var zipsplit = selectedOpt.customid.split("_");
							$(control.$input[0]).val(zipsplit[1]);
						}
					},
					onItemRemove: function () {
						emptyAll();
					}
				}, {
					domElt: dom.zip,
					create: true,
					debounceTime: 1000,
					labelField: 'zipcode',
					valueField: 'customid',
					searchField: ['zipcode', 'subname'],
					maxItems: 1,
					minItems: 1,
					load: function (value, sele) {

						var countryCode = dom.country.val();

						return new Promise(function (resolve, reject) {

							Utils.getZipList(value, countryCode).then(function (ziplist) {
								resolve(ziplist);
							});
						});
					},
					onChange: function (value, sele) {

						var prezip = $(sele.$input[0]).val();
						var prezip = prezip.split("_");

						if (prezip.length > 1) {
							$(sele.$input[0]).val(prezip[1]);
						}
					},
					onItemAdd: function (value, item, opt) {

						var selectedOpt = opt.options[value];

						var $select = dom.town.selectize();
						var control = $select[0].selectize;
						control.addOption({ 'name': selectedOpt.name }); //option can be created manually or loaded using Ajax
						control.addItem(selectedOpt.name);
					},
					onItemRemove: function () {
						emptyAll();
					}
				}, {
					domElt: dom.state,
					create: true,
					debounceTime: 1000,
					labelField: 'name',
					valueField: 'name',
					searchField: ['name', 'code'],
					maxItems: 1,
					minItems: 1,
					load: function (value, sele) {

						var countryCode = dom.country.val();

						return new Promise(function (resolve, reject) {

							Utils.getStateList(value, countryCode).then(function (ziplist) {
								resolve(ziplist);
							});
						});
					},
					onItemRemove: function () {
						emptyAll();
					}
				}];

				_this.initSelectize();
			});
		}
	});

	return FormAddress;
});
