/**
 *	@desc Model Staff, extend Core Model 
 */
define('models/singles/staff',['models/core/model', 'db/easy', 'tools/ajax', 'underscore'], function (Model, DB, Ajax, _) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			name: '',
			forename: '',
			tel: '',
			mobile: '',
			email: '',
			uilang: 'fr'
		},

		name: 'staff',
		storeName: 'staff',
		urlRoot: '/rest/staff',

		/**
   * @desc count nb of staff in localDB
   * @returns {Promise}
   */
		countLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].count().then(function (nbTotal) {
					resolve(nbTotal);
				});
			});
		},

		/**
  * @desc Check if model is valid
  */
		validate: function (attributes, options) {

			var required = {
				"staff": {
					name: true,
					forename: true,
					email: true,
					mobile: false,
					tel: false
				}
			};

			var validatorRegEx = {
				email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i
			};

			var validationErrors = { staff: {} };

			//staff validator

			if (attributes.name === "" && required.staff.name) {
				validationErrors['staff']['name'] = this.computeError({ name: 'name', type: 'required' });
			}

			if (attributes.forename === "" && required.staff.forename) {
				validationErrors['staff']['forename'] = this.computeError({ name: 'forename', type: 'required' });
			}

			if (required.staff.email && attributes.email != "") {
				if (!validatorRegEx.email.test(attributes.email)) {
					validationErrors['staff']['email'] = this.computeError({ name: 'email', type: 'invalid' });
				}
			} else {
				validationErrors['staff']['email'] = this.computeError({ name: 'email', type: 'required' });
			}

			if (_.toArray(validationErrors.staff).length > 0) {
				return validationErrors;
			}
		}

	}, {

		/**
   * 
   * @param {type} oldPwd
   * @param {type} newPwd
   * @param {type} newPwdConf
   * @returns {Promise}
   */
		updatePwd: function (passwords) {

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/staff/updatepwd',
					method: 'POST',
					dataType: 'json',
					data: JSON.stringify(passwords),
					success: function (apiResponse) {

						if (!_.isEmpty(apiResponse) && apiResponse.error !== '') {
							reject(apiResponse.error);
						} else {
							resolve();
						}
					},
					fail: reject

				});
			});
		},

		/**
   * 
   * @param {type} attributes
   * @returns {String|Object}
   */
		validatePwd: function (attributes) {

			var validationErrors = {
				password: {}
			};

			var validation = {

				required: {

					password: {
						oldPassword: true,
						newPassword: true,
						newPasswordConf: true
					}

				},

				length: {

					min: {

						password: {
							oldPassword: 6,
							newPassword: 6,
							newPasswordConf: 6
						}
					},

					max: {

						password: {
							oldPassword: 80,
							newPassword: 80,
							newPasswordConf: 80
						}
					}

				}

			};

			_.each(attributes.password, function (pwd, pwdName) {

				if (pwd === '' && validation.required.password[pwdName]) {

					validationErrors['password'][pwdName] = "This field is required";
				} else if (pwd.length > validation.length.max.password[pwdName]) {

					validationErrors['password'][pwdName] = "Max password length is " + validation.length.max.password[pwdName];
				} else if (pwd.length < validation.length.min.password[pwdName]) {

					validationErrors['password'][pwdName] = "Min password length is " + validation.length.min.password[pwdName];
				}
			});

			if (attributes.password['newPassword'] !== attributes.password['newPasswordConf']) {
				validationErrors['password']["newPasswordConf"] = "Confirmation don't match new Password ";
			}

			return _.isEmpty(validationErrors.password) ? '' : validationErrors;
		}

		//		/**
		//		 * 
		//		 * @returns {undefined}
		//		 */
		//		getTimezones : function() {
		//			
		//			return new Promise(function(resolve, reject){
		//				
		//				Ajax({
		//					url			: '/rest/staff/timezones',
		//					success		: function() {
		//						resolve();
		//					},
		//					fail		: function() {
		//						reject();
		//					}
		//				});
		//				
		//			});
		//			
		//		}

	});
});
