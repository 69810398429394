/**
 * @desc know provider Form, extend ToggleablePage View
 * Child View : Core Form
 */
define('views/nylas/knowProviderForm',['underscore', 'views/core/ToggleablePage', 'views/core/form', 'tools/buildURL', 'app'], function (_, ToggleablePage, Form, URLBuilder, App) {

    'use strict';

    return ToggleablePage.extend({

        id: 'exchangeForm',
        events: _.extend({}, ToggleablePage.prototype.events),

        /**
         *
         */
        actionList: {},

        /**
         * @desc initialize
         */
        initialize: function (router, options) {

            ToggleablePage.prototype.initialize.apply(this, arguments);

            this.form = new Form({ parentView: this, template: 'templates/auth/exchangeForm.twig' });
            this.subviews.push(this.form);

            this.listenTo(this.form, 'dataSend', this.formAction);
        },

        /**
         *
         * @returns {undefined}
         */
        afterRender: function () {
            ToggleablePage.prototype.afterRender.apply(this, arguments);
        },

        /**
         * @desc action when we click on return arrow and when back is pressed for native app
         * @param {type} e
         * @returns {Boolean}
         */
        backHandler: function (e) {
            this.$el.trigger('hide');
            return false;
        },

        /**
         *
         * @returns {undefined}
         */
        onShow: function (e, provider) {

            var _this = this;
            $('input[name="nylas[provider]"]').val(provider);
            $('#action-list').hide();
            $('#page-title', $('.navbar-fixed-top')).text(provider);
            $('.return', '.icon-wrapper-left').off();
            $('.return', '.icon-wrapper-left').one('click', this.backHandler.bind(this));
        },

        /**
         *
         */
        onHide: function () {
            this.renderHeader();
            $('#page-title', $('.navbar-fixed-top')).text(App.polyglot.t('addAnAccount'));
            $('input', this.$el).val('');
            $('.btn', this.$el).removeAttr('disabled');
            $('#action-list').show();
        },

        /**
         *
         */
        formAction: function () {

            var dataForm = this.form.sendDataFormV2();
            this.mainView.nylasAuthorizeRequest(dataForm);

            return false;
        }

    });
});
