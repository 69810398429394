/**
 * @description file Model , extend Core Model 
 */
define('models/singles/file',['models/core/model', 'app', 'tools/ajax'], function (Model, App, Ajax) {

  'use strict';

  return Model.extend({

    defaults: {
      id: 0,
      uploadProgress: 0
    },

    storeName: 'briefcase',
    name: 'file',
    urlRoot: '/rest/briefcase',

    validate: function (attributes) {

      //check file size
      if (attributes.file.size > App.maxFileSize) {
        return "max_size_exceeded";
      }
    },

    /**
    *
     * @param formData
     * @returns {*|c}
     */
    save: function (formData) {

      var _this = this;

      return new Promise(function (resolve, reject) {

        var total, loaded, percent;

        Ajax({
          'url': '/rest/briefcase/upload',
          'method': 'POST',
          contentType: false,
          processData: false,
          success: function (response) {
            resolve();
          },
          data: formData,
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              // For handling the progress of the upload
              myXhr.upload.addEventListener('progress', function (e) {
                if (e.lengthComputable) {

                  if (!total) {
                    total = parseInt(e.total);
                  }

                  //loaded /total *100
                  percent = Math.round(parseInt(e.loaded) / total * 100);
                  _this.set("uploadProgress", percent);
                }
              }, false);
            }
            return myXhr;
          }
        });
      });
    }

  });
});
