define('views/widgets/nativeActionSheet',['underscore', 'views/core/view'], function (_, View) {

    return View.extend({

        /**
         *
         * @returns {undefined}
         */
        initialize: function (options) {

            this.options = options;
            this.options.androidTheme = window.plugins.actionsheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT; // default is THEME_TRADITIONAL
            this.options.androidEnableCancelButton = true; // default false

            this.render();
        },

        render: function () {

            var _this = this;

            var callback = function (buttonIndex) {

                setTimeout(function () {

                    //cancel callback
                    if (buttonIndex === _this.options.buttons.length + 1 && _this.options.cancelCallback) {
                        //cancel button is always last button
                        _this.options.cancelCallback.apply();
                    } else if (_this.options.buttons[buttonIndex - 1] && _.isFunction(_this.options.buttons[buttonIndex - 1]['callback'])) {
                        _this.options.buttons[buttonIndex - 1]['callback'].apply();
                    };
                });
            };

            var buttonsLabels = [];

            _.each(this.options.buttons, function (button) {
                buttonsLabels.push(button.title);
            });

            this.options.buttonLabels = buttonsLabels;
            this.options.addCancelButtonWithLabel = this.options.cancelLabel;

            window.plugins.actionsheet.show(this.options, callback);
        }

    });
});
