/**
 * @description PeoplesFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/peoplesFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/smarttags'], function (_, FilterPanel, App, Utils, ListingFilters, Smarttags) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			// SmartTags parts. Get smarttags of linked
			this.smartTags = new Smarttags();
			this.smartTags.fetchOneTime = true;
			this.smartTags.url = '/rest/smarttags/search';
			this.smartTags.XHRData = {
				search: Smarttags.buildFilterByLinked(listName.substring(0, listName.length - 1)) //exlude the s of the listname for get relationtype ( peoples => third)
			};

			this.title = App.polyglot.t(listName);

			this.collections[this.smartTags.name] = this.smartTags;

			FilterPanel.prototype.initialize.apply(this, [router, listName, "people"]);
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var filterList = {
				smarttags: {
					label: App.polyglot.t('smarttags'),
					field: {
						type: 'input',
						name: 'tags',
						offlineSearchField: 'smarttags',
						classes: "smarttags-selectize",
						isMultiple: false,
						selectize: {
							domElt: 'input[name="filter[tags]"]',
							placeholder: App.polyglot.t('smarttags'),
							create: false,
							valueField: 'word',
							labelField: 'word',
							searchField: ['word'],
							loadThrottle: 600,
							options: this.smartTags,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.smartTags.fetch({

										method: 'POST',
										data: { search: _.extend({ contains: query }, _this.smartTags.XHRData.search) }

									}, true).then(function () {
										resolve(_this.smartTags);
									});
								});
							}
						}

					}
				}

			};

			this.filters = filterList;
			return this.filters;
		}

	});
});
