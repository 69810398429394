/**
 *  @desc purCreditNotes Collection, extend core Collection
 */
define('models/collections/purCreditNotes',['models/collections/purchases', 'models/singles/purCreditNote'], function (Purchases, CreditNote) {

	'use strict';

	return Purchases.extend({

		model: CreditNote,
		doctype: 'creditnote',
		availableSteps: ['draft', 'stored', 'partialspend', 'spent', 'cancelled']

	});
});
