/**
 * @desc Product router, extend Core Router
 */
define('routers/agenda',['backbone', 'underscore', 'routers/core/router', 'tools/buildURL', 'models/collections/filters'], function (Backbone, _, CoreRouter, URLBuilder, ListingFilters) {

	'use strict';

	return CoreRouter.extend({

		checkOnline: {
			form: true,
			formEventEdit: true,
			formTaskEdit: true
		},

		/**
   * 
   */
		routes: {

			'event/home(/:linkedtype)(/:linkedid)': 'eventHome',
			'task/home(/:linkedtype)(/:linkedid)': 'taskHome',

			'calendar/list/event/:period(/)(:relatedType)(/:relatedID)': 'listingEvent',
			'calendar/list/task/:period(/)(:relatedType)(/:relatedID)': 'listingTask',

			'calendar/form/task(/:relatedType/:relatedID)': 'formTask',
			'calendar/form/event(/:relatedType/:relatedID)': 'formEvent',

			'calendar/overview/task/:id': 'overview',

			'calendar/form/event/:id(/:relatedType)(/:relatedID)': 'formEventEdit',
			'calendar/form/task/:id(/:relatedType)(/:relatedID)': 'formTaskEdit',

			'agenda/event(/:relatedType)(/:relatedID)': "calendarEvent"

		},

		/**
   * 
   * @param {type} callback
   * @param {type} args
   * @returns {undefined}
   */
		execute: function (callback, args) {
			CoreRouter.prototype.execute.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} type
   * @returns {undefined}
   */
		listingEvent: function (period, relatedType, relatedID) {

			var _this = this;
			this.sidelink = "event";
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/eventListing'], function (require) {

					var AgendaListing = require('views/agenda/eventListing');

					if (relatedType && relatedID) {

						var params = {
							'relatedtype': relatedType,
							'relatedid': relatedID
						};
					} else {
						var params = {};
					}

					var links = _this.getAvailableActions(relatedType, relatedID);
					var view = new AgendaListing(_this, period, params, links);
					view.trueRoute = trueRoute;

					resolve();
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		listingTask: function (period, relatedType, relatedID, relatedName) {

			this.sidelink = "task";
			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/taskListing'], function (require) {

					var taskListing = require('views/agenda/taskListing');
					var extraParams = "";

					if (relatedType && relatedID) {

						var params = {
							'relatedtype': relatedType,
							'relatedid': relatedID
						};

						extraParams = URLBuilder([relatedType, relatedID], false);
					} else if (relatedType === 'home') {

						var params = {
							'relatedtype': 'home'
						};
					} else {
						var params = {};
					}

					var links = [{
						fa: 'fa-calendar-times-o',
						name: 'late',
						link: URLBuilder(['calendar', 'list', 'task', 'late', extraParams]),
						canAccess: true
					}, {
						fa: 'fa-calendar-check-o',
						name: 'today',
						link: URLBuilder(['calendar', 'list', 'task', 'today', extraParams]),
						canAccess: true
					}, {
						fa: 'fa-calendar',
						name: 'later',
						link: URLBuilder(['calendar', 'list', 'task', 'tomorrow', extraParams]),
						canAccess: true
					}];

					if (relatedType === 'home') {
						links = undefined;
					}

					var view = new taskListing(_this, period, params, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/*
   * 
   * @param {type} relatedType
   * @param {type} relatedID
   * @param {type} relatedName
   * @returns {undefined}
   */
		formEvent: function (relatedType, relatedID, relatedName) {
			return this.form('event', relatedType, relatedID, relatedName);
		},

		/**
         * 
         * @param {*} relatedType 
         * @param {*} relatedID 
         */
		formTask: function (relatedType, relatedID) {
			return this.form('task', relatedType, relatedID);
		},

		/**
   * 
   * @param {type} type : event or  task
   * @param {type} relatedType
   * @param {type} relatedID
   * @returns {undefined}
   */
		form: function (type, relatedType, relatedID) {

			var _this = this;
			this.sidelink = type;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/form'], function (require) {

					var AgendaForm = require('views/agenda/form');

					if (relatedType && relatedID) {

						var agendaFormView = {
							'relatedtype': relatedType,
							'relatedid': relatedID
						};
					} else {
						var agendaFormView = {};
					}

					var view = new AgendaForm(_this, type, agendaFormView);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} type : event or  task
   * @param {type} year
   * @param {type} month
   * @param {type} day
   * @param {type} eventid
   * @returns {undefined}
   */
		formEventEdit: function (id, relatedtype, relatedid) {

			var _this = this;
			this.sidelink = 'event';
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/form'], function (require) {

					var AgendaForm = require('views/agenda/form');

					var agendaFormView = {
						'relatedtype': relatedtype,
						'relatedid': relatedid
					};

					var view = new AgendaForm(_this, 'event', agendaFormView, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} type
   * @param {type} taskid
   * @returns {undefined}
   */
		formTaskEdit: function (id, relatedtype, relatedid) {

			var _this = this;
			this.sidelink = 'task';
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/form'], function (require) {

					var AgendaForm = require('views/agenda/form');

					var agendaFormView = {
						'id': id,
						'relatedtype': relatedtype,
						'relatedid': relatedid
					};

					var view = new AgendaForm(_this, 'task', agendaFormView, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		taskHome: function (linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = "task";

			$('.sidebar-task').addClass('active');

			return new Promise(function (resolve, reject) {

				var extraParams = "";

				if (linkedid && linkedtype) {
					extraParams = URLBuilder([linkedtype, linkedid], false);
				}

				require(['require', 'views/core/homeModule', 'models/collections/tasks'], function (require, HomeModule, Tasks) {

					var promises = [];

					var tasksLate = new Tasks();
					var tasksToday = new Tasks();
					var tasksTomorrow = new Tasks();

					var lateTasks;
					var todayTasks;
					var tomorrowTasks;

					//get current filter of task listing and get nb tasks for each category
					_this.listingFiltersCollection = new ListingFilters();
					_this.listingFiltersCollection.XHRData = {
						search: {
							listing: 'tasks'
						}
					};

					//take saved filters values in local DB
					_this.listingFiltersCollection.fetchLocal(_this.listingFiltersCollection.XHRData).then(_.bind(function () {

						if (_this.listingFiltersCollection.get('tasks_staffs')) {
							var staffsFilter = _this.listingFiltersCollection.get('tasks_staffs').get('value');
						} else {
							var staffsFilter = [App.currentStaff.id];
						}

						promises.push(tasksLate.getNbCurrents(linkedtype, linkedid, { period: ['late'], staffs: staffsFilter }).then(function (nbTasksInLate) {
							lateTasks = nbTasksInLate > 9 ? "9+" : nbTasksInLate;
						}));

						promises.push(tasksToday.getNbCurrents(linkedtype, linkedid, { period: ['today'], staffs: staffsFilter }).then(function (nbTasksToday) {
							todayTasks = nbTasksToday > 9 ? "9+" : nbTasksToday;
						}));

						promises.push(tasksTomorrow.getNbCurrents(linkedtype, linkedid, { period: ['tomorrow'], staffs: staffsFilter }).then(function (nbTasksTomorrow) {
							tomorrowTasks = nbTasksTomorrow > 9 ? "9+" : nbTasksTomorrow;
						}));

						Promise.all(promises).then(function () {

							var links = [{
								fa: 'fa-calendar-times-o',
								name: 'late',
								link: URLBuilder(['calendar', 'list', 'task', 'late', extraParams]),
								badge: lateTasks,
								canAccess: true
							}, {
								fa: 'fa-calendar-check-o',
								name: 'today',
								link: URLBuilder(['calendar', 'list', 'task', 'today', extraParams]),
								badge: todayTasks,
								canAccess: true
							}, {
								fa: 'fa-calendar',
								name: 'later',
								badge: tomorrowTasks,
								link: URLBuilder(['calendar', 'list', 'task', 'tomorrow', extraParams]),
								canAccess: true
							}];

							var view = new HomeModule(_this, links, 'tasks');
							view.trueRoute = trueRoute;

							resolve(view);
						}, function (e) {
							console.log(e);
						}).catch(function (e) {
							console.log(e);
						});
					}, _this));
				});
			});
		},

		eventHome: function (linkedtype, linkedid) {

			$('.sidebar-event').addClass('active');

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = "event";

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule'], function (require, HomeModule) {

					var links = _this.getAvailableActions(linkedtype, linkedid);
					var view = new HomeModule(_this, links, 'agenda');
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} type
   * @param {type} linkedtype
   * @param {type} linkedid
   * @param {type} linkedname
   * @returns {undefined}
   */
		overview: function (id, linkedtype, linkedid, linkedname) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = "type";
			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/overview'], function (require, Overview) {

					var view = new Overview(_this, id, linkedtype, linkedid, linkedname);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		calendarEvent: function (relatedType, relatedId) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/agenda/calendarEvent'], function (require, CalendarEvent) {

					App.getPreferencesByPlugin('agenda').then(function () {
						var view = new CalendarEvent(_this, relatedType, relatedId);
						view.trueRoute = trueRoute;

						resolve(view);
					});
				});
			});
		},

		/**
   * 
   * @param {type} linkedtype
   * @param {type} linkedid
   * @param {type} linkedname
   * @returns {Array}
   */
		getAvailableActions: function (linkedtype, linkedid, linkedname) {

			var extraParams = "";

			if (linkedid && linkedtype) {
				extraParams = URLBuilder([linkedtype, linkedid], false);
			}

			var links = [{
				fa: 'fa-calendar-o',
				name: 'today',
				link: URLBuilder(['calendar', 'list', 'event', 'today', extraParams]),
				canAccess: true
			}, {
				fa: 'fa-calendar',
				name: 'forthcoming',
				link: URLBuilder(['calendar', 'list', 'event', 'tomorrow', extraParams]),
				canAccess: true
			}, {
				fa: 'fa-calendar-check-o',
				name: 'past',
				link: URLBuilder(['calendar', 'list', 'event', 'late', extraParams]),
				canAccess: true
			}];

			return links;
		}

	});
});
