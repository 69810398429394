/**
 * @description TabsView
 */
define('views/widgets/tabs',['underscore', 'views/core/view', 'app'], function (_, View, App) {

	'use strict';

	return View.extend({

		events: {
			'click li:not(.active)': function () {
				$('li.active').removeClass('active');
			}
		},

		models: {},
		content: '',
		parentView: {},
		className: 'nav nav-tabs',
		template: 'templates/widgets/tabButtons.twig',
		options: {
			extraData: {}
		},

		attributes: {
			"role": "tablist"
		},

		/**
   * 
   * @param {String} template
   * @param {View} listing
   */
		initialize: function (insertIn, tabButtons) {

			this.tabButtons = tabButtons ? tabButtons : [{
				label: App.polyglot.t("details"),
				for: 'details',
				active: true
			}, {
				label: App.polyglot.t("customfields"),
				for: 'customfields'
			}, {
				label: App.polyglot.t("actions"),
				for: 'actions'
			}];

			this.insertIn = insertIn;

			this.var4template('tabButtons', this.tabButtons);
		},

		/**
   * 
   * @returns {undefined}
   */
		insertInDom: function () {

			$(this.insertIn).append(this.$el);

			this.$el.find('li').css('width', "calc(100% / " + this.tabButtons.length + ")");
			this.delegateEvents();
		}

	});
});
