/**
* @desc corp address prefs
* Child View : Core Overview
*/

define('views/setting/CorpAddressesPref',['underscore', 'views/core/page', 'views/core/overview', 'app', 'models/singles/address', 'tools/buildURL', 'tools/materialEffect', 'views/core/popupAction', 'models/collections/addresses', 'views/core/pageList'], function (_, Page, Overview, App, Address, URLBuilder, initMaterialEffect, PopupAction, Addresses, PageList) {

	'use strict';

	return PageList.extend({

		className: 'corpAddresses page',
		overview: {},
		template: 'templates/setting/corpAddresses',
		toastMessages: {
			address: {
				addressDeleted: App.polyglot.t("addressDeleted")
			}
		},
		title: App.polyglot.t("corpAddresses"),
		events: {
			'click .fa-envelope-o': 'changeMainDelivAddr',
			'click .fa-star-o': 'changeMainAddr'
		},
		addAction: function () {
			this.goToAddressForm();
		},
		hideFilterPannel: true,

		disablePullToRefresh: true,

		/**
   * 
   * @returns {undefined}
   */
		initialize: function (router) {

			this.canAdd = true;
			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.corp = App.currentCorp;
				var _this = this;

				this.options.extraData.corpAddresses = function () {

					return new Promise(function (resolve, reject) {

						_this.corp.getCorpAddresses().then(function (corpAddresses) {

							_this.collection = new Addresses(_.toArray(corpAddresses));
							resolve(corpAddresses);
						});
					});
				};

				this.overview = new Overview({ parentView: this }, router);
				this.subviews.push(this.overview);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainDelivAddr: function (e) {

			var _this = this;
			var $newMainDelivAddr = $(e.currentTarget).closest('.address');
			var $newDelivId = $newMainDelivAddr.data('id');
			var newDelivAddr = new Address(this.corp.get('addresses')[$newDelivId]);

			newDelivAddr.set({ isMainDeliv: 'Y' });
			newDelivAddr.set({ linkedid: this.corp.get('linkedid'), linkedtype: 'corp' });
			newDelivAddr.unset('isMain');

			var $loader = this.formActionSetLoader();

			$newMainDelivAddr.append($loader);

			newDelivAddr.save().then(function () {
				_this.overview.render({ setLoader: false });
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainAddr: function (e) {

			var _this = this;
			var $newMainAddr = $(e.currentTarget).closest('.address');
			var $newMainId = $newMainAddr.data('id');
			var newMainAddr = new Address(this.corp.get('addresses')[$newMainId]);

			newMainAddr.set({ linkedid: this.corp.get('linkedid'), linkedtype: 'corp' });
			newMainAddr.set({ isMain: 'Y' });
			newMainAddr.unset('isMainDeliv');

			var $loader = this.formActionSetLoader();
			$newMainAddr.append($loader);

			newMainAddr.save().then(function () {
				_this.overview.render({ setLoader: false });
			});
		},

		/**
   * 
   */
		goToAddressForm: function (model) {

			if (model && model.id) {
				this.router.navigate(URLBuilder(['address', 'form', App.currentCorp.id, 'corp', model.id]), { trigger: true });
			} else {
				this.router.navigate(URLBuilder(['address', 'form', App.currentCorp.id, 'corp']), { trigger: true });
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			$('.overview', this.$el).css('background', "#F2F6F8");

			this.initEffect({
				onModify: function (model) {
					return _this.goToAddressForm.call(_this, model.toJSON());
				},
				onRemove: function (model, $currentTarget) {
					$currentTarget.remove();
				},
				getModel: function ($currentTarget) {
					return _this.collection.get($currentTarget.data('id'));
				},
				removeMsg: _this.toastMessages.addressDeleted
			}, '', this);
		}

	});
});
