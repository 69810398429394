/**
 *  @desc Deliveries Collection, extend core Collection
 */
define('models/collections/deliveries',['models/collections/documents', 'models/singles/delivery'], function (Documents, Delivery) {

	'use strict';

	return Documents.extend({

		model: Delivery,
		storeName: 'document',
		url: '/rest/document/list',
		doctype: 'delivery',
		availableSteps: ['draft', "partialinvoiced", "invoiced", "read", "sent"]

	});
});
