/**
 * @description TasksFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/tasksFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/agendaLabels', 'models/collections/groups'], function (_, FilterPanel, App, Utils, ListingFilters, AgendaLabels, Groups) {

	'use strict';

	return FilterPanel.extend({

		initialize: function (router, listName) {

			this.title = App.polyglot.t("tasks");
			var _this = this;
			this.agendaLabels = new AgendaLabels();
			var promises = [];
			this.hideSavedSearch = true;

			promises.push(this.agendaLabels.fetch({ method: 'POST' }));

			this.groups = new Groups();

			promises.push(this.groups.fetch({ method: 'POST' }));

			Promise.all(promises).then(function () {
				FilterPanel.prototype.initialize.apply(_this, [router, listName]);
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;
			var staffsList = this.groups.buildStaffs4groups();

			var filterList = {
				staffs: {
					label: App.polyglot.t('staffsAffected'),
					field: {
						type: 'input',
						name: 'staffs',
						offlineSearchField: 'staffid',
						classes: "offline-selectize",
						isMultiple: true,
						selectize: {
							domElt: "[name='filter[staffs]']",
							options: staffsList,
							create: false,
							labelField: 'fullName',
							valueField: 'buildid',
							searchField: ['fullName'],
							onItemAdd: this.selectizeOnItemAdd,
							onItemRemove: this.selectizeOnItemRemove,
							render: 'staff',
							optgroups: _this.groups.toJSON(),
							optgroupField: 'groupid',
							optgroupLabelField: 'name',
							optgroupValueField: 'id'
						}
					}
				},
				label: {
					label: App.polyglot.t('categories'),
					field: {
						type: 'input',
						name: 'labels',
						offlineSearchField: 'labelid',
						isMultiple: true,
						classes: "offline-selectize",
						selectize: {
							domElt: 'input[name="filter[labels]"]',
							placeholder: App.polyglot.t('categories'),
							create: false,
							valueField: 'id',
							labelField: 'value',
							options: this.agendaLabels
						}
					}
				}

			};

			//if we have not groups dont show optGroup
			if (this.groups.length == 1) {
				delete filterList['staffs']['field']['selectize']['optgroups'];
				delete filterList['staffs']['field']['selectize']['optgroupField'];
				delete filterList['staffs']['field']['selectize']['optgroupLabelField'];
				delete filterList['staffs']['field']['selectize']['optgroupValueField'];
			}

			this.filters = filterList;
			return this.filters;
		}

	});
});
