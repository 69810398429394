/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/sources',['models/core/collection', 'models/singles/source'], function (Collection, Source) {

	'use strict';

	return Collection.extend({

		model: Source,
		storeName: 'source',
		name: 'sources',
		url: '/rest/source/list',
		fields: []

	});
});
