/**
 *  @desc Purchases Collection, extend core Collection
 */
define('models/collections/purchases',['models/core/collection', 'models/singles/purchase'], function (Collection, Purchase) {

	'use strict';

	return Collection.extend({

		model: Purchase,
		storeName: 'purchase',
		name: 'purchases',
		url: '/rest/purchase/list',
		fields: ['ident'],
		searchFieldName: 'ident',

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {
			Collection.apply(this, arguments);
			this.offlineQueryArgs = ['thirdid', 'doctype', 'step'];
		}

	}, {

		/**
   * @description return the right constructor for the given doctype
   * @returns {undefined}
   */
		classNameByDoctype: function (doctype) {

			var className = '';

			return new Promise(function (resolve, reject) {

				switch (doctype) {

					case 'purInvoice':

						require(['models/collections/purInvoices'], function (Invoices) {
							className = Invoices;
							resolve(className);
						});

						break;

					case 'purOrder':

						require(['models/collections/purOrders'], function (Orders) {
							className = Orders;
							resolve(className);
						});

						break;

					case 'purDelivery':

						require(['models/collections/purDeliveries'], function (Deliveries) {
							className = Deliveries;
							resolve(className);
						});

						break;

					case 'purCreditNote':

						require(['models/collections/purCreditNotes'], function (CreditNotes) {
							className = CreditNotes;
							resolve(className);
						});

						break;

						reject('invalid doctype : ' + doctype);

						break;
				}
			});
		}

	});
});
