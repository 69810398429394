/* global Promise, Dexie */

/**
 *  @desc PayMEdiums Collection, extend core Collection
 */
define('models/collections/payMediums',['models/core/collection', 'models/singles/payMedium', 'db/easy', 'tools/ajax'], function (Collection, PayMedium, DB, Ajax) {

	'use strict';

	var payMediumsCollection = Collection.extend({

		model: PayMedium,
		storeName: 'paymedium',
		name: 'paymediums',
		url: '/rest/paymedium/list',
		canBeStored: true,

		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.reset(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		},

		//return paymediumChecked
		takeChecked: function () {

			var checkedCollection = new payMediumsCollection();

			this.each(function (paymedium) {
				if (paymedium.get('isChecked') === 'Y') {
					checkedCollection.add(paymedium.toJSON());
				}
			});

			return checkedCollection;
		},

		/**
   * 
   * @returns {undefined}
   */
		checkPaymediums: function (payMediums2Check) {

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: '/rest/paymedium/list/check',
					data: JSON.stringify(payMediums2Check),
					dataType: "json",
					success: function () {
						DB.transaction("rw", DB['paymedium'], function () {
							//							//update paymediumChecked in local
							DB['paymedium'].toCollection().modify({ isChecked: "N" }).then(function () {
								//								
								DB['paymedium'].where('id').anyOf(payMediums2Check).modify({ isChecked: "Y" }).then(function () {
									resolve(); //success
								});
								//								
							}, function (err) {
								console.log(err);
							});
							//							
						});
					},
					error: function () {
						reject();
					}
				});
			});
		}

	}, {});

	return payMediumsCollection;
});
