/**
 *  @desc purDeliveries Collection, extend purchases Collection
 */
define('models/collections/purDeliveries',['models/collections/purchases', 'models/singles/purDelivery'], function (Purchases, Delivery) {

	'use strict';

	return Purchases.extend({

		model: Delivery,
		doctype: 'delivery',
		availableSteps: ['draft', 'sent', 'read', "partialinvoiced", "invoiced"]

	});
});
