/**
 * @desc Third Doc List, extend Doclisting View
 * Child View : Core Listing
 */
define('views/third/linkedDocs',['underscore', 'views/document/list', 'views/core/listing', 'views/core/pageList', 'app', 'models/collections/documents', 'models/collections/purchases', 'models/singles/purchase', 'tools/core/utils', 'models/collections/smarttags', 'tools/buildURL'], function (_, Doclisting, Listing, PageList, App, Documents, Purchases, Purchase, Utils, Smarttags, URLBuilder) {

	'use strict';

	return Doclisting.extend({

		events: _.extend({
			// "change select" : 'loadDocsByDoctype'
		}, Doclisting.prototype.events),

		listing: {},
		toastMessages: {
			document: {
				documentRemoved: App.polyglot.t("documentRemoved")
			}
		},

		title: App.polyglot.t("estimateList"),

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, linkedid, linkedtype) {

			var _this = this;

			this.linkedtype = linkedtype;
			this.linkedid = linkedid;

			this.addAction = function () {
				this.listing.goToForm(URLBuilder(['document', 'createDoc', this.doctype, 0, 0, linkedid]));
			};

			if (linkedtype == "supplier") {

				var purDoctype = Purchase.toPurDoctype(doctype);
				this.canAdd = App.permission.can("purchases_create" + purDoctype);
				this.canDelete = App.permission.can("purchases_delete" + purDoctype);
			} else {
				this.canDelete = App.permission.can("invoicing_delete" + this.doctype);
				this.canAdd = App.permission.can("invoicing_create" + this.doctype);
			}

			this.availableDocTypes = this.setAvailableDocTypes(linkedtype);
			this.availableActions = this.getAvailableActions(this.availableDocTypes);
			this.name = 'third_' + doctype;

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.template = "templates/document/list.twig";

				this.var4template('doctype', doctype);

				var DocClassName = linkedtype === 'supplier' ? Purchases : Documents;

				DocClassName.classNameByDoctype(purDoctype ? purDoctype : doctype).then(_.bind(function (ClassName) {

					this.placeholderSearchBar = App.polyglot.t("documentNumber");
					this.doctype = doctype;
					this.subviews = [];
					this.router = router;
					this.collection = new ClassName();
					this.collection.XHRData = {
						doctype: doctype,
						search: {
							thirdid: linkedid,
							thirds: [linkedid]
						}
					};

					this.collections['documents'] = this.collection;

					this.title = App.polyglot.t(doctype === 'delivery' ? 'deliveries' : doctype + 's');

					this.listing = new Listing({ parentView: this });
					this.subviews.push(this.listing);
					this.render();
				}, this));
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageList.prototype.afterRender.apply(this, arguments);

			var _this = this;

			if (this.canAdd || this.canDelete) {

				//init material effect on each row of listing
				this.listing.initSwipeEffect({

					removeMsg: this.toastMessages.document.documentRemoved,
					onModify: function (model, $row) {

						if (model.isEditable()) {
							this.router.navigate(URLBuilder(["document", "form", this.collection.XHRData.doctype, model.get('thirdid'), model.id, 'list']), { trigger: true });
						} else {

							new ToastNotification({
								id: 'toast-error',
								classes: 'col-xs-12',
								timeDuration: 2500,
								content: App.polyglot.t('docStatusCannotModify')
							});
						}
					},
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					isDeleteAvailable: function (model) {

						if (model.isEditable(model)) {
							return true;
						} else {

							new ToastNotification({
								id: 'toast-error',
								classes: 'col-xs-12',
								timeDuration: 2500,
								content: App.polyglot.t('docStatusCannotDelete')
							});

							return false;
						}
					},
					popupid: 'doc-option',
					modelName: "ident",
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					}
				}, '', this);
			}
		},

		/**
   * 
   * @param {type} relatedtype
   * @returns {undefined}
   */
		setAvailableDocTypes: function (relatedtype) {

			switch (relatedtype) {
				case 'third':
				case 'opportunity':
					return _.reject(App.availableDoctypes, function (type) {
						return type == 'model' ? true : false;
					});
					break;
				case 'prospect':
					return ["estimate"];
					break;
				case 'supplier':
					return App.availablePurDoctypes_raw;
					break;
			}
		},

		/**
   * 
   * @param {type} doctypes
   * @returns {Array}
   */
		getAvailableActions: function (doctypes) {

			var links = [];
			var _this = this;
			_.each(this.linkedtype === "supplier" ? App.permissionPurDoctypes : App.permissionDoctypes, function (doctype) {
				links.push({
					name: doctype,
					link: URLBuilder(["third", "linkedDocs", doctype, _this.linkedid, _this.linkedtype]),
					canAccess: true
				});
			});

			return links;
		}

	});
});
