/**
 * @desc Timeline ticket, extend ToggleablePage View
 * Child View : Core Overview
 */
define('views/support/formResponse',['underscore', 'views/core/ToggleablePage', 'views/core/form', 'tools/buildURL', 'app', 'tinyMCE', 'models/singles/ticketConversation', 'models/collections/ticketConversations', 'views/core/toastNotification', 'models/collections/supportTemplates', 'models/singles/filePicture', 'views/core/toastNotification'], function (_, ToggleablePage, Form, URLBuilder, App, TinyMCE, TicketConversation, TicketConversations, ToastNotification, SupportTemplates, FilePicture, ToastNotif) {

    return ToggleablePage.extend({

        id: 'mail-form',
        events: _.extend({
            'change #templates': function (e) {

                var tplId = $(e.currentTarget).val();

                if (tplId != 0) {
                    var template = this.supportTemplates.get(tplId);
                    this.editor.setContent(template.get('message'));
                }
            }
        }, ToggleablePage.prototype.events),

        actionList: {},

        options: {
            extraData: {}
        },

        hideSearchIcon: true,

        toastMessages: {
            stepupdated: App.polyglot.t('stepupdated'),
            assignationupdated: App.polyglot.t('assignationupdated'),
            noteRemoved: App.polyglot.t('noteRemoved'),
            replyAdded: App.polyglot.t('replyAdded'),
            replyUpdated: App.polyglot.t('replyUpdated'),
            noteAdded: App.polyglot.t('noteAdded'),
            noteUpdated: App.polyglot.t('noteUpdated'),
            thirdUpdated: App.polyglot.t('thirdUpdated')
        },

        /**
         * @desc initialize
         */
        initialize: function (router, options) {

            ToggleablePage.prototype.initialize.apply(this, arguments);
            this.ticket = options.modelsFetched['ticket'];
            this.var4template('ticket', this.ticket);

            this.supportTemplates = new SupportTemplates();
            this.supportTemplates.XHRData['ticketid'] = this.ticket.id;
            this.collections[this.supportTemplates.name] = this.supportTemplates;
        },

        render: function () {

            if (!_.isEmpty(this.subviews)) {
                this.subviews = [];
            }

            this.form = new Form({ parentView: this, template: 'templates/support/formResponse.twig' });
            this.subviews.push(this.form);

            ToggleablePage.prototype.render.apply(this, arguments);
        },

        /**
         *
         * @returns {undefined}
         */
        afterRender: function () {

            ToggleablePage.prototype.afterRender.apply(this, arguments);

            var _this = this;

            TinyMCE.init({
                selector: '.body-mail',
                toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
                plugins: "autoresize placeholder",
                menubar: false,
                statusbar: false,
                setup: function (editor) {

                    _this.editor = editor;
                }
            });
        },

        /**
         * @desc action when we click on return arrow and when back is pressed for native app
         * @param {type} e
         * @returns {Boolean}
         */
        backHandler: function (e) {
            this.$el.trigger('hide');
            return false;
        },

        /**
         *
         * @returns {undefined}
         */
        onShow: function (e, params, draftid) {

            if (this.editor) {
                this.editor.setContent('');
            }

            //clean form
            $('input[name="response[id]"]').val('');

            App.header.$el.find('nav').removeClass('box-shadow-none');

            var _this = this;
            $('.return', '.icon-wrapper-left').off();
            $('.return', '.icon-wrapper-left').one('click', this.backHandler.bind(this));
            $('#attachments').remove();

            if (params === 'email' || params === 'draft') {

                this.actionList = {

                    response: {
                        img: "send_mail.svg",
                        link: function (e) {
                            this.replyTicket(e);
                        }
                    }

                };

                $('input[name="response[type]"]').val('email');
                $('#page-title', $('.navbar-fixed-top')).text(App.polyglot.t("reply"));
                $('#response-wrapper').show();

                if (params === 'draft') {

                    var timeline = this.ticket.get('timeline');
                    var timelineCollection = new TicketConversations(timeline);
                    var draft = timelineCollection.get(draftid);

                    $('[name="response[draft]"').prop('checked', true);
                    $('[name="response[id]"').val(draft.get('id'));

                    _this.editor.setContent(draft.get('message'));
                }
            } else {

                this.actionList = {

                    note: {
                        fa: "fa-file-text-o",
                        link: function (e) {
                            this.replyTicket(e);
                        }
                    }

                };

                $('input[name="response[type]"]').val('note');
                $('#page-title', $('.navbar-fixed-top')).text(App.polyglot.t("internalNote"));

                $('#response-wrapper').hide();
            }

            this.populateActionList();
        },

        /**
         *
         * @param {type} params
         * @returns {undefined}
         */
        onHide: function (e) {
            this.renderHeader();
            $('#action-list').empty();
            $('#page-title', $('.navbar-fixed-top')).text('');
            App.header.$el.find('nav').addClass('box-shadow-none');
        },

        /**
         *
         * @param {type} e
         * @returns {undefined}
         */
        replyTicket: function (e) {

            TinyMCE.triggerSave();

            var _this = this;
            var formData = this.form.sendDataFormV2();

            formData.response.file = $('[name="response[file]"]')[0].files[0]; //sendDataFormV2 didn't catch file input

            if (formData['response']['id'] === '') {
                delete formData['response']['id'];
            }

            e.preventDefault();
            $(e.currentTarget).prop('disabled', true).css('pointer-events', 'none');

            var modelToSave = new TicketConversation();
            modelToSave.set(formData['response']);

            var isNew = modelToSave.isNew();

            if (formData.response.file) {
                var file = new FilePicture();
                file.set({
                    file: formData.response.file
                });
            }

            if (!file || file.isValid()) {

                if (modelToSave.isValid()) {

                    $('img, .fa', '#action-list').replaceWith('<i class="fa fa-circle-o-notch fa-spin"></i>');

                    modelToSave.save({}, { method: "POST" }).then(function () {

                        var notif = !isNew ? _this.toastMessages.replyUpdated : _this.toastMessages.replyAdded;

                        if (modelToSave.get('type') == 'note') {

                            var notif;

                            if (isNew) {
                                notif = _this.toastMessages.noteAdded;
                            } else {
                                notif = _this.toastMessages.noteUpdated;
                            }
                        } else {

                            if (isNew) {
                                notif = _this.toastMessages.replyAdded;
                            } else {
                                notif = _this.toastMessages.replyUpdated;
                            }
                        }

                        _this.mainView.render().then(function () {
                            new ToastNotification({
                                id: 'toast-success',
                                classes: 'col-xs-12',
                                timeDuration: 4000,
                                content: notif
                            });
                            _this.$el.trigger('hide');
                        });
                    }, function () {
                        // re activate submit button
                        $(e.currentTarget).prop('disabled', false);
                        $(e.currentTarget).removeAttr('style');
                    });
                } else {

                    // re activate submit button
                    $('.submit-form', this.form.$el).removeAttr('disabled');
                    $(e.currentTarget).removeAttr('style');

                    //Make invalid inputs in error
                    _.each(modelToSave.validationError, function (error, keyError, arrayError) {

                        if (!_.isEmpty(error)) {

                            _.each(error, function (msgError, keyMsg) {
                                _this.form.showValidationError(keyError, keyMsg, msgError, true);
                            });
                        }
                    });
                }
            } else {
                new ToastNotif({
                    'id': 'toast-error',
                    'content': App.polyglot.t(file.validationError),
                    classes: 'col-xs-12',
                    timeDuration: 3000
                });

                $('.submit-form', this.form.$el).removeAttr('disabled');
            }
        }

    });
});
