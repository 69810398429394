/**
 *	@desc Model , extend Core Model 
 */
define('models/singles/prospect',['models/singles/third', 'app'], function (Third, App) {

	'use strict';

	return Third.extend({

		defaults: _.extend({
			id: 0
		}, Third.prototype.defaults),

		storeName: 'third',
		name: 'prospect',
		urlRoot: '/rest/prospect/'

	});
});
