define('db/easy',['dexie', "jquery", "app", 'tools/core/utils', 'tools/monitoring'], function (Dexie, $, App, Utils, Monitoring) {

	'use strict';

	/**
  * 
  * Use ShimindexedDB for ios version < 10 else use classic indexeddb
  */

	var isIOS = Utils.isIOS();

	if (!isIOS || isIOS >= 10) {

		Dexie.dependencies = {
			// Required: 
			indexedDB: indexedDB || window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB,
			IDBKeyRange: IDBKeyRange || window.IDBKeyRange || window.webkitIDBKeyRange || window.IDBKeyRange,
			IDBTransaction: IDBTransaction || window.IDBTransaction || window.webkitIDBTransaction || window.IDBTransaction
		};
	} else {
		// window.shimIndexedDB.__debug(true);
	}

	App.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
	var db = new Dexie("SellsyEasyDB", { indexedDB: window.indexedDB, IDBKeyRange: window.IDBKeyRange });

	/**
  *      / \   
  *	   / | \
  *    /	 .  \	
  *		
  *  Ne pas supprimer les anciennes versions lors d'une maj de la DB locale, il refaire un object db.version(v+1).stores et laisser les anciennes sinon on a une erreur 
  *
  */

	db.version(1).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id',
		funnels: 'id'
	});

	db.version(2).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id',
		funnels: 'id'
	});

	db.version(3).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id',
		funnels: 'id'
	});

	db.version(4).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid'
	});

	db.version(5).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid'
	});

	db.version(6).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]'

	});

	db.version(8).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]'

	});

	db.version(9).stores({
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		address: "id, name, zip, town, country",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agenda: 'idMonthAndYear, orderByAttribute, number',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		accounting: 'id',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		annotation: 'id, orderByAttribute',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]'
	});

	db.version(10).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid'
	});

	db.version(11).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid'
	}).upgrade(function () {
		db['listingFilter'].clear();
	});

	db.version(12).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid',
		supportTemplates: 'id, name'
	});

	db.version(13).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, isEventRecurring, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid',
		supportTemplates: 'id, name',
		groups: 'id'
	}).upgrade(function () {
		db['listingFilter'].clear();
	});

	db.version(14).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, isEventRecurring, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid',
		supportTemplates: 'id, name',
		groups: 'id'
	}).upgrade(function () {
		db['listingFilter'].clear();
	});

	db.version(15).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, isEventRecurring, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid',
		supportTemplates: 'id, name',
		groups: 'id',
		emailThread: "id, orderByAttribute",
		folder: "id",
		emailDraft: "id",
		message: "id"
	});

	db.version(16).stores({
		accounting: 'id',
		address: "id, name, zip, town, country",
		agenda: 'id, isEventRecurring, orderByAttribute',
		annotation: 'id, orderByAttribute',
		third: "id, name, email, orderByAttribute",
		staff: "id, name, forename, email",
		contact: 'id, name, forename, email, orderByAttribute',
		product: '[id+declid], name, description, type, orderByAttribute',
		tax: 'id',
		unity: 'id',
		system: 'id',
		corp: 'id',
		document: 'id, ident, thirdname, orderByAttribute, doctype',
		currency: 'id, name, symbol',
		date: 'timeZone, timeFormat, hourFormat',
		payment: 'id',
		paymedium: 'id, corpid, orderByAttribute',
		packaging: 'id, name, orderByAttribute',
		shipping: 'id, name, orderByAttribute',
		paydate: 'id',
		agendaLabel: 'id, name, orderByAttribute',
		warehouse: 'id, orderByAttribute',
		task: 'id, orderByAttribute, relatedtype, relatedid, state',
		ratecategory: 'id',
		smarttags: 'id, word, category, linkedid,linkedtype',
		customfields: 'id',
		docLayout: 'id',
		lang: 'id, lang',
		variationfield: 'id',
		email: 'id, orderByAttribute',
		eventlog: 'id',
		timetracking: 'id,orderByAttribute',
		opportunity: 'id, funnelid, orderByAttribute',
		source: 'id',
		purchase: 'id, orderByAttribute',
		expense: 'id, orderByAttribute',
		support: 'id, orderByAttribute',
		supportsteps: 'name',
		supportsources: 'name',
		supportconversations: 'id',
		emailTemplate: 'id',
		bankAccount: 'id',
		stats: 'id, type',
		funnels: 'id',
		briefcase: 'id,linkedtype, linkedid',
		listingFilter: '[name+listing]',
		opportunitiesstats: 'funnelid',
		supportTemplates: 'id, name',
		groups: 'id',
		emailThread: "id, orderByAttribute",
		folder: "id",
		emailDraft: "id",
		message: "id",
		tokens: "id, token"
	}).upgrade(function () {
		db['listingFilter'].clear();
	});

	window.onunhandledrejection = function (event) {

		if (event.reason) {
			if (event.reason.name === 'VersionError') {

				console.log("deleteDatabase");

				db.delete().then(function () {
					location.reload();
				});
			}

			//inxededDB not avalaible
			if (event.reason.name === 'MissingAPIError') {
				App.indexedDB = false;
			}

			var reason = event.reason;
			console.warn('Unhandled promise rejection: ' + (reason && (reason.stack || reason)));
		}
	};

	Dexie.Promise.on('error', function (err) {
		// Catch all uncatched DB-related errors and exceptions
		App.monitoring.captureException(err, {
			extra: {}
		});
	});

	return db;
});
