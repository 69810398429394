/**
 * @desc Email Form, extend Page View
 * Child View : Core Form
 */
define('views/emailBox/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/message', 'app', 'tools/buildURL', 'tinyMCE', 'models/collections/emailTemplates', 'models/collections/thirds', 'models/collections/suppliers', 'models/collections/prospects', 'models/collections/peoples', 'models/collections/folders', 'tools/core/utils', 'models/singles/filePicture', 'models/singles/document', 'moment', 'models/singles/emailDraft', 'models/singles/emailThread', 'views/core/popupAction', 'views/widgets/nativeActionSheet', 'views/core/toastNotification'], function (Backbone, _, Page, Form, Message, App, URLBuilder, TinyMCE, EmailTemplates, Thirds, Suppliers, Prospects, Peoples, Folders, Utils, FilePicture, Document, Moment, EmailDraft, EmailThread, PopupAction, ActionSheet, ToastNotif) {

    'use strict';

    return Page.extend({

        events: {
            'change #mailTemplates': 'setTemplate'
        },
        id: 'mail-form',
        template: "templates/emailbox/form.twig",
        title: App.polyglot.t("newMessage"),
        form: {},
        toastMessages: {
            emailSaved: App.polyglot.t('emailSend'),
            emailUpdated: App.polyglot.t('emailUpdated')
        },

        /**
         *
         */
        onBackCallback: function () {

            var _this = this;

            return new Promise(function (resolve, reject) {

                if (this.context === 'draft') {

                    if (App.useActionSheet) {

                        new ActionSheet({
                            title: App.polyglot.t('doYouWantsaveDraft'),
                            buttons: [{
                                title: _this.polyglot.t('add'),
                                callback: function () {

                                    _this.form.$el.trigger('submit');
                                    resolve();
                                }
                            }],
                            cancelLabel: App.polyglot.t('cancel'),
                            cancelCallback: function () {
                                resolve();
                            }
                        });
                    } else {

                        //init popup for upload confirmation
                        var popup = new PopupAction({
                            id: 'saveDraft',
                            title: App.polyglot.t('doYouWantsaveDraft'),
                            content: "",
                            buttons: [{
                                title: App.polyglot.t('yes'),
                                classes: "btn button-with-network btn-submit",
                                onclick: function () {
                                    _this.form.$el.trigger('submit');
                                    resolve();
                                }
                            }, {
                                title: App.polyglot.t('no'),
                                classes: "btn btn-cancel btn-blue",
                                onclick: function () {
                                    //do nothing just go back
                                    resolve();
                                }
                            }],
                            callback: function () {
                                reject();
                            },
                            containerBtn: '.modal-footer'
                        });
                    }
                } else {

                    if (App.useActionSheet) {

                        new ActionSheet({
                            title: App.polyglot.t('doYouWantsaveDraft'),
                            buttons: [{
                                title: _this.polyglot.t('add'),
                                callback: function () {

                                    _this.modelToSave = new EmailDraft();

                                    _this.form.$el.trigger('submit');
                                    resolve();
                                }
                            }],
                            cancelLabel: App.polyglot.t('cancel'),
                            cancelCallback: function () {
                                resolve();
                            }
                        });
                    } else {

                        //init popup for upload confirmation
                        var popup = new PopupAction({
                            id: 'saveDraft',
                            title: App.polyglot.t('doYouWantsaveDraft'),
                            content: "",
                            buttons: [{
                                title: App.polyglot.t('yes'),
                                classes: "btn button-with-network btn-submit",
                                onclick: function () {

                                    _this.modelToSave = new EmailDraft();
                                    _this.form.$el.trigger('submit');

                                    resolve();
                                }
                            }, {
                                title: App.polyglot.t('no'),
                                classes: "btn btn-cancel btn-blue",
                                onclick: function () {
                                    //do nothing just go back
                                    resolve();
                                }
                            }],
                            callback: function () {
                                reject();
                            },
                            containerBtn: '.modal-footer'
                        });
                    }
                }
            });
        },

        actionList: {

            sendmail: {
                // img		: "send_mail.svg",
                fa: 'fa-paper-plane-o',
                link: function (e) {
                    $(e.currentTarget).hide();
                    $(e.currentTarget).parent().append('<i class="fa fa-circle-o-notch fa-spin"></i>');
                    this.form.sendDataForm(e);
                },
                aClass: "sendMail vertical-center"
            }

        },
        options: {},

        /**
         *
         * @param {Backbone.router} router
         * @param threadId
         * @param linkedid
         * @param linkedtype
         */
        initialize: function (router, box, context, messageId, linkedtype, linkedid) {

            Page.prototype.initialize.apply(this, arguments);

            linkedtype = linkedtype === "prospect" || linkedtype === 'supplier' ? "third" : linkedtype;

            var _this = this;
            this.context = context;
            this.messageId = messageId;
            this.emailBox = box;

            this.signatureBefore = App.currentCorp.get('preferences')['signatureBefore'];

            this.modelToSave = new Message();
            this.suppliers = new Suppliers();
            this.prospects = new Prospects();
            this.thirds = new Thirds();
            this.peoples = new Peoples();
            this.staffs = App.staffs.clone();
            this.templates = new EmailTemplates();
            this.folders = new Folders();

            this.models[this.modelToSave.name] = this.modelToSave;
            this.collections[this.folders.name] = this.folders;

            this.options.extraData = {
                signature: function () {
                    return EmailThread.getSignature();
                },
                nylasPrefs: function () {

                    return new Promise(function (resolve, reject) {

                        EmailThread.getNylasPrefs().then(function (nylasPrefs) {
                            App.nylasPrefs = nylasPrefs;
                            resolve();
                        });
                    });
                }
            };

            if (linkedid && linkedtype) {

                this.isLinkedListing = true;
                this.linkedid = linkedid;
                this.linkedtype = linkedtype;

                this.var4template("linked", {
                    linkedid: linkedid,
                    linkedtype: linkedtype
                });

                this.templates.XHRData = {
                    linkedid: linkedid,
                    linkedtype: linkedtype
                };
            }

            if (messageId != 0) {

                if (context !== "draft") {

                    this.message = new Message({ id: messageId });
                    this.message.mustFetchLocal = true;
                } else {
                    this.message = new EmailDraft({ id: messageId });
                }

                this.models[this.message.name] = this.message;
            }

            //load custom email templates
            var templatesPromise = new Promise(function (resolve, reject) {

                _this.templates.fetch({ method: "POST", data: JSON.stringify(_this.templates.XHRData) }).then(function (data) {

                    //get email template for doctype
                    if (Utils.inArray(linkedtype, App.availableDoctypes)) {

                        Document.getMailTemplate(linkedtype, linkedid).then(function (template) {

                            _this.docTplId = template.id;
                            _this.templates.add(template);
                            _this.var4template("templates", _this.templates.toJSON());
                            resolve();
                        });
                    } else {
                        _this.var4template("templates", _this.templates.toJSON());
                        resolve();
                    }
                }, function () {
                    _this.router.navigate("#emailBox/syncError", { trigger: true, replace: true });
                });
            });

            templatesPromise.then(_.bind(function () {

                this.router = router;
                this.form = new Form({ parentView: this });

                //get emails available in selectize and default option
                this.buildEmailsList().then(function (options) {

                    _this.emailList = options[0];
                    _this.defaultEmail = options[1];

                    _this.subviews.push(_this.form);
                    _this.render();
                });

                this.listenTo(this.form, 'dataSend', this.formAction);
            }, this));
        },

        /**
         *
         * @returns {undefined}
         */
        afterRender: function () {

            var _this = this;

            //on choisi les emails à set par défaut dans les seletizes selon l'action souhaitée (répondre/répondre à tous ... et la boite dans laquelle on se trouvait
            switch (this.context) {

                case 'reply':

                    var sender = this.message.get('from')[0].email;

                    if (App.nylasPrefs.email === sender) {

                        this.defaultEmail = this.message.get('to').map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        Array.prototype.push.apply(this.emailList, bccEmails);
                    } else {

                        this.defaultEmail = this.message.get('from').map(function (email) {
                            email.id = email.email;
                            return email;
                        });
                    }

                    Array.prototype.push.apply(this.emailList, this.defaultEmail);
                    Array.prototype.push.apply(this.emailList, ccEmails);

                    break;

                case 'draft':

                    this.defaultEmail = this.message.get('to').map(function (email) {
                        email.id = email.email;
                        return email;
                    });

                    Array.prototype.push.apply(this.emailList, this.defaultEmail);

                    break;

                case 'replyAll':

                    var sender = this.message.get('from')[0].email;

                    if (App.nylasPrefs.email === sender) {

                        this.defaultEmail = this.message.get('to').map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        var ccEmails = this.message.get('cc').map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        var bccEmails = this.message.get('bcc').map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        Array.prototype.push.apply(this.emailList, bccEmails);
                    } else {

                        this.defaultEmail = this.message.get('from').map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        var ccEmails = this.message.get('to').filter(function (email) {
                            return email.email !== App.nylasPrefs.email;
                        });

                        var cc2Emails = this.message.get('cc').filter(function (email) {
                            return email.email !== App.nylasPrefs.email;
                        });

                        ccEmails = ccEmails.map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        cc2Emails = cc2Emails.map(function (email) {
                            email.id = email.email;
                            return email;
                        });

                        Array.prototype.push.apply(ccEmails, cc2Emails);
                    }

                    Array.prototype.push.apply(this.emailList, this.defaultEmail);
                    Array.prototype.push.apply(this.emailList, ccEmails);

                    break;

            }

            this.selectizeObjects = [{
                domElt: $("input[name='mail[emails]']", this.$el),
                create: true,
                searchField: ['email', 'name'],
                valueField: 'id',
                labelField: 'email',
                options: this.emailList,
                maxItems: 10,
                maxOptions: 10,
                defaultValues: _.isArray(_this.defaultEmail) ? _this.defaultEmail : [_this.defaultEmail],
                load: this.loadOption.bind(this),
                render: 'email'
            }, {
                domElt: $("input[name='mail[emailsCC]']", this.$el),
                create: true,
                searchField: ['email', 'name'],
                valueField: 'id',
                labelField: 'email',
                options: this.emailList,
                maxItems: 10,
                maxOptions: 10,
                load: this.loadOption.bind(this),
                render: 'email',
                defaultValues: ccEmails
            }, {
                domElt: $("input[name='mail[emailsBCC]']", this.$el),
                create: true,
                searchField: ['email', 'name'],
                valueField: 'id',
                labelField: 'email',
                options: this.emailList,
                maxItems: 10,
                maxOptions: 10,
                load: this.loadOption.bind(this),
                render: 'email',
                defaultValues: bccEmails
            }];

            Page.prototype.afterRender.apply(this, arguments);

            TinyMCE.init({
                selector: '.body-mail',
                toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
                plugins: "autoresize",
                menubar: false,
                statusbar: false,
                setup: function (editor) {

                    editor.on('init', _.bind(function (args) {

                        var message = "";
                        var subject = "";

                        _this.editor = editor;

                        //Set mail body depending context
                        switch (_this.context) {

                            case 'reply':
                            case 'replyAll':

                                message = "<br />" + App.polyglot.t('the') + " " + Moment(_this.message.get("date"), "X").format(App.dateFormat.momentFormat + " " + App.hourFormat) + "<br />" + '<blockquote class="gmail_extra">' + _this.message.get('body') + '</blockquote>';

                                subject = App.polyglot.t('re') + ": " + _this.message.get('subject');

                                break;

                            case 'forward':

                                message = "<br />" + App.polyglot.t('the') + " " + Moment(_this.message.get("date"), "X").format(App.dateFormat.momentFormat + " " + App.hourFormat) + "<br />" + '<blockquote class="gmail_extra">' + _this.message.get('body') + '</blockquote>';

                                subject = App.polyglot.t('tr') + ': ' + _this.message.get('subject');

                                break;

                            case 'draft':

                                message = _this.message.get('body');
                                subject = _this.message.get('subject');

                                break;

                        }

                        if (_this.signatureBefore === "Y") {
                            message = _this.models.signature + message;
                        } else {
                            message += _this.models.signature;
                        }

                        $("[name='mail[subject]']").val(subject);
                        _this.editor.setContent(message);
                    }, this));
                }
            });
        },

        /**
         * @description fetch thirds and peoples for populate selectize depending query
         * @param query
         * @param callback
         * @returns {*|c}
         */
        loadOption: function (query, callback) {

            var _this = this;

            return new Promise(function (resolve, reject) {

                if (_this.isLinkedListing) {
                    resolve();
                } else {

                    var promises = [];

                    if (App.permission.level('clientlevel')) {

                        promises.push(_this.thirds.fetch({

                            method: 'POST',
                            data: {
                                search: {
                                    contains: query
                                }
                            }

                        }));
                    }

                    if (App.permission.level('prospectlevel')) {
                        promises.push(_this.prospects.fetch({

                            method: 'POST',
                            data: {
                                search: {
                                    contains: query
                                }
                            }

                        }));
                    }

                    if (App.permission.level('supplierlevel')) {
                        promises.push(_this.suppliers.fetch({

                            method: 'POST',
                            data: {
                                search: {
                                    contains: query
                                }
                            }

                        }));
                    }

                    if (App.permission.level('peoplelevel') || App.permission.level('supplierlevel') || App.permission.level('prospectlevel') || App.permission.level('clientlevel')) {

                        promises.push(_this.peoples.fetch({

                            method: 'POST',
                            data: {
                                search: {
                                    contains: query
                                }
                            }

                        }));
                    }

                    Promise.all(promises).then(function () {

                        var thirds = new Thirds();
                        thirds.add(_this.thirds.toJSON());
                        thirds.add(_this.prospects.toJSON());
                        thirds.add(_this.suppliers.toJSON());

                        thirds.each(function (third) {

                            if (third.get('email')) {
                                _this.emailList.push({ name: third.get('name'), email: third.get('email'), id: "third_" + third.get('id'), avatar: third.get('avatar') });
                            }
                        });

                        _this.peoples.each(function (people) {

                            if (people.get('email')) {
                                _this.emailList.push({ name: people.get('fullName'), email: people.get('email'), id: 'people_' + people.get('id'), avatar: people.get('avatar') });
                            }
                        });

                        resolve(_this.emailList);
                    }, function (error) {
                        reject(error);
                    });
                }
            });
        },

        /**
         * @description get emails list for populate selectize :
         *  -- for a third we get staffs, email of third and emails of contacts
         *  -- for a people we get staffs and email of contact
         *  -- for a doc we get staffs and email of linked third
         *  -- for an opp we get staffs and email of liked third and emails of linked contacts
         *  -- for a global context we get staff, thirds will be added with loadOption method
         * @returns {Array}
         */
        buildEmailsList: function () {

            var emailsList = [];
            var _this = this;

            return new Promise(function (resolve, reject) {

                _this.staffs.each(function (staff) {

                    emailsList.push({
                        name: staff.get('fullName'),
                        email: staff.get('email'),
                        id: 'staff_' + staff.get('id'),
                        avatar: staff.get('avatar')
                    });
                });

                if (_this.isLinkedListing) {

                    switch (_this.linkedtype) {

                        case 'third':

                            require(['models/singles/third'], function (Third) {

                                var linked = new Third({ id: _this.linkedid });

                                linked.fetchLocal().then(function () {

                                    var emailDefault = linked.get("email");

                                    if (emailDefault) {
                                        var buildedId = 'third_' + linked.get('id');
                                        emailsList.push({ name: linked.get('name'), email: emailDefault, id: buildedId, avatar: linked.get('avatar') });
                                    }

                                    _.each(linked.get('contacts'), function (contact) {

                                        if (contact.email) {
                                            emailsList.push({ name: contact.fullName, email: contact.email, id: 'third_' + linked.get('id') + "_" + contact.id, avatar: contact.avatar });
                                        }
                                    });

                                    resolve([emailsList, buildedId]);
                                });
                            });

                            break;

                        case 'people':

                            require(['models/singles/people'], function (People) {

                                var linked = new People({ id: _this.linkedid });

                                linked.fetch().then(function () {

                                    var emailDefault = linked.get('email');

                                    if (emailDefault) {
                                        var buildedId = 'people_' + linked.get('id');
                                        emailsList.push({ name: linked.get('fullName'), email: emailDefault, id: buildedId, avatar: linked.get('avatar') });
                                    };

                                    resolve([emailsList, buildedId]);
                                });
                            });

                            break;

                        case "opportunity":

                            require(['models/singles/opportunity', 'models/singles/third', 'models/singles/prospect'], function (Opportunity, Client, Prospect) {

                                var linked = new Opportunity({ id: _this.linkedid });

                                linked.fetchLocal().then(function () {

                                    console.log(linked);

                                    var Third = linked.get('relationType') == "prospect" ? Prospect : Client;
                                    var third = new Third({ id: linked.get('linkedid') });

                                    third.fetch().then(function () {

                                        if (third.get('email')) {
                                            var buildedId = 'third_' + third.get('id');
                                            emailsList.push({ name: third.get('name'), email: third.get("email"), id: buildedId, avatar: third.get('avatar') });
                                        }

                                        _.each(linked.get("contacts"), function (contact) {
                                            if (contact.email) {
                                                emailsList.push({ name: contact.fullName, email: contact.email, id: "people_" + contact.id, avatar: contact.avatar });
                                            }
                                        });

                                        resolve([emailsList, buildedId]);
                                    });
                                });
                            });

                            break;

                        case 'invoice':
                        case 'estimate':
                        case 'proforma':
                        case 'delivery':
                        case 'order':
                        case 'creditnote':

                            require(['models/singles/document', 'models/singles/third', 'models/singles/prospect'], function (Document, Client, Prospect) {

                                var linked = new Document({ id: _this.linkedid });

                                linked.fetchLocal().then(function () {

                                    var Third = linked.get('thirdRelationType') === "prospect" ? Prospect : Client;
                                    var third = new Third({ id: linked.get('thirdid') });

                                    third.fetch().then(function () {

                                        if (third.get('email')) {
                                            var buildedId = 'third_' + third.get('id');
                                            emailsList.push({ name: third.get('name'), email: third.get("email"), id: buildedId, avatar: third.get('avatar') });
                                        }

                                        _.each(third.get('contacts'), function (contact) {

                                            if (contact.email) {
                                                emailsList.push({ name: contact.fullName, email: contact.email, id: 'people_' + contact.id, avatar: contact.avatar });
                                            }
                                        });

                                        resolve([emailsList, buildedId]);
                                    });
                                });
                            });

                            break;

                        case 'purInvoice':
                        case 'purDelivery':
                        case 'purOrder':
                        case 'purCreditNote':
                        case 'purCreditnote':

                            require(['models/singles/purchase', 'models/singles/supplier'], function (Purchase, Supplier) {

                                var linked = new Purchase({ id: _this.linkedid });

                                linked.fetchLocal().then(function () {

                                    var third = new Supplier({ id: linked.get('thirdid') });

                                    third.fetch().then(function () {

                                        if (third.get('email')) {
                                            var buildedId = 'third_' + third.get('id');
                                            emailsList.push({ name: third.get('name'), email: third.get("email"), id: buildedId, avatar: third.get('avatar') });
                                        }

                                        resolve([emailsList, buildedId]);
                                    });
                                });
                            });

                            break;

                    }
                } else {
                    resolve([emailsList]);
                }
            });

            return emailsList;
        },

        /**
         * @description get name and email of selected object depending linkedtype
         * @param {string} buildedId - builded id following this format "linkedtype_linkeid"
         * @returns {{email: *}|{name, email}}
         */
        getDataForObject: function (buildedId) {

            var arrayValues = buildedId.split('_');

            //length = 1 for cutsom email
            if (arrayValues.length == 1) {

                var dataForEmails = {
                    email: arrayValues[0]
                };
            } else {

                var linkdetype = arrayValues[0];
                var linkedid = arrayValues[1];

                var linked = _.first(_.filter(this.emailList, function (email) {
                    return email.id == buildedId;
                }));

                var dataForEmails = {
                    'name': linked['name'],
                    'email': linked['email']
                };
            }

            return dataForEmails;
        },

        /**
         * @description set template in redactor and subject input when we choose it in select input
         * @param event e
         * @returns {undefined}
         */
        setTemplate: function (e) {

            var $currentTarget = $(e.currentTarget);
            var tplId = $currentTarget.val();

            if (tplId != 0) {

                var templateModel = this.templates.get(tplId);
                var templateMessage = templateModel.get('message');
                templateMessage += this.models.signature;

                this.editor.setContent(templateMessage);
                $('input[name="mail[subject]"]', this.$el).val(templateModel.get('subject'));
            } else {

                $('input[name="mail[subject]"]', this.$el).val('');
                this.editor.setContent('');
            }
        },

        /**
         *
         */
        getLinked: function (recipients) {

            var link = {};

            _.every(recipients, function (recipient) {

                var recipient = recipient.split('_');

                if (recipient.length > 1 && recipient[0] !== 'staff') {

                    link = {
                        linkedtype: recipient[0],
                        linkedid: recipient[1]
                    };

                    return false;
                }
            });

            return link;
        },

        /**
         *  @description action when form is submit
         */
        formAction: function () {

            var dataForm = this.form.dataForm;
            var _this = this;

            dataForm["mail"]['recipients'] = [];
            dataForm["mail"]['emails'] = dataForm["mail"]['emails'].split(',');
            dataForm["mail"]['emailsCC'] = dataForm["mail"]['emailsCC'] ? dataForm["mail"]['emailsCC'].split(',') : "";
            dataForm["mail"]['emailsBCC'] = dataForm["mail"]['emailsBCC'] ? dataForm["mail"]['emailsBCC'].split(',') : "";
            dataForm['mail']['message'] = dataForm['mail']['content'];
            dataForm['mail']['file'] = $('[name="mail[file]"]')[0].files[0]; //sendDataFormV2 didn't catch file input

            if (dataForm.mail.file) {

                var file = new FilePicture();
                file.set({
                    file: dataForm.mail.file
                });
            }

            if (!dataForm.mail['linkedtype'] || !dataForm.mail['linkedid']) {

                var link = this.getLinked(dataForm['mail']['emails']);
                dataForm['mail'] = _.extend(dataForm['mail'], link);
            }

            _.each(dataForm["mail"]['emails'], function (email, key) {

                var recipientsData = _this.getDataForObject(email);

                if (recipientsData) {
                    dataForm['mail']['recipients'].push(recipientsData);
                }
            });

            dataForm['mail']['cc'] = [];
            _.each(dataForm["mail"]['emailsCC'], function (email) {

                var recipientsData = _this.getDataForObject(email);

                if (recipientsData) {
                    dataForm['mail']['cc'].push(recipientsData);
                }
            });

            dataForm['mail']['bcc'] = [];
            _.each(dataForm["mail"]['emailsBCC'], function (email) {

                var recipientsData = _this.getDataForObject(email);

                if (recipientsData) {
                    dataForm['mail']['bcc'].push(recipientsData);
                }
            });

            this.modelToSave.set(dataForm.mail);

            if (!file || file.isValid()) {

                if (this.modelToSave.isValid()) {

                    dataForm['mail']['recipients'] = JSON.stringify(dataForm['mail']['recipients']);
                    dataForm['mail']['cc'] = JSON.stringify(dataForm['mail']['cc']);
                    dataForm['mail']['bcc'] = JSON.stringify(dataForm['mail']['bcc']);

                    switch (_this.context) {

                        case "reply":
                            dataForm['mail']['replyid'] = _this.message.get("id");
                            break;

                        case "draft":
                            dataForm['mail']['id'] = _this.message.get("id");
                            dataForm['mail']['version'] = _this.message.get("version");
                            break;
                    }

                    this.modelToSave.set(dataForm['mail']);

                    var toastMsg = this.modelToSave.isNew() ? this.toastMessages.emailSaved : this.toastMessages.emailUpdated;

                    this.modelToSave.save({ savelocal: false }, { validate: false }).then(function () {

                        //when we save a draft , return is already triggered by return arrow
                        if (_this.modelToSave.name !== 'emailDraft') {

                            console.log(_this.context);

                            //delete draft
                            if (_this.context === 'draft') {

                                var draftToDelete = new EmailThread({ id: _this.message.id });

                                draftToDelete.destroyLocal().then(function () {
                                    window.history.back();
                                });
                            } else {
                                window.history.back();
                            }
                        }

                        _this.form.trigger('successful', toastMsg);
                    }, function () {
                        // re activate submit button
                        $('.submit-form', _this.form.$el).removeAttr('disabled');
                    });
                } else {

                    // re activate submit button
                    $('.submit-form', this.form.$el).removeAttr('disabled');

                    //Make invalid inputs in error
                    _.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

                        if (!_.isEmpty(error)) {

                            _.each(error, function (msgError, keyMsg) {
                                _this.form.showValidationError(keyError, keyMsg, msgError, true);
                            });
                        }
                    });
                }
            } else {

                new ToastNotif({
                    'id': 'toast-error',
                    'content': App.polyglot.t(file.validationError),
                    classes: 'col-xs-12',
                    timeDuration: 3000
                });

                $('.submit-form', this.form.$el).removeAttr('disabled');
            }

            return false;
        }

    });
});
