/**
* @desc TogglePage, extend Page View
*/

define('views/core/ToggleablePage',['underscore', 'views/core/view', 'tools/core/utils', 'app'], function (_, View, Utils, App) {

	'use strict';

	return View.extend({

		className: 'toggleablePage',
		onShow: function () {},
		onHide: function () {},
		onBackCallback: function () {

			return new Promise(function (resolve, reject) {
				resolve();
			});
		},

		events: {
			'show': function (e, params) {

				var _this = this;

				this.mainView.$el.transition({ x: -$(window).width() + 'px', duration: 550 });
				_this.onShow.apply(this, arguments);

				var position;

				if ($(window).width() >= 768 && $('.content-wrapper-toggled').length) {
					position = $(window).width() - $('#side-bar').width();
				} else {
					position = $(window).width();
				}

				this.$el.transition({ x: -position + 'px', duration: 550 }, function () {
					_this.$el.addClass('toggled');
				});
			},
			'hide': function (e, params) {

				var _this = this;

				this.mainView.$el.transition({ x: '0px', duration: 550 });

				_this.onHide.apply(this, arguments);

				this.$el.transition({ x: $(window).width() + 'px', duration: 550 }, function () {
					_this.$el.removeClass('toggled');
				});
			}

		},

		actionList: {},

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			this.modelsFetched = {};
			this.models = {};
			this.collections = {};
			this.renderPromises = [];
			this.subviews = [];

			View.apply(this, arguments);
		},

		/**
   * 
   * @param {type} router
   * @returns {undefined}
   */
		initialize: function (router, options) {

			View.prototype.initialize.apply(this, arguments);

			this.mainView = options.mainView; //page view
			this.modelsFetched = options.modelsFetched;
			this.router = router;
		},

		/**
   * 
   * @returns {Promise}
   */
		render: function () {

			var _this = this;

			this.renderPromises = [];

			return new Promise(_.bind(function (resolve, reject) {

				_.each(this.subviews, function (subview) {

					if (subview.doRender !== false) {
						_this.renderPromises.push(subview.render());
					}
				});

				Promise.all(this.renderPromises).then(function () {
					_this.afterRender();
					resolve();
				}, function (e) {
					reject();
					console.log(e);
				}).catch(function (e) {
					console.log(e);
				});
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			this.delegateEvents();
			this.$.contentWrapper.prepend(this.$el);

			//init all selectize
			this.initSelectize();
		}

	});
});
