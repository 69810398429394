/**
 * 
 */
define('views/widgets/linkedStaffs',['underscore', 'views/core/view', 'app', 'tools/core/utils'], function (_, View, App, Utils) {

	'use strict';

	return View.extend({

		events: {
			"click .cta": "toggleEditableMode",
			"click .remove-staff": "removeStaff"
		},

		/**
   * 
   */
		template: '\n\t\t\t<% _.each(staffs,function(staff) { %>\n\t\t\t\t\n\t\t\t\t\n\t\t\t\t<li data-staffid="<%= staff.id %>">\n\t\t\t\t\t<% if(staff.avatar.type === \'initials\') { %>\n\t\t\t\t\t\t<div class="avatar-initials avatar-32"> <%= staff.avatar.value %> </div>\n\t\t\t\t\t\t<div class="staffName"><%= staff.fullName %></div>\n\t\t\t\t\t<% }\xA0else { %>\n\t\t\t\t\t\t<img class="img-32" src=\'<%= App.fileUrl + staff.avatar.value %>\'/>\n\t\t\t\t\t\t<div class="staffName"><%= staff.fullName %></div>\n\t\t\t\t\t<% } %>\n\t\t\t\t\t\t<div class=" remove-staff icon-32 fright"><i class="icon icon-close"></i></div>\n\t\t\t\t</li>\n\t\t\t<% }) %>\n\t\t\t<li>\n\t\t\t\t<input data-catchable="N" class="staff-selectize" type="text" name="staff-selectize"/>\n\t\t\t</li>\n\t\t\t<li class="flex-right">\n\t\t\t\t<img class="cta icon-32" src="/ressources/images/add.svg">\n\t\t\t</li>\n\t\t',

		/**
   * @desc initialize
   */
		initialize: function (options, staffs) {
			View.prototype.initialize.apply(this, arguments);
			this.staffs = staffs;
		},

		render: function () {

			this.staffs = _.map(this.staffs, function (staff) {
				staff.id = parseInt(staff.id);
				staff.avatar = staff.avatar ? staff.avatar : staff.advpic;
				return staff;
			});

			var staffsOptions = Utils.getArrayObjDiff(App.staffs.toJSON(), this.staffs);

			var template = _.template(this.template);
			this.$el.html(template({ staffs: this.staffs }));

			this.selectizeObjects = [{
				domElt: $(".staff-selectize", this.$el),
				options: staffsOptions,
				create: false,
				labelField: 'fullName',
				valueField: 'id',
				searchField: ['fullName'],
				onItemAdd: this.selectizeOnStaffAdd,
				render: 'staff',
				placeholder: App.polyglot.t("addStaff")
			}];

			this.initSelectize();
		},

		/**
   * @description triggered when we add a linked staff, add a row for choose if new staff can update event or not
   * @param {type} value
   * @param {type} item
   * @returns {undefined}
   */
		selectizeOnStaffAdd: function (staffId) {

			$('.staff-selectize')[0].selectize.removeItem(staffId);
			$('.staff-selectize')[0].selectize.removeOption(staffId);

			this.staffs.push(App.staffs.get(staffId).toJSON());
			this.render();
		},

		/**
   * 
   * @param {type} ev
   * @returns {undefined}
   */
		toggleEditableMode: function (ev) {

			var $currentTarget = $(ev.currentTarget);
			this.$el.toggleClass('editing');

			if (!this.$el.hasClass("editing")) {
				this.trigger('editCompleted', this.staffs);
			}
		},

		/**
   * 
   * @param {type} ev
   * @returns {undefined}
   */
		removeStaff: function (ev) {

			var $row = $(ev.currentTarget).closest('li');
			var staffid = $row.data('staffid');

			//remove in event staffs list
			this.staffs = _.filter(this.staffs, function (staff) {
				return staff.id != staffid;
			});

			this.render();
		}

	});
});
