/**
 * Child View : Core Listing
 */
define('views/support/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/tickets', 'app'], function (_, PageList, Listing, URLBuilder, Tickets, App) {

	'use strict';

	return PageList.extend({

		addAction: function (e) {

			if (this.thirdid) {
				this.listing.goToForm(URLBuilder(['ticket', 'form', this.thirdid]));
			} else {
				this.listing.goToForm(URLBuilder(['ticket', 'form']));
			}
		},

		title: App.polyglot.t('ticketlisting'),
		listing: {},

		placeholderSearchBar: App.polyglot.t('ref') + ', ' + App.polyglot.t('subject') + ', ' + App.polyglot.t('message') + ', ' + App.polyglot.t('email'),
		template: "templates/support/list.twig",

		/**
   * @desc initialize
   */
		initialize: function (router, thirdid) {

			this.canAdd = App.permission.can("support_createticket");
			this.name = thirdid ? "third_tickets" : 'tickets';

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.router = router;
				this.collection = new Tickets();
				this.collection.XHRData = {
					search: {
						"assigned": 'mine'
					}
				};

				this.checkBeforeOverview = this.checkButton;
				this.listing = new Listing({ parentView: this });

				//override filter defined in listing core with linked third
				if (thirdid) {

					this.thirdid = thirdid;
					this.collection.XHRData.search["thirds"] = [thirdid];
					this.collection.XHRData.search["thirdid"] = thirdid;
					this.var4template('thirdname', localStorage.getItem('linkedName'));
				}

				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			PageList.prototype.afterRender.apply(this, arguments);
		}

	});
});
