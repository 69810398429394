/**
 * @desc form Core  extend Core View
 */
define('views/core/form',['backbone', 'underscore', 'views/core/view', 'app', 'views/core/toastNotification', 'moment', 'tinyMCE', 'tools/buildURL', 'libs/jquery.serialize-object.min'], function (Backbone, _, View, App, Notif, Moment, TinyMCE, URLBuilder) {

	'use strict';

	return View.extend({

		events: {
			"submit": 'sendDataForm',
			"focus .has-error input": 'resetInput',
			"change .number-format": 'formatNumber',
			'focus input': 'togglePlaceholder',
			'blur input': "togglePlaceholder",

			/**
    * @desc dont know why but if i remove tis event, selectize dropdown cant be closed :(
             */
			"click .cancel": function () {}

		},

		tagName: "form",
		className: "form form-horizontal container-fluid",
		attributes: {
			enctype: "multipart/form-data"
		},
		collections: {},
		models: {},
		modelToSave: {},
		dataForm: [],
		template: '',
		inputArray: [],

		/**
   *
   * @param {type} view
   * @param {type} insertIn
   * @param {type} target
   * @returns {undefined}
   */
		initialize: function (view, insertIn, target) {

			View.prototype.initialize.apply(this, arguments);

			this.classes = view.parentView.classes;
			this.template = view.template ? view.template : view.parentView.template;
			this.parentView = view.parentView;
			this.modelToSave = view.parentView.modelToSave;
			this.models = view.parentView.models;
			this.header = view.parentView.header;
			this.router = view.parentView.router;
			this.collections = view.parentView.collections;
			this.options = view.parentView.options;
			this.$insertIn = _.isUndefined(insertIn) ? this.parentView.$el : insertIn; //where the form will be insterted

			//When domElt is already in DOM , we just need to attach backbone view to DOMElt
			if (!_.isUndefined(target)) {

				this.doRender = false;

				var _this = this;

				//Cette fonction va être appelée une fois que la vue principale est dans le dom avec l'objet que l'ont souhaite sélectionner pour notre vue
				this.insertInDom = function () {
					_this.setElement(target);
					_this.delegateEvents();
				};
			}

			//call render for popup
			if (view.parentView.$el.hasClass('modal')) {
				this.render();
			}
		},

		/**
   * @desc insert the form in the DOM
   */
		renderCallback: function () {

			if (App.header) {
				//hide menuburger icon
				App.header.$.brandMenu.addClass('hidden');
			}

			this.$insertIn.html(this.$el);

			this.parentView.scrollable = '.form';

			if (!_.isUndefined(this.classes)) {
				this.$el.addClass(this.classes);
			};

			this.on('successful', this.showConfirmation.bind(this));
		},

		/**
   *
   * @param {type} $context
   * @returns {undefined}
   */
		disabledSubmitBtn: function ($context) {
			$('.submit-form', $context).button('loading');
		},

		/**
   *
   * @param {type} $content
   * @returns {undefined}
   */
		enableSubmitBtn: function ($context) {
			$('.submit-form', $context).button('reset');
		},

		/**
   * @desc magic function that takes all inputs datas and put it into a multidimensional object
   * @returns {undefined}
   */
		sendDataFormV2: function () {
			var form = this.$el.find('form').length == 1 ? this.$el.find('form').serializeObject() : this.$el.closest('form').serializeObject();
			$('.submit-form', form).prop('disabled', true);
			return form;
		},

		/**
   *  @desc action when form is submit
   *   build an array with name and input value
   *   fired dataSend Event to parent View
   */
		sendDataForm: function (e, temp, goTo, $eventTarget) {

			var numberFormat = this.parentView.numberFormat ? this.parentView.numberFormat : App.numberFormat;

			if (temp && _.isObject(temp) && temp.hasClass('modal')) {
				var context = temp;
			} else {
				var context = e.currentTarget;
			}

			//disable submit button for prevent multiple click
			$('.submit-form', context).prop('disabled', true);
			//			$('.tooltip').tooltip('destroy');

			TinyMCE.triggerSave(); //save text editor content in textarea

			var myRegex = new RegExp("([a-z0-9]*)(?:\\[([a-z0-9_]*)\\])", "i");
			var data = {};
			var $allInput = $(' *[data-catchable="Y"], input:not(input[data-catchable="N"]), select:not(select[data-catchable="N"]), radio:not(radio[data-catchable="N"]), textarea:not(textarea[data-catchable="N"])', this.$el);
			var inputDisabled = [];

			//loop throught every form input
			$allInput.each(function (index, element) {

				if (element.name == '') {
					return true;
				}

				//enable input disabled for save them
				if (element.disabled === true) {
					inputDisabled.push(element);
					$(element).prop('disabled', false);
				}

				if ($(element).is($('input,select,radio,textarea'))) {

					var name = element.name;
					var value = element.value;
					var result = myRegex.exec(name);
				} else {
					//case of element is not an input but div,span,ect..

					var name = $(element).data('nameattr');
					var value = $(element).data('value');
					var result = myRegex.exec(name);
				}

				//init data
				if (data[result[1]] === undefined) {
					data[result[1]] = {};
				}

				if (element.type !== 'select-one') {

					//if element have data-attribute, add it in result
					if (!_.isEmpty($(element).data())) {

						_.each($(element).data(), function (attr, name) {
							if (name !== 'catchable' && name !== 'value' && name !== 'nameattr' && name !== 'selectize' && name !== 'bs.tooltip' && name !== 'DateTimePicker') {
								data[result[1]][name] = attr;
							}
						});
					}
				}

				switch (element.type) {

					case 'select-one':

						data[result[1]][result[2]] = value;

						var $selected = $(element).find('option:selected');

						//if element have data-attribute, add it in result
						if (!_.isEmpty($selected.data())) {
							_.each($selected.data(), function (attr, name) {
								if (name !== 'catchable' && name !== 'value') {
									data[result[1]][name] = attr;
								}
							});
						}

						break;

					// in case of checkbox we take only checked val
					case 'checkbox':

						if ($(element).attr('data-multidimensional') == 'Y') {

							if (element.checked === true) {

								if (_.isUndefined(data[result[1]][result[2]])) {
									data[result[1]][result[2]] = [];
								}

								data[result[1]][result[2]].push(value);
							}
						} else {

							if (element.checked) {
								data[result[1]][result[2]] = value;
							}
						}

						break;

					case 'radio':

						if (element.checked === true) {
							data[result[1]][result[2]] = value;
						}

						break;

					case 'date':

						if ($(element).attr('data-output') == 'timestamp') {
							data[result[1]][result[2]] = Moment(value).unix();
						} else {
							data[result[1]][result[2]] = value;
						}

						break;

					case 'time':

						if ($(element).attr('data-output') == 'timestamp' && value != '') {

							// purely subjective date ;)
							var step1 = Moment('1987-12-25 ' + value).unix();
							var step2 = Moment('1987-12-25 00:00:01').unix();

							// get the number of seconds between to dates
							data[result[1]][result[2]] = step1 - step2;
						} else {
							data[result[1]][result[2]] = value;
						}

						break;

					default:

						//if value is an id, parse it in int
						if (result[2] === 'id') {
							data[result[1]][result[2]] = parseInt(value);
						} else {

							//if input had class 'has-error' value take data-oldvalue
							if ($(element).parent().parent().hasClass("has-error")) {

								if (!_.isUndefined($(element).data('oldvalue'))) {
									data[result[1]][result[2]] = $(element).data("oldvalue");
								} else {
									data[result[1]][result[2]] = value;
								}
							} else {

								if ($(element).hasClass('number-format')) {
									value = numberFormat.formatToFloat(value);
								}

								data[result[1]][result[2]] = value;
							}
						}

						break;
				};
			});

			//Disable inputs no updatable
			if (!_.isUndefined(this.modelToSave) && !_.isEmpty(this.modelToSave) && !this.modelToSave.isNew()) {
				_.each(inputDisabled, function (input) {
					$(input).prop('disabled', true);
				});
			}

			this.dataForm = data;
			this.trigger('dataSend', temp, goTo, $eventTarget);

			return false;
		},

		/**
   *
   * @returns {undefined}
   */
		formatNumber: function (e) {

			var input = $(e.currentTarget);
			var numberFormat = this.parentView.numberFormat ? this.parentView.numberFormat : App.numberFormat;
			var number = input.val();
			var min = input.attr('min');
			var max = input.attr('max');
			var precision = input.attr('precision') ? input.attr('precision') : numberFormat.precision;
			var precisioncustom = input.attr('precisioncustom') ? input.attr('precisioncustom') : numberFormat.precisioncustom;
			number = numberFormat.formatToFloat(number);

			if (min && number < min) {
				number = min;
			} else if (max && number > max) {
				number = max;
			}

			if (Number.isNaN(number)) {
				number = 0;
			}

			input.val(numberFormat.formatToDisplay(number, false, false, false, precision, true, precisioncustom));
		},

		/**
   *
   * @returns {undefined}
   */
		showValidationError: function (modelInError, attrInError, msgError, keepOldData) {

			_.isUndefined(keepOldData) ? false : keepOldData;

			if (_.isArray(attrInError)) {
				//if input name have more than one lvl

				var selector = '[name = "' + modelInError;
				var nameAttr = '';

				_.each(attrInError, function (key, index) {
					selector += '[' + key + ']';
					nameAttr = key; //take last key
				});

				selector += '"]';

				var msgError = msgError[nameAttr] ? msgError[nameAttr] : msgError;
			} else {
				var selector = '[name = "' + modelInError + '[' + attrInError + ']"]';
			}

			var $selector = $(selector);

			if (keepOldData && $selector.val() !== "") {
				$selector.data("oldvalue", $selector.val());
			}

			var $parentWrapper = $selector.parent();

			//remove old msg
			$parentWrapper.find('.validation-error').remove();

			var validationError = "<div class='validation-error'>* " + msgError + "</div>";

			if ($parentWrapper.hasClass('selectize-group')) {
				$parentWrapper.parent().prepend(validationError);
			} else {

				if ($parentWrapper.find('label').length) {
					$($parentWrapper.find('label')[0]).after(validationError);
				} else {
					$parentWrapper.prepend(validationError);
				}
			}

			//on décale l'icone à l'intérieur des inputs de la taille du message d'erreur
			if ($('i.fa', $parentWrapper).length) {

				$('i', $parentWrapper).removeAttr('style');

				var h = $('i', $parentWrapper).position().top + $('.validation-error', $parentWrapper).outerHeight(true);

				$('i', $parentWrapper).css('top', h);
			}

			if (!$($selector).is("textarea") && !$selector.attr('type') !== 'date') {
				$selector.val("");
			}

			$selector.parent().addClass("has-error");

			//@todo scroll to first input in error
			/*var $firstError 		= $($('.validation-error','.has-error')[0]);
   var offset 				= $firstError.offset().top;
   var currentScrollPos 	= this.$el.scrollTop();
            var offset_t 			= offset - currentScrollPos
            */

			this.$el.scrollTop(0);

			if (!$('#toast-error').length) {

				var options = {
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 3000,
					content: App.polyglot.t('someFormDatasAreInvalid')
				};

				new Notif(options);
			}
		},

		/**
   *
   * @param {type} errorTxt
   * @returns {undefined}
   */
		showOneError: function (errorTxt) {

			if (this.$el.find('#error-reporting')) {
				this.$el.find('#error-reporting').html(errorTxt);
			} else {
				this.$el.append('<div id="error-reporting">' + errorTxt + '</div>');
			}

			this.$el.find('#error-reporting').css('display', 'block');
		},

		/**
   *  @desc render an input validation error to his initial state
   */
		resetInput: function (event) {

			var $input = $(event.currentTarget);

			//for selectize
			if (!$input) {

				var $selectize = $input.closest(".form-group").find('.selectize-input input');

				$selectize.attr('placeholder', $input.data('placeholder'));
				$input.parent().removeClass("has-error");

				//for other input type
			} else {

				if ($input.data('placeholder')) {
					$input.prop('placeholder', $input.data('placeholder'));
				} else {
					$input.prop('placeholder', '');
				}

				if ($input.data('oldvalue')) {
					$input.val($input.data("oldvalue"));
				}

				$input.parent().removeClass("has-error");
			}
		},

		/**
   *
   * @param {type} msg
   * @param {type} link
   * @returns {undefined}
   */
		/**
   *
   * @param {type} msg
   * @param {type} link
   * @returns {undefined}
   */
		showConfirmation: function (msg, link) {

			var options = {
				id: 'toast-success',
				classes: 'col-xs-12',
				timeDuration: 4000,
				content: msg
			};

			if (!_.isUndefined(link)) {
				options['linkMessage'] = link.message;
				options['link'] = link.href;
			}

			new Notif(options);
		},

		/**
   *
   * @param {type} msg
   * @param {type} link
   * @returns {undefined}
   */
		showError: function (msg, link) {

			var options = {
				id: 'toast-error',
				classes: 'col-xs-12',
				timeDuration: 4000,
				content: msg
			};

			if (!_.isUndefined(link)) {
				options['linkMessage'] = link.message;
				options['link'] = link.href;
			}

			new Notif(options);
		},

		/**
   * @desc
   * @returns {undefined}
   */
		redirect: function (isNew, overviewUrl) {

			if (isNew) {
				this.router.navigate(overviewUrl, { trigger: true, replace: true });
			} else {

				var isListingRegexp = new RegExp(".*\/list.*");

				//if we come from listing navigate on overview, else just do window.back for return on overview update
				if (isListingRegexp.test(this.router.history[0])) {
					this.router.navigate(overviewUrl, { trigger: true, replace: true });
				} else {
					window.history.back();
				}
			}
		}

	});
});
