/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/prospects',['models/collections/thirds', 'models/singles/prospect'], function (Thirds, Prospect) {

	'use strict';

	return Thirds.extend({

		model: Prospect,
		storeName: 'third',
		name: 'prospects',
		url: '/rest/prospect/list',
		relationType: 'prospect'

	});
});
