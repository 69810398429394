/**
 *	@desc Model PayDate, extend Core Model 
 */
define('models/singles/paydate',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			value: '',
			endmonth: 'N'
		},

		storeName: 'paydate',
		name: 'paydate',
		urlRoot: '/rest/paydate'

	});
});
