/**
 *  @desc Warehouse Collection, extend core Collection
 */
define('models/collections/variationFields',['models/core/collection', 'models/singles/variationField', 'db/easy', 'tools/ajax'], function (Collection, variationfield, DB, Ajax) {

	'use strict';

	return Collection.extend({

		model: variationfield,
		storeName: 'variationfield',
		name: 'variationfield',
		url: '/rest/variationfield/list',
		fields: ['name']

	});
});
