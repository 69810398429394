tinymce.PluginManager.add("autoresize", function (a) {
  function b() {
    return a.plugins.fullscreen && a.plugins.fullscreen.isFullscreen();
  }function c(d) {
    var g,
        h,
        i,
        j,
        k,
        l,
        m,
        n,
        o,
        p,
        q,
        r,
        s = tinymce.DOM;if (h = a.getDoc()) {
      if (i = h.body, j = h.documentElement, k = e.autoresize_min_height, !i || d && "setcontent" === d.type && d.initial || b()) return void (i && j && (i.style.overflowY = "auto", j.style.overflowY = "auto"));m = a.dom.getStyle(i, "margin-top", !0), n = a.dom.getStyle(i, "margin-bottom", !0), o = a.dom.getStyle(i, "padding-top", !0), p = a.dom.getStyle(i, "padding-bottom", !0), q = a.dom.getStyle(i, "border-top-width", !0), r = a.dom.getStyle(i, "border-bottom-width", !0), l = i.offsetHeight + parseInt(m, 10) + parseInt(n, 10) + parseInt(o, 10) + parseInt(p, 10) + parseInt(q, 10) + parseInt(r, 10), (isNaN(l) || 0 >= l) && (l = tinymce.Env.ie ? i.scrollHeight : tinymce.Env.webkit && 0 === i.clientHeight ? 0 : i.offsetHeight), l > e.autoresize_min_height && (k = l), e.autoresize_max_height && l > e.autoresize_max_height ? (k = e.autoresize_max_height, i.style.overflowY = "auto", j.style.overflowY = "auto") : (i.style.overflowY = "hidden", j.style.overflowY = "hidden", i.scrollTop = 0), k !== f && (g = k - f, s.setStyle(a.iframeElement, "height", k + "px"), f = k, tinymce.isWebKit && 0 > g && c(d));
    }
  }function d(b, e, f) {
    tinymce.util.Delay.setEditorTimeout(a, function () {
      c({}), b-- ? d(b, e, f) : f && f();
    }, e);
  }var e = a.settings,
      f = 0;a.settings.inline || (e.autoresize_min_height = parseInt(a.getParam("autoresize_min_height", a.getElement().offsetHeight), 10), e.autoresize_max_height = parseInt(a.getParam("autoresize_max_height", 0), 10), a.on("init", function () {
    var b, c;b = a.getParam("autoresize_overflow_padding", 1), c = a.getParam("autoresize_bottom_margin", 50), b !== !1 && a.dom.setStyles(a.getBody(), { paddingLeft: b, paddingRight: b }), c !== !1 && a.dom.setStyles(a.getBody(), { paddingBottom: c });
  }), a.on("nodechange setcontent keyup FullscreenStateChanged", c), a.getParam("autoresize_on_init", !0) && a.on("init", function () {
    d(20, 100, function () {
      d(5, 1e3);
    });
  }), a.addCommand("mceAutoResize", c));
});
define("libs/plugins/autoresize/plugin.min", function(){});

