/**
 * @desc Contact Form, extend Page View
 * Child View : Core Form
 */
define('views/annotation/form',['backbone', 'underscore', 'views/core/page', 'views/core/toastNotification', 'models/collections/staffs', 'models/collections/annotations', 'models/singles/annotation', 'models/singles/event', 'views/core/listing', 'app', 'tinyMCE', 'tools/core/utils', 'selectize'], function (Backbone, _, Page, ToastNotification, staffs, Annotations, Annotation, Event, Listing, App, TinyMCE, Utils) {

	'use strict';

	return Page.extend({

		events: _.extend({
			'click .annotation-form button[type=submit]': 'record',
			'click .annotation-cancel': 'cancelResponse',
			'click .al-footer .btn-darkblue': 'addResponse',
			'click .al-footer .btn-modify': 'editAnnot'
		}, Page.prototype.events),

		title: App.polyglot.t('annotations'),
		form: {},
		staffCollection: {},
		popupAnnotation: {},
		event: {},
		toastMessages: {
			annotationAdded: App.polyglot.t('annotAdded'),
			annotationEdited: App.polyglot.t('annotModify'),
			annotationDeleted: App.polyglot.t('annotDeleted')
		},
		id: "annotation-page",
		hideSearchIcon: true,

		/**
   * 
   * @param {Router} router
   * @param {int} thirdId
   */
		initialize: function (router, params) {
			var _this2 = this;

			var _this = this;

			Page.prototype.initialize.apply(this, arguments);

			this.canEdit = true;
			this.canDelete = true;
			this.canAdd = true;
			this.modelToSave = new Annotation();
			this.relateds = this.modelToSave.checkRelated(params);
			this.annotations = new Annotations();
			this.collection = this.annotations;
			this.collection.XHRData = {
				search: _this.relateds
			};

			var initPromise = new Promise(function (resolve, reject) {

				//we need to fech google event for take his calendarid
				if (Utils.inArray(params.relatedtype, ['googleEvent', 'outlookEvent'])) {
					var event = new Event({ id: params.relatedid });
					event.fetchLocal().then(function () {
						_this2.collection.XHRData.search['calendarid'] = event.get('calendarId');
						_this2.modelToSave.set({ 'calendarid': event.get('calendarId') });
						resolve();
					});
				} else {
					resolve();
				}
			});

			initPromise.then(function () {

				_this2.staffCollection = new staffs();
				_this2.collections[_this2.staffCollection.name] = _this2.staffCollection;

				_this2.title += ' - ' + localStorage.getItem('linkedName');
				_this2.template = "templates/annotation/form.twig";
				_this2.router = router;
				_this2.subviews = [];

				_this2.listing = new Listing({ parentView: _this2 });
				_this2.subviews.push(_this2.listing);
				_this2.render();
			});
		},

		/**
   * @description : triggered when dom is ready and all models data are fetched
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			var _this = this;

			TinyMCE.remove();

			TinyMCE.init({
				selector: 'textarea[name="annotation[text]"]',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize",
				menubar: false,
				statusbar: false,
				setup: _.bind(function (ed) {
					//wait tinyMCe initialization before continuing

					ed.on('init', _.bind(function (args) {
						_this.TinyEditor = ed;
					}, this));
				}, this)

			});

			var onModify = function (model, currentTarget) {

				currentTarget.closest('.material-effect').addClass('disabledLongPress');

				// get annotation content div
				var noteDom = currentTarget.find('.al-content');
				// remplace br in the html content by line break
				var noteContent = currentTarget.find('.al-content').html();
				// empty the dom an create a textarea with the txt
				noteDom.html('');
				noteDom.append('<textarea id="annot-edit" class="al-annotation-edit" rows="3">' + noteContent + '</textarea>');

				TinyMCE.init({
					selector: '.al-annotation-edit',
					toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
					plugins: "autoresize",
					menubar: false,
					statusbar: false,
					setup: _.bind(function (ed) {
						//wait tinyMCe initialization before continuing

						ed.on('init', _.bind(function (args) {

							_this.modifyEditor = ed;
							TinyMCE.get('annot-edit').focus();

							var footer = $($(args.target)[0]['contentAreaContainer']).closest('.bubble').find('.al-footer');

							footer.find('.btn-darkblue').addClass('hidden');
							footer.find('.btn-modify').removeClass('hidden');
						}, this));
					}, this)
				});

				// hide form - compute its height and add ten px for security
				var formHeight = this.$el.find('.annotation-form').height() + 10;
				this.$el.find('.annotation-form').transition({ y: formHeight + 'px' });
			};

			var onRemove = function (model, currentTarget) {
				_this.render();
				_this.modelToSave.clear();
			};

			//init material effect on each row of listing
			this.listing.initEffect({

				popupid: 'annotation-option',
				title: App.polyglot.t('annotations'),
				onModify: onModify,
				onRemove: onRemove,
				removeMsg: _this.toastMessages.annotationDeleted,
				getModel: function (currentTarget) {

					// if it's a simple annot
					if (currentTarget.hasClass('al-annot')) {
						return _this.collection.get(currentTarget.data('id'));
					}
					// but if it's a sub annot, we got to its parent to find him
					else if (currentTarget.hasClass('al-subannot')) {

							var parent = _this.collection.get(currentTarget.data('parentid'));
							var childObj = _this.collection.getChildById(parent, currentTarget.data('id'));
							var annot = new Annotation(childObj);
							return annot;
						}
				}
			}, '', this);
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		editAnnot: function (e) {

			TinyMCE.triggerSave();

			var _this = this;
			var v_current = $(e.currentTarget);

			var commentContainer = v_current.closest('.al-annot, .al-subannot');
			var commentContent = TinyMCE.activeEditor.getContent();
			var commentid = commentContainer.data('id');

			commentContainer.removeClass("disabledLongPress");

			//delete tinyEditor and put content in dom
			commentContainer.find('.al-content').html(commentContent);
			// hide modify btn and show reply btn
			commentContainer.find('.al-footer .btn-replyAnnot').removeClass('hidden');
			commentContainer.find('.al-footer .btn-modify').addClass('hidden');

			var object = {
				'id': commentid,
				'parentid': commentContainer.data('parentid'),
				'relatedtype': _this.relateds.relatedtype,
				'relatedid': _this.relateds.relatedid,
				'text': commentContent,
				'canEdit': 'Y'
			};

			this.record('', object);
		},

		/**
   * 
   * @returns {undefined}
   */
		record: function (e, comment) {

			var _this = this;

			TinyMCE.triggerSave();

			$(e.currentTarget).attr('disabled', true);

			if (_.isUndefined(comment)) {
				var comment = {
					'parentid': this.$el.find("input[name='annotation[parentid]']").val(),
					'relatedtype': _this.relateds.relatedtype,
					'relatedid': _this.relateds.relatedid,
					'text': this.$el.find("textarea[name='annotation[text]']").val(),
					'canEdit': 'Y'
				};
			}

			this.modelToSave.set(comment);

			if (this.modelToSave.isValid()) {

				this.modelToSave.save().then(function () {

					var toastMsg = _this.toastMessages.annotationEdited;
					var callback = function () {
						_this.$el.find('.annotation-form').transition({ y: '0px' });
					};

					if (!comment.id) {

						toastMsg = _this.toastMessages.annotationAdded;

						_this.render().then(function () {
							_this.listing.$el.animate({ scrollTop: 0 }, 'slow');
							callback = function () {};
						});
					}

					new ToastNotification({
						id: 'toast-success',
						classes: 'col-xs-12 ',
						timeDuration: 4000,
						content: toastMsg,
						callback: callback
					});

					_this.modelToSave.clear();
				}, function () {
					console.log(arguments);
				});
			} else {
				console.log("error");
				$(e.currentTarget).attr('disabled', false);
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		addResponse: function (e) {

			var currentTarget = $(e.currentTarget);
			var annotElem = currentTarget.closest('.bubble');

			var parentid = annotElem.data('parentid');
			if (parentid == 0) {
				parentid = annotElem.data('id');
			}

			annotElem.attr("data-isresponse", "Y");

			var annotationForm = this.$el.find('.annotation-form');

			// set parentid
			annotationForm.find('#annotation-parentid').val(parentid);

			// set response to and cancel
			var sender = annotElem.find('.al-sender').html();
			var trad = annotationForm.find('#annotation-responseto-trad').html();
			var cancel = "<i class='fa fa-times-circle-o annotation-cancel pointer' style='margin-left:5px;'></i>";

			annotationForm.find('#annotation-responsenote').html(trad + ' ' + sender + ' ' + cancel);
			annotationForm.find('#annotation-responsenote').removeClass('hidden');
			annotationForm.find('.af-container').addClass('af-isresponse');
			annotationForm.find('.af-responseto').removeClass('hidden');
		},

		/**
   * 
   * @returns {undefined}
   */
		cancelResponse: function (e) {
			var v_form = $(e.currentTarget).closest('.annotation-form');
			v_form.find('input[name="annotation[parentid]"]').val('0');
			v_form.find('.af-responseto').addClass('hidden');
		}

	});
});
