/**
 * @desc Product Form, extend Page View
 * Child View : Core Form
 */
define('views/timetracking/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/timetracking', 'models/collections/thirds', 'models/collections/prospects', "models/collections/documents", "models/collections/services", 'models/collections/smarttags', 'tools/buildURL', 'tinyMCE', 'app', 'models/singles/ticket', 'models/singles/third', "models/collections/tickets"], function (Backbone, _, Page, Form, Timetracking, Thirds, Prospects, Documents, Services, Smarttags, URLBuilder, TinyMCE, App, Ticket, Third, Tickets) {

	'use strict';

	return Page.extend({

		events: _.extend(Page.prototype.events, {}),

		title: App.polyglot.t('timetracking'),
		form: {},
		template: "templates/timetracking/form.twig",
		toastMessages: {
			created: App.polyglot.t('entryCreated'),
			updated: App.polyglot.t('entryUpdated')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router, id, linkedtype, linkedid) {

			this.router = router;

			Page.prototype.initialize.apply(this, arguments);

			this.linkedPromise = Promise.resolve();
			this.modelToSave = new Timetracking({ id: id });
			this.models[this.modelToSave.name] = this.modelToSave;

			// go looking for smarttags only on editing mode
			if (!this.modelToSave.isNew()) {

				this.modelToSave.mustFetchLocal = true;
				// smartTags filter
				this.smartTagsFilter = {
					linkedtype: 'timetracking',
					linkedid: id,
					category: 'timetracking',
					categoryForIdb: 'Timetraking'
				};

				// SmartTags parts. Get smarttags of linked
				this.smartTags = new Smarttags();
				this.smartTags.XHRData = {
					search: this.smartTagsFilter
				};

				this.collections[this.smartTags.name] = this.smartTags;
			} else {

				if (App.permission.level('clientlevel')) {
					this.thirds = new Thirds();
				}

				if (App.permission.level('prospectlevel')) {
					this.prospects = new Prospects();
				}

				if (App.permission.level('invoicing_invoicelevel')) {
					this.invoices = new Documents();
					this.invoices.XHRData = {
						doctype: 'invoice'
					};
				}

				if (App.permission.level('invoicing_estimatelevel')) {
					this.estimates = new Documents();
					this.estimates.XHRData = {
						doctype: 'estimate'
					};
				}

				if (App.permission.can("support_access")) {
					this.tickets = new Tickets();
				}

				this.services = new Services();
				this.services.XHRData = {
					type: 'service'
				};

				if (linkedid && linkedtype) {

					this.hasLinked = true;
					this.linkedid = linkedid;

					switch (linkedtype) {

						case 'ticket':

							this.ticket = new Ticket({ id: linkedid });
							this.linkedPromise = this.ticket.fetchLocal();

							break;

					}
				}
			}

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);

			this.linkedPromise.then(_.bind(function () {

				if (this.hasLinked && this.ticket.get('thirdid')) {
					this.third = new Third({ id: this.ticket.get('thirdid') });
					this.models[this.third.name] = this.third;
				}

				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			this.thirds = this.thirds || this.prospects;

			if (!this.modelToSave.isNew()) {

				if (this.modelToSave.get('thirdid') != '0') {
					var defaultThird = {
						0: {
							'id': this.modelToSave.get('thirdid'),
							'name': this.modelToSave.get('third')
						}
					};
				}

				if (this.modelToSave.get('linkedid') != '0') {
					var defaultDoc = {
						0: {
							'id': this.modelToSave.get('linkedid'),
							'ident': this.modelToSave.get('docident')
						}
					};
				}

				if (this.modelToSave.get('serviceid') != '0') {
					var defaultService = {
						0: {
							'id': this.modelToSave.get('serviceid'),
							'name': this.modelToSave.get('service')
						}
					};
				}

				if (this.modelToSave.get('ttleLinkedId') && this.modelToSave.get('ttleLinkedType') === "ticket") {
					var defaultTicket = {
						0: {
							'id': this.modelToSave.get('ttleLinkedId'),
							'name': this.modelToSave.get('ticketSubject')
						}
					};
				}
			} else {

				if (this.hasLinked) {

					if (this.third) {
						this.thirds.add(this.third);
						var defaultThird = [this.third.id];
					}

					this.tickets.add(this.ticket);
					var defaultTicket = [this.ticket.id];
				}

				var ThirdOptions = this.thirds;
			}

			this.selectizeObjects = [{
				domElt: _this.$el.find('input[name="timetracking[third]"]'),
				create: false,
				defaultValues: defaultThird,
				options: ThirdOptions,
				valueField: 'id',
				labelField: 'name',
				searchField: ['name', 'forename'],
				maxItems: '1',
				maxOptions: 10,
				loadThrottle: 600,
				render: 'third',
				load: function (query, callback) {

					return new Promise(function (resolve, reject) {

						var promises = [];

						if (_this.thirds) {
							promises.push(_this.thirds.fetch({
								method: 'POST',
								data: { search: { contains: query } }
							}, true));
						}

						if (_this.prospects) {
							promises.push(_this.prospects.fetch({
								method: 'POST',
								data: { search: { contains: query } }
							}, true));
						}

						Promise.all(promises).then(function () {
							_this.thirds.add(_this.prospects.toJSON());
							resolve(_this.thirds);
						});
					});
				},

				//when we change of third, we clear tickets and documents collection for not have the tickets and docs of old third
				onChange: function (thirdid) {

					if (thirdid) {

						if (_this.invoices) {
							_this.invoices.reset();
						}

						if (_this.estimates) {
							_this.estimates.reset();
						}

						if (_this.tickets) {

							_this.tickets.reset();

							if ($('input[name="timetracking[ttleLinkedId]"]').length) {
								_this.$el.find('input[name="timetracking[ttleLinkedId]"]')[0].selectize.clearOptions();
							}
						}

						_this.currentThirdId = thirdid;

						if (_this.invoices || _this.estimates) {
							if ($('input[name="timetracking[doc]"]').length) {
								_this.$el.find('input[name="timetracking[doc]"]')[0].selectize.clearOptions();
							}
						}
					}
				},
				onItemRemove: function () {

					if (this.thirds || this.prospects) {
						_this.$el.find('input[name="timetracking[third]"]')[0].selectize.removeOption(_this.currentThirdId);
					}

					if (this.tickets) {
						_this.$el.find('input[name="timetracking[ttleLinkedId]"]')[0].selectize.clear();
					}

					if (this.documents) {
						_this.$el.find('input[name="timetracking[doc]"]')[0].selectize.clear();
					}

					_this.currentThirdId = undefined;
				}
			}, {
				domElt: _this.$el.find('input[name="timetracking[doc]"]'),
				create: false,
				defaultValues: defaultDoc,
				valueField: 'id',
				labelField: 'ident',
				searchField: ['ident', 'id'],
				maxItems: '1',
				maxOptions: 10,
				loadThrottle: 600,
				load: function (query, callback) {

					return new Promise(function (resolve, reject) {

						var promises = [];

						// go looking for invoices
						if (_this.invoices) {
							promises.push(_this.invoices.fetch({
								method: 'POST',
								data: {
									doctype: 'invoice',
									search: {
										ident: query,
										thirds: [_this.currentThirdId]
									}
								}
							}, true));
						}

						// go looking for estimates	
						if (_this.estimates) {
							promises.push(_this.estimates.fetch({
								method: 'POST',
								data: {
									doctype: 'estimate',
									search: {
										ident: query,
										thirds: [_this.currentThirdId]
									}
								}
							}, true));
						}

						Promise.all(promises).then(function (documents) {

							var docs = {};

							if (documents[0]) {
								_.extend(docs, documents[0].toJSON());
							}

							if (documents[1]) {
								_.extend(docs, documents[1].toJSON());
							}

							resolve(docs);
						}, function () {
							console.log(arguments);
							reject();
						});
					});
				},
				onItemAdd: function (value, item, opt) {

					var selectedOpt = opt.options[value];
					if (selectedOpt['doctype']) {
						$("input[name='timetracking[doctype]']", _this.$el).val(selectedOpt['doctype']);
					}

					if (selectedOpt['doctype'] === 'invoice') {
						var doc = _this.invoices.get(value);
					} else {
						var doc = _this.estimates.get(value);
					}

					var third = {
						id: doc.get('thirdid'),
						name: doc.get('thirdName')
					};

					//trick : si l'item third qui était sélectionné avait était créé depuis un doc on n'avait pas son avatar, on le supprime donc de le la liste pour ne pas avoir d'erreur JS 
					if (_this.currentThirdId && _this.currentThirdId !== third.id) {
						_this.$el.find('input[name="timetracking[third]"]')[0].selectize.removeOption(_this.currentThirdId);
					}

					_this.$el.find('input[name="timetracking[third]"]')[0].selectize.addOption(third);
					_this.$el.find('input[name="timetracking[third]"]')[0].selectize.setValue(third.id, { silent: true });

					_this.currentThirdId = third.id;
				},
				render: 'document'
			}, {
				domElt: _this.$el.find('input[name="timetracking[service]"]'),
				create: false,
				defaultValues: defaultService,
				valueField: 'id',
				labelField: 'name',
				searchField: ['name', 'id'],
				maxItems: '1',
				maxOptions: 10,
				loadThrottle: 600,
				load: function (query, callback) {

					return new Promise(function (resolve, reject) {

						resolve(_this.services.fetch({
							method: 'POST',
							data: {
								type: 'service',
								search: {
									name: query
								}
							}
						}, true));
					});
				},
				render: 'product'
			}, {
				domElt: _this.$el.find('input[name="timetracking[ttleLinkedId]"]'),
				create: false,
				defaultValues: defaultTicket,
				valueField: 'id',
				labelField: 'ident',
				searchField: ['ident', 'id', 'subject'],
				maxItems: '1',
				maxOptions: 10,
				loadThrottle: 600,
				render: 'ticket',
				options: this.tickets,
				load: function (query, callback) {

					return new Promise(function (resolve, reject) {

						resolve(_this.tickets.fetch({
							method: 'POST',
							data: {
								search: {
									contains: query,
									thirds: [_this.currentThirdId]
								}
							}
						}, true));
					});
				},
				onItemAdd: function (value, item, opt) {

					var ticket = _this.tickets.get(value);
					var third = {
						id: ticket.get('thirdid'),
						name: ticket.get('thirdName')
					};

					//trick : si l'item third qui était sélectionné avait était créé depuis un doc on n'avait pas son avatar, on le supprime donc de le la liste pour ne pas avoir d'erreur JS 
					if (_this.currentThirdId && _this.currentThirdId !== third.id) {
						_this.$el.find('input[name="timetracking[third]"]')[0].selectize.removeOption(_this.currentThirdId);
					}

					_this.$el.find('input[name="timetracking[third]"]')[0].selectize.addOption(third);
					_this.$el.find('input[name="timetracking[third]"]')[0].selectize.setValue(third.id, { silent: true });

					_this.currentThirdId = third.id;
				}
			}];

			return new Promise(function (resolve, reject) {

				if (!_this.modelToSave.isNew()) {
					_this.constructSmartTags().then(function () {
						Page.prototype.afterRender.apply(_this, arguments);
						resolve();
					});
				} else {
					Page.prototype.afterRender.apply(_this, arguments);
					resolve();
				}
			}).then(function () {

				TinyMCE.init({
					selector: 'textarea[name="timetracking[note]"]',
					toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
					plugins: "autoresize",
					menubar: false,
					statusbar: false,
					autoresize_max_height: '100px'
				});

				if (!_this.modelToSave.isNew()) {
					$('#isBillable').prop('checked', _this.modelToSave.get('isBillable') === "Y");
				}

				_this.initSelectize();
			});
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			if (!dataForm.timetracking.isBillable) {
				dataForm.timetracking.isBillable = "N";
			}

			this.modelToSave.set(dataForm.timetracking);

			if (this.modelToSave.isValid()) {

				var toastMsg = this.modelToSave.isNew() ? this.toastMessages.created : this.toastMessages.updated;

				this.modelToSave.save().then(function () {

					delete App.cacheControl[_this.router.history[0]];
					_this.form.trigger('successful', toastMsg);
					_this.goToPreviousPage();
				}, function () {
					// re activate submit button
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
