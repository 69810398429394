/**
 *  @desc Models Collection, extend core Collection
 */
define('models/collections/models',['models/collections/documents', 'models/singles/model'], function (Documents, Model) {

	'use strict';

	return Documents.extend({

		model: Model,
		storeName: 'document',
		url: '/rest/document/list',
		doctype: 'model'

	});
});
