/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/files',['models/core/collection', 'models/singles/file'], function (Collection, File) {

	'use strict';

	return Collection.extend({

		model: File,
		storeName: 'briefcase',
		name: 'files',
		url: '/rest/briefcase/list/files',
		fields: []

	});
});
