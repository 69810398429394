/**
 * @desc Prospection router, extend Core Router
 */
define('routers/purchase',['backbone', 'underscore', 'routers/core/router', 'app', 'tools/buildURL'], function (Backbone, _, CoreRouter, App, URLBuilder) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "purchase",
		plugin: "purchases",

		checkOnline: {
			fastForm: true
		},

		routes: {
			'purchase/home': 'purchaseHome',
			'purchase/list/:doctype': 'listing',
			'purchase/fastForm(/:id)': 'fastForm',
			'purchase/overview(/:doctype)(/:id)': 'overview'
		},

		/**
   * 
   */
		checkBefore: {
			fastForm: function () {
				return App.permission.can("purchases_fastpurchase") && App.permission.can("purchases_createpurinvoice");
			}
		},

		/**
   * 
   * @param {type} doctype
   * @returns {unresolved}
   */
		checkRight: function (doctype) {
			//purchases_purinvoicelevel
			return App.permission.level("purchases_" + doctype + "level");
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} doctype
   * @returns {undefined}
   */
		getClassName: function (doctype) {

			switch (doctype) {
				case 'invoice':
					return 'purInvoice';
					break;
				case 'creditNote':
					return 'purInvoice';
					break;
				case 'delivery':
					return 'purDelivery';
					break;
				case 'order':
					return 'purOrder';
					break;
				default:
					return false;
			}
		},

		/**
   * 
   * @param {type} comefrom
   * @returns {undefined}
   */
		listing: function (doctype) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			if (!this.checkRight(this.getClassName(doctype))) {
				return this.redirectError();
			}

			return new Promise(function (resolve, reject) {

				require(['require', 'views/purchase/list'], function (require, PurchaseList) {

					var links = _this.getAvailableActions();
					var view = new PurchaseList(_this, doctype, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} id
   * @returns {undefined}
   */
		fastForm: function (id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['views/purchase/fastForm'], function (FastForm) {

					var view = new FastForm(_this, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
  * 
  * @param {type} prospectId
  * @returns {undefined}
  */
		overview: function (doctype, id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			if (!this.checkRight(this.getClassName(doctype))) {
				return this.redirectError();
			}

			return new Promise(function (resolve, reject) {

				require(['require', 'views/purchase/overview'], function (require, Overview) {

					var view = new Overview(_this, doctype, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		purchaseHome: function () {
			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule'], function (require, HomeModule) {

					var links = _this.getAvailableActions();
					var view = new HomeModule(_this, links, 'purchases');
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		getAvailableActions: function () {

			var links = [{
				fa: 'fa-file-text-o',
				name: 'invoices',
				link: URLBuilder(['purchase', 'list', 'invoice']),
				canAccess: App.permission.level('purchases_purinvoicelevel')
			}, {
				fa: 'fa-file-text-o',
				name: 'orders',
				link: URLBuilder(['purchase', 'list', 'order']),
				canAccess: App.permission.level("purchases_purorderlevel")
			}, {
				fa: 'fa-file-text-o',
				name: 'deliveries',
				link: URLBuilder(['purchase', 'list', 'delivery']),
				canAccess: App.permission.level('purchases_purdeliverylevel')
			}, {
				fa: 'fa-file-text-o',
				name: 'creditnotes',
				link: URLBuilder(['purchase', 'list', 'creditNote']),
				canAccess: App.permission.level("purchases_purinvoicelevel")
			}, {
				fa: 'fa-pencil-square-o',
				name: 'quickSupplierInvoice',
				link: URLBuilder(['purchase', 'fastForm']),
				canAccess: App.permission.can("purchases_fastpurchase") && App.permission.can("purchases_createpurinvoice") && App.permission.level('supplierlevel')

			}];

			return links;
		}

	});
});
