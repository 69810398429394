/**
 *	@desc Model , extend Core Model 
 */
define('models/singles/filter',['models/core/model', 'app', 'db/easy'], function (Model, App, DB) {

	'use strict';

	return Model.extend({

		idAttribute: ['listing', 'name'],
		storeName: 'listingFilter',
		name: 'filter',

		updateLocal: function (dataToUpdate, createIfNotFound) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].update([_this.get('name'), _this.get('listing')], dataToUpdate).then(function (result) {

					if (!result) {

						if (createIfNotFound) {
							//if filter not exist, create it
							_this.saveLocal().then(function () {
								resolve();
							});
						} else {
							reject();
						}
					} else {
						resolve();
					}
				});
			});
		}

	});
});
