/* global Backbone */

/**
*	@description Public Page view, parent view where content are insered 
*/
define('views/core/publicPage',['app', 'jquery', 'views/core/view', 'libs/itemslide'], function (App, $, View) {

	return View.extend({

		tagName: 'div',
		className: 'page',
		title: '',
		slideDirection: 'left',
		renderPromises: [],

		constructor: function () {

			this.models = {};
			this.collections = {};
			this.toggleableViews = [], this.subviews = [];

			View.apply(this, arguments);
		},

		/**
   * 
   * @param {type} router
   * @param {type} id
   * @returns {undefined}
   */
		initialize: function (router, id) {

			$('.navbar-fixed-top').hide();
			$('#side-bar').hide();

			if (App.hasNetworkInterval) {
				//don't check network on public page
				clearInterval(App.hasNetworkInterval);
				App.hasNetworkInterval = false;
			}

			this.router = router;
			var _this = this;

			//DOM elements declaration
			this.$.contentWrapper = $('.content-wrapper');
		},

		/**
   * @description , render every subviews of page and call after render when its finished
   * @returns {undefined}
   */
		render: function () {

			var _this = this;

			_.each(this.subviews, function (subview) {

				if (subview.doRender !== false) {
					_this.renderPromises.push(subview.render());
				}
			});

			Promise.all(this.renderPromises).then(function () {

				//when main view and his subviews are fetched, we render now toggleableViews
				_.each(_this.toggleableViews, function (toggleableView) {
					_this.renderPromises.push(toggleableView.render());
				});

				Promise.all(_this.renderPromises).then(function () {

					_this.afterRender();
				}, function (e) {
					console.log(e);
				});
			}, function (e) {
				console.log(e);
			}).catch(function () {
				console.log(arguments);
			});
		},

		/**
   * 
   * @description insert the Page in the DOM with slide Transitition 
   */
		afterRender: function () {

			return new Promise(_.bind(function (resolve, reject) {

				//remove preloader (loader on refresh)
				$(".preloader").remove();

				this.$.contentWrapper.css('padding-top', '0');

				var oldPage = App.view;
				var windowWidth = $(window).width();
				var _this = this;

				var slideMouvement = App.slideDirection === 'left' ? -windowWidth : windowWidth;

				$(".navbar-header h3").text(_this.title);

				if (!_.isEmpty(oldPage)) {

					oldPage.$el.css('position', 'absolute');

					if (this.$el.prop('tagName') === "IFRAME") {

						_this.$.contentWrapper.html(_this.$el);

						this.$el.load(function () {

							_this.$el.css({
								position: 'absolute',
								left: windowWidth
							});

							oldPage.$el.transition({ x: slideMouvement + 'px', duration: 550 }, function () {
								_this.router.setView(_this);
							});

							_this.$el.removeClass('hidden');

							_this.$el.transition({ x: slideMouvement + 'px', duration: 550 }, function () {
								_this.$el.removeAttr('style');
								App.slideDirection = 'left';
								resolve();
							});
						});
					} else {

						_this.$el.css({
							position: 'absolute',
							left: windowWidth
						});

						oldPage.$el.transition({ x: slideMouvement + 'px', duration: 550 }, function () {
							_this.router.setView(_this);
						});

						_this.$.contentWrapper.append(_this.$el);
						_this.$el.transition({ x: slideMouvement + 'px', duration: 550 }, function () {
							_this.$el.removeAttr('style');
							App.slideDirection = 'left';
							resolve();
						});
					}
				} else {
					_this.router.setView(_this);
					_this.$.contentWrapper.append(_this.$el);
				}

				$('body').removeAttr('style');

				this.$.contentWrapper.css({
					width: 100 + '%',
					'margin-left': 0
				});

				if (navigator.isNative && navigator.splashscreen) {
					navigator.splashscreen.hide();
				}
			}, this));
		},

		/**
   * @returns {undefined}
   */
		destroy: function () {

			var _this = this;

			_.each(App.view.subviews, function (view, index, list) {
				view.remove();
			});

			//remove Parent view
			App.view.remove();
		},

		destroyObjects: function () {

			//remove childs views and events
			_.each(App.view.subviews, function (view, index, list) {

				var myview = App.view.subviews.shift();

				if (!_.isUndefined(myview.collections)) {

					_.each(myview.collections, function (collection, index) {
						delete myview.collections[index];
					});
				}

				if (!_.isUndefined(myview.models)) {

					_.each(myview.models, function (model, index) {
						delete myview.models[index];
					});
				}

				if (!_.isUndefined(myview.options.extraData)) {

					_.each(myview.options.extraData, function (extraData, index) {
						delete myview.options.extraData[index];
					});
				}
			});

			//remove childs views and events
			_.each(App.view.subviews, function (view, index, list) {

				var myview = App.view.subviews.shift();

				if (!_.isUndefined(myview.collections)) {

					_.each(myview.collections, function (collection, index) {
						delete myview.collections[index];
					});
				}

				if (!_.isUndefined(myview.models)) {

					_.each(myview.models, function (model, index) {
						delete myview.models[index];
					});
				}

				if (!_.isUndefined(myview.options.extraData)) {

					_.each(myview.options.extraData, function (extraData, index) {
						delete myview.options.extraData[index];
					});
				}

				//					myview.remove();
			});
		},

		/**
   * 
   * @desc change the menu burger logo by an arrow logo
   * @param {type} route
   */
		checkArrowReturn: function (route) {

			var $arrow = $(".navbar-brand.return .fa-arrow-right");
			var $navbarbrandMenu = $('.navbar-brand.menu');
			var $navbarbrandReturn = $('.navbar-brand.return');

			//remove arrow if we are not on overview/form/accountPref
			if ($arrow.length !== 0 && !/overview|form|(.+Pref).*/.test(route)) {

				$arrow.removeAttr("style");
				$arrow.transition({ rotate: "180deg" }, 200, "in", function () {
					$navbarbrandReturn.hide();
					$navbarbrandMenu.show();
				});
			}

			//add arrow if we are on overview/form/accountPref
			if (/overview|form|(.+Pref).*/.test(route)) {

				$navbarbrandReturn.show();
				$navbarbrandMenu.hide();

				$arrow.removeAttr("style");
				$arrow.transition({ rotate: "180deg" }, 200, "in");
			}
		}

	});
});
