define('libs/selectize-select-optgroup',['selectize'], function (Selectize) {

	Selectize.define('select-optgroup', function (options) {

		var self = this;

		this.onBlur = function (e, dest) {

			var self = this;

			if (!self.isFocused) return;
			self.isFocused = false;

			if ($(dest).hasClass('optgroup-header')) {
				self.isFocused = true;
				return;
			}

			if (self.ignoreFocus) {
				return;
			} else if (!self.ignoreBlur && document.activeElement === self.$dropdown_content[0]) {
				// necessary to prevent IE closing the dropdown when the scrollbar is clicked
				self.ignoreBlur = true;
				self.onFocus(e);
				return;
			}

			var deactivate = function () {
				self.close();
				self.setTextboxValue('');
				self.setActiveItem(null);
				self.setActiveOption(null);
				self.setCaret(self.items.length);
				self.refreshState();

				// IE11 bug: element still marked as active
				dest && dest.focus && dest.focus();

				self.ignoreFocus = false;
				self.trigger('blur');
			};

			self.ignoreFocus = true;
			if (self.settings.create && self.settings.createOnBlur) {
				self.createItem(null, false, deactivate);
			} else {
				deactivate();
			}
		};

		// override the setup method to add an extra `click`  handler
		this.setup = function () {

			var original = self.setup;

			return function () {

				original.apply(this, arguments);

				this.$control_input.off('blur');

				this.$dropdown_content.on('click', '.optgroup-header', function (e) {

					e.preventDefault();

					//take every items of opt-group
					var $optGroupWrapper = $(e.currentTarget).parent();

					$("div[data-value]", $optGroupWrapper).each(function () {
						self.addItem($(this).data('value'));
					});
				});

				this.$control_input.on('blur', self.onBlur);
			};
		}();
	});
});
