/**
 *	@desc Model Unity, extend Core Model 
 */
define('models/singles/unity',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'unity',
		name: 'unity',
		urlRoot: '/rest/unity'

	});
});
