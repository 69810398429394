/**
 * @desc represent the rightSideBar
 */
define('views/sidebar/panelRight',['jquery', 'backbone', 'tools/ajax', 'views/core/view', 'app', 'starter'], function ($, Backbone, Ajax, View, App) {

	return View.extend({

		events: {
			'click a': "closeSideBar",
			'remove': "onViewDestroy",
			'click .button-with-network': "checkButton"
		},

		template: '/sidebarRight',
		id: 'side-bar-right',
		tagName: 'div',
		className: 'sidebar box-shadow-hight',
		parentView: {},
		buttons: {},
		collections: {},

		/**
   * 
   */
		initialize: function (parentView, buttonsList, events) {

			var _this = this;
			this.events = _.extend(this.events, events);

			if (parentView !== undefined) {

				if ($("#side-bar-right").length) {
					$("#side-bar-right").off();
					$("#side-bar-right").remove();
				}

				this.parentView = parentView.parentView;
				this.buttonsList = buttonsList;

				// loop on each btn categ (task, event, ect)
				$.each(this.buttonsList, function () {

					// loop on each buttons
					if (this.callbacks) {
						$.each(this.callbacks, function (key, value) {
							_this.events[key] = value;
						});
					}
				});

				this.render(buttonsList);
			}
		},

		checkButton: function (e) {
			this.parentView.checkButton.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		renderCallback: function () {
			$('body').append(this.$el);
		},

		/**
   * 
   * @returns {panelRight_L14.panelRightAnonym$1|Promise}
   */
		render: function (buttonsList) {

			var _this = this;

			return new Promise(function (renderSuccess, renderFailed) {

				if (App.isLogged) {

					var templateRequest = new Promise(function (resolve, reject) {

						//get template
						_this.requesters.template = Ajax({
							url: _this.template,
							dataType: "html",
							success: function (template) {
								resolve(template);
							},
							error: function (error) {
								console.log(error);
								reject();
							}
						});
					});

					templateRequest.then(function (html) {

						var template = _.template(html); //compile template with underscore

						_this.$el.html(template({ content: buttonsList, App: App })); //Insert template in DOM with data

						_this.renderCallback();

						renderSuccess();
					}, function (error) {
						renderFailed(error);
					});
				}
			});

			return this;
		},

		/**
   * 
   * @returns {undefined}
   */
		onViewDestroy: function () {
			App.header.$.rightSidebarTrigger.addClass('hidden');
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		closeSideBar: function (e) {
			this.$el.toggleClass("side-bar-up");
			$('.mask').toggleClass("mask-up");
		}

	});
});
