/**
 *	@desc Model Unity, extend Core Model 
 */
define('models/singles/variationField',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'variationfield',
		name: 'variationfield',
		urlRoot: '/rest/variationfield'

	});
});
