/**
 *  @desc Address Collection, extend core Collection
 */
define('models/collections/agendaLabels',['models/core/collection', 'models/singles/agendaLabel', 'db/easy'], function (Collection, agendaLabel, DB) {

	'use strict';

	return Collection.extend({

		model: agendaLabel,
		storeName: 'agendaLabel',
		name: 'agendaLabel',
		url: '/rest/agenda/labelList',

		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.add(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		}

	});
});
