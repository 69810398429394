/**
 * Created by vnavarrete on 15/06/2017.
 */

/**
 *	@desc email message Model , extend Core Model
 */
define('models/singles/message',['models/core/model', 'app', 'tools/buildURL', 'tools/ajax'], function (Model, App, URLBuilder, Ajax) {

    'use strict';

    return Model.extend({

        defaults: {},

        name: 'message',
        storeName: 'message',
        urlRoot: '/rest/emailBox/message',

        /**
         *
         * @returns {undefined}
         */
        validate: function (attributes) {

            var _this = this;

            var validatorRegEx = /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i;
            var validationErrors = { mail: {} };

            if (!attributes.emails) {
                validationErrors['mail']['emails'] = this.computeError({ name: 'emails', type: 'required' });
            } else {

                _.each(attributes.recipients, function (recipient) {

                    if (!validatorRegEx.test(recipient.email)) {
                        validationErrors['mail']['emails'] = _this.computeError({ name: 'emails', type: 'invalid' });
                    }
                });
            }

            if (!_.isEmpty(attributes.cc)) {

                _.each(attributes.cc, function (cc) {

                    if (!validatorRegEx.test(cc.email)) {
                        validationErrors['mail']['emailsCC'] = _this.computeError({ name: 'emailsCC', type: 'invalid' });
                    }
                });
            }

            if (!_.isEmpty(attributes.bcc)) {

                _.each(attributes.bcc, function (bcc) {

                    if (!validatorRegEx.test(bcc.email)) {
                        validationErrors['mail']['emailsBCC'] = _this.computeError({ name: 'emailsBCC', type: 'invalid' });
                    }
                });
            }

            if (_.toArray(validationErrors.mail).length > 0) {
                return validationErrors;
            }
        }

    });
});
