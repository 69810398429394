/**
 *  @desc Estimates Collection, extend core Collection
 */
define('models/collections/eventLogs',['models/core/collection', 'models/singles/eventLog', 'app'], function (Collection, EventLog, App) {

	'use strict';

	return Collection.extend({
		model: EventLog,
		storeName: 'eventlog',
		name: 'eventlog',
		url: '/rest/eventlog/list',
		availablesRelateds: ['client', 'people', 'item', 'service', 'invoice', 'estimate', 'delivery', 'order', 'creditnote', 'proforma', 'supplier', 'prospect'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {
			Collection.apply(this, arguments);
			this.offlineQueryArgs = ['relatedtype'];
		},

		/**
   * 
   * @returns {undefined}
   */
		getAvailableRelateds: function () {

			if (App.pluginIsLoaded('prospection')) {
				this.availablesRelateds.push('opportunity');
			}

			if (App.permission.can('purchases_access')) {
				this.availablesRelateds = _.union(this.availablesRelateds, App.availablePurDoctypes);
			}

			if (App.permission.can('expenses_access')) {
				this.availablesRelateds.push('expense');
			}

			if (App.permission.can('support_access')) {
				this.availablesRelateds.push('ticket');
			}

			if (App.permission.can('timetracking_access')) {
				this.availablesRelateds.push('timetracking');
			}

			return this.availablesRelateds;
		}

	});
});
