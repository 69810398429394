/**
 * @desc Email router, extend Core Router
 */
define('routers/email',['backbone', 'underscore', 'routers/core/router', 'tools/buildURL'], function (Backbone, _, CoreRouter, URLBuilder) {

	'use strict';

	return CoreRouter.extend({

		checkOnline: {
			form: true
		},

		routes: {
			'email/home(/:linkedtype)(/:linkedid)': 'emailHome',
			'email/list/:box(/)(:linkedtype)(/:linkedid)': 'listing',
			'email/overview/:id(/:linkedtype)(/:linkedid)': 'overview',
			'email/form(/:linkedtype)(/:linkedid)(/:comefrom)': 'form'
		},

		sidelink: 'mailbox',

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} comefrom
   * @returns {undefined}
   */
		listing: function (box, linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/email/list'], function (require, ListingMail) {

					var links = _this.getAvailableActions(linkedtype, linkedid, 'listing');
					var view = new ListingMail(_this, box, linkedtype, linkedid, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} peopleId
   * @returns {undefined}
   */
		overview: function (id, linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/email/overview'], function (require, OverviewMail) {

					var view = new OverviewMail(_this, id, linkedtype, linkedid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		form: function (linkedtype, linkedid, comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/email/form'], function (require, FormMail) {

					var view = new FormMail(_this, linkedtype, linkedid, comefrom);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} linkedtype
   * @param {type} linkedid
   * @param {type} linkedname
   * @returns {undefined}
   */
		emailHome: function (linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule'], function (require, HomeModule) {

					var links = _this.getAvailableActions(linkedtype, linkedid);

					var view = new HomeModule(_this, links, 'emails');
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} linkedtype
   * @param {type} linkedid
   * @param {type} linkedname
   * @returns {undefined}
   */
		getAvailableActions: function (linkedtype, linkedid, context) {

			var extraParams = '';

			if (linkedid && linkedtype) {
				extraParams = URLBuilder([linkedtype, linkedid], false);
			}

			var links = [{
				fa: 'fa-inbox',
				name: 'inbox',
				link: URLBuilder(['email', 'list', 'inbox', extraParams])
			}, {
				fa: 'fa-paper-plane',
				name: 'emailsended',
				link: URLBuilder(['email', 'list', 'outbox', extraParams])
			}, {
				fa: 'fa-trash',
				name: 'emaildeleted',
				link: URLBuilder(['email', 'list', 'trashbox', extraParams])
			}, {
				fa: 'fa-archive',
				name: 'emailprogrammed',
				link: URLBuilder(['email', 'list', 'scheduledbox', extraParams])
			}];

			if (context !== 'listing') {
				links.push({
					name: 'newMessage',
					link: extraParams ? URLBuilder(['email', 'form', extraParams]) : URLBuilder(['email', 'form']),
					class: "new-mail-from-home btn btn-primary color-white horizontal-center",
					liClass: 'border-none'
				});
			}

			return links;
		}

	});
});
