/**
 *	@desc support Model , extend Core Model 
 */
define('models/singles/ticket',['models/core/model', 'app', 'tools/ajax'], function (Model, App, Ajax) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'support',
		name: 'ticket',
		urlRoot: '/rest/support/',

		/**
   * 
   * @param {type} attributes
   * @returns {ticket_L9.ticketAnonym$1.validate.validationErrors}
   */
		validate: function (attributes) {

			var required = {
				"ticket": {
					'thirdid': false,
					'thirdcontactid': false,
					'requesterEmail': false,
					'step': true,
					'source': true,
					'staffid': true,
					'subject': true,
					'message': true
				}
			};

			var validationErrors = { ticket: {} };

			if (required.ticket.step && !attributes.step) {
				validationErrors['ticket']['step'] = this.computeError({ name: 'step', type: 'required' });
			}

			if (required.ticket.source && !attributes.source) {
				validationErrors['ticket']['source'] = this.computeError({ name: 'source', type: 'required' });
			} else {
				if (attributes.source == 'email') {
					required.ticket.requesterEmail = true;
				}
			}

			if (required.ticket.staffid && attributes.staffid === '') {
				validationErrors['ticket']['staffid'] = this.computeError({ name: 'staffid', type: 'required' });
			}

			if (required.ticket.subject && !attributes.subject) {
				validationErrors['ticket']['subject'] = this.computeError({ name: 'subject', type: 'required' });
			}

			if (required.ticket.message && !attributes.message) {
				validationErrors['ticket']['message'] = this.computeError({ name: 'message', type: 'required' });
			}

			if (required.ticket.requesterEmail && !attributes.requesterEmail) {
				validationErrors['ticket']['requesterEmail'] = this.computeError({ name: 'requesterEmail', type: 'required' });
			}

			if (_.toArray(validationErrors.ticket).length > 0) {
				return validationErrors;
			}
		},

		isValidRequest: function (attributes) {

			var required = {

				'ticket': {
					'requesterEmail': true,
					'subject': true,
					'message': true
				}

			};

			var validationErrors = {};
			var _this = this;
			validationErrors[this.name] = {};

			//check required
			_.each(required.ticket, function (attr, nameAttr) {

				if (!attributes[nameAttr] && required['ticket'][nameAttr]) {
					validationErrors[_this.name][nameAttr] = _this.computeError({ name: nameAttr, type: 'required' });
				}
			});

			if (_.toArray(validationErrors[this.name]).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @param {type} newStep
   * @returns {Promise}
   */
		updateStep: function (newStep) {

			var _this = this;

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: _this.urlRoot + 'updatestep/' + _this.id,
					data: {
						step: newStep
					},
					dataType: "json",
					success: function (data) {
						_this.step = data;
						resolve(data);
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @param {type} staffid
   * @returns {Promise}
   */
		updateAssignation: function (staffid) {

			var _this = this;

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: _this.urlRoot + 'updateassignation/' + _this.id,
					data: {
						assignedTo: staffid
					},
					dataType: "json",
					success: function () {
						resolve();
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @param {type} thirdid
   * @param {type} thirdcontactid
   * @returns {undefined}
   */
		updateThird: function (thirdid, thirdcontactid) {

			var _this = this;

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: _this.urlRoot + 'updateThird/' + _this.id,
					data: {
						thirdid: thirdid,
						thirdcontactid: thirdcontactid
					},
					dataType: "json",
					success: function () {
						resolve();
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		sendSupportRequest: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({

					method: 'POST',
					url: _this.urlRoot + 'supportRequest',
					data: JSON.stringify({
						request: _this.toJSON()
					}),
					dataType: "json",
					success: function () {
						resolve();
					},
					error: function () {
						reject();
					}

				});
			});
		}

	});
});
