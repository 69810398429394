/**
 * Child View : Core Listing
 */
define('views/timetracking/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/timetrackings', 'app'], function (_, PageList, Listing, URLBuilder, TimeTrackings, App) {

	'use strict';

	return PageList.extend({

		name: 'timetracking',
		events: _.extend({}, PageList.prototype.events),

		hideFilterPannel: true,
		title: "Timetracking",
		listing: {},
		placeholderSearchBar: App.polyglot.t('customername'),
		toastMessages: {
			ttRemoved: App.polyglot.t('entrydeleted')
		},
		template: "templates/timetracking/list.twig",
		addAction: function (e) {

			if (this.linkedid) {
				this.listing.goToForm(URLBuilder(['timetracking', 'form', 0, this.linkedtype, this.linkedid]));
			} else {
				this.listing.goToForm(URLBuilder(['timetracking', 'form']));
			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router, owner, linkedtype, linkedid, availableActions) {

			this.canAdd = true;

			if (App.currentStaff.get('permission').level === "admin" || App.currentStaff.get('permission').level === "owner" || App.currentStaff.get('permission').permissions['timetracking_entrylevel']['valuecheck'] == 'all') {
				this.availableActions = availableActions;
			};

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.canDelete = App.permission.can("timetracking_deleteentry");
				this.collection = new TimeTrackings();

				this.collection.XHRData.search = {
					'staffOnly': owner === 'all' ? 'N' : "Y"
				};

				if (linkedtype && linkedid) {

					this.hideSearchIcon = true;

					this.linkedtype = linkedtype;
					this.linkedid = linkedid;

					this.collection.XHRData.search["ttleLinkedType"] = linkedtype;
					this.collection.XHRData.search["ttleLinkedId"] = linkedid;
				}

				this.listing = new Listing({ parentView: this });
				this.subviews.push(this.listing);

				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;
			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canDelete) {

				//init material effect on each row of listing
				this.listing.initSwipeEffect({
					removeMsg: _this.toastMessages.ttRemoved,
					onModify: function (model, $row) {

						if ($row.data('cancrud') == 'Y') {
							_this.router.navigate(URLBuilder(["timetracking", "form", model.get('id')]), { trigger: true });
						}
					},
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},

					canDelete: function (model) {
						return model.get('canDelete') === 'Y';
					},
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					},
					popupid: 'tt-pp',
					title: App.polyglot.t('timetracking')
				}, '', this);
			}
		}

	});
});
