define('backboneCompositeKey',['backbone', 'underscore'], function (backbone, underscore) {

	'use strict';

	// Generated by CoffeeScript 1.3.3

	return function () {
		var Backbone,
		    generateId,
		    idChangeEvents,
		    set,
		    _,
		    _onModelEvent,
		    __indexOf = [].indexOf || function (item) {
			for (var i = 0, l = this.length; i < l; i++) {
				if (i in this && this[i] === item) return i;
			}return -1;
		};

		_ = (typeof window !== "undefined" && window !== null ? window._ : void 0) || require('underscore');

		Backbone = (typeof window !== "undefined" && window !== null ? window.Backbone : void 0) || require('backbone');

		set = Backbone.Model.prototype.set;

		generateId = function (idAttr, attrs) {
			var index, indexes, val, _i, _len;
			if (typeof idAttr === 'string') {
				return attrs[idAttr];
			}
			indexes = [];
			for (_i = 0, _len = idAttr.length; _i < _len; _i++) {
				index = idAttr[_i];
				if ((val = attrs[index]) == null) {
					return void 0;
				}
				indexes.push(val);
			}
			return indexes.join('_');
		};

		idChangeEvents = function (idAttr) {
			if (typeof idAttr === 'string') {
				return ["change:" + idAttr];
			}
			return _.map(idAttr, function (index) {
				return "change:" + index;
			});
		};

		_.extend(Backbone.Model.prototype, {
			_generateId: function (attrs) {
				if (attrs == null) {
					attrs = this.attributes;
				}
				return generateId(this.idAttribute, attrs);
			},
			set: function (key, val, options) {
				var attrs;
				if (key == null) {
					return this;
				}
				if (_.isObject(key)) {
					attrs = key;
					options = val;
				} else {
					(attrs = {})[key] = val;
				}
				if (!this._validate(attrs, options || {})) {
					return false;
				}
				this.id = this._generateId(_.extend({}, this.attributes, attrs));
				return set.apply(this, arguments);
			}
		});

		_onModelEvent = Backbone.Collection.prototype._onModelEvent;

		_.extend(Backbone.Collection.prototype, {
			get: function (id) {
				if (id == null) {
					return void 0;
				}
				this._idAttr || (this._idAttr = this.model.prototype.idAttribute);
				if (_.isObject(id)) {
					id = id.id || id.cid || generateId(this._idAttr, id);
				}
				return this._byId[id];
			},
			_onModelEvent: function (event, model, collection, options) {
				var prev;
				if (__indexOf.call(idChangeEvents(this._idAttr || (this._idAttr = this.model.prototype.idAttribute)), event) >= 0) {
					prev = generateId(this._idAttr, model.previousAttributes());
					if (prev !== model.id) {
						delete this._byId[prev];
						if (model.id != null) {
							this._byId[model.id] = model;
						}
					}
				}
				return _onModelEvent.apply(this, arguments);
			}
		});
	}.call(this);
});
