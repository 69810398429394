/**
 * @desc Doc Form, extend Page View
 * Child View : Core Form
 */
define('views/document/form',['underscore', 'jquery', 'views/core/page', 'views/core/form', 'tools/buildURL', "models/singles/document", "models/singles/third", "models/singles/rateCategory", 'models/collections/paydates', 'models/singles/currency', 'app', 'moment', 'views/document/docFormHeader', 'big', 'tools/math', 'tools/numberFormat', "tinyMCE", 'tools/core/utils', 'models/collections/warehouses', 'models/collections/taxes', "models/singles/prospect", "selectize", 'libs/plugins/autoresize/plugin.min', 'libs/plugins/placeholder/plugin'], function (_, $, Page, Form, URLBuilder, Document, Third, RateCategory, Paydates, Currency, App, Moment, DocFormHeader, Big, Math, NumberFormat, TinyMCE, Utils, Warehouses, Taxes, Prospect) {

	'use strict';

	var DocForm = Page.extend(

	/**
  * @namespace DocForm
  * @property {Document} modelToSave  - The document model to save
  */
	{

		events: _.extend({
			'mouseup': 'closeMoreMenu',
			'click .remove-line': 'deleteRow',
			'click .goto': 'docInTemp',
			'click .formatting-doc': 'formattingAction',
			'click .change-rank .fa': 'changeRank'
		}, Page.prototype.events),

		id: 'doc-form',
		form: {},

		/**
   *
   */
		modelToSave: {},

		toastMessages: {
			docSaved: App.polyglot.t('docSaved'),
			docUpdated: App.polyglot.t('docUpdated')
		},
		actionList: {
			record: {
				fa: false,
				aClass: "recordDoc",
				label: App.polyglot.t("record"),
				link: function (e) {

					$(e.currentTarget).hide();
					$(e.currentTarget).parent().append('<i class="fa fa-circle-o-notch fa-spin"></i>');

					this.form.$el.trigger('submit');
				}
			},
			prefs: {
				fa: false,
				label: App.polyglot.t("preferences"),
				link: function (e) {
					this.docInTemp(e);
				},
				datas: {
					'goto': 'docPrefGlobal'
				}
			}
		},

		/**
   * 
   * @param {type} router
   * @param {type} doctype
   * @param {type} thirdid
   * @param {type} docId
   * @param {type} comefrom
   * @param {type} transformTo
   * @returns {undefined}
   */
		initialize: function (router, doctype, thirdid, docId, comefrom, transformTo) {

			Page.prototype.initialize.apply(this, arguments);

			var _this = this;

			this.paydates = new Paydates();
			this.collections[this.paydates.name] = this.paydates;

			if (App.pluginIsLoaded('stock')) {

				this.warehouses = new Warehouses();
				this.warehouses.mustFetchLocal = docId == 0 ? true : false;
				this.collections[this.warehouses.name] = this.warehouses;
				this.isStockLoaded = true;
			}

			/**
    * case of transformTo is provided
    */
			if (transformTo) {
				//in case of conversion/model :doctype is parent doctype and :tranformTo is doctype for new doc

				var parentDoctype = doctype;
				doctype = transformTo;

				if (parentDoctype === 'model') {
					this.fromModel = true;
				}

				if (parentDoctype === transformTo) {
					this.fromCopy = true;
				} else {
					this.isTransformation = true;
				}

				this.hasDocSource = true; //if doc is create from another doc (conversion/model/copy)
			}

			/**
    * choose the right doc depending of doctype and context
    */
			Document.classNameByDoctype(doctype).then(_.bind(function (ClassName) {

				//in case of update : :docID is document id , conversion : :docId is parentId , model : :docId is modelId
				this.modelToSave = new ClassName({ id: docId });

				if (!this.modelToSave.isNew()) {
					//:isNew == false in case of update/conversion/model 

					this.isNew = false;

					if (comefrom !== 'list') {
						this.modelToSave.mustFetchLocal = true;
					}

					if (this.isTransformation) {
						//take parentid for conversion
						this.modelToSave.set({ parentid: docId });
					}
				} else {
					this.isNew = true;
				}

				this.comefrom = comefrom;
				this.router = router;

				if (App.hasVat) {
					this.taxes = new Taxes();
					this.collections[this.taxes.name] = this.taxes;
				}

				this.options.extraData.countriesList = function () {

					return new Promise(function (resolve, reject) {

						Utils.getCountriesList().then(function (countriesList) {
							_this.countriesList = countriesList;
							resolve();
						});
					});
				};

				this.options.extraData.document = function () {

					return new Promise(function (resolve, reject) {

						//Si l'on vien d'une des pages faisant parti du docForm (pref, rowForm ou récap de stock) on récupère le doc en cours de création dans la BD locale
						var tempPattern = /docRowForm|docPrefGlobal|stockRecap/i;

						if (tempPattern.test(comefrom) || _this.isNew && comefrom === 'list' || _this.hasDocSource) {

							var fetchRemoteOrLocal = 'fetchLocal';

							if (tempPattern.test(comefrom)) {
								_this.inTemp = true;
								_this.modelToSave.set({ id: 0 });
							}
						} else {
							var fetchRemoteOrLocal = 'fetch';
						}

						_this.modelToSave[fetchRemoteOrLocal].call(_this.modelToSave).then(function () {

							_this.modelToSave.set({ id: docId, docid: docId }); //when fetch is done , we restore id's

							var promises = [];

							if (_this.hasDocSource || _this.isNew) {
								//we take next ident for new/conversion/model

								_this.modelToSave.set({ doctype: doctype }); // if context is model, true doctype is in :parentDoctype else in :doctype

								promises.push(Document.getNextIDent(_this.modelToSave.get('doctype')).then(function (data) {
									_this.modelToSave.set({ ident: data.ident });
								}));
							}

							_this.rateCategory = new RateCategory({ id: _this.modelToSave.get('rateCategory') });

							switch (_this.modelToSave.get('thirdRelationType')) {
								case "client":
									_this.third = new Third({ id: thirdid });
									break;
								case "prospect":
									_this.third = new Prospect({ id: thirdid });
									break;
							}

							//in case of update we take linked datas on server 
							if (!_this.isNew) {
								promises.push(_this.third.fetch());
								promises.push(_this.rateCategory.fetch());
							} else {
								promises.push(_this.third.fetchLocal());
								promises.push(_this.rateCategory.fetchLocal());
							}

							if (_this.isStockLoaded) {

								promises.push(App.getPrefs('stock').then(function (stockPrefs) {
									_this.stockPrefs = stockPrefs;
								}));
							}

							Promise.all(promises).then(function () {

								//set originalid in corpAddress id
								//for model/copy/conversion, unset addresses deleted
								if (_this.hasDocSource) {

									var corpAddress = _this.modelToSave.get('corpAddress');

									if (corpAddress) {

										corpAddress['id'] = corpAddress['originalid'];

										if (App.currentCorp.get('addresses')[_this.modelToSave.get('corpAddress')['originalid']]) {
											_this.modelToSave.set({ 'corpAddress': corpAddress });
										} else {
											_this.modelToSave.unset('corpAddress');
											_this.modelToSave.unset('corpAddressId');
										}
									}

									if (_this.modelToSave.get('thirdaddress')) {

										var thirdAddress = _this.modelToSave.get('thirdaddress');
										thirdAddress['id'] = thirdAddress['originalid'];

										if (_this.third.get('addresses')[_this.modelToSave.get('thirdaddress')['originalid']]) {
											_this.modelToSave.set({ 'thirdaddress': thirdAddress });
										} else {
											_this.modelToSave.unset('thirdaddress');
										}
									}

									if (_this.modelToSave.get('shipaddress')) {

										var shipAddress = _this.modelToSave.get('shipaddress');
										shipAddress['id'] = corpAddress['originalid'];

										if (_this.third.get('addresses')[_this.modelToSave.get('shipaddress')['originalid']]) {
											_this.modelToSave.set({ 'shipAddress': shipAddress });
										} else {
											_this.modelToSave.unset('shipaddress');
										}
									}

									//on  supprime le row id si  le doc à été créé à partir d'un autre doc (model, transformation, copie)
									_.each(_this.modelToSave.get('rows'), function (row, key) {

										if (_this.hasDocSource === true) {
											delete _this.modelToSave.get('rows')[key].id;
										}
									});
								}

								if (transformTo) {
									//now we have data of parent/model doc, set new doc id to 0
									_this.modelToSave.set({ id: 0 });
								}

								//set linkedThird 
								_this.modelToSave.set({ linkedThird: _this.third.toJSON() });

								//check doubleVAT pref
								if (_this.modelToSave.get('hasTaxesInc') === 'Y') {
									_this.isTTC = true;
									_this.modelToSave.set({ 'hasDoubleVat': 'N' });
								} else if (!_this.modelToSave.get('hasDoubleVat')) {
									_this.modelToSave.set({ 'hasDoubleVat': _this.modelToSave.get('prefs').useDoubleVat });
								}

								//set third paymediums on doc for new doc
								if (!_this.modelToSave.get('payMediums')) {
									_this.modelToSave.set({ 'payMediums': _.isArray(_this.third.get('prefs').payMediums) ? _this.third.get('prefs').payMediums.join() : '' });
								}

								//init docNumberformat
								if (_this.modelToSave.get('num_format')) {

									_this.numberFormat = $.extend(true, {}, new NumberFormat(_this.modelToSave.get('num_format')));

									//in case of update we havent currency id so we get it with currency symbol
									if (!_this.modelToSave.get("num_format").currencyid) {

										Currency.getCurrencyBySymbol(_this.modelToSave.get('num_format').currencysymbol).then(function (currency) {

											_this.modelToSave.attributes.num_format.currencyid = currency.id;
											resolve(_this.modelToSave.toJSON());
										});
									} else {
										resolve(_this.modelToSave.toJSON());
									}
								} else {

									_this.numberFormat = $.extend(true, {}, App.numberFormat);
									_this.numberFormat.precisioncustom = _this.modelToSave.get('prefs').numberprecisioncustom ? JSON.parse(_this.modelToSave.get('prefs').numberprecisioncustom) : '';

									//update numberFormatCurrency with currency of rateCategory else thirdCurrency
									if (_this.rateCategory.get('currencyid') != 0) {
										_this.currency = new Currency({ id: _this.rateCategory.get('currencyid') });
									} else {
										_this.currency = new Currency({ id: _this.third.get('prefs').currencyid });
									}

									_this.currency.fetchLocal().then(function () {

										var currency = _this.currency.get('symbol');
										_this.numberFormat.currency.symbol = currency;

										_this.modelToSave.set({ 'num_format': _this.numberFormat.toArray() });
										_this.modelToSave.attributes.num_format.currencyid = _this.currency.id;

										resolve(_this.modelToSave.toJSON());
									});
								}
							}, function (err) {
								_this.resourceNotAvailable();
								_this.goToPreviousPage();
							});
						});
					});
				};

				this.subviews = [];
				this.template = "templates/document/form.twig";
				this.form = new Form({ parentView: this });

				this.subviews.push(this.form);
				this.render();
				//events declaration
				this.listenTo(this.form, 'dataSend', this.formAction);
			}, _this));
		},

		afterRender: function () {

			this.selectizeObjects = [];
			this.form.delegateEvents();

			/**
    * Build Third Address
    * 
    */
			var docThirdAddr = this.modelToSave.get('thirdaddress');
			this.thirdAddresses = !_.isEmpty(this.third.get('addresses')) ? _.clone(this.third.get('addresses')) : {};
			docThirdAddr = this.buildAddress(docThirdAddr);

			//add docthird address in addresses list
			this.thirdAddresses[docThirdAddr.id + "_0"] = docThirdAddr;

			if (_.isEmpty(docThirdAddr) && !_.isEmpty(this.thirdAddresses)) {
				docThirdAddr = this.thirdAddresses[this.third.get('mainaddressid') + '_0'];
			} else {

				//delete original address if she is equals with docThird address
				if (this.thirdAddresses[docThirdAddr.originalid + '_0'] && docThirdAddr.originalid != docThirdAddr.id) {

					if (Utils.isAddressEquals(docThirdAddr, this.thirdAddresses[docThirdAddr.originalid + '_0'])) {
						delete this.thirdAddresses[docThirdAddr.originalid + '_0'];
					}
				}
			}

			if (docThirdAddr && docThirdAddr.id) {

				var placeholderThirdAddr = App.polyglot.t('billingAddress');

				this.selectizeObjects.push({
					domElt: $(".invoiceAddr-selectize", this.$el),
					create: false,
					valueField: 'id',
					labelField: 'name',
					searchField: ['name,part1,part2'],
					options: this.thirdAddresses,
					maxItems: 1,
					maxOptions: 10,
					placeholder: placeholderThirdAddr,
					defaultValues: [docThirdAddr.id],
					render: 'address'
				});
			}

			/**
    * Build corp Address 
    *
    */
			var corpAddress = this.modelToSave.get('corpAddress');
			this.corpAddresses = !_.isEmpty(App.currentCorp.get('addresses')) ? _.clone(App.currentCorp.get('addresses')) : {};
			var corpAddrToCheck = this.buildAddress(corpAddress);

			//if we have not doccorp address take main corp address
			if (_.isEmpty(corpAddrToCheck) && !_.isEmpty(App.currentCorp.get('addresses'))) {
				var corpAddrToCheck = App.currentCorp.get('addresses')[App.currentCorp.get('mainaddressid')];
			} else {

				//add docCorp address in corp addresses list
				if (!this.corpAddresses[corpAddrToCheck.id]) {
					this.corpAddresses[corpAddrToCheck.id] = corpAddrToCheck;
				}

				//delete original address if is was equals with doccorp address
				if (this.corpAddresses[corpAddrToCheck.originalid] && corpAddrToCheck.originalid != corpAddrToCheck.id) {

					if (Utils.isAddressEquals(corpAddrToCheck, this.corpAddresses[corpAddrToCheck.originalid])) {
						delete this.corpAddresses[corpAddrToCheck.originalid];
					}
				}
			}

			if (corpAddrToCheck.id) {

				var placeholderCorpAddr = App.polyglot.t('corpAddress');

				this.selectizeObjects.push({
					domElt: $(".corpAddr-selectize", this.$el),
					create: false,
					valueField: 'id',
					labelField: 'name',
					searchField: ['name,part1,part2'],
					options: this.corpAddresses,
					maxItems: 1,
					maxOptions: 10,
					placeholder: placeholderCorpAddr,
					defaultValues: [corpAddrToCheck.id],
					render: 'address'
				});
			}

			Page.prototype.afterRender.apply(this, arguments); //Call parent afterRender

			var _this = this;

			//Subviews declaration
			this.docFormHeader = new DocFormHeader({ parentView: this }, this.router);
			this.subviews.push(this.docFormHeader);

			//DOM Declaration
			this.$.headerDoc = $('#header-doc', this.docFormHeader.$el);
			this.$.itemRows = $('.item-row', this.$el);
			this.$.ratecategory = $('input[name="document[rateCategory]"]', this.$el);
			this.$.shipAddr = $(".shippAddr-selectize", this.$el);
			this.$.thirdAddr = $(".invoiceAddr-selectize", this.$el);
			this.$.corpAddr = $('.corpAddr-selectize', this.$el);
			this.$.newRow = $('#new-row', this.$el);
			this.$.linkedProducts = $('#linked-products', this.$el);

			TinyMCE.init({
				selector: ".textedit",
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize placeholder",
				menubar: false,
				statusbar: false,
				autoresize_max_height: '100px'
			});

			this.initCommentEditor('.rowedit');

			//make opacity on row in option
			$('.datas-row[data-row_is-option="Y"]', this.$.itemRows).closest(".item-row").addClass("isOption");

			//preset inputs
			$('select[name="document[docspeakerStaffId]"]').val(this.modelToSave.get('docspeakerStaffId') ? this.modelToSave.get('docspeakerStaffId') : App.currentStaff.id);

			if (App.hasVat) {

				//check if doc ttc or ht
				if (this.modelToSave.get('hasTaxesInc') === 'Y' || this.rateCategory instanceof Backbone.Model && this.rateCategory.get('hasTaxesInc') === 'Y') {

					this.hasTaxesInc = 'Y';
					$('.docHT', _this.$el).remove();
					this.isDocTTC = true;
				} else {

					this.hasTaxesInc = 'N';
					$('.docTTC', _this.$el).remove();
					this.isDocTTC = false;
				}

				$('.docNoVat', this.$el).remove();
			} else {

				this.hasTaxesInc = 'N';
				$('.docTTC, .docHT', _this.$el).remove();
			}

			if (this.$.itemRows.length) {
				this.sumDoc();
			}

			if ($('.subtotal-row', this.$el).length) {
				this.calcSubTotals(); //compute every subtotal lines
			}
		},

		buildAddress: function (addressToCheck) {

			//choose addr
			if (addressToCheck && !_.isEmpty(addressToCheck)) {

				//format address for displaying 
				addressToCheck.toDisplay = _.isArray(addressToCheck['toDisplay']) ? {} : addressToCheck['toDisplay'];
				addressToCheck.toDisplay['countryname'] = this.countriesList[addressToCheck.countrycode]['name'];

				if (!addressToCheck.name) {
					addressToCheck.name = addressToCheck.toDisplay['countryname'];
				}
			} else {
				addressToCheck = {};
			}

			return addressToCheck;
		},

		/**
   * @desc add a formatting line (title,break,comment....)
   */
		formattingAction: function (e) {

			var $currentTarget = $(e.currentTarget);
			var $faStack = $('.fa-stack', $currentTarget);

			//take index of last row for put new row after it
			var $lastRow = $('#linked-products .doc-row:last', this.$el);
			var $index = $lastRow.length ? parseInt($('input[name$="[row_index]"]', $lastRow).val()) + 1 : 0;

			//clone hidden row who is a base template  for every formatting rows
			var $row = this.$.newRow.clone();

			//prepare row before display it
			$row.removeAttr('id').removeClass('hide');
			$('input[name="row[row_index]"]', $row).val($index).attr('name', $index + '[row_index]');
			$row.addClass($faStack.attr("id"));

			$row.attr('data-row_type', $faStack.attr('id'));

			//build row depending of type
			switch ($faStack.attr("id")) {

				case 'comment':

					var $comment = $('.new-comment', this.$el).clone();
					var name = $index + '[row_comment]';
					var id = 'row_comment_' + Moment().unix();
					var selector = '#' + id;

					$row.attr('data-tinymce_id', id);
					$comment.removeClass("hidden new-comment").addClass("rowedit datas-row").attr('data-row_type', "comment").attr("name", name).attr('id', id).attr('placeholder', App.polyglot.t("comment"));
					$row.find('.change-rank').before($comment);

					break;

				case 'title':

					$row.find('.change-rank').before('<input placeholder="' + App.polyglot.t("title") + '" class="col-xs-8 datas-row" data-row_type="title" name=' + $index + '[row_title]>');

					break;

				case 'sum':

					$row.addClass('vertical-center').find('.change-rank').before("<h1 class='formatting-label'>Sous-total</h1><span class='subtotal-amount formatting-label text-right'></span>");
					$('input[name$="[row_index]"]', $row).addClass("datas-row").attr('data-row_type', 'sum');

					break;

				case 'empty':

					var $rowContainer = $row;

					$rowContainer.find('.change-rank').before('<div class="formatting-label">Saut de ligne</div>');
					$rowContainer.append('<input class="datas-row" type="hidden" name="' + $index + '[row_type]" value="empty">');

					break;

				case 'break':

					var $rowContainer = $row;

					$rowContainer.find('.change-rank').before('<div class="formatting-label">Saut de page</div>');
					$rowContainer.append('<input class="datas-row" type="hidden" name="' + $index + '[row_type]" value="break">');

					break;

			}

			this.$.linkedProducts.append($row);

			if (selector) {
				this.initCommentEditor(selector, $row);
			}

			this.calcSubTotals();
		},

		/**
   * 
   * @returns {undefined}
   */
		calcSubTotals: function () {

			var $rows = $('#linked-products .doc-row', this.$el);
			var _this = this;

			//put every rows in array
			var rowsArray = [];

			$rows.each(function (index) {
				var $this = $(this);
				rowsArray.push({ type: $this.data('row_type'), amount: $('.datas-row', $this).data("totalAmount"), domElmt: $this });
			});

			var subtotalAmount = new Big(0);

			_.each(rowsArray, function (row) {

				if (row.domElmt.hasClass('item-row') && !row.domElmt.hasClass('isOption')) {
					// rows with class 'item-row' are calculable rows
					subtotalAmount = subtotalAmount.plus(row.amount);
				} else if (row.type === 'sum') {
					//display sub total
					$('.subtotal-amount', row.domElmt).text(_this.numberFormat.formatToDisplay(subtotalAmount.toString()));
					subtotalAmount = new Big(0);
				}
			});
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		docInTemp: function (e) {

			var $currentTarget = $(e.currentTarget);
			var goto = $currentTarget.data('goto');
			var $row = $currentTarget.closest('.doc-row');

			$currentTarget.css('pointer-events', 'none');

			if ($row.length) {

				this.lineToModify = parseInt($('input[name$="[row_index]"]', $row).val());
				this.linkedRowId = $('.datas-row', $row).val() ? $('.datas-row', $row).val() : 0;
				this.declid = $('.datas-row', $row).data('row_declid') ? $('.datas-row', $row).data('row_declid') : 0;
			}

			this.form.$el.trigger('submit', [true, goto, $currentTarget]);
		},

		/**
   * @desc make the sum of a doc, display it and stock it in var
   * @returns {undefined}
   */
		sumDoc: function () {

			var _this = this;
			var arrayRows = [];
			var hasDoubleVat = this.modelToSave.get('hasDoubleVat') === 'Y';
			var globalDiscountUnit = this.modelToSave.get('globalDiscountUnit');
			var globalDiscount = this.modelToSave.get('globalDiscount');

			/*
    * build rows for make the doc sum
    */
			_.each(this.modelToSave.get('rows'), function (row) {

				if ((row.type === 'item' || row.type === 'once' || row.type === 'packaging' || row.type === 'shipping') && row.isOption !== 'Y') {

					var row2Compute = {
						qt: row.qt,
						taxid: row.taxid,
						discountUnit: row.discountUnit,
						discount: row.discount,
						unitAmount: row.unitAmount,
						type: row.type
					};

					if (App.hasVat) {

						row2Compute.taxRate = _this.taxes.get(row.taxid).get('value');

						if (hasDoubleVat) {
							row2Compute.tax2id = row.tax2id;
							row2Compute.taxRate2 = _this.taxes.get(row.tax2id).get('value');
						}
					}

					if (row.useEcoTax === 'Y') {

						row2Compute.useEcoTax = true;
						row2Compute.ecoTax = row.ecoTax;
						row2Compute.ecoTaxDisplay = row.ecoTaxDisplay;
						row2Compute.ecoTaxType = row.ecoTaxType;
					}

					arrayRows.push(row2Compute);
				}
			});

			/**
    * make the doc sum
    */
			this.docSum = Math.sumRowsV2(arrayRows, {
				hasDoubleVat: this.modelToSave.get('hasDoubleVat'),
				hasTaxesInc: this.hasTaxesInc,
				globalDiscount: globalDiscount,
				globalDiscountUnit: globalDiscountUnit,
				hasVat: App.hasVat,
				numberFormat: this.numberFormat
			});

			/**
    * Display result
    */

			this.totalPrecision = this.numberFormat.precision > 2 ? 2 : this.numberFormat.precision;

			if (this.isDocTTC || !App.hasVat) {
				$('#total-amount .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmount, true, true, undefined, this.totalPrecision));
				$('#totalAllInc .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmountNet, true, true, undefined, this.totalPrecision));
				$('#totalDue .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmountAllInc, true, true, undefined, this.totalPrecision));
			} else {
				$('#totalTaxesFree_step .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmountNet, true, true, undefined, this.totalPrecision));
				$('#totalTaxesFree .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmount, true, true, undefined, this.totalPrecision));
				$('#totalAllInc .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmountAllInc, true, true, undefined, this.totalPrecision));
			}

			if (new Big(this.docSum.globalDiscountAmount).gt(0)) {
				$('#totalDiscount .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.globalDiscountAmount, true, true, undefined, this.totalPrecision));
				$('#totalDiscount_step .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalAmountDiscounted, true, true, undefined, this.totalPrecision));
				$('.discount-line', this.$el).removeClass('hide');
			} else {
				$('.discount-line.value', this.$el).text(0);
				$('.discount-line', this.$el).addClass('hide');
			}

			if (!_.isEmpty(_this.docSum.taxPart)) {

				$('.tax-line', this.$el).each(function () {

					var $taxLine = $(this);
					var taxid = $taxLine.data('taxid');

					if (_this.docSum.taxPart[taxid]) {
						var taxAmount = _this.docSum.taxPart[taxid].amount;
						$taxLine.removeClass('hide').children(".value").text(_this.numberFormat.formatToDisplay(taxAmount, true, true, undefined, _this.totalPrecision));
					} else {
						$taxLine.addClass('hide');
					}
				});
			} else {
				$('.tax-line', this.$el).hide();
			}

			var $ecoTax = $('.ecotax-part', this.$el);
			var $ecotaxVal = $('.ecotax-val.value', $ecoTax);
			var $totalPackaging = $('#totalPackaging .value', this.$el);
			var $totalTaxesFreeStep = $('#totalTaxesFree_step', this.$el);
			var $shippingPart = $('.shipping-part', this.$el);
			var $packagingPart = $('.packaging-part', this.$el);

			if (new Big(this.docSum.ecoTaxPart).gt(0)) {

				$ecotaxVal.text(this.numberFormat.formatToDisplay(this.docSum.ecoTaxPart, true, true, undefined, this.totalPrecision));
				$('#totalAllEcoTax .value').text(this.numberFormat.formatToDisplay(this.docSum.ecoTaxAmount, true, true, undefined, this.totalPrecision));

				$ecoTax.removeClass('hide');
			} else {

				$ecotaxVal.text(0);
				$('#totalTaxesFree_step .value', $ecoTax).text(0);
				$('#totalAllEcoTax .value').text(0);
				$ecoTax.addClass('hide');
			}

			if (new Big(this.docSum.totalShippingAmount).gt(0)) {

				$('#totalShipping .value', this.$el).text(this.numberFormat.formatToDisplay(this.docSum.totalShippingAmount, true, true, undefined, this.totalPrecision));
				$totalTaxesFreeStep.removeClass('hide');
				$shippingPart.removeClass('hide');
			} else {

				$('#totalShipping .value', this.$el).text(0);
				$shippingPart.addClass('hide');
			}

			if (new Big(this.docSum.totalPackagingAmount).gt(0)) {

				$totalPackaging.text(this.numberFormat.formatToDisplay(this.docSum.totalPackagingAmount, true, true, undefined, this.totalPrecision));
				$packagingPart.removeClass('hide');
				$totalTaxesFreeStep.removeClass('hide');
			} else {

				$totalPackaging.text(0);
				$packagingPart.addClass('hide');
			}
		},

		/**
   * @description delete row to the doc
   */
		deleteRow: function (e) {

			e.stopImmediatePropagation();
			e.stopPropagation();

			var $currentTarget = $(e.currentTarget);
			var $row2Delete = $currentTarget.closest('.doc-row');
			var rowIndex = $('input[name$="[row_index]"]', $row2Delete).val();
			var rows = _.toArray(this.modelToSave.get('rows'));

			if ($row2Delete.data('row_type') === 'comment') {
				var id = $row2Delete.data('tinymce_id');
				var ed = TinyMCE.get(id);
				ed.remove();
			}

			rows.splice(rowIndex, 1); //delete row of rows array
			$row2Delete.remove(); //delete row of DOM

			$('.doc-row', "#linked-products").each(function (index) {

				$('input[name$="[row_index]"]', this).val(index).attr('name', index + '[row_index]');

				var name = $('.datas-row', this).attr('name');
				name = name.replace(/[0-9]+(.*)/, index + "$1");

				$('.datas-row', this).attr('name', name);
			});

			this.modelToSave.set({ rows: rows });

			if ($('.item-row', this.$el).length === 0) {
				$('.value', '#price-details').text(this.numberFormat.formatToDisplay(0));
			}

			this.calcSubTotals();
			this.sumDoc();
		},

		/**
   * 
   */
		changeRank: function (e) {

			var $currentTarget = $(e.currentTarget);
			var $row = $currentTarget.closest('.doc-row');
			var rowRank = $('input[name$="[row_index]"]', $row).val();

			$row.css('background-color', 'aliceblue');

			setTimeout(_.bind(function () {

				if ($currentTarget.hasClass('fa-arrow-up')) {

					var $prev = $row.prev();
					var $swap = $prev;

					if ($prev.hasClass('doc-row')) {

						var prevRank = $('input[name$="[row_index]"]', $prev).val();

						$prev.before($row);

						//switch rank and update input name
						$('input[name$="[row_index]"]', $row).val(prevRank).attr('name', prevRank + '[row_index]');

						var rowName = $('.datas-row', $row).attr('name');
						rowName = rowName.replace(/[0-9]+(.*)/, prevRank + "$1");
						$('.datas-row', $row).attr('name', rowName);

						$('input[name$="[row_index]"]', $prev).val(rowRank).attr('name', rowRank + '[row_index]');

						var prevName = $('.datas-row', $prev).attr('name');
						prevName = prevName.replace(/[0-9]+(.*)/, rowRank + "$1");
						$('.datas-row', $prev).attr('name', prevName);
					}
				} else {

					var $next = $row.next();
					var $swap = $next;

					if ($next.hasClass('doc-row')) {

						$next.after($row);
						var nextRank = $('input[name$="[row_index]"]', $next).val();

						//switch rank and update input name
						$('input[name$="[row_index]"]', $next).val(rowRank).attr('name', rowRank + '[row_index]');

						var nextName = $('.datas-row', $next).attr('name');
						nextName = nextName.replace(/[0-9]+(.*)/, rowRank + "$1");
						$('.datas-row', $next).attr('name', nextName);

						$('input[name$="[row_index]"]', $row).val(nextRank).attr('name', nextRank + '[row_index]');

						var rowName = $('.datas-row', $row).attr('name');
						rowName = rowName.replace(/[0-9]+(.*)/, nextRank + "$1");
						$('.datas-row', $row).attr('name', rowName);
					}
				}

				if ($swap.hasClass('sum') || $row.hasClass('sum')) {
					this.calcSubTotals();
				}

				var _this = this;

				setTimeout(function () {
					$row.removeAttr('style');
					if ($row.data('row_type') === 'comment') {

						var ed = TinyMCE.get($row.data('tinymce_id'));
						var content = ed.getContent();
						ed.remove();
						_this.initCommentEditor("#" + $row.data('tinymce_id'));
					}
				}, 270);
			}, this), 50);
		},

		/**
   * 
   */
		initCommentEditor: function (selector) {

			var tinyHeight = $('.doc-row', this.$el).height();

			TinyMCE.init({
				selector: selector,
				toolbar: false,
				plugins: "autoresize placeholder",
				menubar: false,
				statusbar: false,
				cleanup: true,
				forced_root_block: "",
				autoresize_max_height: '80px',
				width: '100%',
				setup: _.bind(function (ed) {
					//wait tinyMCe initialization before continuing

					ed.on('init', _.bind(function (args) {}, this));
				}, this)

			});
		},

		/**
   * 
   */
		displayStockRecap: function () {

			var doctype = this.modelToSave.get('doctype');

			var checkStock = true;

			if (!this.isStockLoaded || !this.checkStock) {
				checkStock = false;
			} else {

				switch (doctype) {

					case 'proforma':

						checkStock = false;

						break;

					case 'estimate':

						if (this.stockPrefs.bookStockOnEstimate === 'N') {
							checkStock = false;
						}

						break;

					case 'order':

						if (this.stockPrefs.bookStockOnOrder === 'N') {
							checkStock = false;
						}

						break;

					default:

						break;

				}

				return checkStock;
			}
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function (temp, goTo, $eventTarget) {

			var _this = this;
			var dataForm = this.form.dataForm;
			dataForm.row = {};

			//set address object instead of address id
			dataForm['document']['thirdaddress'] = this.thirdAddresses[dataForm['document']['thirdaddress'] + '_0'];

			if (this.modelToSave.get('shipaddress')) {
				dataForm['document']['shipaddress'] = this.modelToSave.get('shipaddress');
			}

			if (goTo !== 'stockRecap') {
				var displayedDate = dataForm.document['displayedDate'];
				var timestamp = Utils.HTMLDateToTimestamp(displayedDate, true);
				dataForm.document['displayedDate'] = timestamp;
			}

			dataForm.document['selectize'] = ''; //remove datepicker object

			if (temp) {
				//save the doc in temp before go to rowForm  or thirdDocForm

				this.modelToSave.set(dataForm.document);

				//put every row in row object
				_.each(dataForm, function (docData, key) {

					if (!isNaN(key)) {

						//map dataform for be right with template
						switch (docData.row_type) {

							case 'item':

							case 'once':

							case 'shipping':

							case 'packaging':

								if (!docData.row_whid && _this.isStockLoaded) {
									docData.row_whid = _this.warehouses.first().id;
								}

								dataForm['row'][docData.row_index] = {
									name: docData.row_name,
									notes: unescape(docData.row_notes),
									qt: docData.row_qt,
									taxid: docData.row_taxid,
									tax2id: docData.row_tax2id,
									declid: docData.row_declid,
									buildedId: docData.row_buildedId,
									whid: docData.row_whid,
									type: docData.row_type,
									unitText: docData.row_unit,
									unitAmount: docData.row_unitAmount,
									linkedid: docData.row_linkedid,
									totalAmount: docData.totalAmount,
									totalAmountTaxesInc: docData.totalAmount,
									isOption: docData.row_isOption,
									discountUnit: docData.row_discountUnit,
									discount: docData.row_discount,
									purchaseAmount: docData.row_purchaseAmount,
									accountingCode: docData.row_accountingCode,
									bcid: docData.row_bcid,
									barcode: docData.row_barcode,
									useEcoTax: docData.row_useEcoTax,
									promotionid: docData.row_promotionid,
									ecoTax: docData.row_ecoTax,
									ecoTaxType: docData.row_ecoTaxType,
									serialid: docData.row_serialid,
									serial: docData.row_serial,
									ihs_id: docData.row_savedserialid,
									ecoTaxDisplay: docData.ecoTaxDisplay,
									unitAmountWithoutPromo: docData.unitAmountWithoutPromo,
									linkedtype: docData.linkedtype,
									stockmove: docData.row_stockmove,
									index: docData.row_index,
									stockenabled: docData.row_stockenabled,
									mixedBarCodes: docData.mixedBarCodes,
									serial_bcid: docData.row_serial_bcid
								};

								break;

							case 'title':

								dataForm['row'][docData.row_index] = {
									type: docData.row_type,
									name: docData.row_title
								};

								break;

							case 'comment':

								dataForm['row'][docData.row_index] = {
									type: docData.row_type,
									notes: docData.row_comment
								};

								break;

							case 'break':

							case 'sum':

							case 'empty':

								dataForm['row'][docData.row_index] = {
									type: docData.row_type
								};

								break;
						}

						if (docData.row_id) {
							dataForm['row'][key]['id'] = docData.row_id;
						}
					}
				});

				this.modelToSave.set({
					rows: dataForm.row,
					docid: this.modelToSave.get('id'), //keep original id in memory
					id: 0 //we don't modify original doc but we create a local copy 
				});

				this.modelToSave.saveLocal().then(function () {
					// create local copy

					var url = URLBuilder(['document', goTo, _this.modelToSave.get('doctype'), _this.modelToSave.get('thirdid'), _this.modelToSave.get('docid')]);

					switch (goTo) {

						case 'docRowForm':

							url = URLBuilder([url, _this.modelToSave.get('docLang')], false);

							if (!_.isUndefined(_this.lineToModify)) {
								url = URLBuilder([url, _this.lineToModify, _this.linkedRowId, _this.declid], false);
							}

							break;

						case 'docThirdForm':

							if (!_.isUndefined($("#thirdName").data('value'))) {
								url = URLBuilder([url, _this.modelToSave.get('currentaddressid')], false);
							}

							break;

						case 'formAddress':

							url = URLBuilder(['address', "form", _this.modelToSave.get('thirdid'), $eventTarget.data('linkedtype'), 0, 'docForm']);

							break;

						default:

							break;

					}

					_this.router.navigate(url, { trigger: true, replace: true });
				}, function (error) {
					console.log(error);
				});
			} else {

				//put every rows in row object
				_.each(dataForm, function (docData, key) {

					if (!isNaN(key)) {

						if (!docData.row_id) {
							delete docData.row_id;
						}

						dataForm['row'][key] = docData;

						if (_this.isTTC && docData.row_useEcoTax === 'Y') {}
						//							dataForm['row'][key]["row_ecoTax"] = docData.row_ecoTaxDisplay;

						//put data specific to row type
						if (docData.row_type === 'packaging') {
							dataForm['row'][key]["row_packaging"] = docData.row_name;
						}

						if (docData.row_type === 'shipping') {
							dataForm['row'][key]["row_shipping"] = docData.row_name;
						}
					}
				});

				if (dataForm['document']['payMediums']) {
					dataForm['document']['payMediums'] = dataForm['document']['payMediums'].split(',');
				}

				this.modelToSave.set(dataForm);

				var isnew = this.modelToSave.isNew();

				_.each(this.modelToSave.get('row'), function (row, key) {

					if (row.row_stockenabled === 'Y') {
						_this.checkStock = true;
					}

					if (Document.isEmptyRow(row)) {
						//delete empty row
						delete _this.modelToSave.get("row")[key];
					} else {
						//unescape notes
						_this.modelToSave.get('row')[key].row_notes = unescape(_this.modelToSave.get('row')[key]['row_notes']);
					}
				});

				if (this.modelToSave.isValid()) {

					//display stockRecap page, depending some conditions
					if (this.displayStockRecap()) {
						this.formAction(true, 'stockRecap'); //save the doc in temp and go to stock recap page
					} else {

						if (isnew) {
							var toastMsg = _this.toastMessages.docSaved;
						} else {
							var toastMsg = _this.toastMessages.docUpdated;
						}

						this.modelToSave.save().then(function () {

							_this.form.trigger('successful', toastMsg);
							_this.router.navigate(URLBuilder(["document", "overview", _this.modelToSave.get('doctype'), _this.modelToSave.id]), { trigger: true, replace: true });
						}, function (error) {
							// re activate submit button
							$('.recordDoc').show();
							$('.fa-circle-o-notch').remove();
							_this.formInError = true;
						});
					}
				} else {

					// re activate submit button
					$('.recordDoc').show();
					$('.fa-circle-o-notch').remove();

					//Make invalid inputs in error
					_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

						if (!_.isEmpty(error)) {

							_.each(error, function (msgError, keyMsg) {
								_this.form.showValidationError(keyError, keyMsg, msgError, true);
							});
						}
					});
				}
			}

			return false;
		}

	});

	return DocForm;
});
