/**
 *	@desc Model , extend Core Model 
 */
define('models/singles/bankAccount',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			accounting: {},
			hasiban: 'N'
		},

		storeName: 'bankAccount',
		name: 'bankAccount',
		urlRoot: '/rest/bankAccount',

		validate: function (attributes) {

			var required = {
				"bankAccount": {
					'label': true,
					'linkedtype': false,
					'linkedid': false,
					'hasiban': true,
					'accountingCode': false
				}
			};

			if (attributes.hasiban === 'Y') {

				required.bankAccount = _.extend(required.bankAccount, {

					'bic': true,
					'iban': true,
					'sepa_authorizationNumber': false,
					'sepa_transmitterNationalNumber': false,
					'sepa_signaturemandat': false,

					'name': false,
					'number': false,
					'rounting': false,
					'swift': false

				});
			} else {
				required.bankAccount = _.extend(required.bankAccount, {

					'bic': false,
					'iban': false,
					'sepa_authorizationNumber': false,
					'sepa_transmitterNationalNumber': false,
					'sepa_signaturemandat': false,

					'name': true,
					'number': true,
					'rounting': true,
					'swift': true

				});
			}

			var _this = this;
			var validationErrors = {};
			validationErrors['bankAccounts'] = {};

			//check required
			_.each(required.bankAccount, function (attr, nameAttr) {

				if (!attributes[nameAttr] && required['bankAccount'][nameAttr]) {
					validationErrors['bankAccounts'][nameAttr] = _this.computeError({ name: nameAttr, type: 'required' });
				}
			});

			if (_.toArray(validationErrors['bankAccounts']).length > 0) {
				return validationErrors;
			}
		}

	});
});
