/**
* @desc Page Overview, extend Page View
* Child View : Core Overview
*/

define('views/core/pageOverview',['underscore', 'views/core/page', 'views/core/form', 'models/collections/customfields', 'models/collections/unities', 'models/collections/smarttags', 'views/core/accordion', 'views/core/shortenedPanel', 'tools/buildURL', 'tools/core/utils', 'app', 'views/core/toastNotification', 'tinyMCE', 'libs/plugins/placeholder/plugin'], function (_, Page, Form, Customfields, Unities, Smarttags, Accordion, ShortenedPanel, URLBuilder, Utils, App, ToastNotification, TinyMCE) {

	'use strict';

	return Page.extend({

		smartTags: {},
		smartTagsFilter: {},
		customFieldFilter: {},
		required4Accordion: {},

		events: {
			'click #customFields-container .submit-form': 'onCFSubmit'
		},

		actionList: {

			modify: {
				fa: false,
				label: App.polyglot.t("update"),
				link: function () {

					if (App.isOnline) {
						this.goToForm();
					} else {
						this.notifWriteDisabled();
					}
				},
				aClass: 'button-with-network'
			},

			delete: {
				fa: false,
				label: App.polyglot.t("delete"),
				link: function () {

					if (App.isOnline) {
						this.showPopupConfirm();
					} else {
						this.notifWriteDisabled();
					}
				},
				aClass: 'button-with-network'
			}

		},

		/**
   * 
   * @param {type} router
   * @returns {undefined}
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			var toast = {
				customfieldsRecord: this.polyglot.t('customfieldsSaved')
			};

			if (this.toastMessages) {
				this.toastMessages = _.extend(this.toastMessages, toast);
			} else {
				this.toastMessages = toast;
			}

			// get cfs
			if (!_.isEmpty(this.customFieldFilter)) {

				this.unities = new Unities();

				this.customfields = new Customfields();
				this.customfields.XHRData = {
					search: this.customFieldFilter,
					getAll: true
				};

				if (!App.isOnline) {

					//query params for offline mode
					this.customfields.XHRData.search['useOn_' + this.customfields.XHRData.search.useOn] = 'Y';
					this.customfields.offlineQueryArgs.push('useOn_' + this.customfields.XHRData.search.useOn);
				}

				this.form = new Form({ parentView: this }, '', '#customfields');
				this.subviews.push(this.form);

				this.collections[this.customfields.name] = this.customfields;
				this.collections[this.unities.name] = this.unities;

				this.listenTo(this.form, 'dataSend', this.formAction);
			}

			// get smartTags listing
			if (!_.isEmpty(this.smartTagsFilter)) {

				// SmartTags parts. Get smarttags of linked
				this.smartTags = new Smarttags();

				this.smartTags.XHRData = {
					search: this.smartTagsFilter
				};

				this.collections[this.smartTags.name] = this.smartTags;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			Page.prototype.afterRender.apply(this, arguments);

			App.header.$el.find("nav").addClass("box-shadow-none");
			App.header.$.brandMenu.addClass('hidden');

			if (!_.isEmpty(this.smartTagsFilter)) {

				this.constructSmartTags().then(function () {

					_this.initSelectize();

					if (!_this.canEdit) {
						$(".selectize-smarttags", _this.$el)[0].selectize.lock();
						$('#customfields .btn-submit', _this.$el).remove();
					}
				});
			}

			// _this.initPullToRefresh(this.el, $('.overview',this.$el));

			//insert subviews
			_.each(this.subviews, function (subview) {

				if (_.isFunction(subview.insertInDom)) {
					subview.insertInDom();
				}
			});

			// if there is customfields on overview , instanciate a form
			// + add an event on submit who will be triggered formaction on the bottom of this class
			if (!_.isEmpty(this.customFieldFilter)) {

				TinyMCE.init({
					selector: 'textarea',
					toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
					plugins: "autoresize placeholder",
					menubar: false,
					statusbar: false,
					cleanup: true,
					forced_root_block: "",
					autoresize_max_height: '80px',
					width: '100%',
					setup: _.bind(function (ed) {
						//wait tinyMCe initialization before continuing

						ed.on('init', _.bind(function (args) {}, this));
					}, this)

				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		checkRight: function () {

			if (!this.canEdit) {

				var options = {
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: App.polyglot.t('insufficientPrivileges')
				};

				new ToastNotification(options);
			}

			return this.canEdit;
		},

		/**
   * 
   * @returns {undefined}
   */
		buildShortenedPanel: function (colorFocused) {

			if (!Utils.findInObjectsByValue(this.subviews, 'name', 'shortenedPanel')) {
				this.shortP = new ShortenedPanel({ parentView: this }, this.shortenedPanel);
				this.subviews.push(this.shortP);
			}
		},

		/**
   * 
   * @returns {Boolean}
   */
		onCFSubmit: function () {
			if (!this.checkRight()) {
				return false;
			}
		},

		/**
   * 
   * @returns {Boolean}
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var cftoSave = new Customfields();
			var _this = this;

			_.each(dataForm, function (cf, index) {
				cf.nameattr = index + '[value]';
				cftoSave.add(cf);
			});

			var error = false;

			// Error checking - if an error is thrown during validate, show it in form
			cftoSave.each(function (cf, index) {

				if (!cf.isValid()) {

					//Make the input invalid in error
					_.each(cf.validationError, function (error, keyError, arrayError) {

						if (!_.isEmpty(error)) {

							_.each(error, function (msgError, keyMsg) {
								_this.form.showValidationError(keyError, keyMsg, msgError, true);
							});
						}
					});

					error = true;
					return;
				}
			});

			// if no error is founded, SAVE THEM ALL
			if (!error) {

				cftoSave.XHRData = this.customFieldFilter;

				cftoSave.save().then(function () {

					_this.model.fetch().then(function () {

						//click on the title of the panel to collapse it
						_this.form.$el.prev().find('a').click();
						_this.form.showConfirmation(_this.toastMessages.customfieldsRecord);
						$('.submit-form', _this.form.$el).removeAttr('disabled');
					});
				}, function () {

					// re activate submit button
					$('.submit-form', _this.form.$el).removeAttr('disabled');
					_this.form.showError(App.polyglot.t('anErrorOccured'));
				});
			} else {
				$('.submit-form', _this.form.$el).removeAttr('disabled');
			}

			return false;
		},

		goToForm: function () {}

	});
});
