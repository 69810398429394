/**
 * @desc Email Overview, extend Page View
 * Child View : Core Overview
 */
define('views/email/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/email', 'app', 'tools/buildURL'], function (_, Page, Overview, Email, App, URLBuilder) {

	'use strict';

	return Page.extend({

		events: _.extend({
			'click .mail-actions': 'goToMailForm'
		}, Page.prototype.events),

		popupConfirmOptions: {},
		listing: {},
		toastMessages: {
			email: {
				emailRemoved: App.polyglot.t("emailRemoved")
			}
		},

		actionList: {
			//			delete : {
			//				fa		: false,
			//				label	: App.polyglot.t("delete"),
			//				link	: function(){
			//					this.showPopupConfirm();
			//				},
			//			}
			forwardTo: {
				fa: false,
				label: App.polyglot.t("forwardTo"),
				aClass: "forward mail-actions",
				link: function (e) {
					this.goToMailForm(e);
				}

			},
			replyTo: {
				fa: false,
				aClass: "reply mail-actions",
				label: App.polyglot.t("replyTo"),
				link: function (e) {
					this.goToMailForm(e);
				}

			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router, id, linkedtype, linkedid, linkedname) {

			this.canDelete = true;
			this.canAdd = true;

			if (linkedtype && linkedid) {
				this.linkedtype = linkedtype;
				this.linkedid = linkedid;
				this.linkedname = linkedname;
			}

			Page.prototype.initialize.apply(this, arguments);

			this.mail = new Email({ id: id });
			this.models[this.mail.name] = this.mail;
			this.template = "templates/email/overview.twig";
			this.overview = new Overview({ parentView: this }, this.router);

			this.subviews.push(this.overview);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			Page.prototype.afterRender.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		goToMailForm: function (e) {

			var context = $(e.currentTarget).hasClass('forward') ? 'forward' : 'reply';
			var urlToMailForm = URLBuilder(["email", "form", context, this.mail.get('id')]);

			this.router.navigate(urlToMailForm, { trigger: true });

			return false;
		},

		/**
   * 
   * @returns {Boolean}
   */
		showPopupConfirm: function () {

			var callback = this.goToPreviousPage.bind(this);
			this.removeConfirm(this.mail, this.mail.get('subject'), this.$el, this.toastMessages.email.emailRemoved, callback);

			return false;
		}

	});
});
