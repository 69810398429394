/**
* @description Page view, parent view where content are insered 
*/
define('views/core/page',['app', 'underscore', 'jquery', 'views/core/view', 'views/core/header', 'views/core/Popup', 'db/easy', 'views/sidebar/panelRight', 'transit', 'tools/core/utils', 'moment', 'datetimepicker'], function (App, _, $, View, Header, Popup, DB, panelRight, transit, Utils, Moment) {

	return View.extend({

		tagName: 'div',
		className: 'page',
		title: '',
		slideDirection: 'left',
		actionList: {},
		selectizeObjects: {},
		rightSidebar: {},
		headerEvents: {},
		constructor: function () {

			this.models = {};
			this.collections = {};
			this.renderPromises = [];
			this.toggleableViews = [];

			View.apply(this, arguments);
		},
		timepickerStep: 30,
		onBackCallback: function () {

			return new Promise(function (resolve, reject) {
				resolve();
			});
		},

		/**
  *
  * @param {type} router
  * @returns {undefined}
  */
		initialize: function (router) {

			var _this = this;

			this.subviews = [];
			this.router = router;
			this.options = {
				extraData: {}
			};

			this.modelToSave = {};

			View.prototype.initialize.apply(this, arguments);

			this.polyglot = App.polyglot;
			App.header = new Header({ parentView: this });

			this.subviews.push(App.header);

			this.renderHeader();

			//DOM elements declaration
			this.$.contentWrapper = $('.content-wrapper');
			this.$.widgetNoNetwork = $('#widget-noNetwork');
		},

		/**
   * @description , render every subviews of page and call after render when its finished
   * @returns {undefined}
   */
		render: function () {

			this.renderPromises = [];

			if (this.render4Scroll) {
				this.setLoader($('.content-wrapper'));
			}

			return new Promise(_.bind(function (resolve, reject) {

				var _this = this;

				_.each(this.subviews, function (subview) {

					if (subview.doRender !== false) {
						_this.renderPromises.push(subview.render(subview.renderOptions));
					}
				});

				Promise.all(this.renderPromises).then(function () {

					//when main view and his subviews are fetched, we render now toggleableViews
					_.each(_this.toggleableViews, function (toggleableView) {
						_this.renderPromises.push(toggleableView.render());
					});

					Promise.all(_this.renderPromises).then(function () {
						try {
							_this.afterRender();
						} catch (e) {
							App.monitoring.captureException(e, {
								extra: {
									collections: _this.collections,
									models: _this.models,
									route: _this.router.currentHash,
									template: _this.template
								}
							});
						}
						resolve();
					}, function (e) {
						console.log(e);
					});
				}, function (e) {
					console.log(e);
				}).catch(function (e) {
					console.log(e);
				});
			}, this)).catch(function (e) {
				console.log(e);
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		hidePopup: function () {
			$("#no-network").modal('hide');
			this.$.widgetNoNetwork.show();
			location.reload();
		},

		/**
   * @returns {undefined}
   */
		afterRender: function () {

			/**
    * Une fois que la page et toutes les données ont été chargées ont peux la mettre dans le cache. 
    * On enregistre la date du dernier "render" de la page
    * La prochaine fois que l'ont retourne sur cette page on vérifiera que ça durée de vie n'a pas expiré
    * Si elle n'a pas expiré on récupèrera les données de la page dans la BD locale
    * Si la page est déjà dans la cache on ne la remet pas, elle sera supprimé quand son temps de vie aura expiré 
    * 
    */
			if (!App.cacheControl[this.router.currentHash]) {
				App.cacheControl[this.router.currentHash] = Date.now();
			}

			//rebind header events
			App.header.delegateEvents();

			//remove preloader (loader on refresh)
			$(".preloader").remove();

			//loader when pull-to-refresh action
			if (!$('#refreshMe').length) {
				this.$el.prepend('<div id="refreshMe">' + this.fa_loader + '</div>');
			}

			var _this = this;

			$('.content-wrapper').removeAttr('style');
			App.header.$.rightSidebarTrigger.addClass('hidden');

			//set new current view if render is not triggered by infinite scroll
			if (App.view.cid !== this.cid) {
				this.router.setView(this);
				this.$.contentWrapper.append(this.$el);
			}

			$('input').on('focus', function (e) {

				var $scrollable = $(_this.scrollable, _this.$el);
				var $currentTarget = $(e.currentTarget);
				var offset = $currentTarget.offset();

				$scrollable.animate({ scrollTop: $scrollable.scrollTop() + offset.top - $currentTarget.height() - 75 });
			});

			App.windowHeight = $(window).height();

			//init all selectize
			this.initSelectize();

			// populate actions list
			if (this.actionList && !_.isEmpty(this.actionList)) {
				this.populateActionList();
			}

			//sidebar
			this.buildRightSideBar();

			//display title in header
			if (this.availableActions) {

				$('#page-title').html('<select id="availableActions"></select>');
				var $select = $('#availableActions');

				_.each(this.availableActions, function (action) {

					if (action.canAccess !== false) {
						$select.append('<option value="' + action.link + '">' + Utils.capitalizeFirstLetter(App.polyglot.t(action.name)) + '</option>');
					}
				});

				$select.val(_this.trueRoute);

				//div helper for set a dynamic width on select, depending of selected option
				$('#page-title').prepend('<div id="actionWidthHelper"></ div>');

				$('#actionWidthHelper').text($select.find('option:selected').text());
				$select.css('width', $("#actionWidthHelper").width() + 50); //text width + padding + 10 for put space between text and "chevron"

				$select.on('change', function (e) {
					_this.router.navigate($select.val(), { trigger: true });
				});
			} else {
				$('#page-title', $('.navbar-fixed-top')).text(this.title);
			}

			//Check network before click on action item in header
			$('.button-with-network', App.header.$el).on('click', this.checkButton.bind(this));

			$('[data-toggle="tooltip"]').tooltip({
				html: true
			});

			// check the corp preferences
			var hourParams = App.dateFormat.hourFormat == "24" ? { format: 'HH:mm', stepping: this.timepickerStep, ignoreReadonly: true, maxDate: this.maxHour, minDate: this.minHour } : { format: 'hh:mm a', stepping: this.timepickerStep, ignoreReadonly: true, minDate: this.minHour, maxDate: this.maxHour };
			// this one if for agenda for example, we check the corp date format
			$('.hour-input.check-format').datetimepicker(hourParams);
			// hour + minute
			$('.hour-input:not(.check-format)').datetimepicker({ format: 'HH:mm', ignoreReadonly: true });
			// hour + minute + seconde
			$('.hourToSecond-input:not(.check-format)').datetimepicker({ format: 'HH:mm:ss', ignoreReadonly: true });

			this.removeLoader();

			$("[data-linktorelated]").each(function () {

				this.addEventListener("click", function (e) {

					var link = $(this).data('linktorelated');
					_this.router.navigate(link, { trigger: true });

					e.stopPropagation();
				}, true);
			});

			$('.button-with-network').each(function () {

				this.addEventListener("click", function (e) {
					_this.checkButton(e);
				}, true);
			});
		},

		/**
  /**
   * 
   * @returns {undefined}
   */
		buildRightSideBar: function () {
			if (!_.isEmpty(this.sideBar)) {
				this.rightSideBar = new panelRight({ parentView: this }, this.sideBar, this.sidebarEvents);
				this.subviews.push(this.rightSideBar);
			}
		},

		/**
   * @desc open or close the sideBar
   */
		sidebarAction: function (e) {

			$current = $(e.currentTarget);

			var $sidebar = $($current.data('for')); //select sidebar triggered

			if (!$current.hasClass('mask')) {
				$('.mask').data('for', $current.data('for')); // we had data on mask for know which sidebar triggered on mask click
			}

			$(".content-wrapper").toggleClass("content-wrapper-toggled");
			App.header.$el.toggleClass("header-toggled");

			$sidebar.toggleClass("side-bar-up");
			$('.mask').toggleClass("mask-up");
		},

		/**
   * @desc display or hide the searchbar
   */
		searchBarAction: function () {

			//			$('.searchBar').toggle('slide', 'right', 500);
			$('.searchBar').toggleClass("searchBarUp");

			App.header.$.title.toggleClass("titleDown");
			$('.searchBar input').focus();

			//Avoid bug on smartphone when keyboard up resize window on listing
			if (!$('.searchBar').is(':visible')) {
				$(window).height(App.windowHeight);
				$('#listing').css('height', App.windowHeight - 50);
			}

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		changeSlideDirection: function () {
			App.slideDirection = 'right';
		},

		/**
   * 
   * @param {type} category
   * @param {type} linkedtype
   * @param {type} linkedid
   * @returns {undefined}
   */
		constructSmartTags: function () {

			var _this = this;

			var smCollection = _this.collections[_this.smartTags.name];
			var promises = [];

			return new Promise(function (resolve, reject) {

				// get smarttags of the linked id
				promises.push(smCollection.getSmartTagsByCategoryOrLinked(_this.smartTagsFilter.linkedtype, _this.smartTagsFilter.linkedid));

				// get smarttags of according to the category
				promises.push(smCollection.getSmartTagsByCategoryOrLinked(_this.smartTagsFilter.linkedtype));

				Promise.all(promises).then(function (values) {

					var dom = $(".selectize-smarttags", _this.$el);

					_this.selectizeObjects = [{
						domElt: dom,
						options: values[1],
						defaultValues: values[0],
						create: true,
						labelField: 'word',
						searchField: 'word',
						valueField: 'originalid',
						debounceTime: 1000,
						placeholder: App.polyglot.t("clicktoaddsmarttags"),
						onChange: function (value, sele) {

							var items = sele.items;
							var options = sele.options;
							var smToSave = [];

							$.each(items, function (index, value) {

								if (options[value] !== undefined) {
									smToSave.push(options[value].word);
								}
							});

							smCollection.saveSmartTags(smToSave, { linkedtype: _this.smartTagsFilter.linkedtype, linkedid: _this.smartTagsFilter.linkedid });
						},
						load: function (query, callback) {

							return new Promise(function (resolve, reject) {

								smCollection.fetch({
									url: '/rest/smarttags/search',
									method: 'POST',
									data: { search: _.extend({ contains: query }, smCollection.XHRData.search) }

								}, true).then(function () {
									resolve(smCollection);
								});
							});
						}
					}];

					resolve();
				}, function () {
					console.log(arguments);
					reject();
				});
			});
		},

		/**
   * 
   * @description call when user switch to offline. disable some inputs who require network
   */
		disableWriteActions: function () {

			//lock smartags selectize
			if ($(".selectized:not(.offline-selectize)").length) {

				$(".selectized:not(.offline-selectize)").each(function () {
					if ($(this)[0].selectize) {
						$(this)[0].selectize.lock();
					}
				});
			}
		},

		/**
   * @description call when user switch to online. disable some inputs who require network 
   */
		enableWriteActions: function () {

			if ($(".selectized:not(.offline-selectize)").length) {

				$(".selectized:not(.offline-selectize)").each(function () {
					if ($(this)[0].selectize) {
						$(this)[0].selectize.unlock();
					}
				});
			}
		}

	});
});
