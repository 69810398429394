/**
 * @desc represent the loading Page
 */
define('views/core/loadingPage',['jquery', 'backbone', 'views/core/publicPage', "tools/ajax"], function ($, Backbone, PublicPage, Ajax) {

	return PublicPage.extend({

		template: '/templates/widgets/loadingPage.twig',
		tagName: 'div',
		id: 'loading-page',
		setLoader: false,

		/**
   * 
   */
		initialize: function (router) {
			PublicPage.prototype.initialize.apply(this, arguments);
			this.router = router;
		},

		/**
   * @desc Render view
   */
		render: function () {

			var _this = this;

			return new Promise(function (renderSuccess, renderFailed) {

				var templateRequest = new Promise(function (resolve, reject) {

					//get template
					_this.requesters.template = Ajax({
						url: _this.template,
						dataType: "html",
						success: function (template) {
							resolve(template);
						},
						error: function (error) {
							console.log(error);
							reject();
						}
					});
				});

				templateRequest.then(function (html) {

					var template = _.template(html); //compile template with underscore

					_this.$el.html(template()); //Set template view

					_this.afterRender(); // insert view in DOM

					renderSuccess();
				}, function (error) {
					renderFailed(error);
				});
			});

			return this;
		}

	});
});
