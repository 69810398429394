define('views/purchase/overview',['views/document/overview', 'views/core/pageOverview', 'views/core/overview', 'models/singles/purchase', 'app', 'big', 'tools/core/utils', 'tools/buildURL', 'models/collections/taxes', 'models/collections/smarttags', 'models/collections/accountings', 'models/core/collection', 'views/widgets/tabs'], function (DocOverview, PageOverview, Overview, Purchase, App, Big, Utils, URLBuilder, Taxes, Smarttags, AccountingsCode, Collection, Tabs) {

	return DocOverview.extend({

		actionList: {

			delete: {
				fa: false,
				label: App.polyglot.t("delete"),
				link: function () {
					if (App.isOnline) {
						this.showPopupConfirm();
					} else {
						this.notifWriteDisabled();
					}
				}
			}

		},

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, id) {

			var _this = this;
			var purDoctype = Purchase.toPurDoctype(doctype);

			this.canDelete = App.permission.can("purchases_delete" + purDoctype);
			this.canEdit = App.permission.can("purchases_edit" + purDoctype);
			this.router = router;
			this.firstRender = true;
			this.isPurchase = true;
			this.doctype = doctype;
			this.smartTagsFilter = Smarttags.buildFilterByLinked("pur" + Utils.capitalizeFirstLetter(doctype), id);

			// customFields filter
			this.customFieldFilter = {
				useOn: 'purchase',
				linkedtype: 'purchase',
				linkedid: id
			};

			//some checks for display or not a panel in the accordion
			this.required4Accordion = {
				'paymentlist-container': Utils.inArray(purDoctype, ['invoice', 'purInvoice', 'estimate', 'order', 'delivery', 'proforma', 'creditnote'])
			};

			PageOverview.prototype.initialize.apply(this, arguments);

			Purchase.classNameByDoctype(purDoctype).then(function (documentType) {

				_this.document = _this.model = new documentType({ id: id });
				_this.document = _this.model = new documentType({ id: id });
				_this.template = '/templates/purchase/overview.twig';
				_this.shortenedPanel = {

					relatedType: purDoctype,
					relatedId: id,
					name: 'ident',
					model: _this.document,
					actions: {
						'briefcase': {
							label: App.polyglot.t("attachments")
						},
						'logHisto': {
							url: purDoctype === 'purCreditNote' ? URLBuilder(["log", "list", 'purCreditnote', id]) : undefined
						}
					}

				};

				if (App.pluginIsLoaded('accounting')) {

					_this.accountingsCode = new AccountingsCode();
					_this.accountingsCode.keepCollection = true;
					_this.collections[_this.accountingsCode.name] = _this.accountingsCode;
				}

				_this.taxes = new Taxes();
				_this.collections[_this.taxes.name] = _this.taxes;

				_this.options.extraData[_this.document.name] = function () {

					return new Promise(function (resolve, reject) {

						_this.document.fetch().then(function () {

							var promises = [];

							Promise.all(promises).then(function () {
								resolve(_this.document);
							}, function () {
								reject('third not found');
							});
						}, function (err) {
							reject(err);
						});
					});
				};

				_this.overview = new Overview({ parentView: _this });
				_this.subviews.push(_this.overview);
				_this.subviews.push(new Tabs(".header-overview"));
				_this.buildShortenedPanel();

				_this.render();
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageOverview.prototype.afterRender.apply(this, arguments);

			if (!this.canEdit) {
				$('#step-select').prop('disabled', true);
			}

			if (App.hasVat) {

				if (this.document.get('hasTaxesInc') === 'Y') {
					$('#price-details.docHT', this.$el).remove();
					this.isDocTTC = true;
				} else {
					$('#price-details.docTTC', this.$el).remove();
					this.isDocTTC = false;
				}

				$('.docNoVat', this.$el).remove();
			} else {
				$('.docTTC, .docHT', this.$el).remove();
			}

			if (new Big(this.document.get('globalDiscount')).gt(0)) {
				$('#price-details .discount-line').removeClass('hide');
			}

			//make opacity on row in option
			$('.doc-row[data-is-option="Y"]', this.$el).addClass("isOption");

			if (this.firstRender) {
				this.firstRender = false;
			}
		}

	});
});
