/**
 * @desc Opportunity Overview, extend PageOverview View
 * Child View : Core Overview
 */
define('views/prospection/opportunity/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/opportunity', 'app', 'tools/buildURL', 'views/core/toastNotification', 'models/collections/smarttags', 'views/widgets/tabs'], function (_, PageOverview, Overview, Opportunity, App, URLBuilder, ToastNotification, Smarttags, Tabs) {

	'use strict';

	return PageOverview.extend({

		events: _.extend({
			'click .state': 'updateStep',
			'change #opp-status': 'changeStatus'
		}, PageOverview.prototype.events),

		popupConfirmOptions: {},
		listing: {},
		toastMessages: {
			opportunity: {
				opportunityDeleted: App.polyglot.t("opportunityRemoved"),
				stepUpdated: App.polyglot.t('pipelineStateUpdated'),
				statusUpdated: App.polyglot.t('oppStatusUpdated')
			}
		},
		// customFields filter
		customFieldFilter: {
			useOn: 'opportunity',
			linkedtype: 'opportunity'
		},

		/**
   * @desc initialize
   */
		initialize: function (router, id) {

			this.canEdit = App.permission.level('prospection_editopportunity');
			this.canDelete = App.permission.can('prospection_deleteopportunity');
			this.customFieldFilter.linkedid = id;
			this.smartTagsFilter = Smarttags.buildFilterByLinked('opportunity', id);

			PageOverview.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = "templates/opportunity/overview.twig";
			this.opportunity = this.model = new Opportunity({ id: id });
			this.models[this.opportunity.name] = this.opportunity;

			this.shortenedPanel = {
				relatedType: 'opportunity',
				relatedId: id,
				name: 'ident',
				model: this.opportunity
			};

			this.overview = new Overview({ parentView: this }, this.router);
			this.subviews.push(this.overview);
			this.subviews.push(new Tabs('#header-opp'));
			this.buildShortenedPanel();

			this.listenTo(this.overview, 'renderend', this.afterRender);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var signed = this.opportunity.get('signed');
			var _this = this;

			this.selectizeObjects = [{
				domElt: $("#step-selectize input", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'label',
				searchField: ['label'],
				maxOptions: 99,
				maxItems: 1,
				options: this.opportunity.get('stepsForFunnel'),
				onChange: function (value) {
					_this.updateStep(value);
				},
				onInitialize: function () {
					$('.selectize-input', '#step-selectize').after('<div id="selectize-overlay"></div>');
					$('.selectize-input input', "#step-selectize").attr('readonly', true);
				},
				hideSelected: true,
				defaultValues: [this.opportunity.get('stepid')]
			}];

			PageOverview.prototype.afterRender.apply(this, arguments);

			this.$.oppStatus = $('#opp-status', this.$el);
			var $currentState = $('.active-state', this.$el);
		},

		/**
   * 
   * @param {event} e
   * @returns {undefined}
   */
		updateStep: function (value) {

			if (this.checkRight()) {

				var _this = this;

				this.opportunity.updateStep(value).then(function () {

					delete App.cacheControl[URLBuilder(['opportunity', 'list', _this.opportunity.get('funnelid')])];
					delete App.cacheControl[URLBuilder(['opportunity', 'list', _this.opportunity.get('funnelid'), _this.opportunity.get('linkedid')])];

					_this.render();

					new ToastNotification({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 3000,
						content: _this.toastMessages.opportunity.stepUpdated
					});
				});
			} else {

				new ToastNotification({
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 3000,
					content: App.polyglot.t("insufficientPrivileges")
				});
			}
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeStatus: function (e) {

			if (this.checkRight()) {

				var _this = this;
				var $currentTarget = $(e.currentTarget);
				var oldClass = this.opportunity.get('signed');

				this.opportunity.updateStatus($currentTarget.val()).then(function () {

					new ToastNotification({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 3000,
						content: _this.toastMessages.opportunity.statusUpdated
					});

					delete App.cacheControl[URLBuilder(['opportunity', 'list', _this.opportunity.get('funnelid')])];
					delete App.cacheControl[URLBuilder(['opportunity', 'list', _this.opportunity.get('funnelid'), _this.opportunity.get('linkedid')])];

					_this.render({ setLoader: false });
				});
			}
		},

		/**
   * 
   * @returns {Boolean}
   */
		goToForm: function () {
			this.router.navigate(URLBuilder(['opportunity', "form", this.opportunity.id]), { trigger: true });
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		showPopupConfirm: function () {

			var _this = this;

			var callback = function () {
				delete App.cacheControl[URLBuilder(['opportunity', 'list', _this.opportunity.get('funnelid'), ''])];
				_this.goToPreviousPage();
			};

			this.removeConfirm(this.opportunity, this.opportunity.get('name'), this.$el, this.toastMessages.opportunity.opportunityDeleted, callback);

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		disableWriteActions: function () {

			PageOverview.prototype.disableWriteActions.apply(this, arguments);
			this.$.oppStatus.prop('disabled', true);
		},

		/**
   * 
   * @returns {undefined}
   */
		enableWriteActions: function () {

			PageOverview.prototype.enableWriteActions.apply(this, arguments);
			this.$.oppStatus.prop('disabled', false);
		}

	});
});
