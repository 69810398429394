/**
 *  @desc Packagings Collection, extend core Collection
 */
define('models/collections/packagings',['models/core/collection', 'models/singles/packaging'], function (Collection, Packaging) {

	'use strict';

	return Collection.extend({

		model: Packaging,
		storeName: 'packaging',
		name: 'packagings',
		url: '/rest/packaging/list',
		fields: ['name'],
		canBeStored: true
	});
});
