define('views/agenda/calendarEvent',['underscore', 'views/core/page', 'views/core/overview', 'app', 'moment', 'hammerjs', 'views/widgets/hammerView', "views/agenda/calendarEventList", "models/collections/events", 'models/collections/filters', 'tools/buildURL', 'tools/core/utils', 'libs/hammer-time.min', 'libs/clndr.min'], function (_, Page, Overview, App, Moment, Hammer, HammerView, CalendarEventList, Events, ListingFilters, URLBuilder, Utils) {

	return Page.extend({

		template: "templates/agenda/calendar.twig",
		subviews: [],
		id: "calendarEvent",
		canWrite: true,
		name: "events",
		title: App.polyglot.t('agenda'),
		addAction: function () {
			this.router.navigate(URLBuilder(['calendar', 'form', 'event']), { trigger: true });
		},

		calendarWeekTemplate: '\n\t\t\t<div>\n\t\t\t\t<div class="days">\n\t\t\t\t\t<% _.each(days, function (day) { %>\n\t\t\t\t\t\t<span class="<%= day.classes %>"><%= day.day %></span>\n\t\t\t\t\t<% }); %>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t',

		actionList: {

			add: {
				fa: false,
				label: App.polyglot.t("add"),
				link: function () {

					if (this.relatedId && this.relatedType) {
						this.router.navigate(URLBuilder(['calendar', 'form', 'event', this.relatedType, this.relatedId]), { trigger: true });
					} else {
						this.router.navigate(URLBuilder(['calendar', 'form', 'event']), { trigger: true });
					}
				}
			},

			filter: {
				fa: false,
				label: App.polyglot.t("filterTo"),
				link: function () {
					this.router.navigate(URLBuilder(['filterPanel', this.name]), { trigger: true });
				}
			}

		},

		/**
   * 
   * @param {type} router
   * @returns {undefined}`
   */
		initialize: function (router, relatedType, relatedId) {

			Page.prototype.initialize.apply(this, arguments);

			this.listingFiltersCollection = new ListingFilters();
			this.listingFiltersCollection.XHRData = {
				search: {
					listing: this.name
				}
			};

			//take saved filters values in local DB
			this.listingFiltersCollection.fetchLocal(this.listingFiltersCollection.XHRData).then(_.bind(function () {

				if (this.listingFiltersCollection.get(this.name + '_savedSearchId')) {
					this.useSavedSearch = true;
				}

				//default values for underscore template
				this.var4template('events', {});
				this.var4template('event', {});
				this.var4template('date', "");
				//		
				this.eventsCollection = new Events();

				var firstDayOfPreviousMonth = Moment().startOf("month").subtract(1, "day").startOf('month').unix();
				var lastDayOfNextMonth = Moment().endOf("month").add(1, "day").endOf('month').unix();

				this.eventsCollection.XHRData = {
					search: {
						"for": "calendar",
						"start": firstDayOfPreviousMonth,
						"end": lastDayOfNextMonth,
						"type": "event"
					}
				};

				if (relatedType && relatedId) {

					this.relatedType = relatedType;
					this.relatedId = relatedId;

					this.eventsCollection.XHRData['search']["relatedtype"] = relatedType;
					this.eventsCollection.XHRData['search']["relatedid"] = relatedId;
				}

				//map saved filter value with XHRData
				if (this.listingFiltersCollection) {

					var _this = this;

					this.listingFiltersCollection.each(function (filter) {

						if (filter.get('value') && filter.get('value') != '--' && filter.get('value') !== 'all') {

							_this.eventsCollection.XHRData.search[filter.get('name')] = filter.get('isMultiple') ? !_.isArray(filter.get('value')) ? [filter.get('value')] : filter.get('value') : filter.get('value');

							if (filter.get('offlineSearchField')) {

								switch (filter.get('value')) {
									case 'mine':
										_this.eventsCollection.XHRData.search[filter.get('offlineSearchField')] = App.currentStaff.get('id');
										break;

									case 'none':
										_this.eventsCollection.XHRData.search[filter.get('offlineSearchField')] = '0';
										break;

									default:
										_this.eventsCollection.XHRData.search[filter.get('offlineSearchField')] = filter.get('isMultiple') ? !_.isArray(filter.get('value')) ? [filter.get('value')] : filter.get('value') : filter.get('value');
										break;

								}
							}
						} else {
							delete _this.eventsCollection.XHRData.search[filter.get('name')]; //prevent default values outer filters
							delete _this.eventsCollection.XHRData.search[filter.get('offlineSearchField')]; //prevent default values outer filters
						}

						//on check si le calendrier stocké en DB locale existe toujours dans les les calendriers du compte
						if (filter.get('name') === "calendars") {

							if (App.preferences.agenda.provider !== "sellsy") {

								var calendarExist = _.filter(App.preferences.agenda.prefs['cache_calendars'], function (calendar) {
									return calendar.id == filter.get('value');
								});

								if (_.isEmpty(calendarExist)) {
									delete _this.eventsCollection.XHRData.search[filter.get('name')];
								}
							} else {
								delete _this.eventsCollection.XHRData.search[filter.get('name')];
							}
						}
					});
				}

				this.collections[this.eventsCollection.name] = this.eventsCollection;

				this.overview = new Overview({ parentView: this });
				this.subviews.push(this.overview);

				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			App.header.$.brandMenu.addClass('hidden');

			this.initCalendar();
			this.initCalendarResizing();

			this.calendarEventList = new CalendarEventList(this.overview.htmlTemplate);
			this.subviews.push(this.calendarEventList);

			this.calendarEventList.render(this.eventsCollection.toJSON(), Moment());
			$('#calendar-mask').prepend('<div class="overlay hidden">' + App.view.fa_loader + '</div>');

			this.$documentHeight = $(document).height();
			this.swipeableBarHeight = $('#calendar-mask .swipeable-area').outerHeight();

			var eventListHeight = this.computeEventListHeight();
			this.calendarEventList.$el.height(eventListHeight);

			//focus on today 
			$('.today', ".calendar-current").trigger('click');
		},

		/**
   * 
   * @returns {undefined}
   */
		initCalendarResizing: function () {

			var _this = this;
			var $calendarMask = this.$calendarMask = $("#calendar-mask");
			var calendarMask = $calendarMask[0];
			var hammerSwipeBar = new HammerView('#calendar-mask .swipeable-area', {
				hammerManager: new Hammer.Manager($("#calendar-mask .swipeable-area")[0]),
				startY: 0
			});

			var doubleTap = new Hammer.Tap({ taps: 2 });
			var panV = new Hammer.Pan({ threshold: 20, direction: 24 });

			hammerSwipeBar.hammerManager.add(panV);
			hammerSwipeBar.hammerManager.add(doubleTap);

			var onPan = function (ev) {

				_this.calendarEventList.$el.height("100%");
				var pos = hammerSwipeBar.startY + ev.deltaY;

				if (pos > -280 && pos < 0) {

					hammerSwipeBar.transform.translateY = pos;
					hammerSwipeBar.requestElementUpdate(calendarMask);
				} else if (pos < -280) {

					hammerSwipeBar.transform.translateY = -280;
					hammerSwipeBar.requestElementUpdate(calendarMask);
				} else if (ev.direction === 16 && pos > 0) {

					hammerSwipeBar.transform.translateY = 0;
					hammerSwipeBar.requestElementUpdate(calendarMask);
				}
			};

			hammerSwipeBar.hammerManager.on("tap", function (e) {

				var pos = hammerSwipeBar.transform.translateY;

				if (pos === 0) {
					hammerSwipeBar.startY = -280;
				} else {
					hammerSwipeBar.startY = 0;
				}

				hammerSwipeBar.resetElement(calendarMask);
				setTimeout(_.bind(function () {
					_this.calendarEventList.$el.height(_this.computeEventListHeight());
				}, this), 100);
			});

			hammerSwipeBar.hammerManager.on('panstart panmove', onPan.bind(this));

			hammerSwipeBar.hammerManager.on("hammer.input", _.bind(function (ev) {

				var pos = hammerSwipeBar.transform.translateY;
				if (ev.isFinal) {

					if (pos > -160) {
						hammerSwipeBar.startY = 0;
						hammerSwipeBar.resetElement(calendarMask);
					} else {
						hammerSwipeBar.startY = -280;
						hammerSwipeBar.resetElement(calendarMask);
					}

					setTimeout(_.bind(function () {
						_this.calendarEventList.$el.height(_this.computeEventListHeight());
					}, this), 100);
				}
			}, this));

			hammerSwipeBar.resetElement();
		},

		/**
   * 
   */
		initCalendar: function () {

			//setup and init calendar
			Moment.locale(App.currentStaff.get('uilang'));

			this.$calendarCurrent = $('#calendar-current');
			this.$calendarPrevious = $('#calendar-previous');
			this.$calendarNext = $('#calendar-next');

			this.calendarCurrent = this.createCalendar(this.$calendarCurrent, Moment());
			this.calendarPrevious = this.createCalendar(this.$calendarPrevious, Moment().subtract(1, "months"));
			this.calendarNext = this.createCalendar(this.$calendarNext, Moment().add(1, "months"));

			var calendarPreviousPos = 10;
			var calendarNextPos = -475;
			var calendarCurrentPos = -235;
			var _this = this;
			this.$currentMonth = $('#current-month');

			$(".clndr-control-button").remove();
			$('.month').remove();

			$("#calendar-wrapper").on('touchmove', function (e) {
				e.preventDefault();
			});

			App.header.$.title.text(this.calendarCurrent.month.format("MMMM - YYYY"));
			this.hammerCalendar = new HammerView('#calendar-wrapper', {
				hammerManager: new Hammer.Manager(document.getElementById("calendar-wrapper")),
				startY: calendarCurrentPos
			});

			var panV = new Hammer.Pan({ threshold: 20, direction: 24 });
			this.hammerCalendar.hammerManager.add(panV);

			var onPan = function (ev) {
				this.hammerCalendar.transform.translateY = this.hammerCalendar.startY + ev.deltaY;
				this.hammerCalendar.requestElementUpdate();
			};

			var breakPointVal = 240 * 0.4; //40% of calendar height

			this.hammerCalendar.hammerManager.on('panstart panmove', onPan.bind(this));
			this.hammerCalendar.hammerManager.on("panmove panend", _.bind(function (ev) {
				var _this2 = this;

				var pos = this.hammerCalendar.transform.translateY;

				//show next month calendar
				if (ev.deltaY < -breakPointVal) {

					$("#calendar-next td.day:not(.adjacent-month)").css('color', "#2E4554");
					App.header.$.title.text(_this.calendarNext.month.format("MMMM - YYYY"));

					if (ev.isFinal) {

						this.hammerCalendar.ticking = false;

						$('#calendar-wrapper').css('transition', "all 0.3s linear 0s");

						//finish transition until next calendar
						this.hammerCalendar.transform.translateY = calendarNextPos;
						this.hammerCalendar.requestElementUpdate(undefined, undefined, function () {

							$('#calendar-wrapper').css('transition', "none");
							var newCurrentMoment = _this.calendarNext.month.clone();
							_this.changeCurrentCalendar(newCurrentMoment);

							_this2.hammerCalendar.transform.translateY = _this2.hammerCalendar.startY;
							_this2.hammerCalendar.requestElementUpdate();
						});
					}

					//show previous month calendar
				} else if (ev.deltaY >= breakPointVal) {

					$("#calendar-previous td.day:not(.adjacent-month)").css('color', "#2E4554");
					App.header.$.title.text(_this.calendarPrevious.month.format("MMMM - YYYY"));

					if (ev.isFinal) {

						this.hammerCalendar.ticking = false;

						$('#calendar-wrapper').css('transition', "all 0.3s linear 0s");

						//finish translation until previous calendar
						this.hammerCalendar.transform.translateY = calendarPreviousPos;
						this.hammerCalendar.requestElementUpdate(undefined, undefined, function () {

							$('#calendar-wrapper').css('transition', "none");
							var newCurrentMoment = _this.calendarPrevious.month.clone();
							_this.changeCurrentCalendar(newCurrentMoment);

							_this2.hammerCalendar.transform.translateY = _this2.hammerCalendar.startY;
							_this2.hammerCalendar.requestElementUpdate();
						});
					}

					//stay on current month	
				} else {

					App.header.$.title.text(_this.calendarCurrent.month.format("MMMM - YYYY"));
					$(".calendar:not(#calendar-current) td.day:not(.adjacent-month)").removeAttr("style");

					if (ev.isFinal) {
						this.$currentMonth.text(_this.calendarCurrent.month.format("MMMM - YYYY"));
						this.hammerCalendar.ticking = false;
						this.hammerCalendar.resetElement();
					}
				}
			}, this));

			this.hammerCalendar.resetElement();
		},

		/**
   * 
   * @returns {undefined}
   */
		createCalendar: function ($el, startMoment, template) {

			var _this = this;

			return $el.clndr({
				template: template ? template : undefined,
				moment: Moment,
				startWithMonth: startMoment,
				forceSixRows: true,
				events: this.eventsCollection.toJSON(),
				multiDayEvents: {
					endDate: 'end',
					startDate: 'start',
					// If you also have single day events with a different date field,
					// use the singleDay property and point it to the date field.
					singleDay: 'start'
				},
				clickEvents: {

					click: function (target) {

						if ($(target.element).hasClass('next-month')) {

							_this.changeCurrentCalendar(_this.calendarNext.month.clone());
							$('.focused-day').removeClass('focused-day');
						} else if ($(target.element).hasClass('last-month')) {
							_this.changeCurrentCalendar(_this.calendarPrevious.month.clone());
							$('.focused-day').removeClass('focused-day');
						} else {
							$('.focused-day').removeClass('focused-day');
							$(target.element).addClass('focused-day');
						}

						_this.showEventsForTarget(target.events, target.date);
					}

				},
				ready: function () {
					//					//when current calendar is ready, focus on today or first day of current month 
					//					if(this.element.prop('id') === "calendar-current") {
					//						
					//						if($(".today",this.element).length) {
					//							$('.today').trigger('click');
					//						} else {
					//							$('.calendar-day-'  + this.month.startOf('month').format("YYYY-MM-DD"), this.element).trigger('click');
					//						}
					//						
					//					}
				},
				doneRendering: function () {
					$(".clndr-control-button").remove();
					$('.month').remove();

					if (!$('thead', '#calendar-mask').length) {
						//cut thead with days of weeks label and paste them in page header
						$('#calendar-wrapper').before($("thead:first"));
						$('thead:first').prop('id', "days-of-week");
					}

					$('table thead').remove();
				}

			});
		},

		/**
   * 
   * @param {type} newCurrentMoment
   * @returns {undefined}
   */
		changeCurrentCalendar: function (newCurrentMoment) {

			var _this = this;
			$('#calendar-mask .overlay:first').removeClass("hidden");
			this.calendarCurrent.destroy();
			this.calendarPrevious.destroy();
			this.calendarNext.destroy();

			var momentBefore = newCurrentMoment.clone().startOf('month').subtract(1, "days");
			var momentNext = newCurrentMoment.clone().endOf('month').add(1, "days");

			//load events for new calendar
			this.eventsCollection.XHRData.search.start = momentBefore.startOf('month').unix();
			this.eventsCollection.XHRData.search.end = momentNext.endOf('month').unix();

			//create calendars
			this.calendarPrevious = this.createCalendar(this.$calendarPrevious, momentBefore);
			this.calendarCurrent = this.createCalendar(this.$calendarCurrent, newCurrentMoment);
			this.calendarNext = this.createCalendar(this.$calendarNext, momentNext);

			App.header.$.title.text(_this.calendarCurrent.month.format("MMMM - YYYY"));

			this.eventsCollection.fetch({ method: "POST", data: this.eventsCollection.XHRData }).then(_.bind(function () {
				_this.calendarCurrent.setEvents(_this.eventsCollection.toJSON());
				_this.calendarEventList.render(_this.eventsCollection.toJSON(), newCurrentMoment);
				_this.calendarPrevious.setEvents(_this.eventsCollection.toJSON());
				_this.calendarNext.setEvents(_this.eventsCollection.toJSON());
				$('#calendar-mask .overlay:first').addClass("hidden");
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		showEventsForTarget: function (events, date) {
			var $event = $('#' + date.format("YYYY-MM-DD"));

			if ($event.length) {

				var eventPos = $event.position().top - 39;

				if (eventPos != 0) {
					var scrollTopListing = $("#event-list").scrollTop();
					$('#event-list').scrollTop(scrollTopListing + eventPos);
				}
			}
		},

		/**
   * 
   * @returns {Number}
   */
		computeEventListHeight: function () {
			return this.$documentHeight - this.$calendarMask.offset().top - this.swipeableBarHeight;
		}

	});
});
