/**
 * @desc Product router, extend Core Router
 */
define('routers/product',['backbone', 'underscore', 'routers/core/router', 'app', 'tools/buildURL'], function (Backbone, _, CoreRouter, App, URLBuilder) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "catalogue",

		checkOnline: {
			form: true
		},

		/**
   * 
   */
		routes: {
			'catalog/home': 'catalogHome',
			'product/list/:type': 'listing',
			'product/overview/:type/:id/:declid': 'overview',
			'product/form/:type(/)(:id)(/)(:declid)(/)(:comefrom)': 'form'
		},

		/**
   * 
   */
		checkBefore: {

			listing: function () {
				return App.permission.level("itemlevel");
			},
			overview: function () {
				return App.permission.level("itemlevel");
			},
			form: function () {
				return App.permission.can('createitem') || App.permission.can('edititem');
			}

		},

		/**
   * 
   * @param {type} callback
   * @param {type} args
   * @returns {undefined}
   */
		execute: function (callback, args) {
			CoreRouter.prototype.execute.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} type
   * @returns {undefined}
   */
		listing: function (type) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/product/list'], function (require, ProductList) {

					if (type !== null && (type === 'item' || type === 'service')) {

						var links = _this.getAvailableActions();
						var view = new ProductList(_this, type, links);
						view.trueRoute = trueRoute;

						resolve(view);
					}
				});
			});
		},

		/**
   * 
   * @param {type} id
   * @param {type} comefrom
   * @returns {undefined}
   */
		form: function (type, id, declid, comefrom) {

			this.sidelink = type === 'item' ? "produits" : "services";
			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/product/form'], function (require) {

					var ProductForm = require('views/product/form');
					var view = id !== null ? new ProductForm(_this, type, id, declid, comefrom) : new ProductForm(_this, type);
					view.trueRoute = trueRoute;

					resolve(trueRoute);
				});
			});
		},

		/**
   * 
   * @param {type} type
   * @param {type} id
   * @returns {undefined}
   */
		overview: function (type, id, declid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = type === 'item' ? "produits" : "services";

			return new Promise(function (resolve, reject) {

				require(['require', 'views/product/overview'], function (require) {

					var ProductOverview = require('views/product/overview');
					var view = new ProductOverview(_this, type, id, declid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {Promise}
   */
		catalogHome: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule'], function (require, HomeModule) {

					var links = _this.getAvailableActions();
					var view = new HomeModule(_this, links, 'catalog');
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		getAvailableActions: function () {

			var links = [{
				fa: 'fa-folder-open',
				name: 'products',
				link: URLBuilder(['product', 'list', 'item']),
				canAccess: App.permission.level('itemlevel')
			}, {
				fa: 'fa-folder-open',
				name: 'services',
				link: URLBuilder(['product', 'list', 'service']),
				canAccess: App.permission.level('itemlevel')
			}];

			return links;
		}

	});
});
