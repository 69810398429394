define('app',['underscore', 'tools/ajax', 'tools/buildURL', 'tools/numberFormat', 'polyglot', 'tools/core/utils'], function (_, Ajax, URLBuilder, NumberFormat, Polyglot, Utils) {

	'use strict';

	return {

		sellsyEasy: {},
		isOnline: true,
		isReady: false, // permet de savoir si les données principale de l'appli ont été chargé (staff,corp, trads notamment)
		view: {},
		globalPromises: {}, //aray containing the promise state of global collection like unities. 
		hasNetworkInterval: false,
		numberFormat: {},
		NumberFormat: NumberFormat,
		dateFormat: {},
		returnActionsProvided: ['form', 'formAddress', 'stockRecap', 'fastForm', "opportunityForm", "formAddress"], //previous action where return is provided
		useLocalDB: true,
		indexedDB: window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB,
		slideDirection: 'left',
		corpId: '',
		isLogged: true,
		started: false,
		saasUrl: '',
		fileUrl: '',
		availableThirds: ['third', 'supplier', 'prospect', 'client'],
		availableItems: ['item', 'service'],
		availableDoctypes: ["estimate", "invoice", "model", "delivery", "order", "creditnote", "proforma"],
		availablePurDoctypes: ["purInvoice", "purDelivery", "purOrder", "purCreditNote"],
		availablePurDoctypes_raw: ["invoice", "delivery", "order", "creditnote"],
		availableOppStatus: ['won', 'lost', 'cancelled', 'closed', 'late', 'open'],
		availableTicketStep: ["active", "closed", "pending", "spam"],
		availableExpenseStep: ['draft', 'accepted', 'refused'],
		availableSearchDate: [{ label: 'last12month', searchMethod: 'month' }, { label: 'previousyear', searchMethod: 'month' }, { label: 'last6month', searchMethod: 'month' }, { label: 'previousmonth', searchMethod: 'day' }, { label: 'previousweek', searchMethod: 'day' }, { label: 'yesterday', searchMethod: 'day' }, { label: 'currentyear', searchMethod: 'month' }, { label: 'currentmonth', searchMethod: 'day' }, { label: 'today', searchMethod: 'day' }, { label: 'custom' }],
		HTMLDateFormat: 'YYYY-MM-DD',
		defaultInterval: 60000,
		offLineInterval: 15000,
		localTablesWithStringKey: ['stats', 'smarttags', 'eventlog', 'listingFilter', 'folder', 'emailThread', 'message', "emailDraft", 'agenda'],

		invoiceSteps: ['draft', 'due', 'payinprogress', 'paid', 'late', 'cancelled'],
		estimateSteps: ['draft', 'sent', 'read', 'accepted', 'refused', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled'],
		orderSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled'],
		deliverySteps: ['draft', "partialinvoiced", "invoiced", "read", "sent"],
		creditnoteSteps: ['draft', 'stored', 'partialspend', 'spent', 'cancelled'],
		proformaSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled'],

		purInvoiceSteps: ['draft', 'due', 'payinprogress', 'paid', 'late', 'cancelled'],
		purOrderSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'partialinvoiced', 'invoiced', 'cancelled'],
		purDeliverySteps: ['draft', 'sent', 'read', "partialinvoiced", "invoiced"],
		purCreditNoteSteps: ['draft', 'stored', 'partialspend', 'spent', 'cancelled'],

		preferences: {},
		TTLView: '300000', //life time value of a view in milliseconde
		maxFileSize: '8000000', //30Mo
		mimeTypeImage: ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', "image/jpg", "image/bmp"],
		extensionImage: ['gif', 'jpeg', 'jpg', 'png', 'svg', 'bmp'],

		sellsyLoader: '\n\t\t<div id="loader"> \n\t\t\t<div id="loadingContent">\n\t\t\t\t<div class="msgContent">\n\t\t\t\t\t<div id="cache-container">\n\t\t\t\t\t\t<div id="cache-translation"></div>\n\t\t\t\t\t\t<div id="cache-enum"></div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class="loader loadingPage">\n\t\t\t\t\t\t<div class="rect1"></div>\n\t\t\t\t\t\t<div class="rect2"></div>\n\t\t\t\t\t\t<div class="rect3"></div>\n\t\t\t\t\t\t<div class="rect4"></div>\n\t\t\t\t\t\t<div class="rect5"></div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t',

		/**
   * 
   * @returns {undefined}
   */
		goToLogPage: function () {

			this.isLogged = false;
			localStorage.setItem('isLogged', false);

			this.sellsyEasy.authRouter.navigate("#auth", { trigger: this.currentRoute === 'publicHome' ? false : true });
		},

		/**
   * 
   * @returns {undefined}
   */
		initTranslations: function (locale) {

			return new Promise(function (resolve, reject) {

				Ajax({
					method: 'GET',
					url: '/rest/translations/' + locale,
					dataType: "json",
					success: function (translations) {
						var polyglot = new Polyglot({ phrases: translations });
						polyglot.locale(locale);
						resolve(polyglot);
					}
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		toggleOfflineMode: function (val) {

			return new Promise(function (resolve, reject) {

				Ajax({

					method: 'POST',
					url: '/rest/staff/togglofflinemode',
					dataType: "json",
					data: {
						'value': val
					},
					success: function () {
						Utils.reload();
					}

				});
			});
		},

		/**
   * 
   * @param {type} relatedtype
   * @param {type} relatedid
   * @returns {unresolved}
   */
		GoToOverviewByRelated: function (relatedtype, relatedid) {

			switch (relatedtype) {

				case 'item':
				case 'service':

					var id = relatedid.split("-");

					//if its declined
					if (id.length == 2) {
						relatedid = _.first(id);
						var declid = _.last(id);
					} else {
						declid = 0;
					}

					return URLBuilder(['product', 'overview', relatedtype, relatedid, declid]);

					break;

				case 'client':
					return URLBuilder(['third', 'overview', relatedid]);
					break;
				case 'third':
				case 'prospect':
				case 'supplier':
				case 'people':
					return URLBuilder([relatedtype, 'overview', relatedid]);
					break;
				case 'invoice':
				case 'estimate':
				case 'order':
				case 'delivery':
				case 'creditnote':
					return URLBuilder(['document', 'overview', relatedtype, relatedid]);
					break;

				case 'purInvoice':
					return URLBuilder(['purchase', 'overview', 'invoice', relatedid]);
					break;
				case 'purOrder':
					return URLBuilder(['purchase', 'overview', 'order', relatedid]);
					break;
				case 'purDelivery':
					return URLBuilder(['purchase', 'overview', 'delivery', relatedid]);
					break;
				case 'purCreditNote':
					return URLBuilder(['purchase', 'overview', 'creditNote', relatedid]);
					break;

				case 'event':
				case 'task':
					return URLBuilder(['calendar', 'form', relatedtype, relatedid]);
					break;
				case "opportunity":
					return URLBuilder([relatedtype, 'overview', relatedid]);
					break;
				case "timetracking":
					return URLBuilder([relatedtype, 'form', relatedid]);
					break;
				case "expense":
					return URLBuilder([relatedtype, "overview", relatedid]);
					break;
				case "ticket":
				case "support":
					return URLBuilder(['ticket', "overview", relatedid]);
					break;

				default:
					return location.hash;
			}
		},

		loadCordovaIfNative: function () {

			var cordovaFile = [];

			if (navigator) {

				// set devices informations if we are on mobile app
				var isNative = false;
				var cordovaVersion = false;
				var os = false;

				// retrieve devices
				if (navigator.appVersion.match(/Ipad/i) || navigator.appVersion.match(/iPhone/i)) {
					os = 'ios';
				} else if (navigator.appVersion.match(/Android/i)) {
					os = 'android';
				}

				// User agent added in native code
				if (navigator.appVersion.match(/SellsyMobile/i)) {

					isNative = true;

					var version = navigator.appVersion.match("SellsyMobile v_([^)]+)_");
					cordovaVersion = version[1];

					cordovaFile.push(window.location.origin + '/ressources/js/libs/cordova/' + cordovaVersion + '/' + os + '/cordova.js');
				}

				navigator.isNative = isNative;
				navigator.os = os;
				navigator.cordovaVersion = cordovaVersion;
			}

			return cordovaFile;
		},

		/**
   * 
   * @returns {undefined}
   */
		deviceType: function () {

			if ($(window).width() >= 767) {
				return 'medium';
			} else {
				return 'small';
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		onConnectionLost: function () {

			var _this = this;

			//On attend d'avoir chargé les données necessaire à l'appli (trads notamment)  avant de lancer la passer en offline
			if (this.isReady) {

				if (this.isOnline && this.currentStaff.get('isOfflineActivated') == 'Y') {

					var Toast = require('views/core/toastNotification');

					new Toast({
						id: 'toast-error',
						classes: "toast-offline",
						content: _this.polyglot.t('appLostConnection'),
						callback: function () {}
					});

					if (!_.isEmpty(this.view) && _.isFunction(this.view.disableWriteActions)) {
						//disabled some inputs who require network
						this.view.disableWriteActions();
					}

					if (this.hasNetworkInterval) {
						clearInterval(this.hasNetworkInterval);
						this.hasNetworkInterval = setInterval(this.hasNetworkFunction.bind(this), this.offLineInterval);
					}
				} else if (this.isOnline && this.currentStaff.get('isOfflineActivated') == 'N') {

					$('.page').html('<div class="row container-fluid overview"><div id="errorpage" class="col-xs-12"><h2 class="text-center"><i class="fa fa-wifi alert-icon"></i>' + this.polyglot.t('connectionLost') + '</h2><p class="text-center">' + this.polyglot.t('connectionLost_txt') + '</p></div></div>');
					$('header nav h1').html('');
					$('header nav ul').empty();
				}

				this.isOnline = false;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		onConnectionRetrieve: function () {

			var Notification = require('views/core/toastNotification');
			var DB = require('db/easy');

			var _this = this;

			$('.toast-offline').remove();

			new Notification({
				id: 'toast-info',
				timeDuration: 4000,
				content: _this.polyglot.t('appNowConnected'),
				callback: function () {

					_this.view.enableWriteActions();

					if (_this.indexedDB) {
						DB['system'].put({ id: 1, isOnline: true });
					}

					clearInterval(_this.hasNetworkInterval);
					_this.hasNetworkInterval = setInterval(_this.hasNetworkFunction(), _this.defaultInterval);
				}

			});
		},

		/**
   * 
   * @returns {undefined}
   */
		onNewVersion: function () {

			var _this = this;

			if (this.isReady) {

				var Toast = require('views/core/toastNotification');

				if (!$('.update-available').length) {

					var toast = new Toast({

						id: 'toast-success',
						classes: "update-available",
						content: _this.polyglot.t('newVersionAvailable'),
						linkMessage: _this.polyglot.t('maj'),
						linkEvent: function () {

							if (_this.isOnline) {
								Utils.reload();
							} else {
								toast.removeToast();
							}
						}

					});
				}
			}
		},

		/**
   * 
   * @param {type} pluginName
   * @returns {undefined}
   */
		getPreferencesByPlugin: function (pluginName) {
			var _this = this;

			return new Promise(function (resolve, reject) {

				//si on a déjà les prefs on les retourne directement on ne refait pas de requête
				if (_this.preferences[pluginName]) {
					resolve(_this.preferences[pluginName]);
				}

				switch (pluginName) {

					case 'support':

						require(['require', 'models/singles/ticket'], function (require, Support) {

							var support = new Support();
							support.getPreferences().then(function (prefs) {
								_this.preferences[pluginName] = prefs;
								resolve(prefs);
							});
						});

						break;

					case 'timetracking':

						require(['require', 'models/singles/timetracking'], function (require, Timetracking) {

							var timetracking = new Timetracking();
							timetracking.getPreferences().then(function (prefs) {
								_this.preferences[pluginName] = prefs;
								resolve(prefs);
							});
						});

						break;

					case "agenda":

						require(['models/singles/event'], function (Event) {

							Event.getPreferences().then(function (prefs) {
								_this.preferences[pluginName] = prefs;
								resolve(prefs);
							});
						});

						break;

					default:
						resolve();
						break;

				}
			});
		},

		/**
   * 
   * @param {type} duration
   * @returns {undefined}
   */
		vibrate: function (duration) {

			if (navigator.isNative === true) {
				navigator.vibrate(duration ? duration : 400);
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		reloadSession: function () {

			var secretToken = localStorage.getItem('oauth_token_secret');
			var token = localStorage.getItem('oauth_token');
			var _this = this;

			return new Promise(function (reloaded, revoked) {

				if (secretToken && token) {

					Ajax({

						url: '/rest/auth/reloadSession',
						method: 'POST',
						dataType: "json",
						data: {
							secret: secretToken,
							token: token
						},
						success: function (data) {

							if (data.status === "error") {

								localStorage.setItem('oauth_token', '');
								localStorage.setItem('oauth_token_secret', '');
								revoked(data);
							} else {
								reloaded();
							}
						},
						error: function (err) {
							localStorage.setItem('oauth_token', '');
							localStorage.setItem('oauth_token_secret', '');
							revoked(err);
						}

					});
				} else {

					if (localStorage.getItem('step') === 'accessApi') {
						reloaded();
					} else {

						var xhr = _this.logout();

						xhr.done(function () {
							revoked();
						});
					}
				}
			});
		},

		/**
  *
   */
		/**
   * 
   * @returns {appL#8.Ajax}
   */
		logout: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				require(['models/core/collection'], function (Collection) {

					_.each(Collection.prototype.storedCollections, function (collectionPrototype) {
						collectionPrototype.isStored = false;
					});

					return _this.clearTokens().then(function () {
						resolve();
						new Ajax({
							url: "rest/auth/logout",
							method: 'post'
						});
					});
				});
			});
		},

		/**
  *
   */
		pluginIsLoaded: function (syscode) {
			return this.plugins[syscode] ? true : false;
		},

		/**
  *
   */
		getPrefs: function (plugin) {

			return new Promise(function (resolve, reject) {

				var url = '';

				switch (plugin) {

					case 'stock':

						url = '/rest/stock/prefs';

						break;

					default:
						console.log(reject);
						reject('plugin don\'t exist');

						break;
				};

				Ajax({

					url: url,
					method: 'GET',
					success: function (data) {

						if (data.error) {
							reject(data.error);
						} else {
							resolve(data);
						}
					},

					error: function (error) {
						reject(error);
					}

				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		clearTokens: function () {

			return new Promise(function (resolve, reject) {

				require(["db/easy"], function (DB) {
					DB['tokens'].clear().then(function () {
						resolve();
					}).catch(function (e) {
						console.log(e);
					});
				});
			});
		}

	};
});
