/**
 * @desc corp Pref, extend Page View
 */
define('views/setting/corpPref',['underscore', 'views/core/page', 'views/core/form', 'models/singles/corp', 'tools/buildURL', 'app'], function (_, Page, Form, Corp, URLBuilder, App) {

	'use strict';

	return Page.extend({

		toastMessages: {
			corp: {
				saveMsg: App.polyglot.t("corpInfosSaved")
			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/corp';
			this.modelToSave = new Corp();
			this.models[this.modelToSave.name] = this.modelToSave;
			this.title = App.polyglot.t("corpInfos");
			this.form = new Form({ parentView: this });

			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			Page.prototype.afterRender.apply(this, arguments);
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			this.modelToSave.set(dataForm.corp);

			if (this.modelToSave.isValid()) {

				this.modelToSave.save().then(function () {

					//refresh panel staffCorpInfo when saved
					App.sidebar.staffView.trigger('staffCorpUpdated');
					_this.form.trigger('successful', _this.toastMessages.corp.saveMsg);
					_this.router.navigate(URLBuilder(["accountPref"]), { trigger: true });
				}, function (jqXHR) {
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				//re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
