/**
 *  @desc Corporations Collection, extend core Collection
 */
define('models/collections/corps',['models/core/collection', 'models/singles/corp', 'db/easy', 'app'], function (Collection, Corp, DB, App) {

	'use strict';

	return Collection.extend({

		model: Corp,
		storeName: 'corp',
		name: 'corps',

		countLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				if (App.indexedDB) {

					DB[_this.storeName].count().then(function (nbTotal) {
						resolve(nbTotal);
					});
				} else {
					resolve(1);
				}
			});
		}

	});
});
