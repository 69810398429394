/**
 * @desc Support Form, extend Page View
 * Child View : Core Form
 */
define('views/support/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/ticket', 'models/collections/supportSources', 'models/collections/thirds', 'app', 'tinyMCE', 'tools/buildURL', 'models/collections/prospects', 'models/collections/suppliers', 'models/singles/third', 'models/singles/filePicture', 'views/core/toastNotification'], function (Backbone, _, Page, Form, Ticket, SupportSources, Thirds, App, TinyMCE, URLBuilder, Prospects, Suppliers, Third, FilePicture, ToastNotif) {

	'use strict';

	return Page.extend({

		events: {},

		title: App.polyglot.t('createticket'),
		form: {},
		template: "templates/support/form.twig",
		toastMessages: {
			created: App.polyglot.t('ticketadded')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router, thirdid) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;

			this.modelToSave = new Ticket();
			this.models[this.modelToSave.name] = this.modelToSave;

			this.options.extraData = {
				supportsteps: function () {
					return new Promise(function (resolve, reject) {
						resolve(App.availableTicketStep);
					});
				}
			};

			this.supportSources = new SupportSources();
			this.collections[this.supportSources.name] = this.supportSources;

			if (App.permission.level('clientlevel')) {
				this.thirds = new Thirds();
			}

			if (App.permission.level('prospectlevel')) {
				this.prospects = new Prospects();
			}

			if (App.permission.level('supplierlevel')) {
				this.suppliers = new Suppliers();
			}

			var thirdPromise = Promise.resolve();

			if (thirdid) {

				this.third = new Third({ id: thirdid });
				var _this = this;

				var thirdPromise = new Promise(function (resolve, reject) {

					_this.third.fetchLocal().then(function () {
						resolve();
					}, function () {

						_this.third.fetch().then(function () {
							resolve();
						});
					});
				});
			}

			thirdPromise.then(_.bind(function () {

				this.form = new Form({ parentView: this });
				this.subviews.push(this.form);

				this.listenTo(this.form, 'dataSend', this.formAction);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			this.thirds = this.thirds || this.prospects || this.suppliers;

			if (this.third) {

				this.thirds.add(this.third);
				var defaultOptions = [this.third.id];
			} else {
				var defaultOptions = [];
			}

			if (this.thirds || this.prospects || this.suppliers) {

				this.selectizeObjects = [{
					domElt: _this.$el.find('input[name="ticket[thirdcontactid]"]'),
					create: false,
					valueField: 'contactid',
					labelField: 'fullName',
					searchField: ['fullName'],
					maxItems: '1',
					maxOptions: 10,
					loadThrottle: 600,
					onItemAdd: function (value, item, opt) {
						var selectedOpt = opt.options[value];
						_this.$el.find('input[name="ticket[requesterEmail]"]').val(selectedOpt.email);
					},
					onItemRemove: function () {
						_this.$el.find('input[name="ticket[requesterEmail]"]').val('');
					}
				}, {
					domElt: _this.$el.find('input[name="ticket[thirdid]"]'),
					create: false,
					options: _this.thirds,
					valueField: 'id',
					labelField: 'name',
					searchField: ['name', 'forename'],
					maxItems: '1',
					maxOptions: 10,
					loadThrottle: 600,
					load: function (query, callback) {

						return new Promise(function (resolve, reject) {

							var promises = [];

							if (_this.thirds) {

								promises.push(_this.thirds.fetch({
									method: 'POST',
									data: { search: { contains: query } }
								}, true));
							}

							if (_this.prospects) {

								promises.push(_this.prospects.fetch({
									method: 'POST',
									data: { search: { contains: query } }
								}, true));
							}

							if (_this.suppliers) {

								promises.push(_this.suppliers.fetch({
									method: 'POST',
									data: { search: { contains: query } }
								}, true));
							}

							Promise.all(promises).then(function () {

								if (_this.prospects) {
									_this.thirds.add(_this.prospects.toJSON());
								}

								if (_this.suppliers) {
									_this.thirds.add(_this.suppliers.toJSON());
								}

								resolve(_this.thirds);
							});
						});
					},
					defaultValues: defaultOptions,
					onItemAdd: function (value, item, opt) {

						var selectedOpt = opt.options[value];

						if (selectedOpt.contacts) {

							// get all of his contacts
							var $select = $('[name="ticket[thirdcontactid]"]', _this.$el)[0];
							var control = $select.selectize;
							control.clear();

							$.each(selectedOpt.contacts, function (index, value) {
								control.addOption(value);
							});
							control.refreshOptions();
						}
					},
					onItemRemove: function () {
						var $select = _this.$el.find('input[name="ticket[thirdcontactid]"]').selectize();
						var control = $select[0].selectize;
						control.clear();
						control.clearOptions();
						_this.$el.find('input[name="ticket[requesterEmail]"]').val('');
					},
					render: 'third'
				}];
			}

			Page.prototype.afterRender.apply(_this, arguments);

			TinyMCE.init({
				selector: 'textarea[name="ticket[message]"]',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize",
				menubar: false,
				statusbar: false
			});
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			dataForm.ticket.file = $('[name="response[file]"]')[0].files[0]; //sendDataFormV2 didn't catch file input

			this.modelToSave.set(dataForm.ticket);

			if (dataForm.ticket.file) {
				var file = new FilePicture();
				file.set({
					file: dataForm.ticket.file
				});
			}

			if (!file || file.isValid()) {

				if (this.modelToSave.isValid()) {

					var toastMsg = this.toastMessages.created;

					this.modelToSave.save().then(function () {
						_this.form.trigger('successful', toastMsg);

						_this.form.redirect(true, URLBuilder(["ticket", "overview", _this.modelToSave.get('id')]));
					}, function () {
						// re activate submit button
						$('.submit-form', _this.form.$el).removeAttr('disabled');
					});
				} else {

					// re activate submit button
					$('.submit-form', this.form.$el).removeAttr('disabled');

					//Make invalid inputs in error
					_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

						if (!_.isEmpty(error)) {

							_.each(error, function (msgError, keyMsg) {
								_this.form.showValidationError(keyError, keyMsg, msgError, true);
							});
						}
					});
				}
			} else {

				new ToastNotif({
					'id': 'toast-error',
					'content': App.polyglot.t(file.validationError),
					classes: 'col-xs-12',
					timeDuration: 3000
				});

				$('.submit-form', this.form.$el).removeAttr('disabled');
			}

			return false;
		}

	});
});
