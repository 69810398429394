/**
 *  @desc BankAccounts Collection, extend core Collection
 */
define('models/collections/bankAccounts',['models/core/collection', 'models/singles/bankAccount'], function (Collection, BankAccount) {

	'use strict';

	return Collection.extend({

		model: BankAccount,
		storeName: 'bankAccount',
		name: 'bankAccounts',
		url: '/rest/bankAccount/list',
		fields: []

	});
});
