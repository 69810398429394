/**
 * @desc Third router, extend Core Router
 */
define('routers/third',['backbone', 'underscore', 'routers/core/router', 'tools/buildURL'], function (Backbone, _, CoreRouter, URLBuilder) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "contacts",

		checkOnline: {
			form: true,
			formPeople: true,
			formAddress: true
		},

		routes: _.extend(CoreRouter.prototype.routes, {

			'third/home': 'thirdHome',
			'third/list(/)(:comefrom)': 'listing',
			'third/overview/:id': 'overview',
			'third/form(/)(:thirdid)(/)(:comefrom)': 'form',
			'third/linkedDocs/:doctype/:thirdid/:relationtype': 'listingLinkedDocs',

			'address/form/:thirdid(/)(:relationtype)(/)(:addressid)(/:comeFrom)': 'formAddress'

		}),

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} comefrom
   * @returns {undefined}
   */
		listing: function (comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/third/list', 'models/collections/thirds'], function (require, ThirdList, Thirds) {

					var links = _this.getAvailableActions();
					var thirds = new Thirds();
					var view = new ThirdList(_this, thirds, links);

					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} thirdId
   * @param {type} comefrom
   * @returns {undefined}
   */
		form: function (thirdId, comefrom) {

			var _this = this;
			thirdId = thirdId ? thirdId : 0;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/third/form', 'models/singles/third'], function (require, ThirdForm, Third) {

					var third = new Third({ id: thirdId });
					var view = new ThirdForm(_this, thirdId, third, comefrom);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} thirdId
   * @returns {undefined}
   */
		overview: function (thirdId) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/third/overview', 'models/singles/third'], function (require, ThirdOverview, Third) {

					var third = new Third({ id: thirdId });
					var view = new ThirdOverview(_this, thirdId, third);

					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} thirdid
   * @param {type} addressId
   * @returns {undefined}
   */
		formAddress: function (thirdid, relationtype, addressId, comeFrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/address/formAddress'], function (require, FormAddress) {

					var view = new FormAddress(_this, thirdid, addressId, relationtype, comeFrom);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		listingLinkedDocs: function (doctype, thirdid, relationtype) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['views/third/linkedDocs'], function (LinkedDocs) {

					var view = new LinkedDocs(_this, doctype, thirdid, relationtype);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		thirdHome: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule'], function (require, HomeModule) {

					var links = _this.getAvailableActions();
					var view = new HomeModule(_this, links, 'contacts');
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		getAvailableActions: function () {

			var links = [{
				fa: 'fa-user',
				name: 'clients',
				link: URLBuilder(['third', 'list']),
				canAccess: App.permission.level("clientlevel")
			}, {
				fa: 'fa-user',
				name: 'prospects',
				link: URLBuilder(['prospect', 'list']),
				canAccess: App.permission.level("prospectlevel")
			}, {
				fa: 'fa-user',
				name: 'suppliers',
				link: URLBuilder(['supplier', 'list']),
				canAccess: App.permission.level("supplierlevel")
			}, {
				fa: 'fa-user',
				name: 'contacts',
				link: URLBuilder(['people', 'list']),
				canAccess: App.permission.level("peoplelevel") || App.permission.level("supplierlevel") || App.permission.level("prospectlevel") || App.permission.level("clientlevel")
			}];

			return links;
		}

	});
});
