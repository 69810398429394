/**
 * @desc Setting router, extend Core Router
 */
define('routers/setting',['underscore', 'routers/core/router'], function (_, CoreRouter) {

	'use strict';

	return CoreRouter.extend({

		checkOnline: {
			'prefOverview': true,
			'staffPref': true,
			'corpPref': true,
			'tvaPref': true,
			'pwdPref': true,
			'appPref': true,
			'addrPref': true,
			'mediumPref': true,
			'packagingPref': true,
			'shippingPref': true,
			contactsSynchronisation: true
		},

		routes: {
			// preferences
			'accountPref/staff': 'staffPref',
			'accountPref/corp': 'corpPref',
			'accountPref/tva': 'tvaPref',
			'accountPref/pwd': 'pwdPref',
			'accountPref/app': 'appPref',
			'accountPref/addr': 'addrPref',
			'accountPref/medium': 'mediumPref',
			'accountPref/packaging': 'packagingPref',
			'accountPref/shipping': 'shippingPref',
			'accountPref/bankaccount': 'bankAccountPref',
			'accountPref/rateCategories': 'rateCategoriesPref',
			'accountPref/unities': 'unityPref',
			'accountPref': 'prefOverview',
			'accountPref/reload': 'reloadToOverview',
			// 'accountPref/nylas'				: 'nylasPrefs',
			// tools
			'accountTool/synchro': 'contactsSynchronisation'
		},

		/**
   * 
   */
		checkBefore: {

			contactsSynchronisation: function () {
				return navigator.isNative;
			}

		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		contactsSynchronisation: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/contactsSynchro'], function (require) {

					var contactsSynchro = require('views/setting/contactsSynchro');
					var view = new contactsSynchro(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		prefOverview: function (isdeleted) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/accountPref'], function (require) {

					var AccountPref = require('views/setting/accountPref');
					var view = new AccountPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   *
   */
		nylasPrefs: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/nylas/nylasPrefs'], function (require) {

					var NylasPrefs = require('views/nylas/nylasPrefs');
					var view = new NylasPrefs(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		staffPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/staffPref'], function (require) {

					var StaffPref = require('views/setting/staffPref');
					var view = new StaffPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		corpPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/corpPref'], function (require) {

					var CorpPref = require('views/setting/corpPref');
					var view = new CorpPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		tvaPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/tvaPref'], function (require) {

					var TvaPref = require('views/setting/tvaPref');
					var view = new TvaPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		pwdPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/pwdPref'], function (require) {

					var PwdPref = require('views/setting/pwdPref');
					var view = new PwdPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		appPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/appPref'], function (require) {

					var AppPref = require('views/setting/appPref');
					var view = new AppPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		addrPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/CorpAddressesPref'], function (require, AddrPref) {

					var view = new AddrPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		mediumPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/mediumPref'], function (require) {

					var MediumPref = require('views/setting/mediumPref');
					var view = new MediumPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		packagingPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/packagingPref'], function (require) {

					var PackagingPref = require('views/setting/packagingPref');
					var view = new PackagingPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		shippingPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/shippingPref'], function (require) {

					var ShippingPref = require('views/setting/shippingPref');
					var view = new ShippingPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		bankAccountPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;var _this = this;

			return new Promise(function (resolve, reject) {

				require(['views/setting/bankAccountPref'], function (BankAccountPref) {

					var view = new BankAccountPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		rateCategoriesPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['views/setting/rateCategoriesPref'], function (RateCategoriesPref) {

					var view = new RateCategoriesPref(_this);
					view.trueRoute = trueRoute;

					view.render();
					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		unityPref: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['views/setting/unityPref'], function (UnityPref) {

					var view = new UnityPref(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		reloadToOverview: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/setting/accountPref'], function (require) {

					var AccountPref = require('views/setting/accountPref');
					var view = new AccountPref(_this);
					view.trueRoute = trueRoute;

					view.render();
					resolve(view);
				});
			});
		}

	});
});
