/**
 *  @desc Accountings Collection, extend core Collection
 */
define('models/collections/accountings',['models/core/collection', 'models/singles/accounting'], function (Collection, Accounting) {

	'use strict';

	return Collection.extend({
		model: Accounting,
		storeName: 'accounting',
		name: 'accountings',
		url: '/rest/accounting/list',
		fields: ["label"],
		canBeStored: true
	});
});
