/**
 * @desc mailToForm Form, extend Page View
 * Child View : Core Form
 */
define('views/email/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/third', 'models/singles/document', 'models/singles/email', 'models/collections/thirds', 'models/collections/staffs', 'models/collections/peoples', 'models/collections/emailTemplates', 'app', 'tinyMCE', 'tools/core/utils', 'tools/buildURL', 'moment', 'selectize', 'libs/plugins/placeholder/plugin'], function (Backbone, _, Page, Form, Third, Document, Email, Thirds, Staffs, Peoples, EmailTemplates, App, TinyMCE, Utils, URLBuilder, Moment) {

	'use strict';

	return Page.extend({

		id: 'mail-form',
		events: _.extend({
			'change #mailTemplates': 'setTemplate'
		}, Page.prototype.events),

		title: App.polyglot.t("newMessage"),
		form: {},
		toastMessages: {
			emailSaved: App.polyglot.t("emailSend")
		},

		headerEvents: {
			'click .send-mail': function (e) {
				$('#mail-form form').trigger('submit');
				return false;
			}
		},

		actionList: {

			sendmail: {
				img: "send_mail.svg",
				link: function (e) {
					$(e.currentTarget).hide();
					$(e.currentTarget).parent().append('<i class="fa fa-circle-o-notch fa-spin"></i>');
					this.form.sendDataForm(e);
				},
				aClass: "sendMail"
			}

		},

		/**
   * 
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router, linkedtype, linkedid, comefrom) {

			var _this = this;
			this.template = "templates/email/form.twig";
			this.linkedname = localStorage.getItem('linkedName');
			this.linkedid = linkedid;

			Page.prototype.initialize.apply(this, arguments);

			var linkedClassName = new Promise(function (resolve, reject) {

				/**
    * 
    * @desc take the right linked object depending of linkedtype
    */
				if (linkedtype && linkedid) {

					_this.params = {
						name: _this.linkedname
					};

					if (linkedtype === 'reply' || linkedtype === 'forward') {

						//linkedid is parentid. parentid is mailId of mail than we reply
						require(['models/singles/email'], function (Email) {

							_this.params['parentid'] = linkedid;
							_this.replyOrForward = linkedtype;
							_this.thirds = new Thirds();
							_this.collections[_this.thirds.name] = _this.thirds;
							_this.peoples = new Peoples();
							_this.collections[_this.peoples.name] = _this.peoples;
							_this.parentMail = new Email({ id: linkedid });

							_this.parentMail.fetchLocal().then(function () {

								//take in priority the related object before the linked
								if (_this.parentMail.get('relatedid') != 0) {
									var idAttr = "relatedid";
									var typeAttr = "relatedtype";
								} else {
									var idAttr = "linkedid";
									var typeAttr = "linkedtype";
								}

								//choose linked object of parent email for set cutomvalues of custom templates
								_this.chooseAndBuildLinkeds(_this.parentMail.get(idAttr), _this.parentMail.get(typeAttr)).then(function (Linked) {

									linkedid = _this.linkedid = _this.parentMail.get(idAttr);
									linkedtype = _this.parentMail.get(typeAttr);

									if (!Linked) {
										_this.linked = _this.parentMail;
									}

									resolve(Linked);
								});
							});
						});
					} else {
						_this.chooseAndBuildLinkeds(linkedid, linkedtype).then(function (Linked) {
							resolve(Linked);
						});
					}
				} else {

					_this.thirds = new Thirds();
					_this.collections[_this.thirds.name] = _this.thirds;
					_this.peoples = new Peoples();
					_this.collections[_this.peoples.name] = _this.peoples;
					resolve();
				}
			});

			linkedClassName.then(_.bind(function (Linked) {

				_this.options.extraData = {};

				if (Linked) {

					this.linked = new Linked({ id: linkedid });
					_this.linked.bypassNotLoadable = true;

					var linkedWithThirdRequired = ['opportunity'].concat(App.availableDoctypes, App.availablePurDoctypes);

					//we need to fetch linkedobj in first for take his linkedthird before render
					if (Utils.inArray(_this.linkedtype, linkedWithThirdRequired)) {

						this.options.extraData.linkedObj = function () {

							return new Promise(function (resolve, reject) {

								_this.linked.fetchLocal().then(function () {

									_this.linkedname = _this.linked.get(_this.linkedNameAttr);
									_this.third = new Third({ id: _this.linked.get('thirdid') ? _this.linked.get('thirdid') : _this.linked.get('linkedid') });
									_this.third.bypassNotLoadable = true;
									_this.params['linkedid'] = _this.third.get('id');

									_this.third.fetch().then(function () {
										resolve(_this.linked.toJSON());
									});
								});
							});
						};
					} else {
						this.models[this.linked.name] = this.linked;
					}
				}

				//add some extradatas for template
				_this.options.extraData.linked = function () {

					return new Promise(function (resolve, reject) {
						resolve(_this.params);
					});
				};

				this.staffs = new Staffs();
				this.staffs.mustFetchLocal = true;
				this.collections[this.staffs.name] = this.staffs;

				//Get custom email templates
				this.options.extraData.templates = function () {

					return new Promise(function (resolve, reject) {

						_this.emailTemplates = new EmailTemplates();

						var paramsRequest = _.clone(_this.params);

						//if related is set, we unset linkedtype/linkedid for APIRequest
						if (paramsRequest && paramsRequest['relatedtype']) {

							delete paramsRequest['linkedid'];
							delete paramsRequest['linkedtype'];
						}

						_this.emailTemplates.fetch({
							method: "POST",
							data: JSON.stringify(paramsRequest)
						}).then(function () {

							_this.emailTemplates.add({ id: 0, message: '', subject: '', name: App.polyglot.t('noTemplate') }, { at: 0, silent: true });

							if (_this.isDoc && comefrom !== 'mailbox') {

								Document.getMailTemplate(_this.params['relatedtype'], linkedid).then(function (docTemplate) {

									_this.docTplId = docTemplate.id;
									docTemplate.name = Utils.capitalizeFirstLetter(App.polyglot.t(_this.params['relatedtype']));

									_this.emailTemplates.add(docTemplate);

									resolve(_this.emailTemplates.toJSON());
								});
							} else {
								resolve(_this.emailTemplates.toJSON());
							}
						});
					});
				};

				this.form = new Form({ parentView: this });
				this.subviews.push(this.form);
				this.render();

				this.listenTo(this.form, 'dataSend', this.formAction);
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;
			this.emailList = [];
			var emailPreset = '';
			var loadOption = {};

			if (this.linked && !this.linkedname) {
				this.linkedname = this.linked.get(this.linkedNameAttr);
			}

			if (this.replyOrForward === 'reply') {

				if (this.parentMail.get('peopleid')) {
					var linkedid = this.parentMail.get('peopleid');
				} else if (this.linked.get('relatedid')) {
					var linkedid = this.parentMail.get('relatedid');
				} else {
					var linkedid = this.parentMail.get('linkedid');
				}

				if (this.parentMail.get('box') === 'inbox') {
					var keyName = 'sender';
				} else {
					var keyName = 'recipients';
				}

				if (_.isArray(this.parentMail.get(keyName))) {
					var sender = _.first(this.parentMail.get(keyName));
				} else {
					var sender = this.parentMail.get(keyName);
				}

				var email = sender['email'];
				var name = sender['name'];
				var emailPreset = linkedid + '_' + email;

				this.emailList.push({ name: name, email: email, id: emailPreset });
			} else {

				switch (this.linkedtype) {

					case 'third':

						var emailDefault = this.linked.get("email");

						if (emailDefault) {
							this.emailList.push({ name: this.linked.get('name'), email: emailDefault, id: this.linked.get('id') + '_' + this.linked.get("email"), avatar: this.linked.get('avatar') });
						}

						_.each(this.linked.get('contacts'), function (contact) {

							if (contact.email) {
								_this.emailList.push({ name: contact.fullName, email: contact.email, id: _this.linked.get('id') + '_' + contact.email, avatar: contact.avatar });
							}
						});

						var emailPreset = emailDefault ? this.linked.get('id') + '_' + this.linked.get("email") : _this.linked.get('id') + '_' + this.linked.get('contact')['email'];

						break;

					case 'people':

						var emailDefault = this.linked.get("email");

						if (emailDefault) {
							this.emailList.push({ name: this.linked.get('name'), email: emailDefault, id: this.linked.get('id') + '_' + this.linked.get("email"), avatar: this.linked.get('avatar') });
						}

						var emailPreset = emailDefault ? this.linked.get('id') + '_' + emailDefault : '';

						break;

					case 'invoice':
					case 'estimate':
					case 'proforma':
					case 'delivery':
					case 'order':
					case 'creditnote':
					case 'opportunity':
					case 'purInvoice':
					case 'purDelivery':
					case 'purOrder':
					case 'purCreditNote':
					case 'purCreditnote':

						var emailDefault = this.third.get("email");

						if (emailDefault) {
							this.emailList.push({ name: this.third.get('name'), email: emailDefault, id: this.third.get('id') + '_' + this.third.get("email"), avatar: this.third.get('avatar') });
						}

						_.each(this.third.get('contacts'), function (contact) {

							if (contact.email) {
								_this.emailList.push({ name: contact.fullName, email: contact.email, id: _this.third.get('id') + '_' + contact.email, avatar: contact.avatar });
							}
						});

						var emailPreset = emailDefault ? this.third.get('id') + '_' + this.third.get("email") : _this.third.get('id') + '_' + this.third.get('contact')['email'];

						break;

					default:

						var loadOption = function (query, callback) {

							return new Promise(function (resolve, reject) {

								var promises = [];
								var thirds4Search = new Thirds();
								var peoples4Search = new Peoples();

								promises.push(thirds4Search.fetch({

									method: 'POST',
									data: {
										search: {
											contains: query
										}
									}

								}));

								promises.push(peoples4Search.fetch({

									method: 'POST',
									data: {
										search: {
											contains: query
										}
									}

								}));

								Promise.all(promises).then(function () {

									thirds4Search.each(function (third) {

										if (third.get('email')) {
											_this.emailList.push({ name: third.get('name'), email: third.get('email'), id: third.get('id') + '_' + third.get('email'), avatar: third.get('avatar') });
										}
									});

									peoples4Search.each(function (people) {
										if (people.get('email')) {
											_this.emailList.push({ name: people.get('fullName'), email: people.get('email'), id: people.get('id') + '_' + people.get('email'), avatar: people.get('avatar') });
										}
									});

									resolve(_this.emailList);
								}, function (error) {
									reject(error);
								});
							});
						};

						this.thirds.each(function (third) {

							if (third.get('email')) {
								_this.emailList.push({ name: third.get('name'), email: third.get('email'), id: third.get('id') + '_' + third.get('email'), avatar: third.get('avatar') });
							}
						});

						this.peoples.each(function (people) {

							if (people.get('email')) {
								_this.emailList.push({ name: people.get('fullName'), email: people.get('email'), id: people.get('id') + '_' + people.get('email'), avatar: people.get('avatar') });
							}
						});

						break;

				}
			}

			this.staffs.each(function (staff) {
				_this.emailList.push({ name: staff.get('fullName'), email: staff.get('email'), id: staff.get('id') + '_' + staff.get('email'), avatar: staff.get('avatar') });
			});

			this.selectizeObjects = [{
				domElt: $("input[name='mail[emails]']", this.$el),
				create: true,
				searchField: ['email', 'name'],
				valueField: 'id',
				labelField: 'email',
				options: this.emailList,
				maxItems: 10,
				maxOptions: 10,
				defaultValues: [emailPreset],
				load: loadOption,
				render: 'email'
			}, {
				domElt: $("input[name='mail[emailsCC]']", this.$el),
				create: true,
				searchField: ['email', 'name'],
				valueField: 'id',
				labelField: 'email',
				options: this.emailList,
				maxItems: 10,
				maxOptions: 10,
				load: loadOption,
				render: 'email'
			}, {
				domElt: $("input[name='mail[emailsBCC]']", this.$el),
				create: true,
				searchField: ['email', 'name'],
				valueField: 'id',
				labelField: 'email',
				options: this.emailList,
				maxItems: 10,
				maxOptions: 10,
				load: loadOption,
				render: 'email'
			}];

			Page.prototype.afterRender.apply(this, arguments);

			TinyMCE.init({
				selector: '.body-mail',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize placeholder",
				menubar: false,
				statusbar: false,
				cleanup: true,
				forced_root_block: "",
				setup: function (editor) {

					_this.editor = editor;

					editor.on('init', _.bind(function (args) {

						if (_this.replyOrForward === 'forward') {

							var recipientsEmail = _.pluck(_this.linked.get('recipients'), "email");

							var message = "<br /><br />---------- " + App.polyglot.t("emailForwarded") + " ----------<br /> " + App.polyglot.t("de") + " : " + _this.parentMail.get('sender').email + "<br />" + App.polyglot.t("date") + " : " + Moment.unix(_this.parentMail.get('created_date')).format(App.dateFormat.momentFormat + ' ' + App.hourFormat) + "<br />" + App.polyglot.t("subject") + " : " + _this.parentMail.get('subject') + "<br />" + App.polyglot.t("to") + " : " + recipientsEmail.join(',') + "<br />" + '<blockquote>' + _this.parentMail.get('message') + '</blockquote>';

							editor.setContent(message);

							$('input[name="mail[subject]"]').val(App.polyglot.t("tr") + ' : ' + _this.parentMail.get('subject'));
						}

						if (_this.docTplId) {
							$('#mailTemplates').val(_this.docTplId).trigger('change');
						}
					}, this));
				}
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		chooseAndBuildLinkeds: function (linkedid, linkedtype) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				//require linked
				switch (linkedtype) {

					case 'third':
					case 'client':
						require(['models/singles/third'], function (Third) {

							_this.params['linkedtype'] = _this.linkedtype = 'third';
							_this.params['linkedid'] = linkedid;
							_this.linkedNameAttr = 'name';
							resolve(Third);
						});

						break;

					case 'prospect':

						require(['models/singles/prospect'], function (Prospect) {

							_this.params['linkedtype'] = _this.linkedtype = 'third';
							_this.params['linkedid'] = linkedid;
							_this.linkedNameAttr = 'name';

							resolve(Prospect);
						});

						break;

					case 'supplier':

						require(['models/singles/supplier'], function (Supplier) {

							_this.params['linkedtype'] = _this.linkedtype = 'third';
							_this.params['linkedid'] = linkedid;
							_this.linkedNameAttr = 'name';

							resolve(Supplier);
						});

						break;

					case 'people':

						require(['models/singles/people'], function (People) {

							_this.params['linkedtype'] = _this.linkedtype = 'people';
							_this.params['linkedid'] = linkedid;
							_this.linkedNameAttr = 'fullName';

							resolve(People);
						});

						break;

					case 'opportunity':

						require(['models/singles/opportunity'], function (Opportunity) {

							_this.params['relatedtype'] = linkedtype;
							_this.params['linkedtype'] = 'third';
							_this.linkedtype = 'opportunity';
							_this.params['relatedid'] = linkedid;
							_this.linkedNameAttr = 'ident';

							resolve(Opportunity);
						});

						break;

					case 'estimate':
					case 'order':
					case 'delivery':
					case 'proforma':
					case 'invoice':
					case 'creditnote':

						_this.params['relatedtype'] = linkedtype;
						_this.params['relatedid'] = linkedid;
						_this.params['linkedtype'] = 'third';
						_this.linkedtype = linkedtype;
						_this.isDoc = true;
						_this.linkedNameAttr = 'ident';

						require(['models/singles/document'], function (Document) {

							Document.classNameByDoctype(_this.params['relatedtype']).then(function (documentType) {
								resolve(documentType);
							});
						});

						break;

					case 'purOrder':
					case 'purDelivery':
					case 'purInvoice':
					case 'purCreditNote':

						_this.params['relatedtype'] = linkedtype;
						_this.params['relatedid'] = linkedid;
						_this.params['linkedtype'] = 'third';
						_this.linkedNameAttr = 'ident';
						_this.linkedtype = linkedtype;

						require(['models/singles/purchase'], function (Purchase) {

							Purchase.classNameByDoctype(linkedtype).then(function (documentType) {
								resolve(documentType);
							});
						});

						break;

					default:

						if (_this.replyOrForward && _this.parentMail.get('peopleid') != 0) {

							require(['models/singles/people'], function (People) {

								_this.params['linkedtype'] = _this.linkedtype = 'people';
								_this.params['linkedid'] = _this.parentMail.get('peopleid');
								_this.linkedNameAttr = 'fullName';

								resolve(People);
							});
						} else {
							resolve();
						}

						break;

				}
			});
		},

		/**
   * 
   * @param event e
   * @returns {undefined}
   */
		setTemplate: function (e) {

			var $currentTarget = $(e.currentTarget);
			var tplId = $currentTarget.val();

			if (tplId != 0) {

				var templateModel = this.emailTemplates.get(tplId);

				this.editor.setContent(templateModel.get('message'));

				$('input[name="mail[subject]"]', this.$el).val(templateModel.get('subject'));
			} else {

				$('input[name="mail[subject]"]', this.$el).val('');
				this.editor.setContent('');
			}
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function (e) {

			var _this = this;
			var dataForm = this.form.dataForm;
			dataForm.mail['selectize'] = '';

			//we dont try to link email to third if he's deleted
			if (this.third && this.third.isLoadable === false || this.parentMail) {
				delete dataForm['mail']['linkedid'];
				delete dataForm['mail']['linkedtype'];
			}

			var mail = new Email(dataForm);

			if (mail.isValid()) {

				mail.save().then(function () {

					var linkToNewMail = URLBuilder(['email', 'list', "inbox"]);

					if (_this.linked && _this.linkedtype && _this.linkedid && _this.linked.isLoadable !== false) {
						linkToNewMail = URLBuilder([linkToNewMail, _this.linkedtype, _this.linkedid], false);
					}

					var isListingRegexp = new RegExp(".*\/list.*");

					//if we come from listing navigate on overview, else just do window.back for return on overview update
					if (isListingRegexp.test(_this.router.history[0])) {
						window.history.back();
					} else {
						_this.router.navigate(linkToNewMail, { trigger: true, replace: true });
					}

					_this.form.trigger('successful', _this.toastMessages.emailSaved);
				}, function () {

					$('.sendMail').show();
					$('.fa-circle-o-notch').remove();
				});
			} else {

				// re activate submit button
				$('.sendMail').show();
				$('.fa-circle-o-notch').remove();

				//Make the input invalid in error
				_.each(mail.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
