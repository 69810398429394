/**
 * @desc Third List, extend PageList View
 * Child View : Core Listing
 */
define('views/third/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'app', 'tools/core/utils'], function (_, PageList, Listing, URLBuilder, App, Utils) {

	'use strict';

	return PageList.extend({

		name: 'clients',
		linked: 'third',

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		addAction: function (e) {
			this.listing.goToForm(URLBuilder([this.linked, 'form']));
		},

		addImg: Utils.loadFile('new_contact.svg', 'images'),
		title: App.polyglot.t('clients'),
		listing: {},
		subviews: [],
		models: {},
		urlToForm: URLBuilder(["third", "form"]),
		toastMessages: {
			linked: {
				linkedRemoved: App.polyglot.t('clientDeleted')
			}
		},

		template: "templates/third/list.twig",

		/**
   * @desc initialize
   */
		initialize: function (router, collection, availableActions) {

			var relationType = this.linked === "third" ? "client" : this.linked;

			this.availableActions = availableActions;
			this.canDelete = App.permission.can("delete" + relationType);
			this.canAdd = App.permission.can("create" + relationType);
			this.canEdit = App.permission.can("edit" + relationType);

			if (relationType === "people") {
				this.canAdd = this.canAdd && App.permission.level("peoplelevel");
			}

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.var4template("trueRelationType", relationType);
				this.router = router;
				this.placeholderSearchBar = App.polyglot.t('name') + ', ' + App.polyglot.t('forename') + ', ' + App.polyglot.t('email') + ', ' + App.polyglot.t("tel") + ", " + App.polyglot.t('ref');
				this.collection = collection;
				this.listing = new Listing({ parentView: this });

				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canEdit || this.canDelete) {

				//init material effect on each row of listing
				this.listing.initSwipeEffect({
					removeMsg: this.toastMessages.linked.linkedRemoved,
					onModify: URLBuilder([this.linked, "form"]),
					popupid: this.linked + '-option',
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					}

				}, '', this);
			}
		}

	}, {
		/**
   * STATIC Methods
   */

	});
});
