/**
 *	@desc People model, extend Core Model 
 */
define('models/singles/people',['models/core/model', 'underscore', 'db/easy', 'tools/ajax', 'tools/buildURL'], function (Model, _, DB, Ajax, URLBuilder) {

	'use strict';

	return Model.extend({

		defaults: {

			id: 0,
			contactid: 0,
			clientid: 0,
			name: "",
			forename: "",
			email: "",
			tel: "",
			mobile: "",
			civil: "",
			isMain: "N"

		},

		storeName: 'contact',
		urlRoot: '/rest/people/',
		name: 'people',

		/**
   * @desc Check if model is valid
   */
		validate: function (attributes, options) {

			var required = {
				"people": {
					name: true,
					forename: false,
					email: false,
					mobile: false,
					tel: false
				}
			};

			var validatorRegEx = {
				email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i
			};

			var validationErrors = { people: {} };

			//Contact validator

			if (attributes.name === "" && required.people.name) {
				validationErrors['people']['name'] = this.computeError({ name: 'name', type: 'required' });
			}

			if (!validatorRegEx.email.test(attributes.email) && attributes.email !== "") {
				validationErrors['people']['email'] = this.computeError({ name: 'email', type: 'invalid' });
			}

			if (_.toArray(validationErrors.people).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		saveLocal: function (ajaxError) {

			var _this = this;

			var errorHandler = function (resolve, reject, ajaxError) {

				// Becareful with ajax response
				if (_.isUndefined(ajaxError)) {
					resolve();
				} else {
					reject(ajaxError);
				}
			};

			return new Promise(function (resolve, reject) {

				if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

					var linkedId = _this.get('clientid');

					if (_this.id !== 0) {

						var jsonContact = _this.toJSON();

						DB[_this.storeName].put(jsonContact).then(function (lastId) {

							//							DB['third'].update(parseInt(linkedId), {contact : jsonContact}).then(function(){
							//								resolve();
							//							}, function(error){
							//								console.log(error);
							//								reject();
							//							});

							resolve();
						});
					} else {

						var jsonContact = _this.toJSON();

						DB[_this.storeName].put(jsonContact).then(function (lastId) {
							resolve();
						}, function (error) {
							console.log(error);
							reject();
						});
					}
				} else {
					errorHandler(resolve, reject, ajaxError);
				}
			});
		},

		/**
   * 
   * @returns {Promise}
   */
		destroyLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].delete(_this.id).then(function () {
					resolve();
				});
			});
		}

	}, {

		/**
   * 
   * @returns {undefined}
   */
		updateMainContactForThird: function (peopleid, thirdid) {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(['rest', 'people', 'updateMain4Third', peopleid, thirdid], false),
					method: 'POST',
					success: function () {
						resolve();
					},
					error: function (error) {
						reject();
					}

				});
			});
		}

	});
});
