/**
 *  @desc purOrders Collection, extend core Collection
 */
define('models/collections/purOrders',['models/collections/purchases', 'models/singles/purOrder'], function (Purchases, Order) {

	'use strict';

	return Purchases.extend({

		model: Order,
		doctype: 'order',
		availableSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'partialinvoiced', 'invoiced', 'cancelled']

	});
});
