/**
 * @desc DocPref PaymentPref, extend Page View
 */
define('views/document/docPrefGlobal',['underscore', 'views/core/page', 'views/core/form', 'models/collections/taxes', 'tools/buildURL', 'models/singles/document', 'views/core/toastNotification', "models/collections/payMediums", 'models/collections/paydates', 'moment', 'app', 'views/core/accordion', 'tools/core/utils', 'models/singles/currency', 'models/singles/rateCategory', 'tools/numberFormat', 'models/singles/third', 'selectize'], function (_, Page, Form, Taxes, URLBuilder, Document, ToastNotification, PayMediums, PayDates, Moment, App, Accordion, Utils, Currency, RateCategory, NumberFormat, Third) {

	'use strict';

	return Page.extend({

		id: 'docPrefGlobal',
		title: 'Préférences du document',
		events: {
			'change .discount-row select': 'changeDiscountType',
			"change select[name='paydate[id]']": "payDateSelecter",
			'change input[name="document[displayShipAddress]"]': "toggleAddrSelectize",
			'change input[name="document[useServiceDates]"]': "toggleServiceDatas",
			"click .goto": "docInTemp"
		},

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, thirdid, docid) {

			this.previousLink = router.navigate.bind(this, URLBuilder(['document', 'form', doctype, thirdid, docid, 'docPrefGlobal']), { trigger: true, replace: true });

			Page.prototype.initialize.apply(this, arguments);

			Document.classNameByDoctype(doctype).then(_.bind(function (ClassName) {

				this.modelToSave = new ClassName({ id: 0 });
				var _this = this;
				this.taxes = new Taxes();
				this.paymediums = new PayMediums();
				this.paydates = new PayDates();
				this.collections[this.paydates.name] = this.paydates;
				this.collections[this.paymediums.name] = this.paymediums;
				this.collections[this.taxes.name] = this.taxes;
				this.router = router;
				this.template = 'templates/document/docPrefGlobal.twig';

				this.options = {

					extraData: {

						//take tempDoc in localDB
						document: function () {

							return new Promise(function (resolve, reject) {

								_this.modelToSave.fetchLocal().then(function () {

									_this.third = new Third({ id: _this.modelToSave.get('thirdid') });

									_this.third.fetchLocal().then(function () {

										_this.modelToSave.set({ linkedThird: _this.third.toJSON() });

										//init docNumberformat
										if (_this.modelToSave.get('num_format')) {

											_this.numberFormat = $.extend(true, {}, new NumberFormat(_this.modelToSave.get('num_format')));

											//in case of update we havent currency id so we get it with currency symbol
											if (!_this.modelToSave.get("num_format").currencyid) {

												Currency.getCurrencyBySymbol(_this.modelToSave.get('num_format').currencysymbol).then(function (currency) {

													_this.modelToSave.attributes.num_format.currencyid = currency.id;
													resolve(_this.modelToSave.toJSON());
												});
											} else {
												resolve(_this.modelToSave.toJSON());
											}
										} else {

											_this.rateCategory = new RateCategory({ id: _this.modelToSave.get('rateCategory') });
											_this.numberFormat = $.extend(true, {}, App.numberFormat);
											_this.numberFormat.precisioncustom = _this.modelToSave.get('prefs').numberprecisioncustom ? JSON.parse(_this.modelToSave.get('prefs').numberprecisioncustom) : '';

											_this.rateCategory.fetchLocal().then(function () {

												//update numberFormatCurrency with currency of rateCategory
												if (_this.rateCategory.get('currencyid') != 0) {

													_this.currency = new Currency({ id: _this.rateCategory.get('currencyid') });
													_this.currency.fetchLocal().then(function () {

														var currency = _this.currency.get('symbol');
														_this.numberFormat.currency.symbol = currency;

														resolve(_this.modelToSave.toJSON());
													});
												} else {
													resolve(_this.modelToSave.toJSON());
												}
											});
										}
									});
								});
							});
						},

						countriesList: function () {

							return new Promise(function (resolve, reject) {

								Utils.getCountriesList().then(function (countriesList) {

									_this.countriesList = countriesList;
									resolve();
								});
							});
						}
					}

				};

				this.form = new Form({ parentView: this });

				this.subviews.push(this.form);
				this.listenTo(this.form, 'dataSend', this.formAction);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */

		afterRender: function () {

			this.delegateEvents();
			this.form.delegateEvents();

			if (this.modelToSave.get('payMediums')) {
				//priority on doctemps data
				var ids = _.isArray(this.modelToSave.get('payMediums')) ? this.modelToSave.get('payMediums') : this.modelToSave.get('payMediums').split(',');
			} else {
				var ids = this.modelToSave.get('linkedThird').prefs['payMediums'];
			}

			/**
    * Build Third Shipping Address
    * 
    */
			this.third = this.modelToSave.get('linkedThird');
			var placeholderThirdAddr = App.polyglot.t("shippingAddress");
			var addrToCheck = this.modelToSave.get('shipaddress');
			this.thirdAddresses = !_.isEmpty(this.third.addresses) ? _.clone(this.third.addresses) : {};

			if (addrToCheck && !_.isEmpty(addrToCheck)) {

				//format address for displaying 
				addrToCheck.toDisplay = _.isArray(addrToCheck['toDisplay']) ? {} : addrToCheck['toDisplay'];
				addrToCheck.toDisplay['countryname'] = this.countriesList[addrToCheck.countrycode]['name'];

				if (!addrToCheck.name) {
					addrToCheck.name = addrToCheck.toDisplay['countryname'];
				}
			} else {
				addrToCheck = {};
			}

			//if third have addresses and doc havent docship address, take default ship address of third
			if (_.isEmpty(addrToCheck) && !_.isEmpty(this.thirdAddresses)) {
				addrToCheck = this.third.mainAddressDeliv ? this.third.mainAddressDeliv : this.third.mainAddress;
			} else {

				//add docthird address in third addresses
				if (!this.thirdAddresses[addrToCheck.id + '_0']) {
					this.thirdAddresses[addrToCheck.id + "_0"] = addrToCheck;
				}

				//delete original address if she is equals with docship address
				if (this.thirdAddresses[addrToCheck.originalid + '_0']) {

					if (Utils.isAddressEquals(addrToCheck, this.thirdAddresses[addrToCheck.originalid + '_0'])) {
						delete this.thirdAddresses[addrToCheck.originalid + '_0'];
					}
				}
			}

			if (this.modelToSave.get('displayShipAddress') === 'Y') {
				$('input[name="document[displayShipAddress]"]', this.$el).prop("checked", true).trigger('change');
			}

			this.selectizeObjects = [{
				domElt: $(".pay-mediums-selectize", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'value',
				searchField: ['value'],
				options: this.paymediums,
				minItems: 1,
				defaultValues: ids
			}];

			if (addrToCheck.id) {

				this.selectizeObjects.push({
					domElt: $(".shipAddr-selectize", this.$el),
					create: false,
					valueField: 'id',
					labelField: 'name',
					searchField: ['name,part1,part2'],
					options: this.thirdAddresses,
					maxItems: '1',
					maxOptions: 10,
					placeholder: placeholderThirdAddr,
					defaultValues: [addrToCheck.id],
					render: 'address'
				});
			}

			//if paydate is already define, initialize inputs with paydate values
			if (this.modelToSave.get('paydate')) {
				$("select[name='paydate[id]']", this.$el).val(this.modelToSave.get('paydate').id).trigger('change');
			} else {
				$("select[name='paydate[id]']").trigger('change');
			}

			/**
    * Call parent aferRender function
    * Page is in DOM after this function
    */
			Page.prototype.afterRender.apply(this, arguments);

			//some checks for display or not a panel in the accordion
			this.required4Accordion = {
				'taxes-info': this.modelToSave.get('hasTaxesInc') === 'N' && !this.modelToSave.get('docid')
			};

			var accordion = new Accordion({ scrollable: $('.form', this.$el), required: this.required4Accordion });

			$('.checkbox').disableSelection();

			if (this.modelToSave.get('hasDoubleVat') === "Y") {
				$('input[name="document[hasDoubleVat]"]', this.$el).prop('checked', true).trigger('change');
			}

			//focus input's numberFormat
			$('select[name="numberFormat[currencyid]"] option[data-currencysymbol="' + this.numberFormat.currency.symbol + '"]', this.el).prop('selected', true);
			$('select[name="numberFormat[currencypos]"] option[value="' + this.numberFormat.currency.position + '"]', this.el).prop('selected', true);
			$('select[name="numberFormat[precision]"] option[value="' + this.numberFormat.precision + '"]', this.el).prop('selected', true);
			$('select[name="numberFormat[separator]"] option[data-thousands="' + this.numberFormat.separators.thousands + '"][data-decimals="' + this.numberFormat.separators.decimals + '"]', this.el).prop('selected', true);
			$('select[name="precisioncustom[qt]"]', this.el).val(this.numberFormat.precisioncustom && this.numberFormat.precisioncustom.qt !== 'default' ? this.numberFormat.precisioncustom.qt : this.numberFormat.precision);

			//set date service inputs
			if (this.modelToSave.get('useServiceDates') === 'Y') {
				$('input[name="document[useServiceDates]"]').prop('checked', true).trigger('change');
				$('input[name="document[serviceDateStart]"]').val(this.modelToSave.get('serviceDateStart'));
				$('input[name="document[serviceDateStop]"]').val(this.modelToSave.get('serviceDateStop'));
			}

			if (this.modelToSave.get('globalDiscountUnit') === 'amount' || this.modelToSave.get('globalDiscountUnit') === 'percent') {

				var $selectDiscountUnit = $('select[name="document[globalDiscountUnit]"]', this.$el);

				$selectDiscountUnit.val(this.modelToSave.get('globalDiscountUnit')).trigger('change');
				$('input[name="document[globalDiscount]"]', this.$el).val(this.modelToSave.get('globalDiscount')).trigger('change');
			}
		},

		/**
   * 
   */
		changeDiscountType: function (e) {

			var $currentTarget = $(e.currentTarget);

			//change symbol of input discount-amount
			if ($currentTarget.val() === 'percent') {
				$('.discount-row .right-addon', this.$el).text('%');
			} else {
				$('.discount-row .right-addon', this.$el).text(this.numberFormat.currency.symbol);
			}
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		payDateSelecter: function (e) {

			var $currentTarget = $(e.currentTarget);
			var $option = $('option:selected', $currentTarget);
			var isUpdate = this.modelToSave.get('paydate');

			//reset paydate;
			this.restorePaydate();

			var $inputCustom = $('input[name="paydate[custom]"]', this.$el);

			switch ($option.data('syscode')) {

				case "custom":
				case "onorder":

					$inputCustom.removeAttr('data-catchable').closest(".form-group").removeClass('hidden');

					if (isUpdate && isUpdate.custom) {

						var formattedDate = Moment(isUpdate.custom, 'X').format(App.HTMLDateFormat);
						$inputCustom.val(formattedDate);
					} else {

						var formattedDate = Moment().add(30, 'days').format(App.HTMLDateFormat);
						$inputCustom.val(formattedDate);
					}

					break;

				case "endmonth":
					break;

				case "30days":
				case "45days":
				case "60days":
				case "90days":

					$option.data('xdays', $option.data('syscode').substring(0, 2));
					$('input[name="paydate[endmonth]"]', this.$el).removeAttr('data-catchable').prop('checked', this.modelToSave.get('paydate').endmonth === 'Y' ? true : false).closest(".form-group").removeClass('hidden');

					break;

				case "xdays":

					var $xdays = $('input[name="paydate[xdays]"]', this.$el);

					$xdays.removeAttr('data-catchable').closest(".form-group").removeClass('hidden');

					if (isUpdate) {
						$xdays.val(isUpdate.xdays);
					}

					break;

				case "scaled":

					var $scaled = $('textarea[name="paydate[scaledDetails]"]', this.$el);
					var $xdays = $('input[name="paydate[xdays]"]', this.$el);

					$scaled.removeAttr('data-catchable').closest(".form-group").removeClass('hidden');
					$xdays.removeAttr('data-catchable').closest(".form-group").removeClass('hidden');

					if (isUpdate) {
						$scaled.val(isUpdate.scaledDetails);
						$xdays.val(isUpdate.xdays);
					}

					break;

				default:
					break;

			}
		},

		/**
   * 
   * @returns {undefined}
   */
		restorePaydate: function () {

			$('textarea[name="paydate[scaledDetails]"]', this.$el).data('catchable', 'N').closest(".form-group").addClass('hidden');
			$('input[name="paydate[custom]"]', this.$el).data('catchable', 'N').closest(".form-group").addClass('hidden');
			$('input[name="paydate[xdays]"]', this.$el).data('catchable', 'N').closest(".form-group").addClass('hidden');
			$('input[name="paydate[endmonth]"]', this.$el).data('catchable', 'N').closest(".form-group").addClass('hidden');
			$('select[name="paydate[id]"] option', this.$el).removeAttr('data-xdays');
		},

		/**
   * 
   * @returns {undefined}
   */
		toggleAddrSelectize: function (e) {
			$('.shipAddr-selectize', this.$el).closest('.form-group').toggleClass('hidden');
		},

		toggleServiceDatas: function (e) {

			var $target = $(e.currentTarget);

			$('#servciceDatesInterval').toggleClass('hidden');

			if ($target.prop('checked')) {
				$('input', "#servciceDatesInterval").removeAttr('data-catchable').prop('required', true);
			} else {
				$('input', "#servciceDatesInterval").attr('data-catchable', 'N').prop('required', false);
			}
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		docInTemp: function (e) {

			var $currentTarget = $(e.currentTarget);
			var goto = $currentTarget.data('goto');

			$currentTarget.css('pointer-events', 'none');

			this.form.$el.trigger('submit', [true, goto, $currentTarget]);
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function (temp, goto, $eventTarget) {

			var _this = this;
			var dataForm = this.form.dataForm;

			dataForm['document']['selectize'] = '';
			dataForm['paydate']['value'] = this.paydates.get(dataForm.paydate['id']).get('value'); //set label for display it
			dataForm['document']['payMediums'] = dataForm['document']['payMediums'].split(',');

			if (dataForm.document.hasDoubleVat === 'Y' || this.modelToSave.get('docid') && this.modelToSave.get('hasDoubleVat') === 'Y') {

				var tax2id = this.modelToSave.get('prefs').defaultTax2id != 0 ? this.modelToSave.get('prefs').defaultTax2id : this.modelToSave.get('prefs').defaultTaxid;
				var taxRate2 = _this.taxes.get(tax2id).get('value');

				//add on each row default VAT2 or if default VAT2 not set, first VAT in taxes collection
				_.each(this.modelToSave.get('rows'), function (row) {

					if (!row.tax2id) {
						row.tax2id = tax2id;
						row.taxRate2 = taxRate2;
					}
				});
			} else {

				dataForm.document.hasDoubleVat = 'N';

				_.each(this.modelToSave.get('rows'), function (row) {

					if (row.tax2id) {
						delete row.tax2id;
						delete row.taxRate2;
					}
				});
			}

			if (!dataForm['document']['useServiceDates']) {
				dataForm['document']['serviceDateStart'] = '';
				dataForm['document']['serviceDateStop'] = '';
				dataForm['document']['useServiceDates'] = 'N';
			}

			if (dataForm['document']["displayShipAddress"] !== 'Y') {
				dataForm['document']["displayShipAddress"] = 'N';
			}

			//set address object instead of address id
			dataForm['document']['shipaddress'] = this.thirdAddresses[dataForm['document']['shipaddressid'] + '_0'];

			var hasError = Document.validatePayDate.call(this.modelToSave, dataForm.paydate); //check validation errors

			if (!hasError) {

				if (dataForm.paydate['syscode'] === 'custom' || dataForm.paydate['syscode'] === "onorder") {
					//transform dataform custom in timestamp
					dataForm['paydate_custom'] = Utils.SellsyMoment(dataForm.paydate['custom'], App.HTMLDateFormat, true).unix();
					dataForm.paydate['custom'] = dataForm['paydate_custom'];
				}

				if (dataForm.precisioncustom) {
					dataForm.numberFormat.precisioncustom = dataForm.precisioncustom;
				}

				if (dataForm.document.useServiceDates === "N") {}

				_this.modelToSave.set({
					num_format: dataForm.numberFormat,
					paydate: dataForm.paydate,
					paydate_custom: dataForm.paydate_custom
				});

				var regExSysCode = new RegExp('.*days');

				if (regExSysCode.test(dataForm.paydate['syscode'])) {
					delete dataForm.paydate['syscode']['custom'];
				}

				_this.modelToSave.set(dataForm.document);

				this.modelToSave.saveLocal().then(function () {

					if (temp) {

						//j'ai fait un switch pour plus tard si l'on rajoute d'autres pages vers ou aller depuis les docprefs
						switch (goto) {

							case 'formAddress':

								var url = URLBuilder(['address', "form", _this.modelToSave.get('thirdid'), $eventTarget.data('linkedtype'), 0, 'docForm']);

								break;

							default:
								break;

						}

						_this.router.navigate(url, { trigger: true });
					} else {
						_this.previousLink();
					}
				});

				$('.submit-form', this.form.$el).removeAttr('disabled');
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make the input invalid in error
				_.each(hasError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {
						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}

					$('.panel-collapse', _this.$el).each(function (index, $element) {

						if ($(this).find('.has-error').length) {
							$(this).closest('.panel').addClass('panel-error');
						}
					});
				});
			}

			return false;
		}

	});
});
