define('views/purchase/fastForm',['views/core/page', 'views/core/form', 'models/singles/purInvoice', 'models/collections/suppliers', 'models/collections/taxes', "models/collections/accountings", 'models/collections/payMediums', 'app', 'big', 'moment', 'tools/math', 'tools/buildURL', 'tools/core/utils'], function (Page, Form, PurInvoice, Thirds, Taxes, Accountings, Paymediums, App, Big, Moment, Math, URLBuilder, Utils) {

	return Page.extend({

		id: 'doc-form',
		className: 'page fast-form',
		events: {
			'click .btn-itemLine': 'addRow',
			'click .btn-paymentLine': 'addPayment',
			'click .remove-line': 'deleteRow',
			'change #pur-rows input.number-format': 'computeRow',
			'change select[name*="taxid"]': 'computeRow',
			'change	#totalAmount4Compute': 'computePayments',
			'submit form': 'formAction'
		},
		toastMessages: {
			docSaved: App.polyglot.t('documentSaved')
		},

		/**
   * @desc initialize
   */
		initialize: function (router, id) {

			this.firstRender = true;

			Page.prototype.initialize.apply(this, arguments);

			this.modelToSave = new PurInvoice();

			if (id) {
				this.modelToSave.set({ id: id });
			} else {

				this.title = App.polyglot.t('createFastPurchase');

				this.options.extraData = {

					ident: function () {

						return new Promise(function (resolve, reject) {

							PurInvoice.getNextIdent('invoice').then(function (nextIdent) {
								resolve(nextIdent);
							});
						});
					}

				};
			}

			this.template = '/templates/purchase/fastForm.twig';
			this.thirds = new Thirds();
			this.taxes = new Taxes();
			this.currencies = App.currencies;

			if (App.pluginIsLoaded('accounting')) {

				this.accountings = new Accountings();
				this.accountings.XHRData = {
					'search': {
						view: 'purchase'
					}
				};

				this.collections[this.accountings.name] = this.accountings;
			}

			this.paymediums = new Paymediums();
			this.models[this.modelToSave.name] = this.modelToSave;
			this.collections[this.thirds.name] = this.thirds;
			this.collections[this.taxes.name] = this.taxes;
			this.collections[this.paymediums.name] = this.paymediums;

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		afterRender: function () {

			var _this = this;

			this.selectizeObjects = [{
				domElt: $(".supplier-selectize", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'name',
				searchField: ['name', 'forename'],
				options: this.thirds.toJSON(),
				maxItems: '1',
				maxOptions: 10,
				placeholder: Utils.capitalizeFirstLetter(this.polyglot.t('supplier')),
				loadThrottle: 600,
				load: function (query, callback) {

					return _this.thirds.fetch({

						method: 'POST',
						data: { search: { contains: query } }

					}, true);
				},
				render: 'third'
			}, {
				domElt: $('input[name="purchase[currency]"]', this.$el),
				create: false,
				defaultValues: this.currencies.findWhere({ 'selected': 'Y' }),
				options: this.currencies,
				valueField: 'id',
				labelField: 'symbol',
				searchField: ['symbol', 'name'],
				maxItems: '1',
				maxOptions: '1',
				loadThrottle: 600
			}];

			Page.prototype.afterRender.apply(this, arguments);

			this.currentRowIndex = 1;
			this.$row4Clone = $('#alpha-row', this.$el);
			this.currentPaymentIndex = 1;
			this.$payment4Clone = $('#alpha-payment', this.$el);
		},

		/**
   * 
   * @returns {undefined}
   */
		addRow: function () {

			var $newRow = this.$row4Clone.clone();

			$newRow.removeAttr('id');

			var htmlNewRow = $newRow.wrap("</p>").parent().html();
			htmlNewRow = htmlNewRow.replace(/(name(?:\s)*=(?:\s)*(?:'|").*\[)0(\]\[.*\])/g, "$1" + this.currentRowIndex + "$2");

			$('#pur-rows', this.$el).append(htmlNewRow);

			this.currentRowIndex++;
		},

		/**
   * 
   * @returns {undefined}
   */
		addPayment: function () {

			var $newRow = this.$payment4Clone.clone();

			$newRow.removeAttr('id');

			var htmlNewRow = $newRow.wrap("</p>").parent().html();
			htmlNewRow = htmlNewRow.replace(/(name(?:\s)*=(?:\s)*(?:'|").*\[)0(\]\[.*\])/g, "$1" + this.currentPaymentIndex + "$2");
			$('#linked-payments', this.$el).append(htmlNewRow);

			this.currentPaymentIndex++;
			this.computePayments();
		},

		/**
   * 
   * @returns {undefined}
   */
		deleteRow: function (e) {

			var $currentTarget = $(e.currentTarget);

			if (!$currentTarget.closest('#alpha-row').length) {
				$currentTarget.closest('li').remove();
				this.computeDoc();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		computePayments: function () {

			var $paymentRows = $('.payment-row', this.$el);
			var nbPayments = $paymentRows.length;

			if (nbPayments) {

				var totalDoc = $('#totalAmount4Compute', this.$el).val();
				var totalPerPayments = new Big(totalDoc).div(nbPayments);
				$paymentRows.each(function () {
					$('input[name$="[amount]"]').val(App.numberFormat.formatToDisplay(totalPerPayments.toString(), true, false));
				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		computeRow: function (e) {

			var $currentTarget = $(e.currentTarget);
			var $currentRow = $currentTarget.closest('.row-infos');

			if (App.hasVat) {

				var taxid = $('select[name*="[taxid]"]', $currentRow).val();
				var currentTax = this.taxes.get(taxid).get('value');

				if (new RegExp(/.*\[unitAmountTaxesInc\]/).test($currentTarget.attr('name'))) {

					var unitAmountTTC = $currentTarget.val();
					var unitAmount = Math.TTCtoHT(App.numberFormat.formatToFloat(unitAmountTTC), currentTax);

					$('input[name*="[unitAmount]"]', $currentRow).val(App.numberFormat.formatToDisplay(unitAmount.toString(), true, false));
				} else {
					//case of unitamount or tax input change

					var unitAmount = $('input[name*="[unitAmount]"]', $currentRow).val();
					var unitAmountTTC = Math.percentageIncrease(App.numberFormat.formatToFloat(unitAmount), currentTax);

					$('input[name*="[unitAmountTaxesInc]"]', $currentRow).val(App.numberFormat.formatToDisplay(unitAmountTTC.toString(), true, false));
				}
			} else {

				if (new RegExp(/.*\[unitAmountTaxesInc\]/).test($currentTarget.attr('name'))) {
					$('input[name*="[unitAmount]"]', $currentRow).val(App.numberFormat.formatToDisplay($currentTarget.val(), true, false));
				} else {
					$('input[name*="[unitAmountTaxesInc]"]', $currentRow).val(App.numberFormat.formatToDisplay($currentTarget.val(), true, false));
				}
			}

			this.computeDoc();
		},

		/**
   * 
   * @returns {undefined}
   */
		computeDoc: function () {

			var _this = this;
			var rows = [];

			//buildRows for compute Doc
			$('#pur-rows .row-infos', this.$el).each(function () {

				var $row = $(this);

				var row2Compute = {
					qt: 1,
					unitAmount: App.numberFormat.formatToFloat($('input[name*="[unitAmount]"]', $row).val()),
					type: 'once'
				};

				if (App.hasVat) {

					var taxid = $('select[name*="[taxid]"]', $row).val();
					var taxRate = _this.taxes.get(taxid).get('value');

					row2Compute.taxid = taxid;
					row2Compute.taxRate = taxRate;
				}

				rows.push(row2Compute);
			});

			var result = Math.sumRowsV2(rows, {
				hasVat: App.hasVat ? true : false
			});

			$('#row-amount', this.$el).text(App.numberFormat.formatToDisplay(result.totalAmountAllInc));
			$('#totalAmount4Compute', this.$el).val(result.totalAmountAllInc).trigger('change');
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function () {

			var _this = this;
			var dataForm = this.form.sendDataFormV2();

			if (dataForm['purchase']['displayedDate']) {
				dataForm['purchase']['displayedDate'] = Utils.SellsyMoment(dataForm["purchase"]['displayedDate'], App.HTMLDateFormat, true).unix(); //convert date in timestamp
			}

			_.each(dataForm['payments'], function (payment, key) {

				if (dataForm['payments'][key]['date']) {
					dataForm['payments'][key]['date'] = Utils.SellsyMoment(dataForm['payments'][key]['date'], App.HTMLDateFormat, true).unix(); //convert date in timestamp
				}
			});

			dataForm['paydate']['custom'] = Utils.SellsyMoment(dataForm['paydate']['custom'], App.HTMLDateFormat, true).unix();

			this.modelToSave.set(dataForm);

			if (this.modelToSave.isValid()) {

				this.modelToSave.save().then(function () {

					_this.form.trigger('successful', _this.toastMessages.docSaved);
					_this.router.navigate(URLBuilder(["purchase", "overview", _this.modelToSave.get('doctype'), _this.modelToSave.id]), { trigger: true, replace: true });
				});
			} else {

				$('.submit-form', _this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {

							if (_.isObject(msgError) || _.isArray(msgError)) {

								_.each(msgError, function (msgError2, keyMsg2) {

									var keys = [keyMsg, keyMsg2];
									_this.form.showValidationError(keyError, keys, msgError, true);
								});
							} else {
								_this.form.showValidationError(keyError, keyMsg, msgError, true);
							}
						});
					}
				});

				$('.panel-collapse', _this.$el).each(function (index, $element) {

					if ($(this).find('.has-error').length) {
						$(this).closest('.panel').addClass('panel-error');
					}
				});
			}

			return false;
		}

	});
});
