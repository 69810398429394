/**
 *  @desc Langs Collection, extend core Collection
 */
define('models/collections/langs',['models/core/collection', "models/singles/lang"], function (Collection, Lang) {

	'use strict';

	return Collection.extend({

		model: Lang,
		storeName: 'lang',
		name: 'langs',
		url: '/rest/main/langs',
		fields: [],
		canBeStored: true
	});
});
