/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/docLayouts',['models/core/collection', 'models/singles/docLayout'], function (Collection, DocLayout) {

	'use strict';

	return Collection.extend({

		model: DocLayout,
		storeName: 'docLayout',
		name: 'docLayouts',
		url: '/rest/doclayout/list',
		fields: []

	});
});
