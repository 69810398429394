/**
 * Child View : Core Listing
 */
define('views/expense/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/expenses', 'app'], function (_, PageList, Listing, URLBuilder, Expenses, App) {

	'use strict';

	return PageList.extend({

		name: 'expenses',
		params: {},
		title: App.polyglot.t('expenses'),
		listing: {},
		toastMessages: {
			removed: App.polyglot.t('expensedeleted')
		},
		template: "templates/expense/list.twig",
		addAction: function () {
			this.listing.goToForm(URLBuilder(['expense', 'form']));
		},

		placeholderSearchBar: App.polyglot.t('ref'),

		/**
   * @desc initialize
   */
		initialize: function (router) {

			this.canAdd = App.permission.can('expenses_createexpense') && App.permission.level('supplierlevel');
			this.canDelete = App.permission.can('expenses_deleteexpense');
			this.canEdit = App.permission.can('expenses_editexpense');

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.collection = new Expenses();
				this.listing = new Listing({ parentView: this });

				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canEdit || this.canDelete) {

				//init material effect on each row of listing
				this.listing.initSwipeEffect({
					removeMsg: _this.toastMessages.removed,
					onModify: function (model, $row) {
						_this.router.navigate(URLBuilder(["expense", "form", model.get('id')]), { trigger: true });
					},
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					canDelete: function (model) {
						return model.get('step') == "draft";
					},
					popupid: 'expense-pp',
					modelName: "ident"
				}, '', this);
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		buildListingFilters: function () {

			var steps = [{ value: '', label: App.polyglot.t('all') }];
			$.each(App.availableExpenseStep, function (index, value) {
				steps.push({ value: value, label: App.polyglot.t(value) });
			});

			var filterList = {
				state: {
					label: App.polyglot.t('state'),
					field: {
						type: 'select',
						isMultiple: true,
						name: 'steps',
						offlineSearchField: 'step',
						values: steps
					}
				}
			};

			this.listingFilters = filterList;
		}

	});
});
