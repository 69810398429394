/**
 * @desc Prospection router, extend Core Router
 */
define('routers/prospect',['backbone', 'underscore', 'routers/core/router', 'app'], function (Backbone, _, CoreRouter, App) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "contacts",

		checkOnline: {
			form: true
		},

		routes: {
			'prospect/list(/)(:comefrom)': 'listing',
			'prospect/overview/:id': 'overview',
			'prospect/form(/)(:id)(/)(:comefrom)': 'form'
		},

		/**
   * 
   */
		checkBefore: {

			form: function () {
				return App.permission.can("createprospect") || App.permission.can("editprospect");
			}

		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} comefrom
   * @returns {undefined}
   */
		listing: function (comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/prospection/prospect/list', 'models/collections/prospects'], function (require, ProspectList, Prospects) {

					var links = App.sellsyEasy.thirdRouter.getAvailableActions();
					var prospect = new Prospects();
					var view = new ProspectList(_this, prospect, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} thirdId
   * @param {type} comefrom
   * @returns {undefined}
   */
		form: function (thirdId, comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			thirdId = thirdId ? thirdId : 0;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/prospection/prospect/form', 'models/singles/prospect'], function (require, ProspectForm, Prospect) {

					var prospect = new Prospect({ id: thirdId });
					resolve(new ProspectForm(_this, thirdId, prospect, comefrom));
				});
			});
		},

		/**
  * 
  * @param {type} prospectId
  * @returns {undefined}
  */
		overview: function (prospectId) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/prospection/prospect/overview', 'models/singles/prospect'], function (require, ProspectOverview, Prospect) {

					var prospect = new Prospect({ id: prospectId });
					var view = new ProspectOverview(_this, prospectId, prospect);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		}

	});
});
