define('tools/core/utils',['underscore', 'jquery', 'tools/ajax', 'moment', 'tools/buildURL'], function (_, $, Ajax, Moment, URLBuilder) {

	'use strict';

	return {

		countriesList: {},

		// return boolean. return true if arrays are equals
		isArrayEquals: function (array1, array2) {
			return _.isEmpty(_.difference(array1, array2));
		},

		/**
   * 
   * @returns {undefined}
   */
		reload: function () {
			location.reload();
		},

		/**
   * 
   * @returns {undefined}
   */
		relocate: function (url) {
			window.location.href = url;
			window.location.reload(true);
		},

		/**
   * @desc string
   * @param {type} string
   * @returns {unresolved}
   */
		slug: function (string) {
			var $slug = '';
			var trimmed = $.trim(string);
			$slug = trimmed.replace(/[^a-z0-9-]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
			return $slug.toLowerCase();
		},

		/**
   * 
   * @param {type} collections
   * @param {type} key
   * @param {type} value
   * @returns {Boolean}
   */
		findInObjectsByValue: function (collections, key, value) {

			var row = false;

			_.each(collections, function (value, index) {

				if (value[key] && value[key] == value) {
					row = value;
				}
			});

			return row;
		},

		/**
   * 
   * @returns {undefined}
   */
		getCountriesList: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				if (!_.isEmpty(_this.countriesList)) {

					resolve(_this.countriesList);
				} else {

					Ajax({
						url: '/rest/address/countrieslist',
						method: 'GET',
						success: function (data) {

							_this.countriesList = data;
							resolve(_this.countriesList);
						}
					});
				}
			});
		},

		/**
   * 
   * @param {type} town
   * @param {type} country
   * @returns {undefined}
   */
		getTownList: function (town, country) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/address/townlist/' + town + '/' + country,
					method: 'GET',
					success: function (data) {
						resolve(data);
					}
				});
			});
		},

		/**
   * 
   */
		getZipList: function (town, country) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/address/ziplist/' + town + '/' + country,
					method: 'GET',
					success: function (data) {
						resolve(data);
					}
				});
			});
		},

		/**
   * 
   * @param {type} town
   * @param {type} country
   * @returns {Promise}
   */
		getStateList: function (town, country) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/address/statelist/' + town + '/' + country,
					method: 'GET',
					success: function (data) {
						resolve(data);
					}
				});
			});
		},

		/**
   * 
   * @param {type} str
   * @returns {unresolved}
   */
		capitalizeFirstLetter: function (str) {

			if (str) {
				return str.charAt(0).toUpperCase() + str.slice(1);
			} else {
				return str;
			}
		},

		lowerCaseFirstLetter: function (str) {
			return str.charAt(0).toLowerCase() + str.slice(1);
		},

		/**
   * 
   * @param {anything} val
   * @param {array} array
   * @returns {Boolean}
   */
		inArray: function (val, array) {
			return $.inArray(val, array) !== -1;
		},

		/**
   * 
   * @returns {String}
   */
		getRandomHTMLColor: function () {
			return '#' + Math.random().toString(16).slice(2, 8).toUpperCase();
		},

		/**
   * 
   * @param {type} monthID
   * @returns {Array}
   */
		getMonths: function () {

			//this array is filled below
			var monthDetails = [{
				label: 'january',
				color: '#303F9F',
				number: '01'
			}, {
				label: 'february',
				color: '#1976D2',
				number: '02'
			}, {
				label: 'march',
				color: '#00796B',
				number: '03'
			}, {
				label: 'april',
				color: '#4CAF50',
				number: '04'
			}, {
				label: 'may',
				color: '#CDDC39',
				number: '05'
			}, {
				label: 'june',
				color: '#FF9800',
				number: '06'
			}, {
				label: 'july',
				color: '#E64A19',
				number: '07'
			}, {
				label: 'august',
				color: '#607D8B',
				number: '08'
			}, {
				label: 'september',
				color: '#FF5252',
				number: '09'
			}, {
				label: 'october',
				color: '#E91E63',
				number: '10'
			}, {
				label: 'november',
				color: '#00BCD4',
				number: '11'
			}, {
				label: 'december',
				color: '#34495e',
				number: '12'
			}];

			return monthDetails;
		},

		/**
   * @function
   * @param {Object} address1
   * @param {Object} address2
   * @returns {Boolean}
   */
		isAddressEquals: function (address1, address2) {

			if (address1.name !== address2.name) {
				return false;
			}

			if (address1.part1 !== address2.part1) {
				return false;
			}

			if (address1.part2 !== address2.part2) {
				return false;
			}

			if (address1.part3 !== address2.part3) {
				return false;
			}

			if (address1.part4 !== address2.part4) {
				return false;
			}

			if (address1.town !== address2.town) {
				return false;
			}

			if (address1.zip !== address2.zip) {
				return false;
			}

			if (address1.countrycode !== address2.countrycode) {
				return false;
			}

			return true;
		},

		/**
   * @description convert date html format (Y-m-d) to timestamp
   * @param {type} htmlDate
   * @returns {unresolved}
   */
		HTMLDateToTimestamp: function (htmlDate, forceTimezone) {

			if (forceTimezone) {
				var momentDate = Moment(htmlDate, "YYYY-MM-DD").utc(2).unix();
			} else {
				var momentDate = Moment(htmlDate, "YYYY-MM-DD").unix();
			}

			return momentDate;
		},

		/**
   * @description convert timestamp to html date format
   * @param {type} timestamp
   * @returns {undefined}
   */
		timeStampToHTMLDate: function (timestamp) {
			return Moment(timestamp, "X").format('YYYY-MM-DD');
		},

		/**
   * 
   * @param {type} fileName
   * @param {type} folder
   * @returns {undefined}
   */
		loadFile: function (fileName, folder) {
			return URLBuilder(['ressources', folder, fileName], false);
		},

		/**
   * @description create a moment instance with a params for force timezone to Paris 
   * @param {type} date
   * @param {type} fromFormat
   * @param {type} forceTimezone
   * @returns {unresolved}
   */
		SellsyMoment: function (date, fromFormat, forceTimezone) {

			date = !date ? '' : date;
			var m;

			if (date === true) {
				m = new Moment().utc(2);
			} else {

				if (fromFormat) {
					m = new Moment(date, fromFormat);
				} else {
					m = new Moment(date);
				}
			}

			return forceTimezone ? m.utc(2) : m;
		},

		/**
   * 
   * @returns {undefined}
   */
		isIOS: function () {
			var iosVMatcher = new RegExp('OS ((\\d+_?){2,3})\\s', 'gi');
			var iosV = iosVMatcher.exec(navigator['userAgent']);

			if (iosV) {
				iosV = iosV[1].split("_");
				iosV.length = 2;
				iosV = iosV.join('.');
			}

			return iosV;
		},

		/**
   * Simply compares two string version values.
   *
   * Example:
   * versionCompare('1.1', '1.2') => -1
   * versionCompare('1.1', '1.1') =>  0
   * versionCompare('1.2', '1.1') =>  1
   * versionCompare('2.23.3', '2.22.3') => 1
   *
   * Returns:
   * -1 = left is LOWER than right
   *  0 = they are equal
   *  1 = left is GREATER = right is LOWER
   *  And FALSE if one of input versions are not valid
   *
   * @function
   * @param {String} left  Version #1
   * @param {String} right Version #2
   * @return {Integer|Boolean}
   * @author Alexey Bass (albass)
   * @since 2011-07-14
   */
		versionCompare: function (left, right) {
			if (typeof left + typeof right != 'stringstring') return false;

			var a = left.split('.'),
			    b = right.split('.'),
			    i = 0,
			    len = Math.max(a.length, b.length);

			for (; i < len; i++) {
				if (a[i] && !b[i] && parseInt(a[i]) > 0 || parseInt(a[i]) > parseInt(b[i])) {
					return 1;
				} else if (b[i] && !a[i] && parseInt(b[i]) > 0 || parseInt(a[i]) < parseInt(b[i])) {
					return -1;
				}
			}

			return 0;
		},

		/**
  *
   * @param email
   * @returns {boolean}
   */
		validateEmail: function (email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},

		/**
  *
   */
		isSellsyCorp: function (corpId) {

			switch (window.environment) {
				case 'dev':
					var sellsyCorps = ['2102', '727'];
					break;
				case 'preprod':
					var sellsyCorps = ['2', '133'];
					break;
				default:
					var sellsyCorps = ['1', '10292'];
					break;
			}

			return this.inArray(corpId, sellsyCorps);
		},

		/**
   * 
   * @param {type} arr1
   * @param {type} arr2
   * @returns {unresolved}
   */
		getArrayObjDiff: function (arr1, arr2) {

			var diff = _.difference(_.pluck(arr1, "id"), _.pluck(arr2, "id"));
			var result = _.filter(arr1, function (obj) {
				return diff.indexOf(obj.id) >= 0;
			});

			return result;
		}
	};
});
