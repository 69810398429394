/**
 *  @desc Emails Collection, extend core Collection
 */
define('models/collections/emailThreads',['models/core/collection', 'models/singles/emailThread', 'db/easy'], function (Collection, EmailThread, DB) {

    'use strict';

    return Collection.extend({

        model: EmailThread,
        storeName: 'emailThread',
        name: 'emailThreads',
        url: '/rest/emailBox/list',

        /**
         *
         * @returns {undefined}
         */
        constructor: function () {

            Collection.apply(this, arguments);

            this.offlineQueryArgs = ['linked_id', 'linked_type', 'folder'];
            this.orderBy = 'desc';
            this.orderArg = 'created';
        }

    });
});
