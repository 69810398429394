/**
 *	@desc Accounting Model , extend Core Model 
 */
define('models/singles/accounting',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'accounting',
		name: 'accounting',
		urlRoot: '/rest/accounting'

	});
});
