/**
 * @desc Agenda Overview, extend Page View
 * Child View : Core Overview
 */
define('views/agenda/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/event', 'models/singles/task', 'app', 'tools/buildURL', 'views/widgets/tabs', 'views/core/Popup', 'views/core/toastNotification', 'views/agenda/popupRecurringAlert', 'views/widgets/nativeActionSheet'], function (_, PageOverview, Overview, Event, Task, App, URLBuilder, Tabs, Popup, ToastNotification, PopupRecurringAlert, ActionSheet) {

	'use strict';

	return PageOverview.extend({

		events: _.extend({
			'click .btn-endtask': function () {

				var _this = this;

				if (App.useActionSheet) {

					new ActionSheet({
						title: App.polyglot.t('terminateTaskConfirmation'),
						buttons: [{
							title: _this.polyglot.t('terminateTask'),
							callback: function () {

								_this.setLoader(_this.$el);

								_this.agendaObj.setAsFinished().then(function () {

									new ToastNotification({
										id: 'toast-success',
										content: _this.toastMessages.task.taskTerminated,
										timeDuration: 3000
									});
									_this.goToPreviousPage();
								});
							}
						}],
						cancelLabel: App.polyglot.t('cancel')
					});
				} else {

					new Popup({

						id: "modal-remove",
						title: App.polyglot.t('terminateTaskConfirmation'),
						buttons: [{
							title: App.polyglot.t('terminateTask'),
							classes: "btn btn-primary",
							onclick: function () {

								_this.setLoader(_this.$el);

								_this.agendaObj.setAsFinished().then(function () {

									new ToastNotification({
										id: 'toast-success',
										content: _this.toastMessages.task.taskTerminated,
										timeDuration: 3000
									});
									_this.goToPreviousPage();
								});
							}
						}, {
							title: App.polyglot.t('cancel'),
							classes: "btn btn-secondary"
						}],
						mergeBtns: false,
						content: App.polyglot.t('confirmEndTask'),
						data: {},
						callback: ""

					});
				}
			}
		}, PageOverview.prototype.events),

		popupConfirmOptions: {},
		toastMessages: {
			event: {
				eventRemoved: App.polyglot.t("eventRemoved")
			},
			task: {
				taskRemoved: App.polyglot.t("taskRemoved"),
				taskTerminated: App.polyglot.t("taskTerminated")
			}
		},

		actionList: {

			modify: {
				fa: false,
				label: App.polyglot.t("update"),
				link: function () {

					if (App.isOnline) {
						this.goToForm();
					} else {
						this.notifWriteDisabled();
					}
				},
				aClass: 'button-with-network'
			},
			delete: {

				fa: false,
				label: App.polyglot.t("delete"),
				link: function () {

					if (App.isOnline) {

						if (this.agendaObj.get('isEventRecurring') == 'Y') {

							new PopupRecurringAlert({
								parentView: this,
								id: 'recurring-event',
								title: App.polyglot.t('checkRecurringEvent'),
								models: { event: this.agendaObj.toJSON() },
								isUpdate: false
							});
						} else {
							this.showPopupConfirm();
						}
					} else {
						this.notifWriteDisabled();
					}
				},
				aClass: 'button-with-network'

			}

		},

		/**
   * @desc initialize
   */
		initialize: function (router, id) {

			this.canEdit = true;
			this.canDelete = true;

			this.agendaObj = new Task({ id: id });

			PageOverview.prototype.initialize.apply(this, arguments);

			this.agendaObj.mustFetchLocal = true;
			this.models["agendaObj"] = this.agendaObj;
			this.template = "templates/agenda/overview.twig";
			this.overview = new Overview({ parentView: this }, this.router);

			this.subviews.push(this.overview);
			this.subviews.push(new Tabs(".header-overview", [{
				label: App.polyglot.t("details"),
				for: 'details',
				active: true
			}, {
				label: App.polyglot.t("actions"),
				for: 'actions'
			}]));

			this.shortenedPanel = {
				relatedType: 'task',
				relatedId: id,
				name: 'labelname',
				model: this.agendaObj,
				actions: {
					event: {
						display: false
					},
					task: {
						display: false
					},
					email: {
						display: false
					},
					comment: {
						fa: 'fa-comments',
						label: App.polyglot.t("annotations"),
						url: function () {
							return URLBuilder(["annotation", "form", 'task', id]);
						}
					},
					logHisto: {
						display: false
					},
					briefcase: {
						icon: 'icon-briefcase',
						label: App.polyglot.t('briefcase'),
						url: function () {
							return URLBuilder(['briefcase', 'list', "event", id]);
						}
					}
				}
			};

			this.buildShortenedPanel();
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageOverview.prototype.afterRender.apply(this, arguments);

			if (this.agendaObj.get('isOwner') === "N") {
				$('.deleteAction').remove();
			}

			if (this.agendaObj.get('editable') === "N") {
				$('.editAction').remove();
			}
		},

		/**
   * 
   * @returns {Boolean}
   */
		showPopupConfirm: function () {

			var callback = this.goToPreviousPage.bind(this);
			this.removeConfirm(this.agendaObj, "", this.$el, this.toastMessages.task.taskRemoved, callback);

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		goToForm: function () {
			this.router.navigate(URLBuilder(['calendar', "form", "task", this.agendaObj.id]), { trigger: true });
		}

	});
});
