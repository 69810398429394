/**
 * @desc Pwd Pref, extend Page View
 */
define('views/setting/pwdPref',['underscore', 'views/core/page', 'views/core/form', 'models/singles/staff', 'tools/buildURL', 'views/core/toastNotification', 'app'], function (_, Page, Form, Staff, URLBuilder, ToastNotification, App) {

	'use strict';

	return Page.extend({

		title: App.polyglot.t("pwdModification"),
		toastMessages: {
			pwdPref: {
				saveMsg: App.polyglot.t("pwdUpdated")
			}
		},
		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/pwd';
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);
			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			Page.prototype.afterRender.apply(this, arguments);
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var validation = Staff.validatePwd(dataForm);

			$('.submit-form', this.form.$el).removeAttr('disabled');

			var validation = Staff.validatePwd(dataForm);

			if (validation === '') {

				Staff.updatePwd(dataForm['password']).then(function () {

					new ToastNotification({
						id: 'toast-success',
						timeDuration: 4000,
						content: _this.toastMessages.pwdPref.saveMsg,
						classes: "col-xs-12"
					});

					_this.router.navigate(URLBuilder(["accountPref"]), { trigger: true });
				}, function (error) {

					switch (error.inerror) {

						case "oldPassword":

							_this.form.showValidationError("password", error.inerror, error.more);

							break;

						case "newPassword":

							break;

						case "newPasswordConf":

							break;

					}
				});
			} else {

				//re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(validation, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError);
						});
					}
				});
			}

			return false;
		}

	});
});
