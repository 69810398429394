/**
 *  @desc Estimates Collection, extend core Collection
 */
define('models/collections/estimates',['models/collections/documents', 'models/singles/estimate'], function (Documents, Estimate) {

	'use strict';

	return Documents.extend({

		model: Estimate,
		storeName: 'document',
		url: '/rest/document/list',
		doctype: 'estimate',
		availableSteps: ['draft', 'sent', 'read', 'accepted', 'refused', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled']

	});
});
