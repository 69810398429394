/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/event',['models/core/model', 'db/easy', 'app', 'moment', 'tools/core/utils', 'tools/ajax'], function (Model, DB, App, Moment, Utils, Ajax) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			type: 'event',
			label: 0,
			alerts: {},
			description: '',
			startdate: '',
			starthour: '',
			enddate: '',
			endhour: '',
			allDay: 'Y',
			isPrivate: 'Y',
			relatedtype: '',
			relatedid: '',
			labelname: ''
		},

		urlRoot: '/rest/agenda',
		name: 'event',
		storeName: 'agenda',

		/**
   * @description  change some value for be right with API
   * @returns {undefined}
   */
		formatToSave: function () {

			this.set('allDay', this.get('allDay') === true ? "Y" : "N");
			this.set('staffids', _.pluck(this.get('staffs'), 'id'));
			this.unset('staffs');

			if (!Number.isInteger(this.get('start'))) {
				this.set('start', Moment(this.get('start')).unix());
			}

			if (!Number.isInteger(this.get('end'))) {
				this.set('end', Moment(this.get('end')).unix());
			}

			this.set('label', this.get('label') + "");
			this.set('calendar', this.get('calendarId'));

			if (this.get('alerts') === "none") {
				this.unset('alerts');
			}

			return this;
		},

		/**
   * 
   * @returns {undefined}
   */
		validate: function (attributes) {

			var validationErrors = { event: {}, recurrence: {} };

			if (!Moment(attributes.startdate).isValid()) {
				validationErrors.event.startdate = this.computeError({ name: 'startdate', type: 'invalid' });
			}

			if (!Moment(attributes.enddate).isValid()) {
				validationErrors.event.enddate = this.computeError({ name: 'enddate', type: 'invalid' });
			}

			if (attributes.allDay == 'N' && _.isEmpty(attributes.starthour)) {
				validationErrors.event.starthour = this.computeError({ name: 'starthour', type: 'invalid' });
			}

			if (attributes.allDay == 'N' && _.isEmpty(attributes.endhour)) {
				validationErrors.event.endhour = this.computeError({ name: 'endhour', type: 'invalid' });;
			}

			//data check
			if (attributes.allDay == 'N') {

				var start = attributes.startdate;
				var end = attributes.enddate;

				if (!_.isEmpty(attributes.starthour) && !_.isEmpty(attributes.endhour)) {

					if (App.dateFormat.hourFormat == '12') {
						attributes.starthour = Moment(attributes.starthour, ["h:mm A"]).format("HH:mm");
						attributes.endhour = Moment(attributes.endhour, ["h:mm A"]).format("HH:mm");
					}

					start += ' ' + attributes.starthour;
					end += ' ' + attributes.endhour;
				}

				if (Moment(start).unix() > Moment(end).unix()) {

					if (-(Moment(end).unix() - Moment(start).unix()) >= 86400) {
						validationErrors.event.startdate = this.computeError({ name: 'startdate', type: 'invalid' });
						validationErrors.event.enddate = this.computeError({ name: 'enddate', type: 'invalid' });
					} else {
						validationErrors.event.starthour = this.computeError({ name: 'starthour', type: 'invalid' });
						validationErrors.event.endhour = this.computeError({ name: 'endhour', type: 'invalid' });
					}
				} else {
					this.attributes.start = Utils.SellsyMoment(attributes.startdate + ' ' + attributes.starthour, '', false).unix();
					this.attributes.end = Utils.SellsyMoment(attributes.enddate + ' ' + attributes.endhour, '', false).unix();
				}
			} else if (attributes.allDay == 'Y') {

				var start = Utils.SellsyMoment(attributes.startdate, App.HTMLDateFormat, true).unix();
				var end = Utils.SellsyMoment(attributes.enddate, App.HTMLDateFormat, true).unix();

				if (Moment(start).unix() > Moment(end).unix()) {

					validationErrors.event.startdate = this.computeError({ name: 'startdate', type: 'invalid' });
					validationErrors.event.enddate = this.computeError({ name: 'enddate', type: 'invalid' });
				} else {
					this.attributes.start = start;
					this.attributes.end = end;
				}
			} else {
				validationErrors.event.allDay = this.computeError({ name: 'allDay', type: 'invalid' });
			}

			if (attributes.isPrivate == '') {
				this.attributes.isPrivate = 'Y';
			}

			if (attributes.isPrivate != 'Y' && attributes.isPrivate != 'N') {
				validationErrors.event.isPrivate = this.computeError({ name: 'isPrivate', type: 'invalid' });
			}

			if (attributes.use_recurrence === "Y") {

				if (attributes.recurrence.frequency !== "yearly" && !attributes.recurrence.periodicity) {
					validationErrors.recurrence.periodicity = this.computeError({ name: "periodicity", type: "required" });
				}

				switch (attributes.recurrence.frequency) {

					case 'daily':
						break;

					case 'weekly':

						if (!attributes.recurrence.weekMode) {

							validationErrors.recurrence.weekMode = this.computeError({ name: "weekMode", type: "required" });
						} else {

							if (!Utils.inArray(Moment(start).format("d"), attributes.recurrence.weekMode)) {
								validationErrors.recurrence.weekMode = App.polyglot.t("firstOccurenceMustMatchWithEventStartDate");
							}
						}

						break;

					case 'monthly':

						break;

					case 'yearly':
						break;

				}

				switch (attributes.recurrence.endMode) {

					case 'occurences':

						if (!attributes.recurrence.endOccurences) {
							validationErrors.recurrence.endOccurences = this.computeError({ name: "endOccurences", type: 'required' });
						}

						break;

					case "date":

						if (!attributes.recurrence.endDateValue) {
							validationErrors.recurrence.endDateValue = this.computeError({ name: "endDateValue", type: 'required' });
						} else if (attributes.recurrence.endDateValue <= this.attributes.start) {
							validationErrors.recurrence.endDateValue = this.computeError({ name: "endDateValue", type: 'invalid' });
						}

						break;

					case 'never':

						break;
				}
			}

			if (_.toArray(validationErrors.event).length > 0 || _.toArray(validationErrors.recurrence).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   */
		formatAttendees: function () {
			var _this2 = this;

			var participants = [];
			var _this = this;

			_.each(this.get('attendees'), function (attendee) {

				if (_this2.get('origin') == "outlook") {

					//si le participant est un staff on l'enlève des participants
					var isStaff = _.find(App.staffs.toJSON(), function (staff) {

						if (attendee.emailAddress.address == staff.email) {

							//on check au passage si notre staff fait parti des participants
							if (staff.email === App.currentStaff.get('email')) {
								_this.set('staffIsParticipant', true);
								_this.set('staffResponse', attendee.status.response == "notResponded" ? "needsAction" : attendee.status.response);
							}

							return true;
						} else {
							return false;
						}
					});

					if (!isStaff) {
						participants.push({
							email: attendee.emailAddress.address,
							name: attendee.emailAddress.name,
							response: attendee.status.response == "notResponded" ? "needsAction" : attendee.status.response
						});
					}
				} else if (_this2.get('origin') === "google") {

					var isStaff = _.find(App.staffs.toJSON(), function (staff) {

						if (attendee.email == staff.email) {

							//on check au passage si notre staff fait parti des participants
							if (staff.email === App.currentStaff.get('email')) {
								_this.set('staffIsParticipant', true);
								_this.set('staffResponse', attendee.responseStatus);
							}

							return true;
						} else {
							return false;
						}
					});

					if (!isStaff) {
						participants.push({
							email: attendee.email,
							name: attendee.displayName,
							response: attendee.responseStatus
						});
					}
				}
			});

			this.set('participants', participants);
		},

		/**
   * 
   * @returns {Promise}
   */
		responseParticipate: function (response, recurringEventId, typeModifyRecurring) {
			var _this3 = this;

			return new Promise(function (resolve, reject) {
				Ajax({

					url: "/rest/agenda/responseParticipate",
					method: 'POST',
					data: {
						'response': response,
						'id': _this3.get('id'),
						'calendar': _this3.get('calendarId'),
						'recurringEventId': recurringEventId,
						'typeModifyRecurring': typeModifyRecurring
					},
					success: function (data) {
						if (data.error) {
							reject(data.error);
						} else {
							resolve(data);
						}
					},
					error: function (error) {
						reject(error);
					}

				});
			});
		}

	}, {
		/**
   * 
   * @returns {undefined}
   */
		getPreferences: function () {

			return new Promise(function (resolve, reject) {
				Ajax({

					url: "/rest/agenda/prefs",
					method: 'GET',
					success: function (data) {
						if (data.error) {
							reject(data.error);
						} else {
							resolve(data);
						}
					},

					error: function (error) {
						reject(error);
					}

				});
			});
		},

		/**
   * 
   * @param {type} origin
   * @returns {undefined}
   */
		getRelatedTypeByOrigin: function (origin) {

			switch (origin) {

				case 'sellsy':

					return 'task';
					break;

				case 'outlook':

					return 'outlookEvent';
					break;
				case 'google':

					return 'googleEvent';
					break;
			}
		}
	});
});
