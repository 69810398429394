/**
 * @desc Supplier router, extend Core Router
 */
define('routers/supplier',['backbone', 'underscore', 'routers/core/router', 'app'], function (Backbone, _, CoreRouter, App) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "contacts",

		checkOnline: {
			form: true
		},

		routes: {
			'supplier/list(/)(:comefrom)': 'listing',
			'supplier/overview/:id': 'overview',
			'supplier/form(/)(:id)(/)(:comefrom)': 'form'
		},

		/**
   * 
   */
		checkBefore: {
			form: function () {
				return App.permission.can("createsupplier");
			}
		},

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		listing: function (comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/supplier/list', 'models/collections/suppliers'], function (require, SupplierList, Suppliers) {

					var links = App.sellsyEasy.thirdRouter.getAvailableActions();
					var supplier = new Suppliers();
					var view = new SupplierList(_this, supplier, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		form: function (thirdId, comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			thirdId = thirdId ? thirdId : 0;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/supplier/form', 'models/singles/supplier'], function (require, FormSupplier, Supplier) {

					var supplier = new Supplier({ id: thirdId });
					var view = new FormSupplier(_this, thirdId, supplier, comefrom);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} thirdId
   * @returns {undefined}
   */
		overview: function (thirdId) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/supplier/overview', 'models/singles/supplier'], function (require, SupplierOverview, Supplier) {

					var supplier = new Supplier({ id: thirdId });
					var view = new SupplierOverview(_this, thirdId, supplier);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		}

	});
});
