/**
 * @desc Agenda Form, extend Page View
 * Child View : Core Form
 */
define('views/agenda/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'tools/buildURL', 'models/singles/event', 'models/singles/task', 'models/collections/agendaLabels', 'models/collections/staffs', 'models/collections/thirds', 'models/collections/suppliers', 'models/collections/prospects', 'models/collections/peoples', 'models/collections/opportunities', 'app', 'tinyMCE', 'tools/core/utils', 'views/agenda/popupRecurringAlert', 'moment', 'selectize', 'libs/plugins/placeholder/plugin'], function (Backbone, _, Page, Form, URLBuilder, Event, Task, agendaLabels, staffs, Thirds, Suppliers, Prospects, Peoples, Opportunities, App, TinyMCE, Utils, PopupRecurringAlert, Moment) {

	'use strict';

	return Page.extend({

		events: _.extend({
			"change input[name='event[allDay]']": "showHourInput",
			'change .linkedtype': 'changeLinkedType',
			"change	input[name='event[use_recurrence]']": "toggleRecurrence",
			"change	select[name='recurrence[frequency]']": "toggleRecurrenceFrequency",
			"change	select[name='recurrence[endMode]']": "toggleRecurrenceEndMode"

			// "change input[name='event[startdate]'], input[name='event[enddate]']" : "followDate",


		}, Page.prototype.events),

		title: App.polyglot.t('addtaskevent'),
		form: {},
		staffCollection: {},
		event: {},
		toastMessages: {
			eventSaved: App.polyglot.t('eventRecorded'),
			eventUpdated: App.polyglot.t('eventModified'),
			eventDeleted: App.polyglot.t('eventDeleted'),
			taskSaved: App.polyglot.t('taskRecorded'),
			taskUpdated: App.polyglot.t('taskModified'),
			taskDeleted: App.polyglot.t('taskDeleted')
		},
		actionList: {},
		classes: "agendaForm",

		/**
   * 
   * @param {Router} router
   * @param {int} thirdId
   */
		initialize: function (router, taskOrEvent, params, id) {

			this.taskOrEvent = taskOrEvent;
			var _this = this;

			if (params.relatedtype && params.relatedid) {

				if ($.inArray(params.relatedtype, App.availableThirds) !== -1) {
					params.relatedtype = 'third';
				}

				this.relatedtype = params.relatedtype;
				this.relatedid = params.relatedid;

				if (taskOrEvent == 'event') {
					this.listingHash = URLBuilder(['agenda', 'event', params.relatedtype, params.relatedid]);
				} else {
					this.listingHash = URLBuilder(['calendar', 'list', 'task', 'today', params.relatedtype, params.relatedid]);
				}
			} else {

				if (taskOrEvent == 'event') {
					this.listingHash = URLBuilder(['agenda', 'event']);
				} else {
					this.listingHash = URLBuilder(['calendar', 'list', taskOrEvent, '']);
				}
			}

			Page.prototype.initialize.apply(this, arguments);

			if (taskOrEvent == 'event') {

				this.modelToSave = new Event({ id: id });

				if (this.modelToSave.isNew()) {
					this.title = App.polyglot.t('addEvent');
				} else {
					this.modelToSave.mustFetchLocal = true;
					this.title = App.polyglot.t('modifyEvent');
				}

				this.modelToSave.set({
					'allDay': App.currentCorp.get('preferences').agendaAllDay
				});

				this.timepickerStep = 15;
			} else {

				this.timepickerStep = 5;

				this.modelToSave = new Task({ id: id });

				if (this.modelToSave.isNew()) {
					this.title = App.polyglot.t('addTask');
				} else {
					this.title = App.polyglot.t('modifyTask');
				}
			}

			this.modelToSave.set({
				'isPrivate': App.currentCorp.get('preferences').agendaIsPrivate
			});

			// for create task/event from linked. Define the screen callback after creation
			this.options = {

				extraData: {

					sender: function () {

						return new Promise(function (resolve, reject) {

							if (params.relatedtype && params.relatedid) {
								params.link = App.GoToOverviewByRelated(params.relatedtype, params.relatedid);
								resolve(params);
							} else {
								resolve('');
							}
						});
					},
					prefs: function () {
						return new Promise(function (resolve, reject) {
							App.getPreferencesByPlugin('agenda').then(function (prefs) {
								resolve(prefs);
							}, function (err) {
								reject(err);
							});
						});
					}

				}

			};

			this.collection = new agendaLabels();
			this.collections[this.collection.name] = this.collection;

			this.staffCollection = new staffs();
			this.collections[this.staffCollection.name] = this.staffCollection;

			this.modelToSave.mustFetchLocal = !this.modelToSave.isNew();
			this.models[this.modelToSave.name] = this.modelToSave;

			this.suppliers = new Suppliers();
			this.prospects = new Prospects();
			this.thirds = new Thirds();
			this.peoples = new Peoples();
			this.opportunities = new Opportunities();
			this.template = "templates/agenda/form.twig";
			this.router = router;
			this.form = new Form({ parentView: this });

			this.subviews.push(this.form);
			this.render();
			this.listenTo(this.form, 'dataSend', this.formAction);
		},

		/**
   * @description : triggered when dom is ready and all models data are fetched
   */
		afterRender: function () {

			this.prefs = App.preferences['agenda'];
			var _this = this;

			if (this.modelToSave.get('isRecurring') === 'Y') {
				var recurringParams = this.modelToSave.get('recurring_params');
				this.isRecurring = true;
			}

			this.selectizeObjects = [{
				domElt: $(".selectize-alerts", this.$el),
				options: {
					0: {
						type: "instant",
						label: App.polyglot.t('instant')
					},
					1: {
						type: "prevHour",
						label: App.polyglot.t('prevHour')
					},
					2: {
						type: "prevDay",
						label: App.polyglot.t('prevDay')
					},
					3: {
						type: "prevWeek",
						label: App.polyglot.t('prevWeek')
					},
					4: {
						type: "prevMonth",
						label: App.polyglot.t('prevMonth')
					}
				},

				create: false,
				labelField: 'label',
				valueField: 'type',
				onInitialize: function () {
					$('input', '.selectize-alerts').attr('readonly', true);
				}
			}, {
				domElt: $(".weekMode_selectize", this.$el),
				options: [{
					value: 1,
					label: App.polyglot.t('monday')
				}, {
					value: 2,
					label: App.polyglot.t('tuesday')
				}, {
					value: 3,
					label: App.polyglot.t('wednesday')
				}, {
					value: 4,
					label: App.polyglot.t('thursday')
				}, {
					value: 5,
					label: App.polyglot.t('friday')
				}, {
					value: 6,
					label: App.polyglot.t('saturday')
				}, {
					value: 0,
					label: App.polyglot.t('sunday')
				}],

				defaultValues: recurringParams ? recurringParams.recur_weekMode : '',
				create: false,
				labelField: 'label',
				valueField: 'value'
			}, {
				domElt: $(".selectize-staffs", this.$el),
				options: this.staffCollection,
				create: false,
				labelField: 'fullName',
				valueField: 'id',
				searchField: ['fullName'],
				onItemAdd: this.selectizeOnItemAdd,
				onItemRemove: this.selectizeOnItemRemove,
				defaultValues: this.modelToSave.get('staffs'),
				render: 'staff'
			}];

			if (this.taskOrEvent === "event" && Utils.inArray(this.prefs.provider, ["outlook", "google"])) {

				this.contactsList = [];

				this.selectizeObjects.push({
					domElt: $("input[name='event[participants]']", this.$el),
					create: true,
					searchField: ['email', 'name'],
					valueField: 'id',
					labelField: 'name',
					options: [],
					maxItems: 10,
					maxOptions: 10,
					render: 'email',
					load: this.loadContacts.bind(this)
				});
			}

			if (App.permission.level('clientlevel') || App.permission.level('prospectlevel') || App.permission.can("prospection_access")) {

				if (!this.relatedtype && !this.relatedid) {

					var canUseLinked = true;

					this.selectizeObjects.push({
						domElt: $(".selectize-linked", this.$el),
						create: false,
						labelField: 'name',
						valueField: 'id',
						maxItems: '1',
						searchField: ['name', 'ident'],
						render: 'third',
						load: this.getSelectizeLoader('third').bind(this)
					});
				};
			}

			//set min/max value on hour input
			var day = Moment().format('YYYY-MM-DD');

			this.minHour = _this.prefs.startTime;
			this.maxHour = _this.prefs.endTime != "00:00:00" ? _this.prefs.endTime : "23:45:00";

			this.minHour = Moment(day + " " + this.minHour, 'YYYY-MM-DD HH:mm:ss');
			this.maxHour = Moment(day + " " + this.maxHour, 'YYYY-MM-DD HH:mm:ss');

			Page.prototype.afterRender.apply(this, arguments);

			if (canUseLinked) {

				this.$.linkedSelectize = $('.selectize-linked', this.$el);
				this.linkedSelectizeInstance = this.$.linkedSelectize[0].selectize;

				if (!this.modelToSave.isNew() || this.relatedtype && this.relatedid) {
					$('.additional-linked', this.$el).remove();
				}

				$('.linkedtype', this.$el).trigger("change");
			}

			if (this.modelToSave.isNew()) {

				//pour outlook et google , on met le staff courant en collab associé
				if (this.prefs.provider !== "sellsy") {

					var staffSelectize = $(".selectize-staffs", this.$el)[0].selectize;
					staffSelectize.addItem(App.currentStaff.id);
				}

				$('input[name="' + this.modelToSave.get('type') + '[starthour]"]').data("DateTimePicker").date(this.minHour);

				if ($('input[name="' + this.modelToSave.get('type') + '[endhour]"]').data("DateTimePicker")) {
					//end hour is just for event
					$('input[name="' + this.modelToSave.get('type') + '[endhour]"]').data("DateTimePicker").date(this.minHour.clone().add(1, "h"));
				}
			} else {

				if (!this.modelToSave.get('allDay')) {

					$('input[name="' + this.modelToSave.get('type') + '[starthour]"]').data("DateTimePicker").date(this.modelToSave.get('startHour'));

					if ($('input[name="' + this.modelToSave.get('type') + '[endhour]"]').data("DateTimePicker")) {
						//end hour is just for event
						$('input[name="' + this.modelToSave.get('type') + '[endhour]"]').data("DateTimePicker").date(this.modelToSave.get('endHour'));
					}
				} else {
					$('input[name="event[allDay]"]').prop("checked", true).trigger('change');
				}

				if (this.taskOrEvent === "event") {

					if (this.prefs.provider !== "sellsy") {

						$('select[name="event[calendar]"]').val(this.modelToSave.get('calendarId'));

						//set participants
						var participantsSelectize = $('input[name="event[participants]"]')[0].selectize;
						this.modelToSave.formatAttendees();

						_.each(this.modelToSave.get('participants'), function (participant) {
							participantsSelectize.createItem(participant.email);
						});
					}

					if (this.prefs.provider === "outlook") {
						$('[name="event[alerts]"]').val(this.modelToSave.get('alerts'));
					} else {

						if (this.prefs['provider'] === 'google') {
							_.each(this.modelToSave.get('alerts')[0], function (alert) {
								$('[name="event[alerts]"]')[0].selectize.addItem(alert.type);
							});
						} else {
							_.each(this.modelToSave.get('alerts'), function (alert) {
								$('[name="event[alerts]"]')[0].selectize.addItem(alert);
							});
						}
					}
				}
			}

			$("input[name='event[starthour]']").on('dp.change', function (e) {
				var momentStartHour = $(e.currentTarget).data("DateTimePicker").date();
				$("input[name='event[endhour]']").data("DateTimePicker").date(momentStartHour.clone().add(1, 'h'));
			});

			TinyMCE.init({
				selector: 'textarea[name="' + _this.taskOrEvent + '[description]"]',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize placeholder",
				menubar: false,
				statusbar: false,
				autoresize_max_height: '100px'
			});

			if (this.modelToSave.get('isRecurring') === 'Y') {

				$('input[name="event[use_recurrence]"]').prop('checked', true).trigger('change');

				$('[name="recurrence[frequency]"]').val(recurringParams.recur_frequency).trigger('change');
				$('[name="recurrence[monthMode]"]').val(recurringParams.recur_monthMode).trigger('change');
				$('[name="recurrence[periodicity]"]').val(recurringParams.recur_periodicity);

				$('[name="recurrence[endMode]"]').val(recurringParams.recur_endMode).trigger('change');

				if (recurringParams.recur_endMode === 'date') {
					var endDate = Moment(recurringParams.recur_endDate, App.dateFormat.momentFormat).format(App.HTMLDateFormat);
					$('[name="recurrence[endDateValue]"]').val(endDate);
				} else {
					$('[name="recurrence[endOccurences]"]').val(recurringParams.recur_endOccurences);
				}
			}
		},

		/**
   * @description triggered when we add a linked staff, add a row for choose if new staff can update evnet or not
   * @param {type} value
   * @param {type} item
   * @returns {undefined}
   */
		selectizeOnItemAdd: function (value, item, input) {

			if (!this.taskOrEvent === 'event' || this.prefs.syncagenda === "N") {
				var staffs = this.modelToSave.get('staffs');
				var canEdit = 'Y';

				$.each(staffs, function (index, elem) {
					if (this.id == value) {
						canEdit = this.canedit;
					}
				});
				var confi = this.form.$el.find('.confidentiality-container');
				var fieldName = this.modelToSave.name;
				var selectedUser = $(item[0]).text().slice(0, -1);

				canEdit = canEdit == 'Y' ? 'CHECKED' : '';

				var html = '<div class="input-group canEdit canEdit_' + value + '">';
				html += '<div><i class="fa fa-user"></i> ' + selectedUser + '</div>';
				html += '<div><div class="checkbox" style="padding-top: 0px !important;"><label>' + App.polyglot.t('allowModification');
				html += '<input type="checkbox" name="' + fieldName + '[canEdit_' + value + ']" value="Y" style="margin-left:15px; padding:0;" ' + canEdit + '/> ';
				html += '</label></div></div></div>';

				confi.append(html);
			}
		},

		/**
   * 
   * @param {type} value
   * @param {type} item
   * @returns {undefined}
   */
		selectizeOnItemRemove: function (value, item) {
			this.form.$el.find('.confidentiality-container').find('.canEdit_' + value).remove();
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		showHourInput: function (e) {

			var e_elem = this.form.$el.find(".startHourContainer");

			if (e_elem.hasClass('hidden')) {
				this.form.$el.find(".startHourContainer, .endHourContainer").removeClass('hidden');
			} else {
				this.form.$el.find(".startHourContainer, .endHourContainer").addClass('hidden');
			}
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeLinkedType: function (e) {

			var $currentTarget = $(e.currentTarget);
			var _this = this;

			switch ($currentTarget.val()) {

				case 'client':
				case 'third':

					this.selectizeObjects = [{
						domElt: $(".selectize-linked", this.$el),
						options: this.thirds,
						create: false,
						labelField: 'name',
						valueField: 'id',
						searchField: ['name', 'ident'],
						maxItems: 1,
						render: 'third',
						load: this.getSelectizeLoader('third').bind(this),
						onInitialize: function () {
							this.linkedSelectizeInstance = this.$.linkedSelectize[0].selectize;
						}
					}];

					this.linkedSelectizeInstance.destroy();
					this.initSelectize();
					//					

					break;

				case 'opportunity':

					this.selectizeObjects = [{
						domElt: $(".selectize-linked", this.$el),
						options: this.opportunities,
						create: false,
						labelField: 'ident',
						valueField: 'id',
						searchField: ['name', 'ident'],
						maxItems: 1,
						load: this.getSelectizeLoader('opportunity').bind(this),
						onInitialize: function () {
							this.linkedSelectizeInstance = this.$.linkedSelectize[0].selectize;
						}
					}];

					this.linkedSelectizeInstance.destroy();
					this.initSelectize();

					break;

				case 'prospect':

					this.selectizeObjects = [{
						domElt: $(".selectize-linked", this.$el),
						options: this.prospects,
						create: false,
						labelField: 'name',
						valueField: 'id',
						searchField: ['name', 'ident'],
						render: 'prospect',
						load: this.getSelectizeLoader('prospect').bind(this),
						maxItems: 1,
						onInitialize: function () {
							this.linkedSelectizeInstance = this.$.linkedSelectize[0].selectize;
						}
					}];

					this.linkedSelectizeInstance.destroy();
					this.initSelectize();

					break;

			}
		},

		/**
   * 
   */
		toggleRecurrence: function (e) {
			$('#recurringParams').toggleClass("hidden");
		},

		/**
   * 
   */
		toggleRecurrenceFrequency: function (e) {

			var currentValue = $(e.currentTarget).val();

			$('.recurring_frequency:not(#' + currentValue + ')').addClass('hidden');
			$("input", '.recurring_frequency:not(#' + currentValue + ')').attr('data-catchable', 'N');
			$('#' + currentValue).removeClass('hidden').find('input').removeAttr('data-catchable');
		},

		/**
   * 
   */
		toggleRecurrenceEndMode: function (e) {

			var currentValue = $(e.currentTarget).val();

			$('.recurring_endMode').addClass("hidden");
			$('input', '.recurring_endMode').attr('data-catchable', 'Y');
			$('#' + currentValue).removeClass('hidden').find('input').removeAttr('data-catchable');
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		followDate: function (e) {
			var dateVal = $(e.currentTarget).val();
			$('input[type=date]').val(dateVal);
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		followHour: function (e) {
			var startHourValue = $(e.currentTarget).val();
		},

		loadContacts: function (query, callback) {

			var _this = this;
			var promises = [];

			return new Promise(function (resolve, reject) {

				if (App.permission.level('peoplelevel') || App.permission.level('supplierlevel') || App.permission.level('prospectlevel') || App.permission.level('clientlevel')) {

					var peoples = new Peoples();

					promises.push(peoples.fetch({

						method: 'POST',
						data: {
							search: {
								contains: query
							}
						}

					}));
				}

				Promise.all(promises).then(function () {

					peoples.each(function (people) {

						if (people.get('email')) {
							_this.peoples.add(people);
						}
					});

					resolve(_this.peoples.toJSON());
				}, function (error) {
					reject(error);
				});
			});
		},

		/**
  *
   * @returns {boolean}
   */
		formAction: function () {
			var _this2 = this;

			var dataForm = this.form.dataForm;
			var _this = this;
			var key = dataForm.eventOrTask[0];

			var originalEvent = this.modelToSave.clone();

			_.each(dataForm[key], function (attribute, index, model) {
				var selector = '[name = "' + _this.modelToSave.name + '[' + index + ']"]';
				var $selector = $(selector);
				$selector.data("oldvalue", attribute);
			});

			if (!dataForm[key].relatedid) {
				delete dataForm[key].relatedtype;
			}

			dataForm[key]['allDay'] = !_.isUndefined(dataForm[key]['allDay']) ? dataForm[key]['allDay'] : 'N';
			dataForm[key]['isPrivate'] = !_.isUndefined(dataForm[key]['isPrivate']) ? dataForm[key]['isPrivate'] : 'N';
			dataForm[key].selectize = '';

			if (this.taskOrEvent === 'event' && Utils.inArray(this.prefs.provider, ['outlook', 'google'])) {

				dataForm['event']['id'] = $('input[name="event[id]"]').val();

				//build participant
				if (dataForm["event"]['participants']) {

					dataForm["event"]['participants'] = dataForm["event"]['participants'].split(',');
					var formattedParticipants = [];

					_.each(dataForm['event']['participants'], function (participant) {

						if (parseInt(participant) == participant) {

							var participantFormatted = {
								'id': participant
							};

							formattedParticipants.push(participantFormatted);
						} else {

							var participantFormatted = {
								"email": participant
							};
							//in update we could take name in attendees
							if (!_this2.modelToSave.isNew()) {

								_.each(_this2.modelToSave.get('participants'), function (attendee) {
									if (attendee.email === participant && attendee.email !== attendee.name) {
										participantFormatted['name'] = attendee.name;
									}
								});
							}

							formattedParticipants.push(participantFormatted);
						}
					});
					dataForm["event"]['participants'] = formattedParticipants;
				}
			}

			dataForm[key]['canEdit'] = {};

			_.each(this.modelToSave.get('staffs'), function (staff) {

				if (staff.canedit && staff.canedit === "Y") {
					dataForm[key]["canEdit"][staff.id] = "Y";
				}
			});

			this.modelToSave.set(dataForm[key], { silent: true });

			if (dataForm[key]['use_recurrence'] === "Y") {

				if (!_.isEmpty(dataForm['recurrence']['weekMode'])) {
					dataForm['recurrence']['weekMode'] = dataForm['recurrence']['weekMode'].split(',');
				}

				if (dataForm['recurrence']['endMode'] === 'date') {
					dataForm['recurrence']['endDateValue'] = Moment(dataForm['recurrence']['endDateValue'], App.HTMLDateFormat).unix();
				}

				this.modelToSave.set("recurrence", dataForm['recurrence']);
			}

			if (this.modelToSave.isValid()) {

				if (!this.modelToSave.isNew() && this.isRecurring) {

					new PopupRecurringAlert({
						parentView: this,
						id: 'recurring-event',
						title: App.polyglot.t('checkRecurringEvent'),
						models: {
							event: this.modelToSave.toJSON(), //event udpate by form data
							originalEvent: originalEvent, //event obj before update by form data
							prefs: this.prefs
						},
						isUpdate: true,
						listingHash: this.listingHash,
						callback: function () {
							$('.submit-form', _this.form.$el).prop('disabled', false);
						}
					});
				} else {

					var toastMsg = this.modelToSave.isNew() ? this.toastMessages[key + 'Saved'] : this.toastMessages[key + 'Updated'];
					var isEvent = this.modelToSave.get('type') === 'event';
					var options = isEvent ? { 'savelocal': false } : {};

					if (this.prefs.syncagenda === "N") {
						options['fetchAfterSave'] = true;
					}

					this.modelToSave.save(options, { validate: false }).then(function () {

						//invalidate cache control for eventListing for update listing with new event
						if (!isEvent) {
							delete App.cacheControl[_this.router.history[0]];
						} else {
							delete App.cacheControl[_this.listingHash];
						}

						_this.goToPreviousPage();
						_this.form.trigger('successful', toastMsg);
					}, function (response) {

						if (response.error.code === "E_OBJ_NOT_LOADABLE") {

							_this.resourceNotAvailable();

							_this.modelToSave.destroyLocal().then(function () {
								_this.router.navigate(_this.listingHash, { trigger: true, replace: true });
							});
						}
					});
				}
			} else {

				//Make the input invalid in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});

				// re activate submit button
				$('.submit-form', this.form.$el).prop('disabled', false);
			}

			return false;
		}

	});
});
