/**
 * @description SearchBar View
 */
define('views/core/searchBar',['underscore', 'views/core/view', 'app'], function (_, View, App) {

	'use strict';

	return View.extend({

		events: {
			"keyup input": 'search',
			"submit": function () {
				return false;
			}
		},

		"id": "wrapper-search",

		/**
   * 
   * @param {String} template
   * @param {View} listing
   */
		initialize: function (template, listing, placeholderSearchBar) {

			if (template !== undefined && listing !== undefined) {

				this.template = template;
				this.listing = listing;
				this.placeholder = placeholderSearchBar;

				this.listing.parentView.$el.css('padding-top', '50px');

				this.render();
			}
		},

		renderCallback: function () {

			App.header.$el.append(this.$el);
			$('#search-bar', App.header.$el).prop('placeholder', this.placeholder);
		},

		/**
   *  @desc Search the input value in all the listing
   */
		search: _.debounce(function (event) {

			var searchPattern = event.currentTarget.value;
			this.doSearchQuery(searchPattern);
		}, 800),

		/**
   * 
   * @returns {undefined}
   */
		doSearchQuery: function (value) {

			var _this = this;
			var search = {};

			search[_this.listing.collection.searchFieldName] = value;

			_this.listing.collection.search = _.extend(!_.isUndefined(_this.listing.collection.search) ? _this.listing.collection.search : {}, search);

			_this.listing.collection.pagination.pagenum = 1;
			_this.setLoader($('.main-container', _this.listing.$el), 0.6);
			_this.listing.collection.reset();
			_this.listing.render({ add: false }).then(function () {
				_this.listing.parentView.afterRender();
			});
		}

	});
});
