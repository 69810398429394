/**
 * @desc tax Pref, extend Page View
 */
define('views/setting/tvaPref',['underscore', 'views/core/page', 'views/core/form', 'models/collections/taxes', 'app', 'views/core/accordion'], function (_, Page, Form, Taxes, App, Accordion) {

	'use strict';

	return Page.extend({

		title: 'TVA',
		events: {
			'click .btn-TaxLine': 'addTaxLine',
			'click .delete-item': 'showRemoveConfirm',
			'submit form': 'formAction'
		},
		toastMessages: {
			tax: {
				removeMsg: App.polyglot.t("taxRemoved"),
				saveMsg: App.polyglot.t("taxesSaved")
			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/tax';

			this.taxes = new Taxes();
			this.taxes.XHRData = { includeEcoTax: 'Y' };
			this.collections[this.taxes.name] = this.taxes;

			this.title = App.polyglot.t("taxes");
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			this.required4Accordion = {
				'panel-ecotax': $('.tax-line', '#panel-ecotax').length > 0
			};

			//init accordion
			this.accordion = new Accordion({ scrollable: $('.form', this.$el), required: this.required4Accordion });

			//disable inputs isWritabled=N
			$('input[data-is-writeabled="N"]').each(function () {
				$(this).prop('disabled', true);
			});

			//events declaration
			this.listenTo(this.form, 'dataSend', this.formAction);
			this.form.$el.on('submit', this.buildData);
		},

		/**
   * @description insert a new tva line in the dom
   * @returns {undefined}
   */
		addTaxLine: function () {

			//take a tax line
			var $line = $("#new-tax-line").clone();
			var $inputVal = $('.tax-value', $line);
			var $inputId = $('.tax-id', $line);
			var $inputName = $('.tax-name', $line);

			//index of new line
			var index = $(".tax-line", "#taxesInfos").length - 1; // -1 for hidden line

			//inputVal name
			$inputVal.attr('name', "tax" + index + "[taxe][value]").removeAttr('data-catchable');
			$inputName.attr('name', "tax" + index + "[taxe][name]").removeAttr('data-catchable');

			//input id name
			$inputId.attr('name', "tax" + index + "[id]");

			//insert new line in DOM
			$("#taxesInfos").append($line);

			//show the line
			$line.removeAttr('id');
		},

		showRemoveConfirm: function (e) {

			var $currentTarget = $(e.currentTarget);
			var $id = $currentTarget.closest(".box-shadow").find('.tax-id').val();
			var $line = $currentTarget.closest('.tax-line');

			//if it's a new line just delete the line else show confirm for delete line on server too 
			if (!$id) {
				$line.remove();
			} else {

				var _this = this;

				// executed after suppression
				var callback = function () {
					$('.inprogress-mask').remove();
					$line.remove();
				};

				this.tax = this.taxes.get($id);

				this.removeConfirm(this.tax, "Suppression de la TVA", this.$el, this.toastMessages.tax.removeMsg, callback);
			}

			return false;
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.sendDataFormV2();
			var _this = this;

			var collection2Save = new Taxes();

			_.each(dataForm, function (tax, index) {

				if (tax.taxe.isWriteabled !== 'N' && (tax.taxe.value || tax.taxe.value === 0)) {
					collection2Save.add(tax);
				}
			});

			if (collection2Save.length > 0) {

				//loader animation
				this.$el.append(_this.formActionSetLoader());

				collection2Save.save().then(function () {

					collection2Save.fetch({
						method: 'POST'
					}).then(function () {

						//end animation + toastNotif success
						$(".inprogress-mask", _this.$el).remove();
						_this.form.trigger('successful', _this.toastMessages.tax.saveMsg);
						_this.goToPreviousPage();
					});
				});
			} else {
				_this.goToPreviousPage();
			}

			return false;
		}

	});
});
