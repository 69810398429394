/**
 *  @desc Services Collection, extend Products Collection
 */
define('models/collections/services',['models/collections/products', 'models/singles/service', 'db/easy'], function (Products, Service, DB) {

	'use strict';

	return Products.extend({

		model: Service,
		storeName: 'product',
		name: 'products',
		url: '/rest/product/list',
		fields: ['name', 'unitAmount'],
		type: 'service'

	});
});
