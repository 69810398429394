/**
 *	@desc Packaging Model , extend Core Model 
 */
define('models/singles/packaging',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			unitAmountIsTaxesFree: "N"
		},

		storeName: 'packaging',
		name: 'packaging',
		urlRoot: '/rest/packaging'

	});
});
