/**
* @desc Third Overview, extend PageOverview View
* Child View : Core Overview
*/

define('views/third/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/address', 'models/singles/people', 'views/core/popupAction', 'tools/buildURL', 'tools/materialEffect', 'app', 'views/core/toastNotification', 'models/collections/smarttags', 'tools/core/utils', 'views/widgets/tabs', 'views/core/accordion', 'libs/bootstrap'], function (_, PageOverview, Overview, Address, People, PopupAction, URLBuilder, initMaterialEffect, App, Toast, Smarttags, Utils, Tabs, Accordion) {

	'use strict';

	return PageOverview.extend({

		events: _.extend({
			'click .btn-create-addr': 'addressForm',
			'click .btn-create-contact': 'contactForm',
			'click #linkedAddresses .fa-envelope-o': 'changeMainDelivAddr',
			'click #linkedAddresses .fa-star-o': 'changeMainAddr',
			'click #linkedContacts .fa-star-o': 'changeMainContact',
			'click .linktoform': 'goToThirdForm'
		}, PageOverview.prototype.events),

		template: 'templates/third/overview.twig',
		title: "",
		overview: {},
		linked: 'third',
		toastMessages: {
			contact: {
				contactUpdated: App.polyglot.t("contactUpdated"),
				contactSaved: App.polyglot.t("contactSaved"),
				contactDeleted: App.polyglot.t("contactDeleted"),
				mainContactChanged: App.polyglot.t("mainContactChanged")
			},
			address: {
				addressUpdated: App.polyglot.t("addressUpdated"),
				addressSaved: App.polyglot.t("addressSaved"),
				addressDeleted: App.polyglot.t("addressDeleted"),
				mainDelivAddressChanged: App.polyglot.t("mainDelivAddressChanged"),
				mainAddressChanged: App.polyglot.t("mainAddressChanged")
			},
			third: {
				thirdUpdated: App.polyglot.t('clientUpdated'),
				thirdSaved: App.polyglot.t("clientSaved"),
				thirdDeleted: App.polyglot.t("clientDeleted")
			}
		},

		customFieldFilter: {
			useOn: 'client',
			linkedtype: 'client'
		},

		/**
   * 
   * @param {Router} router
   * @param {int} thirdId
   */
		initialize: function (router, thirdId, third) {

			var relationType = this.linked === "third" ? "client" : this.linked;

			var _this = this;
			this.modelID = parseInt(thirdId, 10);
			this.canEdit = App.permission.can("edit" + relationType);
			this.canDelete = App.permission.can("delete" + relationType);

			//			// customFields filter
			this.customFieldFilter.linkedid = this.modelID;

			this.third = this.model = third;
			this.smartTagsFilter = Smarttags.buildFilterByLinked(this.linked, this.modelID);

			PageOverview.prototype.initialize.apply(this, arguments);

			this.shortenedPanel = {
				relatedType: this.linked,
				relatedId: thirdId,
				name: 'name',
				model: third,
				actions: {
					'logHisto': {
						url: this.linked === 'third' ? URLBuilder(["log", "list", 'client', thirdId]) : undefined
					},
					task: {
						icon: 'icon-task',
						label: App.polyglot.t("tasks"),
						url: function () {
							return URLBuilder(["task", 'home', 'third', thirdId]);
						}
					},
					event: {
						icon: 'icon-agenda',
						label: App.polyglot.t("agenda"),
						url: function () {
							return URLBuilder(['agenda', 'event', "third", thirdId]);
						}
					}
				}

			};

			if (App.currentStaff.get('permission').isAdmin === "Y" || App.permission.level('invoicing_invoicelevel') || App.permission.level('invoicing_estimatelevel') || App.permission.level('invoicing_orderlevel') || App.permission.level('invoicing_deliverylevel') || App.permission.level('invoicing_proformalevel')) {
				this.shortenedPanel.actions['linkedDocs'] = {
					icon: "icon-folder",
					label: App.polyglot.t('linkedDocs'),
					url: URLBuilder(['third', 'linkedDocs', this.linked === 'supplier' ? App.permissionPurDoctypes[0] : App.permissionDoctypes[0], thirdId, this.linked])
				};
			}

			if (App.permission.can("briefcase")) {

				this.shortenedPanel.actions['briefcase'] = {
					icon: 'icon-briefcase',
					label: App.polyglot.t('briefcase'),
					url: URLBuilder(['briefcase', 'list', 'third', thirdId])
				};
			}

			if (this.linked !== 'supplier' && App.permission.can("prospection_access")) {
				this.shortenedPanel['actions']['linkdOpps'] = {
					icon: 'icon-opportunity',
					label: App.polyglot.t("opportunities"),
					url: URLBuilder(['opportunity', "home", thirdId])
				};
			}

			if (App.permission.can('support_access')) {
				this.shortenedPanel['actions']['support'] = {
					icon: "icon-helpdesk",
					label: App.polyglot.t('tickets'),
					url: function () {
						return URLBuilder(['ticket', 'list', thirdId]);
					}
				};
			}

			this.models[this.third.name] = this.third;

			var _this = this;

			this.overview = new Overview({ parentView: this }, this.router);
			this.subviews.push(this.overview);
			this.subviews.push(new Tabs('#third-header'));
			this.buildShortenedPanel();

			this.render();
		},

		/**
   * 
   */
		afterRender: function () {

			var _this = this;

			PageOverview.prototype.afterRender.apply(this, arguments);

			this.accordion = new Accordion({ scrollable: $('.overview', this.$el), required: {
					'contacts-container': function () {
						return _this.third.get('type') === 'corporation';
					}
				} });

			this.subviews.push(this.accordion);

			//var DOM declaration
			this.$.contactTitle = $('.contact-title', this.$el);
			this.$.contact = $('div.contact', this.$el);
			this.$.address = $('div.address', this.$el);
			this.$.addressTitle = $('.address-title', this.$el);
			this.$.callTo = $("#callTo", this.$el);
			this.$.phoneNum = $("#callTo .phone-num", this.$el);
			this.$.phoneNum.secondchild = $("#callTo .phone-num:nth-child(2)", this.$el);
			this.$.togglePhone = $(".togglePhone", this.$el);
			this.$.togglePhone.icon = $(".togglePhone a i", this.$el);
			this.$.avatarInitial = $("#pic-header .avatar-initials", this.$el);
			this.$.avatarImg = $("#pic-header img", this.$el);

			this.overview.initSwipeEffect({
				removeMsg: function (model, $currentTarget) {
					return $currentTarget.hasClass('contact') ? _this.toastMessages.contact.contactDeleted : _this.toastMessages.address.addressDeleted; // message when suppression is done
				},
				onModify: function (model, $currentTarget) {
					$currentTarget.hasClass('contact') ? _this.contactForm.call(_this, model) : _this.addressForm.call(_this, model);
				},
				popupid: this.linked + '-option',
				onRemove: function (model, $currentTarget) {
					$currentTarget.remove();
				},
				getModel: function ($currentTarget) {
					var id = $currentTarget.data('id');
					return $currentTarget.hasClass('contact') ? new People(_this.third.get('contacts')[$currentTarget.data("id") + "_0"]) : new Address(_this.third.get('addresses')[$currentTarget.data("id") + '_0']);
				}

			}, '', this);

			$('#addContact-toast').show();

			if (this.third.get('type') == 'corporation') {
				var name = Utils.slug(this.third.get('name'));
			} else {
				var name = Utils.slug(this.third.get('fullName'));
			}
		},

		/**
   * 
   */
		goToForm: function () {

			if (this.checkRight()) {
				this.router.navigate(URLBuilder([this.linked, "form", this.third.id]), { trigger: true });
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainDelivAddr: function (e) {

			if (this.checkRight()) {

				var _this = this;
				var $newMainDelivAddr = $(e.currentTarget).closest('.address');
				var $newDelivId = $newMainDelivAddr.data('id');
				var newDelivAddr = new Address(this.third.get('addresses')[$newDelivId + "_0"]);

				newDelivAddr.set({ isMainDeliv: 'Y' });
				newDelivAddr.unset('isMain');

				var $loader = this.formActionSetLoader();

				$newMainDelivAddr.append($loader);

				newDelivAddr.save().then(function () {

					new Toast({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 4000,
						content: _this.toastMessages.address.mainDelivAddressChanged
					});

					_this.render({ setLoader: false });
				}, function () {
					$('.inprogress-mask', $newMainDelivAddr).remove();
				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainAddr: function (e) {

			if (this.checkRight()) {

				var _this = this;
				var $newMainAddr = $(e.currentTarget).closest('.address');
				var $newMainId = $newMainAddr.data('id');
				var newMainAddr = new Address(this.third.get('addresses')[$newMainId + "_0"]);

				newMainAddr.set({ isMain: 'Y' });
				newMainAddr.unset('isMainDeliv');

				var $loader = this.formActionSetLoader();
				$newMainAddr.append($loader);

				newMainAddr.save().then(function () {

					new Toast({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 4000,
						content: _this.toastMessages.address.mainAddressChanged
					});

					_this.render({ setLoader: false });
				}, function () {
					$('.inprogress-mask', $newMainAddr).remove();
				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainContact: function (e) {

			if (this.checkRight()) {

				var _this = this;
				var $newMainContact = $(e.currentTarget).closest('.contact');
				var $newMainId = $newMainContact.data('id');
				var loader = this.formActionSetLoader();

				$newMainContact.append(loader);

				People.updateMainContactForThird($newMainId, this.third.id).then(function () {

					new Toast({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 4000,
						content: _this.toastMessages.contact.mainContactChanged
					});

					_this.render({ setLoader: false });
				}, function (error) {
					$('.inprogress-mask', $newMainContact).remove();
				});
			}
		},

		/**
   * @description "Go to the address Form"
   */
		addressForm: function (model) {

			if (this.checkRight()) {

				if (model.id) {
					this.router.navigate(URLBuilder(["address", "form", this.third.id, this.linked, model.id]), { trigger: true });
				} else {
					this.router.navigate(URLBuilder(["address", "form", this.third.id, this.linked]), { trigger: true });
				}
			}

			return false;
		},

		/**
   * @description "Go to contact form"
   */
		contactForm: function (model) {

			if (this.checkRight()) {

				if (model.id) {
					this.router.navigate(URLBuilder(["people", "form", this.third.id, this.linked, model.id]), { trigger: true });
				} else {
					this.router.navigate(URLBuilder(["people", "form", this.third.id, this.linked]), { trigger: true });
				}
			}

			return false;
		},

		/**
   * @description Confirmation popup before remove third
   * @returns {Boolean}
   */
		showPopupConfirm: function () {
			var callback = this.router.navigate.bind(this, URLBuilder([this.linked, "list"]), { trigger: true }); // executed after suppression
			this.removeConfirm(this.third, this.third.get('name'), this.$el, this.toastMessages.third.thirdDeleted, callback);
			return false;
		}

	});
});
