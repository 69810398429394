/**
 *  @desc SavedSearches Collection, extend core Collection
 */
define('models/collections/savedSearches',['models/core/collection', 'models/singles/savedSearch'], function (Collection, SavedSearch) {

    'use strict';

    return Collection.extend({
        model: SavedSearch,
        name: 'savedSearches',
        url: '/rest/savedSearches/list'
    });
});
