/**
 *  @desc Contact  Collection, extend core Collection
 */
define('models/collections/contactsSynchro',['models/core/collection', 'models/singles/contactSynchro', 'tools/ajax'], function (Collection, Contact, Ajax) {

	'use strict';

	return Collection.extend({

		model: Contact,
		name: 'contactsSynchro',
		url: '/rest/setting/synchronizeContacts',

		/**
   * 
   * @returns {Promise}
   */
		synchronize: function (settings) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({
					method: 'POST',
					url: _this.url,
					data: {
						contacts: _this.toJSON(),
						settings: settings
					},
					dataType: "json",
					success: function () {
						resolve();
					},
					error: function () {
						reject();
					}
				});
			});
		}

	});
});
