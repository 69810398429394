/**
 * Child View : Core Form
 */
define('views/product/popupCreateDecli',['underscore', 'views/core/Popup', 'views/core/form', 'tools/core/utils', 'app'], function (_, Popup, Form, Utils, App) {

	'use strict';

	return Popup.extend({

		models: {},
		collections: {},
		parentView: {},
		events: _.extend({
			"click .btn-choosevariation": "chooseVariation"
		}, Popup.prototype.events),

		toastMessage: {
			variationCreated: App.polyglot.t('variationCreated')
		},

		/**
   * @desc initialize
   */
		initialize: function (options, router) {

			Popup.prototype.initialize.apply(this, arguments);

			this.subviews = [];
			this.parentView = options.parentView;
			this.template = options.contentTemplate;
			this.router = router;
			this.classes = "form";
			this.models = options.models;
			this.collections = options.collections;
		},

		/**
   * 
   * @returns {undefined}
   */
		startup: function () {

			this.form = new Form({ parentView: this }, $('.popup-content', this.$el));
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.listenTo(this, 'renderend', this.afterRender);

			this.initBtns();

			this.$('.btn-submit').prop('disabled', true);
		},

		/**
   * 
   * @returns {undefined}
   */
		chooseVariation: function () {

			// if decli is already in the creation pool, stop process
			if ($('.declitocreate', this.$el).length > 0) return false;

			var declinations = this.parentView.product.get('declinations');
			var existingVariations = [];
			var variationToCreate = [];

			// add already existing variations in array
			_.each(declinations, function (existVar) {

				var line = [];

				line.push(existVar['idf1_syscode']);

				if (existVar['idf2_id'] != '0') {
					line.push(existVar['idf2_syscode']);
				}
				if (existVar['idf3_id'] != '0') {
					line.push(existVar['idf3_syscode']);
				}

				existingVariations.push(line);
			});

			var valueNotSelectedError = false;

			// add variation field to create in array
			var currentVars = $('select[name*="idf"]', this.$el);
			_.each(currentVars, function (currentVar) {

				if ($(currentVar).val() != '0') {
					variationToCreate.push($(currentVar).val());
				} else {
					valueNotSelectedError = true;
				}
			});

			// test if variationfield combinaison already exist - if no continue process
			var okToCreate = true;
			_.each(existingVariations, function (combinaison) {
				if (Utils.isArrayEquals(combinaison, variationToCreate)) {
					okToCreate = false;
				}
			});

			// if all variation field has been selected
			if (!valueNotSelectedError) {

				// if the declination is unique
				if (okToCreate) {

					var decliToCreateClone = $('#declitocreate_calc', this.$el).clone();

					// name
					var name = this.parentView.product.get('name') + '-' + _.size(declinations);
					$(decliToCreateClone).find('input[name="declination[name]"]').val(name);

					// tradename
					$(decliToCreateClone).find('input[name="declination[tradename]"]').val(name);

					// variation values
					_.each(currentVars, function (currentVar) {

						var id = $(currentVar).data('id');
						var value = $(currentVar).find('option:selected').text();

						$(decliToCreateClone).find('.variationfield-tocreate-' + id + ' .declifield').val(value);
						$(decliToCreateClone).find('.variationfield-tocreate-' + id).find('.declifield_val').val($(currentVar).find('option:selected').val());
					});

					// unit amount
					var unitamount = App.numberFormat.formatToDisplay(this.parentView.product.get('unitAmount'), true, false);
					$(decliToCreateClone).find('input[name="declination[priceInc]"]').val(unitamount);

					// remove class and id on calc
					decliToCreateClone.removeClass('hidden');
					decliToCreateClone.removeAttr('id');
					decliToCreateClone.addClass('declitocreate');
					$("#declitocreate", this.$el).removeClass('hidden');

					$("#declitocreate", this.$el).append(decliToCreateClone);
					this.$('.btn-submit').prop('disabled', false);

					return false;
				} else {
					this.form.showOneError(App.polyglot.t('variationAlreadyExist'));
				}
			} else {
				this.form.showOneError(App.polyglot.t('oneValuePerVariation'));
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function (context) {

			var dataForm = this.form.dataForm;
			var _this = this;
			var isNotValid = this.parentView.product.validateVariation(dataForm.declination);

			if (isNotValid) {

				$('.submit-form', context).prop('disabled', false);

				//Make invalid inputs in error
				_.each(isNotValid, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			} else {

				// add variation and if its ok, re-render overview
				this.parentView.product.addVariation(dataForm.declination).then(function () {

					_this.hide();
					_this.parentView.render();
					_this.form.trigger('successful', _this.toastMessage.variationCreated);
				}, function () {

					console.log("error");

					new ToastNotification({
						id: 'toast-error',
						classes: 'col-xs-12 ',
						style: { 'margin-bottom': '70px' },
						timeDuration: 4000,
						content: App.polyglot.t('anErrorOccured')
					});
				});
			}

			return false;
		}

	});
});
