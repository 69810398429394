/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/unities',['models/core/collection', 'models/singles/unity', 'db/easy'], function (Collection, Unity, DB) {

	'use strict';

	return Collection.extend({

		model: Unity,
		storeName: 'unity',
		name: 'unities',
		url: '/rest/unity/list',
		fields: ['value'],
		canBeStored: true,
		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.add(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		}
	});
});
