!function a(b, c, d) {
  function e(g, h) {
    if (!c[g]) {
      if (!b[g]) {
        var i = "function" == typeof require && require;if (!h && i) return i(g, !0);if (f) return f(g, !0);var j = new Error("Cannot find module '" + g + "'");throw j.code = "MODULE_NOT_FOUND", j;
      }var k = c[g] = { exports: {} };b[g][0].call(k.exports, function (a) {
        var c = b[g][1][a];return e(c ? c : a);
      }, k, k.exports, a, b, c, d);
    }return c[g].exports;
  }for (var f = "function" == typeof require && require, g = 0; g < d.length; g++) e(d[g]);return e;
}({ 1: [function (a, b) {
    (function (a) {
      var c = function (a) {
        this.$el = a.$el, this.options = a.options, this.vars = a.vars;
      };c.prototype = { gotoSlideByIndex: function (a, b) {
          var c,
              d = this.vars,
              e = this.options,
              f = this.$el;return a >= this.$el.children("li").length - 1 || 0 >= a ? (c = !0, a = Math.min(Math.max(a, 0), this.$el.children("li").length - 1)) : c = !1, this.changeActiveSlideTo(a), this.total_duration = Math.max(e.duration - 1920 / $(window).width() * Math.abs(d.velocity) * 9 * (e.duration / 230) - (this.isOutBoundaries() ? d.distanceFromStart / 15 : 0) * (e.duration / 230), 50), this.total_back = c ? 250 * Math.abs(d.velocity) / $(window).width() : 0, this.currentPos = f.translate3d().x, this.currentLandPos = this.getPositionByIndex(a), b ? void f.translate3d(this.getPositionByIndex(a)) : (window.cancelAnimationFrame(this.slidesGlobalID), this.startTime = Date.now(), void (this.slidesGlobalID = window.requestAnimationFrame(this.animationRepeat.bind(this))));
        }, changeActiveSlideTo: function (a) {
          var b = this.options,
              c = this.vars;this.$el.children(":nth-child(" + (c.currentIndex + 1 || 0) + ")").removeClass("itemslide-active"), this.$el.children(":nth-child(" + (a + 1 || 0) + ")").addClass("itemslide-active"), a != b.currentIndex && (c.currentIndex = a, this.$el.trigger("changeActiveIndex"));
        }, getLandingSlideIndex: function (a) {
          for (var b = this.$el, c = this.options, d = this.vars, e = 0; e < b.children("li").length; e++) if (b.children().outerWidth(!0) * e + b.children().outerWidth(!0) / 2 - b.children().outerWidth(!0) * c.pan_threshold * d.direction - this.getPositionByIndex(0) > a) return c.one_item ? e != d.currentIndex ? d.currentIndex + d.direction : d.currentIndex : e;return c.one_item ? d.currentIndex + 1 : b.children("li").length - 1;
        }, getPositionByIndex: function (a) {
          return -(a * this.$el.children().outerWidth(!0) - (this.$el.parent().outerWidth(!0) - this.$el.children().outerWidth(!0)) / 2);
        }, isOutBoundaries: function () {
          return Math.floor(this.$el.translate3d().x) > this.getPositionByIndex(0) && -1 == this.vars.direction || Math.ceil(this.$el.translate3d().x) < this.getPositionByIndex(this.$el.children("li").length - 1) && 1 == this.vars.direction;
        }, animationRepeat: function () {
          var a = this,
              b = Date.now() - this.startTime;return this.$el.trigger("changePos"), this.$el.translate3d(this.currentPos - easeOutBack(b, 0, this.currentPos - this.currentLandPos, this.total_duration, this.total_back)), b >= this.total_duration ? void this.$el.translate3d(this.currentLandPos) : void (this.slidesGlobalID = requestAnimationFrame(function () {
            a.animationRepeat.call(a);
          }));
        } }, b.exports = c, a.matrixToArray = function (a) {
        return a.substr(7, a.length - 8).split(", ");
      }, a.easeOutBack = function (a, b, c, d, e) {
        return void 0 == e && (e = 1.70158), c * ((a = a / d - 1) * a * ((e + 1) * a + e) + 1) + b;
      };
    }).call(this, "undefined" != typeof global ? global : "undefined" != typeof self ? self : "undefined" != typeof window ? window : {});
  }, {}], 2: [function (a, b) {
    var c = a("./navigation"),
        d = a("./animation"),
        e = a("./slideout");b.exports = { create: function (a, b) {
        var f = this;if (f.$el = b, f.options = $.extend($.fn.itemslide.options, a), f.options.parent_width && b.children().width(b.parent().outerWidth(!0)), b.css({ "touch-action": "pan-y", "-webkit-user-select": "none", "-webkit-touch-callout": "none", "-webkit-user-drag": "none", "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)" }), f.options.disable_autowidth || b.css("width", b.children("li").length * b.children().outerWidth(!0) + 10), f.vars = { currentIndex: 0, parent_width: f.options.parent_width, velocity: 0, slideHeight: b.children().height(), nbSlide: b.children().length }, f.options.show_circle) {
          f.$el.parent().append("<span id='nb-slide'></span>");for (var g = 0; g < f.vars.nbSlide; ++g) $("#nb-slide").append("<div class='circle'></div>");$("#nb-slide .circle").each(function (a) {
            $(this).on("click", function () {
              f.anim.gotoSlideByIndex(a);
            });
          });
        }b.end_animation = !0, f.options.swipe_out && e.slideout(f);var h = new d(f),
            i = new c(f, h);f.anim = h, b.translate3d(0), h.gotoSlideByIndex(f.options.start);try {
          b.mousewheel(function (a) {
            if (!f.options.disable_scroll && !i.get_vertical_pan()) {
              a.preventDefault();var c = f.vars.currentIndex - ((0 == a.deltaX ? a.deltaY : a.deltaX) > 0 ? 1 : -1);if (c >= b.children("li").length || 0 > c) return;f.vars.velocity = 0, h.gotoSlideByIndex(c);
            }
          });
        } catch (j) {}
      } };
  }, { "./animation": 1, "./navigation": 5, "./slideout": 7 }], 3: [function (a, b) {
    b.exports = { apply: function (a, b) {
        a.gotoSlide = function (a) {
          b.anim.gotoSlideByIndex(a);
        }, a.gotoSlide = function (a) {
          b.anim.gotoSlideByIndex(a);
        }, a.next = function () {
          b.anim.gotoSlideByIndex(b.vars.currentIndex + 1);
        }, a.previous = function () {
          b.anim.gotoSlideByIndex(b.vars.currentIndex - 1);
        }, a.reload = function () {
          var a = b.$el,
              c = b.vars;c.parent_width && a.children().width(a.parent().outerWidth(!0)), b.options.disable_autowidth || a.css("width", a.children("li").length * a.children().outerWidth(!0) + 10), c.slideHeight = a.children().height(), c.velocity = 0, b.anim.gotoSlideByIndex(c.currentIndex, !0);
        }, a.addSlide = function (a) {
          b.$el.children("li").last().append("<li>" + a + "</li>"), b.reload();
        }, a.removeSlide = function (a) {
          b.$el.children(":nth-child(" + (a + 1 || 0) + ")").remove();
        }, a.getActiveIndex = function () {
          return b.vars.currentIndex;
        }, a.getCurrentPos = function () {
          return b.anim.translate3d().x;
        };
      } };
  }, {}], 4: [function (a) {
    (function (b) {
      $(function () {
        "use strict";
        b.isExplorer = !!document.documentMode, a("./polyfills");var c = a("./carousel"),
            d = a("./external_funcs");$.fn.itemslide = function (a) {
          var b = Object.create(c);d.apply(this, b), b.create(a, this);
        }, $.fn.itemslide.options = { duration: 350, swipe_sensitivity: 150, disable_slide: !1, disable_clicktoslide: !1, disable_scroll: !1, start: 0, one_item: !1, pan_threshold: .3, disable_autowidth: !1, parent_width: !1, swipe_out: !1, show_circle: !1 }, $.fn.translate3d = function (a, b) {
          if (null == a) {
            var c = matrixToArray(this.css("transform"));if (null != $.fn.jquery) return { x: parseFloat(isExplorer ? c[12] : c[4]), y: parseFloat(isExplorer ? c[13] : c[5]) };var d = this.css("transform").replace("translate3d", "").replace("(", "").replace(")", "").replace(" ", "").replace("px", "").split(",");return { x: parseFloat(d[0]), y: parseFloat(d[1]) };
          }this.css("transform", "translate3d(" + a + "px," + (b || 0) + "px, 0px)");
        };
      });
    }).call(this, "undefined" != typeof global ? global : "undefined" != typeof self ? self : "undefined" != typeof window ? window : {});
  }, { "./carousel": 2, "./external_funcs": 3, "./polyfills": 6 }], 5: [function (a, b) {
    function c() {
      window.getSelection ? window.getSelection().empty ? window.getSelection().empty() : window.getSelection().removeAllRanges && window.getSelection().removeAllRanges() : document.selection && document.selection.empty();
    }function d(a) {
      return null == $.fn.jquery ? a.changedTouches[0] : a.originalEvent.touches[0] || a.originalEvent.changedTouches[0];
    }var e = function (a, b) {
      function e(a) {
        if ("true" !== $(a.target).attr("no-drag")) {
          var b;b = "touchstart" == a.type ? d(a) : a, h.end_animation && (l = Date.now(), m = 1, n = 0, o = b.pageX, p = b.pageY, s = !1, q = !1, h.savedSlide = $(a.target), h.savedSlideIndex = h.savedSlide.index(), r = 0, $(window).on("mousemove touchmove", function (a) {
            f(a);
          }), c());
        }
      }function f(a) {
        var c;if ("touchmove" == a.type ? (c = d(a), Math.abs(c.pageX - o) > 10 && (n = 1), n && a.preventDefault()) : (c = a, i.disable_slide || i.swipe_out || a.preventDefault()), j.direction = -(c.pageX - o) > 0 ? 1 : -1, b.isOutBoundaries() ? u && (t = c.pageX, u = 0) : (u || (b.currentLandPos = h.translate3d().x, o = c.pageX), u = 1), 1 == r && (h.children().css("height", j.slideHeight), h.savedSlide.wrapAll("<div class='itemslide_slideoutwrap' />"), r = -1), Math.abs(c.pageX - o) > 6 && (!s && h.end_animation && (q = !0), window.cancelAnimationFrame(b.slidesGlobalID)), Math.abs(c.pageY - p) > 6 && !q && h.end_animation && (s = !0), q) {
          if (i.disable_slide) return;s = !1, h.translate3d((0 == u ? t - o + (c.pageX - t) / 4 : c.pageX - o) + b.currentLandPos), h.trigger("changePos"), h.trigger("pan");
        } else s && i.swipe_out && (a.preventDefault(), $(".itemslide_slideoutwrap").translate3d(0, c.pageY - p), -1 != r && (r = 1));
      }function g(a) {
        if (m) {
          var c;if (c = "touchend" == a.type ? d(a) : a, $(window).off("mousemove touchmove"), s && i.swipe_out) return s = !1, void k();if (h.end_animation && !i.disable_slide) {
            var e = Date.now() - l;j.velocity = -(c.pageX - o) / e, j.direction = j.velocity > 0 ? 1 : -1, j.distanceFromStart = (c.pageX - o) * j.direction * -1;var f = b.getLandingSlideIndex(j.velocity * i.swipe_sensitivity - h.translate3d().x);if (j.distanceFromStart > 6) return void b.gotoSlideByIndex(f);
          }h.savedSlide.index() == j.currentIndex || i.disable_clicktoslide || (a.preventDefault(), b.gotoSlideByIndex(h.savedSlideIndex));
        }m = !1;
      }var h = a.$el,
          i = a.options,
          j = a.vars,
          k = a.swipeOut;h.on("mousedown touchstart", "li", function (a) {
        e(a);
      }), $(window).on("mouseup touchend", function (a) {
        g(a);
      });var l,
          m,
          n,
          o,
          p,
          q,
          r,
          s = !1;this.get_vertical_pan = function () {
        return s;
      };var t, u;
    };b.exports = e;
  }, {}], 6: [function () {
    for (var a = 0, b = ["ms", "moz", "webkit", "o"], c = 0; c < b.length && !window.requestAnimationFrame; ++c) window.requestAnimationFrame = window[b[c] + "RequestAnimationFrame"], window.cancelAnimationFrame = window[b[c] + "CancelAnimationFrame"] || window[b[c] + "CancelRequestAnimationFrame"];window.requestAnimationFrame || (window.requestAnimationFrame = function (b) {
      var c = new Date().getTime(),
          d = Math.max(0, 16 - (c - a)),
          e = window.setTimeout(function () {
        b(c + d);
      }, d);return a = c + d, e;
    }), window.cancelAnimationFrame || (window.cancelAnimationFrame = function (a) {
      clearTimeout(a);
    }), "function" != typeof Object.create && (Object.create = function (a) {
      function b() {}return b.prototype = a, new b();
    }), $.fn.outerWidth = function () {
      var a = $(this)[0],
          b = a.offsetWidth,
          c = getComputedStyle(a);return b += parseInt(c.marginLeft) + parseInt(c.marginRight);
    };
  }, {}], 7: [function (a, b) {
    function c(a) {
      function b() {
        if (p = Date.now() - i, o) $(".itemslide_slideoutwrap").translate3d(0, j - easeOutBack(p, 0, j - h, 250, 0)), e.savedSlide.css("opacity", m - easeOutBack(p, 0, m, 250, 0) * (n ? -1 : 1));else {
          if (n) return $(".itemslide_slideoutwrap").children().unwrap(), $(s).children().unwrap(), e.children().css("height", ""), e.end_animation = !0, void (p = 0);$(s).translate3d(0 - easeOutBack(p - 250, 0, 0 + e.savedSlide.width(), 125, 0) * (r ? -1 : 1), 0);
        }return 1 == q && ($(".itemslide_slideoutwrap").children().unwrap(), e.savedSlideIndex == g.currentIndex && $(s).children(":nth-child(1)").addClass("itemslide-active"), e.savedSlideIndex != e.children().length - 1 || r || e.savedSlideIndex != g.currentIndex || (f.duration = 200, a.anim.gotoSlideByIndex(e.children().length - 2)), 0 == e.savedSlideIndex && 0 != g.currentIndex && (p = 500), q = -1), p >= 250 && (o = !1, -1 != q && (q = 1), p >= 375) ? ($(s).children().unwrap(), e.removeSlide(c.index()), (0 == e.savedSlideIndex && 0 != g.currentIndex || r) && a.anim.gotoSlideByIndex(g.currentIndex - 1, !0), f.duration = l, p = 0, void (e.end_animation = !0)) : void (k = requestAnimationFrame(b));
      }var c,
          d,
          e = a.$el,
          f = a.options,
          g = a.vars,
          h = -400,
          i = Date.now(),
          j = 0,
          k = 0,
          l = 0,
          m = 0;e.end_animation = !0, e.savedSlideIndex = 0;var n = !1;a.swipeOut = function () {
        j = $(".itemslide_slideoutwrap").translate3d().y, d = 0 > j, h = d ? -400 : 400, Math.abs(0 - j) < 50 ? (n = !0, h = 0) : (n = !1, e.trigger({ type: "swipeout", slide: e.savedSlideIndex })), q = 0, l = f.duration, c = e.savedSlide, i = Date.now(), m = e.savedSlide.css("opacity"), e.savedSlideIndex < g.currentIndex ? (r = !0, e.children(":nth-child(-n+" + (e.savedSlideIndex + 1) + ")").wrapAll("<div class='itemslide_move' />")) : (r = !1, e.children(":nth-child(n+" + (e.savedSlideIndex + 2) + ")").wrapAll("<div class='itemslide_move' />")), o = !0, e.end_animation = !1, k = requestAnimationFrame(b);
      };var o = !0,
          p = 0,
          q = 0,
          r = !1,
          s = ".itemslide_move";
    }b.exports = { slideout: c };
  }, {}] }, {}, [4]);
define("libs/itemslide", ["jquery"], function(){});

