/**
 * @desc Timeline ticket, extend ToggleablePage View
 * Child View : Core Overview
 */
define('views/support/timeline',['underscore', 'views/core/ToggleablePage', 'views/core/listing', 'tools/buildURL', 'app', 'models/collections/ticketConversations', 'views/widgets/expendables', 'views/core/toastNotification'], function (_, ToggleablePage, Listing, URLBuilder, App, TicketConversations, Expendables, ToastNotification) {

	'use strict';

	return ToggleablePage.extend({

		id: 'timeline-view',
		events: _.extend({
			'click input[name="ticket[type]"]': function (e) {

				var _this = this;

				if (App.isOnline) {

					this.onHide();
					this.$el.transition({ x: -($(window).width() * 2) + 'px', duration: 550 }, function () {
						_this.$el.hide().css('transform', 'translate(' + $(window).width() + ', 0)');
						_this.$el.removeClass('toggled');
						_this.$el.show();
					});

					this.mainView.formResponse.$el.trigger('show', [$(e.currentTarget).val()]);
				} else {
					this.notifWriteDisabled();
				}
			},
			'click .edit-draft': function (e) {

				var _this = this;

				if (App.isOnline) {

					this.$el.transition({ x: -($(window).width() * 2) + 'px', duration: 550 }, function () {
						_this.$el.hide().css('transform', 'translate(' + $(window).width() + ', 0)');
						_this.$el.removeClass('toggled');
						_this.$el.show();
					});

					var draftId = $(e.currentTarget).closest('.ticketconv-row').data('id');

					this.mainView.formResponse.$el.trigger('show', ['draft', draftId]);
				} else {
					this.notifWriteDisabled();
				}
			},
			'click .remove-draft': function (e) {

				if (App.isOnline) {

					var _this = this;
					var draftId = $(e.currentTarget).closest('.ticketconv-row').data('id');
					var draft = this.ticketConversations.get(draftId);

					_this.removeConfirm(draft, '', $(e.currentTarget).closest('.ticketconv-row'), App.polyglot.t('draftRemoved'), function () {
						_this.mainView.render();
					}, { ticketid: draft.get('ticketid') });
					//					draft.destroy({
					//						data : JSON.stringify({
					//							ticketid : draft.get('ticketid')
					//						})
					//						
					//					}).then(function(){
					//						
					//						new  ToastNotification({
					//							id				: 'toast-success' ,
					//							class			: 'col-xs-12',
					//							timeDuration	: 4000,
					//							content			: App.polyglot.t('draftRemoved'),
					//						});
					//						
					//						_this.render();
					//						
					//					});
				} else {
					this.notifWriteDisabled();
				}
			}

		}, ToggleablePage.prototype.events),

		actionList: {},

		title: 'timeline',
		hideSearchIcon: true,

		/**
   * @desc initialize
   */
		initialize: function (router, options) {

			ToggleablePage.prototype.initialize.apply(this, arguments);
			this.ticket = options.modelsFetched['ticket'];
		},

		/**
   * 
   * @returns {undefined}
   */
		render: function () {

			//clear subviews when we call render more than one time
			if (!_.isEmpty(this.subviews)) {
				this.subviews = [];
			}

			this.ticketConversations = new TicketConversations();
			this.ticketConversations.add(this.ticket.get('timeline'));

			this.collection = this.ticketConversations;
			this.collection.isAlreadyFetched = true;

			this.listing = new Listing({ parentView: this, template: 'templates/support/timelineFull.twig' });
			this.subviews.push(this.listing);
			ToggleablePage.prototype.render.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			ToggleablePage.prototype.afterRender.apply(this, arguments);

			if (!this.expendables) {
				this.expendables = new Expendables(this.$el);
				this.subviews.push(this.expendables);
			}

			var _this = this;
			var iframePromises = [];
			$('iframe', this.$el).each(function () {

				iframePromises.push(new Promise(_.bind(function (resolve, reject) {

					$(this).prop('srcdoc', _this.collection.get($(this).data('id')).get('message'));

					$(this).on('load', function () {

						var head = $(this).contents().find("head");

						$('<link/>', {
							rel: "stylesheet",
							type: "text/css",
							href: "/ressources/css/iframe.css"
						}).appendTo(head);

						var h = this.contentWindow.document.body.scrollHeight + 10 + 'px';
						$(this).height(h);
						$('body', $(this).contents()).attr('data-id', $(this).data('id')).addClass($(this).prop('class')).css('overflow-y', 'hidden');
						resolve();
					});
				}, this)));
			});
		},

		/**
   * @desc action when we click on return arrow and when back is pressed for native app
   * @param {type} e
   * @returns {Boolean}
   */
		backHandler: function (e) {
			this.$el.trigger('hide');
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		onShow: function () {
			var _this = this;
			App.header.$el.find('nav').removeClass('box-shadow-none');
			$('.return', '.icon-wrapper-left').off();
			$('.return', '.icon-wrapper-left').one('click', this.backHandler.bind(this));
			$('#page-title', $('.navbar-fixed-top')).text('timeline');
		},

		/**
   *
   * @returns {undefined}
   */
		onHide: function () {
			this.renderHeader();
			App.header.$el.find('nav').addClass('box-shadow-none');
			$('#action-list').show();
		}

	});
});
