/**
 * @desc Document Overview, extend PageOverview View
 * Child View : Core Overview
 */
define('views/document/overview',['underscore', 'views/core/pageOverview', 'models/singles/document', 'models/collections/linkedPaymentDoc', 'views/core/overview', 'views/core/Popup', 'views/document/paymentForm', 'tools/buildURL', 'app', 'views/core/toastNotification', 'big', 'views/document/createDoc', 'tools/core/utils', 'models/collections/taxes', "models/collections/smarttags", 'views/widgets/tabs', 'views/document/docOptions', 'views/widgets/nativeActionSheet'], function (_, PageOverview, Document, PaymentsDoc, Overview, Popup, PaymentForm, URLBuilder, App, ToastNotification, Big, CreateDoc, Utils, Taxes, Smarttags, Tabs, DocOptionsView, ActionSheet) {

	'use strict';

	return PageOverview.extend({

		events: {
			'mouseup': 'closeMoreMenu',
			'click #remove-doc': 'showPopupConfirm',
			'click #update-doc': 'goToDocForm',
			'change .doc-step select': 'onStepChange',
			'click #record-payment': function (e) {

				if (this.document.get('hasDeadlines') === 'N') {

					if (this.document.get("step") === 'paid') {

						new ToastNotification({
							id: 'toast-error',
							classes: 'col-xs-12',
							timeDuration: 3000,
							content: App.polyglot.t('docAlreadyPaid')
						});
					} else {

						if (!App.permission.can('payment')) {

							new ToastNotification({
								id: 'toast-error',
								classes: 'col-xs-12',
								timeDuration: 3000,
								content: App.polyglot.t('youHaveNotTheRightForRecordPayment')
							});
						} else {
							this.paymentForm.$el.trigger('show');
						}
					}
				} else {

					new ToastNotification({
						id: 'toast-error',
						classes: 'col-xs-12',
						timeDuration: 3000,
						content: App.polyglot.t("cantRecordPaymentWithDeadlines")
					});
				}
			},
			'click .needLinkedLoadable': 'checkLoadable'
		},

		title: "",
		listing: {},
		toastMessages: {
			document: {
				documentRemoved: App.polyglot.t('documentRemoved'),
				stepUpdated: App.polyglot.t('stepUpdated')
			},
			payment: {
				paymentRemoved: App.polyglot.t('paymentRemoved')
			}
		},
		actionList: {
			modify: {
				fa: false,
				label: App.polyglot.t("update"),
				link: function () {
					this.goToForm();
				}
			},
			options: {
				fa: false,
				label: App.polyglot.t("options"),
				link: function () {
					this.options.$el.trigger('show');
				}
			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, id) {

			var _this = this;
			this.firstRender = true;
			this.doctype = doctype;
			this.canAdd = App.permission.can("invoicing_create" + doctype);
			this.canEdit = App.permission.can("invoicing_edit" + doctype);
			this.canDelete = App.permission.can("invoicing_delete" + doctype);
			this.smartTagsFilter = Smarttags.buildFilterByLinked(doctype, id);

			// customFields filter
			this.customFieldFilter = {
				useOn: 'document',
				linkedtype: 'document',
				linkedid: id
			};

			PageOverview.prototype.initialize.apply(this, arguments);

			Document.classNameByDoctype(doctype).then(function (documentType) {

				_this.document = _this.model = new documentType({ id: id });
				_this.models[_this.document.name] = _this.document;

				_this.taxes = new Taxes();
				_this.collections[_this.taxes.name] = _this.taxes;

				_this.shortenedPanel = {

					relatedType: doctype,
					relatedId: id,
					name: 'ident',
					model: _this.document,
					actions: {
						'briefcase': {
							label: App.polyglot.t("attachments")
						},
						'logHisto': {
							url: doctype === 'purCreditNote' ? URLBuilder(["log", "list", 'purCreditnote', id]) : undefined
						}
					}

				};

				_this.template = "templates/document/overview.twig";
				_this.overview = new Overview({ parentView: _this });
				_this.options = new DocOptionsView(router, {
					modelsFetched: { "document": _this.document },
					mainView: _this
				});

				_this.paymentForm = new PaymentForm(router, {
					modelsFetched: {
						document: _this.document
					},
					mainView: _this
				});

				_this.subviews.push(new Tabs('#header-doc'));
				_this.subviews.push(_this.overview);
				_this.buildShortenedPanel();
				_this.toggleableViews.push(_this.options);
				_this.toggleableViews.push(_this.paymentForm);

				_this.render();
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageOverview.prototype.afterRender.apply(this, arguments);

			//DOM declarations
			this.$.stepSelect = $('#step-select', this.$el);

			if (!this.canEdit) {
				this.$.stepSelect.prop('disabled', true);
			}

			//change some wording for creditnote
			if (this.document.get('doctype') === 'creditnote') {
				$('#payment-panel .collapse-title .title-name', this.$el).text(App.polyglot.t("refundsList"));
				$('#record-payment span', this.$el).text(App.polyglot.t("recordRefund"));
			}

			if (App.hasVat) {

				if (this.document.get('hasTaxesInc') === 'Y') {
					$('#price-details.docHT', this.$el).remove();
					this.isDocTTC = true;
				} else {
					$('#price-details.docTTC', this.$el).remove();
					this.isDocTTC = false;
				}

				$('.docNoVat', this.$el).remove();
			} else {
				$('.docTTC, .docHT', this.$el).remove();
			}

			if (!App.isUSCorp) {

				//if doc has ecotax display ecotaxPart
				if (this.document.get('totalEcoTaxIncDisplay') && new Big(this.document.get('totalEcoTaxIncDisplay')).gt(0)) {
					$('.ecotax-part', this.$el).removeClass('hide');
				};
			}

			if (this.document.get('globalDiscount') && new Big(this.document.get('globalDiscount')).gt(0)) {
				$('#price-details .discount-line').removeClass('hide');
			}

			if (new Big(this.document.get('packagingsAmount')).gt(0) || new Big(this.document.get('shippingsAmount')).gt(0)) {
				$('#totalAllInc').removeClass('hide');
			}

			//make opacity on row in option
			$('.doc-row[data-is-option="Y"]', this.$el).addClass("isOption");

			this.initMaterialEffectOnPaymentList();
		},

		/**
   * 
   * @returns {undefined}
   */
		initMaterialEffectOnPaymentList: function () {

			var _this = this;
			var model = this.document;
			var id = model.get('id');
			var type = model.get('doctype');

			//init material effect on each row of listing
			this.initEffect({

				removeMsg: this.toastMessages.payment.paymentRemoved,
				onModify: false,
				popupid: 'payment-option',
				modelName: 'ident',
				destroyOptions: {
					docid: id,
					doctype: type
				},
				getModel: function (elem) {

					var paymentlist = new PaymentsDoc();

					$.each(model.get('payments').listing, function (key, payment) {
						paymentlist.add(payment);
					});

					return paymentlist.get(elem.data('id'));
				},
				onRemove: function (model, $currentTarget) {
					_this.overview.render();
				}

			}, this.$('#paymentlist-container'), this);
		},

		/**
   * 
   * @param {event} e
   * @returns {undefined}
   */
		onStepChange: function (e) {

			var _this = this;

			var step = $(e.currentTarget).val();

			if (this.document.isStepAllowed(step)) {
				this.updateStep(step);
			} else {
				$('option[value="' + this.document.get("step") + '"]', $(e.currentTarget)).prop('selected', true);
				new ToastNotification({
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: this.document.stepError
				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		updateStep: function (step) {

			var _this = this;

			switch (step) {

				case 'partialinvoiced':
				case 'invoiced':

					var _this = this;

					if (_this.document.get('thirdRelationType') === 'prospect') {

						_this.document.updateStep(step).then(function (step) {
							_this.stepRendering(step);
						}, function (err) {});
					} else {

						if (this.document.get('hasDeadlines') === 'N') {

							var buttons = [{
								title: App.polyglot.t("dontCreateInvoice"),
								classes: "btn-cancel",
								onclick: function () {
									_this.document.updateStep(step).then(function (step) {
										_this.stepRendering(step);
									}, function (err) {
										console.log(err);
									});
								}
							}];

							buttons.push({
								title: App.polyglot.t("createInvoice"),
								classes: "btn btn-submit",
								onclick: function () {
									_this.router.navigate(URLBuilder(['document', 'form', 'estimate', _this.document.get('thirdid'), _this.document.get('id'), 'overview', 'invoice']), { trigger: true });
								}
							});

							if (App.useActionSheet) {

								new ActionSheet({
									title: App.polyglot.t('chargeEstimateConfirmation'),
									buttons: [{
										title: App.polyglot.t('createInvoice'),
										callback: function () {
											_this.router.navigate(URLBuilder(['document', 'form', 'estimate', _this.document.get('thirdid'), _this.document.get('id'), 'overview', 'invoice']), { trigger: true });
										}
									}, {
										title: App.polyglot.t('dontCreateInvoice'),
										callback: function () {
											_this.document.updateStep(step).then(function (step) {
												_this.stepRendering(step);
											}, function (err) {
												console.log(err);
											});
										}
									}],
									cancelLabel: App.polyglot.t('cancel'),
									cancelCallback: function () {
										var oldStep = _this.document.get('step');
										$('#step-select').val(oldStep);
									}
								});
							} else {

								new Popup({

									id: "modal-stepInvoice",
									mergeBtns: false,
									title: App.polyglot.t('chargeEstimate'),
									content: App.polyglot.t('chargeEstimateConfirmation'),
									buttons: buttons

								});
							}
						} else {
							// we cant create create an invoice if estimate use deadlines

							_this.document.updateStep(step).then(function (step) {
								_this.stepRendering(step);
							}, function (err) {});
						}
					}

					break;

				case 'paid':
				case 'payinprogress':
				case 'partialspend':
				case 'spent':

					if (!App.permission.can('payments')) {

						new ToastNotification({
							id: 'toast-error',
							classes: 'col-xs-12',
							timeDuration: 3000,
							content: App.polyglot.t('youHaveNotTheRightForRecordPayment')
						});
					} else {
						this.paymentForm.$el.trigger('show');
					}

					break;

				default:

					this.document.updateStep(step).then(function (step) {
						_this.stepRendering(step);
					}, function (err) {
						$('#step-select').val(_this.document.get('step'));
					});

					break;

			}
		},

		/**
  *
   * @param step
   */
		stepRendering: function (step) {

			var $stepSelect = $('#step-select', this.$el);
			$stepSelect.removeClass("step-" + this.document.get('step'));

			$stepSelect.addClass('step-' + step);
			this.document.set({ step: step });

			new ToastNotification({
				id: 'toast-success',
				classes: 'col-xs-12',
				timeDuration: 4000,
				content: this.toastMessages.document.stepUpdated
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		hidePopup: function () {
			$("#" + this.popupConfirmOptions.id).modal('hide');
		},

		/**
   * 
   * @returns {Boolean}
   */
		goToMailForm: function () {

			this.router.navigate(URLBuilder(["document", "email", this.document.get('doctype'), this.document.id, this.document.get('thirdid')]), { trigger: true });
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		checkLoadable: function () {

			if (this.document.get('thirdStatus') !== 'ok') {

				new ToastNotification({
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 3000,
					content: App.polyglot.t('clientDeletedCantRecordDoc')
				});

				return false;
			}
		},

		/**
   * @description display popup for confirm suppression if it available with the step of doc
   * @returns {undefined}
   */
		showPopupConfirm: function () {

			if (this.document.isEditable()) {

				var callback = this.router.navigate.bind(this, URLBuilder([this.isPurchase ? 'purchase' : 'document', 'list', this.doctype]), { trigger: true }); //executed after suppression
				this.removeConfirm(this.document, this.document.get('ident'), this.$el, this.toastMessages.document.documentRemoved, callback);
			} else {

				new ToastNotification({
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 3000,
					content: App.polyglot.t('docStatusCannotDelete')
				});
			}

			return false;
		},

		/**
   * 
   * @returns {Boolean}
   */
		goToForm: function (docid4Copy, thirdid, isCopy) {

			if (this.document.get('thirdStatus') !== 'ok' || this.document.get('paydate').syscode === 'deadlines') {

				new ToastNotification({
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 3000,
					content: App.polyglot.t(this.document.get('thirdStatus') !== 'ok' ? "cantCreateDocForDeletedThird" : "cantCreateDocWithDeadlines")
				});
			} else {

				if (isCopy) {
					this.router.navigate(URLBuilder(['document', 'createDoc', this.document.get('doctype'), this.document.get('id'), true, this.document.get('thirdid')]), { trigger: true });
				} else {

					if (this.document.isEditable()) {
						this.router.navigate(URLBuilder(['document', 'form', this.document.get('doctype'), this.document.get('thirdid'), this.document.id, 'overview']), { trigger: true, replace: true });
					} else {
						this.toastNotEditable();
					}
				}
			}

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		toastNotEditable: function () {

			new ToastNotification({
				id: 'toast-error',
				classes: 'col-xs-12',
				timeDuration: 4000,
				content: App.polyglot.t('docStatusCannotModify')
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		goToPaymentList: function () {
			this.router.navigate(URLBuilder(['document', 'linkedPayment', this.document.get('doctype'), this.document.id]), { trigger: true });
		}

	});
});
