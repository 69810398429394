/**
 *	@desc Model Delivery, extend Document Model 
 */
define('models/singles/delivery',['models/singles/document'], function (Document) {

	'use strict';

	return Document.extend({

		defaults: _.extend({

			id: 0,
			doctype: 'delivery',
			ident: '',
			formattedDocType: 'bon de livraison',
			thirdName: '',
			availableSteps: ['draft', "partialinvoiced", "invoiced", "read", "sent"]

		}, Document.prototype.defaults),

		urlRoot: '/rest/document/delivery',
		isEditable: function () {
			return !("invoiced" === this.get('step') || "partialinvoiced" === this.get('step'));
		},
		isStepAllowed: function () {
			return true;
		}

	}, {
		doctype: 'delivery'
	});
});
