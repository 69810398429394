/**
 * @description EventsFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/eventsFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/agendaLabels', 'models/collections/groups'], function (_, FilterPanel, App, Utils, ListingFilters, AgendaLabels, Groups) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			this.agendaLabels = new AgendaLabels();
			this.title = App.polyglot.t("events");
			var _this = this;
			var promises = [];
			this.hideSavedSearch = true;

			promises.push(this.agendaLabels.fetch({ method: 'POST' }));

			this.groups = new Groups();

			promises.push(this.groups.fetch({ method: 'POST' }));
			promises.push(App.getPreferencesByPlugin('agenda'));

			Promise.all(promises).then(function () {
				FilterPanel.prototype.initialize.apply(_this, [router, listName]);
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;
			var staffsList = this.groups.buildStaffs4groups();

			var filterList = {
				label: {
					label: App.polyglot.t('categories'),
					field: {
						type: 'input',
						name: 'labelid',
						offlineSearchField: 'labelid',
						isMultiple: true,
						classes: "offline-selectize",
						selectize: {
							domElt: 'input[name="filter[labelid]"]',
							placeholder: App.polyglot.t('categories'),
							create: false,
							valueField: 'id',
							labelField: 'value',
							options: this.agendaLabels
						}
					}
				}
			};

			if (App.currentStaff.get('permission').isAdmin === "Y" || App.currentStaff.get('permission').permissions.agendalevel.valuecheck === "all") {

				filterList['staffs'] = {
					label: App.polyglot.t('staffsAffected'),
					field: {
						type: 'input',
						name: 'staffs',
						offlineSearchField: 'staffid',
						classes: "offline-selectize",
						isMultiple: true,
						selectize: {
							domElt: "[name='filter[staffs]']",
							options: staffsList,
							create: false,
							labelField: 'fullName',
							valueField: 'buildid',
							searchField: ['fullName'],
							onItemAdd: this.selectizeOnItemAdd,
							onItemRemove: this.selectizeOnItemRemove,
							render: 'staff',
							optgroups: _this.groups.toJSON(),
							optgroupField: 'groupid',
							optgroupLabelField: 'name',
							optgroupValueField: 'id'
						}
					}
				};

				//if we have not groups dont show optGroup
				if (this.groups.length == 1) {
					delete filterList['staffs']['field']['selectize']['optgroups'];
					delete filterList['staffs']['field']['selectize']['optgroupField'];
					delete filterList['staffs']['field']['selectize']['optgroupLabelField'];
					delete filterList['staffs']['field']['selectize']['optgroupValueField'];
				}
			}

			var provider = App.preferences['agenda'].provider;

			if (provider !== 'sellsy') {
				filterList['calendar'] = {
					label: App.polyglot.t('calendar'),
					field: {
						type: 'input',
						name: 'calendars',
						offlineSearchField: 'calendars',
						isMultiple: true,
						classes: "offline-selectize",
						selectize: {
							domElt: 'input[name="filter[calendars]"]',
							placeholder: App.polyglot.t('calendars'),
							create: false,
							valueField: 'id',
							labelField: 'name',
							options: App.preferences.agenda.prefs['cache_calendars']
						}
					}
				};
			}

			this.filters = filterList;
			return this.filters;
		}

		/**
   * @returns {undefined}
   */
		//		formAction : function(e){
		//			
		//			var _this	= this;
		//			var inputs	= this.form.sendDataFormV2();
		//			
		//			var listingSearch	= {};
		//			var filtersArray	= [];
		//			
		//			//remove groupid parts of staffsIds and remove doublons
		//			inputs['filter']['staffs'] = inputs['filter']['staffs'].split(',');
		//			
		//			_.each(inputs['filter']['staffs'], function(staff,index){
		//				inputs['filter']['staffs'][index] = staff.split('_')[0];
		//			});
		//			
		//			inputs['filter']['staffs'] = inputs['filter']['staffs'].join();
		//			
		//			_.each(inputs, function(input) {
		//				
		//				_.each(input, function(val, key){
		//					
		//					
		//					var isMultiple = _this.$('[name="filter[' + key + ']"]').data("multiple") === 'Y';
		//					
		//					
		//					if(val && isMultiple){
		//						val =  val.split(',');
		//					}
		//					
		//					var hasOfflinesearchfield	= _this.$('[name="filter[' + key + ']"]').data("offlinesearchfield");
		//					filtersArray.push({name : key, value : val, listing : _this.listName, isMultiple : isMultiple, offlineSearchField : hasOfflinesearchfield });
		//
		//				});
		//				
		//			});
		//			
		//			console.log(filtersArray);
		//			
		//			this.listingFiltersCollection.saveLocal().then(_.bind(function(){
		//			
		//				//we delete listing of cache for take new data with new filters
		//				var previousRoute = this.router.history[0];
		//				delete App.cacheControl[previousRoute];
		//				_this.goToPreviousPage();
		//				return false;
		//				
		//			}, this));
		//			
		//		}
		//		
	});
});
