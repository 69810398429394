/**
 *  @description Accordion View extend Backbone View
 */
define('views/core/accordion',['jquery', 'app', 'views/core/view'], function ($, App, View) {

	return View.extend({

		el: '#accordion',
		events: {
			"shown.bs.collapse": 'fixToTop',
			"click .panel-heading a": 'changeArrow'
		},

		initialize: function (options) {

			this.$scrollableParent = options.scrollable;
			this.checkPrivileges();
			this.required = options.required;
			var _this = this;

			return new Promise(function (resolve, reject) {

				_.each(_this.required, function (condition, panel) {

					if (_.isFunction(condition)) {
						condition = condition();
					}

					if (!condition) {
						$('#' + panel, _this.$el).closest(".panel").remove();
					}
				});

				resolve();
			});
		},

		render: function () {},

		/**
   * 
   * @returns {undefined}
   */
		fixToTop: function (e) {

			var $target = $(e.target);
			if (e instanceof jQuery) {
				var $target = e;
			}

			var $top = $target.closest('.panel').find('.panel-heading');
			var offset = $top.offset();

			this.$scrollableParent.animate({ scrollTop: this.$scrollableParent.scrollTop() + offset.top - $top.height() - 30 });
		},

		/**
   * 
   * @returns {undefined}
   */
		customFixToTop: function (id) {
			this.fixToTop($(id));
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeArrow: function (e) {

			var v_heading = $(e.currentTarget).closest('.panel-heading');

			if (!v_heading.find('.fa-chevron').hasClass('rotate')) {
				v_heading.find('.fa-chevron').removeClass('reverseRotate');
				v_heading.find('.fa-chevron').addClass('rotate');
			} else {
				v_heading.find('.fa-chevron').removeClass('rotate');
				v_heading.find('.fa-chevron').addClass('reverseRotate');
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		checkPrivileges: function () {

			this.$el.find('.panel').each(function (i, elem) {

				var priv = $(elem).data("privilege");

				if (priv) {

					switch (priv) {
						case 'declinaisons':

							if (!App.permission.can('declination')) {
								$(this).remove();
							}

							break;
					}
				}
			});
		}

	});
});
