/**
 * @desc Agenda Overview, extend Page View
 * Child View : Core Overview
 */
define('views/nylas/nylasPrefs',['underscore', 'views/core/pageOverview', 'views/core/overview', 'app', 'tools/buildURL', 'models/collections/nylasAccounts'], function (_, PageOverview, Overview, App, URLBuilder, nylasAccounts) {

    'use strict';

    return PageOverview.extend({

        events: _.extend({
            "click .btn[name=add-account]": "addAccount",
            "click .btn[name=enable-account]": "setAccountEnabled",
            "click .btn[name=disable-account]": "setAccountDisabled"
        }, PageOverview.prototype.events),

        popupConfirmOptions: {},
        toastMessages: {},

        id: "nylas-prefs",

        title: App.polyglot.t('emailPrefs'),

        /**
         * @desc initialize
         */
        initialize: function (router) {

            PageOverview.prototype.initialize.apply(this, arguments);

            this.nylasAccounts = new nylasAccounts();
            this.collections[this.nylasAccounts.name] = this.nylasAccounts;

            this.options.extraData = {
                nylasPrefs: function () {

                    return new Promise(function (resolve, reject) {

                        EmailThread.getNylasPrefs().then(function (nylasPrefs) {
                            App.nylasPrefs = nylasPrefs;
                            resolve();
                        });
                    });
                }
            };

            this.template = "templates/setting/nylas";
            this.overview = new Overview({ parentView: this }, this.router);

            this.subviews.push(this.overview);

            this.render();
        },

        /**
         *
         * @returns {undefined}
         */
        afterRender: function () {

            PageOverview.prototype.afterRender.apply(this, arguments);
            App.header.$el.find("nav").removeClass("box-shadow-none");

            //check if active account is for corp
            var isCorpAccountActive = this.nylasAccounts.find(function (nylasAccount) {
                return nylasAccount.get('active') === 'Y' && nylasAccount.get('defaultFor') == "corp";
            });

            //disable staff accounts if active account is corp
            if (isCorpAccountActive) {

                $('.btn:not([name="add-account"])', "#staff-accounts").prop("disabled", true);

                //disable also corp acocunts if staff is not admin
                if (!App.plugins.isAdmin()) {
                    $('.btn', "#corp-accounts").prop("disabled", true);
                }
            }
        },

        /**
         *
         */
        addAccount: function (e) {
            var $btn = $(e.currentTarget);
            this.router.navigate(URLBuilder(["nylasConnect", $btn.data('type')]), { trigger: true });
        },

        /**
         *
         */
        setAccountEnabled: function (e) {

            var id = $(e.currentTarget).closest('.row-listing').data('id');
            var account = this.nylasAccounts.get(id);
            var _this = this;

            this.setLoader(this.$el);
            account.enable().then(function () {
                _this.render();
            });
        },

        /**
         *
         */
        setAccountDisabled: function (e) {

            var id = $(e.currentTarget).closest('.row-listing').data('id');
            var account = this.nylasAccounts.get(id);
            var _this = this;

            this.setLoader(this.$el);
            account.disable().then(function () {
                _this.render();
            });
        }

    });
});
