/**
 *	@desc Model Payment, extend Core Model 
 */
define('models/singles/payment',['models/core/model', 'db/easy', 'moment', 'big', 'app'], function (Model, DB, Moment, Big, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			amount: 0,
			medium: '',
			ident: '',
			date: '',
			notes: '',
			mailThanks: false
		},

		storeName: 'payment',
		name: 'payment',
		urlRoot: '/rest/document/payment',

		/**
   * 
   * @returns {Promise}
   */
		saveLocal: function (options) {

			options = _.isUndefined(options) ? {} : options;
			var _this = this;

			//if saveLocal was initiated by a collection, we don't fetch document linked to payment
			options.updateLinked = options.initiator === 'collection' ? false : options.updateLinked;

			var errorHandler = function (resolve, reject, ajaxError) {

				// Becareful with ajax response
				if (_.isUndefined(ajaxError)) {
					resolve();
				} else {
					reject(ajaxError);
				}
			};

			return new Promise(function (resolve, reject) {

				if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

					var id = [];
					id[_this.idAttribute] = parseInt(_this.id);

					_this.set(id);

					DB[_this.storeName].put(_this.toJSON()).then(function (lastId) {
						resolve();
					}, function (error) {
						console.log(error, _this);
						reject();
					});
				} else {
					errorHandler(resolve, reject, ajaxError);
				}
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		validate: function (attributes) {

			var validationErrors = { payment: {} };
			var bigAmount = new Big(attributes.amount);

			//we check input only in case of creation  (in update juste note and ref are updatable and they are free input)
			if (!attributes.id) {

				if (!Moment(attributes.date).isValid()) {
					validationErrors.payment.date = this.computeError({ name: 'date', type: 'invalid' });
				}

				if (bigAmount.eq(0)) {
					validationErrors.payment.amount = this.computeError({ name: 'amount', type: 'required' });
				} else if (bigAmount.gt(this.get('sumMax'))) {
					validationErrors.payment.amount = App.polyglot.t("amountCantGreaterThanDueAmount");
				}
			}

			if (_.toArray(validationErrors.payment).length > 0) {
				return validationErrors;
			}
		}

	});
});
