/**
 * @desc Event List, extend Page View
 * Child View : Core Listing
 */
define('views/agenda/eventListing',['underscore', 'views/core/pageList', 'views/core/listing', 'models/collections/events', 'tools/buildURL', 'tools/materialEffect', 'views/core/popupAction', 'models/singles/event', 'app', 'moment', 'models/singles/filter', 'views/agenda/popupRecurringAlert'], function (_, PageList, Listing, AgendaEntries, URLBuilder, initMaterialEffect, PopupAction, Event, App, Moment, Filter, PopupRecurringAlert) {

	'use strict';

	return PageList.extend({

		addAction: function (e) {

			var urlCallback = URLBuilder(['calendar', 'form', 'event', this.period]);

			if (this.relatedtype && this.relatedid) {
				urlCallback = URLBuilder([urlCallback, this.relatedtype, this.relatedid], false);
			}

			this.listing.goToForm(urlCallback);
		},
		title: App.polyglot.t('events'),
		listing: {},
		mainCollection: {},
		toastMessages: {
			removed: App.polyglot.t('eventDeleted')
		},
		hideSearchIcon: true,

		/**
   * @desc initialize
   */
		initialize: function (router, period, params, links) {

			this.availableActions = links;
			this.canAdd = true;
			this.canEdit = true;
			this.canDelete = true;
			this.name = 'events_' + period;
			this.period = period;

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				switch (period) {

					case "today":
						this.title = App.polyglot.t("today");
						params['period'] = ['today'];
						break;

					case "tomorrow":
						this.title = App.polyglot.t("forthcoming");
						params['period'] = ['tomorrow'];
						break;

					case "late":
						this.title = App.polyglot.t("past");
						params['period'] = ['late', 'past'];
						break;

				}

				this.subviews = [];
				this.template = "templates/agenda/eventList.twig";
				this.collection = new AgendaEntries();
				this.collection.mustFetchLocal = true;

				if (params.relatedtype && $.inArray(params.relatedtype, App.availableThirds) !== -1) {
					params.relatedtype = 'third';
				}

				this.collection.XHRData = {
					search: params
				};

				this.relatedid = params.relatedid;
				this.relatedtype = params.relatedtype;

				this.options = {

					extraData: {

						filtered: function () {

							return new Promise(function (resolve, reject) {

								if (params.relatedtype && params.relatedid) {
									params.link = App.GoToOverviewByRelated(params.relatedtype, params.relatedid);
									resolve(params);
								} else {
									resolve('');
								}
							});
						}

					}

				};

				this.var4template('period', period);

				var _this = this;

				if (period !== 'today') {

					this.latePeriodFilters = [{
						id: 'lastWeek',
						label: 'lastWeek'
					}, {
						id: 'lastMonth',
						label: 'lastMonth'
					}, {
						id: 'thisWeek',
						label: 'thisWeek'
					}, {
						id: 'thisMonth',
						label: 'thisMonth'
					}];

					this.tomorrowPeriodFilters = [{
						id: 'thisWeek',
						label: 'thisWeek'
					}, {
						id: 'thisMonth',
						label: 'thisMonth'
					}];

					if (this.listingFiltersCollection.get({ listing: this.name, name: 'periodFiltered' })) {
						this.defaultPeriodFiltered = [this.listingFiltersCollection.get({ listing: this.name, name: 'periodFiltered' }).get('value')];
					} else {
						this.defaultPeriodFiltered = [period === 'late' ? this.latePeriodFilters[0].id : this.tomorrowPeriodFilters[0].id];
					}

					this.collection.XHRData.search['periodFiltered'] = this.defaultPeriodFiltered[0];
					App.header.$el.find('nav').addClass('box-shadow-none');
				}

				if (this.listingFiltersCollection.get({ listing: this.name, name: 'staffs' })) {
					var staffFilter = this.listingFiltersCollection.get({ listing: this.name, name: 'staffs' }).get('value');
				} else {
					var staffFilter = [App.currentStaff.id];
					this.filter = new Filter({ listing: this.name, name: 'staffs', value: staffFilter });
					this.filter.saveLocal();
				}

				this.params = params;
				this.staffFilter = staffFilter;

				//On supprime les ocurrences de nos events récurrents dans la localDB pour faire place au nouveaux récupéré par le fetch
				//API request take every events without pagination
				//So we fetch it for save them in localDB and we do a fetchLocal during render for have the pagination
				this.refresh(params, staffFilter).then(_.bind(function () {

					this.collection.reset();
					this.collection.mustFetchLocal = true;
					this.listing = new Listing({ parentView: this });

					this.subviews.push(this.listing);
					this.render();
				}, this)).catch(function (e) {
					console.log(e);
				});
			}, this));
		},

		/**
  *
   * @param params
   * @param staffFilter
   */
		refresh: function () {

			return new Promise(_.bind(function (resolve, reject) {

				this.collection.clearRecurringOccurrences().then(_.bind(function () {

					this.collection.fetch({
						method: 'POST',
						data: {
							search: {
								period: this.params['period'],
								staffs: this.staffFilter
							}
						}
					}).then(function () {
						resolve();
					});
				}, this));
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;
			var period = this.collection.XHRData.search.period[0];

			//build stats with every events and then clear the collection for take just events of period selected
			this.collection.buildStats().then(_.bind(function () {

				//today period havent selectize for add filter on today period
				if (period === 'today') {
					$('#listing-selectize', this.$el).remove();
				} else {

					var renderFct = _.bind(function (data, escape) {
						var nbEvents = this.stats[period][data.id];

						return '<div>' + "<h1>" + escape(App.polyglot.t(data.label)) + "</h1>" + '<span class="nb-items">' + nbEvents + " " + '</span>' + '<span>' + App.polyglot.t("events") + '</span>' + '</div>';
					}, this.collection);

					this.selectizeObjects = [{
						domElt: $("#listing-selectize input", this.$el),
						create: false,
						valueField: 'id',
						labelField: 'label',
						searchField: ['label'],
						maxOptions: 99,
						maxItems: 1,
						options: period === 'late' ? this.latePeriodFilters : this.tomorrowPeriodFilters,
						onChange: function (value) {

							_this.filter = new Filter({ listing: _this.name, name: 'periodFiltered', value: value });

							_this.filter.updateLocal({ value: value }, true).then(function () {

								_this.listingFiltersCollection.add(_this.filter, { merge: true });

								_this.collection.reset();
								_this.defaultPeriodFiltered = [value];
								_this.collection.XHRData.search['periodFiltered'] = value;
								_this.collection.pagination.pagenum = 1;
								_this.collection.offsetOffline = 0;
								_this.setLoader($('.main-container', _this.$el), 0.6);
								_this.render();
							});
						},
						onInitialize: function () {
							$('.selectize-input', '#listing-selectize').after('<div id="selectize-overlay"></div>');
							$('.selectize-input input', '#listing-selectize').attr('readonly', true);
						},
						noDelete: true,
						hideSelected: true,
						render: {
							item: renderFct,
							option: renderFct
						}
					}];

					this.selectizeObjects[0]['defaultValues'] = this.defaultPeriodFiltered;
				}

				PageList.prototype.afterRender.apply(this, arguments);

				$('.hidden', "#listing-selectize").removeClass('hidden');

				this.listing.initSwipeEffect({
					popupid: 'event-option',
					removeMsg: this.toastMessages.removed,
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					getModel: function ($currentTarget) {
						var id = $currentTarget.attr('eventid');
						return _this.collection.get(id);
					},
					canDelete: function (model) {
						return model.get("isOwner") === 'Y';
					},
					canEdit: function (model) {
						return model.get("editable") === 'Y';
					},
					more: [{
						callback: function (model, $currentTarget) {
							_this.router.navigate(URLBuilder(["annotation", "form", "task", $currentTarget.attr('eventid')]), { trigger: true });
						},
						class: "commentAction",
						'fa': 'fa-comments'
					}],
					removeFunction: function (model, $currentTarget) {

						if (model.get('isEventRecurring') == 'Y') {
							new PopupRecurringAlert({
								parentView: _this,
								id: 'recurring-event',
								title: App.polyglot.t('checkRecurringEvent'),
								models: { event: model.toJSON() },
								isUpdate: false
							});
						} else {
							_this.removeConfirm.call(_this, model, '', $currentTarget, _this.toastMessages.removed, function () {
								$currentTarget.remove();
							});
						}
					}

				});
			}, this));
		}

	});
});
