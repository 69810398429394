/**
 * @description EventsFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/opportunitiesFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/funnels', 'models/collections/smarttags'], function (_, FilterPanel, App, Utils, ListingFilters, Funnels, SmartTags) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			// SmartTags parts. Get smarttags of linked
			this.smartTags = new SmartTags();
			this.smartTags.fetchOneTime = true;
			this.smartTags.url = '/rest/smarttags/search';
			this.smartTags.XHRData = {
				search: SmartTags.buildFilterByLinked('opportunity')
			};

			this.hideSavedSearch = true;
			this.collections[this.smartTags.name] = this.smartTags;

			FilterPanel.prototype.initialize.apply(this, [router, listName]);

			this.title = App.polyglot.t("opportunities");
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;

			var oppStatus = [];
			oppStatus.push({ label: App.polyglot.t('all'), value: '' });

			_.each(App.availableOppStatus, function (status) {
				oppStatus.push({ label: App.polyglot.t("opp" + status), value: status });
			});

			var filterList = {
				staffs: {
					label: App.polyglot.t('staffsAffected'),
					field: {
						type: 'select',
						name: 'staffs',
						offlineSearchField: 'staffid',
						values: App.staffs,
						isMultiple: true,
						allValue: true,
						labelAttr: 'fullName',
						valueAttr: 'id'
					}
				},
				status: {
					label: App.polyglot.t('status'),
					field: {
						type: 'input',
						name: 'status',
						offlineSearchField: 'signed',
						isMultiple: true,
						selectize: {
							domElt: 'input[name="filter[status]"]',
							placeholder: App.polyglot.t('status'),
							create: false,
							valueField: 'value',
							labelField: 'label',
							options: oppStatus
						}
					}
				},
				smarttags: {
					label: App.polyglot.t('smarttags'),
					field: {
						type: 'input',
						name: 'tags',
						offlineSearchField: 'smarttags',
						classes: "smarttags-selectize",
						isMultiple: false,
						selectize: {
							domElt: 'input[name="filter[tags]"]',
							placeholder: App.polyglot.t('smarttags'),
							create: false,
							valueField: 'word',
							labelField: 'word',
							searchField: ['word'],
							debounceTime: 1000,
							options: this.smartTags,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.smartTags.fetch({

										method: 'POST',
										data: { search: _.extend({ contains: query }, _this.smartTags.XHRData.search) }

									}, true).then(function () {
										resolve(_this.smartTags);
									});
								});
							}
						}

					}
				},
				nbDayInCurrentStep_start: {
					label: App.polyglot.t("nbStepInCurrentStep"),
					field: {
						classes: 'form-control material-input',
						type: 'number',
						name: 'nbDayInCurrentStep_start',
						placeholder: App.polyglot.t("between")
					}
				},
				nbDayInCurrentStep_end: {
					field: {
						classes: 'form-control material-input',
						type: 'number',
						name: 'nbDayInCurrentStep_end',
						placeholder: App.polyglot.t("and")
					}
				},
				score_start: {
					label: App.polyglot.t("Score"),
					field: {
						classes: 'form-control material-input',
						type: 'input',
						name: 'minScore',
						placeholder: App.polyglot.t("between")
					}
				},
				score_end: {
					field: {
						classes: 'form-control material-input',
						type: 'input',
						name: 'maxScore',
						placeholder: App.polyglot.t("and")
					}
				},
				periodAssignment_start: {
					label: App.polyglot.t("dateStartAssignementStaff"),
					field: {
						classes: 'form-control material-input',
						type: 'date',
						name: 'periodAssignment_start'
					}
				},
				periodAssignment_end: {
					label: App.polyglot.t("dateEndAssignementStaff"),
					field: {
						classes: 'form-control material-input',
						type: 'date',
						name: 'periodAssignment_end'
					}
				}

			};

			this.filters = filterList;
			return this.filters;
		}

	});
});
