define('tools/buildURL',['underscore'], function (_) {

	'use strict';

	return function (urlToBuild, hastag) {

		var url = _.isUndefined(hastag) ? '#' : hastag ? '#' : '';

		_.each(urlToBuild, function (fragment, index, URLArray) {
			url += index === URLArray.length - 1 ? fragment : fragment + '/';
		});

		return url;
	};
});
