/**
 * @desc Login view, extend Page View
 */
define('views/auth/login',['underscore', 'views/core/publicPage', 'views/core/form', 'tools/buildURL', 'tools/ajax', 'app', "db/easy"], function (_, PublicPage, Form, URLBuilder, Ajax, App, DB) {

	'use strict';

	return PublicPage.extend({

		events: {},
		id: 'login-page',
		subviews: [],
		models: {},
		setLoader: false,

		/**
   * @desc initialize
   */
		initialize: function (router, secret, token) {

			PublicPage.prototype.initialize.apply(this, arguments);

			//autoLog save tokens and go to home page
			if (secret && token) {

				var tokens = [{
					id: "oauth_token",
					token: decodeURI(token)
				}, {
					id: "oauth_token_secret",
					token: decodeURI(secret)
				}];

				DB.transaction("rw", DB["tokens"], function () {

					_.each(tokens, function (token) {
						DB['tokens'].put(token);
					});
				}).then(function () {
					App.isLogged = true;
					router.navigate('#', { trigger: false, replace: true });
					App.sellsyEasy.startRouting();
				});
			} else {

				this.router = router;
				this.template = 'templates/auth/login.twig';
				this.form = new Form({ parentView: this });

				this.subviews.push(this.form);

				this.listenTo(this.form, 'dataSend', this.formAction);
				this.render();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PublicPage.prototype.afterRender.apply(this, arguments);

			var userEmail = localStorage.getItem('userEmail');
			if (!_.isNull(userEmail)) {
				$('input[name="user[email]"]').val(userEmail);
			}

			$('#login-loader').addClass('hidden');
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var url = '/rest/auth/login';

			$('.fa-spin', this.$el).removeClass('hidden');

			var jqXHRLogin = Ajax({

				url: url,
				method: 'POST',
				dataType: "json",
				data: JSON.stringify(dataForm),
				error: function () {}

			});
			var $submitBtn = $('.submit-form', _this.form.$el);

			jqXHRLogin.success(function (apiResponse) {

				if (apiResponse.status === 'error') {

					$submitBtn.removeAttr('disabled');
					$('.fa-spin', this.$el).addClass('hidden');
					$('.form-error').show();
					$('.unknow-error').hide();
				} else if (apiResponse.status === 'pending' && !_.isUndefined(apiResponse.corps)) {
					//if user are not unique we show corp list

					$('.form-error').hide();
					$('.fa-spin', this.$el).addClass('hidden');
					var $loginList = _this.multiCorp(apiResponse.corps);

					$(".submit-wrapper", _this.$el).before($loginList);

					// set selected on previous selected corp
					if (localStorage.getItem('usercorp')) {
						$('.multilog-corp option', this.$el).filter(function () {
							return $(this).val() == localStorage.getItem('usercorp');
						}).attr('selected', true);
					}

					// submit form on corpid change
					$('.multilog-corp', this.$el).change(function () {
						$('.multilog-corp').closest('form').submit();
					});

					$submitBtn.removeAttr('disabled');
				} else {
					$('.form-error').hide();
					$('select', this.$el).prop('disabled', true);
					$('#login-loader').removeClass('hidden');

					try {

						var tokens = [{
							id: "oauth_token",
							token: apiResponse["oauth_token"]
						}, {
							id: "oauth_token_secret",
							token: apiResponse["oauth_token_secret"]
						}];

						DB.transaction("rw", DB["tokens"], function () {

							_.each(tokens, function (token) {
								DB['tokens'].put(token);
							});
						}).then(function () {

							//save email in local storage 
							localStorage.setItem('userEmail', dataForm.user['email']);

							//save corpid in local storage
							if (dataForm.user['corpid']) {
								localStorage.setItem('usercorp', dataForm.user['corpid']);
							}

							App.isLogged = true;
							App.sellsyEasy.initStartup();
						});
					} catch (error) {
						console.log(error);
					}
				}
			});

			jqXHRLogin.error(function () {
				$submitBtn.removeAttr('disabled');
				$('.fa-spin', this.$el).addClass('hidden');
				$('.form-error').hide();
				$('.unknow-error').show();
			});
			return false;
		},

		/**
   * 
   * @returns {String}
   */
		multiCorp: function (corps) {

			var html = '<div class="form-group">' + '<div id="select-wrapper" class="controls input-group input-material-group col-xs-12 ">' + '<label>' + App.polyglot.t('account') + '</label>' + '<select class="form-control material-input multilog-corp" name="user[corpid]">';

			_.each(corps, function (corp) {
				html += '<option value="staff_' + corp.userid + '">' + corp.accountCorp + ' - ' + corp.people_forename + ' ' + corp.people_name + '</option>';
			});

			html += '</select>' + '</div>' + '</div>';

			return html;
		}

	});
});
