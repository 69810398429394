/**
 *	@desc Model Currency, extend Core Model 
 */
define('models/singles/currency',['models/core/model', 'tools/ajax', 'db/easy', 'app'], function (Model, Ajax, DB, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			value: ''
		},

		storeName: 'currency',
		name: 'currency',
		urlRoot: '/rest/currency'

	}, {

		/**
  * 
  * @param {int} currencyId
  * @returns {undefined}
  */
		updateCurrency: function (currency, oldId) {

			return new Promise(function (resolve, reject) {
				Ajax({
					url: '/rest/currency/',
					method: 'post',
					data: JSON.stringify({ id: currency['id'] }),
					success: function (currencyId) {

						//update local currency selected and change old selected to N
						DB.transaction("rw", DB['currency'], function () {

							DB['currency'].update(parseInt(oldId), { selected: 'N' }).then(function () {
								DB['currency'].update(parseInt(currencyId), { selected: 'Y' });
							}, function (error) {
								console.log(error);
							});
						}).then(function () {

							//update number format
							App.numberFormat['currencysymbol'] = currency['symbol'];
							DB['corp'].update(App.corpId, { numberformat: App.numberFormat });
							resolve();
						}, function (error) {
							console.log(error);
						});
					},
					fail: function () {
						reject(arguments);
					}
				});
			});
		},

		/**
   * 
   * @param {string} symbol
   * @returns {Promise}
   */
		getCurrencyBySymbol: function (symbol) {

			return new Promise(function (resolve, reject) {

				DB['currency'].where('symbol').equals(symbol).first().then(function (currency) {
					resolve(currency);
				});
			});
		}

	});
});
