define('routers/auth',['routers/core/router'], function (CoreRouter) {

	'use strict';

	return CoreRouter.extend({

		/**
   * 
   */
		routes: {
			'auth(/:secret)(/:token)': 'login'
		},

		/**
   * 
   * @param {type} callback
   * @param {type} args
   * @returns {undefined}
   */
		execute: function (callback, args) {
			CoreRouter.prototype.execute.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} type
   * @param {type} id
   * @returns {undefined}
   */
		login: function (secret, token) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/auth/login'], function (require) {

					var LoginView = require('views/auth/login');
					var login = new LoginView(_this, secret, token);
					login.trueRoute = trueRoute;

					resolve(login);
				});
			});
		}

	});
});
