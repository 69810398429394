/**
 * @desc pay medium Pref, extend Page View
 */
define('views/setting/mediumPref',['underscore', 'views/core/page', 'views/core/form', 'tools/buildURL', 'models/singles/payMedium', 'models/collections/payMediums', 'app'], function (_, Page, Form, URLBuilder, PayMedium, PayMediums, App) {

	'use strict';

	return Page.extend({

		toastMessages: {
			payMedium: {
				removeMsg: App.polyglot.t("paymediumDeleted"),
				saveMsg: App.polyglot.t("paymediumUpdated")
			}
		},
		events: {
			"click .delete-enabled": "showRemoveConfirm",
			'click .btn-mediumLine': "newLine"
		},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/medium';
			this.collection = new PayMediums();
			this.collections[this.collection.name] = this.collection;
			this.title = this.polyglot.t("paymediums");
			this.payMedium = new PayMedium();
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			var checkedCollection = this.collection.takeChecked();

			//check paymediums checked by default
			checkedCollection.each(function (paymedium) {
				$('.medium-line[data-id="' + paymedium.id + '"] input.medium-checked').prop('checked', true);
			});

			$('input[data-is-writeabled=N]').prop('disabled', true);
		},

		/**
   * 
   * @returns {undefined}
   */
		showRemoveConfirm: function (e) {

			var $line = $(e.currentTarget).closest(".medium-line");
			var $id = $line.find('.medium-value').data('id');

			//if it's a new line just delete the line else show confirm for delete line on server too 
			if (_.isUndefined($id)) {
				$line.remove();
			} else {

				var callback = function () {
					$line.remove();
				}; // executed after suppression

				this.payMedium = this.collection.get($id);

				this.removeConfirm(this.payMedium, this.payMedium.get('value'), $line, this.toastMessages.payMedium.removeMsg, callback);
			}

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		newLine: function () {

			//clone a payMedium line
			var $line = $("#new-medium-line").clone();
			var $inputVal = $('.medium-value', $line);
			var $mediumChecked = $('.medium-checked', $line);

			//index of new line
			var index = $(".medium-line", "#mediumInfos").length - 1; // -1 for hidden line

			//inputVal name
			$inputVal.prop('name', index + "[value]");

			//medium status name
			$mediumChecked.prop('name', index + '[isChecked]');

			//remove data-uncatchable
			$inputVal.removeAttr('data-catchable');
			$mediumChecked.removeAttr('data-catchable');

			//insert new line in DOM
			$("#mediumInfos").append($line);

			//show the line
			$line.removeAttr('id');
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var collection2Save = new PayMediums();
			var mediums2Check = [];

			_.each(dataForm, function (medium, index) {
				//we dont add empty line
				if (medium.value) {
					collection2Save.add(medium);
				}
			});

			var arrayTemp = collection2Save.toJSON();

			if (collection2Save.length > 0) {

				//loader animation
				this.$el.append(_this.formActionSetLoader());
				collection2Save.save().then(function () {

					collection2Save.fetch({
						method: 'POST'
					}).then(function () {

						//take checked value in tempCollection
						collection2Save.each(function (payMedium, index) {
							//add new id to check in medium2check

							payMedium.set({ isChecked: arrayTemp[index].isChecked });

							if (payMedium.get("isChecked") === 'Y') {
								mediums2Check.push(payMedium.id);
							}
						});

						collection2Save.checkPaymediums(mediums2Check).then(function () {

							//end animation + toastNotif success
							$(".inprogress-mask", _this.$el).remove();
							_this.form.trigger('successful', _this.toastMessages.payMedium.saveMsg);
							_this.goToPreviousPage();
						});
					});
				});
			} else {
				_this.goToPreviousPage();
			}

			return false;
		}

	});
});
