/**
 *  @desc Emails Collection, extend core Collection
 */
define('models/collections/emails',['models/core/collection', 'models/singles/email', 'db/easy'], function (Collection, Email, DB) {

	'use strict';

	return Collection.extend({

		model: Email,
		storeName: 'email',
		name: 'emails',
		url: '/rest/email/list',
		fields: ['subject', 'message'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			Collection.apply(this, arguments);
			this.offlineQueryArgs = ['step', 'box'];
		}

	});
});
