/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/item',['models/singles/catalogue', 'tools/ajax'], function (Catalogue, Ajax) {

	'use strict';

	return Catalogue.extend({

		defaults: _.extend({
			id: 0,
			type: 'item',
			formattedType: 'produit',
			name: '',
			tradename: '',
			description: '',
			tax: 0,
			unity: ''
		}, Catalogue.prototype.defaults),

		urlRoot: '/rest/product/item'

	});
});
