/**
 *	@desc Shipping Model , extend Core Model 
 */
define('models/singles/shipping',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			unitAmountIsTaxesFree: 'N'
		},

		storeName: 'shipping',
		name: 'shipping',
		urlRoot: '/rest/shipping'

	});
});
