/**
 * @desc People router, extend Core Router
 */
define('routers/eventLog',['backbone', 'underscore', 'routers/core/router'], function (Backbone, _, CoreRouter) {

	'use strict';

	return CoreRouter.extend({

		routes: {
			'log/list(/:relatedtype)(/:relatedid)': 'listing'
		},

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		listing: function (relatedtype, relatedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/log/list'], function (require) {

					var LogView = require('views/log/list');
					var view = new LogView(_this, relatedtype, relatedid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		}

	});
});
