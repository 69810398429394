/**
 *  @description Header View extend Backbone View
 */
define('views/core/header',['jquery', 'app', 'views/core/view'], function ($, App, View) {

	return View.extend({

		el: '.navbar-fixed-top',
		viewName: 'header',
		events: {
			"click .menu,.mask-up": function (e) {
				this.parentView.sidebarAction.call(this, e);
				return false;
			},
			"click .icon-search a": function (e) {
				this.parentView.searchBarAction(e);
				return false;
			},
			"click .edit": function () {
				this.parentView.goToForm();
				return false;
			}
		},

		initialize: function (view) {

			this.parentView = view.parentView;
			this.events = _.extend(this.events, this.parentView.headerEvents);
			View.prototype.initialize.apply(this, arguments);

			this.hideSearchBar();

			this.$ = {
				title: $('#page-title', this.$el),
				brandMenu: $('.menu', this.$el),
				brandReturn: $('.return', this.$el),
				navbarHeader: $('nav', this.$el),
				actionList: $('#action-list', this.$el),
				rightSidebarTrigger: $('.rightSidebar-icone', this.$el)
			};
		},

		render: function () {},

		/**
   * 
   * @returns {undefined}
   */
		showSearchBar: function () {
			$('#wrapper-search').addClass('wrapper-search-up');
			$('#listing').css('padding-top', "50px");
		},

		hideSearchBar: function () {
			$('#wrapper-search').removeClass('wrapper-search-up');
			$('#listing').css('padding-top', "0");
		}

	});
});
