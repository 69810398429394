/**
 *  @desc Estimates Collection, extend core Collection
 */
define('models/collections/expenses',['models/core/collection', 'models/singles/expense', 'tools/ajax'], function (Collection, expense, Ajax) {

	'use strict';

	return Collection.extend({

		model: expense,
		storeName: 'expense',
		name: 'expenses',
		url: '/rest/expense/list',
		searchFieldName: 'ident',
		fields: ['ident'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {
			Collection.apply(this, arguments);
			this.offlineQueryArgs = ['ident', 'step'];
		},

		/**
   * 
   * @returns {Promise}
   */
		getNextIdent: function () {

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: '/rest/expense/nextident',
					dataType: "json",
					success: function (nextident) {
						resolve(nextident);
					},
					error: function () {
						reject();
					}
				});
			});
		}

	});
});
