/**
 * @desc Popup for add payment to invoice, Extend Popup core
 * Child View : Core Form
 */
define('views/product/popupRateCategories',['underscore', 'views/core/Popup', 'views/core/form', 'views/core/toastNotification', 'app'], function (_, Popup, Form, ToastNotification, App) {

	'use strict';

	return Popup.extend({

		models: {},
		collections: {},
		parentView: {},
		events: _.extend({}, Popup.prototype.events),

		toastMessage: {
			ratesRecorded: App.polyglot.t('ratesRecorded')
		},

		/**
   * @desc initialize
   */
		initialize: function (options, router) {

			Popup.prototype.initialize.apply(this, arguments);

			this.subviews = [];
			this.parentView = options.parentView;
			this.template = options.contentTemplate;
			this.router = router;
			this.classes = "form";
			this.models = options.models;

			this.options = {
				extraData: {
					product: function () {
						return new Promise(function (resolve, reject) {
							resolve(options.models);
						});
					}
				}
			};
		},

		/**
   * 
   * @returns {undefined}
   */
		startup: function () {

			this.form = new Form({ parentView: this }, $('.popup-content', this.$el));
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.listenTo(this, 'renderend', this.afterRender);

			this.initBtns();
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function (context) {

			var _this = this;
			var dataForm = this.form.dataForm;
			var rates = [];

			_.each(dataForm.ratecategories, function (amount, rcid) {

				rates.push({
					id: rcid,
					amount: amount,
					linkedtype: _this.models.type,
					linkedid: _this.models.id,
					declid: _this.models.declid
				});
			});

			// save rc on item
			_this.parentView.product.editRateCategories(rates).then(function () {

				_this.hide();

				_this.parentView.product.fetch().then(function () {
					_this.parentView.render();
					_this.form.trigger('successful', _this.toastMessage.ratesRecorded);
				});
			}, function () {

				console.log("error");

				new ToastNotification({
					id: 'toast-error',
					classes: 'col-xs-12 ',
					style: { 'margin-bottom': '70px' },
					timeDuration: 4000,
					content: App.polyglot.t('anErrorOccured')
				});
			});

			$('.submit-form', context).prop('disabled', false);
		}

	});
});
