define('permission',["app"], function (App) {

    return {

        /**
         *
         */
        can: function (permissionToCheck, byPassForAdmin) {

            byPassForAdmin = byPassForAdmin !== undefined ? byPassForAdmin : true;
            permissionToCheck = permissionToCheck.toLowerCase();
            var permission = App.currentStaff.get('permission');

            if (permission.isAdmin === "Y" && byPassForAdmin) {
                return true;
            } else {
                var permissions = permission.permissions;

                if (permissions[permissionToCheck] && permissions[permissionToCheck]['type'] === "checkbox") {

                    return permissions[permissionToCheck]["valuecheck"] === 'Y';
                } else {
                    return false;
                }
            }
        },

        /**
         *
         */
        level: function (levelToCheck) {

            levelToCheck = levelToCheck.toLowerCase();
            var permission = App.currentStaff.get('permission');

            if (permission.isAdmin === "Y") {
                return true;
            } else {

                var permissions = permission.permissions;

                if (permissions[levelToCheck] && permissions[levelToCheck]['type'] === "select") {
                    return permissions[levelToCheck]["valuecheck"] !== "none";
                } else {
                    return false;
                }
            }
        }

    };
});
