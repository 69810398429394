/**
 * @desc Product Overview, extend ToggleablePage View
 * Child View : Core Overview
 */
define('views/document/docOptions',['underscore', 'views/core/ToggleablePage', 'models/singles/item', 'models/singles/service', 'views/core/overview', 'tools/buildURL', 'app', 'tools/core/utils'], function (_, ToggleablePage, Item, Service, Overview, URLBuilder, App, Utils) {

	'use strict';

	return ToggleablePage.extend({

		id: 'docOptions',
		events: _.extend({}, ToggleablePage.prototype.events),

		actionList: {},

		options: {
			extraData: {}
		},

		/**
   * @desc initialize
   */
		initialize: function (router, options) {
			ToggleablePage.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		render: function () {

			var _this = this;

			this.buildOptions(this.modelsFetched.document);

			$.each(this.optionsList, function () {

				// loop on each buttons
				if (this.callbacks) {
					$.each(this.callbacks, function (key, value) {
						_this.events[key] = value;
					});
				}
			});

			this.overview = new Overview({ parentView: this, template: 'templates/document/options.twig' }, this.router);
			this.subviews.push(this.overview);

			ToggleablePage.prototype.render.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			this.delegateEvents();
			ToggleablePage.prototype.afterRender.apply(this, arguments);
		},

		/**
   * @desc action when we click on return arrow and when back is pressed for native app
   * @param {type} e
   * @returns {Boolean}
   */
		backHandler: function (e) {
			this.$el.trigger('hide');
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		onShow: function () {
			App.header.$el.find('nav').removeClass('box-shadow-none');
			var _this = this;
			$('#action-list').hide();
			$('.return', '.icon-wrapper-left').off();
			$('.return', '.icon-wrapper-left').one('click', this.backHandler.bind(this));
			$('#page-title').text(App.polyglot.t("docOptions"));
		},

		/**
   * 
   * @returns {undefined}
   */
		onHide: function () {
			App.header.$el.find('nav').addClass('box-shadow-none');
			this.renderHeader();
			$('#action-list').show();
			$('#page-title').text("");
		},

		buildOptions: function (document) {

			var _this = this;
			var isThirdLoadable = document.get('isThirdLoadable');

			var list = {
				sendByMail: {
					label: App.polyglot.t('sendByMail'),
					class: 'button-with-network',
					url: URLBuilder(["emailBox", "form", "none", "create", 0, document.get('doctype'), document.id])
				},
				dl: {
					label: App.polyglot.t('downloadpdf'),
					class: 'button-with-network',
					target: '_blank',
					url: App.fileUrl + document.get('publicLink').pdf
				},
				copydoc: {
					label: App.polyglot.t('copyDoc'),
					class: "button-with-network",
					id: 'copy-doc',
					callbacks: {
						'click #copy-doc': this.mainView.goToForm.bind(this.mainView, document.get('id'), document.get('thirdid'), true)
					},
					hasRight: document.get('doctype') !== "estimate" ? document.get('paydate').syscode !== 'deadlines' && App.permission.can('invoicing_create' + document.get('doctype')) && App.permission.level('clientlevel') : document.get('paydate').syscode !== 'deadlines' && App.permission.can('invoicing_create' + document.get('doctype')) && (App.permission.level('clientlevel') || App.permisison.level('prospectlevel'))
				}
			};

			switch (document.get('doctype')) {

				case 'invoice':

					var otherActions = {

						delivery: {
							class: 'button-with-network needLinkedLoadable',
							id: 'toDelivery',
							label: App.polyglot.t('delivery'),
							url: URLBuilder(['document', 'form', document.get('doctype'), document.get('thirdid'), document.get('id'), 'overview', 'delivery']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_deliverylevel') && App.permission.can('invoicing_createdelivery') && document.get('hasDeadlines') === "N"
						},
						creditNote: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('createlinkToCreditnote'),
							id: 'createCreditNote',
							url: URLBuilder(['document', 'form', document.get('doctype'), document.get('thirdid'), document.get('id'), 'overview', 'creditnote']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_invoicelevel') && App.permission.can('invoicing_createcreditnote') && document.get('hasDeadlines') === "N"
						}

					};

					break;

				case 'delivery':

					var otherActions = {

						invoice: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('charge'),
							url: URLBuilder(['document', 'form', 'delivery', document.get('thirdid'), document.get('id'), 'overview', 'invoice']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_deliverylevel') && App.permission.can('invoicing_createinvoice') && document.get('hasDeadlines') === "N"
						}

					};

					break;

				case 'estimate':

					var otherActions = {

						invoice: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('charge'),
							url: URLBuilder(['document', 'form', 'estimate', document.get('thirdid'), document.get('id'), 'overview', 'invoice']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_invoicelevel') && App.permission.can('invoicing_createinvoice') && document.get('thirdRelationType') !== 'prospect' && document.get('hasDeadlines') === "N"
						},
						delivery: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('delivery'),
							url: URLBuilder(['document', 'form', 'estimate', document.get('thirdid'), document.get('id'), 'overview', 'delivery']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_deliverylevel') && App.permission.can('invoicing_createdelivery') && document.get('thirdRelationType') !== 'prospect' && document.get('hasDeadlines') === "N"
						},
						order: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('order'),
							url: URLBuilder(['document', 'form', 'estimate', document.get('thirdid'), document.get('id'), 'overview', 'order']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_orderlevel') && App.permission.can('invoicing_createorder') && document.get('thirdRelationType') !== 'prospect' && document.get('hasDeadlines') === "N"
						},
						proforma: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('proforma'),
							url: URLBuilder(['document', 'form', 'estimate', document.get('thirdid'), document.get('id'), 'overview', 'proforma']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_proformalevel') && App.permission.can('invoicing_createproforma') && document.get('thirdRelationType') !== 'prospect' && document.get('hasDeadlines') === "N"
						}

					};

					break;

				case 'order':

					var otherActions = {

						invoice: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('charge'),
							url: URLBuilder(['document', 'form', 'order', document.get('thirdid'), document.get('id'), 'overview', 'invoice']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_invoicelevel') && App.permission.can('invoicing_createinvoice') && document.get('hasDeadlines') === "N"
						},
						delivery: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('delivery'),
							url: URLBuilder(['document', 'form', 'order', document.get('thirdid'), document.get('id'), 'overview', 'delivery']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_deliverylevel') && App.permission.can('invoicing_createdelivery') && document.get('hasDeadlines') === "N"
						},
						proforma: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('proforma'),
							url: URLBuilder(['document', 'form', 'order', document.get('thirdid'), document.get('id'), 'overview', 'proforma']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_proformalevel') && App.permission.can('invoicing_createproforma') && document.get('hasDeadlines') === "N"
						}

					};

					break;

				case 'proforma':

					var otherActions = {

						invoice: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('charge'),
							url: URLBuilder(['document', 'form', 'proforma', document.get('thirdid'), document.get('id'), 'overview', 'invoice']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_invoicelevel') && App.permission.can('invoicing_createinvoice') && document.get('hasDeadlines') === "N"
						},

						delivery: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('delivery'),
							url: URLBuilder(['document', 'form', 'proforma', document.get('thirdid'), document.get('id'), 'overview', 'delivery']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_deliverylevel') && App.permission.can('invoicing_createdelivery') && document.get('hasDeadlines') === "N"
						},

						order: {
							class: 'button-with-network needLinkedLoadable',
							label: App.polyglot.t('order'),
							url: URLBuilder(['document', 'form', 'proforma', document.get('thirdid'), document.get('id'), 'overview', 'order']),
							hasRight: isThirdLoadable && App.permission.level('invoicing_orderlevel') && App.permission.can('invoicing_createorder') && document.get('hasDeadlines') === "N"
						}

					};

					break;

			}

			this.optionsList = _.extend(list, otherActions);

			this.optionsList['remove'] = {
				id: 'remove-document',
				class: 'button-with-network',
				label: App.polyglot.t("delete"),
				hasRight: App.permission.can("invoicing_delete" + document.get('doctype')),
				callbacks: {
					'click #remove-document': _this.mainView.showPopupConfirm.bind(_this.mainView)
				}
			};

			var sidebarEvents = {

				'click .needLinkedLoadable': function () {

					if (document.get('thirdStatus') !== 'ok') {

						new ToastNotification({
							id: 'toast-error',
							classes: 'col-xs-12',
							timeDuration: 3000,
							content: App.polyglot.t("cantCreateDocForDeletedThird")
						});

						return false;
					}
				}
			};

			_this.var4template("optionsList", this.optionsList);
		}

	});
});
