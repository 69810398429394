/**
 *  @desc Supports Collection, extend core Collection
 */
define('models/collections/ticketConversations',['models/core/collection', 'models/singles/ticketConversation'], function (Collection, TicketConv) {

	'use strict';

	return Collection.extend({

		model: TicketConv,
		storeName: 'supportconversations',
		name: 'conversations',
		url: '/rest/support/conversationslist',

		pagination: {
			pagenum: 1,
			nbperpage: 10
		},

		/**
   * 
   * @param {type} elemsToAdd
   * @returns {undefined}
   */
		massAdd: function (models) {

			var _this = this;

			$.each(models, function (index, model) {
				var tempConv = new TicketConv();
				tempConv.set(model);
				//				console.log(tempConv);
				_this.add(tempConv);
			});
		}

	});
});
