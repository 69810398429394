/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/supportTemplates',['models/core/collection', 'models/singles/supportTemplate'], function (Collection, SupportTemplate) {

	'use strict';

	return Collection.extend({

		model: SupportTemplate,
		storeName: 'supportTemplates',
		name: 'supportTemplates',
		url: '/rest/support/templates'

	});
});
