/**
 *	@desc Model Order, extend Document Model 
 */
define('models/singles/order',['models/singles/document'], function (Document) {

	'use strict';

	return Document.extend({

		defaults: _.extend({

			id: 0,
			doctype: 'order',
			ident: '',
			thirdName: '',
			formattedDocType: 'bon de commande',
			availableSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled']

		}, Document.prototype.defaults),

		urlRoot: '/rest/document/order',
		isEditable: function () {
			return !("advanced" === this.get('step') || "invoiced" === this.get('step') || "accepted" === this.get('step'));
		},
		isStepAllowed: function (step) {
			return true;
		}

	});
});
