/**
 * @desc Expense Overview, extend PageOverview View
 * Child View : Core Overview
 */
define('views/expense/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/expense', 'app', 'tools/buildURL', 'models/collections/accountings', 'views/core/Popup', 'views/core/toastNotification', 'models/collections/smarttags', 'views/widgets/tabs', 'views/widgets/nativeActionSheet', 'royalslider'], function (_, PageOverview, Overview, Expense, App, URLBuilder, Accountings, Popup, ToastNotification, Smarttags, Tabs, ActionSheet) {

	'use strict';

	return PageOverview.extend({

		events: _.extend({
			'change #expenses-addimg': 'addJustif',
			'click #nojustif-add': function () {
				$('#expenses-addimg').click();
			}
		}, PageOverview.prototype.events),

		popupConfirmOptions: {},
		listing: {},
		toastMessages: {
			justifAdded: App.polyglot.t('justifadded'),
			justifDeleted: App.polyglot.t('justifdeleted'),
			justifUploading: App.polyglot.t('justifuploading'),
			justifWrongFormat: App.polyglot.t('justifwrongformat'),
			ExpenseCantEdit: App.polyglot.t('expenseCantEdit')
		},

		/**
   * @desc initialize
   */
		initialize: function (router, id) {

			this.canAdd = App.permission.can('expenses_createexpense');
			this.canDelete = App.permission.can('expenses_deleteexpense');
			this.canEdit = App.permission.can('expenses_editexpense');
			this.smartTagsFilter = Smarttags.buildFilterByLinked('expense', id);

			PageOverview.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = "templates/expense/overview.twig";
			this.expense = new Expense({ id: id });
			this.models[this.expense.name] = this.expense;

			if (App.pluginIsLoaded("accounting")) {

				this.accounting = new Accountings();
				this.accounting.XHRData = {
					search: {
						view: 'purchase'
					}
				};
				this.collections[this.accounting.name] = this.accounting;
			}

			this.overview = new Overview({ parentView: this }, this.router);
			this.subviews.push(this.overview);
			this.subviews.push(new Tabs('.header-overview', [{
				label: App.polyglot.t("details"),
				for: 'details',
				active: true
			}, {
				label: App.polyglot.t("justif"),
				for: 'justif'
			}]));
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;
			var step = this.expense.get('step');

			PageOverview.prototype.afterRender.apply(this, arguments);

			if (step !== "draft") {
				$('.deleteAction').remove();
			}

			if (this.canEdit) {

				if (step == 'accepted') {
					$(".editAction", App.header.$el).remove();
				}
			}

			// if there is justif, transform div to royalSlider
			if (_.size(this.expense.get('justif')) > 0) {

				$('.royalSlider').royalSlider({
					arrowsNav: true,
					loop: true,
					keyboardNavEnabled: true,
					controlsInside: false,
					imageScaleMode: 'fit',
					imageAlignCenter: true,
					arrowsNavAutoHide: false,
					controlNavigation: 'bullets',
					thumbsFitInViewport: true,
					navigateByClick: true,
					autoPlay: false,
					transitionType: 'move'
				});

				this.royalSlider = $(".royalSlider").data('royalSlider');

				$('a[href="#justif"]').click(function () {
					setTimeout(function () {
						_this.royalSlider.updateSliderSize();
					}, 300);
				});
			} else {
				this.$('.royalSlider').remove();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		addJustif: function (e) {

			var _this = this;
			var file = e.currentTarget.files[0];
			var form_data = new FormData($(e.currentTarget).closest('form')[0]);

			form_data.append('fileToUpload', file);

			if (this.checkButton(e)) {

				if (App.useActionSheet) {

					new ActionSheet({
						title: _this.polyglot.t('doyouwanttoaddjustif'),
						buttons: [{
							title: _this.polyglot.t('add'),
							callback: _this.sendJustif.bind(_this, form_data)
						}],
						subtitle: file.name,
						cancelLabel: App.polyglot.t('cancel')
					});
				} else {

					new Popup({
						id: "modal-addjustif",
						title: _this.polyglot.t('doyouwanttoaddjustif') + "?",
						buttons: [{ title: _this.polyglot.t('add'), classes: "btn btn-submit", onclick: _this.sendJustif.bind(_this, form_data) }, { title: _this.polyglot.t('cancel'), classes: "btn btn-cancel" }],
						mergeBtns: false,
						data: {}
					});
				}
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		sendJustif: function (form_data) {

			var _this = this;

			var toastWaiting = new ToastNotification({
				id: 'toast-success',
				classes: 'col-xs-12 ',
				timeDuration: 40000,
				content: _this.toastMessages.justifUploading
			});

			this.expense.sendJustif(form_data).then(function () {

				toastWaiting.hideToast();

				_this.render();

				new ToastNotification({
					id: 'toast-success',
					classes: 'col-xs-12 ',
					timeDuration: 4000,
					content: _this.toastMessages.justifAdded
				});
			});
		},

		/**
   * @desc change the Header title to the name opp
   */
		renderTitle: function (model) {
			this.title = model.get('ident');
			App.header.$.title.text(this.title);
		},

		/**
   * 
   * @returns {Boolean}
   */
		goToForm: function () {
			this.router.navigate(URLBuilder(['expense', "form", this.expense.id]), { trigger: true });
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		showPopupConfirm: function () {

			var callback = this.router.navigate.bind(this, URLBuilder(['expense', "list"]), { trigger: true }); // executed after suppression
			this.removeConfirm(this.expense, this.expense.get('ident'), this.$el, this.toastMessages.justifDeleted, callback);

			return false;
		}

	});
});
