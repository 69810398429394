/**
 * @desc Briefcase router, extend Core Router
 */
define('routers/briefcase',['backbone', 'underscore', 'routers/core/router'], function (Backbone, _, CoreRouter) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "briefcase",

		routes: {
			'briefcase/list(/)(:linkedtype)(/)(:linkedid)': 'listing'
		},

		/**
   * 
   */
		checkBefore: {

			listing: function () {
				return App.permission.can('briefcase');
			}

		},

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} comefrom
   * @returns {undefined}
   */
		listing: function (linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/briefcase/list'], function (require, Listing) {

					var view = new Listing(_this, linkedtype, linkedid);

					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		}

	});
});
