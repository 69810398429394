/**
 *  @desc Invoices Collection, extend core Collection
 */
define('models/collections/invoices',['models/collections/documents', 'models/singles/invoice'], function (Documents, Invoice) {

	'use strict';

	return Documents.extend({

		model: Invoice,
		storeName: 'document',
		url: '/rest/document/list',
		doctype: 'invoice',
		availableSteps: ['draft', 'due', 'payinprogress', 'paid', 'late', 'cancelled']

	});
});
