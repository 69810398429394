/**
 * @desc Mail List, extend PageList View
 * Child View : Core Listing
 */
define('views/emailBox/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/emailThreads', 'models/singles/emailThread', 'app', 'tools/core/utils'], function (_, PageList, Listing, URLBuilder, EmailThreads, EmailThread, App, Utils) {

	'use strict';

	return PageList.extend({

		events: {
			'click .mark-as-important': 'toggleStarred'
		},
		id: "emailbox-list",
		name: "mails",
		hideFilterPannel: true,
		params: {},
		listing: {},
		toastMessages: {
			email: {
				emailRemoved: App.polyglot.t("emailRemoved")
			}
		},
		addImg: Utils.loadFile('new_mail.svg', 'images'),
		addLabel: App.polyglot.t('newMessage'),
		addAction: function () {
			this.listing.goToForm(this.urlToMailForm);
		},

		template: "templates/emailbox/list.twig",

		/**
   * @desc initialize
   */
		initialize: function (router, box, linkedtype, linkedid, links, folders) {

			this.params = {};
			this.availableActions = links;
			this.canAdd = true;
			this.canDelete = true;

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.collection = new EmailThreads();

				this.folderLabel = folders.get(box).get('id');
				this.collection.XHRData = {
					search: {
						'ownerid': App.currentStaff.id,
						'folders': box,
						'folder': this.folderLabel
					}
				};

				var _this = this;
				this.placeholderSearchBar = App.polyglot.t('subject') + ', ' + App.polyglot.t('message');

				if (linkedtype && linkedid) {

					this.params = _.extend(this.params, {
						linkedid: linkedid,
						linkedtype: linkedtype,
						folder: box
					});

					if ($.inArray(linkedtype, App.availableThirds) !== -1) {
						this.params['linkedtype'] = 'third';
					}

					this.urlToMailForm = URLBuilder(["emailBox", 'form', this.folderLabel, 'create', '0']);

					if (this.params['linkedtype']) {
						this.urlToMailForm = URLBuilder([this.urlToMailForm, this.params['relatedtype'] ? this.params['relatedtype'] : this.params['linkedtype'], this.params['relatedid'] ? this.params['relatedid'] : this.params['linkedid']], false);
					}

					this.params['link'] = App.GoToOverviewByRelated(linkedtype, linkedid);

					this.options = {

						extraData: {

							linked: function () {

								return new Promise(function (resolve, reject) {
									resolve(_this.params);
								});
							},

							boxId: function () {

								return new Promise(function (resolve, reject) {
									resolve(box);
								});
							}

						}

					};

					this.linkedid = linkedid;
				} else {
					this.var4template("boxId", box);
					this.urlToMailForm = URLBuilder(['emailBox', 'form', 'create']);
				}

				this.collection.XHRData.search = _.extend(this.collection.XHRData.search, this.params);
				this.listing = new Listing({ parentView: this });
				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageList.prototype.afterRender.apply(this, arguments);
			var _this = this;

			if (this.folderLabel !== 'trash') {

				//			//init material effect on each row of listing
				this.listing.initSwipeEffect({

					removeMsg: this.toastMessages.email.emailRemoved,
					onModify: false,
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					popupid: 'remove-option',
					modelName: "subject",
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					}
				}, '', this);
			}
		},

		/**
   *
   */
		toggleStarred: function (e) {

			e.stopPropagation();

			var $star = $(e.currentTarget);
			var newVal = $star.hasClass('fa-bookmark-o') ? 'Y' : 'N';
			var _this = this;

			var $row = $star.closest('.mail-row');
			var id = $row.data("id");
			var mail = new EmailThread({ id: id });

			$row.append(this.formActionSetLoader());

			mail.toggleStarred(newVal).then(function () {

				if (newVal == 'Y') {
					$star.removeClass('fa-bookmark-o').addClass('fa-bookmark');
				} else {
					$star.removeClass('fa-bookmark').addClass('fa-bookmark-o');
				}

				$('.inprogress-mask', $row).remove();
			});

			return false;
		}

	});
});
