/**
 * @desc Contact Form, extend Page View
 * Child View : Core Form
 */
define('views/nylas/login',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'views/nylas/knowProviderForm', 'views/nylas/smtpForm', 'tools/buildURL', 'models/singles/nylasAccount', 'app'], function (Backbone, _, Page, Form, KnowProviderForm, SmtpForm, URLBuilder, NylasAccount, App) {

    'use strict';

    return Page.extend({

        events: {
            "click .mailbox-choice": function (e) {

                var provider = $(e.currentTarget).attr('id');

                switch (provider) {

                    case 'gmail':

                        gapi.load('client', this.initGoogleAuth.bind(this));

                        break;

                    case 'aol':
                    case 'exchange':
                    case "yahoo":
                    case "hotmail":
                        this.knowProviderForm.$el.trigger('show', provider);
                        break;

                    default:
                        this.smtpForm.$el.trigger('show', provider);
                        break;

                }
            }
        },

        title: App.polyglot.t('addAnAccount'),
        form: {},
        modelToSave: {},
        template: "templates/auth/nylasConnect.twig",

        /**
         *
         * @param {Router} router
         * @param {int} linkedId
         */
        initialize: function (router, type) {

            Page.prototype.initialize.apply(this, arguments);

            this.form = new Form({ parentView: this });
            this.knowProviderForm = new KnowProviderForm(router, {
                mainView: this
            });

            this.smtpForm = new SmtpForm(router, {
                mainView: this
            });

            this.subviews.push(this.form);

            this.toggleableViews.push(this.knowProviderForm);
            this.toggleableViews.push(this.smtpForm);

            this.type = type;

            this.render();
            this.listenTo(this.form, 'dataSend', this.formAction);
        },

        initGoogleAuth: function () {
            var _this = this;

            var loginFunction = function () {
                window.plugins.googleplus.login({
                    'scopes': 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://mail.google.com/ https://www.google.com/m8/feeds https://www.googleapis.com/auth/calendar', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
                    'webClientId': '448732199441-f1scpudp6sh1ts1kupmqji9adeng2le3.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
                    "offline": true
                }, function (obj) {

                    console.log(obj);

                    //for android we need to exchange authorizationCode for have refreshToken
                    if (navigator.os === 'android' || navigator.os == "ios") {
                        $.ajax({
                            url: "/rest/auth/googleAuthorize",
                            method: "POST",
                            data: {
                                serverAuthCode: obj.serverAuthCode
                            },
                            success: function (data) {

                                data = JSON.parse(data);

                                var dataForm = {
                                    "nylas": {
                                        "email": obj.email,
                                        "name": obj.displayName,
                                        "provider": "gmail",
                                        "settings": {
                                            "google_refresh_token": data.refresh_token
                                        }
                                    }
                                };
                                _this.nylasAuthorizeRequest(dataForm);
                            }
                        });
                    } else {

                        var dataForm = {
                            "nylas": {
                                "email": obj.email,
                                "name": obj.displayName,
                                "provider": "gmail",
                                "settings": {
                                    "google_refresh_token": obj.refreshToken
                                }
                            }
                        };
                        _this.nylasAuthorizeRequest(dataForm);
                    }
                }, function (msg) {
                    console.log(msg);
                });
            };

            window.plugins.googleplus.logout(function () {
                loginFunction();
            }, function (error) {
                loginFunction();
            });
        },

        /**
         * @description : triggered when dom is ready and all models data are fetched
         */
        afterRender: function () {

            Page.prototype.afterRender.apply(this, arguments);

            var isAuthorized;
            var currentApiRequest;
        },

        /**
         *  @desc action when form is submit
         */
        nylasAuthorizeRequest: function (dataForm) {

            var _this = this;

            $.ajax({
                url: "/rest/emailBox/nylasConnect",
                method: "POST",
                dataType: "json",
                data: JSON.stringify(dataForm['nylas']),
                success: function (data) {

                    data = JSON.parse(data);

                    if (data.code) {

                        $.ajax({
                            url: "/rest/emailBox/nylasToken",
                            method: "POST",
                            data: JSON.stringify({
                                code: data.code
                            }),
                            success: function (data) {

                                var nylasAccount = new NylasAccount(JSON.parse(data));
                                nylasAccount.unset('id');
                                nylasAccount.set({ type: _this.type });
                                nylasAccount.save().then(function () {
                                    _this.router.navigate("#accountPref/nylas", { trigger: true, replace: true });
                                });
                            }
                        });
                    }
                }
            });

            $('.submit-form', this.form.$el).removeAttr('disabled');

            return false;
        }

    });
});
