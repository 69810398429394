/**
 *  @desc Stats Collection, extend core Collection
 */
define('models/collections/stats',['models/core/collection', 'models/singles/stat', 'db/easy'], function (Collection, Stat, DB) {

	'use strict';

	return Collection.extend({

		constructor: function () {
			Collection.apply(this, arguments);
			this.offlineQueryArgs = ["type"];
		},

		model: Stat,
		storeName: 'stats',
		name: 'stats',
		url: '/rest/stats/'

	});
});
