/**
 *  @desc Suppliers Collection, extend core Collection
 */
define('models/collections/suppliers',['models/collections/thirds', 'models/singles/supplier'], function (Thirds, Supplier) {

	'use strict';

	return Thirds.extend({

		model: Supplier,
		storeName: 'third',
		name: 'suppliers',
		url: '/rest/supplier/list',
		relationType: 'supplier'

	});
});
