/**
 *	@desc purInvoice Model , extend Core Model 
 */
define('models/singles/purInvoice',['models/singles/purchase', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: "0",
			doctype: 'invoice',
			availableSteps: ['draft', 'due', 'payinprogress', 'paid', 'late', 'cancelled']
		},

		/**
   * 
   * @param {type} nextStep
   * @returns {Boolean}
   */
		isStepAllowed: function (nextStep) {

			var currentStep = this.get('step');
			this.stepError = '';

			switch (currentStep) {

				case 'late':

					if (nextStep === 'draft') {
						this.stepError = 'Une facture en retard ne peux plus passer en brouillon';
					} else if (nextStep === 'due') {
						this.stepError = 'Une facture en retard ne peux plus passer en à régler';
					}

					break;

				case 'payinprogress':

					if (nextStep !== 'paid') {
						this.stepError = "Pour changer le statut d'une facture payée ou en cours de paiement, commencez par supprimer le(s) paiement(s) rattaché(s). Pour ce faire, allez sur la liste des paiements";
					}

					break;

				case 'paid':

					this.stepError = "Pour changer le statut d'une facture payée ou en cours de paiement, commencez par supprimer le(s) paiement(s) rattaché(s). Pour ce faire, allez sur la liste des paiements";

					break;

			}

			return this.stepError ? false : true;
		},

		urlRoot: '/rest/purchase/invoice'

	});
});
