/**
 *  @desc purInvoices Collection, extend core Collection
 */
define('models/collections/purInvoices',['models/collections/purchases', 'models/singles/purInvoice'], function (Purchases, Invoice) {

	'use strict';

	return Purchases.extend({

		model: Invoice,
		doctype: 'invoice',
		availableSteps: ['draft', 'due', 'payinprogress', 'paid', 'late', 'cancelled']

	});
});
