/* Main entry point */
'use strict';

var starting = function () {

  if (!isStarted) {
    //prevent multing launching

    isStarted = true;

    require(['jquery', 'starter', 'app', 'db/easy'], function ($, Starter, App, DB) {

      // load cordova files if its a native app
      require(App.loadCordovaIfNative(), function (cordova) {

        var nbAttempt = 0;

        try {
          localStorage.setItem("test", 'test');
          App.localStorage = true;
        } catch (e) {
          App.localStorage = false;
        }

        DB.open().then(function () {

          App.indexedDB = true;

          if (App.localStorage) {

            var starter = new Starter();
            App.sellsyEasy = starter;
            starter.startRouting();
          } else {

            $.ajax({
              url: '/deviceNotSupported',
              success: function (response) {
                $('body').html(response);
              }
            });
          }
        }, function () {

          App.indexedDB = false;

          //try to remove indexedDB and re launch the app
          //after 2 attempt, display error page
          if (nbAttempt > 2) {
            $.ajax({
              url: '/deviceNotSupported',
              success: function (response) {
                $('body').html(response);
              }
            });
          } else {
            DB.delete().then(function () {
              starting();
            }, function () {
              console.log(arguments);
            });
          }

          nbAttempt++;
        });
      });
    });
  }
};

var triggered = false;
var isStarted = false;
var appCache = window.applicationCache;

if (appCache) {

  // cache update in progress
  appCache.addEventListener('progress', function (e) {

    triggered = true;

    if (e.total > 7) {

      var translation = document.getElementById("cache-translation");
      var enums = document.getElementById("cache-enum");

      if (enums && translation) {

        var lang = navigator.language || navigator.userLanguage;
        switch (lang) {
          case 'fr':
            translation.innerHTML = 'Sauvegarde locale des fichiers';
            break;
          case 'en':
            translation.innerHTML = 'Local backup';
            break;
          default:
            translation.innerHTML = 'Local backup';
            break;
        }

        enums.innerHTML = e.loaded + '/' + e.total;
      }
    }
  }, false);

  // Check if a new cache is available.
  appCache.addEventListener('updateready', function () {
    console.log("updateready");
    triggered = true;
    window.applicationCache.swapCache();
    location.reload();
  });
  // Fired after the first cache of the manifest.
  appCache.addEventListener('cached', function () {
    console.log("cached");
    triggered = true;
    starting();
  });
  // Fired after the first download of the manifest.
  appCache.addEventListener('noupdate', function () {
    console.log("noupdate");
    triggered = true;
    starting();
  });
  // triggered  if the download failed
  appCache.addEventListener('error', function () {
    console.log("error");
    triggered = true;
    starting();
  });

  setTimeout(function () {
    if (!triggered) {
      triggered = true;
      starting();
    }
  }, 1000);
} else {
  starting();
};
define("main", function(){});

