/**
 * @desc DocRow Form, extend Page View
 * Child View : Core Form
 */
define('views/document/docRowForm',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/collections/taxes', 'models/collections/unities', 'models/collections/products', 'models/collections/packagings', 'models/collections/shippings', "models/collections/accountings", "models/singles/currency", "models/singles/document", 'models/singles/rateCategory', 'models/singles/catalogue', 'models/singles/shipping', 'models/singles/packaging', 'tools/buildURL', 'big', 'app', 'tools/math', 'models/collections/warehouses', 'views/core/accordion', 'tools/numberFormat', 'tinyMCE', 'moment', 'tools/core/utils', 'selectize'], function (Backbone, _, Page, Form, Taxes, Unities, Products, Packagings, Shippings, Accountings, Currency, Document, RateCategory, CatalogueProduct, Shipping, Packaging, URLBuilder, Big, App, Math, Warehouses, Accordion, NumberFormat, TinyMCE, Moment, Utils) {

	'use strict';

	return Page.extend({

		events: {
			'change .quantity': 'sumLine',
			'change .tax-line select': 'sumLine',
			'change .unit-amount-line': 'sumLine',
			'change .discount-row select': 'changeInputdiscount',
			'change .discount-row input': 'sumLine',
			'change select[name="row[whid]"]': 'changeSerials',
			'change input[name="row[ecoTaxDisplay]"]': 'computeEcoTaxHT'
		},

		title: App.polyglot.t('addline'),
		id: "doc-row-form",
		form: {},
		toastMessages: {
			rowAdded: App.polyglot.t('rowAdded'),
			rowUpdated: App.polyglot.t('rowUpdated')
		},

		availableDoctype4Serials: ['invoice', 'delivery', 'creditnote'],

		/**
   *
   * @param {Router} router
   * @param {int} thirdId
   */
		initialize: function (router, doctype, thirdid, docid, langid, rowindex, linkedid, declid) {

			docid = _.isUndefined(docid) ? 0 : docid;
			this.previousLink = router.navigate.bind(this, URLBuilder(['document', 'form', doctype, thirdid, docid, 'docRowForm']), { trigger: true, replace: true });
			this.langid = langid;
			this.thirdid = thirdid;

			Page.prototype.initialize.apply(this, arguments);

			var _this = this;

			Document.classNameByDoctype(doctype).then(_.bind(function (ClassName) {

				this.unities = new Unities();
				this.modelToSave = new ClassName({ id: 0 });
				this.doctype = doctype;
				this.rowindex = rowindex;
				this.linkedid = linkedid;
				this.declid = declid;
				this.taxes = new Taxes();

				this.collections[this.unities.name] = this.unities;
				this.collections[this.taxes.name] = this.taxes;

				if (App.pluginIsLoaded('accounting')) {

					this.accountings = new Accountings();
					this.collections[this.accountings.name] = this.accountings;
					this.accountings.XHRData = {
						search: {
							view: 'sell'
						}
					};
				}

				/**
     * In case of row update we don't take linked items because linked item are not updatable
     */
				if (!this.rowindex) {

					this.packagings = new Packagings();
					this.shippings = new Shippings();

					this.products = new Products();
					this.products.XHRData = {
						type: 'item',
						search: {
							thirdid: thirdid,
							usePromoOn: 'useOnDoc',
							langID: langid,
							combineDecli: 'Y'
						}
					};

					this.services = new Products();
					this.services.XHRData = {
						type: 'service',
						thirdid: thirdid,
						langID: langid
					};

					this.collections[this.packagings.name] = this.packagings;
					this.collections[this.shippings.name] = this.shippings;
				}

				if (App.pluginIsLoaded('stock')) {
					this.isStockLoaded = true;
					this.warehouses = new Warehouses();
					this.warehouses.mustFetchLocal = true;
					this.collections[this.warehouses.name] = this.warehouses;
				}

				this.template = "templates/document/docRowForm.twig";
				this.router = router;

				this.options = {

					extraData: {

						//take tempDoc in localDB
						document: function () {

							return new Promise(function (resolve, reject) {

								var promises = [];

								_this.modelToSave.fetchLocal().then(function () {

									if (_this.rowindex) {
										//In row update, take linked obj in localDB

										_this.row = _this.modelToSave.get('rows')[_this.rowindex];

										//en cas de modification de row pour une update de doc, on doit récuperer via l'API les codes barres lié à l'item
										if (_this.row.id && _this.row.type === "item") {

											promises.push(CatalogueProduct.getBarCodes(_this.row.linkedid).then(function (bcs) {
												_this.row['barCodes'] = bcs;
											}));
										}
									}

									_this.rateCategory = new RateCategory({ id: _this.modelToSave.get('rateCategory') });
									_this.numberFormat = _this.modelToSave.get('num_format') ? $.extend(true, {}, new NumberFormat(_this.modelToSave.get('num_format'))) : $.extend(true, {}, App.numberFormat); //init docNumberformat

									_this.modelToSave.set({ 'numberFormat': _this.numberFormat });

									promises.push(_this.rateCategory.fetchLocal());

									Promise.all(promises).then(function () {

										//update numberFormatCurrency with currency of rateCategory if doc havent already a number format define in this temp prefs
										if (_this.rateCategory.get('currencyid') != 0 && !_this.modelToSave.get('num_format')) {

											var currency = new Currency({ id: _this.rateCategory.get('currencyid') });

											currency.fetchLocal().then(function () {
												_this.numberFormat.currency.symbol = currency.get('symbol');
												resolve(_this.modelToSave);
											});
										} else {
											resolve(_this.modelToSave);
										}
									});
								});
							});
						}
					}
				};

				this.form = new Form({ parentView: this });
				this.subviews.push(this.form);

				this.listenTo(this.form, 'dataSend', this.formAction);
				this.render();
			}, _this));
		},

		/**
   * @description : triggered when dom is ready and all models data are fetched
   */
		afterRender: function () {

			var _this = this;
			this.isTTC = this.modelToSave.get('hasTaxesInc') === 'Y' ? true : false;

			var rowTypes = [{ type: 'shipping', label: App.polyglot.t('shipper') }, { type: 'packaging', label: App.polyglot.t('packaging') }];

			if (App.permission.level("itemlevel") || !App.permission.level('itemlevel') && this.rowindex) {
				rowTypes.push({ type: 'item', label: App.polyglot.t('catalog') });
			}

			if (App.permission.can("invoicing_doconceline")) {
				rowTypes.splice(1, 0, { type: 'once', label: 'Simple' });
			}

			var rowTypeSelectize = {
				domElt: $(".row-type", this.$el),
				create: false,
				valueField: 'type',
				labelField: 'label',
				options: rowTypes,
				maxItems: '1',
				maxOptions: 10,
				placeholder: App.polyglot.t('rowType'),
				onChange: function (id) {
					_this.onTypeChange(id);
				}
			};

			var itemSelectize = {
				domElt: $('.product-name', this.$el),
				create: false,
				valueField: 'buildedId',
				labelField: 'name',
				maxItems: '1',
				maxOptions: 10,
				placeholder: App.polyglot.t('tradename'),
				render: 'productWithPrice',
				onChange: function (id, item) {
					_this.onItemChange(id, item);
				}
			};

			/**
    * In case of row update, we can't modify linked item, so selectize are simplified
    */
			if (!this.rowindex) {

				this.products.add(this.services.toJSON());

				rowTypeSelectize.searchField = ['label'];
				itemSelectize.searchField = ['name', 'tradename'];

				itemSelectize.onDelete = function (id, value) {
					_this.onItemDelete(id, value);
				};

				itemSelectize.load = _this.loadItem;
			}

			this.selectizeObjects = [rowTypeSelectize, itemSelectize, {
				domElt: $('.accounting', this.$el),
				create: false,
				valueField: 'id',
				labelField: 'code',
				searchField: ['label', 'code'],
				maxItems: '1',
				maxOptions: 10,
				options: this.accountings,
				render: 'accounting'
			}, {
				domElt: $('.barcode  ', this.$el),
				create: false,
				valueField: 'id',
				labelField: 'label',
				searchField: ['label'],
				render: {
					option: function (item, escape) {
						return '<div>' + '<span class="tradename ellipsis">' + $.trim(escape(item.value)) + ' - ' + escape($.trim(item.label)) + '</span>' + '</div>';
					},
					item: function (item, escape) {
						return '<div>' + '<span class="tradename ellipsis">' + $.trim(escape(item.value)) + ' - ' + escape($.trim(item.label)) + '</span>' + '</div>';
					}
				},
				maxItems: '1',
				maxOptions: 10
			}, {
				domElt: $('input.serial', this.$el),
				create: false,
				valueField: 'bcid',
				labelField: 'bcvalue',
				searchField: ['value'],
				maxItems: '1',
				maxOptions: 10,
				onDelete: function () {
					$('input[name="row[ihs_id]"]', _this.$el).val('');
				},
				onChange: function (bcid) {

					if (bcid) {

						var serials = this.product.availableSerials;
						var ihs = _.findWhere(serials, { bcid: bcid });

						this.$.ihs_id.val(ihs.id);
					}
				}

			}];

			this.discountType = 'amount'; // selected in default

			Page.prototype.afterRender.apply(this, arguments);

			var accordion = new Accordion({ scrollable: $('.form', this.$el), required: {
					'row-accounting': function () {
						return App.pluginIsLoaded('accounting');
					}
				} });

			TinyMCE.init({
				selector: '.textedit',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize",
				menubar: false,
				statusbar: false,
				cleanup: true,

				forced_root_block: "",
				autoresize_max_height: '150px',

				setup: _.bind(function (ed) {
					//wait tinyMCe initialization before continuing

					ed.on('init', _.bind(function (args) {

						this.noteEditor = ed;

						//DOM declaration
						this.$.warehouse = $('select[name="row[whid]"]', this.$el);
						this.$.unitAmountLine = $('.unit-amount-line', this.$el);
						this.$.stockPanel = $('#panel-stock', this.$el);
						this.$.accountingPanel = $('#panel-accoutning', this.$el);
						this.$.purchasePanel = $('#panel-purchase', this.$el);
						this.$.accounting = $('.accounting', this.$el)[0];
						this.$.productName = $('.product-name', this.$el);
						this.$.productNotes = $('.product-notes', this.$el);
						this.$.rowid = $('#row-id', this.$el);
						this.$.unity = $('[name="row[unitText]"]', this.$el);
						this.$.qtWrapper = $('#qt-wrapper', this.$el);
						this.$.purchaseAmountWrapper = $('#purchase-amount-wrapper', this.$el);
						this.$.totalAmountLabel = $('#total-amount-label', this.$el);
						this.$.taxid = $('select[name="row[taxid]"]', this.$el);
						this.$.ihs_id = $('input[name="row[ihs_id]"]', this.$el);
						this.$.serials = $('input.serial', this.$el);

						if (App.pluginIsLoaded('accounting')) {
							this.accountingSelectize = this.$.accounting.selectize;
						}

						var $selectItem = this.$.productName[0];
						this.itemSelectize = $selectItem.selectize;
						var $selectType = $('.row-type', this.$el)[0];
						this.selectizeType = $selectType.selectize;
						var barCode = $('.barcode', this.$el)[0];
						var $barCode = $(barCode);
						this.barCodeSelectize = barCode.selectize;

						if (this.$.serials[0]) {
							this.serialSelectize = this.$.serials[0].selectize;
						}

						//set total amount label depending RC
						if (this.modelToSave.get('hasTaxesInc') === 'Y') {
							this.$.totalAmountLabel.text(App.polyglot.t("totalAmountTaxesInc"));
						}

						//take row to the current index
						this.row = this.modelToSave.get('rows')[this.rowindex];

						//lock item selectize until we choose a row type
						this.itemSelectize.lock();

						/**
       * if it's a row update focus selectize on it and display row data in form
       */
						if (this.row) {

							//lock selectizes
							this.selectizeType.lock();

							$('.row-type', this.$el)[0].selectize.setValue(this.row.type);
						}
					}, this));
				}, this)
			});
		},

		/**
   *
   * @param {type} query
   * @param {type} callback
   * @returns {undefined}
   */
		loadItem: function (query, callback) {

			var promises = [];
			var _this = this;

			var rowType = $('.row-type', _this.$el)[0].selectize.items;

			if (!_.isEmpty(rowType)) {

				return new Promise(function (resolve, reject) {

					promises.push(_this.products.fetch({
						method: 'POST',
						data: {
							type: 'item',
							search: {
								thirdid: _this.thirdid,
								usePromoOn: 'useOnDoc',
								langID: _this.langid,
								name: query,
								combineDecli: 'Y'
							}
						}

					}, true));

					promises.push(_this.services.fetch({

						method: 'POST',
						data: {
							type: 'service',
							search: {
								thirdid: _this.thirdid,
								usePromoOn: 'useOnDoc',
								langID: _this.langid,
								name: query
							}
						}

					}, true));

					Promise.all(promises).then(function () {

						_this.products.add(_this.services.toJSON());

						resolve(_this.products);
					}, function (error) {
						reject(error);
					});
				});
			} else {
				return new Promise(function (resolve, reject) {
					resolve([]);
				});
			}
		},

		/**
   *
   */
		onTypeChange: function (value, item) {

			this.razInputs();

			if (App.pluginIsLoaded('accounting')) {
				this.accountingSelectize.clear();
			}

			this.itemSelectize.clear();
			this.itemSelectize.unlock();

			var $producNotesContainer = this.$.productNotes.closest('.form-group');

			$('option', this.$.select).attr("data-type", value);

			if (value && !this.rowindex) {

				switch (value) {

					case 'item':

						var collection = this.products;
						var options = collection.toJSON();
						var canCreate = false;
						var valueField = 'buildedId';
						var load = this.loadItem.bind(this);
						var placeHolder = App.polyglot.t('tradename');

						this.$.stockPanel.show();
						this.$.purchasePanel.show();
						$producNotesContainer.show();

						this.$.unity.show();
						this.$.purchaseAmountWrapper.show();

						break;

					case 'once':

						var options = [];
						var canCreate = true;
						var valueField = 'name';
						var load = "";
						var placeHolder = App.polyglot.t('rowName');

						this.$.stockPanel.hide();
						$producNotesContainer.show();
						this.$.unity.show();
						this.$.purchaseAmountWrapper.show();

						break;

					case 'shipping':

						var collection = this.shippings;
						var options = this.shippings.toJSON();
						var canCreate = false;
						var valueField = 'id';
						var load = function () {

							return new Promise(function (resolve, reject) {
								resolve(collection);
							});
						};
						var placeHolder = App.polyglot.t('carrierName');

						$producNotesContainer.hide();
						this.$.stockPanel.hide();
						this.$.purchasePanel.hide();
						this.$.unity.hide();
						this.$.purchaseAmountWrapper.hide();

						break;

					case 'packaging':

						var collection = this.packagings;
						var options = collection.toJSON();
						var canCreate = false;
						var valueField = 'id';
						var load = function () {

							return new Promise(function (resolve, reject) {
								resolve(collection);
							});
						};
						var placeHolder = App.polyglot.t('packagingName');

						this.$.stockPanel.hide();
						this.$.purchasePanel.hide();
						$producNotesContainer.hide();
						this.$.unity.hide();
						this.$.purchaseAmountWrapper.hide();

						break;

				}

				this.currentCollection = collection;

				// re-init selectize with new values options
				this.itemSelectize.clearOptions();

				this.itemSelectize.settings['create'] = canCreate;
				this.itemSelectize.settings['searchField'] = ['name', 'tradename'];
				this.itemSelectize.settings['valueField'] = valueField;
				this.itemSelectize.settings['placeholder'] = placeHolder;
				this.itemSelectize.updatePlaceholder();
				this.itemSelectize.settings['load'] = !load ? "" : function (query, callback) {

					if (!query.length) return callback();

					if (_.isFunction(load)) {

						load(query, callback).then(function (result) {

							var resultArray = _.toArray(result.toJSON());

							callback(resultArray);
						}, function (error) {
							console.log(error);
						});
					}
				};

				this.itemSelectize.addOption(options);
			} else if (value) {

				//item cant change on row update
				this.itemSelectize.lock();

				switch (value) {

					case 'item':

						var valueField = 'buildedId';

						this.$.stockPanel.show();
						this.$.purchasePanel.show();
						$producNotesContainer.show();
						this.$.unity.show();
						this.$.purchaseAmountWrapper.show();

						break;

					case 'once':

						var valueField = 'name';

						this.$.stockPanel.hide();
						$producNotesContainer.show();
						this.$.unity.show();
						this.$.purchaseAmountWrapper.show();

						break;

					case 'shipping':

					case 'packaging':

						var valueField = 'linkedid';

						this.$.stockPanel.hide();
						this.$.purchasePanel.hide();
						$producNotesContainer.hide();
						this.$.unity.hide();
						this.$.purchaseAmountWrapper.hide();

						break;

				}

				this.itemSelectize.settings['valueField'] = valueField;
			} else {
				this.itemSelectize.lock();
			}

			if (this.row) {
				this.setItemSelectize();
			}
		},

		/**
   *
   * @returns {undefined}
   */
		setItemSelectize: function () {

			//if its not once row focus on linkedid
			if (this.row.type !== 'once') {

				var value = this.row.type === 'item' ? this.row.buildedId : this.row.linkedid;

				//if row not found we create it
				if (_.isEmpty(this.itemSelectize.getOption(value))) {
					this.itemSelectize.addOption(this.row);
				}

				this.itemSelectize.setValue(value);
			} else {
				//if it's a once row, add an item with the name value

				this.itemSelectize.addOption(this.row);
				if (this.row.name) {
					this.itemSelectize.setValue(this.row.name);
				} else {

					this.buildRow4DOM(this.row.id, this.row.type, this.currentCollection);
					this.row = undefined;
				}

				('.product-name', this.$el).attr('name', "row[name]");
			}
		},

		/**
   *
   */
		onItemDelete: function () {
			this.razInputs();
		},

		/**
   * @param {type} id
   * @param {type} item
   * @returns {undefined}
   */
		onItemChange: function (id, item) {

			this.razInputs();
			var $type = this.selectizeType.getValue();

			this.buildRow4DOM.call(this, id, $type, this.currentCollection);
			this.row = undefined;
		},

		/**
   *
   */
		changeInputdiscount: function (e) {

			var $currentTarget = $(e.currentTarget);

			//change symbol of input discount-amount
			if ($currentTarget.val() === 'percent') {
				$('.right-addon', '.discount-row').text('%');
				this.discountType = 'percent';
			} else {
				$('.right-addon', '.discount-row').text(this.numberFormat.currency.symbol);
				this.discountType = 'amount';
			}

			this.sumLine();
		},

		/**
   * @description calculates amount line
   * @param {event} e
   * @returns {undefined}
   */
		sumLine: function (e) {

			var $discountVal = $('.discount-row input').val();
			var $unitAmountInput = $('.unit-amount-line', this.$el);
			var stringAmount = $unitAmountInput.val();
			var stringQty = $('.quantity').val();
			var bigAmount = new Big(this.numberFormat.formatToFloat(stringAmount));
			var bigQuantity = new Big(this.numberFormat.formatToFloat(stringQty));
			var bigDiscount = new Big(this.numberFormat.formatToFloat($discountVal));

			if (bigDiscount.gt(0)) {

				var row2compute = {
					unitAmount: bigAmount
				};

				if (this.useEcoTax) {

					row2compute.ecoTaxType = $('select', '.ecotax-part').val();

					if (this.isTTC) {
						row2compute.ecoTaxDisplay = this.numberFormat.formatToFloat($('input', '.ecotax-part').val());
					} else {
						row2compute.ecoTax = this.numberFormat.formatToFloat($('input', '.ecotax-part').val());
					}
				}

				row2compute.discountUnit = this.discountType;
				row2compute.discount = bigDiscount;
				var bigAmount = Math.computeDiscount(row2compute, this.isTTC, this.numberFormat);
			}

			var bigSum = new Big(bigAmount).times(bigQuantity);
			var sum = bigSum.toString();

			$('#row-amount').text(this.numberFormat.formatToDisplay(sum)).data('value', sum);
		},

		/**
   * @build row object for prepare insertion in DOM
   * @returns {undefined}
   */
		buildRow4DOM: function (id, type, collection) {

			if (id !== '') {
				// if change is not fired by a delete

				var row2Display = {};

				this.$.selectItem = this.$.productName[0];

				if (type !== "once") {

					this.product = collection ? collection.get(id).toJSON() : this.row; //in row creation we take row info in product collection, else in docrow
					this.useEcoTax = this.product.useEcoTax === 'Y' ? true : false;

					if (type === 'item') {

						row2Display.linkedid = this.product.linkedid ? this.product.linkedid : this.product.id;
						row2Display.declid = this.product.declid;
						row2Display.buildedId = this.product.buildedId;
						row2Display.linkedtype = this.product.type;
						var barcodes = this.product.barCodes ? this.product.barCodes : this.product.mixedBarCodes ? JSON.parse(decodeURIComponent(this.product.mixedBarCodes)) : '';

						var groupedBarcodes = _.groupBy(barcodes, 'type');
						row2Display.mixedBarCodes = encodeURI(JSON.stringify(barcodes));
						row2Display.barCodes = groupedBarcodes['simple'];
						row2Display.serialsBC = groupedBarcodes['serial'];
						var qt = this.product.qt;

						this.$.productName.attr('name', 'row[buildedId]');
					} else {
						this.$.productName.attr('name', "row[linkedid]");
					}

					if (this.product.hasPriceException === 'Y') {
						var hasPriceException = true;
					}
				}

				if (this.isTTC) {
					$('#row-amount', this.$el).attr('data-nameattr', 'row[totalAmountTaxesInc]');
				}

				//if row is new choose data of item selectize else choose data row
				if (_.isUndefined(this.row)) {

					//take default tax2id
					var tax2id = this.modelToSave.get('prefs').defaultTax2id != '0' ? this.modelToSave.get('prefs').defaultTax2id : this.modelToSave.get('prefs').defaultTaxid;

					//if we found a product  display it. Else display a simple line with the product name added
					if (type !== 'once') {

						var linkedRC = this.product.prices[this.modelToSave.get('rateCategory')];

						if (hasPriceException) {

							if (this.isTTC) {
								var TTCAmount = Math.percentageIncrease(this.product.exception_price, this.product.tax);
								var unitAmountLine = this.numberFormat.formatToDisplay(TTCAmount.toString(), true, false);
							} else {
								var unitAmountLine = this.numberFormat.formatToDisplay(this.product.exception_price, true, false);
							}
						} else {
							var unitAmountLine = this.numberFormat.formatToDisplay(linkedRC.amount, true, false);
						}

						//check if product have promo
						if (!_.isEmpty(linkedRC.promotion)) {

							var currentPromotion = _.toArray(linkedRC.promotion)[0];

							if (hasPriceException) {

								if (currentPromotion.discountunit === 'price') {
									var unitAmountLine = this.numberFormat.formatToDisplay(this.isTTC ? currentPromotion.promoAmount_taxIncluded : currentPromotion.promoAmount_taxExcluded, true, false);
								} else {

									row2Display.discount = currentPromotion.discountamount;
									row2Display.discountUnit = currentPromotion.discountunit;
								}

								row2Display.amountWithoutPromo = this.numberFormat.formatToDisplay(linkedRC.amount, true, false);
							} else {

								if (currentPromotion.discountunit === 'price') {
									var unitAmountLine = this.numberFormat.formatToDisplay(this.isTTC ? currentPromotion.promoAmount_taxIncluded : currentPromotion.promoAmount_taxExcluded, true, false);
								} else {

									var unitAmountLine = this.numberFormat.formatToDisplay(linkedRC.amount, true, false);
									row2Display.discount = currentPromotion.discountamount;
									row2Display.discountUnit = currentPromotion.discountunit;
								}

								row2Display.amountWithoutPromo = this.numberFormat.formatToDisplay(linkedRC.amount, true, false);
							}

							if (currentPromotion.showDescOnDoc === "Y") {
								row2Display.promoNotes = currentPromotion.docdesc;
							}

							row2Display.promoid = currentPromotion.id;
						}

						var taxid = linkedRC.taxid;

						if (App.hasVat && this.taxes.get(taxid).get('isEnabled') === 'N') {

							var tax = this.taxes.find(function (model) {
								return model.get('isMain') === 'Y';
							});

							if (tax.get('isEnabled') === 'N') {
								tax = this.taxes.find(function (model) {
									return model.get('isEnabled') === 'Y';
								});
							}

							taxid = tax.id;
						}

						var notesLine = this.product.notes;
						var purchaseAmount = this.product.purchaseAmount;
						var accountingCode = this.product.accountingCode;
						var unitText = this.product.unit;
						var stockenabled = this.product.stockenabled;
						var stockMove = Moment().unix();

						if (this.useEcoTax) {

							if (this.isTTC || !App.hasVat) {
								var ecoTaxTTC = Math.percentageIncrease(this.product.ecoTax, 20); // transform ecotax in TTC if docTTC
								row2Display.ecoTaxTTC = ecoTaxTTC;
							}

							var ecoTaxHT = this.product.ecoTax;
							var ecoTaxType = this.product.ecoTaxType;
						}

						$('option', this.$.selectItem).attr("data-name", this.product.name);
					} else {

						//for once row, with haven't productid, so id is row name

						var unitAmountLine = this.numberFormat.formatToDisplay(0, true, false);

						$('option', this.$.selectItem).removeAttr("data-name");
						this.$.productName.attr('name', "row[name]");
						$('option', this.$.selectItem).val(id);
						$('option', this.$.selectItem).attr("data-name", id);
					}
				} else {

					var unitAmountLine = this.numberFormat.formatToDisplay(this.row.unitAmount, true, false);
					var notesLine = this.row.notes;
					var purchaseAmount = this.row.purchaseAmount;
					var accountingCode = this.row.accountingCode;
					var bcid = this.row.bcid;
					var taxid = this.row.taxid;
					var tax2id = this.row.tax2id;
					var unitText = this.row.unitText;
					var stockMove = Moment(this.row.stockmove, App.dateFormat.momentFormat).unix();
					var stockenabled = this.row.stockenabled;
					var qt = this.row.qt;

					row2Display.serialid = this.row.serialid;
					row2Display.ihs_id = this.row.ihs_id;
					row2Display.serial_bcid = this.row.serial_bcid;
					row2Display.promoid = this.row.promotionid;
					row2Display.whid = this.row.whid;
					row2Display.id = this.row.id;
					row2Display.isOption = this.row.isOption;

					if (this.row.discount) {
						row2Display.discount = this.row.discount;
						row2Display.discountUnit = this.row.discountUnit;
					}

					if (this.useEcoTax) {

						if (this.isTTC || !App.hasVat) {
							var ecoTaxTTC = this.row.ecoTaxDisplay;
							row2Display.ecoTaxTTC = ecoTaxTTC;
						}

						var ecoTaxHT = this.row.ecoTax;
						var ecoTaxType = this.row.ecoTaxType;
					}

					$('.unity-line select option[value="' + this.row.unity + '"]').prop('selected', true);
					$('.tax-line select option[value="' + this.row.taxid + '"]').prop('selected', true);

					$('option', this.$.selectItem).attr("data-name", this.row.name);
					$('option', this.$.selectItem).attr("data-row_id", this.row.id);
				}

				row2Display.notes = notesLine;
				row2Display.unitAmount = unitAmountLine;
				row2Display.purchaseAmount = purchaseAmount;
				row2Display.accountingCode = accountingCode;
				row2Display.bcid = bcid;
				row2Display.ecoTaxHT = ecoTaxHT;
				row2Display.ecoTaxType = ecoTaxType;
				row2Display.taxid = taxid;
				row2Display.unitText = unitText;
				row2Display.stockmove = stockMove;
				row2Display.stockenabled = stockenabled;
				row2Display.qt = qt;
				row2Display.tax2id = tax2id;

				$('option', this.$.select).attr("data-type", type);

				this.insertInDOM(row2Display);
			}
		},

		/**
   *
   * @param {type} row
   * @returns {undefined}
   */
		insertInDOM: function (row) {

			//if row exist on server set rowid in input
			if (row.id) {
				this.$.rowid.val(row.id);
			}

			if (row.isOption === 'Y') {
				$('input[name="row[isOption]"]').prop('checked', true);
			}

			row.linkedtype === "service" || row.stockenabled === "N" ? $('[name="row[whid]"]').closest('.form-group').hide() : $('[name="row[whid]"]').closest('.form-group').show();

			if (row.whid) {
				//focus on selected warehouse
				$('select[name="row[whid]"] option[value="' + row.whid + '"]').prop('selected', true);
			}

			var $productNotes = $('.product-notes', this.$el);

			//insert product description
			if (row.notes) {
				this.noteEditor.setContent(row.notes);
			}

			//insert unit amount line
			this.$.unitAmountLine.val(row.unitAmount);

			if (row.linkedid) {
				//linked id and linkedtype is set when row type is item

				$('input[name="row[declid]"]', this.$el).val(row.declid);

				$('option', this.$.selectItem).data('linkedid', row.linkedid);
				$('option', this.$.selectItem).data('declid', row.declid);
				$('option', this.$.selectItem).data('linkedtype', row.linkedtype);
			}

			//insert unit
			if (row.unitText) {
				$('select[name="row[unitText]"]').val(row.unitText);
			}

			//set product qty to 1 or to row qty
			var qty = this.numberFormat.formatToDisplay(row.qt ? row.qt : 1, true, false, undefined, undefined, true, 'qt');

			$('.quantity', this.$el).val(qty).trigger('change');

			//Insert account currency on product price input
			$('.product-price .input-group-addon', this.$el).text(this.numberFormat.currency.symbol);

			//insert purchase amount
			$('input[name="row[purchaseAmount]"]').val(this.numberFormat.formatToDisplay(row.purchaseAmount, true, false)).closest('.form-grou').find('label').addClass('active');

			if (App.pluginIsLoaded('accounting')) {
				//focus on accountingCode
				this.accountingSelectize.setValue(row.accountingCode);
			}

			$('input[name="row[mixedBarCodes]"]').val(row.mixedBarCodes);
			if (row.barCodes) {

				this.barCodeSelectize.addOption(row.barCodes);
				if (row.bcid) {
					this.barCodeSelectize.setValue(row.bcid);
				}
			}

			//			focus on right tax
			if (row.taxid) {
				this.$.taxid.val(row.taxid);
			}

			if (row.tax2id) {
				$('select[name="row[tax2id]"]', this.$el).val(row.tax2id);
			}

			//init ecotax
			if (row.ecoTaxHT) {

				var $ecoTax = $('.ecotax-part', this.$el);

				$ecoTax.removeClass('hide');
				$('select', $ecoTax).val(row.ecoTaxType);

				if (row.ecoTaxTTC) {
					$('input', $ecoTax).val(this.numberFormat.formatToDisplay(row.ecoTaxTTC, true, false)).attr("data-use-eco-tax", 'Y').attr('data-eco-tax', row.ecoTaxHT);
				} else {
					$('input', $ecoTax).val(this.numberFormat.formatToDisplay(row.ecoTaxHT, true, false)).attr("data-use-eco-tax", 'Y').attr('data-eco-tax', row.ecoTaxHT);
				}
			} else {

				var $ecoTax = $('.ecotax-part', this.$el);

				$('input', $ecoTax).val(this.numberFormat.formatToDisplay(0, true, false)).attr("data-use-eco-tax", 'N').attr('data-eco-tax', 0);
				$ecoTax.addClass('hide');
			}

			//init promotion/discount
			if (row.discountUnit === "percent") {
				$('.discount-row input', this.$el).val(this.numberFormat.formatToDisplay(row.discount, true, false));
				$('.discount-row select', this.$el).val('percent').trigger('change');
			} else if (row.discountUnit === 'amount') {
				$('.discount-row input', this.$el).val(this.numberFormat.formatToDisplay(row.discount, true, false));
				$('.discount-row select', this.$el).val('amount').trigger('change');
			}

			if (row.promoid) {

				this.$.unitAmountLine.data('promotionid', row.promoid);
				this.$.unitAmountLine.data('amountWithoutPromo', row.amountWithoutPromo);

				if (row.promoNotes) {
					var notes = $productNotes.val() + "\n" + row.promoNotes;
					$productNotes.val(notes);
				}
			}

			if (this.isStockLoaded) {

				$('input', this.$.qtWrapper).prop('disabled', false);

				if (row.serialsBC && Utils.inArray(this.modelToSave.get('doctype'), this.availableDoctype4Serials)) {

					$('input', this.$.qtWrapper).prop('disabled', true); //lock qt for serial item

					//get Available serials
					CatalogueProduct.getAvailableSerials(this.$.warehouse.val(), this.product.linkedid ? this.product.linkedid : this.product.id, this.product.declid).then(_.bind(function (serials) {

						this.product.availableSerials = serials;

						this.$.serials.closest('.form-group').removeClass('hide');

						if (serials) {
							this.serialSelectize.addOption(_.toArray(serials));
						} else {
							//TODO: display " no serial available"
						}

						if (row.serialid) {

							this.$.qtWrapper.prop('disabled', true);
							this.serialSelectize.addItem(row.serial_bcid);
							this.$.ihs_id.val(row.serialid);
						} else {
							this.$.qtWrapper.prop('disabled', false);
						}
					}, this));
				}

				$('input[name="row[stockmove]"]', this.$el).val(row.stockmove);
				$('input[name="row[stockenabled]"]').val(row.stockenabled);
			}
		},

		/**
   * @description get new serials when wharehouse change
   * @returns {undefined}
   */
		changeSerials: function () {

			if (this.isStockLoaded && Utils.inArray(this.modelToSave.get('doctype'), this.availableDoctype4Serials)) {

				CatalogueProduct.getAvailableSerials(this.$.warehouse.val(), this.product.linkedid ? this.product.linkedid : this.product.id, this.product.declid).then(_.bind(function (serials) {

					this.product.availableSerials = serials;

					this.serialSelectize.clearOptions();

					if (serials) {
						this.serialSelectize.addOption(_.toArray(serials));
					}
				}, this));
			}
		},

		/**
   * @desc pour un doc TTC , recalcule le montant de l'eco tax HT quand l'utilisateur change la valeur de l'eco tax TTC
   * @returns {undefined}
   */
		computeEcoTaxHT: function (e) {

			var $input = $(e.currentTarget);
			var taxid = this.$.taxid.val();
			var taxRate = this.taxes.get(taxid).get('value');
			var ecoTaxTTC = $input.val();
			var ecoTaxHT = Math.TTCtoHT(App.numberFormat.formatToFloat(ecoTaxTTC), taxRate);

			$input.attr('data-eco-tax', ecoTaxHT.round(parseInt(this.numberFormat.precision)).toString());
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var nbRow = _.toArray(this.modelToSave.get('rows')).length;
			var validationErrors = this.modelToSave.validateRow(dataForm.row);
			var rowArray = _.toArray(this.modelToSave.get('rows'));

			rowArray.push(dataForm['row']);

			console.log(dataForm);

			dataForm['row']['selectize'] = '';
			dataForm['row']['display_stockmove'] = dataForm['row']['stockmove'];
			dataForm['row']['stockmove'] = Moment(dataForm['row']['stockmove'], 'X').format(App.dateFormat.momentFormat);

			if (dataForm['row']['serialid']) {

				var serial = this.product.availableSerials[dataForm["row"]['ihs_id']];
				dataForm['row']['serial'] = serial.bcvalue;
				dataForm['row']['serialid'] = serial.id;
				dataForm['row']['serial_bcid'] = serial.bcid;
			}

			if (dataForm['row']['bcid']) {

				var barcode = this.product.barCodes[dataForm["row"]['bcid']];
				dataForm['row']['barcode'] = barcode.value;
			}

			if (!validationErrors) {

				this.modelToSave.unset('numberFormat'); //unset numberformat instance before recording doc in localdb

				//if new row insert it in doc to the right index
				if (!this.rowindex) {
					!nbRow ? this.modelToSave.set({ rows: { 0: dataForm['row'] } }) : this.modelToSave.set({ rows: rowArray });
				} else {
					this.modelToSave.get('rows')[this.rowindex] = dataForm['row'];
				}

				//save doc in temp with new row and return to the doc form
				this.modelToSave.saveLocal().then(function () {
					_this.previousLink();
				});
			} else {

				$('.validation-error').remove();

				if (validationErrors.row['type']) {
					$('.wrapper-productSelectize').before("<div class='validation-error'>" + validationErrors.row['type'] + "</div>");
				}

				if (validationErrors.row['linkedid']) {
					$('.wrapper-productSelectize .product-name:first').before("<div class='validation-error'>" + validationErrors.row['linkedid'] + "</div>");
				}

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');
			}

			return false;
		},

		/**
   * @description "empty every inputs of form"
   * @returns {undefined}
   */
		razInputs: function () {

			var _this = this;

			$('input:not(#row-id):not([type="checkbox"]):not(.number-format)', this.$el).val('');
			$('input.number-format').val(this.numberFormat.formatToDisplay(0, true, false, undefined, undefined, true, $('input.number-format').attr('precisioncustom')));
			$('input', '#qt-wrapper').prop('disabled', false);

			$('input.number-format').each(function () {
				$(this).val(_this.numberFormat.formatToDisplay(0, true, false, undefined, undefined, true, $(this).attr('precisioncustom')));
			});

			$('#row-amount, .discount-row input').text(this.numberFormat.formatToDisplay('0'));
			$('.ecotax-part').addClass('hide');
			$('#isOption', this.$el).prop('checked', false);
			this.$.unitAmountLine.removeData();
			this.barCodeSelectize.clearOptions();

			if (this.isStockLoaded) {
				this.$.serials.closest('.form-group').addClass('hide');
				this.serialSelectize.clearOptions();
			}

			if (this.noteEditor) {
				this.noteEditor.setContent('');
			}
		}

	});
});
