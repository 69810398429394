/* global Promise */

/**
 *	@desc Model Document, extend Core Model
 */
define('models/singles/document',['models/core/model', 'tools/ajax', 'app', 'db/easy', 'models/collections/linkedPaymentDoc', 'tools/buildURL', 'views/core/toastNotification'], function (Model, Ajax, App, DB, LinkedPayments, URLBuilder, ToastNotification) {

	'use strict';

	var Document = Model.extend({

		defaults: {
			publicLink: '',
			prefs: {},
			showContactOnPdf: 'Y'
		},

		urlRoot: '/rest/document/',
		name: 'document',
		storeName: 'document',

		fetchPublicLink: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/document/publicLink/' + _this.get('doctype') + '/' + _this.id,
					success: function (publicLink) {

						_this.set({ publicLink: publicLink });

						resolve();
					},
					error: function () {
						_this.fetchLocalPublicLink().then(function () {
							resolve();
						}, function () {
							reject();
						});
					}

				});
			});
		},

		fetch: function (options) {

			options = options ? options : {};
			options.dataType = 'json';

			var _this = this;
			var xhr = Backbone.Model.prototype.fetch.apply(this, arguments);

			return new Promise(function (resolve, reject) {

				xhr.fail(function () {

					if (App.indexedDB) {
						_this.fetchLocal().then(resolve, reject);
					}
				});

				xhr.done(function (data) {

					if (!data.error) {

						//take public link of doc
						_this.fetchPublicLink().then(function () {

							var promises = [];

							if (App.indexedDB) {

								_this.saveLocal().then(function () {

									//save linkedPayment in localDB
									if (_this.get('doctype') === 'invoice' && _this.get('payments')) {

										var linkedpayments = new LinkedPayments(_this.get('payments').listing);

										linkedpayments.saveLocal({
											updateLinked: false // false : don't update linked doc when payment saved in local
										}).then(function () {
											resolve(data);
										});
									} else {
										resolve();
									}
								});
							} else {
								resolve();
							}
						});
					} else {
						reject(data.error);
					}
				});
			});
		},

		/**
   *
   * @returns {undefined}
   */
		fetch4Copy: function () {

			return new Promise(_.bind(function (resolve, reject) {

				var _this = this;

				Ajax({
					url: URLBuilder(['rest', 'document', this.get('doctype'), this.id, 'copy'], false),
					method: 'GET',
					data: {
						thirdid: this.get('thirdid')
					},
					success: function (data) {

						_this.set(data);

						if (!data.error) {

							if (App.indexedDB) {

								_this.saveLocal().then(function () {
									resolve();
								}, function () {
									reject();
								});
							} else {
								resolve(data);
							}
						} else {
							reject(data);
						}
					},
					error: function (err) {
						reject(err);
					}
				});
			}, this));
		},

		/**
   *
   * @returns {undefined}
   */
		updateStep: function (step) {

			var _this = this;

			return new Promise(_.bind(function (resolve, reject) {

				Ajax({
					url: URLBuilder([this.urlRoot, this.id, step], false),
					method: "POST",
					success: function (data) {

						if (!data.error) {

							_this.saveLocal().then(function () {
								resolve(step);
							});
						} else {
							reject(data.error);
							new ToastNotification({
								id: 'toast-error',
								classes: 'col-xs-12',
								timeDuration: 4000,
								content: 'Le changement de status a échoué'
							});
						}
					},
					error: function (err) {
						reject(err);
					}
				});
			}, this));
		},

		/**
   *
   * @returns {undefined}
   */
		checkStock: function () {

			return new Promise(_.bind(function (resolve, reject) {

				Ajax({
					//					dataType	: 'json', //fix strange parserror...
					url: '/rest/document/checkStock',
					method: 'POST',
					data: JSON.stringify(this.attributes),
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						reject(err);
					}
				});
			}, this));
		},

		/**
   *
   * @param {type} dataDoc
   */
		validate: function (dataDoc) {

			var _this = this;
			var validationErrors = {
				document: {}
			};

			var required = {

				'document': {

					displayedDate: true,
					ident: true
				}

			};

			_.each(required.document, function (attr, nameAttr) {

				if (!dataDoc['document'][nameAttr] && required['document'][nameAttr]) {
					validationErrors['document'][nameAttr] = _this.computeError({ name: nameAttr, type: 'required' });
				}
			});

			if (_.toArray(validationErrors['document']).length > 0) {
				return validationErrors;
			}
		},

		/**
   * @description row validation
   * @returns {undefined}
   */
		validateRow: function (row) {

			var validationErrors = {
				row: {}
			};

			if (!row.type) {
				validationErrors.row['type'] = this.computeError({ name: 'type', type: 'required' });
			}

			if (!row.id && !row.linkedid && !row.name) {
				validationErrors.row['linkedid'] = this.computeError({ name: 'linkedid', type: 'required' });
			}

			if (_.toArray(validationErrors.row).length > 0) {
				return validationErrors;
			}
		}

	}, {

		/**
   *
   * @param {type} emails
   * @param {type} docid
   * @param {type} doctype
   * @param {type} content
   * @returns {Promise}
   */
		sendDocByEmail: function (email) {

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/document/mailto',
					method: 'POST',
					data: JSON.stringify(email),
					success: function () {
						resolve();
					},
					error: function (error) {
						console.log(error);
						reject();
					}
				});
			});
		},

		/**
   *
   * @param {type} doctype
   * @returns {undefined}
   */
		getMailTemplate: function (doctype, docId) {

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/document/mailTemplate/' + doctype + '/' + docId,
					success: function (data) {
						resolve(data);
					},
					error: function (error) {
						console.log(error);
						reject();
					}
				});
			});
		},

		/**
   * @return true if a doc row is empty
   * @returns {undefined}
   */
		isEmptyRow: function (row) {
			return row.row_linkedid === '' && _.isUndefined(row.row_name);
		},

		/**
   *
   * @param {type} paydate
   * @returns {undefined}
   */
		validatePayDate: function (paydate) {

			var validationErrors = {
				paydate: {}
			};

			switch (paydate.syscode) {

				case "custom":
				case "onorder":

					if (!paydate.custom) {
						validationErrors.paydate['custom'] = this.computeError({ name: 'custom', type: 'invalid' });
					}

					break;

				case "xdays":

					if (!paydate.xdays) {
						validationErrors.paydate['xdays'] = this.computeError({ name: 'xdays', type: 'required' });
					} else if (paydate.xdays < 0) {
						validationErrors.paydate['xdays'] = this.computeError({ name: 'xdays', type: 'invalid' });
					}

					break;

				case 'scaled':

					if (!paydate.scaledDetails) {
						validationErrors.paydate['scaledDetails'] = this.computeError({ name: 'scaledDetails', type: 'required' });
					}

					break;

				default:

					break;

			}

			if (_.toArray(validationErrors.paydate).length > 0) {
				return validationErrors;
			}
		},

		/**
   * @description return the right constructor for the given doctype
   * @returns {undefined}
   */
		classNameByDoctype: function (doctype) {

			var className = '';

			return new Promise(function (resolve, reject) {

				switch (doctype) {

					case 'invoice':

						require(['models/singles/invoice'], function (Invoice) {
							className = Invoice;
							resolve(className);
						});

						break;

					case 'estimate':

						require(['models/singles/estimate'], function (Estimate) {
							className = Estimate;
							resolve(className);
						});

						break;

					case 'order':

						require(['models/singles/order'], function (Order) {
							className = Order;
							resolve(className);
						});

						break;

					case 'delivery':

						require(['models/singles/delivery'], function (Delivery) {
							className = Delivery;
							resolve(className);
						});

						break;

					case 'proforma':

						require(['models/singles/proforma'], function (Proforma) {
							className = Proforma;
							resolve(className);
						});

						break;

					case 'creditnote':

						require(['models/singles/creditnote'], function (CreditNote) {
							className = CreditNote;
							resolve(className);
						});

						break;

					case 'model':

						require(['models/singles/model'], function (Model) {
							className = Model;
							resolve(className);
						});

						break;

					default:

						reject('invalid doctype : ' + doctype);
						break;
				}
			});
		},

		/**
   *
   * @returns {undefined}
   */
		getNextIDent: function (doctype) {

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/document/' + doctype,
					success: function (data) {
						resolve(data);
					},
					error: function (error) {
						reject(error);
					}

				});
			});
		},

		/**
   *
   */
		getPrefs: function () {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: "/rest/document/prefs",
					success: function (data) {
						App.docPrefs = data;
						resolve();
					}

				});
			});
		},

		/**
   *
   * @returns {undefined}
   */
		popupHasErrors: function (data) {

			var docAttributes = data.document;
			var validationErrors = { document: {} };

			if (!docAttributes.thirdid) {

				var model = new Model(); //trick for use class function in static function
				validationErrors['document']['thirdid'] = model.computeError({ name: 'thirdid', type: "required" });
			}

			if (_.isEmpty(validationErrors['document'])) {
				return false;
			} else {
				return validationErrors;
			}
		}

	});

	return Document;
});
