define('tools/math',['big', 'underscore', 'app'], function (Big, _, App) {

	'use strict';

	var Math = {

		/**
   * @description decrease an amount by percent
   * @param {type} percent
   * @returns {bigNumber}
   *
   */
		percentageDecrease: function (amount, percent) {

			amount = amount instanceof Big ? amount : new Big(amount);
			percent = percent instanceof Big ? percent : new Big(percent);

			return amount.times(new Big(1).minus(new Big(percent).div(100)));
		},

		/**
   * @description increase an amount by percent
   * @param {type} amount
   * @param {type} percent
   * @returns {bigNumber}
   */
		percentageIncrease: function (amount, percent) {

			amount = amount instanceof Big ? amount : new Big(amount);
			percent = percent instanceof Big ? percent : new Big(percent);

			return amount.times(new Big(1).plus(new Big(percent).div(100)));
		},

		/**
   * @description give the percentage part on an amount
   * @param {type} amount
   * @param {type} percent
   * @returns {undefined}
   */
		percentPart: function (amount, percent) {

			amount = amount instanceof Big ? amount : new Big(amount);
			percent = percent instanceof Big ? percent : new Big(percent);

			return amount.times(percent).div(100);
		},

		/**
   * @desc return amount tax free of an amount tax inc
   */
		TTCtoHT: function (amountTaxInc, taxRate) {

			amountTaxInc = amountTaxInc instanceof Big ? amountTaxInc : new Big(amountTaxInc);
			taxRate = taxRate instanceof Big ? taxRate : new Big(taxRate);
			var taxMultiplier = new Big(1);

			return amountTaxInc.div(taxMultiplier.plus(taxRate.div(100)));
		},

		/**
   * 
   * @param {type} row
   * @returns {undefined}
   */
		computeDiscount: function (row, isTTC, precision) {

			isTTC = _.isUndefined(isTTC) ? false : isTTC;
			var unitAmount = row.unitAmount instanceof Big ? row.unitAmount : new Big(row.unitAmount);
			unitAmount = unitAmount.round(3);

			if (row.ecoTaxType === 'inc') {

				var ecoTaxVal = row.ecoTaxDisplay ? row.ecoTaxDisplay : row.ecoTax;
				unitAmount = unitAmount.minus(ecoTaxVal);
				var ecoTaxInc = true;
			}

			if (row.discountUnit === 'percent') {
				unitAmount = Math.percentageDecrease(unitAmount, row.discount).round(3);
			} else if (row.discountUnit === 'amount') {
				unitAmount = unitAmount.minus(row.discount);
			}

			if (ecoTaxInc) {
				unitAmount = unitAmount.plus(ecoTaxVal);
			}

			return unitAmount.toString();
		},

		/**
   * 
   */
		sumRowsV2: function (rows, options) {

			var options = options ? options : {};
			var numberFormat = options.numberFormat ? options.numberFormat : App.numberFormat;
			var taxPart = {};
			var result = {};
			var totalShipping = new Big(0);
			var totalShippingTTC = new Big(0);
			var totalPackaging = new Big(0);
			var totalPackagingTTC = new Big(0);
			var totalAmountAllInc = new Big(0);
			var totalAmount = new Big(0);
			var totalAmountTTC = new Big(0);
			var ecoTaxTotalAmount = new Big(0);
			var ecoTaxPart = new Big(0);
			var hasDoubleVat = options.hasDoubleVat === 'Y' ? true : false;
			var globalDiscount = options.globalDiscount ? new Big(options.globalDiscount) : new Big(0);
			var precision = parseInt(numberFormat.precision);
			var precisionTax = precision > 2 ? 2 : precision;

			if (options['hasTaxesInc'] === 'Y') {
				var isTTC = true;
			}

			_.each(rows, function (row) {

				var unitAmount = new Big(Math.computeDiscount(row, isTTC, precision)); //unitAmount with eco tax and discount computed

				if (row.useEcoTax) {

					var ecoTaxAmount = isTTC || !options.hasVat ? new Big(row.ecoTaxDisplay) : new Big(row.ecoTax);
					var ecoTaxRowAmount = ecoTaxAmount.times(row.qt);

					if (row.ecoTaxType === 'inc') {
						unitAmount = new Big(unitAmount).minus(ecoTaxAmount); // substrate ecoTax if inc
					}

					ecoTaxTotalAmount = ecoTaxTotalAmount.plus(ecoTaxRowAmount); //make eco taxSum
				}

				var rowAmount = new Big(unitAmount).times(row.qt); //rowAmount with discount computed and eco tax substrate if exist and inc
				var rowAmountTTC = rowAmount;

				if (isTTC) {
					rowAmount = Math.TTCtoHT(rowAmount, row.taxRate);
				}

				switch (row.type) {

					case 'once':

					case 'item':

						totalAmount = totalAmount.plus(rowAmount); //totalAmount taxes free
						totalAmountTTC = totalAmountTTC.plus(rowAmountTTC);
						var useDiscount = true;

						break;

					case 'shipping':

						totalShipping = totalShipping.plus(rowAmount);
						totalShippingTTC = totalShippingTTC.plus(rowAmountTTC);
						var useDiscount = false;

						break;

					case 'packaging':

						totalPackaging = totalPackaging.plus(rowAmount);
						totalPackagingTTC = totalPackagingTTC.plus(rowAmountTTC);
						var useDiscount = false;

						break;

				}

				if (options.hasVat) {

					//init tax part to 0 the first time
					if (!taxPart[row.taxid]) {

						taxPart[row.taxid] = {
							rate: new Big(row.taxRate),
							amount: new Big(0),
							baseAmount: new Big(0),
							useDiscount: useDiscount
						};
					}

					taxPart[row.taxid]['baseAmount'] = taxPart[row.taxid]['baseAmount'].plus(rowAmount);

					if (hasDoubleVat) {

						var taxRate2 = row.taxRate2 instanceof Big ? row.taxRate2 : new Big(row.taxRate2);

						//init tax part to 0 the first time
						if (!taxPart[row.tax2id]) {

							taxPart[row.tax2id] = {
								rate: new Big(row.taxRate2),
								amount: new Big(0),
								baseAmount: new Big(0),
								useDiscount: useDiscount
							};
						}

						taxPart[row.tax2id]['baseAmount'] = taxPart[row.tax2id]['baseAmount'].plus(rowAmount);
					}
				}
			});

			if (globalDiscount.gt(0) && (isTTC && totalAmountTTC.gt(0) || !isTTC && totalAmount.gt(0))) {

				if (options.globalDiscountUnit === 'amount') {

					var discountAmount = globalDiscount;
					var discountPercent = isTTC ? discountAmount.div(totalAmountTTC).times(100) : discountAmount.div(totalAmount).times(100);
				} else if (options.globalDiscountUnit === 'percent') {

					var discountPercent = globalDiscount;
					var discountAmount = isTTC ? totalAmountTTC.times(discountPercent).div(100) : totalAmount.times(discountPercent).div(100);
				}
			} else {
				var discountPercent = new Big(0);
				var discountAmount = new Big(0);
			}

			if (options.hasVat) {

				_.each(taxPart, function (tax, index) {

					var taxCoeff = new Big(1);

					if (tax.useDiscount) {
						var discountVat = taxPart[index].baseAmount.times(discountPercent).div(100);
						taxPart[index].baseAmount = taxPart[index].baseAmount.minus(discountVat);
					}

					taxPart[index].amount = Math.percentPart(taxPart[index].baseAmount, taxPart[index].rate).round(precisionTax).toString();

					if (!isTTC) {
						totalAmountAllInc = totalAmountAllInc.plus(taxPart[index].amount);
					}
				});
			}

			if (ecoTaxTotalAmount.gt(0)) {

				totalAmountAllInc = totalAmountAllInc.plus(ecoTaxTotalAmount);

				if (!isTTC && options.hasVat) {
					ecoTaxPart = Math.percentPart(ecoTaxTotalAmount, 20);
					totalAmountAllInc = totalAmountAllInc.plus(Math.percentPart(ecoTaxTotalAmount, 20));
				} else {
					var ecoTaxTotalAmountHT = Math.TTCtoHT(ecoTaxTotalAmount, 20);
					ecoTaxPart = Math.percentPart(ecoTaxTotalAmountHT, 20);
				}
			}

			totalAmountAllInc = totalAmountAllInc.plus(isTTC ? totalAmountTTC.plus(totalPackagingTTC).plus(totalShippingTTC) : totalAmount.plus(totalShipping).plus(totalPackaging)).minus(discountAmount);

			var totalAmountEcoTaxFree = totalAmountTTC;
			var totalAmountDiscounted = totalAmountTTC.minus(discountAmount);
			var totalAmountNet = totalAmountDiscounted.plus(ecoTaxTotalAmount).plus(totalPackagingTTC).plus(totalShippingTTC);

			var result = {
				totalAmountAllInc: totalAmountAllInc.round(2).toString(),
				totalAmount: totalAmountEcoTaxFree.round(2).toString(),
				totalAmountNet: totalAmountNet.round(2).toString(),
				totalAmountDiscounted: totalAmountDiscounted.round(2).toString(),
				totalShippingAmount: totalShippingTTC.round(2).toString(),
				totalPackagingAmount: totalPackagingTTC.round(2).toString(),
				globalDiscountPercent: discountPercent.round(2).toString(),
				globalDiscountAmount: discountAmount.round(2).toString(),
				taxPart: taxPart,
				ecoTaxAmount: ecoTaxTotalAmount.round(2).toString(),
				ecoTaxPart: ecoTaxPart.round(2).toString()

			};

			return result;
		}

	};

	return Math;
});
