/**
 *	@desc Model Pay medium, extend Core Model 
 */
define('models/singles/payMedium',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			corpid: 0,
			isEnabled: '',
			isWritable: '',
			isChecked: "N",
			rank: '',
			value: ''
		},

		storeName: 'paymedium',
		name: 'paymedium',
		urlRoot: '/rest/paymedium/'
	});
});
