/**
 * @desc Account Pref, extend Page View
 */
define('views/setting/accountPref',['underscore', 'views/core/page', 'app', 'tools/buildURL', 'views/core/navButtons', 'views/core/Popup', 'db/easy', 'tools/core/utils', 'views/core/overview', 'views/widgets/nativeActionSheet'], function (_, Page, App, URLBuilder, navButtons, Popup, DB, Utils, Overview, ActionSheet) {

	'use strict';

	return Page.extend({

		events: _.extend({}, Page.prototype.events),
		title: App.polyglot.t('settings'),

		/**
   * @desc initialize
   */
		initialize: function (router) {

			var _this = this;

			Page.prototype.initialize.apply(this, arguments);

			this.menu = _this.getMenu();

			this.options.extraData = {
				rows: function () {
					return new Promise(function (resolve, reject) {
						resolve(_this.menu);
					});
				}
			};

			this.router = router;
			this.template = 'templates/setting/';
			this.overview = new Overview({ parentView: this });

			_this.subviews.push(_this.overview);
			$('.mask').removeClass("mask-up");
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;
			Page.prototype.afterRender.apply(this, arguments);

			// if we are not on a installed app
			// if(navigator.isNative === true) {
			// 	this.addNavButtons();
			// }

			_.each(this.menu, function (object, key) {

				if (object.callback) {
					_this.events['click ' + '#' + object.id] = object.callback.bind(this);
				}

				if (object.form) {
					if (object.form.callback) {
						_this.events['change ' + '#' + object.form.id] = object.form.callback.bind(this, $('#' + object.form.id));
					}
				}
			});

			this.delegateEvents();
		},

		/**
   * 
   * @returns {undefined}
   */
		addNavButtons: function () {

			var _this = this;

			var onNavPartChange = function (activePart) {
				$('.nav-part:not(' + activePart + ')', this.$el).addClass('hide');
				$('.nav-part' + activePart, this.$el).removeClass('hide');
			};

			this.$('.container-fluid').css("padding-bottom", '40px');

			var options = {

				buttons: {

					prefs: {
						//						label		: "Préférences",
						fa: "fa-cog",
						class: "active",
						callback: function () {
							onNavPartChange.call(this, "#accountpref-prefs");
							_this.title = "Préférences";
						}
					},
					tools: {
						//						label		: "Outils",
						fa: "fa-mobile",
						callback: function () {
							onNavPartChange.call(this, "#accountpref-tools");
							_this.title = "Outils";
						}
					}
				}

			};

			var nav = new navButtons({ parentView: this }, options);
			this.subviews[nav.name] = nav;
		},

		/**
   * 
   * @returns {accountPref_L10.accountPrefAnonym$1.getContent.rows}
   */
		getMenu: function () {

			var _this = this;
			var isAdmin = App.currentStaff.get('permission').isAdmin === "Y";

			var rows = {

				0: {
					fa: 'fa-user',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("corpInfos"),
					url: URLBuilder(["accountPref", "corp"]),
					canAccess: isAdmin
				},
				1: {
					fa: 'fa-user',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("profil"),
					url: URLBuilder(["accountPref", "staff"])
				},
				2: {
					fa: 'fa-bank',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("bankAccounts"),
					url: URLBuilder(["accountPref", "bankaccount"]),
					canAccess: isAdmin
				},
				3: {
					fa: 'fa-list-alt',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("taxes"),
					url: URLBuilder(["accountPref", "tva"]),
					canAccess: isAdmin && App.hasVat
				},
				4: {
					fa: 'fa-cogs',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("dataFormatting"),
					url: URLBuilder(["accountPref", "app"]),
					canAccess: isAdmin
				},
				5: {
					fa: 'fa-lock',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("pwd"),
					url: URLBuilder(["accountPref", "pwd"]),
					canAccess: isAdmin || App.permission.can('newpassword')
				},
				6: {
					fa: 'fa-map-marker',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("corpAddresses"),
					url: URLBuilder(["accountPref", "addr"]),
					canAccess: isAdmin
				},
				7: {
					fa: 'fa-credit-card',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("paymediums"),
					url: URLBuilder(["accountPref", "medium"]),
					canAccess: isAdmin
				},
				8: {
					fa: 'fa-truck',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("transportShippings"),
					url: URLBuilder(["accountPref", "shipping"]),
					canAccess: isAdmin
				},
				9: {
					fa: 'fa-archive',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("packagings"),
					url: URLBuilder(["accountPref", "packaging"]),
					canAccess: isAdmin
				},
				10: {
					fa: 'fa-money',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("ratecategories"),
					url: URLBuilder(["accountPref", "rateCategories"]),
					canAccess: isAdmin
				},
				11: {
					fa: 'fa-balance-scale',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("unities"),
					url: URLBuilder(["accountPref", "unities"]),
					canAccess: isAdmin
				},
				// 12 : {
				// 	fa		: 'fa-paper-plane',
				// 	class	: 'button-with-network uc-first',
				// 	label	: _this.polyglot.t("emailPrefs"),
				// 	url		: URLBuilder(["accountPref","nylas"]),
				// },
				13: {
					id: 'emptylocaldb',
					fa: 'fa-database',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("emptylocaldatas"),
					callback: _this.emptydatabaseConfirm.bind(_this)
				},
				15: {
					id: 'updatecache',
					fa: 'fa-file',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("updatecache"),
					callback: Utils.reload.bind(_this)
				},
				16: {
					id: 'offlineactivation',
					fa: 'fa-wifi',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("offlinemode"),
					form: {
						id: 'offlineactivation-input',
						type: 'boolean',
						value: App.currentStaff.get('isOfflineActivated'),
						callback: function ($elem) {
							var activate = $elem.is(':checked') ? 'Y' : 'N';
							_this.toggleActivationOfflineMode.call(_this, activate, $elem);
						}
					}
				}

			};

			if (navigator.isNative) {

				rows[14] = {
					fa: 'fa-users',
					class: 'button-with-network uc-first',
					label: _this.polyglot.t("contactSync"),
					url: URLBuilder(["accountTool", "synchro"])
				};
			}

			return rows;
		},

		/**
   * 
   * @returns {undefined}
   */
		toggleActivationOfflineMode: function (val, $elem) {

			var _this = this;

			if (val == 'Y') {
				var id = "modal-activateoffline";
				var title = App.polyglot.t('activateoffline');
				var txt = App.polyglot.t('activateoffline-txt');
				var btntxt = App.polyglot.t('activate');
			} else {
				var id = "modal-activateoffline";
				var title = App.polyglot.t('desactivateoffline');
				var txt = App.polyglot.t('desactivateoffline-txt');
				var btntxt = App.polyglot.t('desactivate');
			}

			if (val != App.currentStaff.get('isOfflineActivated')) {

				if (App.useActionSheet) {

					new ActionSheet({
						title: title,
						subtitle: txt,
						buttons: [{
							title: Utils.capitalizeFirstLetter(btntxt),
							callback: App.toggleOfflineMode.bind(_this, val)
						}],
						cancelLabel: App.polyglot.t('cancel'),
						cancelCallback: function () {
							val === 'Y' ? $elem.prop('checked', false) : $elem.prop('checked', true);
						}
					});
				} else {

					new Popup({
						id: id,
						title: title,
						buttons: [{
							title: btntxt, classes: "btn btn-submit", onclick: App.toggleOfflineMode.bind(_this, val)
						}, {
							title: App.polyglot.t('cancel'), classes: "btn btn-cancel"
						}],
						mergeBtns: false,
						content: "<div style='text-align: center;'>" + txt + "</div>",
						callback: function () {
							val === 'Y' ? $elem.prop('checked', false) : $elem.prop('checked', true);
						}
					});
				}
			}
		},

		/**
   * 
   */
		emptydatabaseConfirm: function () {

			if (navigator.isNative) {

				new ActionSheet({
					title: App.polyglot.t('emptylocaldatas'),
					subtitle: App.polyglot.t('emptydatabase_txt'),
					buttons: [{
						title: Utils.capitalizeFirstLetter(App.polyglot.t('validate')),
						callback: this.emptydatabase.bind(this)
					}],
					cancelLabel: App.polyglot.t('cancel')
				});
			} else {

				this.popup = new Popup({
					id: "modal-localdatabase",
					title: App.polyglot.t('emptylocaldatas'),
					buttons: [{ title: App.polyglot.t('validate'), classes: "btn btn-submit", onclick: this.emptydatabase.bind(this) }, { title: App.polyglot.t('cancel'), classes: "btn btn-cancel" }],
					mergeBtns: false,
					content: "<div style='text-align: center;'>" + App.polyglot.t('emptydatabase_txt') + "</div>",
					data: {}
				});
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		emptydatabase: function () {

			var _this = this;
			var promises = [];

			DB.tables.forEach(function (table) {
				promises.push(table.clear());
			});

			Promise.all(promises).then(function () {
				//				_this.router.navigate.call(this, URLBuilder(["accountPref", "deleted"]),{trigger : true});
				Utils.reload();
			}, function () {
				console.log(arguments);
			});
		}

	});
});
