/**
* @desc listing Core, extend Core View 
*/
define('views/core/listing',['underscore', 'jquery', 'views/core/view', 'views/core/searchBar', 'app', 'tools/ajax', 'views/core/toastNotification', 'tools/buildURL', 'hammerjs'], function (_, $, View, SearchBar, App, Ajax, ToastNotification, URLBuilder, Hammer) {

	"use stric";

	return View.extend({

		events: {
			"click .row-listing[data-linkoverview]": "gotoOverview"
		},

		tagName: "div",
		id: "listing",
		template: {},
		collections: {},
		patternSearch: "",
		anchorPosition: 0,
		loadingEnd: true,
		embedded: false,

		/**
   * 
   * @param {type} collection
   * @param {type} template
   * @param {type} header
   * @returns {undefined}
   */
		initialize: function (view, target) {

			View.prototype.initialize.apply(this, arguments);

			this.collections = view.parentView.collections;
			this.parentView = view.parentView;
			this.template = view.template ? view.template : view.parentView.template;
			this.router = view.parentView.router;
			var collectionName = view.parentView.collection.listName ? view.parentView.collection.listName : view.parentView.collection.name;
			this.collection = this.collections[collectionName] = view.parentView.collection;
			this.options = view.parentView.options;
			this.models = view.parentView.models;
			this.render4Scroll = true;

			if (!this.parentView.hideSearchIcon) {
				this.placeholderSearchBar = this.parentView.placeholderSearchBar;
				this.searchBar = new SearchBar('templates/widgets/searchBar.twig', this, this.placeholderSearchBar);
				this.parentView.subviews.push(this.searchBar);
			}

			if (!this.collection.XHRData.search) {
				this.collection.XHRData.search = {};
			}

			//map saved filter value with XHRData
			if (this.parentView.listingFiltersCollection) {

				var _this = this;

				this.parentView.listingFiltersCollection.each(function (filter) {

					if (filter.get('value') && filter.get('value') != '--' && filter.get('value') !== 'all') {

						_this.collection.XHRData.search[filter.get('name')] = filter.get('isMultiple') ? !_.isArray(filter.get('value')) ? [filter.get('value')] : filter.get('value') : filter.get('value');

						if (filter.get('offlineSearchField')) {

							switch (filter.get('value')) {
								case 'mine':
									_this.collection.XHRData.search[filter.get('offlineSearchField')] = App.currentStaff.get('id');
									break;

								case 'none':
									_this.collection.XHRData.search[filter.get('offlineSearchField')] = '0';
									break;

								default:
									_this.collection.XHRData.search[filter.get('offlineSearchField')] = filter.get('isMultiple') ? !_.isArray(filter.get('value')) ? [filter.get('value')] : filter.get('value') : filter.get('value');
									break;

							}
						}
					} else {
						delete _this.collection.XHRData.search[filter.get('name')]; //prevent default values outer filters
						delete _this.collection.XHRData.search[filter.get('offlineSearchField')]; //prevent default values outer filters
					}
				});
			}

			$('.container-fluid', $('header')).removeAttr("style");

			if (!_.isUndefined(target)) {
				this.setElement(target);
				this.events['click .loadMore'] = 'loadMore';
				this.delegateEvents();
				this.embedded = true;
			} else {
				this.on('scrollend', this.loading);
			}
		},

		/**
  * @description do some check for know if we can go to overview since listing or not (overrided in PageViews)
  * @returns {Boolean}
  */
		checkBeforeOverview: function (e) {

			switch (this.collection.name) {

				case 'timetrackings':
					return $(e.currentTarget).data('cancrud') === true && this.checkButton(e);
					break;
				default:
					return true;
					break;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		loadMore: function () {

			var _this = this;
			var loadMoreContainer = this.$('.loadmorebtn-container');
			var btn = loadMoreContainer.find('.btn');
			var faloader = 'fa-spinner';

			btn.attr('disabled', true);

			this.collection.pagination.pagenum = parseInt(this.collection.pagination.pagenum) + 1;

			btn.append(' <i class="fa ' + faloader + ' fa-spin"></i>');

			this.collection.fetch({
				method: 'POST', //fetching collection is always with POST method
				data: {
					pagination: this.collection.pagination
				}
			}, true).then(function (collection) {

				if (collection.nbResults == 0) {

					loadMoreContainer.find('.wrapper-btn').remove();
					loadMoreContainer.append("<div class='emptyPanel'><i class='fa fa-th-list'></i><span class='el-txt'> " + App.polyglot.t('noMoreElem') + " </span></div>");
				} else {

					var collec_json = collection.toJSON();

					var from = parseInt(collection.pagination.pagenum) * parseInt(collection.pagination.nbperpage) - 10;
					var to = parseInt(collection.pagination.nbperpage);

					_this.onLoadMore(collec_json.splice(from, to)).then(function () {
						loadMoreContainer.find('.' + faloader).remove();
						btn.removeAttr('disabled');
					});
				}
			});
		},

		// load more
		onLoadMore: function (collection) {

			var _this = this;

			this.collection.massAdd(collection);

			var insertTemplate = function (template) {
				var templateConvList = _.template(template);
				var tmp = templateConvList({ 'timeline': collection, 'App': App, 'privileges': App.permission });
				_this.$('.listing').append(tmp);
			};

			return new Promise(function (resolve, reject) {

				if (!_this.listTemplate) {

					var rowTmpl = _this.parentView.rowsTemplate;

					Ajax({
						method: 'GET',
						url: rowTmpl,
						dataType: "HTML",
						success: function (template) {
							_this.listTemplate = template;
							insertTemplate(_this.listTemplate);
							resolve();
						},
						error: function () {
							reject();
						}
					});
				} else {
					insertTemplate(_this.listTemplate);
					resolve();
				}
			}).then(function () {
				_this.parentView.initEffect();
			});
		},

		/**
   * insert the listing in DOM
   */
		renderCallback: function () {

			$('body').css('overflow', 'hidden');

			var _this = this;
			this.loadMore = true;
			this.$.page = this.parentView.$el;

			$("#no-result").remove();

			if (this.parentView.$el.hasClass('toggleablePage')) {
				this.$.page.html(this.$el);
			} else {
				this.$.page.append(this.$el);
			}

			this.$.rowListing = $(".row-listing", this.$el);
			this.$.mainContainer = $('.main-container', this.$el);

			this.$.mainContainer.removeAttr('style');

			$(window).on("resize", _this.computeMainContainerHeight.bind(this));

			if (this.render4Scroll) {
				this.computeMainContainerHeight.call(this); //window height - header height (because body have a margin top of the header's size 
			}

			if (!this.embedded && this.collection.pagination || this.collection.mustFetchLocal) {
				this.$.mainContainer.on('scroll', function (e) {
					_this.checkScroll(e);
				});
			}

			//prevent multi rendering
			setTimeout(function () {
				_this.loadingEnd = true;
			}, 200);

			this.$el.disableSelection();

			$('body').removeAttr('style');
			$('#loader', '.main-container').appendTo('#listing-widget').show();

			return this;
		},

		/**
   * 
   * @returns {undefined}
   */
		computeMainContainerHeight: function () {
			var $height = $(window).outerHeight() - App.header.$el.height();
			this.$.mainContainer.css("height", $height);
		},

		/**
   * 
   * @param {type} event
   * @returns {undefined}
   */
		checkScroll: function (event) {

			var $launcher = $(event.currentTarget);

			//check if we are at the end of pagination
			this.loadMore = this.collection.checkPagination();

			//load next page if  we have not already a loading  and if we hare not at the end of pagination
			if (this.loadingEnd && $launcher.scrollTop() + $launcher.outerHeight() >= $launcher.prop('scrollHeight') && this.loadMore) {

				this.collection.pagination.pagenum = parseInt(this.collection.pagination.pagenum) + 1;
				this.anchorPosition = $launcher.scrollTop();

				this.trigger("scrollend");
			}
		},

		/**
   * @desc display loader
   * @returns {undefined}
   */
		loading: function () {

			this.loadingEnd = false;

			var scrollTopVal = this.anchorPosition + $('#loader', '#listing-widget').height() + 20;

			$('#loader', '#listing-widget').show();

			this.$.mainContainer.scrollTop(scrollTopVal);

			this.render4Scroll = true;

			this.renderOptions = {
				add: true
			};

			var _this = this;

			this.parentView.render().then(function () {
				_this.$.mainContainer.scrollTop(_this.anchorPosition);
			});
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		gotoOverview: function (e) {

			var canOverview = this.checkBeforeOverview.call(this, e);

			e.stopPropagation();

			if (!$(e.target).closest('a').length) {

				if (canOverview) {

					var $currentRow = $(e.currentTarget);

					if ($currentRow.data("linkoverview")) {

						var link = $currentRow.data("linkoverview");
						this.router.navigate(link, { trigger: true });
					}
				} else {

					new ToastNotification({
						id: 'toast-error',
						classes: 'col-xs-12 ',
						timeDuration: 4000,
						content: App.polyglot.t('cantEdit')
					});
				}
			}
		},

		/**
   * @description resize window for listing after keyboard down
   * @returns {undefined}
   */
		resizeWindow: function () {
			var $height = this.initialHeight; //window height - header height (because body have a margin top of the header's size 
			this.$.mainContainer.css("height", $height);
		}

	});
});
