/**
 * @desc Document router, extend Core Router
 */
define('routers/document',['backbone', 'underscore', 'routers/core/router', 'app', 'tools/buildURL'], function (Backbone, _, CoreRouter, App, URLBuilder) {

	'use strict';

	return CoreRouter.extend({

		checkOnline: {
			form: true,
			docRowForm: true,
			docPrefGlobal: true,
			stockRecap: true
		},

		plugin: 'invoicing',
		sidelink: 'documents',

		/**
   * 
   */
		routes: {
			'document/home': 'documentHome',
			'document/list/:type': 'listing',
			'document/overview/:doctype/:id': 'overview',
			'document/form/:doctype/:thirdid(/)(:id)(/)(:comefrom)(/)(:transformTo)': 'form',
			'document/docRowForm/:doctype/:thirdid/:id/:langid(/)(:rowid)(/)(:linkedid)(/:declid)': 'docRowForm',
			'document/docPrefGlobal/:doctype/:thirdid/(:id)(/)': 'docPrefGlobal',
			'document/stockRecap/:doctype/:thirdid/(:id)(/)': 'stockRecap',
			'document/createDoc/:doctype(/:docid)(/:isCopy)(/:thirdid)': 'createDoc'
		},

		/**
   * 
   * @param {type} callback
   * @param {type} args
   * @returns {undefined}
   */
		execute: function (callback, args) {
			CoreRouter.prototype.execute.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} doctype
   * @returns {document_L10.documentAnonym$1@call;redirectError}
   */
		checkRight: function (doctype) {
			return App.permission.level('invoicing_' + doctype + 'level');
		},

		/**
   * 
   * @param {doctype} type
   * @returns {undefined}
   */
		listing: function (doctype) {

			var _this = this;

			if (!this.checkRight(doctype)) {
				return this.redirectError();
			}

			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/document/list'], function (require, DocList) {

					var links = _this.getAvailableActions();
					var view = new DocList(_this, doctype, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} id
   * @param {type} comefrom
   * @returns {undefined}
   */
		form: function (type, thirdid, id, comefrom, transformTo) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			if (!this.checkRight(type)) {
				return this.redirectError();
			}

			return new Promise(function (resolve, reject) {

				require(['require', 'views/document/form'], function (require) {

					var DocForm = require('views/document/form');
					var view = id !== null ? new DocForm(_this, type, thirdid, id, comefrom, transformTo) : new DocForm(_this, type, 0, comefrom);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
  	 * @param {type} type
   * @param {type} id
   * @returns {undefined}
   */
		overview: function (doctype, id) {

			var _this = this;

			if (!this.checkRight(doctype)) {
				return this.redirectError();
			}

			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/document/overview'], function (require) {

					var DocOverview = require('views/document/overview');
					var view = new DocOverview(_this, doctype, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} doctype
   * @param {type} docid
   * @returns {undefined}
   */
		docRowForm: function (doctype, thirdid, docid, langid, rowindex, linkedid, declid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			if (!this.checkRight(doctype)) {
				return this.redirectError();
			}

			return new Promise(function (resolve, reject) {

				require(['require', 'views/document/docRowForm'], function (require) {

					var DocRowForm = require('views/document/docRowForm');
					var view = new DocRowForm(_this, doctype, thirdid, docid, langid, rowindex, linkedid, declid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   */
		docPrefGlobal: function (doctype, thirdid, docid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			if (!this.checkRight(doctype)) {
				return this.redirectError();
			}

			return new Promise(function (resolve, reject) {

				require(['require', 'views/document/docPrefGlobal'], function (require) {

					var DocPrefGlobal = require('views/document/docPrefGlobal');
					var view = new DocPrefGlobal(_this, doctype, thirdid, docid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} doctype
   * @param {type} thirdid
   * @param {type} docid
   * @returns {undefined}
   */
		stockRecap: function (doctype, thirdid, docid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			return new Promise(function (resolve, reject) {

				require(['views/document/stockRecap'], function (StockRecap) {

					var view = new StockRecap(_this, doctype, thirdid, docid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {Promise}
   */
		documentHome: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule'], function (require, HomeModule) {

					var links = _this.getAvailableActions();
					var view = new HomeModule(_this, links, 'documents');
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} doctype
   * @param {type} docid
   * @param {type} isCopy
   * @param {type} thirdid
   * @returns {Promise}
   */
		createDoc: function (doctype, docid, isCopy, thirdid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/document/createDoc'], function (require, CreateDoc) {

					var view = new CreateDoc(_this, doctype, docid, isCopy, thirdid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		getAvailableActions: function () {

			var links = [{
				fa: 'fa-file-text-o',
				name: 'invoices',
				link: URLBuilder(['document', 'list', 'invoice']),
				canAccess: App.permission.level('invoicing_invoicelevel')
			}, {
				fa: 'fa-file-text-o',
				name: 'estimates',
				link: URLBuilder(['document', 'list', 'estimate']),
				canAccess: App.permission.level('invoicing_estimatelevel')

			}, {
				fa: 'fa-file-text-o',
				name: 'orders',
				link: URLBuilder(['document', 'list', 'order']),
				canAccess: App.permission.level('invoicing_orderlevel')
			}, {
				fa: 'fa-file-text-o',
				name: 'deliveries',
				link: URLBuilder(['document', 'list', 'delivery']),
				canAccess: App.permission.level('invoicing_deliverylevel')
			}, {
				fa: 'fa-file-text-o',
				name: 'proformas',
				link: URLBuilder(['document', 'list', 'proforma']),
				canAccess: App.permission.level('invoicing_proformalevel')
			}, {
				fa: 'fa-file-text-o',
				name: 'creditnotes',
				link: URLBuilder(['document', 'list', 'creditnote']),
				canAccess: App.permission.level('invoicing_creditnotelevel')
			}];

			return links;
		}

	});
});
