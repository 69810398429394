/**
 *  @desc Documents Collection, extend core Collection
 */
define('models/collections/documents',['models/core/collection', 'models/singles/document', 'db/easy'], function (Collection, Document, DB) {

	'use strict';

	return Collection.extend({
		model: Document,
		storeName: 'document',
		name: 'documents',
		url: '/rest/document/list',
		fields: ['ident', 'thirdname'],
		doctype: '',
		searchFieldName: 'ident',

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['thirdid', 'doctype', 'step'];
			this.orderArg = 'doc_displayedDate';
		}

	}, {

		/**
   * @description return the right constructor for the given doctype
   * @returns {undefined}
   */
		classNameByDoctype: function (doctype) {

			var className = '';

			return new Promise(function (resolve, reject) {

				switch (doctype) {

					case 'invoice':

						require(['models/collections/invoices'], function (Invoices) {
							className = Invoices;
							resolve(className);
						});

						break;

					case 'estimate':

						require(['models/collections/estimates'], function (Estimates) {
							className = Estimates;
							resolve(className);
						});

						break;

					case 'order':

						require(['models/collections/orders'], function (Orders) {
							className = Orders;
							resolve(className);
						});

						break;

					case 'delivery':

						require(['models/collections/deliveries'], function (Deliveries) {
							className = Deliveries;
							resolve(className);
						});

						break;

					case 'proforma':

						require(['models/collections/proformas'], function (Proformas) {
							className = Proformas;
							resolve(className);
						});

						break;

					case 'creditnote':

						require(['models/collections/creditnotes'], function (CreditNotes) {
							className = CreditNotes;
							resolve(className);
						});

						break;

					case 'model':

						require(['models/collections/models'], function (Models) {
							className = Models;
							resolve(className);
						});

						break;

					default:

						reject('invalid doctype : ' + doctype);
						break;
				}
			});
		}

	});
});
