/**
 *  @desc Address Collection, extend core Collection
 */
define('models/collections/tasks',['models/core/collection', 'models/singles/task', 'db/easy', 'moment', 'tools/core/utils'], function (Collection, Agenda, DB, Moment, Utils) {

	'use strict';

	return Collection.extend({

		model: Agenda,
		storeName: 'task',
		name: 'task',
		url: '/rest/agenda/list/task',

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['relationType', 'type', 'labelid'];
			this.orderBy = 'asc';
		},

		/**
   * @desc override for filter by staff in charge
   * @param {type} _this
   * @param {type} value
   * @param {type} options
   * @returns {undefined}
   */
		offlineQuery: function (value, options) {

			var finded = Collection.prototype.offlineQuery.apply(this, arguments);

			//when staffs is provided, do the search with else juste return the result of parent function
			if (this.XHRData.search['staffs']) {

				var staffsIds = this.XHRData.search['staffs'];

				_.each(staffsIds, function (id, index) {
					staffsIds[index] = ("" + id).split('_')[0];
				});

				var filterByStaff = false;

				if (value.staffs) {

					_.each(value.staffs, function (staff) {

						if (Utils.inArray(staff.id, staffsIds)) {
							filterByStaff = true;
						}
					});
				}

				finded = finded && (filterByStaff || Utils.inArray(value.ownerid, staffsIds));
			}

			if (this.XHRData.search['state']) {

				var filterByPeriod = false;
				var dateNow = Moment().unix();
				var endOfDay = Moment().endOf('day').unix();
				switch (this.XHRData.search['state']) {

					case 'late':
						filterByPeriod = value.timestampStart < dateNow;
						break;

					case 'today':
						filterByPeriod = value.timestampStart > dateNow && value.timestampStart < endOfDay;
						break;

					case 'tomorrow':
						filterByPeriod = value.timestampStart > endOfDay;
						break;

				}

				finded = finded && filterByPeriod;
			}

			return finded;
		}

	});
});
