/**
* @desc PageList, extend Page View
* Child View : Core Listing
*/
define('views/core/pageList',['underscore', 'views/core/page', 'models/collections/filters', 'views/core/navButtons', 'tools/buildURL', 'hammerjs', 'tools/core/utils'], function (_, Page, ListingFilters, NavButtons, URLBuilder, Hammer, Utils) {

	'use strict';

	return Page.extend({

		/**
   * 
   * @param {type} router
   * @returns {undefined}
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			return new Promise(_.bind(function (resolve, reject) {

				this.addNavButtons().then(_.bind(function (options) {

					if (!_.isEmpty(options.buttons)) {
						var nav = new NavButtons({ parentView: this }, options);
						this.subviews[nav.name] = nav;
					}

					resolve();
				}, this));
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			//remove box-shadow on header for listing with searchbar
			if (!this.hideSearchIcon) {
				App.header.$el.find("nav").addClass("box-shadow-none");
				//display searchBar
				$("#wrapper-search").css('visibility', 'visible');
			}

			var _this = this;

			Page.prototype.afterRender.apply(this, arguments);

			if (!this.disablePullToRefresh) {
				this.initPullToRefresh(this.listing.el, this.listing.$.mainContainer, 'listing');
			}

			//fix for scrolling on ios (hammer js bug :| )
			$('.main-container', this.$el).css('-webkit-overflow-scrolling', 'touch');
		},

		/**
   * 
   * @returns {Promise}
   */
		addNavButtons: function () {

			var _this = this;

			return new Promise(_.bind(function (resolve, reject) {

				var options = {
					buttons: {}
				};

				//add nav buttons

				if (!this.hideFilterPannel) {

					options.buttons.filter = {
						img: Utils.loadFile('go_filter.svg', 'images'),
						callback: function () {
							_this.router.navigate(URLBuilder(['filterPanel', _this.name]), { trigger: true });
							return false;
						},
						label: this.filterLabel ? this.filterLabel : App.polyglot.t('filterTo')
					};
				}

				if (this.addAction) {
					if (_this.canAdd) {
						options.buttons.add = {
							fa: this.addFa ? this.addFa : "fa-pencil-square-o",
							img: this.addImg ? this.addImg : Utils.loadFile('add_something.svg', 'images'),
							label: this.addLabel ? this.addLabel : App.polyglot.t('add'),
							class: 'button-with-network',
							callback: function () {
								_this.addAction.apply(_this);
								return false;
							}
						};
					}
				};

				//fetch value for nav buttons actions
				if (!this.hideFilterPannel) {

					this.listingFiltersCollection = new ListingFilters();
					this.listingFiltersCollection.XHRData = {
						search: {
							listing: this.name
						}
					};

					//take saved filters values in local DB
					this.listingFiltersCollection.fetchLocal(this.listingFiltersCollection.XHRData).then(function () {

						if (_this.listingFiltersCollection.get(_this.name + '_savedSearchId')) {
							_this.useSavedSearch = true;
						}

						resolve(options);
					});
				} else {
					resolve(options);
				}
			}, this));
		}

	});
});
