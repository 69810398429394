/**
 *	@desc Model Corp, extend Core Model 
 */
define('models/singles/corp',['models/core/model', 'db/easy', 'tools/ajax', 'app', 'tools/numberFormat'], function (Model, DB, Ajax, App, NumberFormat) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'corp',
		name: 'corp',
		url: '/rest/corp/',

		fetchLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				if (App.indexedDB) {

					if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {
						DB[_this.storeName].toCollection().first().then(function (model) {

							_this.set(model);

							resolve();
						}, reject);
					}
				}
			});
		},

		/**
   * 
   * @returns {Promise}
   */
		saveLocal: function () {

			var _this = this;

			var errorHandler = function (resolve, reject, ajaxError) {

				// Becareful with ajax response
				if (_.isUndefined(ajaxError)) {
					resolve();
				} else {
					reject(ajaxError);
				}
			};

			return new Promise(function (resolve, reject) {

				if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

					var id = [];
					id[_this.idAttribute] = parseInt(_this.id);

					_this.set(id);

					DB[_this.storeName].put(_this.toJSON()).then(function (lastId) {

						if (_this.get('address')) {
							//save address in localDB 
							DB['address'].put(_this.get('address')).then(function () {
								resolve();
							}, function (error) {
								console.log(error);
							});
						} else {
							resolve();
						}
					}, reject);
				} else {
					errorHandler(resolve, reject, ajaxError);
				}
			}).catch(function (e) {
				console.log(e);
			});
		},

		updateCorpAddress: function () {

			var _this = this;

			var address = this.get('address');
			address['addressid'] = parseInt(address['addressid']);

			return new Promise(function (resolve, reject) {

				Ajax({
					method: 'PUT',
					url: '/rest/corp/address',
					data: JSON.stringify({ address: address }),
					success: function () {
						DB[_this.name].update(parseInt(_this.id), { address: address }).then(function () {
							DB['address'].put(address).then(function () {
								resolve();
							}, function (error) {
								console.log(error);
							});
						});
					},
					fail: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		getCorpAddresses: function () {

			return new Promise(function (resolve, reject) {

				Ajax({
					method: 'GET',
					url: '/rest/corp/addresses',
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						console.log(err);
						reject(err);
					}
				});
			});
		},

		register: function () {

			var _this = this;
			var jsonData = this.toJSON();

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/auth/register',
					method: 'POST',
					dataType: "json",
					data: JSON.stringify(jsonData),
					success: function (data) {

						if (data.status === 'success') {
							resolve();
						} else {
							reject();
						}
					},

					error: function (data) {
						reject();
					}

				});
			});
		},

		/**
   * 
   * @param {type} attributes
   * @returns {undefined}
   */
		validate: function (attributes) {

			var validationErrors = { corp: {} };

			var required = {

				'corp': {
					'name': true,
					'email': true,
					'tel': true
				}

			};

			//corp validator
			if (!attributes.name && required.corp.name) {
				validationErrors['corp']['name'] = this.computeError({ name: 'name', type: 'required' });
			}

			//email validator
			if (!attributes.email && required.corp.email) {
				validationErrors['corp']['email'] = this.computeError({ name: 'email', type: 'required' });
			}

			//email validator
			if (!attributes.tel && required.corp.tel) {
				validationErrors['corp']['tel'] = this.computeError({ name: 'tel', type: 'required' });
			}

			if (_.toArray(validationErrors.corp).length > 0) {
				return validationErrors;
			}
		},

		validateRegister: function (attributes) {

			var required = {

				"corp": {
					registerCorpName: true,
					registerEmail: true,
					registerTel: true,
					registerPwd: true,
					registerCountryCode: true
				}

			};

			var validatorRegEx = {
				email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i,
				phone: /^([0-9]*)$/
			};

			var validationErrors = { corp: {} };

			//corp validator
			if (!attributes.registerCorpName && required.corp.registerCorpName) {
				validationErrors['corp']['registerCorpName'] = 'Corp name is required';
			}

			if (attributes.registerPwd.length < 6) {
				validationErrors['corp']['registerPwd'] = 'Votre mot de passe doit faire au moins 6 caractères';
			}

			if (!validatorRegEx.email.test(attributes.registerEmail) || attributes.registerEmail === '' && required.corp.registerEmail) {
				validationErrors['corp']['registerEmail'] = attributes.registerEmail === "" ? this.computeError({ name: 'registerEmail', type: 'required' }) : this.computeError({ name: 'registerEmail', type: 'invalid' });
			}

			if (!validatorRegEx.phone.test(attributes.registerTel) || attributes.registerTel === "" && required.corp.registerTel) {
				validationErrors['corp']['registerTel'] = attributes.registerTel === '' ? this.computeError({ name: 'registerTel', type: 'required' }) : this.computeError({ name: 'registerTel', type: 'invalid' });
			}

			if (_.toArray(validationErrors.corp).length > 0) {
				return validationErrors;
			}
		}

	}, {
		//STATIC METHODS

		/**
  * 
  * @param {type} timeFormat
  * @returns {Promise}
  */
		updateDateFormat: function (dateFormat) {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: '/rest/corp/dateFormat',
					method: 'put',
					data: JSON.stringify({ dateFormat: dateFormat }),
					success: function () {

						App.dateFormat = dateFormat;
						App.dateFormat.momentFormat = dateFormat.timeFormat === 'dmy' ? "DD/MM/YYYY" : "MM/DD/YYYY";

						DB['corp'].update(App.corpId, { dateformat: App.dateFormat }).then(function () {
							resolve();
						});
					},
					fail: function () {
						reject(arguments);
					}

				});
			});
		},

		/**
   * 
   * @param {type} numberFormat
   * @returns {undefined}
   */
		updateNumberFormat: function (numberFormat, oldId) {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: '/rest/corp/numberFormat',
					method: 'PUT',
					data: JSON.stringify({ numberformat: numberFormat }),
					success: function () {

						var numberformat = {
							currencypos: numberFormat.currencyposition,
							currencysymbol: numberFormat.symbol,
							decimals: numberFormat.numberdecimals,
							precision: numberFormat.numberprecision,
							thousands: numberFormat.numberthousands
						};

						//update numberformat,local currency selected, and change old selected to N
						DB.transaction("rw", DB['currency'], DB['corp'], function () {

							//update global var
							App.numberFormat = new NumberFormat(numberformat);

							//update local numberformat
							DB['corp'].update(App.corpId, { numberformat: App.numberFormat });

							// change old selected to N
							DB['currency'].update(parseInt(oldId), { selected: 'N' }).then(function () {
								//change new selected to Y
								DB['currency'].update(parseInt(numberFormat.currencyid), { selected: 'Y' });
							}, function (error) {
								console.log(error);
							});
						}).then(function () {
							resolve();
						}, function (error) {
							console.log(error);
						});

						resolve();
					},
					fail: function () {
						reject(arguments);
					}

				});
			});
		}

	});
});
