/**
 * @desc Expense Form, extend Page View
 * Child View : Core Form
 */
define('views/expense/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/expense', 'models/collections/expenses', 'models/collections/suppliers', 'models/collections/taxes', 'models/collections/accountings', 'models/collections/smarttags', 'app', 'tools/buildURL', 'tools/math', 'big'], function (Backbone, _, Page, Form, Expense, Expenses, Suppliers, Taxes, Accountings, Smarttags, App, URLBuilder, Math, Big) {

	'use strict';

	return Page.extend({

		events: {
			"change input[name$='[unitAmount]']": 'computeTaxInc',
			"change select[name$='[taxid]'], select[name$='[tax2id]']": 'computeTaxInc',
			"change input[name$='[unitAmountTaxesInc]']": 'computeTaxFree',
			"click .addline": 'addLine',
			"click .remove-line": 'deleteRow'
		},

		form: {},
		template: "templates/expense/form.twig",
		toastMessages: {
			created: App.polyglot.t('expenseadded'),
			updated: App.polyglot.t('expenseupdated')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router, id) {

			Page.prototype.initialize.apply(this, arguments);

			this.modelToSave = new Expense({ id: id });
			this.models[this.modelToSave.name] = this.modelToSave;

			this.supplier = new Suppliers();
			this.collections[this.supplier.name] = this.supplier;

			// if accounting plugin is loaded
			if (App.pluginIsLoaded('accounting')) {

				this.accounting = new Accountings();

				this.accounting.XHRData = {
					search: {
						view: 'purchase'
					}
				};

				this.collections[this.accounting.name] = this.accounting;
			}

			if (App.hasVat) {
				this.taxes = new Taxes();
				this.collections[this.taxes.name] = this.taxes;
			}

			if (this.modelToSave.isNew()) {

				this.modelToSave.set({ hasDoubleVat: App.docPrefs.useDoubleVat });

				this.options.extraData.nextIdent = function () {

					return new Promise(function (resolve, reject) {

						var expenses = new Expenses();

						expenses.getNextIdent().then(function (nextident) {
							resolve(nextident);
						});
					});
				};

				this.title = App.polyglot.t("newExpense");
			} else {

				this.title = App.polyglot.t("editExpense");
				// go looking for smarttags only on editing mode

				// smartTags filter
				this.smartTagsFilter = {
					linkedtype: 'expense',
					linkedid: id,
					category: 'expense',
					categoryForIdb: 'Note de frais'
				};

				// SmartTags parts. Get smarttags of linked
				this.smartTags = new Smarttags();
				this.smartTags.XHRData = {
					search: this.smartTagsFilter
				};

				this.collections[this.smartTags.name] = this.smartTags;
			}

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);
			this.render();
			this.listenTo(this.form, 'dataSend', this.formAction);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			this.rowTemplate = this.$('#expenseRow-tmp').clone();
			this.rowTemplate.removeClass('hidden');
			this.$('#expenseRow-tmp').remove();

			if (this.modelToSave.isNew()) {

				var line = _this.setExpenseRow({}, 1, 1);
				this.$('#expenseRowForm .expenseRows').append(line);
			} else {

				var nbTotalRows = Object.keys(_this.modelToSave.get('map').rows).length - 1;

				$.each(_this.modelToSave.get('map').rows, function (index, row) {
					if (index != '_xml_childtag') {
						var line = _this.setExpenseRow(row, row.rank, nbTotalRows);
						_this.$('#expenseRowForm .expenseRows').append(line);
					}
				});
			}

			if (!this.modelToSave.isNew()) {
				this.constructSmartTags().then(function () {
					Page.prototype.afterRender.apply(_this, arguments);
				});
			} else {
				Page.prototype.afterRender.apply(_this, arguments);
			}

			this.setSelectize();
			this.delegateEvents();
			this.computeTotalAmount();
		},

		/**
   * 
   * @param {type} row
   * @returns {unresolved}
   */
		setExpenseRow: function (row, rank, nbTotalRows) {

			var _this = this;

			var templ = _this.rowTemplate.clone();

			templ.find('.expense-name').attr('value', row.name);
			templ.find('.expense-notes').attr('value', row.notes);
			templ.find('.expense-unitAmount').attr('value', App.numberFormat.formatToDisplay(row.unitAmount, true, false));
			templ.find('.expense-accountingCode option').removeAttr('selected').filter('[value="' + row.accountingCode + '"]').attr('selected', 'selected');
			templ.find('.expense-analyticsCode').attr('value', row.analyticsCode);
			templ.find('.expense-taxid option').removeAttr('selected').filter('[value="' + row.taxid + '"]').attr('selected', 'selected');
			templ.find('.expense-tax2id option').removeAttr('selected').filter('[value="' + row.tax2id + '"]').attr('selected', 'selected');
			templ.find('.expense-unitAmountTaxesInc').attr('value', App.numberFormat.formatToDisplay(row.unitAmountTaxesInc, true, false));

			if (nbTotalRows == 1) {
				templ.find('.remove-line').addClass('hidden');
			}

			return templ.html().replace(/(\[)i(\])/g, rank);
		},

		/**
   * 
   */
		deleteRow: function (e) {

			var expenseRows = $(e.currentTarget).closest('.expenseRows');
			var v_current = $(e.currentTarget).closest('.expenseRow');

			if (expenseRows.find('.expenseRow').length == 2) {
				v_current.remove();
				expenseRows.find('.expenseRow').find('.remove-line').addClass('hidden');
			} else {
				v_current.remove();
			}

			this.computeTotalAmount();
		},

		/**
   * 
   * @returns {undefined}
   */
		addLine: function () {

			var expenseRows = $('#expenseRowForm .expenseRows');
			var nbLines = expenseRows.find('.expenseRow').length;
			expenseRows.append(this.setExpenseRow({}, nbLines + 1));

			if (nbLines >= 1) {
				expenseRows.find('.remove-line').removeClass('hidden');
			}

			this.computeTotalAmount();
		},

		/**
   * 
   * @returns {undefined}
   */
		computeTaxInc: function (e) {

			var v_current = $(e.currentTarget);
			var row = v_current.closest('.expenseRow');
			var unitAmount = App.numberFormat.formatToFloat(row.find('.expense-unitAmount').val());

			if (this.modelToSave.get('hasVat') == 'Y' || App.hasVat) {

				var taxAmount = row.find('.expense-taxid option:selected').data('value');
				var taxSum = Math.percentPart(unitAmount, taxAmount, true);

				if (this.modelToSave.get('hasDoubleVat') == 'Y') {
					var taxAmount = row.find('.expense-tax2id option:selected').data('value');
					taxSum = +Math.percentPart(unitAmount, taxAmount).plus(taxSum);
				}

				var unitAmountTTC = Math.percentageIncrease(unitAmount, taxAmount);
			}

			row.find('.expense-unitAmountTaxesInc').val(App.numberFormat.formatToDisplay(unitAmountTTC, true, false));
			this.computeTotalAmount();
		},

		/**
   * 
   * @returns {undefined}
   */
		computeTaxFree: function (e) {

			var v_current = $(e.currentTarget);
			var row = v_current.closest('.expenseRow');

			var unitAmount = App.numberFormat.formatToFloat(v_current.val());
			var unitAmountFree = unitAmount;

			if (this.modelToSave.get('hasVat') == 'Y' || App.hasVat) {

				var taxAmount = row.find('.expense-taxid option:selected').data('value');
				unitAmountFree = Math.TTCtoHT(unitAmount, taxAmount).toString();

				if (this.modelToSave.get('hasDoubleVat') == 'Y') {
					//impossible
				}
			}

			row.find('.expense-unitAmount').val(App.numberFormat.formatToDisplay(unitAmountFree, true, false));
			this.computeTotalAmount();
		},

		/**
   * 
   * @returns {undefined}
   */
		computeTotalAmount: function () {

			var rows = [];
			var _this = this;

			this.$('.expenseRow').each(function (index, elem) {

				var row = {
					unitAmount: App.numberFormat.formatToFloat($(elem).find('.expense-unitAmount').val()),
					qt: "1",
					type: "once"
				};

				if (_this.modelToSave.get('hasVat') == 'Y' || App.hasVat) {
					row['taxRate'] = $(elem).find('.expense-taxid').find(":selected").data('value');
					row['taxid'] = $(elem).find('.expense-taxid').val();
				}

				if (_this.modelToSave.get('hasDoubleVat') == 'Y') {
					row['taxRate2'] = $(elem).find('.expense-tax2id').find(":selected").data('value');
					row['tax2id'] = $(elem).find('.expense-tax2id').find(":selected").val();
				}

				rows[index] = row;
			});

			var totalDoc = Math.sumRowsV2(rows, { numberFormat: App.numberFormat, hasVat: this.modelToSave.get('hasVat') == 'Y' || App.hasVat, hasDoubleVat: this.modelToSave.get('hasDoubleVat') == 'Y' });
			this.$('#row-amount').html(App.numberFormat.formatToDisplay(totalDoc.totalAmount, true, true));
		},

		/**
   * 
   * @returns {undefined}
   */
		setSelectize: function () {

			var _this = this;

			if (this.modelToSave.isNew()) {

				var thirdOptions = this.collections[this.supplier.name];
				var third = {};

				var currencies = App.currencies;
				var currency = App.currencies.findWhere({ 'selected': 'Y' });
			} else {

				var third = thirdOptions = {
					0: this.modelToSave.get('linkedThird')
				};

				var currency = currencies = {
					0: {
						'id': this.modelToSave.get('currencyid'),
						'symbol': this.modelToSave.get('currencysymbol')
					}
				};
			}

			this.selectizeObjects = [{
				domElt: _this.$el.find('input[name="expense[thirdid]"]'),
				defaultValues: third,
				options: thirdOptions,
				create: false,
				valueField: 'id',
				labelField: 'name',
				searchField: ['name', 'forename'],
				maxItems: 1,
				//					maxOptions		: 1,
				loadThrottle: 600,
				load: function (query, callback) {

					return _this.supplier.fetch({
						method: 'POST',
						data: { search: { contains: query } }
					}, true);
				},
				render: 'third'
			}, {
				domElt: _this.$el.find('input[name="expense[currency]"]'),
				create: false,
				defaultValues: currency,
				options: currencies,
				valueField: 'id',
				labelField: 'symbol',
				searchField: ['symbol', 'name'],
				maxItems: 1,
				//					maxOptions		: 1,
				loadThrottle: 600
			}];

			this.initSelectize();
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			var expense = dataForm.expense;
			delete dataForm.expense;
			expense.rows = dataForm;

			this.modelToSave.set(expense);

			var isNew = this.modelToSave.isNew();

			if (this.modelToSave.isValid()) {

				var toastMsg = this.modelToSave.isNew() ? this.toastMessages.created : this.toastMessages.updated;

				this.modelToSave.save().then(function (id) {

					_this.form.trigger('successful', toastMsg);
					_this.form.redirect.call(_this, isNew, URLBuilder(["expense", "overview", _this.modelToSave.get('id')]));
				}, function () {
					// re activate submit button
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
