/**
* @desc Supplier Overview, extend Third View
* Child View : Core Overview
*/

define('views/supplier/overview',['underscore', 'views/third/overview', 'tools/buildURL'], function (_, ThirdOverview, URLBuilder) {

	'use strict';

	return ThirdOverview.extend({

		overview: {},
		linked: 'supplier',
		template: 'templates/supplier/overview.twig',
		// customFields filter
		customFieldFilter: {
			useOn: 'supplier',
			linkedtype: 'supplier'
		}
	});
});
