/**
* @desc Prospect Overview, extend Third View
* Child View : Core Overview
*/

define('views/prospection/prospect/overview',['underscore', 'views/third/overview', 'app', 'tools/buildURL'], function (_, ThirdOverview, App, URLBuilder) {

	'use strict';

	return ThirdOverview.extend({

		overview: {},
		linked: 'prospect',
		template: 'templates/prospect/overview.twig',
		toastMessages: {
			contact: {
				contactUpdated: App.polyglot.t("contactUpdated"),
				contactSaved: App.polyglot.t("contactSaved"),
				contactDeleted: App.polyglot.t("contactDeleted")
			},
			address: {
				addressUpdated: App.polyglot.t("addressUpdated"),
				addressSaved: App.polyglot.t("addressSaved"),
				addressDeleted: App.polyglot.t("addressDeleted")
			},
			third: {
				thirdUpdated: App.polyglot.t('prospectUpdated'),
				thirdSaved: App.polyglot.t("prospectSaved"),
				thirdDeleted: App.polyglot.t("prospectDeleted")
			}
		},

		// customFields filter
		customFieldFilter: {
			useOn: 'prospect',
			linkedtype: 'prospect'
		}

	});
});
