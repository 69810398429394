/**
 * @desc select some datas before goto doc form
 * Child View : Core Form
 */
define('views/document/createDoc',['underscore', 'views/core/page', 'views/core/form', 'models/collections/thirds', 'models/singles/third', 'models/singles/prospect', 'models/collections/rateCategories', 'models/singles/document', 'models/collections/docLayouts', "models/collections/documents", 'models/collections/warehouses', 'app', 'models/collections/prospects', 'models/collections/langs', 'tools/buildURL', "selectize"], function (_, Page, Form, Thirds, Third, Prospect, RateCategories, Document, DocLayouts, Documents, Warehouses, App, Prospects, Langs, URLBuilder) {
	'use strict';

	return Page.extend({

		models: {},
		collections: {},
		options: {
			extraData: {}
		},
		events: _.extend({
			'change select[name="document[doclayout]"]': 'changeLang'
		}, Page.prototype.events),

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, docid, isCopy, thirdid) {

			Page.prototype.initialize.apply(this, arguments);

			switch (doctype) {

				case 'invoice':
					this.title = App.polyglot.t("newInvoice");
					break;

				case 'estimate':
					this.title = App.polyglot.t("newEstimate");
					break;

				case 'order':
					this.title = App.polyglot.t("newOrder");
					break;

				case 'creditnote':
					this.title = App.polyglot.t("newCreditnote");
					break;

				case 'proforma':
					this.title = App.polyglot.t("newProforma");
					break;

				case 'delivery':
					this.title = App.polyglot.t("newDelivery");
					break;

			}

			var _this = this;
			this.subviews = [];
			this.template = "/templates/document/createDoc.twig";
			this.router = router;
			this.classes = "form";
			this.rateCategories = new RateCategories();
			this.modelsDocs = new Documents();
			this.thirdid = thirdid;
			this.isCopy = isCopy && isCopy != 0;
			this.newDoctype = doctype;
			this.modelsDocs.XHRData = {};

			//refresh docPrefs
			Document.getPrefs();

			if (this.newDoctype === 'estimate' && App.permission.level("prospectlevel")) {
				this.prospects = new Prospects();
			}

			if (App.permission.level('clientlevel')) {
				this.thirds = new Thirds();
			} else {
				this.thirds = this.prospects;
			}

			if (this.isCopy) {
				this.docid = docid;
				this.documentSource = new Document({ id: this.docid });
				this.documentSource.mustFetchLocal = true;
				this.models[this.documentSource.name] = this.documentSource;
			} else {

				this.docLayouts = new DocLayouts();
				this.collections[this.docLayouts.name] = this.docLayouts;

				if (App.permission.level('invoicing_modellevel')) {
					this.modelsDocs.XHRData.doctype = "model";
					this.collections[this.modelsDocs.name] = this.modelsDocs;
				}
			}

			if (this.thirdid) {
				this.third = new Third({ id: this.thirdid });
				this.third.bypassNotLoadable = true;
				this.models[this.third.name] = this.third;
			}

			if (App.pluginIsLoaded('stock')) {
				this.warehouses = new Warehouses();
				this.stockIsLoaded = true;

				this.collections[this.warehouses.name] = this.warehouses;
			}

			Document.classNameByDoctype(this.newDoctype).then(_.bind(function (ClassName) {

				_this.modelToSave = new ClassName();

				this.collections[this.rateCategories.name] = this.rateCategories;

				this.langs = new Langs();
				this.collections[this.langs.name] = this.langs;

				this.options.extraData.isCopy = function () {

					return new Promise(function (resolve, reject) {
						resolve(_this.isCopy ? true : false);
					});
				};

				this.form = new Form({ parentView: this });
				this.subviews.push(this.form);

				this.listenTo(this.form, 'dataSend', this.formAction);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			//DOM declarations
			this.$.ratecategory = $('select[name="document[rateCategory]"]', this.$el);
			this.$.updatePrice = $('input[name="model[updatePriceItems]"]', this.$el);

			this.thirds.add(this.third);

			this.selectizeObjects = [{
				domElt: $(".third-selectize", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'name',
				searchField: ['name', 'forename'],
				options: this.thirds.toJSON(),
				maxItems: 1,
				maxOptions: 10,
				placeholder: App.polyglot.t('client'),
				onChange: function (value) {

					if (value) {

						var third = _this.thirds.get(value);
						this.thirdRelationType = third.get('relationType');

						if (third.get('rateCategory') != 0) {
							this.$.ratecategory.val(third.get('rateCategory'));
						}
					}
				},

				loadThrottle: 600,
				load: function (query, callback) {

					return new Promise(function (resolve, reject) {

						if (_this.prospects) {

							var promises = [];

							promises.push(_this.thirds.fetch({

								method: 'POST',
								data: { search: { contains: query } }

							}, true));

							promises.push(_this.prospects.fetch({

								method: 'POST',
								data: { search: { contains: query } }

							}, true));

							Promise.all(promises).then(function () {

								_this.thirds.add(_this.prospects.toJSON());
								resolve(_this.thirds);
							});
						} else {

							_this.thirds.fetch({

								method: 'POST',
								data: { search: { contains: query } }

							}, true).then(function () {
								resolve(_this.thirds);
							});
						}
					});
				},
				render: 'third'
			}, {
				domElt: $(".model-selectize", this.$el),
				create: false,
				placeholder: App.polyglot.t('fromModel'),
				valueField: 'id',
				labelField: 'ident',
				searchField: ['ident'],
				options: this.modelsDocs.toJSON(),
				maxItems: 1,
				maxOptions: 10,
				loadThrottle: 600,
				load: function (query, callback) {

					return _this.modelsDocs.fetch({

						method: 'POST',
						data: { search: { ident: query }, doctype: 'model' }

					}, true);
				},
				render: 'model',
				onChange: function (value) {

					if (value) {

						var docModel = _this.modelsDocs.get(value);

						this.$.ratecategory.val(docModel.get('rateCategory'));
						this.$.updatePrice.attr('data-catchable', 'Y').closest('.form-group').removeClass('hidden');
						this.$.ratecategory.prop('disabled', true);
					} else {
						this.$.updatePrice.attr('data-catchable', 'N').closest('.form-group').addClass('hidden');
						this.$.ratecategory.prop('disabled', false);
					}
				}
			}];

			Page.prototype.afterRender.apply(this, arguments);

			this.initSelectize();

			if (this.thirdid && this.third.isLoadable !== false) {

				var thirdSelectize = $('.third-selectize', this.$el)[0].selectize;
				if (_.isEmpty(thirdSelectize.getOption(this.thirdid))) {
					thirdSelectize.addOption(this.third.toJSON());
				}

				thirdSelectize.setValue(this.third.get('id'));
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		changeLang: function (e) {

			var $target = $(e.currentTarget);
			var doclayout = this.docLayouts.get($target.val());
			var langid = doclayout.get('langid');

			$('select[name="document[docLang]"]').val(langid);
		},

		/**
   * @description fire submit event of form view
   * @returns {undefined}
   */
		fireSubmit: function () {
			this.form.$el.trigger('submit');
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function () {

			var _this = this;
			var dataForm = this.form.dataForm;
			var validationErrors = Document.popupHasErrors(dataForm);

			if (!validationErrors) {

				//if it's a doc copy, we fetch docSource for copy
				if (this.isCopy) {

					this.documentSource.set(dataForm.document);
					this.documentSource.fetch4Copy().then(function () {
						_this.router.navigate(URLBuilder(["document", "form", 'model', dataForm.document['thirdid'], _this.documentSource.get('id'), 'list', _this.newDoctype]), { trigger: true, replace: true });
					});

					return false;
				} else {

					dataForm.document['thirdName'] = this.thirds.get(dataForm.document.thirdid).get('name');
					var rateCategory = _this.rateCategories.get(_this.modelToSave.get("rateCategory"));

					this.modelToSave.set(dataForm.document);
					this.modelToSave.set({ prefs: App.docPrefs });
					this.modelToSave.set({ "thirdRelationType": _this.thirdRelationType });
					$('.modal-content', this.$el).append(_this.formActionSetLoader());

					this.modelToSave.saveLocal().then(function () {

						//fetch and make in temp every data needed for doc form 
						var promises = [];
						if (_this.thirdRelationType == "prospect") {
							var third = new Prospect({ id: dataForm.document['thirdid'] });
						} else {
							var third = new Third({ id: dataForm.document['thirdid'] });
						}

						promises.push(third.fetch());

						if (dataForm.document['modelid']) {
							//if model is provided , we fetch it now, for do a local fetch on docform

							promises.push(new Promise(function (resolve, reject) {

								require(['models/singles/model'], function (Model) {

									var model = new Model({ id: dataForm.document['modelid'] });

									model.fetchForCreate({
										data: { updatePrice: dataForm.model['updatePriceItems'], newDoctype: _this.newDoctype, thirdid: dataForm.document['thirdid'] }
									}).then(function () {

										model.set(dataForm.document);

										if (_this.stockIsLoaded) {

											//set a whid  for every row of type item
											_.each(model.get('rows'), function (row, index) {

												if (row.type === 'item' && row.stockenabled === "Y") {
													var rows = this.get('rows');
													rows[index].whid = _this.warehouses.first().id;
												}
											}, model);
										}

										model.saveLocal().then(resolve);
									});
								});
							}));
						}

						Promise.all(promises).then(function () {

							if (dataForm.document['modelid']) {
								_this.router.navigate(URLBuilder(["document", "form", 'model', third.id, dataForm.document['modelid'], 'list', _this.newDoctype]), { trigger: true, replace: true });
							} else {
								_this.router.navigate(URLBuilder(["document", "form", _this.newDoctype, third.id, 0, 'list']), { trigger: true, replace: true });
							}

							//							_this.router.navigate(URLBuilder(["document","form" , _this.newDoctype, dataForm.document['thirdid'], 0, 'list']), {trigger : true, replace : true});
						});
					});
				}
			} else {

				//Make invalid inputs in error
				_.each(validationErrors, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');
			}
		}

	});
});
