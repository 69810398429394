/**
 * @desc Payment Form, extend ToggleablePage View
 * Child View : Core Overview
 */
define('views/document/paymentForm',['underscore', 'views/core/ToggleablePage', 'models/singles/payment', 'views/core/form', 'tools/buildURL', 'app', 'models/collections/payMediums', 'moment'], function (_, ToggleablePage, Payment, Form, URLBuilder, App, PayMediums, Moment) {

	'use strict';

	return ToggleablePage.extend({

		id: 'paymentForm',
		events: _.extend({}, ToggleablePage.prototype.events),

		actionList: {},
		toastMessage: {
			paymentRecorded: App.polyglot.t('paymentRecorded')
		},

		/**
   * @desc initialize
   */
		initialize: function (router, options) {

			ToggleablePage.prototype.initialize.apply(this, arguments);

			this.document = this.modelsFetched.document;
			this.paymediums = new PayMediums();
			this.collections[this.paymediums.name] = this.paymediums;
			this.form = new Form({ parentView: this, template: 'templates/document/formPayment.twig' });

			this.subviews.push(this.form);
			this.listenTo(this.form, 'dataSend', this.formAction);
		},

		/**
   * 
   * @returns {undefined}
   */
		render: function () {

			this.modelToSave = new Payment({
				sumMax: this.document.get('dueAmount') ? this.document.get('dueAmount') : this.document.get('totalAmount'),
				doctype: this.document.get('doctype'),
				docid: this.document.get('id')
			});
			this.models[this.modelToSave.name] = this.modelToSave;
			ToggleablePage.prototype.render.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			ToggleablePage.prototype.afterRender.apply(this, arguments);
			$('input[name="payment[date]"]').val(Moment().format('YYYY-MM-DD'));
			this.form.delegateEvents();
		},

		/**
   * @desc action when we click on return arrow and when back is pressed for native app
   * @param {type} e
   * @returns {Boolean}
   */
		backHandler: function (e) {
			this.$el.trigger('hide');
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		onShow: function () {
			var _this = this;
			$('#action-list').hide();
			$('.return', '.icon-wrapper-left').off();
			$('.return', '.icon-wrapper-left').one('click', this.backHandler.bind(this));
		},

		/**
   * 
   * @returns {undefined}
   */
		onHide: function () {
			this.renderHeader();
			$('#action-list').show();
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function (context) {

			var _this = this;
			var dataForm = this.form.dataForm;

			dataForm.payment['date'] = Moment(dataForm.payment["date"]).unix();

			this.modelToSave.set(dataForm.payment);

			if (this.modelToSave.isValid()) {

				$('.modal-content', this.$el).append(_this.formActionSetLoader());

				this.modelToSave.save().then(function () {

					_this.$el.trigger('hide');
					_this.form.trigger('successful', _this.toastMessage.paymentRecorded);

					_this.mainView.render();
				});
			} else {

				// re activate submit button
				$('.submit-form', context).prop('disabled', false);

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {
						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}
		}

	});
});
