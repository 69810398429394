/**
 *	@desc Model , extend Core Model 
 */
define('models/singles/supportTemplate',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'supportTemplates',
		name: 'supportTemplate',
		urlRoot: '/rest/support/supportTemplate'

	});
});
