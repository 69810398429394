/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/filters',['models/core/collection', 'models/singles/filter'], function (Collection, Filter) {

	'use strict';

	return Collection.extend({

		/**
   * 
   * @param {type} models
   * @param {type} options
   * @returns {undefined}
   */
		constructor: function (models, options) {

			var _this = this;

			Collection.apply(this, arguments);
			this.offlineQueryArgs = ['listing'];
		},

		model: Filter,
		storeName: 'listingFilter',
		name: 'filters'

	});
});
