/**
 *  @desc tt Collection, extend core Collection
 */
define('models/collections/timetrackings',['models/core/collection', 'models/singles/timetracking'], function (Collection, Timetracking) {

	'use strict';

	return Collection.extend({
		model: Timetracking,
		storeName: 'timetracking',
		name: 'timetrackings',
		url: '/rest/timetracking/list',
		searchFieldName: 'third',
		fields: ['third', 'third_name'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['ownerid', 'ttleLinkedId', "ttleLinkedType"];
			this.orderBy = 'desc';
		}

	});
});
