/**
 * @desc packaging Pref, extend Page View
 */
define('views/setting/packagingPref',['underscore', 'views/core/page', 'views/core/form', 'models/singles/packaging', 'models/collections/packagings', "models/collections/taxes", 'app'], function (_, Page, Form, Packaging, Packagings, Taxes, App) {

	'use strict';

	return Page.extend({

		toastMessages: {
			packaging: {
				removeMsg: App.polyglot.t("packagingRemoved"),
				saveMsg: App.polyglot.t("packagingSaved")
			}
		},
		events: {
			"click .delete-item": "showRemoveConfirm",
			'click .btn-itemLine': "newLine"
		},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/packaging';
			this.collection = new Packagings();
			this.taxes = new Taxes();
			this.collections[this.taxes.name] = this.taxes;
			this.collections[this.collection.name] = this.collection;
			this.title = App.polyglot.t("packagings");
			this.packaging = new Packaging();
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   */
		afterRender: function () {
			Page.prototype.afterRender.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		showRemoveConfirm: function (e) {

			var $line = $(e.currentTarget).closest(".item-line");
			var $id = $line.find('.item-value').data('id');

			//if it's a new line just delete the line else show confirm for delete line on server too 
			if (_.isUndefined($id)) {
				$line.remove();
			} else {

				var callback = function () {

					$line.remove();
					$('.inprogress-mask').remove();
				}; // executed after suppression

				this.packaging = this.collection.get($id);

				this.removeConfirm(this.packaging, this.packaging.get('name'), $line, this.toastMessages.packaging.removeMsg, callback);
			}

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		newLine: function () {

			//clone a payMedium line
			var $line = $("#new-item-line").clone();
			var $inputVal = $('.item-value', $line);
			var $taxes = $('.item-tax', $line);
			var $qty = $('.item-qty	', $line);
			var $unitAmount = $('.item-unitAmount', $line);
			var $isTaxFree = $('.item-isTaxFree', $line);

			//index of new line
			var index = $(".item-line", "#itemInfos").length - 1; // -1 for hidden line
			//inputs name
			$inputVal.prop('name', index + "[name]");
			$taxes.prop('name', index + "[taxid]");
			$qty.prop('name', index + "[qt]");
			$unitAmount.prop('name', index + "[unitAmount]");
			$isTaxFree.prop('name', index + "[unitAmountIsTaxesFree]").attr('id', index + "[unitAmountIsTaxesFree]").closest('label').attr('for', index + "[unitAmountIsTaxesFree]");;

			//remove data-uncatchable
			$('input, select', $line).removeAttr('data-catchable');

			//insert new line in DOM
			$("#itemInfos").append($line);

			//show the line
			$line.removeAttr('id');
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var collection2Save = new Packagings();

			_.each(dataForm, function (medium, index) {
				collection2Save.add(medium);
			});

			if (collection2Save.length > 0) {

				//loader animation
				this.$el.append(_this.formActionSetLoader());

				collection2Save.save().then(function () {

					collection2Save.fetch({
						method: 'POST'
					}).then(function () {

						//end animation + toastNotif success
						$(".inprogress-mask", _this.$el).remove();
						_this.form.trigger('successful', _this.toastMessages.packaging.saveMsg);
						_this.goToPreviousPage();
					});
				});
			} else {
				_this.goToPreviousPage();
			}

			return false;
		}

	});
});
