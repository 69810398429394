/**
 * @desc Document List, extend Page View
 * Child View : Core Listing
 */
define('views/document/list',['underscore', 'views/core/pageList', 'models/collections/documents', 'views/core/listing', 'tools/buildURL', 'app', 'views/document/createDoc', 'views/core/toastNotification'], function (_, PageList, Documents, Listing, URLBuilder, App, CreateDoc, ToastNotification) {

	'use strict';

	return PageList.extend({

		addAction: function () {
			this.listing.goToForm(URLBuilder(['document', 'createDoc', this.doctype]));
		},
		title: "",
		listing: {},
		previousLink: false,
		toastMessages: {
			document: {
				documentRemoved: App.polyglot.t('documentRemoved')
			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, links) {

			this.availableActions = links;
			this.name = doctype;

			if (doctype === 'estimate') {
				this.canAdd = App.permission.can("invoicing_create" + doctype) && (App.permission.level('clientlevel') || App.permission.level('prospectlevel'));
			} else {
				this.canAdd = App.permission.can("invoicing_create" + doctype) && App.permission.level('clientlevel');
			}

			this.canDelete = App.permission.can("invoicing_delete" + doctype);
			this.canEdit = App.permission.can("invoicing_edit" + doctype);

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.template = "templates/document/list.twig";

				this.var4template('doctype', doctype);

				Documents.classNameByDoctype(doctype).then(_.bind(function (ClassName) {

					this.placeholderSearchBar = App.polyglot.t("documentNumber");
					this.doctype = doctype;
					this.subviews = [];
					this.router = router;
					this.collection = new ClassName();
					this.collection.XHRData = {
						doctype: doctype
					};

					this.collections[this.collection.name] = this.collection;

					this.title = App.polyglot.t(doctype === 'delivery' ? 'deliveries' : doctype + 's');

					this.listing = new Listing({ parentView: this });
					this.subviews.push(this.listing);
					this.render();
				}, this));
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canEdit || this.canDelete) {

				//init material effect on each row of listing
				this.listing.initSwipeEffect({

					removeMsg: this.toastMessages.document.documentRemoved,
					onModify: function (model, $row) {

						if (model.isEditable()) {
							this.router.navigate(URLBuilder(["document", "form", this.collection.XHRData.doctype, model.get('thirdid'), model.id, 'list']), { trigger: true });
						} else {

							new ToastNotification({
								id: 'toast-error',
								classes: 'col-xs-12',
								timeDuration: 2500,
								content: App.polyglot.t('docStatusCannotModify')
							});
						}
					},
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					isDeleteAvailable: function (model) {

						if (model.isEditable(model)) {
							return true;
						} else {

							new ToastNotification({
								id: 'toast-error',
								classes: 'col-xs-12',
								timeDuration: 2500,
								content: App.polyglot.t('docStatusCannotDelete')
							});

							return false;
						}
					},
					popupid: 'doc-option',
					modelName: "ident",
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					}
				}, '', this);
			}
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		goToDocForm: function (modelid, thirdid) {

			if (modelid) {
				this.router.navigate(URLBuilder(["document", "form", 'model', thirdid, modelid, 'list', this.collection.XHRData.doctype]), { trigger: true });
			} else {
				this.router.navigate(URLBuilder(["document", "form", this.collection.XHRData.doctype, thirdid, 0, 'list']), { trigger: true });
			}
		}

	});
});
