/**
 * @desc People router, extend Core Router
 */
define('routers/expense',['backbone', 'underscore', 'routers/core/router', 'app'], function (Backbone, _, CoreRouter, App) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "expense",
		plugin: 'expenses',

		checkOnline: {
			form: true
		},

		routes: {
			'expense/list': 'listing',
			'expense/form(/)(:id)': 'form',
			'expense/overview/:id': 'overview'
		},

		/**
   * 
   */
		checkBefore: {

			form: function () {
				return App.permission.can('expenses_createexpense');
			}

		},

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		listing: function () {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/expense/list'], function (require) {

					var ExpenseView = require('views/expense/list');
					var view = new ExpenseView(_this);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		form: function (id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/expense/form'], function (require) {

					id = id ? id : 0;

					var ExpenseView = require('views/expense/form');
					var view = new ExpenseView(_this, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} id
   * @returns {undefined}
   */
		overview: function (id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/expense/overview'], function (require) {

					var ExpenseView = require('views/expense/overview');
					var view = new ExpenseView(_this, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		}

	});
});
