/**
 *	@desc DocModel , extend Document Model
 */
define('models/singles/model',['models/singles/document', 'app', 'tools/ajax'], function (Document, App, Ajax) {

	'use strict';

	return Document.extend({

		defaults: _.extend({
			id: 0,
			doctype: 'model',
			ident: '',
			thirdName: '',
			formattedDocType: 'modèle'
		}, Document.prototype.defaults),

		storeName: 'document',
		name: 'model',
		urlRoot: '/rest/document/model',

		fetchForCreate: function (options) {

			var _this = this;
			var xhr = Ajax({
				url: this.urlRoot + '/forCreate/' + this.id,
				data: options.data
			});

			return new Promise(function (resolve, reject) {

				xhr.fail(function () {

					if (App.indexedDB) {
						_this.fetchLocal().then(resolve, reject);
					}
				});

				xhr.done(function (data) {

					_this.set(data);

					if (App.indexedDB) {

						_this.saveLocal().then(function () {
							resolve(data);
						});
					} else {
						resolve();
					}
				});
			});
		}

	});
});
