/**
 *  @desc Payments Collection, extend core Collection
 */
define('models/collections/payments',['models/core/collection', 'models/singles/payment'], function (Collection, Payment) {

	'use strict';

	return Collection.extend({

		model: Payment,
		storeName: 'payment',
		name: 'payments',
		url: '/rest/payment/list'

	});
});
