/**
 * @description FilterPanel View
 * @extend Page View
 * @childView : core Form
 */
define('views/filterPanels/filterPanel',['underscore', 'views/core/form', 'views/core/page', 'app', 'tools/core/utils', 'models/collections/filters', 'views/widgets/tabs', 'models/collections/savedSearches'], function (_, Form, Page, App, Utils, ListingFilters, Tabs, SavedSearches) {

	'use strict';

	return Page.extend({

		template: '/templates/widgets/filterPanel.twig',
		selectizeObjects: {},
		id: 'filterPanel',

		events: _.extend({
			'click .btn-cancel': 'resetForm'
		}),

		hideSavedSearch: false,

		/**
   * @desc initialize
   */
		initialize: function (router, listName, linkedtypeForSavedSearch) {

			Page.prototype.initialize.apply(this, arguments);

			this.listName = listName;
			this.listingFiltersCollection = new ListingFilters(); //take filters values
			this.listingFiltersCollection.mustFetchLocal = true;
			this.listingFiltersCollection.XHRData = {
				search: {
					listing: listName
				}
			};

			this.collections[this.listingFiltersCollection.name] = this.listingFiltersCollection;

			this.var4template('filters', this.buildFilters());
			this.var4template('hideSavedSearch', this.hideSavedSearch);

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			if (App.isOnline && !this.hideSavedSearch) {

				this.savedSearches = new SavedSearches();
				this.savedSearches.XHRData = {
					search: {
						linkedTypes: linkedtypeForSavedSearch ? [linkedtypeForSavedSearch] : [listName.split("_")[0]]
					}
				};

				this.collections[this.savedSearches.name] = this.savedSearches;

				this.tabs = new Tabs('#filters-header', [{
					label: App.polyglot.t("filters"),
					for: 'filters-tab',
					active: true
				}, {
					label: App.polyglot.t("savedSearches"),
					for: 'savedSearches-tab'
				}]);

				this.subviews.push(this.tabs);
			}

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   *
   * @returns {undefined}
   */
		afterRender: function () {

			var promises = [];
			var _this = this;

			Page.prototype.afterRender.apply(_this, arguments);

			if (App.isOnline && !this.hideSavedSearch) {
				this.tabs.insertInDom();

				var savedSearchFilter = this.listingFiltersCollection.get(this.listName + "_" + "savedSearchId");

				//focus on right tab
				if (savedSearchFilter && savedSearchFilter.get('value')) {
					$('a[href="#savedSearches-tab"]').trigger('click');
				}
			}

			$.each(_this.filters, function (key, elem) {

				//take saved filter value
				var filterId = _this.listName + '_' + elem.field['name'];
				var filterModel = _this.listingFiltersCollection.get(filterId);
				var selectize = elem.field.selectize;
				var $filter = $('[name="filter[' + elem.field.name + ']"]');

				if (selectize && selectize.domElt) {

					//set saved values for filter in DOM
					if (filterModel && filterModel.get('value')) {

						var defaultValues = _.isArray(filterModel.get('value')) ? filterModel.get('value') : ("" + filterModel.get('value')).split(",");
						selectize.defaultValues = defaultValues;

						//we need to take value in local DB before apply filter
						switch (elem.field['name']) {

							case 'clients':
							case 'thirds':

								promises.push(new Promise(function (resolve, reject) {

									require(['models/singles/third'], function (Third) {

										var third = new Third({ id: filterModel.get('value') });

										third.fetchLocal().then(function () {
											selectize.options = [third.toJSON()];
											resolve();
										});
									});
								}));

								break;

						}
					}

					selectize.domElt = _this.$el.find(selectize.domElt);
					_this.selectizeObjects[key] = selectize;
				} else {

					//set saved value for filter in DOM
					if (filterModel) {
						$filter.val(filterModel.get('value'));
					}
				}

				if (elem.onChange) {

					$filter.on('change', elem.onChange);

					if (filterModel && filterModel.get('value')) {
						elem.onChange($filter);
					}
				}

				if (filterModel && filterModel.get('value')) {
					$filter.closest('.sb-row').find('.sb-label').trigger('click');
				}
			});

			//check if we use savedSearch
			var filterId = _this.listName + '_savedSearchId';
			var filterModel = _this.listingFiltersCollection.get(filterId);

			if (filterModel && filterModel.get('value')) {
				$('input[value="' + filterModel.get('value') + '"]').prop('checked', true);
			}

			Promise.all(promises).then(function () {
				_this.initSelectize();
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		resetForm: function () {

			// reset select
			this.$('select').each(function (key, elem) {
				$(this).find("option:selected").removeAttr("selected");
				$(this).find('option:first-child').attr("selected", "selected");
			});

			// reset input
			this.$('input').val('');

			//reset selectize
			$('.selectized').each(function () {
				$(this)[0].selectize.clear({ silent: true });
			});
		},

		/**
   * @returns {undefined}
   */
		formAction: function (e) {

			var _this = this;
			var inputs = this.form.sendDataFormV2();

			var listingSearch = {};
			var filtersArray = [];

			if ($('a[href="#savedSearches-tab"]', '.active').length) {
				//saved search

				inputs = {
					filter: {
						savedSearchId: inputs['filter']['savedSearchId']
					}
				};
			} else {
				//filters
				inputs['filter']['savedSearchId'] = '';
			}

			//keep just filters value or saved search id depending of active tab

			_.each(inputs, function (input) {

				_.each(input, function (val, key) {

					var isMultiple = _this.$('[name="filter[' + key + ']"]').data("multiple") === 'Y';

					if (val && isMultiple) {
						val = val.split(',');
					}

					var hasOfflinesearchfield = _this.$('[name="filter[' + key + ']"]').data("offlinesearchfield");
					filtersArray.push({ name: key, value: val, listing: _this.listName, isMultiple: isMultiple, offlineSearchField: hasOfflinesearchfield });
				});
			});

			this.listingFiltersCollection.set(filtersArray);
			this.listingFiltersCollection.saveLocal().then(_.bind(function () {

				//we delete listing of cache for take new data with new filters
				var previousRoute = this.router.history[0];
				delete App.cacheControl[previousRoute];
				_this.goToPreviousPage();
				return false;
			}, this));
		}

	});
});
