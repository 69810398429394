/**
 *	@desc Shipping Model , extend Core Model 
 */
define('models/singles/smarttag',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			linkedid: '',
			linkedtype: ''
		},

		storeName: 'smarttags',
		name: 'smarttag',
		urlRoot: '/rest/smarttags'

	});
});
