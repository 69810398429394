/**
 *  @desc Funnels Collection, extend core Collection
 */
define('models/collections/funnels',['models/core/collection', 'models/singles/funnel'], function (Collection, Funnel) {

	'use strict';

	return Collection.extend({

		model: Funnel,
		storeName: 'funnels',
		name: 'funnels',
		url: '/rest/opportunity/getFunnels'

	});
});
