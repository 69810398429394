define('tools/materialEffect',[], function () {

	'use strict';

	return function ($target, callback) {

		callback = !_.isUndefined(callback) ? callback : function () {};

		var d, x, y;

		var applyEffect = function (e) {

			var ink;

			$target.css({ overflow: "hidden", position: 'relative' });

			//create .ink element if it doesn't exist
			if ($target.find(".ink").length === 0) {
				$target.prepend("<span class='ink'></span>");
			}

			ink = $target.find(".ink");
			//incase of quick double clicks stop the previous animation
			ink.removeClass("animate");

			//set size of .ink
			if (!ink.height() && !ink.width()) {
				//use $target's width or height whichever is larger for the diameter to make a circle which can cover the entire element.
				d = $target.outerWidth();

				ink.css({ height: d, width: d });
			}

			//get click coordinates
			//logic = click coordinates relative to page - $target's position relative to page - half of self height/width to make it controllable from the center;
			x = e.pageX - $target.offset().left - ink.width() / 2;
			y = e.pageY - $target.offset().top - ink.height() / 2;

			//set the position and add class .animate
			ink.css({ top: y + 'px', left: x + 'px' });

			return ink;
		};

		$target.hammer().off('press');
		//on press do an action after effect
		$target.hammer().on('press', function (e) {

			if (!$(e.currentTarget).hasClass('disabledLongPress')) {
				applyEffect(e).addClass("animate-forwards").on('animationend webkitAnimationEnd', callback.bind(this, $(e.currentTarget)));
			}
		});
		//		
		//		$target.hammer().on('pressup', function(e) {
		//			e.gesture.srcEvent.preventDefault();	
		//			e.gesture.srcEvent.stopPropagation();	
		//			e.gesture.srcEvent.stopImmediatePropagation();	
		//			return false;
		//		});

		$target.hammer().off('tap');

		//on tap just do an effect
		$target.hammer().on('tap', function (e) {
			applyEffect(e).addClass('animate');
		});

		$target.data('hammer').get('press').set({ time: 600, threshold: 20 });
	};
});
