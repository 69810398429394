/**
 *	@desc Warehouse Model , extend Core Model 
 */
define('models/singles/timetracking',['models/core/model', 'tools/core/utils', 'app', 'moment'], function (Model, Utils, App, Moment) {

	'use strict';

	return Model.extend({

		defaults: {
			id: '0'
		},

		storeName: 'timetracking',
		name: 'timetracking',
		urlRoot: '/rest/timetracking/',

		/**
   * 
   * @param {type} attributes
   * @returns {timetracking_L9.timetrackingAnonym$1.validate.validationErrors}
   */
		validate: function (attributes) {

			var required = {
				'createdDate': true,
				'time': true,
				'service': true,
				'third': false,
				'doc': false,
				'note': false,
				'isBillable': 'N'
			};

			var validationErrors = { timetracking: {} };

			if (required.date && !attributes.date) {
				validationErrors['timetracking']['createdDate'] = this.computeError({ name: 'createdDate', type: 'required' });
			} else {
				this.attributes.datetotimestamp = Utils.SellsyMoment(attributes.createdDate, App.HTMLDateFormat, true).unix();
			}

			if (required.third && !attributes.third) {
				validationErrors['timetracking']['third'] = this.computeError({ name: 'third', type: 'required' });
			}

			if (required.doc && !attributes.doc) {
				validationErrors['timetracking']['doc'] = this.computeError({ name: 'doc', type: 'required' });
			}

			if (required.service && !attributes.service) {
				validationErrors['timetracking']['service'] = this.computeError({ name: 'service', type: 'required' });
			}

			if (required.time && !attributes.time) {
				validationErrors['timetracking']['time'] = this.computeError({ name: 'time', type: 'required' });
			} else {

				if (App.preferences.timetracking.usePrecisionSecond == 'Y') {
					this.attributes.timeToSecond = Moment.duration(attributes.time).asSeconds();
				} else {
					this.attributes.timeToMinute = Moment.duration(attributes.time).asMinutes();
				}
			}

			if (required.note && !attributes.note) {
				validationErrors['timetracking']['note'] = this.computeError({ name: 'note', type: 'required' });
			}

			if (_.toArray(validationErrors.timetracking).length > 0) {
				return validationErrors;
			}
		}

	});
});
