/**
 * @desc, extend Page View
 */
define('views/setting/rateCategoriesPref',['underscore', 'views/core/page', 'views/core/view', 'models/collections/rateCategories', 'app'], function (_, Page, View, RateCategories, App) {

	'use strict';

	return Page.extend({

		className: 'page',
		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/rateCategories';
			this.rateCategories = new RateCategories();
			this.collections[this.rateCategories.name] = this.rateCategories;
			this.title = App.polyglot.t('ratecategories');
			this.render();
		},

		/**
   * 
   */
		render: function () {
			View.prototype.render.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		renderCallback: function () {
			Page.prototype.afterRender.apply(this, arguments);
		}

	});
});
