/**
 * @desc bank account Pref, extend Page View
 */
define('views/setting/bankAccountPref',['underscore', 'views/core/page', 'views/core/form', 'models/collections/bankAccounts', 'models/collections/accountings', 'app'], function (_, Page, Form, BankAccounts, Accountings, App) {

	'use strict';

	return Page.extend({

		toastMessages: {
			bankAccount: {
				removeMsg: App.polyglot.t("bankAccountSaved"),
				saveMsg: App.polyglot.t("bankAccountUpdated")
			}
		},

		events: {
			"click .delete-item": "showRemoveConfirm",
			'click .addline': "newLine",
			'change input[type="checkbox"].hasIban': "toggleIbanPart",
			'submit form': 'formAction'
		},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.title = App.polyglot.t("bankAccounts");
			this.router = router;
			this.template = 'templates/setting/bankAccount';

			if (App.pluginIsLoaded('accounting')) {
				this.accountings = new Accountings();
				this.collections[this.accountings.name] = this.accountings;
				this.accountings.XHRData = {
					'search': {
						view: 'bank'
					}
				};
			}

			this.collection = new BankAccounts();
			this.collections[this.collection.name] = this.collection;

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);
			$('input[type="checkbox"].hasIban', this.$el).trigger('change');

			//DOM declaration
			this.$row4Clone = $('#new-bank-row', this.$el).clone();
			this.$.bankAccounts = $('#bank-accounts', this.$el);
			this.currentRowIndex = $('.bank-row', this.$el).length - 1;

			$('#new-bank-row', this.$el).remove();
		},

		/**
   * 
   * @returns {undefined}
   */
		showRemoveConfirm: function (e) {

			console.log('paaassee');

			var $line = $(e.currentTarget).closest(".bank-row");
			var $id = $line.find($('input[name$="[id]"]')).val();

			//if it's a new line just delete the line else show confirm for delete line on server too 
			if (_.isUndefined($id)) {
				$line.remove();
			} else {

				var callback = this.form.render.bind(this.form); // executed after suppression
				this.bankAccount = this.collection.get($id);

				this.removeConfirm(this.bankAccount, this.bankAccount.get('label'), $line, this.toastMessages.bankAccount.removeMsg, callback);
			}

			return false;
		},

		/**
   * 
   * @param {Event} e
   * @returns {undefined}
   */
		toggleIbanPart: function (e) {

			var $currentTarget = $(e.currentTarget);

			if ($currentTarget.prop('checked')) {

				$currentTarget.closest('.box-shadow').find('.hasIbanPart').show();
				$currentTarget.closest('.box-shadow').find('.withoutIbanPart').hide();
			} else {

				$currentTarget.closest('.box-shadow').find('.hasIbanPart').hide();
				$currentTarget.closest('.box-shadow').find('.withoutIbanPart').show();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		newLine: function () {

			if ($('.emptyListing').length) {
				$('.emptyListing ').remove();
			}

			var $newRow = this.$row4Clone.clone();

			$newRow.removeAttr('id');

			var htmlNewRow = $newRow.wrap("</p>").parent().html();
			htmlNewRow = htmlNewRow.replace(/((?:name|for|id)(?:\s)*=(?:\s)*(?:'|")[a-zA-Z0-9_]*\[)i(\]\[[a-zA-Z0-9_]*\])/g, "$1" + this.currentRowIndex + "$2");

			this.$.bankAccounts.append(htmlNewRow);
			this.currentRowIndex++;
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.sendDataFormV2();
			var _this = this;
			var collection2Save = new BankAccounts(dataForm.bankAccounts);

			if (collection2Save.length > 0) {

				//validate bankaccounts
				var areValid = true;

				collection2Save.each(function (bankAccount, index) {

					if (!bankAccount.isValid()) {

						areValid = false;

						_.each(bankAccount.validationError, function (error, keyError, arrayError) {

							if (!_.isEmpty(error)) {

								_.each(error, function (msgError, keyMsg) {
									_this.form.showValidationError(keyError, [index, keyMsg], msgError, true);
								});
							}
						});
					}
				});

				if (areValid) {

					//loader animation
					this.$el.append(_this.formActionSetLoader());

					collection2Save.save().then(function () {

						collection2Save.fetch({
							method: 'POST'
						}).then(function () {

							//end animation + toastNotif success
							$(".inprogress-mask", _this.$el).remove();
							_this.form.trigger('successful', _this.toastMessages.bankAccount.saveMsg);
							_this.goToPreviousPage();;
						});
					});
				} else {
					$('.submit-form', this.form.$el).removeAttr('disabled');
				}
			} else {
				_this.goToPreviousPage();
			}

			return false;
		}

	});
});
