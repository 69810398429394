/**
 * @desc Prospection router, extend Core Router
 */
define('routers/prospection',['backbone', 'underscore', 'routers/core/router', 'app', 'tools/buildURL', 'tools/core/utils'], function (Backbone, _, CoreRouter, App, URLBuilder, Utils) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "opportunities",
		plugin: "prospection",

		checkOnline: {
			form: true,
			opportunityForm: true
		},

		routes: {
			'opportunity/list/:funnelid(/)(:thirdid)': 'opportunityList',
			'opportunity/form(/:id)': 'opportunityForm',
			'opportunity/overview(/:id)': 'opportunityOverview',
			'opportunity/home(/)(:thirdid)': 'opportunityHome'
		},

		/**
   *
   */
		checkBefore: {

			form: function () {
				return App.permission.canWriteProspects();
			},

			opportunityForm: function () {
				return App.permission.can('prospection_createopportunity') || App.permission.can('prospection_editopportunity');
			}

		},

		/**
   *
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   *
   */
		opportunityList: function (funnelid, thirdid) {

			thirdid = thirdid ? thirdid : '';
			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = 'opportunities';

			return new Promise(function (resolve, reject) {

				require(['require', 'views/prospection/opportunity/list', 'models/collections/funnels'], function (require, OpportunityList, Funnels) {

					var funnels = new Funnels();

					funnels.fetchLocal({ getAll: true }).then(function () {

						var links = _this.getAvailableActions(funnels, thirdid);
						var view = new OpportunityList(_this, funnelid, thirdid, links);
						view.trueRoute = trueRoute;

						resolve(view);
					}, function () {
						_this.navigate(URLBuilder(['oppotunity', 'home', thirdid]), { trigger: true });
					});
				});
			});
		},

		/**
   *
   * @param {type} id
   * @returns {undefined}
   */
		opportunityOverview: function (id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = 'opportunities';

			return new Promise(function (resolve, reject) {

				require(['require', 'views/prospection/opportunity/overview'], function (require, OpportunityOverview) {

					var view = new OpportunityOverview(_this, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   *
   * @param {type} id
   * @returns {undefined}
   */
		opportunityForm: function (id) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = 'opportunities';

			return new Promise(function (resolve, reject) {

				require(['require', 'views/prospection/opportunity/form'], function (require, OpportunityForm) {

					var view = new OpportunityForm(_this, id);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   *
   * @param {type} thirdid
   * @returns {Promise}
   */
		opportunityHome: function (thirdid) {

			thirdid = thirdid ? thirdid : '';

			var _this = this;
			var trueRoute = Backbone.history.location.hash;
			this.sidelink = 'opportunities';

			return new Promise(function (resolve, reject) {

				require(['require', 'views/core/homeModule', 'models/collections/funnels'], function (require, HomeModule, Funnels) {

					var funnels = new Funnels();

					funnels.clearLocal().then(function () {

						funnels.fetch({ method: 'post' }).then(function () {

							if (funnels.length == 1) {
								_this.navigate(URLBuilder(["opportunity", "list", funnels.first().id, thirdid]), { trigger: true, replace: true });
							} else {

								var links = _this.getAvailableActions(funnels, thirdid);
								var view = new HomeModule(_this, links, App.polyglot.t('opportunity') + " - " + Utils.capitalizeFirstLetter(App.polyglot.t('pipelines')));
								view.trueRoute = trueRoute;

								resolve(view);
							}
						});
					});
				});
			});
		},

		/**
   *
   * @returns {undefined}
   */
		getAvailableActions: function (funnels, thirdid) {

			var links = [];
			funnels.each(function (funnel) {

				links.push({
					fa: 'fa-tachometer',
					name: funnel.get('name'),
					link: URLBuilder(['opportunity', 'list', funnel.id, thirdid]),
					canAccess: App.permission.can("prospection_access")
				});
			});

			return links;
		}

	});
});
