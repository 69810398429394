/**
 *  @desc Creditnotes Collection, extend core Collection
 */
define('models/collections/creditnotes',['models/collections/documents', 'models/singles/creditnote'], function (Documents, Creditnote) {

	'use strict';

	return Documents.extend({

		model: Creditnote,
		storeName: 'document',
		url: '/rest/document/list',
		doctype: 'creditnote',
		availableSteps: ['draft', 'stored', 'partialspend', 'spent', 'cancelled']

	});
});
