/**
 * @desc Product Overview, extend PageOverview View
 * Child View : Core Overview
 */
define('views/product/overview',['underscore', 'views/core/pageOverview', 'models/singles/item', 'models/singles/service', 'views/core/overview', 'views/product/popupRateCategories', 'views/product/popupActivateDecli', 'views/product/popupCreateDecli', 'models/collections/variationFields', 'tools/buildURL', 'app', 'models/collections/smarttags', 'tools/core/utils', 'views/widgets/tabs', 'views/product/stockInfos', 'selectize'], function (_, PageOverview, Item, Service, Overview, popupRateCategories, popupActivateDecli, popupCreateDecli, variationFields, URLBuilder, App, Smarttags, Utils, Tabs, StockView) {

	'use strict';

	return PageOverview.extend({

		events: _.extend({
			'click .btn-editRate': 'editRateCategories',
			'click .btn-activatedecli': 'activateDeclination',
			'click .delete-variation.delete-item': 'deleteVariation',
			'click .btn-create-variation': 'createVariation'

		}, PageOverview.prototype.events),

		title: "",
		popupConfirmOptions: {},
		listing: {},
		filter: {},
		toastMessages: {
			product: {
				productDeleted: App.polyglot.t('itemdeleted'),
				variationDeleted: App.polyglot.t('decldeleted')
			}
		},

		actionList: {

			modify: {
				fa: false,
				label: App.polyglot.t("update"),
				link: function () {
					if (App.isOnline) {
						this.goToForm();
					} else {
						this.notifWriteDisabled();
					}
				}
			},
			stock: {
				fa: false,
				label: App.polyglot.t("stocks"),
				link: function () {
					this.stocks.$el.trigger('show');
				}
			}

		},

		/**
   * @desc initialize
   */
		initialize: function (router, type, id, declid) {

			this.subviews = [];

			if (type === "item") {
				this.product = this.model = new Item({ id: id, declid: declid });
			} else {
				this.product = this.model = new Service({ id: id, declid: declid });
			}

			this.canDelete = App.permission.can('deleteitem');
			this.canEdit = App.permission.can('edititem');

			this.required4Accordion = {
				'declinations-container': type === 'item',
				'stock-container': App.pluginIsLoaded('stock') ? 'Y' : 'N'
			};

			// customFields filter
			this.customFieldFilter = {
				useOn: type,
				linkedtype: type,
				linkedid: id
			};

			// smartTags filter
			this.smartTagsFilter = Smarttags.buildFilterByLinked(type, id);

			PageOverview.prototype.initialize.apply(this, arguments);

			this.router = router;

			this.models[this.product.name] = this.product;
			this.template = "templates/product/overview.twig";
			this.overview = new Overview({ parentView: this }, this.router);
			this.tabs = new Tabs('#product-header');
			this.stocks = new StockView(router, {
				modelsFetched: { "product": this.product },
				mainView: this
			});

			this.subviews.push(this.overview);
			this.subviews.push(this.tabs);
			this.toggleableViews.push(this.stocks);

			var _this = this;

			this.shortenedPanel = {
				relatedType: this.product.get('type'),
				relatedId: this.product.get('id'),
				name: 'name',
				model: this.product,
				actions: {
					event: {
						//for service : for api we sand item everytime . For indexedDB service/item
						url: function () {
							return URLBuilder(['event', 'home', App.isOnline ? 'item' : _this.product.get('type'), _this.product.get('id')]);
						}
					},
					email: {
						display: false
					}
				}
			};

			this.buildShortenedPanel();
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			PageOverview.prototype.afterRender.apply(this, arguments);

			if (!_.isEmpty(this.product.get('tradename'))) {
				var name = Utils.slug(this.product.get('tradename'));
			} else {
				var name = Utils.slug(this.product.get('name'));
			}
		},

		/**
   * 
   * @returns {Boolean}
   */
		goToForm: function () {
			this.router.navigate(URLBuilder(["product", "form", this.product.get('type'), this.product.get('id'), this.product.get('declid')]), { trigger: true, replace: true });
			return false;
		},

		/**
   * Confirmation popup before remove third
   * @returns {Boolean}
   */
		showPopupConfirm: function () {

			var callback = _.bind(function () {
				this.router.navigate(URLBuilder(["product", "list", this.product.get('type')]), { trigger: true });
			}, this);

			this.removeConfirm(this.product, this.product.get('name'), this.$el, this.toastMessages.product.productDeleted, callback);
			return false;
		},

		/**
   * 
   */
		editRateCategories: function () {

			var _this = this;

			new popupRateCategories({
				id: 'edit-rates',
				title: App.polyglot.t('editRates'),
				contentTemplate: "/templates/product/popupRateCategories.twig",
				parentView: _this,
				models: _this.product.toJSON(),
				callback: function () {
					//remove material effect
					$('.ink').remove();
				}
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		activateDeclination: function () {

			var _this = this;
			var varf = new variationFields();

			new popupActivateDecli({
				id: 'activate-declination',
				title: App.polyglot.t('activateDeclination'),
				contentTemplate: "/templates/product/popupActivateDeclination.twig",
				parentView: _this,
				collections: {
					'variationfield': varf
				},
				callback: function () {
					//remove material effect
					$('.ink').remove();
				}
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		deleteVariation: function (e) {

			var _this = this;
			var line = $(e.currentTarget).closest(".variations-list");

			var type = line.data('type');
			var id = line.data('id');
			var declid = line.data('declid');

			if (type === "item") {
				var todelete = new Item({ id: id, declid: declid });
			} else {
				var todelete = new Service({ id: id, declid: declid });
			}

			var callback = function () {
				line.remove();
			};

			this.removeConfirm(todelete, App.polyglot.t('deleteDeclinationConfirm'), line, _this.toastMessages.product.variationDeleted, callback);

			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		createVariation: function () {

			var _this = this;
			var varf = new variationFields();

			new popupCreateDecli({
				id: 'create-declination',
				title: App.polyglot.t('createDeclination'),
				contentTemplate: "/templates/product/popupCreateDeclination.twig",
				parentView: _this,
				collections: {
					variationfield: varf
				},
				models: {
					product: _this.product.toJSON()
				},
				callback: function () {
					//remove material effect
					$('.ink').remove();
				}
			});
		}

	});
});
