/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/rateCategories',['models/core/collection', 'models/singles/rateCategory'], function (Collection, RateCategory) {

	'use strict';

	return Collection.extend({

		model: RateCategory,
		storeName: 'ratecategory',
		name: 'ratecategories',
		url: '/rest/ratecategory/list'

	});
});
