/**
 *  @desc Peoples  Collection, extend core Collection
 */
define('models/collections/peoples',['models/core/collection', 'models/singles/people'], function (Collection, People) {

	'use strict';

	return Collection.extend({

		model: People,
		storeName: 'contact',
		name: 'peoples',
		listName: 'linkeds',
		url: '/rest/people/list',
		fields: ['name', 'forename', 'email', 'tel', 'mobile'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			var _this = this;

			Collection.apply(this, arguments);

			this.orderBy = 'asc';
		}

	});
});
