/**
 *  @desc Events Collection, extend core Collection
 */
define('models/collections/events',['models/core/collection', 'models/singles/event', 'db/easy', 'moment', 'tools/core/utils'], function (Collection, Event, DB, Moment, Utils) {

	'use strict';

	/**
  * 
  * @type @exp;Collection@call;extend
  */

	var Events = Collection.extend({

		model: Event,
		storeName: 'agenda',
		name: 'agenda',
		url: '/rest/agenda/list/event',
		pagination: { nbperpage: 50 },
		comparator: "timestampStart",

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			var _this = this;

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['relationType', 'type', 'labelid'];
			this.orderBy = 'asc';

			this.XHRData = {
				search: {
					relationType: _this.relationType
				}
			};
		},

		/**
  *
   * @param value
   * @param options
   * @returns {*}
   */
		offlineQuery: function (value, options) {

			var finded = Collection.prototype.offlineQuery.apply(this, arguments);

			//don't return parent recurringEvent
			if (value.recurring === "Y" && !value.originalId) {
				return false;
			}

			//dont return recurring occurrence whith timestampStart > recurring_end
			if (value.recurring === 'Y' && value.recurring_end != "0" && value.timestampStart > value.recurring_end) {
				return false;
			}

			if (finded) {

				if (this.XHRData.search['staffs']) {

					var staffsIds = this.XHRData.search['staffs'];

					_.each(staffsIds, function (id, index) {
						staffsIds[index] = ("" + id).split('_')[0];
					});

					var filterByStaff = false;

					if (value.staffs) {

						_.each(value.staffs, function (staff) {
							if (Utils.inArray(staff.id, staffsIds)) {
								filterByStaff = true;
							}
						});
					}

					finded = finded && (filterByStaff || Utils.inArray(value.ownerid, staffsIds));
				}

				var eventStartDate = Moment(value.timestampStart, "X");
				var eventEndDate = Moment(value.timestampEnd, "X");
				var dayDate = Moment();

				switch (options['search']['period'][0]) {

					case 'late':

						switch (options['search']['periodFiltered']) {

							case 'lastWeek':

								if (eventStartDate.isSameOrBefore(dayDate.clone().subtract(1, 'week'), 'week') && eventEndDate.isSameOrAfter(dayDate.clone().subtract(1, 'week'), 'week')) {
									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

							case 'lastMonth':

								if (eventStartDate.isSameOrBefore(dayDate.clone().subtract(1, 'month'), 'month') && eventEndDate.isSameOrAfter(dayDate.clone().subtract(1, 'month'), 'month')) {
									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

							case 'thisWeek':

								if (eventStartDate.isBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate, 'week')) {

									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

							case 'thisMonth':

								if (eventStartDate.isBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate, 'month')) {
									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

							default:

								if (eventStartDate.isBefore(dayDate, 'day')) {
									finded = finded && true;
								}

								break;

						}

						break;

					case 'today':

						if (value.allDay === 'Y') {

							if (eventStartDate.isSameOrBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate, 'day')) {
								finded = finded && true;
							} else {
								finded = finded && false;
							}
						} else {

							if (eventStartDate.isSameOrBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate)) {
								finded = finded && true;
							} else {
								finded = finded && false;
							}
						}

						break;

					case 'tomorrow':

						switch (options['search']['periodFiltered']) {

							case 'thisWeek':

								if (eventStartDate.isSame(dayDate, 'week') && eventStartDate.isAfter(dayDate, 'day')) {
									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

							case 'thisMonth':

								if (eventStartDate.isSame(dayDate, 'month') && eventStartDate.isAfter(dayDate, 'day')) {
									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

							default:

								if (eventEndDate.isAfter(dayDate, 'day')) {
									finded = finded && true;
								} else {
									finded = finded && false;
								}

								break;

						}

						break;

				}
			}

			return finded;
		},

		/**
   * @description create stats of events collection
   * @returns {undefined}
   */
		buildStats: function () {

			var _this = this;

			var eventsForStats = new Events();
			eventsForStats.XHRData = {
				search: {
					period: this.XHRData.search.period,
					staffs: this.XHRData.search.staffs,
					relatedtype: this.XHRData.search.relatedtype,
					relatedid: this.XHRData.search.relatedid,
					labelid: this.XHRData.search.labelid
				},
				getAll: true
			};

			this.stats = {
				late: {
					"lastWeek": 0,
					"lastMonth": 0,
					"thisWeek": 0,
					"thisMonth": 0
				},
				today: 0,
				tomorrow: {
					"thisWeek": 0,
					"thisMonth": 0
				}

			};

			return new Promise(function (resolve, reject) {

				eventsForStats.fetchLocal(eventsForStats.XHRData).then(_.bind(function () {

					eventsForStats.each(function (event) {

						var eventStartDate = Moment(event.get('timestampStart'), "X");
						var eventEndDate = Moment(event.get('timestampEnd'), "X");
						var dayDate = Moment();
						var checkIt = true;

						if (eventsForStats.XHRData.search['staffs']) {

							var staffsIds = eventsForStats.XHRData.search['staffs'];

							_.each(staffsIds, function (id, index) {
								staffsIds[index] = ("" + id).split('_')[0];
							});

							var filterByStaff = false;

							if (event.get('staffs')) {

								_.each(event.get('staffs'), function (staff) {

									if (Utils.inArray(staff.id, staffsIds)) {
										filterByStaff = true;
									}
								});
							}

							checkIt = filterByStaff || Utils.inArray(event.get('ownerid'), staffsIds);
						}

						if (eventsForStats.XHRData.search['labelid']) {

							var labelid = _.first(eventsForStats.XHRData.search['labelid']);
							checkIt = checkIt && event.get('labelid') === labelid;
						}

						if (checkIt) {

							if (event.get('allDay') === 'Y') {

								if (eventStartDate.isSameOrBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate, 'day')) {
									_this.stats['today'] += 1;
								}
							} else {

								if (eventStartDate.isSameOrBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate)) {
									_this.stats['today'] += 1;
								}
							}

							if (eventStartDate.isSame(dayDate, 'month') && eventStartDate.isAfter(dayDate, 'day')) {
								_this.stats['tomorrow']['thisMonth'] += 1;
							}

							if (eventStartDate.isSame(dayDate, 'week') && eventStartDate.isAfter(dayDate, 'day')) {
								_this.stats['tomorrow']['thisWeek'] += 1;
							}

							if (eventStartDate.isSameOrBefore(dayDate.clone().subtract(1, 'week'), 'week') && eventEndDate.isSameOrAfter(dayDate.clone().subtract(1, 'week'), 'week')) {
								_this.stats['late']['lastWeek'] += 1;
							}

							if (eventStartDate.isSameOrBefore(dayDate.clone().subtract(1, 'month'), 'month') && eventEndDate.isSameOrAfter(dayDate.clone().subtract(1, 'month'), 'month')) {
								_this.stats['late']['lastMonth'] += 1;
							}

							if (eventStartDate.isSameOrBefore(dayDate, 'week') && eventStartDate.isBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate, 'week')) {
								_this.stats['late']['thisWeek'] += 1;
							}

							if (eventStartDate.isSameOrBefore(dayDate, 'month') && eventStartDate.isBefore(dayDate, 'day') && eventEndDate.isSameOrAfter(dayDate, 'month')) {
								_this.stats['late']['thisMonth'] += 1;
							}
						}
					});

					resolve();
				}, this));
			});
		},

		/**
  *
   */
		clearRecurringOccurrences: function () {
			return DB[this.storeName].where("isEventRecurring").equals('Y').delete();
		}

	});

	return Events;
});
