/**
 * Child View : Core Form
 */
define('views/product/popupActivateDecli',['underscore', 'views/core/Popup', 'views/core/form', 'tools/core/utils', 'views/core/toastNotification', 'app'], function (_, Popup, Form, Utils, ToastNotification, App) {

	'use strict';

	return Popup.extend({

		models: {},
		collections: {},
		parentView: {},
		events: _.extend({}, Popup.prototype.events),

		toastMessage: {
			variationActivated: App.polyglot.t('variationActivated')
		},

		/**
   * @desc initialize
   */
		initialize: function (options, router) {

			Popup.prototype.initialize.apply(this, arguments);

			this.subviews = [];
			this.parentView = options.parentView;
			this.template = options.contentTemplate;
			this.router = router;
			this.classes = "form";
			this.models = options.models;
			this.collections = options.collections;
		},

		/**
   * 
   * @returns {undefined}
   */
		startup: function () {

			this.form = new Form({ parentView: this }, $('.popup-content', this.$el));
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.listenTo(this, 'renderend', this.afterRender);

			this.initBtns();
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function (context) {

			var dataForm = this.form.dataForm;
			var _this = this;
			var error = false;
			var errormsg = '';
			var used = [];

			// check if not all variation field are not 0
			if (Utils.isArrayEquals(_.toArray(dataForm.variationfield), ["0", "0", "0"])) {
				error = true;
				errormsg = App.polyglot.t('chooseOneVariation');
			}

			// do not erase first error
			if (error === false) {

				// check if variation field has been selected multiple times
				_.each(dataForm.variationfield, function (vfield) {

					if (vfield != 0 && used.indexOf(vfield) != -1) {
						error = true;
						errormsg = App.polyglot.t('chooseDifferentValues');
					}

					used.push(vfield);
				});
			}

			// if no error
			if (error === false) {

				var decliFields = [];

				_.each(dataForm.variationfield, function (vfield, index) {
					decliFields.push({ id: vfield });
				});

				// save
				_this.parentView.product.activateVariation(decliFields).then(function () {

					_this.hide();
					_this.parentView.render();
					_this.form.trigger('successful', _this.toastMessage.variationActivated);
				}, function () {

					new ToastNotification({
						id: 'toast-error',
						classes: 'col-xs-12 ',
						style: { 'margin-bottom': '70px' },
						timeDuration: 4000,
						content: App.polyglot.t('anErrorOccured')
					});
				});
			} else {
				$('.submit-form', context).prop('disabled', false);
				_this.form.showOneError(errormsg);
			}

			return false;
		}

	});
});
