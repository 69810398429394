/**
 *	@desc Model CreditNote, extend Document Model 
 */
define('models/singles/creditnote',['models/singles/document', 'app', 'tools/core/utils'], function (Document, App, Utils) {

	'use strict';

	return Document.extend({

		defaults: _.extend({

			id: 0,
			doctype: 'creditnote',
			ident: '',
			formattedDocType: 'Avoir',
			thirdName: '',
			availableSteps: ['draft', 'stored', 'partialspend', 'spent', 'cancelled']

		}, Document.prototype.defaults),
		urlRoot: '/rest/document/creditnote',
		isEditable: function () {

			var currentStep = this.get('step');

			if (App.strictMode) {
				return currentStep === 'draft';
			} else {
				return Utils.inArray(currentStep, ['stored', 'draft']);
			}
		},

		isStepAllowed: function (nextStep) {

			var currentStep = this.get('step');
			this.stepError = '';

			//do this check only in strict mod
			if (App.strictMode) {

				if (currentStep === 'cancelled') {
					this.stepError = App.polyglot.t("cantChangeStepOfCancelledDoc");
					return false;
				}

				if (nextStep === 'draft') {
					this.stepError = App.polyglot.t("cantReturnToDraftStep");
					return false;
				}

				if (nextStep === 'cancelled' && currentStep !== 'draft') {
					this.stepError = App.polyglot.t("creditnoteCanBeCancelledOnlyInDraft");
				}
			}

			if (currentStep === 'spent' || currentStep === 'partialspend' && nextStep !== 'spent') {
				this.stepError = App.polyglot.t("cantChangeSpendStatus"); //Pour changer le statut d'un avoir payé ou en cours de paiement, commencez par supprimer le(s) remboursement(s) rattaché(s). Pour ce faire, cliquez sur liste des remboursements
			}

			return this.stepError ? false : true;
		}

	});
});
