/**
 * @description SearchBar View
 */
define('views/core/shortenedPanel',['underscore', 'views/core/view', 'app', 'models/collections/events', 'models/collections/tasks', 'models/collections/annotations', 'tools/core/utils', 'tools/buildURL'], function (_, View, App, Events, Tasks, Annots, Utils, URLBuilder) {

	'use strict';

	return View.extend({

		template: {},
		models: {},
		content: '',
		parentView: {},
		id: 'actions',
		className: 'tab-pane',
		name: 'shortenedPanel',

		/**
   * 
   * @param {String} template
   * @param {View} listing
   */
		initialize: function (parentView, options) {

			this.template = 'templates/widgets/shortenedPanel.twig';
			this.parentView = parentView.parentView;
			this.content = options.actions;
			this.linkedtype = options.relatedType;
			this.linkedid = options.relatedId;
			this.panelActions = options;
		},

		/**
   * 
   * @returns {undefined}
   */
		render: function () {

			var relatedtype = this.panelActions.relatedType;
			var relatedid = this.panelActions.relatedId;
			var _this = this;

			return new Promise(function (resolve, reject) {

				var defautlActions = {

					event: {
						icon: 'icon-agenda',
						label: App.polyglot.t("agenda"),
						url: function () {
							return URLBuilder(['agenda', 'event', relatedtype, relatedid]);
						}
					},
					task: {
						icon: 'icon-task',
						label: App.polyglot.t("tasks"),
						url: function () {
							return URLBuilder(["task", 'home', relatedtype, relatedid]);
						}
					},
					comment: {
						icon: 'icon-conversation',
						label: App.polyglot.t("annotations"),
						url: function () {
							return URLBuilder(["annotation", "form", relatedtype, relatedid]);
						}
					},
					logHisto: {
						icon: 'icon-activity',
						label: App.polyglot.t("actionshistory"),
						url: function () {
							return URLBuilder(["log", "list", relatedtype, relatedid]);
						}
					},
					email: {
						icon: 'icon-email',
						label: App.polyglot.t("emails"),
						url: function () {
							return URLBuilder(["emailBox", "home", relatedtype, relatedid]);
						}
					}

				};

				if (App.permission.can('briefcase')) {
					defautlActions['briefcase'] = {
						icon: 'icon-briefcase',
						label: App.polyglot.t('briefcase'),
						url: function () {
							return URLBuilder(['briefcase', 'list', relatedtype, relatedid]);
						}
					};
				}

				//edit defaultActions with custom parameters of each overviews
				_.each(_this.panelActions.actions, function (action, actionName) {

					//if action is not in default actions, we add it
					if (!defautlActions[actionName]) {
						defautlActions[actionName] = action;
					} else {

						if (action.display === false) {
							delete defautlActions[actionName];
						}

						if (action.url) {
							defautlActions[actionName]['url'] = action.url;
						}

						if (action.label) {
							defautlActions[actionName]['label'] = action.label;
						}
					}
				});

				_this.options = {
					extraData: {
						actions: function () {
							return new Promise(function (resolve, reject) {
								resolve(defautlActions);
							});
						}
					}
				};

				_this.actions = defautlActions;
				View.prototype.render.apply(_this, arguments).then(function () {
					resolve();
				}, function (e) {
					console.log(e);
					reject();
				});
			});
		},

		/**
   * 
   * @desc insert in the DOM
   */
		insertInDom: function () {

			var _this = this;

			this.$el.removeClass('active');
			$('.tab-content', this.parentView.$el).append(this.$el);

			//set link on each actions
			_.each(this.actions, function (action, id) {

				$('#' + id).attr('href', _.isFunction(action.url) ? action.url() : action.url).on('click', function () {
					localStorage.setItem('linkedName', _this.panelActions.model.get(_this.panelActions.name));
				});
			});

			if (App.isOnline) {
				this.getNbTotalForLinkeds();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		getNbTotalForLinkeds: function () {

			var _this = this;
			var promises = [];

			var events = new Events();
			var tasks = new Tasks();
			var annots = new Annots();

			if (_this.actions['event']) {
				promises.push(events.getNbCurrents(Utils.inArray(_this.linkedtype, App.availableThirds) ? "third" : _this.linkedtype, _this.linkedid, { period: ['today'], staffs: [App.currentStaff.id] }));
			}

			if (_this.actions['task']) {
				promises.push(tasks.getNbCurrents(Utils.inArray(_this.linkedtype, App.availableThirds) ? "third" : _this.linkedtype, _this.linkedid, { period: ['today'], staffs: [App.currentStaff.id] }));
			}

			if (this.actions['comment']) {

				var typeforcomment = _this.linkedtype;
				var additionalParams = {};

				if (Utils.inArray(typeforcomment, App.availableDoctypes)) {
					typeforcomment = 'document';
				}

				if (Utils.inArray(typeforcomment, App.availableThirds)) {
					typeforcomment = 'third';
				}

				if (Utils.inArray(typeforcomment, App.availableItems)) {
					typeforcomment = 'item';
				}

				if (typeforcomment === 'event' || typeforcomment === 'task') {
					typeforcomment = App.isOnline ? 'task' : typeforcomment;
				}

				if (typeforcomment === "googleEvent" || typeforcomment === "outlookEvent") {
					additionalParams['calendarid'] = this.panelActions.model.get("calendarId");
				}

				promises.push(annots.getNbCurrents(typeforcomment, _this.linkedid, additionalParams));
			}

			Promise.all(promises).then(function (result) {

				var nbEvents = result[0];
				var nbTasks = result[1];
				var nbAnnots = annots.nbResults;

				if (nbEvents > 0) {
					_this.$('#event i').prepend('<div class="counter">' + nbEvents + '</div>');
				}

				if (nbTasks > 0) {
					_this.$('#task i').prepend('<div class="counter">' + nbTasks + '</div>');
				}

				if (nbAnnots > 0) {
					_this.$('#comment i').prepend('<div class="counter">' + nbAnnots + '</div>');
				}
			}, function () {
				console.log(arguments);
			});
		}

	});
});
