/**
 * @desc Product Form, extend Page View
 * Child View : Core Form
 */
define('views/product/form',['backbone', 'underscore', 'views/core/page', 'models/singles/item', 'views/core/form', 'models/collections/taxes', 'models/collections/unities', 'models/singles/service', 'app', 'tools/buildURL', 'tinyMCE'], function (Backbone, _, Page, Item, Form, Taxes, Unities, Service, App, URLBuilder, TinyMCE) {

	'use strict';

	return Page.extend({

		events: _.extend(Page.prototype.events, {
			"click .type": 'checkTypeAction'
		}),

		title: "",
		form: {},
		toastMessages: {
			productSaved: App.polyglot.t('productSaved'),
			productUpdated: App.polyglot.t('productUpdated')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router, type, productId, declid, comefrom) {

			Page.prototype.initialize.apply(this, arguments);

			this.modelToSave = type === 'item' ? new Item({ id: productId, declid: declid }) : new Service({ id: productId, declid: declid });
			this.models[this.modelToSave.name] = this.modelToSave;

			this.taxes = new Taxes();

			this.collections[this.taxes.name] = this.taxes;

			if (!this.modelToSave.isNew()) {
				this.title = "Modifier un " + this.modelToSave.get('formattedType');
				this.modelToSave.mustFetchLocal = true;
			} else {
				this.title = "Ajouter un " + this.modelToSave.get('formattedType');
			}

			this.template = "templates/product/form.twig";
			this.unities = new Unities();

			this.collections[this.unities.name] = this.unities;

			this.router = router;
			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);
			this.render();

			this.listenTo(this.form, 'dataSend', this.formAction);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			TinyMCE.init({
				selector: "#product-note",
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				menubar: false,
				statusbar: false,
				cleanup: true,
				forced_root_block: ""
			});

			//focus select on the model value
			if (!this.modelToSave.isNew()) {
				$("select[name='product[taxid]']").val(this.modelToSave.get('taxid'));
				$("select[name='product[unit]']").val(this.modelToSave.get('unit'));
			}

			if (this.modelToSave.get('unitAmountIsTaxesFree') === "Y") {
				$("input[name='product[unitAmountIsTaxesFree]']").prop('checked', true);
			}

			$('.currency-symbol', this.$el).text(App.numberFormat.currency.symbol);
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			this.modelToSave.set(dataForm.product);

			if (_.isUndefined(dataForm.product['unitAmountIsTaxesFree'])) {
				this.modelToSave.set({ unitAmountIsTaxesFree: 'N' });
			}

			if (this.modelToSave.isValid()) {

				var toastMsg = this.modelToSave.isNew() ? this.toastMessages.productSaved : this.toastMessages.productUpdated;

				this.modelToSave.save().then(function () {
					_this.form.trigger('successful', toastMsg);
					_this.router.navigate(URLBuilder(["product", "overview", _this.modelToSave.get('type'), _this.modelToSave.get('id'), _this.modelToSave.get('declid')]), { trigger: true, replace: true });
				}, function () {
					// re activate submit button
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
