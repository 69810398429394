define('views/widgets/expendables',['underscore', 'views/core/view'], function (_, View) {

	return View.extend({

		events: {
			'click .expendable > *:nth-child(1)': 'toggleExpendable'
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function ($elt, options) {
			this.setElement($elt);

			if (options) {

				if (options.defaultState === 'contracted') {
					$('.expendable', $elt).addClass('contracted');
				}
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		toggleExpendable: function (e) {

			var $target = $(e.currentTarget);
			var $expendable = $target.closest('.expendable');

			$expendable.toggleClass('contracted');
		}

	});
});
