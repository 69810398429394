/**
 *  @desc Products Collection, extend core Collection
 */
define('models/collections/products',['models/core/collection', 'models/singles/catalogue'], function (Collection, Catalogue) {

	'use strict';

	return Collection.extend({

		model: Catalogue,
		storeName: 'product',
		name: 'products',
		url: '/rest/product/list',
		fields: ['name', 'tradename', 'notes', 'unitAmount'],
		type: '',
		searchFieldName: 'name',

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['type', 'useDeclination', 'hasStockAvaible', 'isDecli'];
			this.orderBy = 'asc';
		}

	});
});
