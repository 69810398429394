/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/supportSource',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'supportsources',
		name: 'supportsource',
		urlRoot: '/rest/support/source/'

	});
});
