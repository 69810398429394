/**
 *  @desc Address Collection, extend core Collection
 */
define('models/collections/addresses',['models/core/collection', 'models/singles/address'], function (Collection, Address) {

	'use strict';

	return Collection.extend({

		model: Address,
		storeName: 'address',
		name: 'addresses',
		url: '/rest/address/list'
	});
});
