/**
 * @description ExpenseFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/expensesFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters'], function (_, FilterPanel, App, Utils, ListingFilters) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			this.title = App.polyglot.t("expenses");

			FilterPanel.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;

			var steps = [{ value: '', label: App.polyglot.t('all') }];
			$.each(App.availableExpenseStep, function (index, value) {
				steps.push({ value: value, label: App.polyglot.t(value) });
			});

			var filterList = {
				state: {
					label: App.polyglot.t('state'),
					field: {
						type: 'select',
						isMultiple: true,
						name: 'steps',
						offlineSearchField: 'step',
						values: steps,
						labelAttr: 'label',
						valueAttr: 'value'
					}
				}
			};

			this.filters = filterList;
			return this.filters;
		}

	});
});
