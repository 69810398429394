/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/eventLog',['models/core/model', 'tools/ajax', 'db/easy', 'app', 'moment'], function (Model, Ajax, DB, App, Moment) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		urlRoot: '/rest/evenlog',
		name: 'eventlog',
		storeName: 'eventlog'

	});
});
