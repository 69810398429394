/**
 * @description DocumentFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/catalogFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/thirds', 'models/collections/smarttags'], function (_, FilterPanel, App, Utils, ListingFilters, Thirds, Smarttags) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			this.type = listName;
			this.thirds = new Thirds();
			this.smartTags = new Smarttags();
			this.smartTags.fetchOneTime = true;
			this.smartTags.url = '/rest/smarttags/search';
			this.smartTags.XHRData = {
				search: Smarttags.buildFilterByLinked(this.type)
			};

			this.collections[this.smartTags.name] = this.smartTags;
			this.title = App.polyglot.t(listName == 'item' ? 'products' : 'services');
			FilterPanel.prototype.initialize.apply(this, [router, listName, listName == "item" ? "items" : "services"]);
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var filterList = {};
			var _this = this;
			if (this.type === 'item') {

				filterList['useDeclination'] = {
					label: App.polyglot.t('productDeclined'),
					field: {
						type: 'select',
						name: 'useDeclination',
						offlineSearchField: 'useDeclination',
						values: [{ 'value': '', label: App.polyglot.t("allProduct") }, { 'value': 'Y', label: App.polyglot.t("yes") }, { value: 'N', label: App.polyglot.t("no") }],
						labelAttr: 'label',
						valueAttr: 'value'
					}
				};
			}

			filterList = _.extend(filterList, {
				stockAvailable: {
					label: App.polyglot.t('stockAvailable'),
					field: {
						type: 'select',
						name: 'stockAvailable',
						offlineSearchField: 'hasStockAvaible',
						values: [{ 'value': '', label: App.polyglot.t("all") }, { 'value': 'Y', label: App.polyglot.t("yes") }, { value: 'N', label: App.polyglot.t("outOfStock") }],
						labelAttr: 'label',
						valueAttr: 'value'
					}
				},
				smarttags: {
					label: App.polyglot.t('smarttags'),
					field: {
						type: 'input',
						name: 'tags',
						offlineSearchField: 'smarttags',
						classes: "smarttags-selectize",
						isMultiple: false,
						selectize: {
							domElt: 'input[name="filter[tags]"]',
							placeholder: App.polyglot.t('smarttags'),
							create: false,
							valueField: 'word',
							labelField: 'word',
							searchField: ['word'],
							loadThrottle: 600,
							options: this.smartTags,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.smartTags.fetch({

										method: 'POST',
										data: { search: _.extend({ contains: query }, _this.smartTags.XHRData.search) }

									}, true).then(function () {
										resolve(_this.smartTags);
									});
								});
							}
						}

					}
				}

			});

			this.filters = filterList;
			return this.filters;
		}

	});
});
