define('views/widgets/deleteActionSheet',['underscore', 'views/core/view', 'app', 'tools/core/utils'], function (_, View, App, Utils) {

    return View.extend({

        /**
         *
         * @returns {undefined}
         */
        initialize: function (deleteCallback) {
            this.deleteCallback = deleteCallback;
            this.render();
        },

        render: function () {

            var _this = this;

            var callback = function (buttonIndex) {

                if (buttonIndex == 1) {
                    _this.deleteCallback();
                }
            };

            var options = {
                title: App.polyglot.t('confirmDelete'),
                androidTheme: window.plugins.actionsheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT, // default is THEME_TRADITIONAL
                androidEnableCancelButton: true, // default false
                'addCancelButtonWithLabel': App.polyglot.t('cancel'),
                'addDestructiveButtonWithLabel': Utils.capitalizeFirstLetter(App.polyglot.t('delete')),
                androidTheme: window.plugins.actionsheet.ANDROID_THEMES.THEME_DEVICE_DEFAULT_LIGHT // default is THEME_TRADITIONAL
            };

            window.plugins.actionsheet.show(options, callback);
        }

    });
});
