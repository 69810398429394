/**
 * @desc Prospect List, extend Third Listing View
 * Child View : Core Listing
 */
define('views/prospection/prospect/list',['underscore', 'views/third/list', 'tools/buildURL', 'app'], function (_, ThirdList, URLBuilder, App) {

	'use strict';

	return ThirdList.extend({

		name: 'prospects',
		linked: 'prospect',
		title: App.polyglot.t("prospects"),
		toastMessages: {
			linked: {
				linkedRemoved: App.polyglot.t('prospectRemoved')
			}
		},
		template: "templates/prospect/list.twig",
		urlToForm: URLBuilder(["prospect", "form"])
	});
});
