/**
 *  @desc Taxes Collection, extend core Collection
 */
define('models/collections/taxes',['models/core/collection', 'models/singles/tax', 'db/easy'], function (Collection, Tax, DB) {

	'use strict';

	return Collection.extend({

		model: Tax,
		storeName: 'tax',
		name: 'taxes',
		url: '/rest/tax/list',
		fields: ['value'],
		canBeStored: true,

		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.reset(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		}

	});
});
