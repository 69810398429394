/**
 *	@desc Model Stat, extend Core Model 
 */
define('models/singles/stat',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		storeName: 'stats',
		name: 'stat',
		urlRoot: '/rest/stats'

	});
});
