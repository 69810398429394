/**
 *	@desc Shipping Model , extend Core Model 
 */
define('models/singles/customfield',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			type: '',
			min: 0,
			max: 250,
			orderByAttribute: ''
		},

		storeName: 'customfields',
		name: 'customfield',
		urlRoot: '/rest/customfield',

		/**
   * 
   * @returns {undefined}
   */
		validate: function (attributes) {

			var validationErrors = {};

			switch (attributes.type) {

				case 'checkbox':

					if (_.isUndefined(attributes.value)) {
						attributes.value = [];
					}

					if (attributes.value.length < this.get('min') || attributes.value.length > this.get('max')) {

						if (_.isUndefined(validationErrors[attributes.nameattr])) {
							validationErrors[attributes.nameattr] = [];
						}

						if (attributes.value.length < this.get('min')) {
							validationErrors[attributes.nameattr][0] = this.get('min') + ' minimum';
						}

						if (attributes.value.length > this.get('max')) {
							validationErrors[attributes.nameattr][0] = this.get('max') + ' maximum';
						}
					}

					break;
			}

			if (_.toArray(validationErrors).length > 0) {
				return validationErrors;
			}
		}

	});
});
