/**
 * @desc Contact Form, extend Page View
 * Child View : Core Form
 */
define('views/people/form',['backbone', 'underscore', 'views/core/page', 'views/core/form', 'models/singles/people', 'tools/buildURL', 'app'], function (Backbone, _, Page, Form, People, URLBuilder, App) {

	'use strict';

	return Page.extend({

		events: {},

		title: App.polyglot.t('addcontact'),
		form: {},
		modelToSave: {},
		template: "templates/third/formContact.twig",
		toastMessages: {
			peopleSaved: App.polyglot.t('contactSaved'),
			peopleUpdated: App.polyglot.t('contactUpdated')
		},

		/**
   * 
   * @param {Router} router
   * @param {int} linkedId
   */
		initialize: function (router, linkedId, relationtype, peopleid, comefrom) {

			Page.prototype.initialize.apply(this, arguments);

			/**
    * if relationtype and peopleid are not defined, we don't come from a linked third,
    * we are directly on a people, so linkedId = peopleId
    * we set however relationtype and linkedIdfor be right with other cases
    */

			if (!relationtype || relationtype == 'list') {

				this.modelToSave = new People({ id: linkedId });
				this.relationType = "people";
			} else {
				this.relationType = relationtype;
				this.modelToSave = new People({ id: peopleid, clientid: linkedId });
			}

			this.linkedId = linkedId;

			this.models[this.modelToSave.name] = this.modelToSave;

			if (!this.modelToSave.isNew()) {
				this.title = App.polyglot.t('modifyContact');
			}

			this.form = new Form({ parentView: this });
			this.subviews = [];

			this.subviews.push(this.form);
			this.render();
			this.listenTo(this.form, 'dataSend', this.formAction);
		},

		/**
   * @description : triggered when dom is ready and all models data are fetched
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			// DOM Declaration
			this.$.radioMan = $('#radioMan', this.$el);
			this.$.radioWoman = $('#radioWoman', this.$el);
			this.$.radioIsMain = $('input[name="third[isMain]"]', this.$el);

			//check people civil
			if (this.modelToSave.get("civil") === "man") {

				this.$.radioMan.prop("checked", true);
			} else if (this.modelToSave.get("civil") === "woman") {

				this.$.radioWoman.prop("checked", true);
			}

			if (this.modelToSave.get('isMain') === "Y") {
				this.$.radioIsMain.prop('checked', true);
			}
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			_.each(dataForm.people, function (attribute, index, model) {
				var selector = '[name = "' + _this.modelToSave.name + '[' + index + ']"]';
				var $selector = $(selector);
				$selector.data("oldvalue", attribute);
			});

			this.modelToSave.clear();
			this.modelToSave.set(dataForm.people);

			var thirds = [dataForm.third];

			//for linked people to third
			if (this.relationType !== 'people') {
				this.modelToSave.set({ thirds: thirds });
			}

			var isNew = this.modelToSave.isNew();

			if (this.modelToSave.isValid()) {

				var toastMsg = this.modelToSave.isNew() ? this.toastMessages.peopleSaved : this.toastMessages.peopleUpdated;

				this.modelToSave.save().then(function () {

					_this.form.trigger('successful', toastMsg);
					_this.form.redirect(isNew, URLBuilder(['people', "overview", _this.modelToSave.id]));
				}, function () {
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make the input invalid in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
