/**
 *  @desc Opportunities Collection, extend core Collection
 */
define('models/collections/opportunities',['models/core/collection', 'models/singles/opportunity', 'db/easy'], function (Collection, Opportunity, DB) {

	'use strict';

	return Collection.extend({

		model: Opportunity,
		storeName: 'opportunity',
		statsStoreName: 'opportunitiesstats',
		name: 'opportunities',
		url: '/rest/opportunity/list',
		searchFieldName: 'ident',
		fields: ['ident'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			var _this = this;

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['funnelid', 'linkedid', 'signed', 'stepid'];
			this.orderBy = 'desc';
			this.orderArg = 'po_created';
		},

		/**
   * @desc override for filter by staff in charge
   * @param {type} _this
   * @param {type} value
   * @param {type} options
   * @returns {undefined}
   */
		offlineQuery: function (value, options) {

			var finded = Collection.prototype.offlineQuery.apply(this, arguments);

			//when staffs is provided, do the search with else juste return the result of parent function
			if (this.XHRData.search['staffs']) {

				var staffid = _.first(this.XHRData.search['staffs']);
				var filterByStaff = false;

				if (value.staffsFullList) {

					_.each(value.staffsFullList, function (staffInCharge) {

						if (staffInCharge.id == staffid) {
							filterByStaff = true;
						}
					});
				}

				finded = finded && filterByStaff;
			}

			if (this.XHRData.search['nbDayInCurrentStep_end'] || this.XHRData.search['nbDayInCurrentStep_start']) {

				if (this.XHRData.search['nbDayInCurrentStep_start']) {
					finded = finded && value.nbDayInCurrentStep >= this.XHRData.search['nbDayInCurrentStep_start'];
				}

				if (this.XHRData.search['nbDayInCurrentStep_end']) {
					finded = finded && value.nbDayInCurrentStep <= this.XHRData.search['nbDayInCurrentStep_end'];
				}
			}

			if (this.XHRData.search['minScore'] || this.XHRData.search['maxScore']) {

				if (this.XHRData.search['minScore']) {
					finded = finded && value.score >= this.XHRData.search['minScore'];
				}

				if (this.XHRData.search['maxScore']) {
					finded = finded && value.score <= this.XHRData.search['maxScore'];
				}
			}

			return finded;
		},

		/**
   * 
   * @returns {undefined}
   */
		saveStatsLocal: function () {

			var _this = this;

			_this.stats = {
				funnelid: _this.XHRData['search']['funnelid'],
				stats: _this.stats
			};

			DB[_this.statsStoreName].put(_this.stats);
		},

		/**
   * @description get opportunity stats corresponding to funnel selected
   * @returns {unresolved}
   */
		fetchStatsLocal: function () {

			var _this = this;

			return DB[_this.statsStoreName].where('funnelid').equals(_this.XHRData['search']['funnelid']).each(function (stats) {
				_this.stats = stats;
			});
		}

	});
});
