/**
 * @desc Product List, extend PageList View
 * Child View : Core Listing
 */
define('views/product/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/items', 'models/collections/services', 'app'], function (_, PageList, Listing, URLBuilder, Items, Services, App) {

	'use strict';

	return PageList.extend({

		title: "",
		listing: {},
		toastMessages: {
			product: {
				productRemoved: App.polyglot.t('productRemoved')
			}
		},
		addAction: function (e) {
			this.listing.goToForm(URLBuilder(['product', 'form', this.collection.XHRData.type]));
		},
		/**
   * @desc initialize
   */
		initialize: function (router, type, links) {

			this.availableActions = links;
			this.canDelete = App.permission.can('deleteitem');
			this.canAdd = App.permission.can('createitem');
			this.canEdit = App.permission.can('edititem');
			this.name = type;

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.title = type === 'item' ? App.polyglot.t('products') : App.polyglot.t('services');
				this.collection = type === 'item' ? new Items() : new Services();
				this.collection.XHRData = {
					type: type,
					isDecli: "N"
				};

				this.placeholderSearchBar = App.polyglot.t('ref') + ', ' + App.polyglot.t('tradename') + ', ' + App.polyglot.t('description');
				this.template = "templates/product/list.twig";

				this.listing = new Listing({ parentView: this });
				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canEdit || this.canDelete) {

				this.listing.initSwipeEffect({

					onModify: function (model) {
						this.router.navigate(URLBuilder(["product", "form", model.get('type'), model.get('id'), model.get('declid'), 'list']), { trigger: true });
					},
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					removeMsg: this.toastMessages.product.productRemoved,
					popupid: "product-option",
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					}

				}, '', this);
			}
		}

	});
});
