/**
 * @desc represent the SideBar
 *  Staff Info are in a child View
 */
define('views/sidebar/panel',['jquery', 'backbone', 'views/sidebar/staff', 'views/core/Popup', 'tools/ajax', 'views/core/view', 'app', 'tools/core/utils'], function ($, Backbone, ViewStaff, Popup, Ajax, View, App, Utils) {

	return View.extend({

		events: {
			'click .sb-row a': function () {
				$('.mask').toggleClass("mask-up");
				this.$el.removeClass("side-bar-up");
			},
			'click .sidebar-logout': "logout",
			'click .button-with-network': 'checkButton',
			'click #staffInfo .icon-close': function (e) {
				$(".content-wrapper").toggleClass("content-wrapper-toggled");
				App.header.$el.toggleClass("header-toggled");

				this.$el.toggleClass("side-bar-up");
				$('.mask').toggleClass("mask-up");
			}
		},

		staffView: {},
		models: {},
		el: "#side-bar",
		setLoader: false,

		/**
   * 
   */
		initialize: function () {

			View.prototype.initialize.apply(this, arguments);

			this.$.itemMenu = $('li', this.$el);
			this.render();

			$(window).on("resize", this.updateSideBarHeight.bind(this));
		},

		/**
   * 
   */
		setPanelContent: function () {

			var panel = {
				'dashboard': {
					label: App.polyglot.t("dashboard"),
					class: "sidebar-dashboard",
					icon: "icon-dashboard",
					link: "#"
				},
				'mail': {
					label: App.polyglot.t("mailbox"),
					class: "sidebar-mailbox",
					icon: "icon-email",
					link: "#emailBox/home"
				},
				'contact': {
					label: App.polyglot.t("contacts"),
					class: "sidebar-contacts",
					icon: "icon-contact",
					link: "#third/home",
					canAccess: App.permission.level("clientlevel") || App.permission.level("prospectlevel") || App.permission.level("supplierlevel") || App.permission.level("peoplelevel")
				},
				'opportunities': {
					label: App.polyglot.t("opportunities"),
					class: "sidebar-opportunities",
					icon: "icon-opportunity",
					canAccess: App.permission.can("prospection_access") && App.pluginIsLoaded("prospection"),
					link: "#opportunity/home"
				},
				'catalogue': {
					label: App.polyglot.t("catalog"),
					class: "sidebar-catalogue",
					icon: "icon-catalog",
					link: "#catalog/home",
					canAccess: App.permission.level('itemlevel')
				},
				'document': {
					label: App.polyglot.t("documents"),
					class: "sidebar-documents",
					icon: "icon-folder",
					link: '#document/home',
					canAccess: App.pluginIsLoaded("invoicing") && (App.currentStaff.get('permission').isAdmin === "Y" || App.permission.level('invoicing_invoicelevel') || App.permission.level('invoicing_estimatelevel') || App.permission.level('invoicing_orderlevel') || App.permission.level('invoicing_deliverylevel') || App.permission.level('invoicing_proformalevel'))
				},
				'purchase': {
					label: App.polyglot.t('purchases'),
					class: "sidebar-purchase",
					icon: "icon-purchase",
					canAccess: App.pluginIsLoaded("purchases") && App.permission.can('purchases_access') && (App.permission.level('purchases_purinvoicelevel') || App.permission.level("purchases_purorderlevel") || App.permission.level('purchases_purdeliverylevel') || App.permission.level("purchases_purinvoicelevel")),
					link: "#purchase/home"
				},
				'expense': {
					label: App.polyglot.t('expense'),
					class: "sidebar-expense",
					icon: "icon-expense",
					canAccess: App.pluginIsLoaded("expenses") && App.permission.can('expenses_access'),
					link: "#expense/list"
				},
				'support': {
					label: App.polyglot.t("support"),
					class: "sidebar-support",
					icon: "icon-helpdesk",
					canAccess: App.pluginIsLoaded("support") && App.permission.can('support_access'),
					link: "#ticket/list"
				},
				'timetracking': {
					label: App.polyglot.t('timetracking'),
					class: 'sidebar-timetracking',
					icon: "icon-timetracking",
					canAccess: App.pluginIsLoaded("timetracking") && App.permission.can('timetracking_access'),
					link: "#timetracking/list/myentries"
				},
				'event': {
					label: App.polyglot.t("agenda"),
					class: "sidebar-event",
					icon: "icon-agenda",
					link: "#agenda/event"
				},
				'task': {
					label: App.polyglot.t("tasks"),
					class: "sidebar-task",
					icon: "icon-task",
					link: "#task/home"
				},
				'helpdesk': {
					label: App.polyglot.t("Feedback"),
					class: "sidebar-settings button-with-network",
					icon: "icon-supportsellsy",
					link: "#supportRequest"
				},
				'setting': {
					label: App.polyglot.t("settings"),
					class: "sidebar-settings button-with-network",
					icon: "icon-setting",
					link: "#accountPref"
				},
				'logout': {
					label: App.polyglot.t("logout"),
					class: "sidebar-logout",
					link: "#"
				}
			};

			return panel;
		},

		/**
   * @desc Render view
   */
		render: function () {

			var _this = this;

			// Compile the template using underscore
			var template = _.template($('#side-bar-template').html());

			var panel = _this.setPanelContent();

			// Load the compiled HTML into the Backbone "el"
			_this.$el.html(template({ panel: panel })); //Insert template in DOM with data

			this.staffView = new ViewStaff();
			this.listenTo(_this.staffView, 'renderend', _this.afterRender);
			this.staffView.render();

			return this;
		},

		/**
   * 
   * @returns {undefined}
   */
		updateSideBarHeight: function () {

			var $sideBar = this.$el;
			var panelHeight = $sideBar.outerHeight();
			var staffViewHeight = $('#staffInfo', $sideBar).outerHeight();
			var width = panelHeight - staffViewHeight;

			$('.sidebar', $sideBar).height(width);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {},

		/**
   * 
   * @returns {undefined}
   */
		logout: function () {

			App.logout().then(function () {

				App.goToLogPage();
				App.header.undelegateEvents();
				App.sidebar.undelegateEvents();
				$('.content-wrapper').removeClass('content-wrapper-toggled');
			});

			return false;
		}

	});
});
