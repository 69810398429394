define('starter',['underscore', 'jquery', 'backbone', 'routers/third', 'routers/product', 'routers/agenda', 'routers/annotation', 'routers/people', 'routers/eventLog', 'routers/timetracking', 'routers/support', 'routers/expense', 'app', 'db/easy', 'tools/numberFormat', 'models/singles/corp', 'models/collections/corps', 'routers/document', 'tools/buildURL', 'models/collections/staffs', 'models/singles/staff', 'routers/setting', 'models/collections/currencies', 'routers/auth', 'views/sidebar/panel', 'models/singles/document', 'routers/prospection', 'routers/supplier', 'routers/email', 'routers/purchase', 'tools/ajax', 'views/core/toastNotification', 'routers/briefcase', 'routers/prospect', 'routers/emailBox', 'tools/monitoring', 'tools/core/utils', "permission", 'views/filterPanels/catalogFilterPanel', 'views/filterPanels/documentsFilterPanel', 'views/filterPanels/eventsFilterPanel', 'views/filterPanels/expensesFilterPanel', 'views/filterPanels/filterPanel', 'views/filterPanels/opportunitiesFilterPanel', 'views/filterPanels/purchaseFilterPanel', 'views/filterPanels/tasksFilterPanel', 'views/filterPanels/thirdsFilterPanel', 'views/filterPanels/ticketsFilterPanel', 'views/filterPanels/peoplesFilterPanel'], function (_, $, Backbone, ThirdRouter, ProductRouter, AgendaRouter, AnnotRouter, PeopleRouter, EventLogRouter, TimeTrackingRouter, SupportRouter, ExpenseRouter, App, DB, NumberFormat, Corp, Corps, DocumentRouter, URLBuilder, Staffs, Staff, SettingsRouter, Currencies, AuthRouter, Panel, Document, ProspectionRouter, SupplierRouter, EmailRouter, PurchaseRouter, Ajax, Notification, BriefcaseRouter, ProspectRouter, EmailBoxRouter, Monitoring, Utils, Permission) {

	var sellsyEasy = function () {

		this.startRouting = function () {
			//init routing
			this.thirdRouter = new ThirdRouter();
			this.settingsRouter = new SettingsRouter();
			this.productRouter = new ProductRouter();
			this.documentRouter = new DocumentRouter();
			this.authRouter = new AuthRouter();
			this.agendaRouter = new AgendaRouter();
			this.annotRouter = new AnnotRouter();
			this.peopleRouter = new PeopleRouter();
			this.prospectionRouter = new ProspectionRouter();
			this.supplierRouter = new SupplierRouter();
			this.emailRouter = new EmailRouter();
			this.eventLogRouter = new EventLogRouter();
			this.timetrackingRouter = new TimeTrackingRouter();
			this.purchaseRouter = new PurchaseRouter();
			this.expenseRouter = new ExpenseRouter();
			this.supportRouter = new SupportRouter();
			this.briefcaseRouter = new BriefcaseRouter();
			this.prospectRouter = new ProspectRouter();
			this.emailBoxRouter = new EmailBoxRouter();

			var _this = this;

			if (!Backbone.History.started) {
				Backbone.history.start({ silent: true });
			}

			var autoLogRegExp = new RegExp("\#auth\/.+\/.+");

			if (autoLogRegExp.test(location.hash)) {
				Backbone.history.loadUrl(location.hash);
				return false;
			}

			App.cacheControl = {}; //init the cache

			if (navigator.isNative) {

				var nativeBackHandler = function () {

					if (location.hash === "" || location.hash === "#auth") {
						navigator.app.exitApp();
					} else {
						$('.return').trigger('click');
					}

					return false;
				};

				document.addEventListener("backbutton", nativeBackHandler, false);
			}

			this.initStartup();
		};

		//Do some controle before startup
		this.initStartup = function () {

			var corp = new Corp();
			var staff = new Staff({ id: localStorage.getItem("lastLoggedStaff") });
			var _this = this;
			var promisesIdentity = [];

			var logoutCallback = function () {
				//go to log page
				App.isLogged = false;

				App.initTranslations((navigator.language || navigator.userLanguage).indexOf('fr') == 0 ? 'fr' : 'en').then(function (trad) {

					App.polyglot = trad;

					var route = '#auth';
					var handler = Backbone.history.navigate(route, { trigger: true, replace: true });

					if (!handler) {
						Backbone.history.loadUrl(route);
					}
				});

				return false;
			};

			DB['tokens'].toArray().then(function (tokens) {

				if (_.isEmpty(tokens)) {

					logoutCallback();
					return false;
				}

				//build tokens obj
				App.apiTokens = {};
				App.apiTokens[tokens[0]['id']] = tokens[0]['token'];
				App.apiTokens[tokens[1]['id']] = tokens[1]['token'];

				corp.fetch().then(function () {

					staff.fetch().then(function () {

						// add prefs in app
						App.isUSCorp = corp.get('preferences').countrycode === 'US';
						App.docPrefs = corp.get('preferences');
						App.strictMode = corp.get('strictMode') === 'Y' ? true : false;

						// check if there are more than 1 corp in localDB and clear LocalDB if corps.length > 1
						var corps = new Corps();
						var nbCorps = 0;

						corps.countLocal().then(function (nbTotal) {

							nbCorps = nbTotal;
							var resetTablesPromises = [];

							//on vide la BD locale : 
							//Si on se connecte avec une corp différente
							//Si on se connecte sur la même corp avec un staff différent
							if (nbCorps > 1 || localStorage.getItem('lastLoggedStaff') && staff.get('id') != localStorage.getItem('lastLoggedStaff')) {

								DB.tables.forEach(function (table) {

									if (table.name !== "tokens") {
										resetTablesPromises.push(table.clear());
									}

									console.log('RAZ tables');
								});
							}

							Promise.all(resetTablesPromises).then(function () {

								App.monitoring.setUser(staff, corp);

								var saveIdentityPromises = [];
								//if  DB local has been cleared, we put new corp and staff in.
								if (!_.isEmpty(resetTablesPromises)) {
									saveIdentityPromises.push(staff.saveLocal());
									saveIdentityPromises.push(corp.saveLocal());
								}

								Promise.all(saveIdentityPromises).then(function () {

									try {
										localStorage.setItem('lastLoggedStaff', staff.get('id'));
									} catch (error) {
										console.log(error);
									};

									staff.set('licences', corp.get('licences').staff);
									corp.unset('licences', 'silent');

									App.monitoring.setUser(staff, corp);

									//initiliaze global var and launch the starter
									App.version = corp.get('APP_VERSION');
									App.saasUrl = corp.get('SAAS_URL');
									App.fileUrl = corp.get('FILE_URL');
									App.currentStaff = staff;
									App.currentCorp = corp;
									App.numberFormat = new NumberFormat(corp.get('numberformat'));
									App.dateFormat = corp.get('dateformat');
									App.dateFormat.momentFormat = App.dateFormat.timeFormat === "dmy" ? "DD/MM/YYYY" : "MM/DD/YYYY";
									App.hourFormat = App.dateFormat.hourFormat;
									App.hourFormat = App.hourFormat == 24 ? 'HH:mm' : 'hh:mm';
									App.corpId = parseInt(corp.id);
									App.hasVat = corp.get('hasVat') === 'Y';
									App.permission = Permission;
									App.permissionDoctypes = [];

									if (App.permission.can('invoicing_access')) {

										if (App.currentStaff.get('permission').isAdmin === "Y") {
											App.permissionDoctypes = App.availableDoctypes;
										} else {

											if (App.permission.level('invoicing_invoicelevel')) {
												App.permissionDoctypes.push("invoice");
											}

											if (App.permission.level('invoicing_estimatelevel')) {
												App.permissionDoctypes.push("estimate");
											}

											if (App.permission.level('invoicing_orderlevel')) {
												App.permissionDoctypes.push("order");
											}

											if (App.permission.level('invoicing_deliverylevel')) {
												App.permissionDoctypes.push("delivery");
											}

											if (App.permission.level('invoicing_proformalevel')) {
												App.permissionDoctypes.push("proforma");
											}

											if (App.permission.level('invoicing_creditnotelevel')) {
												App.permissionDoctypes.push("creditnote");
											}
										}
									}

									App.permissionPurDoctypes = [];

									if (App.permission.can('purchases_access')) {

										if (App.currentStaff.get('permission').isAdmin === "Y") {
											App.permissionPurDoctypes = App.availableDoctypes;
										} else {

											if (App.permission.level('purchases_purinvoicelevel')) {
												App.permissionPurDoctypes.push("invoice");
											}

											if (App.permission.level('purchases_purorderlevel')) {
												App.permissionPurDoctypes.push("order");
											}

											if (App.permission.level('purchases_purdeliverylevel')) {
												App.permissionPurDoctypes.push("delivery");
											}

											if (App.permission.level('purchases_purcreditnotelevel')) {
												App.permissionPurDoctypes.push("creditnote");
											}
										}
									}

									App.plugins = {};

									_.each(corp.get('plugins'), function (plugin) {

										if (plugin.plugin) {
											App.plugins[plugin.plugin['syscode']] = plugin.plugin;
										}
									});

									// load translation - and when we have it, we init app startup
									App.initTranslations(App.currentStaff.get('uilang')).then(function (trad) {
										App.polyglot = trad;

										if (!App.sidebar) {
											App.sidebar = new Panel();
										} else {
											App.sidebar.render();
											App.sidebar.delegateEvents();
										}

										// app is accessible from console
										window.App = App;
										_this.startup();
									});
								});
							});
						}, function (err) {
							console.log(err);
						});
					});
				}).catch(function (e) {

					if (e === "E_SUBSCRIBE_HAVETO") {
						App.initTranslations((navigator.language || navigator.userLanguage).indexOf('fr') == 0 ? 'fr' : 'en').then(function (trad) {
							App.polyglot = trad;
							location.href = "#corpBlocked";
						});
					}
				});
			}, function (err) {
				logoutCallback();
				console.log(err);
			}).catch(function (e) {
				console.log(e);
			});
		};

		//start the app
		this.startup = function () {

			App.hasNetworkFunction = function () {

				var _this = this;

				/*
    * @desc check the network connection, display a notification if connection not working
    */
				var jqXHR = new Ajax({
					url: 'rest/main/network/check',
					method: 'POST'
				});

				jqXHR.done(function (data) {

					if (!App.isOnline) {

						$('.toast-offline').remove();

						new Notification({
							id: 'toast-info',
							timeDuration: 4000,
							content: App.polyglot.t('appNowConnected'),
							callback: function () {

								if (_.isFunction(App.view.enableWriteActions)) {
									App.view.enableWriteActions();
								}

								if (App.indexedDB) {
									DB['system'].put({ id: 1, isOnline: true });
								}

								clearInterval(App.hasNetworkInterval);
								App.hasNetworkInterval = setInterval(App.hasNetworkFunction.bind(App), App.defaultInterval);
							}

						});

						App.isOnline = true;

						if (App.currentStaff.get('isOfflineActivated') == 'N') {
							App.sellsyEasy.thirdRouter.navigate('', { trigger: true });
						}
					}

					if (App.indexedDB) {
						DB['system'].put({ id: 1, isOnline: true });
					}
				});

				jqXHR.fail(function (request, textStatus, error) {

					App.isOnline = false;

					if (App.indexedDB) {
						DB['system'].put({ id: 1, isOnline: false });
					}
				});
			};

			//start check network Interval
			App.hasNetworkInterval = setInterval(App.hasNetworkFunction.bind(App), App.defaultInterval);

			App.currencies = new Currencies();
			App.staffs = new Staffs();

			/**
    * @description get all data for stock them in localDB 
    * @returns {undefined}
    */
			var fetchAll = function () {

				var promises = [];

				return new Promise(function (resolve, reject) {

					if (App.isLogged) {

						//staffs promise
						promises.push(App.staffs.fetch({
							method: 'POST'
						}, { add: false }));

						//currencies promise
						promises.push(new Promise(function (resolve, reject) {

							App.currencies.fetchLocal().then(function () {

								if (App.currencies.length > 0) {
									resolve();
								} else {

									App.currencies.fetch({
										method: 'POST'
									}, { add: false }).then(function () {
										resolve();
									}, reject);
								}
							}, reject);
						}));

						Promise.all(promises).then(function () {
							if (navigator.isNative && window.cordova.getAppVersion) {

								window.cordova.getAppVersion().then(function (version) {

									App.nativeAppVersion = version;
									App.useActionSheet = true;

									if (Utils.versionCompare("" + version, "3.3") >= 0) {
										App.useOpener = cordova.plugins.disusered; //plugin for open file with external app
									}
								}, function () {
									console.log(e);
								});
							} else {
								App.useActionSheet = false;
								resolve();
							}

							App.isReady = true;
							Backbone.history.loadUrl(Backbone.history.location.hash);
							resolve();
						}, function () {
							console.log(arguments);
							reject();
						});
					}
				});
			};

			//init and configure popover
			$(function () {
				$('[data-toggle="popover"]').popover();
			});

			//fetch all at startup	
			fetchAll();
		};
	};

	return sellsyEasy;
});
