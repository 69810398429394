/**
 *  @desc Services Collection, extend Products Collection
 */
define('models/collections/items',['models/collections/products', 'models/singles/item', 'db/easy'], function (Products, Item, DB) {

	'use strict';

	return Products.extend({

		model: Item,
		storeName: 'product',
		name: 'products',
		url: '/rest/product/list',
		fields: ['name', 'unitAmount', 'notes'],
		type: 'item'

	});
});
