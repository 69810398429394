/**
 * @desc Product Overview, extend Page View
 * Child View : Core Overview
 */
define('views/setting/contactsSynchro',['underscore', 'views/core/page', 'views/core/form', 'app', 'models/collections/contactsSynchro', 'views/core/toastNotification', 'views/core/popupAction', 'tools/ajax', 'selectize', 'royalslider'], function (_, Page, Form, App, Contacts4Synchro, ToastNotification, PopupAction, Ajax) {

	'use strict';

	return Page.extend({

		events: _.extend({
			'click .removeRow': 'removeRow',
			'click .fa-showRow': 'showRow',
			'change select[name="groupedActions[checkall]"]': 'selectAllContacts',
			'change select[name="groupedActions[applythirdtype]"]': 'applythirdtype'

		}, Page.prototype.events),

		title: App.polyglot.t('synchronization'),
		availableThirdsTypes: [],
		toastMessages: {},
		actionList: {},

		/**
   * @desc initialize
   */
		initialize: function (router) {

			var _this = this;
			this.router = router;

			Page.prototype.initialize.apply(this, arguments);

			this.template = "templates/setting/contactsSynchro";

			this.options = {
				extraData: {
					canCreate: function () {
						return new Promise(function (resolve, reject) {
							resolve(_this.loadCanCreate());
						});
					}
				}
			};

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);

			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			Page.prototype.afterRender.apply(this, arguments);
			this.browseContacts();
			this.delegateEvents();
		},

		/**
   * 
   * @returns {contactsSynchro_L17.contactsSynchroAnonym$1.loadCanCreate.canCreate}
   */
		loadCanCreate: function () {

			// contains all third type user can create
			var canCreate = {};

			if (App.permission.can('createclient')) {
				canCreate['third'] = { type: 'third', label: App.polyglot.t('client') };
			}
			if (App.permission.can('createsupplier')) {
				canCreate['supplier'] = { type: 'supplier', label: App.polyglot.t('supplier') };
			}
			if (App.permission.can('createprospect')) {
				canCreate['prospect'] = { type: 'prospect', label: App.polyglot.t('prospect') };
			}
			if (App.permission.can('createpeople')) {
				canCreate['people'] = { type: 'people', label: App.polyglot.t('contact') };
			}

			return canCreate;
		},

		/**
   * 
   * @returns {undefined}
   */
		selectAllContacts: function (e) {
			var v_current = $(e.currentTarget);
			$('input[name$="[doimport]"]', this.$el).prop('checked', v_current.val() == 'check' ? true : false);
		},

		/**
   * 
   * @returns {undefined}
   */
		applythirdtype: function (e) {

			var v_current = $(e.currentTarget);
			var valueToApply = v_current.val();

			$('select[name$="[linkedtype]"] option[value=' + valueToApply + ']', this.$el).prop('selected', 'selected');
		},

		/**
   * 
   */
		browseContacts: function () {

			var onError = function () {
				console.log('error');
			};

			var options = new ContactFindOptions();
			options.filter = "";
			options.multiple = true;
			var fields = ["displayName", "name"];
			navigator.contacts.find(fields, this.showContact.bind(this), onError, options);
		},

		/**
   * 
   */
		showContact: function (contacts) {

			var _this = this;

			var cleanContacts = {};

			$.each(contacts, function (key, value) {

				var hasMobile = _.where(this.phoneNumbers, { type: "mobile" });

				if (this.name.formatted && this.phoneNumbers && !_.isEmpty(hasMobile)) {
					cleanContacts[key] = this;
				}
			});

			contacts = cleanContacts;

			_this.findTemplate().then(function (contactsList) {

				var templateContactsList = _.template(contactsList);
				var tmp = templateContactsList({ 'contacts': contacts, 'canCreate': _this.loadCanCreate(), 'polyglot': App.polyglot });
				$("#contacts-list").html(tmp);
			});
		},

		/**
   * 
   * @returns {Promise}
   */
		findTemplate: function () {

			return new Promise(function (resolve, reject) {

				Ajax({
					method: 'GET',
					url: 'templates/setting/rowsTemplate',
					dataType: "HTML",
					success: function (template) {
						resolve(template);
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		removeRow: function (e) {
			var v_row = $(e.currentTarget).closest('.row-listing');
			v_row.remove();
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		showRow: function (e) {
			var v_row = $(e.currentTarget).closest('.row-listing');
			this.showRowCallback(v_row);
		},

		/**
   * 
   */
		showRowCallback: function (v_row) {

			v_row.find('.rowDetail').slideToggle('fast');
			v_row.find('.rowDetail').toggleClass('showContactDetail');

			if (v_row.find('.rowDetail').hasClass('showContactDetail')) {
				v_row.find('.rowTitle .fa-caret').removeClass('fa-caret-up');
				v_row.find('.rowTitle .fa-caret').addClass('fa-caret-down');
			} else {
				v_row.find('.rowTitle .fa-caret').removeClass('fa-caret-down');
				v_row.find('.rowTitle .fa-caret').addClass('fa-caret-up');
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;
			var isOk = true;

			if (!dataForm.config) {
				isOk = false;
			}

			var contacts4Synchro = new Contacts4Synchro();

			_.each(dataForm, function (contact) {
				if (contact.doimport === 'Y') {
					contacts4Synchro.add(contact);
				}
			});

			if (contacts4Synchro.length > 0) {

				contacts4Synchro.each(function (contactM, index) {

					if (!contactM.isValid()) {

						isOk = false;
						console.log('non valid');

						//Make the input invalid in error
						_.each(contactM.validationError, function (error, keyError, arrayError) {

							if (!_.isEmpty(error)) {

								_.each(error, function (msgError, keyMsg) {
									_this.showValidationError(contactM.get('index'), msgError, _this);
								});
							}
						});
					}
				});
			} else {
				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');
				return false;
			}

			if (isOk) {

				var popup = new PopupAction({

					id: 'contacts-synchro-popup',
					title: App.polyglot.t('synchronization'),
					content: '<div class="popupLoader text-center">' + _this.fa_loader + '</div>',
					containerBtn: '.modal-footer',
					callback: function () {
						//remove material effect
						$('.ink').remove();
					}
				});

				// import in progress
				contacts4Synchro.synchronize(dataForm.config).then(function () {

					popup.content = '<div class="text-center">' + App.polyglot.t('synchronizationisover') + '</div>';
					popup.setContent();

					// add close btns now the import is done
					popup.addButtons({
						buttons: [{
							title: App.polyglot.t('close'),
							classes: "btn btn-submit button-with-network disabled",
							fa: "fa-times",
							onclick: function () {}
						}]
					});
				}, function () {

					// synchronize cant work if there are errors
					var options = {
						id: 'toast-error',
						classes: 'col-xs-12',
						timeDuration: 4000,
						content: App.polyglot.t('synchronizationErrorOccured')
					};

					new ToastNotification(options);
				});
			} else {

				// synchronize cant work if there are errors
				var options = {
					id: 'toast-error',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: App.polyglot.t('synchronizationErrorOccured')
				};

				new ToastNotification(options);
			}

			// re activate submit button
			$('.submit-form', this.form.$el).removeAttr('disabled');

			return false;
		},

		/**
   * 
   * @param {type} indexError
   * @param {type} messageError
   * @returns {undefined}
   */
		showValidationError: function (indexError, messageError, _this) {

			var errorLine = _this.$el.find('.error-' + indexError);
			errorLine.removeClass('hidden');
			errorLine.find('ul').append("<li>" + messageError + "</li>");

			if (!errorLine.closest('.rowDetail').hasClass('showContactDetail')) {
				this.showRowCallback(errorLine.closest('.row-listing'));
			}
		}

	});
});
