/**
 * Created by vnavarrete on 07/09/2017.
 *  @desc Nylas accounts Collection, extend core Collection
 */
define('models/collections/nylasAccounts',['models/core/collection', 'models/singles/nylasAccount'], function (Collection, NylasAccount) {

    'use strict';

    return Collection.extend({

        model: NylasAccount,
        url: '/rest/emailBox/nylasAccount/list',
        name: "nylasAccounts"

    });
});
