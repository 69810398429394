/**
 *	@desc email Model , extend Core Model 
 */
define('models/singles/email',['models/core/model', 'app', 'tools/ajax', 'tools/buildURL'], function (Model, App, Ajax, URLBuilder) {

	'use strict';

	return Model.extend({

		defaults: {},

		name: 'mail',
		storeName: 'email',
		urlRoot: '/rest/email/',

		/**
   * 
   * @returns {undefined}
   */
		validate: function (attributes) {

			var validatorRegEx = {
				email: /^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$/i
			};

			var validationErrors = { mail: {} };

			if (!attributes.mail.emails) {
				validationErrors['mail']['emails'] = this.computeError({ name: 'emails', type: 'required' });
			}

			if (_.toArray(validationErrors.mail).length > 0) {
				return validationErrors;
			}
		}

	}, {

		/**
   * 
   * @returns Promise
   */
		getCustomTemplates: function () {

			return new Promise(_.bind(function (resolve, reject) {

				Ajax({

					url: URLBuilder(['rest', 'email', 'customTemplates'], false),
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						rejecr(err);
					}

				});
			}, this));
		}

	});
});
