/**
 * @desc opportunity Form, extend Page View
 * Child View : Core Form
 */
define('views/prospection/opportunity/form',['backbone', 'underscore', 'views/core/page', 'models/singles/opportunity', 'models/collections/sources', 'models/collections/thirds', 'models/collections/prospects', 'views/core/form', 'app', 'tools/buildURL', 'moment', 'tinyMCE', 'tools/core/utils', 'models/collections/funnels', 'libs/plugins/placeholder/plugin'], function (Backbone, _, Page, Opportunity, Sources, Thirds, Prospects, Form, App, URLBuilder, Moment, TinyMCE, Utils, Funnels) {

	'use strict';

	return Page.extend({

		events: _.extend({
			'change .linkedtype': 'changeLinkedType',
			'change select[name="opportunity[stepid]"]': 'changeProba',
			'change select[name="opportunity[funnelid]"]': 'focusOnRightPipeline'
		}, Page.prototype.events),

		title: "",
		form: {},
		toastMessages: {
			opportunitySaved: App.polyglot.t('opportunitySaved'),
			opportunityUpdated: App.polyglot.t('opportunityUpdated')
		},

		/**
   *
   * @param {Router} router
   * @param {int} productId
   */
		initialize: function (router, id) {

			Page.prototype.initialize.apply(this, arguments);

			this.modelToSave = new Opportunity();

			if (id) {
				this.modelToSave.set({ id: id });
			}

			if (!this.modelToSave.isNew()) {
				this.title = this.polyglot.t('updateOpportunity');
			} else {

				this.title = this.polyglot.t('createOpportunity');

				if (App.permission.level("clientlevel")) {
					this.thirds = new Thirds();
					this.collections[this.thirds.name] = this.thirds;
				}

				if (App.permission.level("prospectlevel")) {
					this.prospects = new Prospects();
					this.collections[this.prospects.name] = this.prospects;
				}
			}

			var _this = this;
			this.template = "templates/opportunity/form.twig";
			this.router = router;
			this.funnels = new Funnels();
			this.sources = new Sources();
			this.collections[this.sources.name] = this.sources;
			this.collections[this.funnels.name] = this.funnels;
			this.options.extraData = {

				/**
     *
     * @returns {Promise}
     */
				currentIdent: function () {

					return new Promise(function (resolve, reject) {

						Opportunity.getCurrentIdent().then(function (ident) {
							resolve(ident);
						});
					});
				}

			};

			/**
    * if it's an update we fetch the opportunity and then the third for take his contacts
    */
			if (!this.modelToSave.isNew()) {

				this.options.extraData.opportunity = function () {

					return new Promise(function (resolve, reject) {

						_this.modelToSave.fetch().then(function () {

							var buildLinkedThird = new Promise(function (resolve, reject) {

								if (_this.modelToSave.get('relationType') === 'client') {

									require(['models/singles/third'], function (Third) {
										_this.third = new Third({ id: _this.modelToSave.get('linkedid') });
										resolve();
									});
								} else {

									require(['models/singles/prospect'], function (Prospect) {
										_this.third = new Prospect({ id: _this.modelToSave.get('linkedid') });
										resolve();
									});
								}
							});

							buildLinkedThird.then(function () {

								_this.third.bypassNotLoadable = true;
								_this.third.fetch().then(function () {
									resolve(_this.modelToSave.toJSON());
								});
							});
						});
					});
				};
			} else {
				this.models[this.modelToSave.name] = this.modelToSave;
			}

			this.form = new Form({ parentView: this });
			this.subviews.push(this.form);
			this.render();

			this.listenTo(this.form, 'dataSend', this.formAction);
		},

		/**
   *
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			if (this.modelToSave.isNew()) {

				this.modelIsNew = true;
				var thirdOptions = [];
				var contactOptions = [];
			} else {

				this.modelIsNew = false;

				if (!this.third.get('error')) {
					var thirdOptions = [this.third.toJSON()];
					var contactOptions = _.toArray(this.third.get('contacts'));
				} else {
					var thirdOptions = [];
					var contactOptions = [];
				}
			}

			this.selectizeObjects = [{
				domElt: $(".contact-selectize", this.$el),
				create: false,
				valueField: 'contactid',
				labelField: 'name',
				searchField: ['name', 'forename'],
				maxOptions: 10,
				maxItems: 99,
				options: contactOptions,
				placeholder: Utils.capitalizeFirstLetter(this.polyglot.t('contacts')),
				onChange: function (value) {}
			}, {
				domElt: $(".staff-selectize", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'name',
				options: App.staffs.toJSON(),
				searchField: ['name', 'forename'],
				maxOptions: 10,
				maxItems: 99,
				placeholder: Utils.capitalizeFirstLetter(this.polyglot.t('staffs')),
				onChange: function (value) {},
				render: 'staff'
			}, {
				domElt: $(".third-selectize", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'name',
				searchField: ['name'],
				options: thirdOptions,
				maxItems: 1,
				maxOptions: 10,
				loadThrottle: 600,
				onChange: function (value) {
					_this.populateContactsSelectize(value, _this.currentCollection);
				},
				load: this.getSelectizeLoader('third').bind(this),
				render: 'third'
			}];

			Page.prototype.afterRender.apply(this, arguments);

			this.focusOnRightPipeline();

			//DOM declarations
			this.$.thirdSelectize = $('.third-selectize', this.$el);
			this.$.contactSelectize = $('.contact-selectize', this.$el);
			this.$.staffSelectize = $('.staff-selectize', this.$el);

			this.thirdSelectizeInstance = this.$.thirdSelectize[0].selectize;
			var contactSelectize = this.$.contactSelectize[0].selectize;

			if (!this.modelIsNew) {

				this.thirdSelectizeInstance.lock();

				if (this.third.get('error')) {

					contactSelectize.lock();

					this.thirdSelectizeInstance.addOption({ name: this.modelToSave.get('linkedname'), id: 0 });
					this.thirdSelectizeInstance.setValue(0, true);
				} else {

					var third = this.third.toJSON();

					//if row not found we create it
					this.thirdSelectizeInstance.addOption(third);
					this.thirdSelectizeInstance.setValue(this.third.get('id'), true);

					//select current contacts
					if (!_.isEmpty(this.modelToSave.get('contacts'))) {

						var contactIds = [];

						_.each(this.modelToSave.get('contacts'), function (contact) {
							contactIds.push(contact.tpcid);
						});

						contactSelectize.setValue(contactIds);
					}
				}

				//Select current staff
				if (!_.isEmpty(this.modelToSave.get('staffsFullList'))) {

					var staffSelectize = this.$.staffSelectize[0].selectize;

					var staffIds = [];

					_.each(this.modelToSave.get('staffsFullList'), function (staff) {
						staffIds.push(staff.id);
					});

					staffSelectize.setValue(staffIds);
				}
			} else {
				if (!App.permission.level("prospectlevel")) {
					$('.third-type-selector').remove();
					$('input[value="third"]', this.$el).prop('checked', true).trigger('change');
				} else if (!App.permission.level("clientlevel")) {
					$('#prospect', '.third-type-selector').prop('checked', true).trigger('change');
					$('.third-type-selector').remove();
				} else {
					$('input[value="third"]', this.$el).prop('checked', true).trigger('change');
				}
			}

			this.initTinyMCE();
		},

		/**
   *
   */
		changeLinkedType: function (e) {

			var $currentTarget = $(e.currentTarget);
			var _this = this;

			$('.linkedtype').parent().toggleClass('focused');

			if ($currentTarget.val() === 'third') {

				this.currentCollection = this.thirds;
				this.thirdSelectizeInstance.settings.load = this.getSelectizeLoader('third');

				this.thirdSelectizeInstance.clearOptions();
				this.thirdSelectizeInstance.addOption(this.thirds.toJSON());

				$('[name="opportunity[linkedid]"]').parent().find('label').text(App.polyglot.t("client"));
			} else {

				this.currentCollection = this.prospects;
				this.thirdSelectizeInstance.settings.load = this.getSelectizeLoader('prospect');

				$('[name="opportunity[linkedid]"]').parent().find('label').text(App.polyglot.t("prospect"));
				this.thirdSelectizeInstance.clearOptions();
				this.thirdSelectizeInstance.addOption(this.prospects.toJSON());
			}
		},

		loadThirds: function (query, callback) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				_this.thirds.fetch({

					method: 'POST',
					data: {
						search: {
							contains: query
						}
					}

				}, true).then(function () {

					var resultArray = _.toArray(_this.thirds.toJSON());

					callback(resultArray);
					resolve(_this.thirds);
				});
			});
		},

		loadProspects: function (query, callback) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				_this.prospects.fetch({

					method: 'POST',
					data: {
						search: {
							contains: query
						}
					}

				}, true).then(function () {

					var resultArray = _.toArray(_this.prospects.toJSON());

					callback(resultArray);
					resolve(_this.prospects);
				});
			});
		},

		/**
   * @description choose the default or selected pipeline and show linked steps select
   * @returns {undefined}
   */
		focusOnRightPipeline: function (e) {

			if (!e) {
				//when its not triggered by a change event

				if (this.modelToSave.isNew()) {
					//focus on defaultPipeline

					if (this.funnels.length === 1) {
						var funnel = this.funnels.first();
					} else {
						var funnel = _.filter(this.funnels.toJSON(), function (funnel) {
							return funnel.isDefault === 'Y';
						});
						funnel = _.first(funnel);
					}

					$('select[name="opportunity[funnelid]"]', this.$el).val(funnel.id);
					var $currentStepSelect = $('select[data-funnelid="' + funnel.id + '"]', this.$el);
				} else {
					//focus on opp pipeline

					$('select[name="opportunity[funnelid]"]', this.$el).val(this.modelToSave.get("funnelid"));
					var $currentStepSelect = $('select[data-funnelid="' + this.modelToSave.get("funnelid") + '"]', this.$el);
					$currentStepSelect.val(this.modelToSave.get('stepid'));
				}
			} else {

				var $oldStepsSelect = $('select[name="opportunity[stepid]"]:visible', this.$el);
				var currentFunnelId = $(e.currentTarget).val();
				var $currentStepSelect = $('select[data-funnelid="' + currentFunnelId + '"]', this.$el);

				$oldStepsSelect.attr('data-catchable', 'N').closest('.form-group').addClass('hidden');
			}

			$currentStepSelect.trigger('change', [this]);
			$currentStepSelect.removeAttr('data-catchable').closest(".form-group").removeClass('hidden');
		},

		/**
   *
   * @returns {undefined}
   */
		initTinyMCE: function () {

			TinyMCE.init({
				selector: '#brief',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize placeholder",
				menubar: false,
				statusbar: false,
				content_style: 'html body *{color : #35596E ;font-size : 14px;}'
			});
		},

		/**
   *
   * @param {event} e
   * @returns {undefined}
   */
		changeProba: function (e, _this) {

			_this = _this ? _this : this;
			var $currentTarget = $(e.currentTarget);
			var stepId = $currentTarget.val();
			var currentFunnelId = $('select[name="opportunity[funnelid]"]').val();
			var currentStep = _this.funnels.get(currentFunnelId).get('steps')[stepId];

			$('input[name="opportunity[proba]"]', _this.$el).val(currentStep.proba);
		},

		/**
   *
   * @param {type} value
   * @param {type} collection
   * @returns {undefined}
   */
		populateContactsSelectize: function (value, collection) {

			if (value) {

				if (this.third) {
					var third = this.third;
				} else {
					var third = collection.get(value);
				}

				var contacts = third.get('type') === 'corporation' ? _.toArray(third.get('contacts')) : third.get('contact');
				var contactSelectize = this.$.contactSelectize[0].selectize;

				contactSelectize.clearOptions();
				contactSelectize.addOption(contacts);
				contactSelectize.refreshOptions(false);

				if (contacts.length === 1) {
					contactSelectize.setValue(contacts[0].contactid);
				}
			}
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			if (dataForm.opportunity['dueDate']) {
				dataForm.opportunity['dueDate'] = Utils.SellsyMoment(dataForm.opportunity['dueDate'], 'YYYY-MM-DD', true).unix();
			}

			this.modelToSave.clear();
			this.modelToSave.set(dataForm.opportunity);
			var isnew = _this.modelToSave.isNew();

			if (this.modelToSave.isValid()) {

				var toastMsg = this.modelToSave.isNew() ? this.toastMessages.opportunitySaved : this.toastMessages.opportunityUpdated;

				this.modelToSave.save().then(function () {

					_this.form.trigger('successful', toastMsg);
					delete App.cacheControl[URLBuilder(['opportunity', 'list', _this.modelToSave.get('funnelid'), ''])];
					_this.form.redirect(isnew, URLBuilder(["opportunity", "overview", _this.modelToSave.get('id')]));
				}, function () {

					// re activate submit button
					$('.submit-form', _this.form.$el).removeAttr('disabled');
				});
			} else {

				// re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
