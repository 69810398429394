/**
 * @desc Email Box Overview, extend Page View
 * Child View : Core Overview
 */
define('views/emailBox/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/emailThread', 'models/collections/messages', 'app', 'tools/buildURL', 'views/widgets/expendables', 'models/singles/emailDraft'], function (_, Page, Overview, EmailThread, Messages, App, URLBuilder, Expendables, EmailDraft) {

    'use strict';

    return Page.extend({

        id: 'emailbox-overview',
        events: _.extend({
            'click .mail-actions': 'goToMailForm',
            'click .mark-as-important': 'toggleStarred',
            'click .email-attachment': 'downloadAttachment'
        }, Page.prototype.events),

        popupConfirmOptions: {},
        listing: {},
        toastMessages: {
            email: {
                emailRemoved: App.polyglot.t("emailRemoved")
            }
        },

        actionList: {
            forwardTo: {
                fa: false,
                label: App.polyglot.t("forwardTo"),
                aClass: "forward mail-actions",
                link: function (e) {
                    this.goToMailForm(e, this.lastMessageId);
                },
                datas: {
                    'context': 'forward'
                }
            },
            replyTo: {
                fa: false,
                aClass: "reply mail-actions",
                label: App.polyglot.t("replyTo"),
                link: function (e) {
                    this.goToMailForm(e, this.lastMessageId);
                },
                datas: {
                    'context': 'reply'
                }

            }
            // replyAll : {
            //     fa		: false,
            //     aClass	: "reply mail-actions",
            //     label	: App.polyglot.t("replyAtAll"),
            //     link	: function(e){
            //         this.goToMailForm(e,this.lastMessageId);
            //     },
            //     datas : {
            //         'context' : 'replyAll'
            //     }
            //
            // },
        },

        /**
         * @desc initialize
         */
        initialize: function (router, box, id, linkedtype, linkedid, linkedname) {

            var _this = this;

            this.canDelete = true;
            this.canWrite = true;
            this.emailBox = box;

            if (linkedtype && linkedid) {
                this.linkedtype = linkedtype;
                this.linkedid = linkedid;
                this.linkedname = linkedname;
            }

            Page.prototype.initialize.apply(this, arguments);

            this.mail = new EmailThread({ id: id });
            this.mail.mustFetchLocal = true;
            this.messages = new Messages();
            this.messages.XHRData = {
                getAll: true,
                id: id
            };

            if (!App.nylasPrefs) {

                this.options.extraData = {

                    nylasPrefs: function () {

                        return new Promise(function (resolve, reject) {

                            EmailThread.getNylasPrefs().then(function (nylasPrefs) {
                                App.nylasPrefs = nylasPrefs;
                                resolve();
                            });
                        });
                    }

                };
            }

            this.collections[this.messages.name] = this.messages;
            this.models[this.mail.name] = this.mail;

            this.template = "templates/emailbox/overview.twig";
            this.overview = new Overview({ parentView: this }, this.router);

            this.subviews.push(this.overview);
            this.render();
        },

        /**
         *
         * @returns {undefined}
         */
        afterRender: function () {

            var _this = this;

            if (this.mail.get('unread') === 'Y') {

                this.mail.set({ unread: "N" });
                this.mail.saveLocal();
            }

            Page.prototype.afterRender.apply(this, arguments);

            this.expendables = new Expendables(this.$el, { defaultState: 'contracted' });
            this.subviews.push(this.expendables);

            var $lastMessage = $('.contracted', this.$el).first();

            $lastMessage.removeClass('contracted');

            this.lastMessageId = $lastMessage.data('id');

            var promises = [];

            $('iframe', this.$el).each(function () {

                promises.push(new Promise(_.bind(function (resolve) {

                    $(this).prop('srcdoc', _this.messages.get($(this).data('id')).get('body'));
                    $(this).on('load', function () {

                        console.log("la");

                        var h = this.contentWindow.document.body.scrollHeight + 10 + 'px';
                        $(this).height(h);

                        var head = $(this).contents().find("head");
                        $('<link/>', {
                            rel: "stylesheet",
                            type: "text/css",
                            href: "/ressources/css/iframe.css"
                        }).appendTo(head);

                        $('body', $(this).contents()).attr('data-id', $(this).data('id')).addClass($(this).prop('class')).css('overflow-y', 'hidden');
                        resolve();
                    });
                }, this)));
            });

            Promise.all(promises).then(function () {
                $('.email-attachments').removeClass('hidden');
            });
        },

        /**
         *
         * @returns {undefined}
         */
        goToMailForm: function (e, lastMessageId) {

            var context = $(e.currentTarget).data('context');
            var urlToMailForm = URLBuilder(["emailBox", "form", this.emailBox, context, lastMessageId]);

            this.router.navigate(urlToMailForm, { trigger: true });

            return false;
        },

        /**
         *
         * @returns {Boolean}
         */
        showPopupConfirm: function () {

            var callback = this.goToPreviousPage.bind(this);
            this.removeConfirm(this.mail, this.mail.get('subject'), this.$el, this.toastMessages.email.emailRemoved, callback);

            return false;
        },

        /**
         *
         */
        toggleStarred: function (e) {

            var $star = $(e.currentTarget);
            var newVal = $star.hasClass('fa-star-o') ? 'Y' : 'N';
            var _this = this;

            this.setLoader(this.$el);
            this.mail.toggleStarred(newVal).then(function () {

                $('.overlay').remove();

                if (newVal == 'Y') {
                    $star.removeClass('fa-star-o').addClass('fa-star');
                } else {
                    $star.removeClass('fa-star').addClass('fa-star-o');
                }
            });
        },

        /**
         *
         * @param e
         */
        downloadAttachment: function (e) {

            var _this = this;
            var $attachment = $(e.currentTarget);
            var fileId = $attachment.data('id');
            var messageId = $attachment.closest('.email-wrapper').data('id');
            var currentMsg = this.messages.get(messageId);
            var currentFile = _.find(currentMsg.get('files'), function (file) {
                return file.id == fileId;
            });

            $attachment.append(_this.formActionSetLoader());

            EmailThread.getAttachment(fileId, currentFile.content_type).then(function (blobFile) {

                $('.inprogress-mask').remove();

                if (navigator.isNative && App.useOpener) {

                    var saveFile = function (dirEntry, fileData, fileName) {
                        dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {

                            console.log(fileEntry, fileData);

                            writeFile(fileEntry, fileData);
                        }, function () {});
                    };

                    var writeFile = function (fileEntry, dataObj, isAppend) {

                        // Create a FileWriter object for our FileEntry
                        fileEntry.createWriter(function (fileWriter) {

                            fileWriter.onwriteend = function () {
                                cordova.plugins.disusered.open(decodeURI(fileEntry.toURL()));
                            };

                            fileWriter.onerror = function (e) {
                                console.log("Failed file write: " + e.toString());
                            };

                            fileWriter.write(dataObj);
                        });
                    };

                    var cacheDir = navigator.os === 'android' ? cordova.file.externalCacheDirectory : cordova.file.tempDirectory;

                    window.resolveLocalFileSystemURL(cacheDir, function (fs) {
                        saveFile(fs, blobFile, currentFile.filename);
                    });
                } else {

                    var objectURL = URL.createObjectURL(blobFile);
                    saveAs(blobFile, currentFile.filename);
                }
            });
        }

    });
});
