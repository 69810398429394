/**
 * Child View : Core Listing
 */
define('views/briefcase/list',['underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'models/collections/files', 'app', 'views/core/popupAction', 'views/core/toastNotification', 'models/singles/filePicture', 'views/widgets/nativeActionSheet', "tools/core/utils"], function (_, PageList, Listing, URLBuilder, Files, App, PopupAction, ToastNotif, FilePicture, ActionSheet, Utils) {

	'use strict';

	return PageList.extend({

		events: {
			'change #fileToUpload': function (e) {

				var _this = this;
				var file = e.currentTarget.files[0];
				var form_data = new FormData($(e.currentTarget).closest('form')[0]);

				form_data.append('fileToUpload', file);

				var modelToSave = new FilePicture({
					file: file,
					linkedid: $('input[name="linkedid"]', 'form'),
					linkedtype: $('input[name="linkedtype"]', 'form'),
					formData: form_data
				});

				if (!modelToSave.isValid()) {

					new ToastNotif({
						'id': 'toast-error',
						'content': App.polyglot.t(modelToSave.validationError),
						classes: 'col-xs-12',
						timeDuration: 3000
					});

					$("#fileToUpload").val("");
				} else {

					var uploadFunction = function () {

						_this.setLoader($('.page'));

						//dislay an upload progress bar
						$('.overlay').append('<div id="upload-progress-label">' + App.polyglot.t('uploadInProgress') + '</div>');
						$('.overlay').append("<div id='upload-progress'></div>");

						var $uploadProgress = $('#upload-progress');

						modelToSave.on('change:uploadProgress', function () {
							$uploadProgress.html(modelToSave.get('uploadProgress') + '%');
						});

						modelToSave.save(form_data).then(function () {

							new ToastNotif({
								id: 'toast-succes',
								'content': App.polyglot.t('fileUploadSuccess'),
								classes: 'col-xs-12',
								timeDuration: 3000
							});

							_this.render();
						});
					};

					if (App.useActionSheet) {

						new ActionSheet({
							title: App.polyglot.t('doYouWantUploadThisFile'),
							buttons: [{
								title: _this.polyglot.t('add'),
								callback: uploadFunction
							}],
							cancelLabel: App.polyglot.t('cancel')
						});
					} else {

						//init popup for upload confirmation
						var popup = new PopupAction({
							id: 'uploadConfirmation',
							title: App.polyglot.t('doYouWantUploadThisFile'),
							content: "<div class='color-cyan'>" + file.name + "</div>",
							buttons: [{
								title: App.polyglot.t('yes'),
								classes: "btn button-with-network btn-submit",
								onclick: uploadFunction
							}, {
								title: App.polyglot.t('no'),
								classes: "btn button-with-network btn-cancel btn-blue",
								onclick: function () {
									$("#fileToUpload").val("");
									popup.hide();
								}
							}],
							callback: function () {
								$('li.active').removeClass('active');
							},
							containerBtn: '.modal-footer'
						});
					}
				}
			},
			'click .row-listing': function (e) {

				e.stopPropagation();

				//get file infos in collection
				var fileId = $(e.currentTarget).data('id');
				var fileInfos = this.collection.get(fileId);
				var url = App.fileUrl + fileInfos.get('public_url');
				var _this = this;

				if (navigator.isNative && App.useOpener) {

					var download = function (fileEntry, uri) {

						var fileTransfer = new FileTransfer();
						var fileURL = fileEntry.toURL();

						fileTransfer.download(uri, fileURL, function (entry) {

							cordova.plugins.disusered.open(decodeURI(fileURL), function () {
								$('.overlay').remove();
							}, function () {
								$('.overlay').remove();
							}, true);
						}, function (e) {}, true, null // or, pass false
						);
					};

					var cacheDir = navigator.os === 'android' ? cordova.file.externalCacheDirectory : cordova.file.tempDirectory;

					window.resolveLocalFileSystemURL(cacheDir, function (fs) {

						_this.setLoader($('.page'), 0.6);

						// Make sure you add the domain name to the Content-Security-Policy <meta> element.
						// Parameters passed to getFile create a new file or return the file if it already exists.
						fs.getFile(encodeURI(fileInfos.get('file_fullname')), { create: true, exclusive: false }, function (fileEntry) {
							download(fileEntry, url);
						}, function (e) {
							$('.overlay').remove();
							console.log(e);
						});
					}, function (e) {
						console.log(arguments);
					});

					return false;
				}
			}
		},
		name: 'briefcase',
		params: {},
		title: App.polyglot.t('briefcase'),
		listing: {},
		placeholderSearchBar: App.polyglot.t('fileName'),
		template: "templates/briefcase/list.twig",
		hideFilterPannel: true,
		hideSearchIcon: true,
		canAdd: true,
		addAction: function () {
			$("#fileToUpload").trigger('click');
			setTimeout(function () {
				$('li.active').removeClass('active');
			}, 1);
		},

		/**
   * @desc initialize
   */
		initialize: function (router, linkedtype, linkedid) {

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.collection = new Files();
				this.collection.XHRData = {
					linkedtype: linkedtype,
					linkedid: linkedid
				};

				this.var4template("linkedid", linkedid);
				this.var4template("linkedtype", linkedtype);

				this.listing = new Listing({ parentView: this });
				this.subviews.push(this.listing);

				this.render();
			}, this));
		}

	});
});
