/**
 * @desc timetracking router, extend Core Router
 */
define('routers/timetracking',['backbone', 'underscore', 'routers/core/router', 'app', 'tools/buildURL'], function (Backbone, _, CoreRouter, App, URLBuilder) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "timetracking",
		plugin: "timetracking",

		checkOnline: {
			form: true
		},

		routes: {
			'timetracking/list(/:owner)(/:linkedtype)(/:linkedid)': 'listing',
			'timetracking/form(/)(:id)(/:linkedtype)(/:linkedid)': 'form'
		},

		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @param {type} comefrom
   * @returns {Promise}
   */
		listing: function (owner, linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/timetracking/list'], function (require, Timetracking) {

					var view = new Timetracking(_this, owner, linkedtype, linkedid, _this.getAvailableActions(linkedtype, linkedid));
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} id
   * @returns {undefined}
   */
		form: function (id, linkedtype, linkedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			var id = !id ? 0 : id;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/timetracking/form'], function (require, Timetracking) {

					var view = new Timetracking(_this, id, linkedtype, linkedid);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
  *
   */
		getAvailableActions: function (linkedtype, linkedid) {

			var extraParams = '';

			if (linkedtype && linkedid) {

				var allLink = URLBuilder(['timetracking', 'list', 'all', linkedtype, linkedid]);
				var myentriesLink = URLBuilder(['timetracking', 'list', 'myentries', linkedtype, linkedid]);
			} else {

				var allLink = URLBuilder(['timetracking', 'list', 'all']);
				var myentriesLink = URLBuilder(['timetracking', 'list', 'myentries']);
			}

			var links = [{
				name: App.polyglot.t('myentries'),
				link: myentriesLink
			}, {
				name: App.polyglot.t('all'),
				link: allLink
			}];

			return links;
		}

	});
});
