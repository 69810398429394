/**
 *	@desc purCreditNote Model , extend Core Model 
 */
define('models/singles/purCreditNote',['models/singles/purchase', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			doctype: 'creditNote',
			availableSteps: ['draft', 'stored', 'partialspend', 'spent', 'cancelled']
		},

		urlRoot: '/rest/purchase/creditNote',

		isStepAllowed: function (nextStep) {

			var currentStep = this.get('step');
			this.stepError = '';

			if (currentStep === 'spent' || currentStep === 'partialspend' && nextStep !== 'spend') {
				this.stepError = "Pour changer le statut d'un avoir payé ou en cours de paiement, commencez par supprimer le(s) remboursement(s) rattaché(s). Pour ce faire, cliquez sur liste des remboursements";
			}
		}

	});
});
