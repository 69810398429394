/**
 * @desc prospect Form, extend ThirdForm View
 * Child View : Core Form
 */
define('views/prospection/prospect/form',['backbone', 'underscore', 'views/third/form', 'views/core/form', 'views/core/toastNotification', 'tools/buildURL', 'app'], function (Backbone, _, ThirdForm, Form, ToastNotification, URLBuilder, App) {

	'use strict';

	return ThirdForm.extend({

		events: _.extend({
			"click .type": 'checkTypeAction'
		}, ThirdForm.prototype.events),

		title4Create: App.polyglot.t('addProspect'),
		title4Update: App.polyglot.t('updateProspect'),
		form: {},
		linked: 'prospect',
		template: "templates/prospect/form.twig",
		toastMessages: {
			thirdSaved: App.polyglot.t('prospectSaved'),
			thirdUpdated: App.polyglot.t('prospectUpdated')
		}

	});
});
