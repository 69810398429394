/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/shippings',['models/core/collection', 'models/singles/shipping'], function (Collection, Shipping) {

	'use strict';

	return Collection.extend({

		model: Shipping,
		storeName: 'shipping',
		name: 'shippings',
		url: '/rest/shipping/list',
		fields: ['name'],
		canBeStored: true
	});
});
