/**
 *  @desc Warehouse Collection, extend core Collection
 */
define('models/collections/warehouses',['models/core/collection', 'models/singles/warehouse'], function (Collection, Warehouse) {

	'use strict';

	return Collection.extend({
		model: Warehouse,
		storeName: 'warehouse',
		name: 'warehouses',
		url: '/rest/warehouse/list',
		fields: ['label']
	});
});
