/**
 *	@desc DocLayout Model , extend Core Model 
 */
define('models/singles/docLayout',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'docLayout',
		name: 'docLayout',
		urlRoot: '/rest/doclayout'

	});
});
