/**
 * @desc People router, extend Core Router
 */
define('routers/people',['backbone', 'underscore', 'routers/core/router'], function (Backbone, _, CoreRouter) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "contacts",

		routes: {

			'people/list(/)(:comefrom)': 'listing',
			'people/overview/:id': 'overview',
			'people/form(/)(:thirdid)(/)(:relationtype)(/)(:peopleid)(/:comefrom)': 'form'

		},

		initialize: function () {

			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		listing: function (comefrom) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/people/list', 'models/collections/peoples'], function (require, PeopleList, Peoples) {

					var links = App.sellsyEasy.thirdRouter.getAvailableActions();
					var peoples = new Peoples();
					var view = new PeopleList(_this, peoples, links);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		overview: function (peopleId) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/people/overview'], function (require, PeopleOverview) {

					var view = new PeopleOverview(_this, peopleId);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		},

		/**
   * 
   * @param {type} thirdid
   * @param {type} relationtype
   * @param {type} peopleId
   * @returns {undefined}
   */
		form: function (thirdid, relationtype, peopleId, comeFrom) {

			var trueRoute = Backbone.history.location.hash;
			var _this = this;
			peopleId = !peopleId ? 0 : peopleId;
			thirdid = !thirdid ? 0 : thirdid;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/people/form'], function (require, PeopleForm) {

					var view = new PeopleForm(_this, thirdid, relationtype, peopleId, comeFrom);
					view.trueRoute = trueRoute;
					resolve(view);
				});
			});
		}

	});
});
