/**
 *	@desc Model Invoice, extend Document Model 
 */
define('models/singles/invoice',['models/singles/document', 'app'], function (Document, App) {

	'use strict';

	return Document.extend({

		defaults: _.extend({
			id: 0,
			doctype: 'invoice',
			ident: '',
			thirdName: '',
			formattedDocType: 'facture',
			availableSteps: ['draft', 'due', 'payinprogress', 'paid', 'late', 'cancelled']
		}, Document.prototype.defaults),

		urlRoot: '/rest/document/invoice',

		/**
   * 
   * @returns {Boolean}
   */
		isEditable: function () {

			var currentStep = this.get('step');

			if (App.strictMode) {
				return currentStep === 'draft';
			} else {
				return "paid" !== currentStep && "payinprogress" !== currentStep;
			}
		},

		/**
   * 
   * @param {type} nextStep
   * @returns {Boolean}
   */
		isStepAllowed: function (nextStep) {

			var currentStep = this.get('step');
			this.stepError = '';

			if ((nextStep === 'paid' || nextStep === 'payinprogress') && !App.permission.can('writepayments')) {
				this.stepError = App.polyglot.t("youHaveNotTheRightForRecordPayment");
			}

			//Prevent payment recording for payadate deadline
			if ((nextStep === 'paid' || nextStep === 'payinprogress') && this.get('paydate').syscode === "deadlines") {
				this.stepError = App.polyglot.t("cantChangeStatusToPaidWithDeadlines"); //Vous ne pouvez enregistrer de paiements sur un document avec des échéances de paiement multiples
				return false;
			}

			//do this check only in strict mod
			if (App.strictMode) {

				if (nextStep === 'draft') {
					this.stepError = App.polyglot.t("cantReturnToDraftStep");
				}

				if (nextStep === 'cancelled' && currentStep !== 'draft') {
					this.stepError = App.polyglot.t("invoiceCanBeCancelledOnlyInDraft");
				}

				if (currentStep === 'cancelled') {
					this.stepError = App.polyglot.t("cantChangeStepOfCancelledDoc");
				}
			}

			switch (currentStep) {

				case 'late':

					if (nextStep === 'draft') {
						this.stepError = App.polyglot.t("cantpPassLateToDraft"); //Une facture en retard ne peux plus passer en brouillon
					} else if (nextStep === 'due') {
						this.stepError = App.polyglot.t("cantPassLateToDue"); //Une facture en retard ne peux plus passer en à régler
					}

					break;

				case 'payinprogress':

					if (nextStep !== 'paid') {
						this.stepError = App.polyglot.t("CantChangePaidStatus"); //Pour changer le statut d'une facture payée ou en cours de paiement, commencez par supprimer le(s) paiement(s) rattaché(s). Pour ce faire, allez sur la liste des paiements
					}

					break;

				case 'paid':

					this.stepError = App.polyglot.t("CantChangePaidStatus"); //Pour changer le statut d'une facture payée ou en cours de paiement, commencez par supprimer le(s) paiement(s) rattaché(s). Pour ce faire, allez sur la liste des paiements

					break;

			}

			return this.stepError ? false : true;
		}

	});
});
