/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/staffs',['models/core/collection', 'models/singles/staff', 'db/easy'], function (Collection, staff, DB) {

	'use strict';

	return Collection.extend({

		model: staff,
		storeName: 'staff',
		name: 'staff',
		url: '/rest/staff/getAll',
		fields: ['id'],

		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.add(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		}
	});
});
