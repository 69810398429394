/**
 * @description filePicture Model , extend File Model
 */
define('models/singles/filePicture',['models/singles/file', 'app', 'tools/core/utils'], function (File, App, Utils) {

    'use strict';

    return File.extend({

        validate: function (attributes) {

            var fileError = File.prototype.validate.apply(this, arguments);

            if (fileError) {
                return fileError;
            } else {

                //check if file is picture
                if (!Utils.inArray(attributes.file.type, App.mimeTypeImage)) {
                    return 'picture_type_error';
                }
            }
        }

    });
});
