/**
 *	@desc purDelivery Model , extend Core Model 
 */
define('models/singles/purDelivery',['models/singles/purchase', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			doctype: 'delivery',
			availableSteps: ['draft', 'sent', 'read', "partialinvoiced", "invoiced"]
		},

		urlRoot: '/rest/purchase/delivery'

	});
});
