/**
 *  @desc Third Collection, extend core Collection
 */
define('models/collections/thirds',['models/core/collection', 'models/singles/third'], function (Collection, Third) {

	'use strict';

	return Collection.extend({

		limitperPageOffline: 20,
		offsetOffline: 0,
		localdataSearch: '',
		model: Third,
		storeName: 'third',
		relationType: 'client',
		name: 'thirds',
		listName: "linkeds",
		url: '/rest/third/list',
		fields: ['name', 'email', 'tel', 'mobile'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {

			var _this = this;

			Collection.apply(this, arguments);

			this.offlineQueryArgs = ['relationType', 'type'];
			this.orderBy = 'asc';
			this.orderArg = 'fullName';

			this.XHRData = {
				search: {
					relationType: _this.relationType
				}
			};
		}

	});
});
