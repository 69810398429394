/**
 * @desc Product Overview, extend ToggleablePage View
 * Child View : Core Overview
 */
define('views/product/stockInfos',['underscore', 'views/core/ToggleablePage', 'models/singles/item', 'models/singles/service', 'views/core/overview', 'tools/buildURL', 'app'], function (_, ToggleablePage, Item, Service, Overview, URLBuilder, App) {

	'use strict';

	return ToggleablePage.extend({

		id: 'stockInfos',
		events: _.extend({}, ToggleablePage.prototype.events),

		actionList: {},

		/**
   * @desc initialize
   */
		initialize: function (router, options) {

			ToggleablePage.prototype.initialize.apply(this, arguments);

			this.overview = new Overview({ parentView: this, template: 'templates/product/stock.twig' }, this.router);
			this.subviews.push(this.overview);
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {
			ToggleablePage.prototype.afterRender.apply(this, arguments);
		},

		/**
   * @desc action when we click on return arrow and when back is pressed for native app
   * @param {type} e
   * @returns {Boolean}
   */
		backHandler: function (e) {
			this.$el.trigger('hide');
			return false;
		},

		/**
   * 
   * @returns {undefined}
   */
		onShow: function () {
			var _this = this;
			$('#action-list').hide();
			$('.return', '.icon-wrapper-left').off();
			$('.return', '.icon-wrapper-left').one('click', this.backHandler.bind(this));
		},

		onHide: function () {
			this.renderHeader();
			$('#action-list').show();
		}

	});
});
