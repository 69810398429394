
/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/groups',['models/core/collection', 'models/singles/group', "tools/core/utils", "app"], function (Collection, Group, Utils, App) {

	'use strict';

	return Collection.extend({

		model: Group,
		storeName: 'groups',
		name: 'groups',
		url: '/rest/staff/groups',
		fields: [],

		/**
   * 
   * @returns {undefined}
   */
		buildStaffs4groups: function () {

			var staffsList = [];
			var staffIdInGroup = [];

			//build staffs list with staff group
			this.each(function (group) {

				_.each(group.get('staffs'), function (staff) {

					var tempStaff = App.staffs.get(staff.staffid);

					if (tempStaff) {
						tempStaff = tempStaff.toJSON();
						tempStaff['groupid'] = group.id;
						tempStaff['buildid'] = staff.staffid + '_' + group.id;

						staffsList.push(tempStaff);
						staffIdInGroup.push(staff.staffid);
					}
				});
			});

			this.add({ id: 1, name: App.polyglot.t("withoutGroup") });

			//take  staffs without group
			App.staffs.each(function (staff) {

				if (!Utils.inArray("" + staff.id, staffIdInGroup)) {

					var tempStaff = staff;
					tempStaff = tempStaff.toJSON();
					tempStaff['groupid'] = 1;
					tempStaff['buildid'] = staff.id + '_' + 1;

					staffsList.push(tempStaff);
				}
			});

			return staffsList;
		}

	});
});
