define('views/widgets/hammerView',['underscore', 'views/core/view', 'hammerjs', 'libs/hammer-time.min'], function (_, View, Hammer) {

	return View.extend({

		/**
   * 
   * @returns {undefined}
   */
		initialize: function (domSelector, options) {

			this.setElement($(domSelector));

			this.hammerManager = options.hammerManager;
			this.transform = {};
			this.ticking = false;

			if (options.startY !== undefined) {
				this.startY = options.startY;
				this.verticalPan = true;
			} else {
				this.startX = options.startX;
			}
		},

		/**
   * 
   */
		updateElementTransform: function (referer, delta, callback) {

			if (this.verticalPan) {
				var value = 'translateY(' + (_.isUndefined(delta) ? this.transform.translateY : delta) + 'px)';
			} else {
				var value = 'translateX(' + (_.isUndefined(delta) ? this.transform.translateX : delta) + 'px)';
			}

			if (referer) {
				referer.style.webkitTransform = value;
				referer.style.mozTransform = value;
				referer.style.transform = value;
			} else {
				this.el.style.webkitTransform = value;
				this.el.style.mozTransform = value;
				this.el.style.transform = value;
			}

			if (callback) {
				$(this.el).one('transitionend', callback);
			}
			this.ticking = false;
		},

		/**
   * 
   * @returns {undefined}
   */
		resetElement: function (referer) {

			if (this.verticalPan) {
				this.transform.translateY = this.startY;
			} else {
				this.transform.translateX = this.startX;
			}
			this.requestElementUpdate(referer);
		},

		/**
   * 
   * @returns {undefined}
   */
		requestElementUpdate: function (referer, delta, callback) {

			var reqAnimationFrame = function () {
				return window[Hammer.prefixed(window, 'requestAnimationFrame')] || function (callback) {
					window.setTimeout(callback, 1000 / 60);
				};
			}();

			if (!this.ticking) {
				reqAnimationFrame(this.updateElementTransform.bind(this, referer, delta, callback));
				this.ticking = true;
			}
		}

	});
});
