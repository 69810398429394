/**
 * @description DocumentFilterPanel View
 * @extend FilterPanel View
 * @childView : core Form
 */
define('views/filterPanels/documentsFilterPanel',['underscore', 'views/filterPanels/filterPanel', 'app', 'tools/core/utils', 'models/collections/filters', 'models/collections/thirds', 'models/collections/smarttags'], function (_, FilterPanel, App, Utils, ListingFilters, Thirds, Smarttags) {

	'use strict';

	return FilterPanel.extend({

		/**
   * @desc initialize
   */
		initialize: function (router, listName) {

			var reg = new RegExp("third_.*");

			if (reg.test(listName)) {
				this.doctype = listName.split('_')[1];
				this.isLinkedListing = true; //we come from  third linkedDoc list
			} else {
				this.doctype = listName;
			}

			switch (this.doctype) {

				case 'invoice':
					var linkedNameForSavedSearch = "docinvoices";
					break;

				case 'estimate':

					var linkedNameForSavedSearch = "docestimates";
					break;

				case 'order':

					var linkedNameForSavedSearch = "docorders";
					break;

				case 'delivery':

					var linkedNameForSavedSearch = "docdeliveries";
					break;

				case 'creditnote':

					var linkedNameForSavedSearch = "doccreditnotes";
					break;

				case 'proforma':

					var linkedNameForSavedSearch = "docproformas";
					break;

			}

			this.thirds = new Thirds();
			this.smartTags = new Smarttags();
			this.smartTags.fetchOneTime = true;
			this.smartTags.url = '/rest/smarttags/search';
			this.smartTags.XHRData = {
				search: Smarttags.buildFilterByLinked(this.doctype)
			};

			this.collections[this.smartTags.name] = this.smartTags;
			this.title = App.polyglot.t(listName === 'delivery' ? 'deliveries' : listName + 's');

			FilterPanel.prototype.initialize.apply(this, [router, listName, linkedNameForSavedSearch]);
		},

		/**
   * 
   * @returns {undefined}
   */
		buildFilters: function () {

			var _this = this;

			var filterList = {};
			var formatted_steps = [];

			_.each(App[this.doctype + 'Steps'], function (step) {
				formatted_steps.push({
					label: App.polyglot.t(step),
					id: step
				});
			});

			filterList = _.extend(filterList, {
				steps: {
					label: App.polyglot.t('status'),
					field: {
						type: 'input',
						isMultiple: true,
						name: 'steps',
						offlineSearchField: 'step',
						classes: "offline-selectize",
						selectize: {
							domElt: 'input[name="filter[steps]"]',
							placeholder: App.polyglot.t('status'),
							create: false,
							valueField: 'id',
							labelField: 'label',
							options: formatted_steps
						}
					}
				},
				client: {
					label: App.polyglot.t('client'),
					field: {
						type: 'input',
						name: 'thirds',
						offlineSearchField: 'thirdid',
						classes: "offline-selectize",
						isMultiple: true,
						selectize: {
							domElt: 'input[name="filter[thirds]"]',
							placeholder: App.polyglot.t('name'),
							create: false,
							valueField: 'id',
							labelField: 'name',
							searchField: ['name', 'forename'],
							maxItems: '1',
							maxOptions: '1',
							loadThrottle: 600,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.thirds.fetch({

										method: 'POST',
										data: { search: { contains: query } }

									}, true).then(function () {
										resolve(_this.thirds);
									});
								});
							},
							render: 'third'
						}
					}
				},
				smarttags: {
					label: App.polyglot.t('smarttags'),
					field: {
						type: 'input',
						name: 'tags',
						offlineSearchField: 'smarttags',
						classes: "smarttags-selectize",
						isMultiple: false,
						selectize: {
							domElt: 'input[name="filter[tags]"]',
							placeholder: App.polyglot.t('smarttags'),
							create: false,
							valueField: 'word',
							labelField: 'word',
							searchField: ['word'],
							loadThrottle: 600,
							options: this.smartTags,
							load: function (query, callback) {

								return new Promise(function (resolve, reject) {

									_this.smartTags.fetch({

										method: 'POST',
										data: { search: _.extend({ contains: query }, _this.smartTags.XHRData.search) }

									}, true).then(function () {
										resolve(_this.smartTags);
									});
								});
							}
						}

					}
				}

			});

			if (this.isLinkedListing) {
				delete filterList['client'];
			}

			this.filters = filterList;

			return this.filters;
		}

	});
});
