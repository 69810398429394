/**
 * @desc Purchase List, extend PageList View
 * Child View : Core Listing
 */
define('views/purchase/list',['underscore', 'views/core/pageList', 'models/collections/purchases', 'models/singles/purchase', 'views/core/listing', 'app'], function (_, PageList, Purchases, Purchase, Listing, App) {

	'use strict';

	return PageList.extend({

		title: "",
		listing: {},
		toastMessages: {
			document: {
				documentRemoved: App.polyglot.t('documentRemoved')
			}
		},

		/**
   * @desc initialize
   */
		initialize: function (router, doctype, links) {

			var purDoctype = Purchase.toPurDoctype(doctype);
			this.name = purDoctype;
			this.canAdd = App.permission.can("purchases_create" + purDoctype);
			this.canDelete = App.permission.can("purchases_delete" + purDoctype);
			this.canEdit = App.permission.can("purchases_edit" + purDoctype);
			this.availableActions = links;

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				this.var4template('doctype', doctype);

				Purchases.classNameByDoctype(purDoctype).then(_.bind(function (ClassName) {

					this.subviews = [];
					this.router = router;
					this.collection = new ClassName();
					this.collection.XHRData = {
						doctype: doctype
					};

					this.collections[this.collection.name] = this.collection;
					this.placeholderSearchBar = App.polyglot.t('docIdent');
					this.template = "templates/purchase/list.twig";
					this.doctype = doctype;

					switch (doctype) {

						case "invoice":

							this.title = App.polyglot.t('supplierInvoices');
							break;

						case "order":

							this.title = App.polyglot.t('orders');
							break;

						case "delivery":

							this.title = App.polyglot.t('deliveries');
							break;

						case "creditNote":

							this.title = App.polyglot.t('supplierCreditNotes');
							break;
					}

					this.listing = new Listing({ parentView: this });
					this.subviews.push(this.listing);
					this.render();
				}, this));
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canDelete || this.canEdit) {

				//init material effect on each row of listing
				this.listing.initSwipeEffect({

					removeMsg: this.toastMessages.document.documentRemoved,
					onModify: false,
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					},
					popupid: 'doc-option',
					modelName: "ident",
					getModel: function ($currentTarget) {
						var id = $currentTarget.data('id');
						return _this.collection.get(id);
					}
				}, '', this);
			}

			//we cant add purchase doc on mobile 
			$('.add-action', this.$el).remove();
		}

	});
});
