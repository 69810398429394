/**
 *	@desc Model Lang, extend Core Model 
 */
define('models/singles/lang',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			value: ''
		},

		storeName: 'lang',
		name: 'lang',
		urlRoot: '/rest/main'

	});
});
