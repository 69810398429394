/**
 *  @desc Unities Collection, extend core Collection
 */
define('models/collections/currencies',['models/core/collection', 'models/singles/currency', 'db/easy'], function (Collection, Currency, DB) {

	'use strict';

	return Collection.extend({

		model: Currency,
		storeName: 'currency',
		name: 'currencies',
		url: '/rest/currency/list',
		fields: ['value'],
		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.add(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		}
	});
});
