/**
* @desc People Overview, extend PageOverview View
* Child View : Core Overview
*/

define('views/people/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'models/singles/address', 'models/singles/people', 'views/core/popupAction', 'tools/buildURL', 'tools/materialEffect', 'app', 'views/core/toastNotification', 'tools/core/utils', 'views/widgets/tabs'], function (_, PageOverview, Overview, Address, People, PopupAction, URLBuilder, initMaterialEffect, App, Toast, Utils, Tabs) {

	'use strict';

	return PageOverview.extend({

		events: _.extend({
			'click #linkedAddresses .btn-create-addr': 'addressForm',
			'click #linkedAddresses .fa-envelope-o': 'changeMainDelivAddr',
			'click #linkedAddresses .fa-star-o': 'changeMainAddr',
			'click .linktoform': 'goToPeopleForm'

		}, PageOverview.prototype.events),

		template: 'templates/people/overview.twig',
		title: "",
		overview: {},
		toastMessages: {
			people: {
				contactUpdated: App.polyglot.t("contactUpdated"),
				contactSaved: App.polyglot.t("contactSaved"),
				contactDeleted: App.polyglot.t("contactDeleted")
			},
			address: {
				addressUpdated: App.polyglot.t("addressUpdated"),
				addressSaved: App.polyglot.t("addressSaved"),
				addressDeleted: App.polyglot.t("addressDeleted"),
				mainDelivAddressChanged: App.polyglot.t("mainDelivAddressChanged"),
				mainAddressChanged: App.polyglot.t("mainAddressChanged")
			}
		},

		customFieldFilter: {
			useOn: 'people',
			linkedtype: 'people'
		},

		/**
   * 
   * @param {Router} router
   * @param {int} thirdId
   */
		initialize: function (router, peopleId) {

			// customFields filter
			this.customFieldFilter.linkedid = peopleId;

			this.canAdd = App.permission.can('createpeople');
			this.canEdit = App.permission.can('editpeople');
			this.canDelete = App.permission.can('deletepeople');

			this.smartTagsFilter = {
				linkedtype: 'people',
				linkedid: peopleId,
				category: 'third',
				categoryForIdb: 'Société / Contact'
			};

			PageOverview.prototype.initialize.apply(this, arguments);

			this.people = this.model = new People({ id: peopleId });
			this.modelID = parseInt(peopleId, 10);
			this.shortenedPanel = {
				relatedType: 'people',
				relatedId: peopleId,
				name: 'name',
				model: this.people
			};

			this.models[this.people.name] = this.people;

			this.subviews.push(new Tabs(".header-overview"));
			this.overview = new Overview({ parentView: this }, this.router);
			this.subviews.push(this.overview);
			this.buildShortenedPanel();

			this.render();
		},

		/**
   * 
   */
		goToForm: function () {

			if (this.checkRight()) {
				this.router.navigate(URLBuilder(['people', "form", this.people.id]), { trigger: true });
			}

			return false;
		},

		/**
   * 
   */
		afterRender: function () {

			PageOverview.prototype.afterRender.apply(this, arguments);

			//var DOM declaration
			this.$.contact = $('div.contact', this.$el);
			this.$.address = $('div.address', this.$el);
			this.$.addressTitle = $('.address-title', this.$el);
			this.$.callTo = $("#callTo", this.$el);
			this.$.phoneNum = $("#callTo .phone-num", this.$el);
			this.$.phoneNum.secondchild = $("#callTo .phone-num:nth-child(2)", this.$el);
			this.$.togglePhone = $(".togglePhone", this.$el);
			this.$.togglePhone.icon = $(".togglePhone a i", this.$el);
			this.$.avatarInitial = $("#pic-header .avatar-initials", this.$el);
			this.$.avatarImg = $("#pic-header img", this.$el);

			//take the initials avatar color and apply it on buttons, header, pic-header
			var colorFocused = this.$.avatarInitial.css('background-color');

			$('.color-focused').css({ 'color': colorFocused });
			$('.background-color-focused').css({ 'background-color': colorFocused });

			var _this = this;

			this.overview.initSwipeEffect({
				removeMsg: this.toastMessages.address.addressDeleted,
				onModify: _this.addressForm.bind(_this),
				popupid: this.linked + '-option',
				onRemove: function (model, $currentTarget) {
					$currentTarget.remove();
				},
				getModel: function ($currentTarget) {
					var id = $currentTarget.data('id');
					return new Address(_this.people.get('addresses')[$currentTarget.data("id") + '_0']);
				}

			}, '', this, $('.address.row'));

			$('#addContact-toast').show();
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainDelivAddr: function (e) {

			if (this.checkRight()) {

				var _this = this;
				var $newMainDelivAddr = $(e.currentTarget).closest('.address');
				var $newDelivId = $newMainDelivAddr.data('id');
				var newDelivAddr = new Address(this.people.get('addresses')[$newDelivId + '_0']);

				newDelivAddr.set({ isMainDeliv: 'Y' });
				newDelivAddr.unset('isMain');

				var $loader = this.formActionSetLoader();
				$newMainDelivAddr.append($loader);

				newDelivAddr.save().then(function () {

					new Toast({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 4000,
						content: _this.toastMessages.address.mainDelivAddressChanged
					});

					_this.overview.render({ setLoader: false }).then(function () {
						_this.afterRender();
					});
				}), function () {
					$('.inprogress-mask', $newMainDelivAddr).remove();
				};
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		changeMainAddr: function (e) {

			if (this.checkRight()) {

				var _this = this;
				var $newMainAddr = $(e.currentTarget).closest('.address');
				var $newMainId = $newMainAddr.data('id');
				var newMainAddr = new Address(this.people.get('addresses')[$newMainId + '_0']);

				newMainAddr.set({ isMain: 'Y' });
				newMainAddr.unset('isMainDeliv');

				var $loader = this.formActionSetLoader();
				$newMainAddr.append($loader);

				newMainAddr.save().then(function () {

					new Toast({
						id: 'toast-success',
						classes: 'col-xs-12',
						timeDuration: 4000,
						content: _this.toastMessages.address.mainAddressChanged
					});

					_this.overview.render({ setLoader: false }).then(function () {
						_this.afterRender();
					});
				}, function () {
					$('.inprogress-mask', $newMainAddr).remove();
				});
			}
		},

		/**
   * @description "Go to the address Form"
   */
		addressForm: function (model) {

			if (this.checkRight()) {

				if (model.id) {
					return this.router.navigate(URLBuilder(["address", "form", this.people.id, 'people', model.id]), { trigger: true });
				} else {
					return this.router.navigate(URLBuilder(["address", "form", this.people.id, 'people']), { trigger: true });
				}
			}

			return false;
		},

		/**
   * @description Confirmation popup before remove people
   * @returns {Boolean}
   */
		showPopupConfirm: function () {
			var callback = this.router.navigate.bind(this, URLBuilder(['people', "list"]), { trigger: true }); // executed after suppression
			this.removeConfirm(this.people, this.people.get('name'), this.$el, this.toastMessages.people.contactDeleted, callback);
			return false;
		}

	});
});
