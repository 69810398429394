/**
 * @desc Opportunity List, extend PageList View
 * Child View : Core Listing
 */
define('views/prospection/opportunity/list',['backbone', 'underscore', 'views/core/pageList', 'views/core/listing', 'tools/buildURL', 'app', 'models/collections/opportunities', 'models/singles/filter', 'models/singles/funnel'], function (Backbone, _, PageList, Listing, URLBuilder, App, Opportunities, Filter, Funnel) {

	'use strict';

	return PageList.extend({

		id: 'opportunityList',
		addAction: function () {
			this.listing.goToForm(URLBuilder(['opportunity', 'form']));
		},
		title: App.polyglot.t('opportunities'),
		listing: {},
		toastMessages: {
			opportunity: {
				opportunityRemoved: App.polyglot.t('opportunityRemoved')
			}
		},
		template: "templates/opportunity/list.twig",
		hideSearchIcon: true,

		/**
   * @desc initialize
   */
		initialize: function (router, funnelid, thirdid, links) {

			this.availableActions = links;
			this.name = 'opportunities_' + funnelid;
			this.canDelete = App.permission.can('prospection_deleteopportunity');
			this.canAdd = App.permission.can('prospection_createopportunity') && (App.permission.level("clientlevel") || App.permission.level("prospectlevel"));
			this.canEdit = App.permission.can('prospection_editopportunity');

			var _this = this;
			//set funnelid in listingFilters
			this.filter = new Filter({ listing: this.name, name: 'funnelid', value: funnelid });
			this.filter.updateLocal({ value: funnelid }, true).then(_.bind(function (result) {

				PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

					this.funnel = new Funnel({ id: funnelid });
					this.collection = new Opportunities();

					this.funnel.fetchLocal().then(_.bind(function () {

						//when we filter opps for one third
						if (thirdid) {
							this.collection.XHRData = { search: { thirds: [thirdid], 'linkedid': thirdid } };
						}

						if (this.listingFiltersCollection.get({ listing: this.name, name: 'stepid' }) && this.listingFiltersCollection.get({ listing: this.name, name: 'stepid' }).get('value')) {

							this.stepId = parseInt(this.listingFiltersCollection.get({ listing: this.name, name: 'stepid' }).get('value'));
							var stepPromise = new Promise(function (resolve) {
								resolve();
							});
						} else {

							//if no value in listingFilters we take first value in steps of funnel and save it in listingFilters
							this.stepId = parseInt(_.keys(this.funnel.get('steps'))[0]);
							this.filter = new Filter({ listing: this.name, name: 'stepid', value: this.stepId });
							this.listingFiltersCollection.add(this.filter);
							var stepPromise = this.filter.updateLocal({ value: this.stepId }, true);
						}

						stepPromise.then(_.bind(function () {

							this.router = router;
							this.placeholderSearchBar = App.polyglot.t('ident');
							this.listing = new Listing({ parentView: this });

							this.subviews.push(this.listing);
							this.render();
						}, this));
					}, this), function () {
						router.navigate(URLBuilder(['opportunity', 'home']), { trigger: true, replace: true });
					});
				}, this));
			}, this));

			var starting = function () {};
		},

		/**
   *
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			App.header.$el.find("nav").addClass("box-shadow-none");

			var stepItem = _.bind(function (data, escape) {

				var currentStepStats = this.stats['stats'][data.id];

				if (!currentStepStats) {

					currentStepStats = {
						potential: 0,
						total: 0
					};
				}

				return '<div class="opp-item">' + "<h1 class='opp-name'>" + escape(data.label) + "</h1>" + '<span class="nb-items">' + currentStepStats['total'] + " " + '</span>' + '<span class="color-blue">' + App.polyglot.t("opportunities") + " " + App.polyglot.t("for") + " " + App.numberFormat.formatToDisplay(currentStepStats['potential'], false, false) + " " + ' ' + "<span class='currency'>" + " " + App.numberFormat.currency.symbol + "</span>" + '</span>' + '</div>';
			}, this.collection);

			this.selectizeObjects = [{
				domElt: $("#listing-selectize input", this.$el),
				create: false,
				valueField: 'id',
				labelField: 'label',
				searchField: ['label'],
				maxOptions: 99,
				maxItems: 1,
				options: this.funnel.get('steps'),
				render: {
					item: stepItem,
					option: stepItem
				},
				onChange: function (value) {

					//save new value in listing filters
					_this.filter = new Filter({ listing: _this.name, name: 'stepid', value: parseInt(value) });
					_this.filter.updateLocal({ value: value }, true).then(function () {

						_this.listingFiltersCollection.add(_this.filter, { merge: true });

						_this.stepId = parseInt(value);
						_this.collection.XHRData.search['stepid'] = value;
						_this.collection.pagination.pagenum = 1;

						_this.setLoader($('.main-container', _this.$el), 0.6);

						_this.render();
					});
				},
				onInitialize: function () {

					$('.selectize-input', '#listing-selectize').after('<div id="selectize-overlay"></div>');
					$('.selectize-input input', '#listing-selectize').attr('readonly', true);
				},
				hideSelected: true,
				noDelete: true,
				defaultValues: [this.stepId]
			}];

			PageList.prototype.afterRender.apply(this, arguments);

			if (this.canEdit || this.canDelete) {

				this.listing.initSwipeEffect({
					removeMsg: this.toastMessages.opportunity.opportunityRemoved,
					onModify: URLBuilder(["opportunity", "form"]),
					popupid: this.linked + '-option',
					onRemove: function (model, $currentTarget) {
						$currentTarget.remove();
					}
				}, '', this);
			}
		}

	});
});
