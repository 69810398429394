/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/tax',['models/core/model'], function (Model) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'tax',
		name: 'tax',
		urlRoot: '/rest/tax/'

	});
});
