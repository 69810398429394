/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/task',['models/core/model', 'tools/ajax', 'app', 'moment'], function (Model, Ajax, App, Moment) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			type: 'task',
			label: 0,
			alerts: {},
			description: '',
			startdate: '',
			starthour: '',
			allDay: 'N',
			isPrivate: 'N',
			relatedtype: '',
			relatedid: '',
			staffs: {},
			labelname: ''
		},

		urlRoot: '/rest/agenda',
		storeName: 'task',
		name: 'task',

		/**
   * 
   * @returns {Promise}
   */
		setAsFinished: function () {

			var _this = this;
			var status = 'complete';

			if (this.setascomplete == 'Y') {
				status = 'ok';
			}

			return new Promise(function (resolve, reject) {
				Ajax({
					method: 'POST',
					url: '/rest/agenda/setAsFinished',
					data: {
						id: _this.id,
						status: status
					},
					dataType: "json",
					success: function () {

						//delete completed task of localDB
						_this.destroyLocal().then(function () {
							resolve();
						});
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		validate: function (attributes) {

			var canEditIds = {};
			$.each(attributes, function (index, value) {
				if (index.substring(0, 8) == 'canEdit_') {
					var id = index.split('_');
					canEditIds[id[1]] = id[1];
				}
			});

			this.attributes.canEdit = canEditIds;

			var validationErrors = { task: {} };

			if (!Moment(attributes.startdate).isValid()) {
				validationErrors.task.startdate = this.computeError({ name: 'startdate', type: 'invalid' });
			}

			if (App.dateFormat.hourFormat == '12' && attributes.starthour != '') {
				attributes.starthour = Moment(attributes.starthour, ["h:mm A"]).format("HH:mm");
			}

			if (Moment(attributes.startdate).isValid() && attributes.starthour != '') {
				this.attributes.start = Moment(attributes.startdate + ' ' + attributes.starthour).unix();
				this.attributes.end = Moment(attributes.startdate + ' ' + attributes.starthour).unix();
			}

			if (attributes.starthour == '') {
				validationErrors.task.starthour = this.computeError({ name: 'starthour', type: 'invalid' });
			}

			if (attributes.description == '') {
				validationErrors.task.description = this.computeError({ name: 'description', type: 'invalid' });
			}

			if (attributes.isPrivate == '') {
				this.attributes.isPrivate = 'Y';
			}
			if (attributes.isPrivate != 'Y' && attributes.isPrivate != 'N') {
				validationErrors.task.isPrivate = this.computeError({ name: 'amount', type: 'invalid' });
			}

			if (_.toArray(validationErrors.task).length > 0) {
				return validationErrors;
			}
		}

	});
});
