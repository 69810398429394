define('tools/numberFormat',['big', 'underscore'], function (Big, _) {

	'use strict';

	var NumberFormat = function (numberFormat) {

		this.name = numberFormat.name;
		this.precision = numberFormat.precision;

		this.currency = {
			position: numberFormat.currencypos,
			symbol: numberFormat.currencysymbol
		};

		this.separators = {
			decimals: numberFormat.decimals,
			thousands: numberFormat.thousands
		};

		this.precisioncustom = numberFormat.precisioncustom;
	};

	/**
  * @desc return numberFormat info
  * @returns {undefined}
  */
	NumberFormat.prototype.toArray = function () {
		return {
			currencypos: this.currency.position,
			currencysymbol: this.currency.symbol,
			decimals: this.separators.decimals,
			thousands: this.separators.thousands,
			precision: this.precision,
			precisioncustom: this.precisioncustom
		};
	};

	NumberFormat.prototype.formatToFloat = function (number) {

		number = '' + number;

		if ('' == number) {
			number = "0";
		}

		// split decimal and Int part
		var parts = number.split('' + this.separators.decimals);
		var intPart = parts[0] != undefined ? '' + parts[0] : '0';
		var decPart = parts[1] != undefined ? '' + parts[1] : '0';

		intPart = intPart.split(this.separators.thousands).join('');

		var numFloat = intPart;

		if (decPart != '') {
			numFloat += '.' + decPart;
		}

		return parseFloat(numFloat);
	};

	NumberFormat.prototype.formatToDisplay = function (number, isAlreadyFloat, includeCurrency, currency, precision, addWhiteSpace, precisioncustom) {

		if (!number && number !== 0 || _.isNaN(number)) {
			return this.formatToDisplay(0, true, false);
		} else {

			includeCurrency = _.isUndefined(includeCurrency) && this.position !== 'none' ? true : includeCurrency;
			currency = !_.isUndefined(currency) ? currency : this.currency;
			precision = !_.isUndefined(precision) ? precision : this.precision;
			addWhiteSpace = !_.isUndefined(addWhiteSpace) ? addWhiteSpace : true;

			// format number to float if necessary (cast or transformation if there's a special format)
			var floatReg = /^[+-]?\d+(\.\d+)?$/;

			// always float in POS, remove ?
			if (!_.isUndefined(isAlreadyFloat) && isAlreadyFloat === false) {

				if (floatReg.test(number)) {
					number = parseFloat(number);
				} else {
					number = this.formatToFloat('' + number);
				}
			} else {
				number = parseFloat(number);
			}

			if (precisioncustom) {
				if (this.precisioncustom && this.precisioncustom[precisioncustom] && this.precisioncustom[precisioncustom] != "default") {
					precision = this.precisioncustom[precisioncustom];
				}
			}

			number = new Big(number).round(parseInt(precision)).toFixed(parseInt(precision));

			var temp = number.split('.');

			var intVal = '' + parseInt(number, 10);
			var str_nbre = '';

			var count = 0;
			for (var i = intVal.length - 1; i >= 0; i--) {

				count++;
				str_nbre = '' + intVal.charAt(i) + str_nbre;

				if (count != 0 && count % 3 == 0 && i != 0) {
					str_nbre = this.separators.thousands + str_nbre;
				}
			}

			if (!_.isUndefined(temp[1])) {
				str_nbre += '' + this.separators.decimals + temp[1];
			} else {
				str_nbre += '';
			}

			if (includeCurrency) {

				var space = addWhiteSpace ? ' ' : '';

				switch (currency.position) {

					case 'left':
						str_nbre = currency.symbol + space + str_nbre;
						break;

					case 'right':
						str_nbre = str_nbre + space + currency.symbol;
						break;

					case 'both':
						str_nbre = currency.symbol + space + str_nbre + space + currency.symbol;
						break;

				}
			}

			return str_nbre;
		}
	};

	return NumberFormat;
});
