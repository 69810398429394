/**
 * @desc Task List, extend PageList View
 * Child View : Core Listing
 */
define('views/agenda/taskListing',['underscore', 'views/core/pageList', 'views/core/listing', 'models/collections/tasks', 'tools/buildURL', 'views/core/navButtons', 'views/core/toastNotification', 'app', 'models/singles/filter'], function (_, PageList, Listing, TaskEntries, URLBuilder, navButtons, ToastNotification, App, Filter) {

	'use strict';

	return PageList.extend({

		name: 'tasks',

		listing: {},
		toastMessages: {
			removed: App.polyglot.t('taskDeleted')
		},

		canAdd: true,
		canEdit: true,
		canDelete: true,
		hideSearchIcon: true,
		addAction: function () {

			var _this = this;
			var urlCallback = URLBuilder(['calendar', 'form', 'task']);

			if (this.relateds.relatedtype && this.relateds.relatedid) {
				urlCallback = URLBuilder([urlCallback, _this.relateds.relatedtype, _this.relateds.relatedid], false);
			}

			this.listing.goToForm(urlCallback);
		},

		/**
   * @desc initialize
   */
		initialize: function (router, period, params, links) {

			if (params.relatedtype === 'home') {
				this.hideFilterPannel = true;
			}

			this.period = period;

			this.availableActions = links;

			PageList.prototype.initialize.apply(this, arguments).then(_.bind(function () {

				switch (period) {

					case 'late':
						this.title = App.polyglot.t('tasks') + ' - ' + App.polyglot.t('late');
						break;

					case 'today':
						this.title = App.polyglot.t('tasks') + ' - ' + App.polyglot.t('today');
						break;

					case 'tomorrow':
						this.title = App.polyglot.t('tasks') + ' - ' + App.polyglot.t('later');

						break;

				}

				var _this = this;
				this.template = "templates/agenda/taskList.twig";

				if ($.inArray(params.relatedtype, App.availableThirds) !== -1) {
					params.relatedtype = 'third';
				}

				this.relateds = params;
				this.collection = new TaskEntries();
				this.collection.XHRData = {
					search: {
						period: [period],
						state: period //map for local query
					}

				};

				if (this.relateds.relatedtype && this.relateds.relatedid) {
					this.collection.XHRData.search.relatedtype = this.relateds.relatedtype;
					this.collection.XHRData.search.relatedid = this.relateds.relatedid;
				}

				this.options = {

					extraData: {

						filtered: function () {

							return new Promise(function (resolve, reject) {

								if (_this.relateds.relatedtype && _this.relateds.relatedid) {

									_.extend(_this.collection.XHRData, _this.relateds);
									_this.relateds.link = App.GoToOverviewByRelated(_this.relateds.relatedtype, _this.relateds.relatedid);
									resolve(_this.relateds);
								} else {
									resolve('');
								}
							});
						}
					}

				};

				this.var4template('period', period);

				this.listing = new Listing({ parentView: this });

				//override filter set in listing core by logged staffid when we come from home
				if (params.relatedtype === 'home') {
					this.collection.XHRData.search.staffs = [App.currentStaff.id];
				} else if (this.listingFiltersCollection.get({ listing: this.name, name: 'staffs' })) {

					var staffFilter = this.listingFiltersCollection.get({ listing: this.name, name: 'staffs' }).get('value');
					this.collection.XHRData.search.staffs = staffFilter;
				} else {
					this.filter = new Filter({ listing: this.name, name: 'staffs', value: [App.currentStaff.id] });
					this.filter.saveLocal();
					this.collection.XHRData.search.staffs = [App.currentStaff.id];
				}

				this.subviews.push(this.listing);
				this.render();
			}, this));
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageList.prototype.afterRender.apply(this, arguments);

			//init material effect on each row of listing
			this.listing.initSwipeEffect({

				onRemove: function (model, $currentTarget) {
					$currentTarget.remove();
				},
				canDelete: function (model) {
					return model.get("isOwner") === 'Y';
				},
				canEdit: function (model) {
					return model.get("editable") === 'Y';
				},
				removeMsg: _this.toastMessages.removed,
				popupid: 'task-option',
				modelName: 'labelname',
				more: [{
					callback: function (model, $currentTarget) {
						_this.router.navigate(URLBuilder(["annotation", "form", "task", model.get('id')]), { trigger: true });
					},
					class: "commentAction",
					'fa': 'fa-comments'
				}, {
					callback: function (model, $currentTarget) {

						$currentTarget.append(_this.formActionSetLoader());

						model.setAsFinished().then(function () {

							$currentTarget.remove();

							new ToastNotification({
								id: 'toast-success',
								classes: 'col-xs-12 toastOnNavbar',
								timeDuration: 3000,
								content: App.polyglot.t('taskIsFinished')
							});
						});
					},
					class: "completeTaskIcon",
					'fa': 'fa-check',
					check: function (model, $currentTarget) {
						return model.get("editable") === 'Y';
					}
				}],
				maxSwipe: 300
			}, '', this);

			this.delegateEvents();
		}

	});
});
