/**
 * @desc Model Core, extend Backbone.Model
 */
define('models/core/model',['underscore', 'backbone', 'db/easy', 'tools/ajax', 'views/core/toastNotification', 'app', 'tools/core/utils', "backboneCompositeKey", 'backbone-model-file-upload'], function (_, Backbone, DB, Ajax, ToastNotification, App, Utils, backboneCompositeKey, BackboneFileUpload) {

	'use strict';

	// Say to Backbone to use our custom Ajax Requester

	Backbone.ajax = Ajax;

	return Backbone.Model.extend({

		/**
   * @desc Check if id == O  or if id not exist (Backbone override)
   * @returns {Boolean}
   */
		isNew: function () {

			var idExist = Backbone.Model.prototype.isNew.apply(this, arguments);

			return this.id == 0 || idExist;
		},

		//Recursive toJSON for nested model
		toJSON: function (options) {

			if (this._isSerializing) {
				return this.id || this.cid;
			}

			this._isSerializing = true;

			var json = _.clone(this.attributes);

			_.each(json, function (value, name) {
				_.isFunction((value || "").toJSON) && (json[name] = value.toJSON());
			});
			this._isSerializing = false;

			return json;
		},

		/**
   * @desc override backbone fetch
   *  make a local save if success
   *	make a local fetch if fail
   * @returns {Promise}
   */
		fetch: function (options) {

			options = options ? options : {};
			options.dataType = 'json';

			var _this = this;
			var xhr = Backbone.Model.prototype.fetch.apply(this, arguments);

			return new Promise(function (resolve, reject) {

				xhr.fail(function () {

					if (App.indexedDB) {
						_this.fetchLocal().then(resolve, reject);
					}
				});

				xhr.done(function (data) {

					if (data.error) {

						//sometime we need to continue even if a model isn't loadable
						if ((data.error.code === 'E_OBJ_NOT_LOADABLE' || data.error.code === 'E_PRIV_NOT_ALLOWED') && _this.bypassNotLoadable === true) {

							_this.isLoadable = false;
							_this.clearLocal().then(function () {
								resolve();
							});
						} else if (data.error.code !== "E_USER_NOT_LOGGED" && data.error.code !== "E_SUBSCRIBE_HAVETO") {
							_this.destroyLocal();
							reject(data.error.code);
						} else {
							reject(data.error.code);
						}
					} else {

						if (App.indexedDB) {

							_this.saveLocal().then(function () {

								if (_.isFunction(_this.fetchCallback)) {

									_this.fetchCallback().then(function () {
										resolve(data);
									});
								} else {
									resolve(data);
								}
							});
						} else {
							resolve();
						}
					}
				});
			});
		},

		/**
   *
   * @param {type} key
   * @param {type} val
   * @param {type} options
   * @returns {Promise}
   */
		save: function (key, val, options) {

			var _this = this;
			key = _.isUndefined(key) ? {} : key;
			options = _.isUndefined(options) ? {} : options;
			var xhr = Backbone.Model.prototype.save.apply(this, arguments);

			return new Promise(function (resolve, reject) {

				xhr.done(function (response) {

					if (response.status !== 'error') {

						if (App.indexedDB && _this.storeName) {

							if (key.fetchAfterSave) {

								//clear model and fetch it (sometime save attributs are differents of fetch attributs)
								_this.clear();
								_this.set({ id: response.id ? response.id : response });

								_this.fetch().then(function () {
									resolve();
								});
							} else {

								if (_.isUndefined(key) || _.isUndefined(key.savelocal) || key.savelocal === true) {

									_this.saveLocal().then(function () {
										resolve();
									});
								} else {
									resolve();
								}
							}
						} else {
							resolve();
						}
					} else {

						if (response.error.message === "Object item not loadable") {

							_this.displaySavedError("anItemIsDeleted");
						} else if (response.error.inerror === "Nylas") {

							_this.displaySavedError("nylasNotConfigured");
						} else {
							_this.displaySavedError();
						}

						reject(response);
					}
				});

				xhr.fail(function (jqXHR) {

					_this.displaySavedError();

					reject(jqXHR);
				});
			});
		},

		/**
   *
   */
		computeError: function (error) {

			switch (error.type) {

				case 'required':

					return App.polyglot.t("valueRequired");

					break;

				case 'invalid':

					return App.polyglot.t("valueInvalid");

					break;

			}
		},

		/**
   *
   * @param {type} options
   * @returns {Promise}
   */
		destroy: function (options) {

			var _this = this;
			var xhr = Backbone.Model.prototype.destroy.apply(this, arguments);
			var deleteLocal = true;

			return new Promise(function (resolve, reject) {

				xhr.done(function (response) {

					if (response.status !== 'error') {

						if (options.data) {
							var datas = jQuery.parseJSON(options.data);
							if (!_.isUndefined(datas.saveLocal) && datas.saveLocal === false) {
								deleteLocal = false;
							}
						}

						if (App.indexedDB && deleteLocal === true) {

							_this.destroyLocal().then(function () {
								resolve();
							}, function (error) {
								console.log(error);
							});
						} else {
							resolve();
						}
					} else {

						_this.destroyLocal();

						if (response.error && response.error.code === "E_OBJ_NOT_LOADABLE" || response.error.code === "'E_PRIV_NOT_ALLOWED'") {

							_this.clearLocal().then(function () {
								reject(response.error.code);
							});
						} else {
							_this.displayDeleteError();
						}
					}
				});

				xhr.fail(function (jqXHR) {
					_this.destroyLocal();
					_this.displayDeleteError();
					reject(jqXHR);
				});
			});
		},

		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				if (App.indexedDB) {

					if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

						var id = !Utils.inArray(_this.storeName, App.localTablesWithStringKey) ? parseInt(_this.id) : _this.id;

						DB[_this.storeName].get(id).then(function (model) {

							if (model) {

								_this.set(model);

								if (_.isFunction(_this.fetchLocalCallback)) {

									_this.fetchLocalCallback().then(function () {
										resolve();
									});
								} else {
									resolve();
								}
							} else if (_this.bypassNotLoadable) {
								resolve();
							} else {
								console.log(_this);
								reject('E_OBJ_NOT_LOADABLE');
							}
						}, function (error) {
							console.log(error, _this);
							reject();
						});
					}
				} else {
					reject('invalid store name');
				}
			});
		},

		/**
   * @desc Save data in local indexedDB
   * @param {type} ajaxError
   * @returns {Promise}
   */
		saveLocal: function (options) {

			var _this = this;

			//unset fileobjet for not save it in local (useless)
			if (this.get('file')) {
				this.unset('file');
			}

			return new Promise(function (resolve, reject) {

				if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

					var id = [];

					if (!Utils.inArray(_this.storeName, App.localTablesWithStringKey)) {

						id[_this.idAttribute] = parseInt(_this.id);
						_this.set(id);
					}

					DB[_this.storeName].put(_this.toJSON()).then(function (lastId) {
						resolve();
					}, function (error) {
						console.log(error, _this);
						reject();
					});
				} else {
					reject();
				}
			});
		},

		/**
   * @desc Destroy data in local indexedDB
   * @returns {Promise}
   */
		destroyLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				var id = [];

				if (!Utils.inArray(_this.storeName, App.localTablesWithStringKey)) {

					id[_this.idAttribute] = parseInt(_this.id);
					_this.set(id);
				}

				DB[_this.storeName].delete(_this.id).then(function () {
					resolve();
				}, function (error) {
					console.log(_this);
					console.log(error);
				});
			});
		},

		/**
   *
   * @returns {undefined}
   */
		displaySavedError: function (errorMsg) {

			errorMsg = _.isUndefined(errorMsg) ? App.polyglot.t("formSavedFailed") : App.polyglot.t(errorMsg);

			if ($("#notif-saveFailed").length < 1) {

				new ToastNotification({

					id: 'toast-error',
					timeDuration: 3000,
					classes: "col-xs-12 col-lg-8 col-lg-offset-2",
					content: App.polyglot.t(errorMsg)

				});
			}

			$('.submit-form').prop('disabled', false);
		},

		/**
   *
   * @returns {undefined}
   */
		displayDeleteError: function () {

			if ($("#notif-deleteFailed").length < 1) {

				new ToastNotification({

					id: 'toast-error',
					timeDuration: 3000,
					classes: "col-xs-12 col-lg-8 col-lg-offset-2",
					content: "La suppression a échoué"
				});
			}
		},

		/**
   *
   * @returns {undefined}
   */
		getPreferences: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({
					method: 'GET',
					url: _this.urlRoot + 'preferences',
					data: {},
					dataType: "json",
					success: function (result) {
						console.log(6);
						resolve(result);
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @returns {unresolved}
   */
		clearLocal: function () {
			return DB[this.storeName].clear();
		}

	});
});
