/**
 *  @desc Emails Collection, extend core Collection
 */
define('models/collections/messages',['models/core/collection', 'models/singles/message', 'db/easy'], function (Collection, Message, DB) {

    'use strict';

    return Collection.extend({

        model: Message,
        storeName: 'message',
        name: 'messages',
        url: '/rest/emailBox/messages/list',

        /**
         *
         * @returns {undefined}
         */
        constructor: function () {
            Collection.apply(this, arguments);
        }

    });
});
