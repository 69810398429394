/**
 *  @desc Annotations Collection, extend core Collection
 */
define('models/collections/annotations',['models/core/collection', 'models/singles/annotation', 'db/easy', 'moment'], function (Collection, annot, DB, Moment) {

	'use strict';

	return Collection.extend({

		model: annot,
		storeName: 'annotation',
		name: 'annotations',
		url: '/rest/annotation/list',

		/**
   * 
   * @param {type} parentModel
   * @param {type} childId
   * @returns {undefined}
   */
		getChildById: function (parentModel, childId) {
			return parentModel.get('child')[childId];
		}

	});
});
