/**
 * @desc Home View
 */
define('views/home',['backbone', 'views/core/page', 'models/collections/stats', 'models/collections/tasks', 'views/core/overview', 'app', 'tools/core/utils', 'peity', 'chart'], function (Backbone, Page, Stats, Tasks, Overview, App, Utils) {

	'use strict';

	return Page.extend({

		events: _.extend({
			'change #dashboardSmartdate': 'changeSmartdate',
			'change #dashboardCurrency': 'changeCurrency',
			'change #dashboardStaff': 'changeStaff',
			'click .row-dashstat': 'changeStatTarget'
		}, Page.prototype.events),

		title: App.polyglot.t('dashboard'),
		template: 'templates/home/index.twig',
		statsListTpl: '\n\t\t\t<div id="salesStats" class=\'col-xs-12\'>\n\t\t\t\t<% _.each(stats, function(stat, key) {\xA0%>\n\n\t\t\t\t\t<div class=\'row vertical-center row-dashstat sales-stats\' data-id="<%= stat.id %>">\n\t\t\t\t\t\t<div class="col-xs-5">\n\t\t\t\t\t\t\t<div class="stat-label"><%= stat.label %></div>\n\t\t\t\t\t\t\t<div class="stat-total"><%= stat.total %> <%= stat.unit %></div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class="col-xs-7">\n\t\t\t\t\t\t\t<% if(stat.graph.datasets[0].data){ %>\n\t\t\t\t\t\t\t\t<span class="minichart"><%= stat.graph.datasets[0].data.join(\',\') %></span>\n\t\t\t\t\t\t\t<% } %>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t<% }); %>\n\t\t\t</div>\n\t\t',

		/**
   * 
   * @returns {undefined}
   */
		initialize: function (router) {

			var _this = this;

			Page.prototype.initialize.apply(this, arguments);

			if (App.permission) {

				var invoicingLoaded = App.pluginIsLoaded("invoicing");
				var purchasesLoaded = App.pluginIsLoaded("purchases");

				var byPassAdmin = App.currentStaff.get('permission').level == "owner" ? true : false;

				var dashboardAccess = App.permission.can('dashboardstats', byPassAdmin);
				var dashboardSalesAccess = App.permission.can('invoicing_dashboardsales', byPassAdmin) && App.permission.can('invoicing_access') && invoicingLoaded;
				var dashboardMarginAccess = App.permission.can('purchases_dashboardmargin', byPassAdmin) && App.permission.can('purchases_access') && purchasesLoaded;

				this.canAdd = (invoicingLoaded || purchasesLoaded) && App.currentStaff.get('permission').level === "owner" || dashboardAccess && (dashboardMarginAccess || dashboardSalesAccess);
				this.var4template("canDashboard", this.canAdd);
			}

			if (this.canAdd) {

				this.statistics = new Stats();
				this.statistics.XHRData = {
					search: {
						smartDate: 'currentmonth',
						interval: 'day',
						currencyid: App.currencies.findWhere({ selected: 'Y' }) ? App.currencies.findWhere({ selected: 'Y' }).get('id') : 1,
						target: dashboardSalesAccess ? 'ca' : 'invoicesMargin'
					}
				};

				this.var4template("search", _this.statistics.XHRData.search);
				this.var4template("smartdate", App.availableSearchDate);
			}

			this.overviewClassName = 'dashboard-overview';
			this.overview = new Overview({ parentView: this }, this.router);

			this.subviews.push(this.overview);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			$('.return', '.icon-wrapper').addClass('hidden');
			$('.navbar-fixed-top').show();
			$('#side-bar').show();

			if (!$('#action-list-left').length) {

				$('.icon-wrapper-left').append('<ul id="action-list-left">' + '<li id="icon-calendar"><i class="icon icon-task"></i></li>' + '</ul>');

				if (App.isOnline) {

					var _this = this;

					var tasks = new Tasks();

					var params = {
						period: ['today'],
						staffs: [App.currentStaff.id]
					};

					tasks.getNbCurrents(undefined, undefined, params).then(function (count) {

						if (count > 0) {
							$('#action-list-left').find('#icon-calendar').append('<span class="counter">' + count + '</span>');
						}

						$('#action-list-left').find('#icon-calendar').on('click', function () {
							_this.router.navigate('#calendar/list/task/today/home', { trigger: true });
						});
					});
				}
			}

			if (this.canAdd) {
				this.renderStats();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		renderStats: function () {

			this.statistics.fetch({
				method: "POST",
				data: this.statistics.XHRData
			}).then(_.bind(function () {

				var template = _.template(this.statsListTpl); //compile template with underscore
				$('.dashboard-content', this.$el).html(template({ stats: this.statistics.toJSON() })); //Insert template in DOM with data

				try {
					App.header.$.title.text(this.statistics.get(this.statistics.XHRData.search.target).get('label'));
				} catch (e) {
					return false;
				}

				$('#loader', "#header-overview").remove();
				this.constructChart();

				$(".minichart").peity("line", {
					width: '80%',
					fill: 'rgba(255, 255, 255, 0)',
					stroke: function (value) {
						return "#35596e";
					}
				});
			}, this));
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeSmartdate: function (e) {

			var v_current = $(e.currentTarget);

			this.statistics.XHRData.search.interval = v_current.find('option:selected').data('searchmethod');
			this.statistics.XHRData.search.smartDate = v_current.val();

			this.fetchForChart();
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeStaff: function (e) {

			var v_current = $(e.currentTarget);
			this.statistics.XHRData.search.staffid = v_current.val();

			this.fetchForChart();
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeCurrency: function (e) {

			var v_current = $(e.currentTarget);
			this.statistics.XHRData.search.currencyid = v_current.val();

			this.fetchForChart();
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		changeStatTarget: function (e) {

			var _this = this;
			var v_current = $(e.currentTarget);

			// remove highlight on previous element
			if (this.statistics.XHRData.search.target) {
				this.$el.find('[data-id="' + this.statistics.XHRData.search.target + '"]').removeClass('active');
			}

			this.statistics.XHRData.search.target = v_current.data('id');

			// highlight current element
			v_current.addClass('active');

			App.header.$.title.text(v_current.find('.stat-label').html());

			this.overview.$el.animate({
				scrollTop: 0
			}, 400, 'swing', function () {
				_this.constructChart();
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		fetchForChart: function () {

			var _this = this;

			//			this.setLoader(this.$el.find('#header-overview'), '0.8');
			$('.dashboard-content').html(App.sellsyLoader);
			$('#main-chart,#chart-nodata').hide();
			$('#header-overview', this.$el).prepend(App.sellsyLoader);

			_this.var4template("search", _this.statistics.XHRData.search);
			_this.renderStats();
		},

		/**
   * 
   * @returns {undefined}
   */
		constructChart: function () {

			var _this = this;
			var stats = this.statistics;
			var statTarget = this.statistics.XHRData.search.target;

			// destroy chart if there is already one
			if (this.myBarChart) {
				this.myBarChart.destroy();
			}

			var datas = stats.get(statTarget).get('graph').datasets[0].data;

			//On check si toutes les valeurs sont à 0
			var statsCheked = _.filter(datas, function (stat) {
				return stat > 0;
			});

			if (!_.isEmpty(statsCheked)) {

				var months = Utils.getMonths();
				var labels = [];
				var labelsColors = [];

				if (_this.statistics.XHRData.search.interval === 'month') {

					var labels = stats.get(statTarget).get('graph').labels;

					_.each(months, function (month) {
						labelsColors.push(month.color);
					});
				} else {

					var labels = stats.get(statTarget).get('graph').labels;
					var dayColor = Utils.getRandomHTMLColor();

					labelsColors.push(dayColor);
				}

				var chartDatas = {
					labels: labels,
					datasets: [{
						//							backgroundColor	: labelsColors,
						//							borderColor		: labelsColors,
						//							borderWidth		: 1,	
						fill: false,
						borderColor: "#229EE6",
						pointBackgroundColor: "#229EE6",
						data: datas
					}]
				};

				var options = {
					legend: {
						display: false
					},
					scales: {

						yAxes: [{
							stacked: true,
							ticks: {
								fontColor: "#2E4554",
								fontSize: "8"
							},
							gridLines: {
								display: false,
								color: "#2E4554"
							}
						}],
						xAxes: [{
							ticks: {
								fontColor: "#2E4554",
								fontSize: "8"
							},
							gridLines: {
								display: false,
								color: "#2E4554"
							}
						}]
					}
				};

				$('#chart-nodata', this.$el).hide();
				$('#main-chart', this.$el).show();

				var ctx = $('#main-chart').get(0).getContext("2d");

				this.myBarChart = new Chart(ctx, {
					type: 'line',
					data: chartDatas,
					options: options
				});
			} else {
				$('#chart-nodata', this.$el).show();
				$('#main-chart', this.$el).hide();
			}
		}

	});
});
