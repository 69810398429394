define('views/core/toastNotification',['jquery', 'underscore', 'views/core/view', 'libs/bootstrap'], function ($, _, View) {

	'use strict';

	var ToastNotification = View.extend({

		events: {
			'click #toast-link': "removeToast"
		},

		id: '',
		tagName: 'div',
		className: 'container toast-notification alert alert-dismissible',
		height: '',
		style: {},
		content: '',
		timeDuration: {},
		link: '',
		linkMessage: '',
		template: "templates/widgets/toastNotification.twig",

		initialize: function (options) {

			this.id = options.id;
			this.timeDuration = options.timeDuration;
			this.link = options.link;
			this.classes = options.classes;
			this.classesGrid = options.classesGrid;
			this.linkMessage = options.linkMessage;
			this.content = options.content;
			this.callback = options.callback;
			this.linkEvent = options.linkEvent;

			if (options.style) {
				this.style = options.style;
			}

			if (options.renderNow || _.isUndefined(options.renderNow)) {
				this.render();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		renderCallback: function () {

			var _this = this;

			this.$el.addClass(this.classes);

			if (!_.isEmpty(this.style)) {
				this.$el.css(this.style);
			}

			if (!$("#" + this.id).length) {

				$('#toast-message', this.$el).html(this.content);

				if (!_.isUndefined(this.link)) {
					$("#toast-link", this.$el).prop('href', this.link);
				}

				if (!_.isUndefined(this.linkMessage)) {
					$("#toast-link", this.$el).text(this.linkMessage);
				}

				!_.isUndefined(this.timeDuration) ? setTimeout(this.hideToast.bind(this), this.timeDuration) : '';

				$('.content-wrapper').append(this.$el);

				//attach callback on click event on link
				if (!_.isUndefined(this.linkEvent) && _.isFunction(this.linkEvent)) {
					$('#toast-link').on('click', _this.linkEvent);
				}
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		hideToast: function () {

			this.$el.on('transitionend', this.removeToast.bind(this));
			this.$el.css("opacity", 0);
			if (!_.isUndefined(this.callback)) {
				this.callback();
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		removeToast: function () {
			this.remove();
		}

	});

	return ToastNotification;
});
