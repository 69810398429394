/**
 * @desc Staff Pref, extend Page View
 */
define('views/setting/staffPref',['underscore', 'views/core/page', 'views/core/form', 'tools/buildURL', 'app'], function (_, Page, Form, URLBuilder, App) {

	'use strict';

	return Page.extend({

		title: App.polyglot.t("profil"),
		toastMessages: {
			staff: {
				saveMsg: App.polyglot.t("staffInfosSaved")
			}
		},
		/**
   * @desc initialize
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.template = 'templates/setting/staff';
			this.modelToSave = App.sidebar.staffView.models['staff'];
			this.models[this.modelToSave.name] = this.modelToSave;
			this.form = new Form({ parentView: this });

			this.subviews.push(this.form);
			this.listenTo(this.form, 'dataSend', this.formAction);
			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			Page.prototype.afterRender.apply(this, arguments);

			// DOM Declaration

			//focus on current select value
			var $timezoneSelected = $("select[name='staff[timezone]'] option[value='" + this.modelToSave.get('timezone') + "']");
			$timezoneSelected.prop('selected', true);

			//focus on current uilang
			$('select[name="staff[uilang]"]', this.$el).val(this.modelToSave.get('uilang'));
			$('select[name="staff[civil]"]', this.$el).val(this.modelToSave.get('civil'));
		},

		/**
   *  @desc action when form is submit
   */
		formAction: function () {

			var dataForm = this.form.dataForm;
			var _this = this;

			this.modelToSave.set(dataForm.staff);

			if (this.modelToSave.isValid()) {

				this.modelToSave.save().then(function () {

					App.initTranslations(_this.modelToSave.get('uilang')).then(function (trad) {

						App.polyglot = trad;

						//refresh panel staffCorpInfo when saved
						App.sidebar.staffView.trigger('staffCorpUpdated');
						_this.form.trigger('successful', _this.toastMessages.staff.saveMsg);
						_this.router.navigate(URLBuilder(["accountPref"]), { trigger: true });
					});
				}, function (jqXHR) {});
			} else {

				//re activate submit button
				$('.submit-form', this.form.$el).removeAttr('disabled');

				//Make invalid inputs in error
				_.each(_this.modelToSave.validationError, function (error, keyError, arrayError) {

					if (!_.isEmpty(error)) {

						_.each(error, function (msgError, keyMsg) {
							_this.form.showValidationError(keyError, keyMsg, msgError, true);
						});
					}
				});
			}

			return false;
		}

	});
});
