/**
 * @desc DocForm Header, extend core View
 */
define('views/document/docFormHeader',['underscore', 'views/core/view', 'models/collections/paydates', "app", 'moment', 'tools/core/utils'], function (_, View, Paydates, App, Moment, Utils) {

	'use strict';

	return View.extend({

		collections: {},
		models: {},
		subviews: [],
		events: {
			'change #paydate': "computePaydate",
			'change #createDate': "computePaydate",
			"click #corp_and_third_container h1": 'togglecContactPart'
		},

		initialize: function (view, router) {

			this.setElement("#doc-form #header-doc");

			this.parentView = view.parentView;
			this.router = router;
			this.document = this.parentView.modelToSave;
			this.currentThird = this.document.get('linkedThird');
			this.paydates = this.parentView.paydates;

			//DOM declaration
			this.$.paydate = $('#paydate', this.$el);

			//get date format
			this.dateFormat = App.dateFormat.momentFormat;

			//set create date,
			this.momentCreatedDate = this.document.isNew() ? Utils.SellsyMoment(true) : Utils.SellsyMoment(this.document.get('displayedDate'), "X", true);
			var createdDate = this.momentCreatedDate.format(App.HTMLDateFormat);

			$('input[name="document[displayedDate]"]', this.$el).val(createdDate);

			//stock in var, paydate model with xdays syscode for use later
			this.xdaysPaydate = this.paydates.find(function (model) {
				return model.get('syscode') === 'xdays';
			});
			//stock in var, paydate model with custom syscode for use later
			this.customPaydate = this.paydates.find(function (model) {
				return model.get('syscode') === 'custom';
			});

			this.setPaydate();
		},

		/**
   * 
   */
		setPaydate: function () {

			//if doc is new we take paydate in third pref else in docPref
			if (this.document.isNew() && !this.parentView.inTemp) {

				//we build a custom paydate obj for compute paydate after
				if (this.currentThird.prefs.payDateid != 0) {
					var prefs = this.currentThird.prefs;
					var docPrefs = this.document.get('prefs');
				} else {
					var prefs = this.document.get('prefs');
				}

				if (this.paydates.get(prefs.payDateid)) {

					var paydateid = prefs.payDateid;
					this.paydates.get(paydateid);
					var paydate = this.paydates.get(paydateid);
				} else {

					var paydate = this.paydates.first();
					prefs.payDateid = paydate.get('id');
				}

				this.paydateObj = {
					syscode: paydate.get('syscode'),
					id: prefs.payDateid,
					endmonth: prefs.payDateEndMonth,
					scaledDetails: docPrefs.payDateScaledDetails,
					xdays: docPrefs.payDateXDays

				};

				//if it's a doc update or a doc temp we take paydate in doc
			} else {

				this.paydateObj = {
					id: this.document.get('paydate').id,
					syscode: this.document.get('paydate').syscode,
					endmonth: this.document.get('paydate').endmonth,
					scaledDetails: this.document.get('paydate').scaledDetails,
					xdays: this.document.get('paydate').xdays,
					custom: this.document.get('paydate').custom,
					value: this.document.get('paydate').value
				};
			}

			//if we switch to custom paydate to xdays for example custom exist always so we need to compute him
			if (this.paydateObj.custom) {
				var momentDate = Utils.SellsyMoment(this.paydateObj.custom, 'X', true);
			} else {
				var momentDate = this.momentCreatedDate;
				this.paydateObj.custom = momentDate.unix();
			}

			switch (this.paydateObj.syscode) {

				case '30days':
				case '45days':
				case '60days':
				case '90days':

					var momentDate = Moment(this.momentCreatedDate).add(this.paydateObj.syscode.substring(0, 2), 'days');

					if (this.paydateObj.endmonth === 'Y') {
						momentDate = momentDate.endOf('month');
					}

					break;

				case 'xdays':
				case 'scaled':

					var momentDate = Moment(this.momentCreatedDate).add(this.paydateObj.xdays, 'days');
					this.paydateObj.endmonth = "N"; //no possibility to endmonth on xdays

					break;

				case 'endmonth':

					momentDate = Moment(this.momentCreatedDate).endOf('month');

					break;

				default:
					break;

			}

			this.document.set({ 'paydate': this.paydateObj });
			var HTMLPaydate = momentDate.format(App.HTMLDateFormat);
			this.$.paydate.val(HTMLPaydate);
		},

		/**
   * 
   * @param {Event} e
   * @returns {undefined}
   */
		computePaydate: function (e) {

			var $currentTarget = $(e.currentTarget);

			if ($currentTarget.attr('id') === 'createDate') {

				var firedByCreatedDate = true;
				this.momentCreatedDate = Utils.SellsyMoment($currentTarget.val(), App.HTMLDateFormat, true);
				var momentPaydate = Utils.SellsyMoment($("#paydate", this.$el).val(), App.HTMLDateFormat, true);
			} else {

				this.paydateObj.endmonth = 'N'; // si l'utilisateur change la paydate , on ne prend plus la fin du mois mais la date exact que l'utilisateur a saisi
				var momentPaydate = Utils.SellsyMoment($currentTarget.val(), App.HTMLDateFormat, true);
			}

			this.paydateObj.custom = momentPaydate.unix();

			switch (this.paydateObj.syscode) {

				//if paydate was in 30/45/60/90 days and user update input of paydate, we set it in xdays and we update xdays val
				case '30days':
				case '45days':
				case '60days':
				case '90days':

					if (!firedByCreatedDate) {

						var xdays = momentPaydate.diff(Moment(this.momentCreatedDate).startOf('days'), 'days');
						this.paydateObj.syscode = "xdays";
						this.paydateObj.id = this.xdaysPaydate.get('id');
						this.paydateObj.xdays = xdays;
					} else {

						momentPaydate = Moment(this.momentCreatedDate).add(this.paydateObj.syscode.substring(0, 2), 'days');

						if (this.paydateObj.endmonth === 'Y') {
							momentPaydate = momentPaydate.endOf('month');
						}

						$('#paydate', this.$el).val(momentPaydate.format(App.HTMLDateFormat));
					}

					break;

				case 'xdays':
				case 'scaled':

					if (!firedByCreatedDate) {

						var xdays = momentPaydate.diff(this.momentCreatedDate.startOf('days'), 'days');
						this.paydateObj.xdays = xdays;
						this.paydateObj.endmonth = "N"; //no possibility to endmonth on xdays
					} else {

						momentPaydate = this.momentCreatedDate.add(this.paydateObj.xdays, 'days');

						if (this.paydateObj.endmonth === "Y") {
							momentPaydate = momentPaydate.endOf('month');
						}

						$("#paydate", this.$el).val(momentPaydate.format(App.HTMLDateFormat));
					}

					break;

				case 'endmonth':

					if (!firedByCreatedDate) {

						//we set paydate to custom
						this.paydateObj.syscode = "custom";
						this.paydateObj.id = this.customPaydate.get('id');
						this.paydateObj.custom = momentPaydate.format(this.dateFormat);
					} else {
						momentPaydate = Moment(this.momentCreatedDate).endOf('month');
						$currentTarget.val(momentPaydate.format(App.HTMLDateFormat));
					}

					break;

			}

			this.document.set({ 'paydate': this.paydateObj });
		},

		/**
   * 
   * @param {Event} e
   * @returns {undefined}
   */
		togglecContactPart: function (e) {

			var $currentTarget = $(e.currentTarget);
			var $container = $currentTarget.parent().children('.contacts-infos');

			$container.slideToggle({

				start: function () {
					$currentTarget.toggleClass('toggled');
				}

			});
		}

	});
});
