/**
 *	@desc purOrder Model , extend Core Model 
 */
define('models/singles/purOrder',['models/singles/purchase', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0,
			doctype: 'order',
			availableSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'partialinvoiced', 'invoiced', 'cancelled']
		},

		urlRoot: '/rest/purchase/order'

	});
});
