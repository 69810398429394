/**
 *	@desc Model Product, extend Core Model 
 */
define('models/singles/annotation',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			'id': '0',
			'parentid': '0',
			'relatedtype': '',
			'relatedid': '',
			'text': '',
			'canEdit': 'Y'
		},

		storeName: 'annotation',
		name: 'annotation',
		urlRoot: '/rest/annotation/',

		/**
   * 
   * @returns {undefined}
   */
		validate: function (attributes) {

			var validationErrors = { annotation: {} };

			if (!attributes.text) {
				validationErrors.annotation.text = this.computeError({ name: 'text', type: 'required' });
			} else {
				this.attributes.text = attributes.text.replace(/\n/g, "<br>");
			}

			if (_.toArray(validationErrors.annotation).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @param {type} relateds
   * @returns {undefined}
   */
		checkRelated: function (relateds) {

			if ($.inArray(relateds.relatedtype, App.availableThirds) !== -1) {
				relateds.relatedtype = 'third';
			} else if (relateds.relatedtype == 'service') {
				relateds.relatedtype = 'item';
			} else {
				relateds = relateds;
			}

			if (relateds.relatedid.split("-").length == 2) {
				relateds.relatedid = _.first(relateds.relatedid.split("-"));
			}

			return relateds;
		}

	});
});
