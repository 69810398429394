/**
 *	@desc Warehouse Model , extend Core Model 
 */
define('models/singles/warehouse',['models/core/model', 'app'], function (Model, App) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'warehouse',
		name: 'warehouse',
		urlRoot: '/rest/warehouse'

	});
});
