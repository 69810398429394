/**
* @desc Core Collection, extend Backbone collection
*
*/
define('models/core/collection',['backbone', 'db/easy', 'tools/ajax', 'underscore', 'app', 'moment', 'tools/core/utils'], function (Backbone, DB, Ajax, _, App, Moment, Utils) {

	'use strict';

	// Say to Backbone to use our custom Ajax Requester

	Backbone.ajax = Ajax;

	return Backbone.Collection.extend({

		name: '',
		pagination: { nbperpage: 20, pagenum: 1 },
		limitperPageOffline: 20,
		offsetOffline: 0,
		getAll: false,
		searchFieldName: 'contains',
		localdataSearch: '',
		storedCollections: {},

		/**
   *
   * @returns {undefined}
   */
		constructor: function () {

			if (this.canBeStored) {

				if (!this.storedCollections[this.name]) {
					this.storedCollections[this.name] = this.constructor.prototype;
				}
			}

			this.orderBy = 'desc';
			this.offlineQueryArgs = [];
			this.defaultOfflineQueryArgs = ["relatedid", 'relatedtype', 'linkedid', 'linkedtype'];
			this.XHRData = {};

			Backbone.Collection.apply(this, arguments);
		},

		/**
   * @desc used in fetchLocal functions for filter indexedDB request with XHRData args
   * @param {type} value
   * @param {type} options
   * @returns {Boolean}
   */
		offlineQuery: function (value, options) {

			options = options ? options : {};
			options.search = options.search ? options.search : {};

			var finded = true;
			var _this = this;
			var queryArgs = _.union(this.offlineQueryArgs, this.defaultOfflineQueryArgs);

			_.each(queryArgs, function (arg) {

				var data = options.search[arg] ? options.search[arg] : options[arg];

				if (data) {

					if (_.isArray(data)) {

						//When api need array but indexedDB not
						if (data.length <= 1) {
							finded = finded && _.first(data) === value[arg];
						} else {
							finded = finded && Utils.inArray(value[arg], data);
						}
					} else {
						finded = finded && data == value[arg];
					}
				}
			});

			if (options.search.tags) {

				var arrayTags = options.search.tags.split(',');
				var tagsFound = _.intersection(value.smartTags, arrayTags);
				finded = finded && !_.isEmpty(tagsFound);
			}

			return finded;
		},

		/**
   *
   * @desc override  backbone
   *  make a local save if success
   *	make a local fetch if fail
   * @returns {Promise}
   */
		fetch: function (options, add) {

			options = options ? _.clone(options) : {};
			options.dataType = 'json';

			if (options.parse === void 0) options.parse = true;

			var success = options.success;
			var _this = this;

			options.success = function (data) {

				var method = options.reset ? 'reset' : 'set';

				method = add && add === true ? 'add' : method;

				if (_.isEmpty(data.models) && !add) {
					_this.reset();
				} else {
					_this[method](_.toArray(data.models), options);
				}

				_this.nbResults = _.size(data.models);
				_this.pagination = data.pagination && !_.isEmpty(data.pagination) ? data.pagination : {
					pagenum: 1,
					nbperpage: 20
				};
				_this.stats = data.stats;

				if (success) {
					success.call(options.context, _this, data, options);
				}

				_this.trigger('sync', _this, data, options);
			};

			return new Promise(function (resolve, reject) {

				var xhr = _this.sync('read', _this, options);

				xhr.done(function (data) {

					if (data.error) {

						//sometime we need to continue even if a collection isn't loadable
						if (data.error.code === 'E_OBJ_NOT_LOADABLE' && _this.bypassNotLoadable === true) {
							resolve();
						} else {
							reject(data.error);
						}
					} else if (App.indexedDB && _this.storeName) {

						if (!_.isUndefined(options.data) && options.data.getAll) {

							_this.saveAllLocal().then(function () {
								resolve();
							});
						} else {

							if (options.saveLocalAfterFetch !== false) {

								_this.saveLocal().then(function () {

									if (_this.canBeStored) {
										_this.constructor.prototype.isStored = true;
									}

									resolve(_this);
								});
							} else {
								resolve(_this);
							}
						}
					} else {
						resolve(_this);
					}
				});

				xhr.fail(function () {

					if (App.indexedDB && _this.storeName) {

						if (options.data !== undefined) {
							_this.fetchLocal(options.data).then(resolve, reject);
						} else {
							_this.fetchLocal().then(resolve, reject);
						}
					} else {
						reject();
					}
				});
			});
		},

		/**
   * @description send entire collection to server for save it
   * @returns {undefined}
   */
		save: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				//split collection in 2 collections : collection2save and 2update
				var ClassName = _this.constructor; //take collection constructor
				var collection2Save = new ClassName();
				var collection2Update = new ClassName();

				_this.each(function (model) {

					//if model is new set id to CID
					if (model.isNew()) {

						model.set({ id: model.cid });
						collection2Save.add(model);
						model.set({ id: 0 });
					} else {

						if (model.get('isWriteabled') === 'Y' || _.isUndefined(model.get('isWriteabled'))) {
							collection2Update.add(model);
						}
					}
				});

				var promises = [];
				var dataToSend = collection2Save;

				if (!_.isEmpty(_this.XHRData)) {

					dataToSend = {
						additionalDatas: _this.XHRData,
						collection: collection2Save.toJSON()
					};
				}

				promises.push(new Promise(function (saveSuccess, saveError) {
					if (collection2Save.length > 0) {

						Ajax({
							method: "POST",
							url: _this.url + '/save',
							data: JSON.stringify(dataToSend),
							success: function () {
								saveSuccess();
							},
							error: function () {
								console.log(arguments);
								saveError();
							}
						});
					} else {
						saveSuccess();
					}
				}));

				var dataToSend = collection2Update;

				if (!_.isEmpty(_this.XHRData)) {

					dataToSend = {
						additionalDatas: _this.XHRData,
						collection: collection2Update.toJSON()
					};
				}

				promises.push(new Promise(function (updateSuccess, updateError) {

					if (collection2Update.length > 0) {

						Ajax({
							method: "PUT",
							url: _this.url + '/save',
							data: JSON.stringify(dataToSend),
							success: function (data) {

								if (data.status != 'error') {
									updateSuccess();
								} else {
									updateError();
								}
							},
							error: function () {
								console.log(arguments);
								updateError();
							}
						});
					} else {
						updateSuccess();
					}
				}));

				Promise.all(promises).then(function () {
					resolve();
				}, function (error) {
					reject();
					console.log(error);
				});
			});
		},

		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function (options, merge) {

			var _this = this;
			var promises = [];

			if (!_.isUndefined(options)) {
				var dataSearch = options.contains ? options.contains : "";
				var getAll = options.getAll;
			} else {
				dataSearch = '';
			}

			return new Promise(function (resolve, reject) {

				//get stats
				if (_this.statsStoreName) {
					var statsPromises = _this.fetchStatsLocal();
				} else {
					var statsPromises = new Promise(function (resolve) {
						resolve();
					});
				}

				statsPromises.then(function () {

					if (getAll) {

						DB[_this.storeName].filter(function (val) {
							return _this.offlineQuery.call(_this, val, options);
						}).toArray(function (models) {

							_this.set(_.toArray(models));
							resolve();
						});
					} else {

						var beforeSearchPromise = new Promise(function (fetch, fetchRejected) {

							//reset listing when searchPattern changes
							if (dataSearch != _this.localdataSearch) {

								_this.offsetOffline = 0;
								_this.localdataSearch = dataSearch;

								_this.reset();

								//count nbtotal of search
								DB[_this.storeName].filter(function (val) {
									return _this.searchLocal.call(_this, val) && _this.offlineQuery.call(_this, val, options);
								}).count().then(function (nbTotal) {
									_this.nbResults = nbTotal;
									fetch();
								});
							} else {
								fetch();
							}
						});

						//execute after promise fetch()
						beforeSearchPromise.then(function () {

							var orderBy = _this['orderBy'];

							if (DB[_this.storeName].schema.idxByName['orderByAttribute']) {

								var query = DB[_this.storeName].orderBy('orderByAttribute');

								if (orderBy === 'desc') {
									query = query.reverse();
								}
							} else {
								var query = DB[_this.storeName].toCollection();
							}

							//listing with local search
							if (dataSearch) {

								query.and(function (value) {
									return _this.searchLocal(value) && _this.offlineQuery.call(_this, value, options);
								}).offset(_this.getOfflineOffset()).limit(_this.pagination.nbperpage).toArray().then(function (array) {

									if (merge === false) {
										_this.reset(_.toArray(array));
									} else {
										_this.add(_.toArray(array));
									}

									_this.isCached = false;
									resolve(_this);
								}, function (error) {
									console.log(error);
									reject();
								});

								// standard listing
							} else {

								DB[_this.storeName].filter(function (val) {

									var result = _this.offlineQuery.call(_this, val, options);
									return result;
								}).count().then(function (nbTotal) {

									_this.nbResults = nbTotal;
									_this.pagination.nbtotal = nbTotal;

									query.and(function (val) {
										var result = _this.offlineQuery.call(_this, val, options);
										return result;
									}).offset(_this.getOfflineOffset()).limit(_this.pagination.nbperpage).toArray().then(function (array) {

										if (merge === false) {
											_this.reset(_.toArray(array));
										} else {
											_this.add(_.toArray(array));
										}

										_this.isCached = false;
										resolve(_this);
									}, function () {
										console.log(arguments);
									});
								});
							}
						});
					}
				});
			});
		},

		/**
   * @desc Save data in local indexedDB
   * @returns {Promise}
   */
		saveLocal: function (options) {

			options = _.isUndefined(options) ? {} : options;
			var _this = this;
			var promises = [];

			return new Promise(function (resolve, reject) {

				promises.push(DB.transaction("rw", DB[_this.storeName], function () {

					_this.each(function (model) {

						model.saveLocal({
							updateLinked: options.updateLinked
						}).then(function () {}, function (err) {
							console.log(err);
						});
					});
				}));

				if (_this.stats && _this.statsStoreName) {
					_this.saveStatsLocal();
				}

				Promise.all(promises).then(function () {
					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		},

		/**
   *
   * @param {type} model
   * @returns {Boolean}
   */
		searchLocal: function (model) {

			var predicate = false;
			var myregex = new RegExp('^.*' + this.localdataSearch + ".*$", 'i');
			var _this = this;

			_.each(this.fields, function (field) {

				switch (field) {

					case 'tags':

						var tagsArray = _.toArray(model[field]);
						var tagsToFind = _this.localdataSearch.split(',');

						predicate = _.any(tagsToFind, function (tag) {
							return _.findWhere(tagsArray, { word: tag });
						});

						break;

					default:

						if (myregex.test(model[field])) {
							predicate = true;
						}

						break;

				}
			});

			return predicate;
		},

		/**
   *
   * @returns {Promise}
   */
		saveAllLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB.transaction("rw", DB[_this.storeName], function () {

					_this.each(function (model) {
						model.saveLocal();
					});
				}).then(function () {
					resolve(_this);
				}, function (error) {
					console.log(error);
					console.log(_this);
				});
			});
		},

		/**
   * @description check if we are at the end of pagination or not
   * @returns {undefined}
   */
		checkPagination: function () {

			if (this.pagination.nbperpage * this.pagination.pagenum >= this.pagination.nbtotal) {
				return false;
			} else {
				return true;
			}
		},

		/**
   * @get the offset for local query with the pagination returned by the API
   * @returns {undefined}
   */
		getOfflineOffset: function () {
			return this.pagination.nbperpage * (this.pagination.pagenum - 1);
		},

		/**
   *
   * @returns {undefined}
   */
		getNbCurrents: function (linkedtype, linkedid, additionalSearch) {

			var _this = this;

			var search = {
				start: Moment().startOf("day").unix(),
				end: Moment().endOf("day").unix(),
				relatedtype: linkedtype,
				relatedid: linkedid
			};

			var search = _.extend(search, additionalSearch ? additionalSearch : {});

			return new Promise(function (resolve, reject) {

				_this.fetch({
					method: 'POST',
					data: {
						pagination: {
							nbperpage: 20,
							pagenum: 1
						},
						search: search
					}
				}, { add: false }).then(function (collection) {

					if (_this.name === 'agenda') {

						collection.XHRData.search = {
							period: ['today'],
							relatedtype: linkedtype,
							relatedid: linkedid
						};

						collection.buildStats().then(function () {
							resolve(collection.stats.today);
						});
					} else {
						resolve(collection.pagination.nbtotal);
					}
				});
			});
		},

		/**
   *
   * @returns {undefined}
   */
		saveStatsLocal: function () {

			var _this = this;

			DB[_this.statsStoreName].clear().then(function () {
				DB[_this.statsStoreName].bulkPut(_.toArray(_this.stats));
			});
		},

		fetchStatsLocal: function () {

			var _this = this;

			return DB[_this.statsStoreName].toArray().then(function (stats) {
				_this.stats = stats;
			});
		},

		/**
   * 
   * @returns {unresolved}
   */
		clearLocal: function () {
			return DB[this.storeName].clear();
		}

	});
});
