/**
 *
 */
define('views/core/homeModule',['underscore', 'views/core/page', 'app', 'views/core/overview', 'models/collections/emailThreads'], function (_, Page, App, Overview, EmailThreads) {

	'use strict';

	return Page.extend({

		id: 'homeModule',
		listing: {},
		template: "templates/widgets/homeModule.twig",
		hideFilterPannel: true,

		/**
   * @desc initialize
   */
		initialize: function (router, links, title) {

			/**
   * when we are on home page of module, back button must redirect to home app
    */
			var homeRegexp = new RegExp("#*\/home$");
			if (homeRegexp.test(router.currentHash)) {
				this.previousLink = "#";
			}

			App.EmailThreads = EmailThreads;

			Page.prototype.initialize.apply(this, arguments);

			this.var4template('links', links);

			this.title = App.polyglot.t(title), this.overview = new Overview({ parentView: this });

			this.subviews.push(this.overview);
			this.render();
		}

	});
});
