/**
 * @desc Supplier Form, extend ThirdForm View
 * Child View : Core Form
 */
define('views/supplier/form',['backbone', 'underscore', 'views/third/form', 'views/core/form', 'views/core/toastNotification', 'tools/buildURL', 'app'], function (Backbone, _, ThirdForm, Form, ToastNotification, URLBuilder, App) {

	'use strict';

	return ThirdForm.extend({

		events: _.extend({
			"click .type": 'checkTypeAction'
		}, ThirdForm.prototype.events),

		title4Create: App.polyglot.t("addSupplier"),
		title4Update: App.polyglot.t("modifySupplier"),
		form: {},
		linked: 'supplier',
		template: "templates/supplier/form.twig",
		toastMessages: {
			thirdSaved: App.polyglot.t("supplierSaved"),
			thirdUpdated: App.polyglot.t("supplierUpdated")
		}

	});
});
