/**
 *	@desc Opportunity Model , extend Core Model 
 */
define('models/singles/opportunity',['models/core/model', 'app', 'tools/ajax', 'tools/buildURL'], function (Model, App, Ajax, URLBuilder) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'opportunity',
		name: 'opportunity',
		urlRoot: '/rest/opportunity/',

		/**
   * 
   * @param {type} attributes
   * @returns {undefined}
   */
		validate: function (attributes) {

			var required = {

				"prospection": {
					'name': true,
					'ident': true,
					'sourceid': true,
					'funnelid': true,
					'stepid': true,
					'dueDate': false,
					'potential': false,
					'proba': false,
					'brief': false,
					'staffs': false,
					'contacts': false,
					'linkedid': true
				}

			};

			if (attributes.id) {
				required['prospection']['funnelid'] = false;
				required['prospection']['stepid'] = false;
			}

			var validationErrors = {};
			var _this = this;
			validationErrors[this.name] = {};

			//check required
			_.each(required.prospection, function (attr, nameAttr) {

				if (!attributes[nameAttr] && required['prospection'][nameAttr]) {
					validationErrors[_this.name][nameAttr] = _this.computeError({ name: nameAttr, type: 'required' });
				}
			});

			if (_.toArray(validationErrors[this.name]).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @param {type} funnelid
   * @returns {Promise}
   */
		getStepsForFunnel: function (funnelid) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder([_this.urlRoot, funnelid, 'getStepsForFunnel'], false),
					success: function (data) {
						resolve(data);
					},
					error: function (error) {
						reject(error);
					}

				});
			});
		},

		/**
   * 
   * @param {type} stepid
   * @returns {undefined}
   */
		updateStep: function (stepid) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(['rest', 'opportunity', _this.id, stepid, 'updateStep'], false),
					method: 'POST',
					success: function (data) {
						resolve(data);
					},
					error: function (error) {
						reject(error);
					}

				});
			});
		},

		/**
   * 
   * @param {type} status
   * @returns {Promise}
   */
		updateStatus: function (status) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(['rest', 'opportunity', _this.id, status, 'updateStatus'], false),
					method: 'POST',
					success: function (data) {
						resolve(data);
					},
					error: function (error) {
						reject(error);
					}

				});
			});
		}

	}, {

		/**
   * 
   * @returns {undefined}
   */
		getFunnels: function () {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(["rest", "opportunity", "getFunnels"], false),
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						reject(err);
					}

				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		getCurrentIdent: function () {

			return new Promise(function (resolve, reject) {

				Ajax({

					url: URLBuilder(["rest", "opportunity", "getCurrentIdent"], false),
					success: function (data) {
						resolve(data);
					},
					error: function (err) {
						reject(err);
					}

				});
			});
		}

	});
});
