/**
 * @desc Product router, extend Core Router
 */
define('routers/annotation',['backbone', 'underscore', 'routers/core/router'], function (Backbone, _, CoreRouter) {

	'use strict';

	return CoreRouter.extend({

		sidelink: "annotation",
		checkOnline: {
			form: false
		},
		/**
   * 
   */
		routes: {
			'annotation/form(/:relatedtype)(/:relatedid)': 'form'
		},

		/**
   * 
   * @param {type} callback
   * @param {type} args
   * @returns {undefined}
   */
		execute: function (callback, args) {
			CoreRouter.prototype.execute.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		initialize: function () {
			CoreRouter.prototype.initialize.apply(this, arguments);
		},

		/**
   * 
   * @returns {undefined}
   */
		form: function (relatedtype, relatedid) {

			var _this = this;
			var trueRoute = Backbone.history.location.hash;

			return new Promise(function (resolve, reject) {

				require(['require', 'views/annotation/form'], function (require) {

					var AnnotForm = require('views/annotation/form');

					if (relatedtype && relatedid) {

						var params = {
							'relatedtype': relatedtype,
							'relatedid': relatedid
						};
					} else {
						var params = {};
					}

					var view = new AnnotForm(_this, params);
					view.trueRoute = trueRoute;

					resolve(view);
				});
			});
		}

	});
});
