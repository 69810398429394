define('views/auth/maintenance',['views/core/page', 'app'], function (Page, App) {

	return Page.extend({

		template: '\n\t\t<div class="content">\n\t\t\t<img src="/ressources/images/sellsy_logo.png"/>\n\t\t\t<% if(new RegExp(".*m.sellsy.fr").test(location.origin)) { %>\n\t\t\t<h2>Maintenance en cours</h2>\n\t\t\t<span>\n\t\t\t\t\tL\u2019\xE9quipage Sellsy proc\xE8de actuellement \xE0 une mise \xE0 jour majeure.\n\t\t\t\t\t<br/>\n\t\t\t\t\tNotre service sera de nouveau accessible \xE0 partir de 21h.\n\t\t\t\t\t<br/>\n\t\t\t\t\tVeuillez nous excusez pour la g\xEAne occasionn\xE9e.\n\t\t\t</span>\n\t\t\t<%\xA0} else { %>\n\t\t\t\t<h2 style="font-size: 24px;font-weight: 500;margin:40px 0 12px;">Maintenance in progress</h2>\n\t\t\t\t<span style="font-size: 14px;line-height:35px;">\n\t\t\t\t\tThe Sellsy crew is actually making a major update.\n\t\t\t\t\t<br/>\n\t\t\t\t\tOur service will be available again at 9 pm.\n\t\t\t\t\t<br/>\n\t\t\t\t\tPlease apologize for the inconvenience.\n\t\t\t\t\t<br/>\n\t\t\t\t</span>\n\t\t\t<% }\xA0%>\n\t\t</div>\n\t\t',

		subviews: [],
		id: 'maintenance',

		/**
   * 
   * @param {type} router
   * @returns {undefined}
   */
		initialize: function (router) {

			Page.prototype.initialize.apply(this, arguments);

			if (App.isMaintenance) {
				this.render();
			} else {
				this.router.navigate('#', { trigger: true, replace: true });
			}
		},

		/**
  * @desc render
  */
		render: function () {

			var template = _.template(this.template); //compile template with underscore

			this.$el.html(template);
			$('.content-wrapper').html(this.$el);
			$('.preloader').remove();
			this.router.setView(this);

			return this;
		}

	});
});
