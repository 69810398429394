/**
 *  @desc SmartTags Collection, extend core Collection
 *  sm ? =)
 */
define('models/collections/smarttags',['models/core/collection', 'models/singles/smarttag', 'db/easy', 'tools/ajax'], function (Collection, sm, DB, Ajax) {

	'use strict';

	var Smarttags = Collection.extend({

		model: sm,
		storeName: 'smarttags',
		name: 'smarttags',
		url: '/rest/smarttags/list',

		constructor: function () {

			var _this = this;

			Collection.apply(this, arguments);
		},

		/**
   * 
   * @param {type} linkedtype
   * @param {type} linkedid
   * @returns {Promise}
   */
		getSmartTagsByCategoryOrLinked: function (linkedtype, linkedid) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].where('linkedtype').equals(linkedtype).and(function (smarttag) {
					var found = true;
					if (linkedid) {
						found = found && smarttag.linkedid == linkedid;
					}
					return found;
				}).toArray().then(function (array) {
					resolve(array);
				}, function (error) {
					console.log(error);
				});
			});
		},

		/**
   * 
   * @param {type} options
   * @returns {Promise}
   */
		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				var query = DB[_this.storeName];

				query.filter(function (smarttag) {

					var found = true;

					if (options.search.linkedtype) {
						found = found && smarttag.linkedtype == options.search.linkedtype;
					}

					if (options.search.linkedid) {
						found = found && smarttag.linkedid == options.search.linkedid;
					}
					return found;
				}).toArray().then(function (array) {
					_this.add(array);
					resolve(array);
				}, function (error) {
					console.log(error);
				});
			});
		},

		/**
   * 
   * @param {type} payMediums2Check
   * @returns {Promise}
   */
		saveSmartTags: function (smartTags, linkedRessource) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				var smartags2Save = smartTags.join();

				Ajax({
					method: 'POST',
					url: _this.url + '/add',
					data: _.extend({
						smartTags: smartags2Save
					}, _this.XHRData.search),
					dataType: "json",
					success: function (smarttags) {

						_this.set(_.toArray(smarttags.models));
						//delete old smarttags of linked
						_this.saveLocal(linkedRessource).then(function () {
							resolve();
						});
					},
					error: function () {
						reject();
					}
				});
			});
		},

		/**
   * 
   * @param {type} linkedRessource
   * @returns {undefined}
   */
		saveLocal: function (linkedRessource) {

			var _this = this;

			if (linkedRessource) {

				var linkedid = linkedRessource.linkedid;
				var linkedtype = linkedRessource.linkedtype;
			} else {

				var linkedid = _this.XHRData.search.linkedid;
				var linkedtype = _this.XHRData.search.linkedtype;
			}

			return new Promise(function (resolve, reject) {

				if (linkedid) {

					var query = DB[_this.storeName].where("linkedid").equals("" + linkedid).and(function (smarttag) {
						return smarttag.linkedtype === linkedtype;
					}).delete();
				} else {

					var p = new Promise(function (resolve, reject) {
						resolve();
					});

					var query = p;
				}

				query.then(function (nbRow) {

					DB.transaction("rw", DB[_this.storeName], function () {

						_this.each(function (model) {

							model.saveLocal().then(function () {}, function (err) {
								console.log(err);
							});
						});
					}).then(function () {
						resolve(_this);
					}, function (err) {
						reject(err);
					});
				}, function (err) {
					console.log(err);
				});
			});
		}

	}, {

		/**
   * STATIC METHODS
   */

		/**
   * 
   * 
   * @returns {Object}
   */
		buildFilterByLinked: function (linkedtype, linkedid) {

			switch (linkedtype) {

				case 'third':
				case 'prospect':
				case 'supplier':
					var filters = {
						linkedtype: 'third',
						category: linkedtype,
						categoryForIdb: "Société / Contact"
					};

					break;

				case 'client':

					filters = {
						linkedtype: 'third',
						category: 'third',
						categoryForIdb: "Société / Contact"
					};

					break;

				case 'people':

					var filters = {
						linkedtype: linkedtype,
						category: 'third',
						categoryForIdb: "Société / Contact"
					};

					break;

				case 'opportunity':

					var filters = {
						linkedtype: linkedtype,
						category: linkedtype,
						categoryForIdb: "Prospection"
					};

					break;

				case 'invoice':
				case 'estimate':
				case 'proforma':
				case 'order':
				case 'delivery':
				case 'creditnote':

					var filters = {
						linkedtype: linkedtype,
						category: 'doc',
						categoryForIdb: 'Documents'
					};

					break;

				case 'purInvoice':
				case 'purEstimate':
				case 'purProforma':
				case 'purOrder':
				case 'purDelivery':
				case 'purCreditNote':

					var filters = {
						linkedtype: linkedtype,
						category: 'purchase',
						categoryForIdb: 'Achats'
					};

					break;

				case 'ticket':

					var filters = {
						linkedtype: linkedtype,
						category: 'support',
						categoryForIdb: 'Ticket'
					};

					break;

				case 'timetracking':

					var filters = {};

					break;

				case 'expense':

					var filters = {
						linkedtype: linkedtype,
						category: linkedtype,
						categoryForIdb: 'Note de frais'
					};

					break;

				case 'item':
				case 'service':

					var filters = {
						linkedtype: linkedtype,
						category: 'catalogue',
						categoryForIdb: 'Catalogue'
					};

					break;

			}

			!_.isUndefined(linkedid) && (filters['linkedid'] = linkedid);

			return filters;
		}

	});

	return Smarttags;
});
