/* big.js v3.0.1 https://github.com/MikeMcl/big.js/LICENCE */(function (n) {
  "use strict";
  function c() {
    function n(t) {
      var i = this;if (!(i instanceof n)) return t === void 0 ? c() : new n(t);t instanceof n ? (i.s = t.s, i.e = t.e, i.c = t.c.slice()) : y(i, t);i.constructor = n;
    }return n.prototype = t, n.DP = l, n.RM = a, n;
  }function s(n, t, i) {
    var e = n.constructor,
        r = t - (n = new e(n)).e,
        f = n.c;for (f.length > ++t && o(n, r, e.RM), f[0] ? i ? r = t : (f = n.c, r = n.e + r + 1) : ++r; f.length < r; f.push(0));return r = n.e, i === 1 || i && (t <= r || r <= u) ? (n.s < 0 && f[0] ? "-" : "") + (f.length > 1 ? f[0] + "." + f.join("").slice(1) : f[0]) + (r < 0 ? "e" : "e+") + r : n.toString();
  }function y(n, t) {
    var u, r, f;for (t === 0 && 1 / t < 0 ? t = "-0" : v.test(t += "") || i(NaN), n.s = t.charAt(0) == "-" ? (t = t.slice(1), -1) : 1, (u = t.indexOf(".")) > -1 && (t = t.replace(".", "")), (r = t.search(/e/i)) > 0 ? (u < 0 && (u = r), u += +t.slice(r + 1), t = t.substring(0, r)) : u < 0 && (u = t.length), r = 0; t.charAt(r) == "0"; r++);if (r == (f = t.length)) n.c = [n.e = 0];else {
      for (; t.charAt(--f) == "0";);for (n.e = u - r - 1, n.c = [], u = 0; r <= f; n.c[u++] = +t.charAt(r++));
    }return n;
  }function o(n, t, r, u) {
    var o,
        e = n.c,
        f = n.e + t + 1;if (r === 1 ? u = e[f] >= 5 : r === 2 ? u = e[f] > 5 || e[f] == 5 && (u || f < 0 || e[f + 1] !== o || e[f - 1] & 1) : r === 3 ? u = u || e[f] !== o || f < 0 : (u = !1, r !== 0 && i("!Big.RM!")), f < 1 || !e[0]) u ? (n.e = -t, n.c = [1]) : n.c = [n.e = 0];else {
      if (e.length = f--, u) for (; ++e[f] > 9;) e[f] = 0, f-- || (++n.e, e.unshift(1));for (f = e.length; !e[--f]; e.pop());
    }return n;
  }function i(n) {
    var t = new Error(n);t.name = "BigError";throw t;
  }var l = 20,
      a = 1,
      r = 1e6,
      h = 1e6,
      u = -7,
      f = 21,
      t = {},
      v = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i,
      e;t.abs = function () {
    var n = new this.constructor(this);return n.s = 1, n;
  };t.cmp = function (n) {
    var e,
        o = this,
        u = o.c,
        f = (n = new o.constructor(n)).c,
        t = o.s,
        s = n.s,
        i = o.e,
        r = n.e;if (!u[0] || !f[0]) return u[0] ? t : f[0] ? -s : 0;if (t != s) return t;if (e = t < 0, i != r) return i > r ^ e ? 1 : -1;for (t = -1, s = (i = u.length) < (r = f.length) ? i : r; ++t < s;) if (u[t] != f[t]) return u[t] > f[t] ^ e ? 1 : -1;return i == r ? 0 : i > r ^ e ? 1 : -1;
  };t.div = function (n) {
    var p = this,
        w = p.constructor,
        h = p.c,
        e = (n = new w(n)).c,
        v = p.s == n.s ? 1 : -1,
        c = w.DP;if ((c !== ~~c || c < 0 || c > r) && i("!Big.DP!"), !h[0] || !e[0]) return h[0] == e[0] && i(NaN), e[0] || i(v / 0), new w(v * 0);var s,
        b,
        y,
        l,
        f,
        tt,
        it = e.slice(),
        k = s = e.length,
        rt = h.length,
        t = h.slice(0, s),
        u = t.length,
        a = n,
        d = a.c = [],
        g = 0,
        nt = c + (a.e = p.e - n.e) + 1;for (a.s = v, v = nt < 0 ? 0 : nt, it.unshift(0); u++ < s; t.push(0));do {
      for (y = 0; y < 10; y++) {
        if (s != (u = t.length)) l = s > u ? 1 : -1;else for (f = -1, l = 0; ++f < s;) if (e[f] != t[f]) {
          l = e[f] > t[f] ? 1 : -1;break;
        }if (l < 0) {
          for (b = u == s ? e : it; u;) {
            if (t[--u] < b[u]) {
              for (f = u; f && !t[--f]; t[f] = 9);--t[f];t[u] += 10;
            }t[u] -= b[u];
          }for (; !t[0]; t.shift());
        } else break;
      }d[g++] = l ? y : ++y;t[0] && l ? t[u] = h[k] || 0 : t = [h[k]];
    } while ((k++ < rt || t[0] !== tt) && v--);return d[0] || g == 1 || (d.shift(), a.e--), g > nt && o(a, c, w.RM, t[0] !== tt), a;
  };t.eq = function (n) {
    return !this.cmp(n);
  };t.gt = function (n) {
    return this.cmp(n) > 0;
  };t.gte = function (n) {
    return this.cmp(n) > -1;
  };t.lt = function (n) {
    return this.cmp(n) < 0;
  };t.lte = function (n) {
    return this.cmp(n) < 1;
  };t.sub = t.minus = function (n) {
    var f,
        u,
        o,
        c,
        s = this,
        l = s.constructor,
        e = s.s,
        i = (n = new l(n)).s;if (e != i) return n.s = -i, s.plus(n);var t = s.c.slice(),
        a = s.e,
        r = n.c,
        h = n.e;if (!t[0] || !r[0]) return r[0] ? (n.s = -i, n) : new l(t[0] ? s : 0);if (e = a - h) {
      for ((c = e < 0) ? (e = -e, o = t) : (h = a, o = r), o.reverse(), i = e; i--; o.push(0));o.reverse();
    } else for (u = ((c = t.length < r.length) ? t : r).length, e = i = 0; i < u; i++) if (t[i] != r[i]) {
      c = t[i] < r[i];break;
    }if (c && (o = t, t = r, r = o, n.s = -n.s), (i = (u = r.length) - (f = t.length)) > 0) for (; i--; t[f++] = 0);for (i = f; u > e;) {
      if (t[--u] < r[u]) {
        for (f = u; f && !t[--f]; t[f] = 9);--t[f];t[u] += 10;
      }t[u] -= r[u];
    }for (; t[--i] === 0; t.pop());for (; t[0] === 0;) t.shift(), --h;return t[0] || (n.s = 1, t = [h = 0]), n.c = t, n.e = h, n;
  };t.mod = function (n) {
    var e,
        t = this,
        r = t.constructor,
        u = t.s,
        f = (n = new r(n)).s;return (n.c[0] || i(NaN), t.s = n.s = 1, e = n.cmp(t) == 1, t.s = u, n.s = f, e) ? new r(t) : (u = r.DP, f = r.RM, r.DP = r.RM = 0, t = t.div(n), r.DP = u, r.RM = f, this.minus(t.times(n)));
  };t.add = t.plus = function (n) {
    var u,
        e = this,
        s = e.constructor,
        i = e.s,
        f = (n = new s(n)).s;if (i != f) return n.s = -f, e.minus(n);var h = e.e,
        t = e.c,
        o = n.e,
        r = n.c;if (!t[0] || !r[0]) return r[0] ? n : new s(t[0] ? e : i * 0);if (t = t.slice(), i = h - o) {
      for (i > 0 ? (o = h, u = r) : (i = -i, u = t), u.reverse(); i--; u.push(0));u.reverse();
    }for (t.length - r.length < 0 && (u = r, r = t, t = u), i = r.length, f = 0; i;) f = (t[--i] = t[i] + r[i] + f) / 10 | 0, t[i] %= 10;for (f && (t.unshift(f), ++o), i = t.length; t[--i] === 0; t.pop());return n.c = t, n.e = o, n;
  };t.pow = function (n) {
    var t = this,
        u = new t.constructor(1),
        r = u,
        f = n < 0;for ((n !== ~~n || n < -h || n > h) && i("!pow!"), n = f ? -n : n;;) {
      if (n & 1 && (r = r.times(t)), n >>= 1, !n) break;t = t.times(t);
    }return f ? u.div(r) : r;
  };t.round = function (n, t) {
    var u = this,
        f = u.constructor;return n == null ? n = 0 : (n !== ~~n || n < 0 || n > r) && i("!round!"), o(u = new f(u), n, t == null ? f.RM : t), u;
  };t.sqrt = function () {
    var f,
        n,
        e,
        r = this,
        u = r.constructor,
        h = r.c,
        t = r.s,
        s = r.e,
        c = new u("0.5");if (!h[0]) return new u(r);t < 0 && i(NaN);t = Math.sqrt(r.toString());t === 0 || t === 1 / 0 ? (f = h.join(""), f.length + s & 1 || (f += "0"), n = new u(Math.sqrt(f).toString()), n.e = ((s + 1) / 2 | 0) - (s < 0 || s & 1)) : n = new u(t.toString());t = n.e + (u.DP += 4);do e = n, n = c.times(e.plus(r.div(e))); while (e.c.slice(0, t).join("") !== n.c.slice(0, t).join(""));return o(n, u.DP -= 4, u.RM), n;
  };t.mul = t.times = function (n) {
    var t,
        s = this,
        h = s.constructor,
        f = s.c,
        e = (n = new h(n)).c,
        o = f.length,
        i = e.length,
        u = s.e,
        r = n.e;if (n.s = s.s == n.s ? 1 : -1, !f[0] || !e[0]) return new h(n.s * 0);for (n.e = u + r, o < i && (t = f, f = e, e = t, r = o, o = i, i = r), t = new Array(r = o + i); r--; t[r] = 0);for (u = i; u--;) {
      for (i = 0, r = o + u; r > u;) i = t[r] + e[u] * f[r - u - 1] + i, t[r--] = i % 10, i = i / 10 | 0;t[r] = (t[r] + i) % 10;
    }for (i && ++n.e, t[0] || t.shift(), u = t.length; !t[--u]; t.pop());return n.c = t, n;
  };t.toString = t.valueOf = t.toJSON = function () {
    var r = this,
        t = r.e,
        n = r.c.join(""),
        i = n.length;if (t <= u || t >= f) n = n.charAt(0) + (i > 1 ? "." + n.slice(1) : "") + (t < 0 ? "e" : "e+") + t;else if (t < 0) {
      for (; ++t; n = "0" + n);n = "0." + n;
    } else if (t > 0) {
      if (++t > i) for (t -= i; t--; n += "0");else t < i && (n = n.slice(0, t) + "." + n.slice(t));
    } else i > 1 && (n = n.charAt(0) + "." + n.slice(1));return r.s < 0 && r.c[0] ? "-" + n : n;
  };t.toExponential = function (n) {
    return n == null ? n = this.c.length - 1 : (n !== ~~n || n < 0 || n > r) && i("!toExp!"), s(this, n, 1);
  };t.toFixed = function (n) {
    var t,
        e = this,
        o = u,
        h = f;return u = -(f = 1 / 0), n == null ? t = e.toString() : n === ~~n && n >= 0 && n <= r && (t = s(e, e.e + n), e.s < 0 && e.c[0] && t.indexOf("-") < 0 && (t = "-" + t)), u = o, f = h, t || i("!toFix!"), t;
  };t.toPrecision = function (n) {
    return n == null ? this.toString() : ((n !== ~~n || n < 1 || n > r) && i("!toPre!"), s(this, n - 1, 2));
  };e = c();typeof define == "function" && define.amd ? define('big',[],function () {
    return e;
  }) : typeof module != "undefined" && module.exports ? module.exports = e : n.Big = e;
})(this);
