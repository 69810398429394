/**
 *	@desc Purchase Model , extend Core Model 
 */
define('models/singles/purchase',['models/core/model', 'app', 'tools/ajax', 'tools/buildURL', 'tools/core/utils'], function (Model, App, Ajax, URLBuilder, Utils) {

	'use strict';

	return Model.extend({

		defaults: {
			id: 0
		},

		storeName: 'purchase',
		name: 'purchase',
		urlRoot: '/rest/purchase',
		isStepAllowed: function () {
			return true;
		},
		isEditable: function () {
			return true;
		},

		updateStep: function (nextstep) {

			return new Promise(_.bind(function (resolve, reject) {

				Ajax({
					url: URLBuilder([this.urlRoot, this.id, nextstep], false),
					method: "POST",
					success: function (data) {

						if (!data.error) {
							resolve(nextstep);
						} else {
							reject();
						}
					},
					error: function (err) {
						reject(err);
					}

				});
			}, this));
		},

		/**
   * 
   * @param {type} attributes
   * @returns {undefined}
   */
		validate: function (attributes) {

			var attributesToValidate = {
				"purchase": attributes['purchase'],
				"payments": attributes['payments'],
				"rows": attributes['rows']
			};

			var required = {
				"purchase": {
					'ident': true,
					'thirdid': true,
					'externalident': false,
					'currency': false,
					'displayedDate': true
				},

				"payments": {
					"amount": true,
					"mediumid": true,
					"ident": true,
					"date": true,
					"notes": false
				},

				"rows": {
					type: true,
					unitAmount: true,
					name: true,
					qt: true,
					notes: false,
					accountingCode: false,
					taxid: true,
					unitAmountTaxesInc: false
				}

			};

			var _this = this;

			var validationErrors = {};
			validationErrors.purchase = {};
			validationErrors.rows = {};
			validationErrors.payments = {};

			//check required
			_.each(attributesToValidate, function (array, arrayKey) {
				// first level : purchase/payments/rows arrays

				_.each(array, function (val, nameAttr) {
					//second level, purchase values/payment/row

					if (_.isObject(val) || _.isArray(val)) {
						//if it's not a value but an array/object, we have a third level

						_.each(val, function (valAttribute, key) {

							if (!valAttribute && required[arrayKey][key]) {

								if (!validationErrors[arrayKey][nameAttr]) {
									validationErrors[arrayKey][nameAttr] = {};
								}

								validationErrors[arrayKey][nameAttr][key] = _this.computeError({ name: nameAttr, type: 'required' });
							}
						});
					} else {
						//else jsut validate values

						if (!val && required[arrayKey][nameAttr]) {
							validationErrors[arrayKey][nameAttr] = _this.computeError({ name: nameAttr, type: 'required' });
						}
					}
				});
			});

			if (_.toArray(validationErrors[this.name]).length > 0 || _.toArray(validationErrors.payments).length > 0 || _.toArray(validationErrors.rows).length > 0) {
				return validationErrors;
			}
		}

	}, {

		/**
   * @description return the right constructor for the given doctype
   * @returns {undefined}
   */
		classNameByDoctype: function (doctype) {

			var className = '';

			return new Promise(function (resolve, reject) {

				switch (doctype) {

					case 'purInvoice':

						require(['models/singles/purInvoice'], function (Invoice) {
							className = Invoice;
							resolve(className);
						});

						break;

					case 'purCreditNote':

						require(['models/singles/purCreditNote'], function (CreditNote) {
							className = CreditNote;
							resolve(className);
						});

						break;

					case 'purOrder':

						require(['models/singles/purOrder'], function (Order) {
							className = Order;
							resolve(className);
						});

						break;

					case 'purDelivery':

						require(['models/singles/purDelivery'], function (Delivery) {
							className = Delivery;
							resolve(className);
						});

						break;

					default:

						reject('invalid doctype : ' + doctype);
						break;

				}
			});
		},

		/**
   * 
   * @param {type} doctype
   * @returns {undefined}
   */
		getNextIdent: function (doctype) {

			return new Promise(function (resolve, reject) {

				Ajax({
					url: '/rest/purchase/' + doctype + '/nextIdent',
					success: function (data) {
						resolve(data);
					},
					error: function (error) {
						reject(error);
					}

				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		toPurDoctype: function (doctype) {
			return doctype === 'creditnote' ? 'purCreditNote' : 'pur' + Utils.capitalizeFirstLetter(doctype);
		}

	});
});
