/**
 *  @desc PayDates Collection, extend core Collection
 */
define('models/collections/paydates',['models/core/collection', 'models/singles/paydate', 'db/easy', 'tools/ajax'], function (Collection, PayDate, DB, Ajax) {

	'use strict';

	var payMediumsCollection = Collection.extend({

		model: PayDate,
		storeName: 'paydate',
		name: 'paydates',
		url: '/rest/paydate/list',
		canBeStored: true,
		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function (options) {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].toArray().then(function (array) {

					_this.reset(array);

					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		}

	});

	return payMediumsCollection;
});
