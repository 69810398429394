/**
 *  @desc LinkedPayments Doc Collection, extend core Payments Collection
 */
define('models/collections/linkedPaymentDoc',['models/collections/payments', 'models/singles/payment', 'db/easy'], function (Collection, Payment, DB) {

	'use strict';

	return Collection.extend({
		url: '/rest/document/paymentList/',
		name: 'docPayments',

		/**
   * @desc Fetch data in local indexedDB
   * @returns {Promise}
   */
		fetchLocal: function (options) {

			var _this = this;

			_this.loadMore = true;

			if (!_.isUndefined(options)) {
				var dataSearch = options.contains;
				var getAll = options.getAll;
			}

			return new Promise(function (resolve, reject) {

				if (getAll) {

					DB[_this.storeName].toArray(function (models) {
						_this.set(models);
						resolve();
					});
				} else {

					//reset listing when searchPattern changes
					if (dataSearch !== _this.localdataSearch) {
						_this.localdataSearch = dataSearch;
						_this.reset();
					}

					//listing with local search
					if (dataSearch !== '') {

						DB[_this.storeName].orderBy('orderByAttribute').and(_this.filterByDoc.bind(_this, _this.XHRData.docid, _this.XHRData.doctype)).and(_this.searchLocal.bind(_this)).toArray().then(function (array) {

							_this.add(array);

							resolve();
						}, function (error) {
							console.log(error);
							reject();
						});

						// standard listing
					} else {

						DB[_this.storeName].orderBy('orderByAttribute').and(_this.filterByDoc.bind(_this, _this.XHRData.docid, _this.XHRData.doctype)).toArray().then(function (array) {

							_this.add(array);

							resolve(_this);
						}, function () {
							console.log(arguments);
						});
					}
				}
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		saveLocal: function (options) {

			options = _.isUndefined(options) ? {} : options;
			var _this = this;

			return new Promise(function (resolve, reject) {

				DB.transaction("rw", DB[_this.storeName], function () {

					_this.each(function (model) {

						model.saveLocal({
							updateLinked: options.updateLinked,
							initiator: 'collection'
						}).then(function () {}, function (err) {
							console.log(err);
						});
					});
				}).then(function () {
					resolve(_this);
				}, function () {
					console.log(arguments);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		filterByDoc: function (docid, doctype, payment) {

			var predicate = false;

			if (payment.docid === docid && payment.doctype === doctype) {
				predicate = true;
			}

			return predicate;
		}

	});
});
