/**
 *  @desc Supports Collection, extend core Collection
 */
define('models/collections/tickets',['models/core/collection', 'models/singles/ticket'], function (Collection, Support) {

	'use strict';

	return Collection.extend({

		model: Support,
		storeName: 'support',
		name: 'tickets',
		url: '/rest/support/list',
		fields: ['message', 'thirdName', 'requesterEmail', 'subject', 'ident'],

		/**
   * 
   * @returns {undefined}
   */
		constructor: function () {
			Collection.apply(this, arguments);
			this.offlineQueryArgs = ['thirdid', 'step', 'staffid'];
		}

	});
});
