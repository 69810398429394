define('tools/monitoring',[], function () {

	'use strict';

	var Monitoring = function () {

		// check if new relic is activated
		this.isNRActivated = window.newrelic ? true : false;
		this.isGActivated = window.ga ? true : false;
	};

	/**
  * @desc return numberFormat info
  * @returns {undefined}
  */
	Monitoring.prototype.setPageView = function (pageName) {

		pageName = pageName ? pageName : "#";

		// new relic
		if (this.isNRActivated) {
			window.newrelic.setPageViewName(pageName);
		}

		// google analytics
		if (this.isGActivated) {
			ga('set', { page: pageName });
			ga('send', 'pageview');
		}
	};

	/**
  * 
  * @returns {undefined}
  */
	Monitoring.prototype.captureException = function (e, datas) {

		if (window.reportErrors) {
			Raven.captureException(e, datas);
		} else {
			console.log(e, datas);
		}
	};

	Monitoring.prototype.setUser = function (staff, corp) {

		Raven.setUserContext({
			id: staff.id,
			corpid: corp.get('linkedid')
		});
	};

	return Monitoring;
});
