/**
 * @desc Popup for add payment to invoice, Extend Popup core
 * Child View : Core Form
 */
define('views/agenda/popupRecurringAlert',['underscore', 'views/core/Popup', 'views/core/form', 'views/core/toastNotification', 'app', 'models/singles/event', 'moment', 'tools/buildURL', 'tools/core/utils'], function (_, Popup, Form, ToastNotification, App, Event, Moment, URLBuilder, Utils) {

  'use strict';

  return Popup.extend({

    models: {},
    collections: {},
    parentView: {},
    events: _.extend({}, Popup.prototype.events),
    options: {
      extraData: {}
    },
    toastMessage: {
      event: App.polyglot.t('eventUpdated')
    },

    /**
     * @desc initialize
     */
    initialize: function (options, router) {

      Popup.prototype.initialize.apply(this, arguments);

      this.var4template('provider', options.models.prefs.provider);
      this.var4template('hideNext', options.models.hideNext);

      this.subviews = [];
      this.parentView = options.parentView;
      this.template = "/templates/agenda/popupRecurringAlert.twig";
      this.router = router;
      this.classes = "form";
      this.parentModels = options.models;
      this.isUpdate = options.isUpdate; //check if action is an update or remove
      this.listingHash = options.listingHash;
      this.process = options.process === false ? false : true; //si process= false on ne fait pas tout le traitement du form action, on retourne juste la valeur choisie dans la popup
    },

    /**
     *
     * @returns {undefined}
     */
    startup: function () {

      this.form = new Form({ parentView: this }, $('.popup-content', this.$el));
      this.subviews.push(this.form);

      this.listenTo(this.form, 'dataSend', this.formAction);
      this.listenTo(this, 'renderend', this.afterRender);

      this.initBtns();
    },

    /**
     *
     * @returns {undefined}
     */
    formAction: function (context) {

      var _this = this;
      var dataForm = this.form.dataForm;
      var event = this.parentModels.event;

      if (!this.process) {

        if (!dataForm.recurrence.updateMode) {
          $('#error-reporting').show();
        } else {
          this.parentView.$el.find("input[name='participate']").first().trigger('change', dataForm.recurrence.updateMode);
          this.hide();
          return false;
        }
      }

      if (this.isUpdate) {

        var originalEvent = this.parentModels.originalEvent;

        //map originalEvent params
        //used when update recurringEvent. sometime we need to update original event too
        var recurrenceParams = originalEvent.get('recurring_params');
        var exceptions = recurrenceParams['exceptions'] ? recurrenceParams['exceptions'] : [];
        recurrenceParams['weekMode'] = recurrenceParams['recur_weekMode'];
        recurrenceParams['endMode'] = recurrenceParams['recur_endMode'];
        recurrenceParams['endValue'] = recurrenceParams['recur_endOccurences'];
        recurrenceParams['endOccurences'] = recurrenceParams['recur_endOccurences'];
        recurrenceParams['endDateValue'] = recurrenceParams['recur_endDate'];
        recurrenceParams['monthMode'] = recurrenceParams['recur_monthMode'];
        recurrenceParams['frequency'] = recurrenceParams['recur_frequency'];

        var m = Moment(originalEvent.get("parent_start"), "YYYY-MM-DD HH:mm:ss");
        var originalStart = m.unix();
        var delta = new Moment(event.timestampEnd, 'X').diff(Moment(event.timestampStart, "X"), 'minutes');
        var timestampEnd = new Moment(originalStart, "X").add(delta, 'minutes').unix();

        originalEvent.set({
          id: originalEvent.get('originalid'),
          label: originalEvent.get('label'),
          start: originalStart,
          end: timestampEnd,
          use_recurrence: 'Y',
          exceptions: exceptions,
          recurrence: recurrenceParams
        });
      }

      if (!dataForm.recurrence.updateMode) {
        $('#error-reporting').show();
      } else {

        this.$el.find('.modal-content').prepend('<div class="popup-loader">' + this.fa_loader + '</div>');

        var recurrenceParams = event.recurring_params;
        var exceptions = recurrenceParams['exceptions'] ? recurrenceParams['exceptions'] : [];
        recurrenceParams['weekMode'] = recurrenceParams['recur_weekMode'];
        recurrenceParams['endMode'] = recurrenceParams['recur_endMode'];
        recurrenceParams['endValue'] = recurrenceParams['recur_endOccurences'];
        recurrenceParams['endOccurences'] = recurrenceParams['recur_endOccurences'];
        recurrenceParams['endDateValue'] = recurrenceParams['recur_endDate'];
        recurrenceParams['monthMode'] = recurrenceParams['recur_monthMode'];
        recurrenceParams['frequency'] = recurrenceParams['recur_frequency'];

        if (recurrenceParams['endDateValue']) {
          recurrenceParams['endDateValue'] = Moment(recurrenceParams['endDateValue'], App.dateFormat.momentFormat).unix();
        }

        var eventToSave = new Event({
          id: event.originalid,
          start: event.start,
          end: event.end,
          description: event.description,
          label: event.label,
          allDay: event.allDay,
          isPrivate: event.isPrivate,
          use_recurrence: event.isRecurring,
          exceptions: exceptions,
          title: event.title,
          recurrence: recurrenceParams
        });

        if (event.staffids) {
          eventToSave.set({ staffids: event.staffids });
        } else if (event.staff) {
          eventToSave.set({ staff: event.staff });
        }

        if (Utils.inArray(this.parentModels.prefs['provider'], ["outlook", "google"])) {

          eventToSave.set({
            calendar: event.calendar ? event.calendar : event.calendarId,
            originalcalendar: event.originalcalendar,
            participants: event.participants,
            typeModifyRecurring: dataForm.recurrence.updateMode,
            recurringEventId: event.recurringEventId
          });
        } else if (!this.isUpdate) {
          eventToSave.formatToSave();
        }

        var timestampStart = Moment(event.start, "YYYY-MM-DD HH:mm:ss").unix();

        switch (dataForm.recurrence.updateMode) {

          case 'only':

            if (!this.isUpdate) {

              if (Utils.inArray(this.parentModels.prefs['provider'], ["outlook", "google"])) {

                eventToSave.destroy({
                  data: JSON.stringify({
                    isEventRecurring: "Y",
                    typeDeleteRecurring: "only",
                    calendar: eventToSave.get('calendar')
                  })
                }).then(function () {
                  _this.hide();
                  _this.parentView.deleteCallback();
                });
              } else {

                eventToSave.set('start', Moment(event.parent_start).unix());
                eventToSave.set('end', Moment(event.parent_end).unix());

                //update recurrence rule for exclude this occurrence
                exceptions.push(timestampStart);

                eventToSave.save({}, { validate: false }).then(function () {
                  _this.hide();
                  _this.parentView.deleteCallback();
                });
              }
            } else {

              var promises = [];

              if (Utils.inArray(this.parentModels.prefs['provider'], ["outlook", "google"])) {

                var options = { 'savelocal': false, 'fetchAfterSave': true };
                promises.push(eventToSave.save(options, { validate: false }));
              } else {

                //exclude this occurrence and create new normal event
                exceptions.push(event.start);

                eventToSave.unset('id');
                eventToSave.set({ use_recurrence: "N" });

                originalEvent.set({
                  'exceptions': exceptions
                });

                promises.push(originalEvent.save({}, { validate: false }));

                var options = { 'savelocal': false, 'fetchAfterSave': true };
                promises.push(eventToSave.save(options, { validate: false }));
              }

              Promise.all(promises).then(function () {
                _this.hide();
                _this.parentView.router.navigate(_this.listingHash, { trigger: true, replace: true });
                _this.parentView.form.trigger('successful', _this.parentView.toastMessages['eventUpdated']);
              });
            }

            break;

          case 'next':

            //set end and start date of parent event 
            eventToSave.set("start", Moment(event.parent_start, "YYYY-MM-DD HH:mm:ss").unix());
            eventToSave.set("end", Moment(event.parent_end, "YYYY-MM-DD HH:mm:ss").unix());

            if (!this.isUpdate) {

              if (Utils.inArray(this.parentModels.prefs['provider'], ["outlook", "google"])) {

                eventToSave.destroy({
                  data: JSON.stringify({
                    isEventRecurring: "Y",
                    typeDeleteRecurring: "next",
                    calendar: eventToSave.get('calendar')
                  })
                }).then(function () {
                  _this.hide();
                  _this.parentView.deleteCallback("next");
                });
              } else {

                //update recurrence_end to occurence date -1d
                var recurrenceEnd = Moment(timestampStart, 'X').subtract(1, "days").format('X');

                recurrenceParams.endMode = 'date';
                recurrenceParams.endDateValue = recurrenceEnd;

                eventToSave.save({}, { validate: false }).then(function () {
                  _this.hide();
                  _this.parentView.deleteCallback(dataForm.recurrence.updateMode);
                });
              }
            } else {

              //On arrete la récurrence en cours à la date de l'ocurrence - 1J et on créé un nouvel event en fonction des params
              var promises = [];

              var recurrenceEnd = Moment(timestampStart, 'X').subtract(1, "days").format('X');

              //create new event
              eventToSave.unset("id");
              var options = { 'savelocal': false, 'fetchAfterSave': true };
              eventToSave.set({ recurrence: event.recurrence });
              promises.push(eventToSave.save(options, { validate: false }));

              //update parent event for stop recurrence at the date where start the new one
              recurrenceParams.endMode = 'date';
              recurrenceParams.endDateValue = recurrenceEnd;
              promises.push(originalEvent.save({ recurrence: recurrenceParams }, { validate: false }));

              Promise.all(promises).then(function () {
                _this.hide();
                _this.parentView.router.navigate(_this.listingHash, { trigger: true, replace: true });
                _this.parentView.form.trigger('successful', _this.parentView.toastMessages['eventUpdated']);
              });
            }

            break;

          case 'all':

            if (!this.isUpdate) {

              if (Utils.inArray(this.parentModels.prefs['provider'], ["outlook", "google"])) {

                eventToSave.destroy({
                  data: JSON.stringify({
                    isEventRecurring: "Y",
                    typeDeleteRecurring: "all",
                    calendar: eventToSave.get('calendar')
                  })
                }).then(function () {
                  _this.hide();
                  _this.parentView.deleteCallback("all");
                });
              } else {

                //delete events and recurring ocurrences
                eventToSave.destroy({}).then(function () {
                  _this.hide();
                  _this.parentView.deleteCallback(dataForm.recurrence.updateMode);
                });
              }
            } else {
              //on modifie l'event parent pour affecter toutes les occurences
              eventToSave.set("recurrence", event.recurrence);

              var options = { 'savelocal': false, 'fetchAfterSave': true };

              eventToSave.save(options, { validate: false }).then(function () {
                _this.hide();
                _this.parentView.router.navigate(_this.listingHash, { trigger: true, replace: true });
                _this.parentView.form.trigger('successful', _this.parentView.toastMessages['eventUpdated']);
              });
            }

            break;

        }
      }

      $('.submit-form', context).prop('disabled', false);
    }

  });
});
