/**
 * @description Popup for trigger some actions on a model
 * @param {type} $
 * @param {type} Popup
 * @param {type} Ajax
 * @param {type} Big
 * @returns {unresolved}
 */
define('views/core/popupAction',['jquery', 'views/core/Popup'], function ($, Popup) {

	'use strict';

	return Popup.extend({

		id: '',
		className: "modal fade",
		tagName: 'div',
		title: '',
		buttons: [],
		width: '',
		height: '',
		data: {},
		callback: '',
		closeAllBefore: true,
		models: {},
		containerBtn: '.popup-content',

		/**
   * 
   * @param {type} options
   * @returns {undefined}
   */
		initialize: function (options) {

			this.id = options.id;
			this.title = options.title;
			this.buttons = options.buttons;
			this.content = options.content;
			this.callback = options.callback;
			if (options.containerBtn) {
				this.containerBtn = options.containerBtn;
			}

			this.$el.on('show.bs.modal', this.reposition.bind(this));

			this.render();
		},

		destroy: function () {

			$('.ink').remove();
			$('.ink', $('iframe').contents()).remove();

			Popup.prototype.destroy.apply(this, arguments);
		}

	});
});
