/**
 *	@desc Address model, extend Core Model 
 */
define('models/singles/address',['models/core/model', 'underscore', 'models/singles/third', 'db/easy', 'app'], function (Model, _, Third, DB, App) {

	'use strict';

	return Model.extend({

		defaults: {

			id: 0,
			linkedid: 0,
			name: "",
			zip: "",
			town: "",
			country: "",
			part1: "",
			part2: "",
			linkedtype: "third"

		},

		storeName: 'address',
		urlRoot: '/rest/address/',
		name: 'address',

		/**
   * @desc Check if model is valid
   */
		validate: function (attributes, options) {

			var required = {

				"address": {
					required: false,
					name: true,
					part1: false,
					part2: false,
					zip: false,
					town: false,
					countrycode: true
				}

			};

			var validationErrors = { address: {} };

			//Address validator
			if (attributes.zip !== '' || attributes.part1 !== '' || attributes.part2 !== '' || attributes.town !== '') {
				required.address.name = true;
			}

			if (required.address.zip && !attributes.zip) {
				validationErrors['address']['zip'] = this.computeError({ name: 'zip', type: 'required' });
			}

			if (!attributes.name && required.address.name) {
				validationErrors['address']['name'] = this.computeError({ name: 'name', type: 'required' });
			}

			if (!attributes.countrycode && required.address.countrycode) {
				validationErrors['address']['countrycode'] = this.computeError({ name: 'countrycode', type: 'required' });
			}

			if (_.toArray(validationErrors.address).length > 0) {
				return validationErrors;
			}
		},

		/**
   * 
   * @returns {undefined}
   */
		saveLocal: function (ajaxError) {

			var _this = this;

			var errorHandler = function (resolve, reject, ajaxError) {

				// Becareful with ajax response
				if (_.isUndefined(ajaxError)) {
					resolve();
				} else {
					reject(ajaxError);
				}
			};

			return new Promise(function (resolve, reject) {

				if (!_.isUndefined(_this.storeName) && _this.storeName !== '') {

					var id = [];
					id[_this.idAttribute] = parseInt(_this.id);
					_this.set(id);

					DB[_this.storeName].put(_this.toJSON()).then(function (lastId) {

						var addressObj = _this.toJSON();
						var linkedId = parseInt(addressObj.linkedid);

						resolve();
					}, function (err) {
						console.log(err);
						reject();
					});
				} else {
					errorHandler(resolve, reject, ajaxError);
				}
			});
		},

		/**
   * 
   * @returns {Promise}
   */
		destroyLocal: function () {

			var _this = this;

			return new Promise(function (resolve, reject) {

				DB[_this.storeName].delete(_this.id).then(function () {

					//					//when address is delete, we need to delete it in linked model too
					//					DB[_this.get('linkedtype')].update(parseInt(_this.get("linkedid")),{mainAddress : '', addressid : 0}).then(function() {
					//						resolve();
					//					}, function(error) {
					//						console.log(error);
					//						reject();
					//					});

					resolve();
				}, function (error) {
					console.log(error);
				});
			});
		},

		/**
   * 
   * @returns {undefined}
   */
		updateLinked: function (linkedid, linkedtype) {

			return new Promise(function (linkedUpdated, updatedFailed) {

				var linked = '';
				var promise = new Promise(function (resolve, reject) {

					switch (linkedtype) {

						case 'corp':

							linked = App.currentCorp;
							resolve();

							break;

						case 'third':

							require(['models/singles/third'], function (Third) {
								linked = new Third({ id: linkedid });
								resolve();
							});

							break;

						case 'people':

							resolve();
							break;

					}
				});

				promise.then(function () {
					linked.fetch().then(function () {
						linkedUpdated(linked);
					});
				});
			});
		}

	});
});
