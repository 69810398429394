/**
 *  @desc support sources Collection, extend core Collection
 */
define('models/collections/supportSources',['models/core/collection', 'models/singles/supportSource'], function (Collection, SupportSource) {

	'use strict';

	return Collection.extend({

		model: SupportSource,
		storeName: 'supportsources',
		name: 'supportsources',
		url: '/rest/support/sourcelist'
	});
});
