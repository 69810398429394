/**
 *  @desc EmailTemplates Collection, extend core Collection
 */
define('models/collections/emailTemplates',['models/core/collection', 'models/singles/emailTemplate', 'tools/core/utils'], function (Collection, EmailTemplate, Utils) {

	'use strict';

	return Collection.extend({

		model: EmailTemplate,
		storeName: 'emailTemplate',
		name: 'templates',
		url: '/rest/emailBox/templates/list',
		fields: []

	});
});
