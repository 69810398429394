/**
 * @description SearchBar View
 */
define('views/core/navButtons',['underscore', 'views/core/view', 'app'], function (_, View, App) {

	'use strict';

	return View.extend({

		events: {
			'click li:not(.active)': 'changeTab',
			'click li.active': function () {
				return false;
			}
		},
		template: {},

		models: {},
		content: '',
		parentView: {},
		id: 'nav-pills-container',
		name: 'navButtons',

		/**
   * 
   * @param {String} template
   * @param {View} listing
   */
		initialize: function (parentView, navContent) {

			if (parentView !== undefined) {

				this.template = 'templates/widgets/navButtons.twig';
				this.parentView = parentView.parentView;
				this.content = navContent;

				this.options = {
					extraData: {
						content: function () {
							return new Promise(function (resolve, reject) {
								resolve(navContent);
							});
						}
					}
				};

				this.render();
			}
		},

		/**
   * 
   * @desc insert navButtons in the DOM
   */
		renderCallback: function () {

			if (this.content.class) {
				this.$el.addClass(this.content.class);
			}

			this.parentView.$el.append(this.$el);
			this.currentli = this.$el.find('.active');
		},

		/**
   * 
   * @returns {undefined}
   */
		triggerNextButtons: function () {
			var first = this.$el.find('ul').find('li:first');
			var selected = this.$el.find('ul').find('li.active');

			var next = selected.next('li').length ? selected.next('li') : first;
			next.trigger('click');
		},
		/**
   * 
   * @returns {undefined}
   */
		triggerPreviousButtons: function () {
			var last = this.$el.find('ul').find('li:last');
			var selected = this.$el.find('ul').find('li.active');

			var prev = selected.prev('li').length ? selected.prev('li') : last;
			prev.trigger('click');
		},

		/**
   * 
   * @param {type} event
   * @returns {Boolean}
   */
		changeTab: function (event) {

			var $launcher = $(event.currentTarget);
			var btn = this.content.buttons[$launcher.data('button-key')];

			if (btn.callback) {

				// exec callback actions
				btn.callback();

				// set active li
				this.currentli.removeClass('active');
				$launcher.addClass('active');

				// set the currently selected li
				this.currentli = $launcher;
				return false;
			}
		}

	});
});
