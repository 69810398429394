/**
 * @desc support Overview, extend PageOverview View
 * Child View : Core Overview
 */
define('views/support/overview',['underscore', 'views/core/pageOverview', 'views/core/overview', 'views/core/listing', 'models/singles/ticket', 'models/singles/ticketConversation', 'models/collections/ticketConversations', 'app', 'tools/buildURL', 'views/core/toastNotification', 'tinyMCE', 'models/collections/smarttags', 'views/widgets/tabs', 'views/support/timeline', 'views/support/formResponse'], function (_, PageOverview, Overview, Listing, Ticket, TicketConversation, TicketConversations, App, URLBuilder, ToastNotification, TinyMCE, Smarttags, Tabs, Timeline, FormResponse) {

	'use strict';

	return PageOverview.extend({

		hideSearchIcon: true,

		events: _.extend({
			'change #ticket-step': 'updateStep',
			'change #ticket-collab': 'updateAssignation',
			'change .type': 'checkTypeAction',
			'click .loadMore': function () {
				this.timeline.$el.trigger('show');
			},
			'click input[name="ticket[type]"]': function (e) {

				if (App.isOnline) {
					this.formResponse.$el.trigger('show', [$(e.currentTarget).val()]);
				} else {
					this.notifWriteDisabled();
				}
			}
		}, PageOverview.prototype.events),

		toastMessages: {
			stepupdated: App.polyglot.t('stepupdated'),
			assignationupdated: App.polyglot.t('assignationupdated'),
			noteRemoved: App.polyglot.t('noteRemoved'),
			replyAdded: App.polyglot.t('replyAdded'),
			replyUpdated: App.polyglot.t('replyUpdated'),
			noteAdded: App.polyglot.t('noteAdded'),
			noteUpdated: App.polyglot.t('noteUpdated'),
			thirdUpdated: App.polyglot.t('thirdUpdated')
		},

		template: "templates/support/overview.twig",

		/**
   * @desc initialize
   */
		initialize: function (router, id) {

			// customFields filter
			this.customFieldFilter = {
				useOn: 'ticket',
				linkedtype: 'ticket',
				linkedid: id
			};

			this.smartTagsFilter = Smarttags.buildFilterByLinked('ticket', id);

			this.canEdit = App.permission.can("support_editticket");
			this.canDelete = App.permission.can("support_createticket");

			PageOverview.prototype.initialize.apply(this, arguments);

			this.router = router;
			this.ticket = this.model = new Ticket({ id: id });
			this.models[this.ticket.name] = this.ticket;
			var _this = this;

			this.shortenedPanel = {
				relatedType: 'support',
				relatedId: id,
				name: 'subject',
				model: this.ticket,
				actions: {
					email: {
						display: false
					},
					logHisto: {
						display: false
					},
					briefcase: {
						display: false
					},
					comment: {
						display: false
					}
				}
			};

			if (App.permission.can('timetracking_access')) {

				this.shortenedPanel.actions['timetracking'] = {
					icon: 'icon-timetracking',
					label: App.polyglot.t("timetracking"),
					url: function () {
						return URLBuilder(['timetracking', 'list', 'myentries', 'ticket', id]);
					}
				};
			}

			this.options.extraData = {
				supportsteps: function () {
					return new Promise(function (resolve, reject) {
						resolve(App.availableTicketStep);
					});
				}
			};

			this.overview = new Overview({ parentView: this }, this.router);
			this.subviews.push(this.overview);
			this.subviews.push(new Tabs('#support-header'));
			this.buildShortenedPanel();

			this.timeline = new Timeline(router, {
				modelsFetched: { "ticket": this.ticket },
				mainView: this
			});

			this.formResponse = new FormResponse(router, {
				modelsFetched: { "ticket": this.ticket },
				mainView: this
			});

			this.toggleableViews.push(this.timeline);
			this.toggleableViews.push(this.formResponse);

			this.render();
		},

		/**
   * 
   * @returns {undefined}
   */
		afterRender: function () {

			var _this = this;

			PageOverview.prototype.afterRender.apply(this, arguments);

			// transform textarea to html editor
			TinyMCE.remove();
			TinyMCE.init({
				selector: '#timeline-container textarea[name="message"]',
				toolbar: 'bold italic | alignleft aligncenter alignright alignjustify',
				plugins: "autoresize",
				menubar: false,
				statusbar: false,
				autoresize_max_height: '100px',
				setup: _.bind(function (ed) {
					//wait tinyMCe initialization before continuing

					ed.on('init', _.bind(function (args) {
						_this.conversationMCE = ed;
					}, this));
				}, this)
			});
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		updateStep: function (e) {

			var _this = this;
			var v_current = $(e.currentTarget);

			this.ticket.updateStep(v_current.val()).then(function (newStep) {

				v_current.addClass('step-' + newStep).removeClass('step-' + _this.ticket.get('step'));
				_this.ticket.set("step", newStep);

				new ToastNotification({
					id: 'toast-success',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: _this.toastMessages.stepupdated
				});
			});
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		updateAssignation: function (e) {

			var _this = this;
			var v_current = $(e.currentTarget);

			this.ticket.updateAssignation(v_current.val()).then(function () {

				new ToastNotification({
					id: 'toast-success',
					classes: 'col-xs-12',
					timeDuration: 4000,
					content: _this.toastMessages.assignationupdated
				});
			});
		},

		/**
   * 
   * @param {type} e
   * @returns {undefined}
   */
		checkTypeAction: function (e) {

			var v_current = $(e.currentTarget);
			var v_elem = v_current.parent();
			var v_tab = v_current.closest('.tabulations');

			var val = $(e.currentTarget).val();

			v_tab.find('.focused').removeClass('focused');
			v_elem.toggleClass('focused');
		}

	});
});
