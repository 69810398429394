/**
 *  @desc Proformas Collection, extend core Collection
 */
define('models/collections/proformas',['models/collections/documents', 'models/singles/proforma'], function (Documents, Proforma) {

	'use strict';

	return Documents.extend({

		model: Proforma,
		storeName: 'document',
		url: '/rest/document/list',
		doctype: 'proforma',
		availableSteps: ['draft', 'sent', 'read', 'accepted', 'expired', 'advanced', 'partialinvoiced', 'invoiced', 'cancelled']

	});
});
